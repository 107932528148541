import React from "react";

export default function StatusComponent({status_name = ''}) {
  return (
    <>
      <span className="cstm_badge_holder cstm_black_border_badge">
        <span className="dot_holder">&nbsp;</span>
        {status_name}
      </span>
      {/* <span className="cstm_badge_holder cstm_black_border_badge broken">
        <span className="dot_holder">&nbsp;</span>
        Broken
      </span>
      <span className="cstm_badge_holder cstm_black_border_badge decom">
        <span className="dot_holder">&nbsp;</span>
        Decommissioned
      </span>
      <span className="cstm_badge_holder cstm_black_border_badge damaged">
        <span className="dot_holder">&nbsp;</span>
        Damaged  
      </span>
      <span className="cstm_badge_holder cstm_black_border_badge assigned">
        <span className="dot_holder">&nbsp;</span>
        Assigned
      </span>
      <span className="cstm_badge_holder cstm_black_border_badge use">
        <span className="dot_holder">&nbsp;</span>
        In Use
      </span>
      <span className="cstm_badge_holder cstm_black_border_badge deployed">
        <span className="dot_holder">&nbsp;</span>
        Deployed
      </span> */}
    </>
  )
}