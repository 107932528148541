import FuseUtils from '@fuse/utils';
import AppContext from 'app/AppContext';
import { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import { setRedirectUrlCache } from 'app/auth/store/userSlice';

class FuseAuthorization extends Component {
	constructor(props, context) {
		super(props);
		const { routes } = context;
		this.excludeRoutesRedirect = ['/login', '/set-password/', '/reset/','/reset-password','/registration','/welcome']
		this.state = {
			accessGranted: true,
			routes
		};
	}

	componentDidMount() {
		if(!this.excludedRoute(this.props.location.pathname) && this.props.location.pathname !== '/'){
			this.props.setRedirectUrlCache(this.props.location.pathname)
		}
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	excludedRoute(path){
		let excluded = false;
		this.excludeRoutesRedirect.forEach(excludedRoute => {
			if(!excluded && path.includes(excludedRoute)){
				excluded = true;
			}
		})
		return excluded
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.accessGranted !== this.state.accessGranted;
	}

	componentDidUpdate() {
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { location, userRole } = props;
		const { pathname } = location;

		const matched = matchRoutes(state.routes, pathname)[0];

		return {
			accessGranted: matched ? FuseUtils.hasPermission(matched.route.auth, userRole) : true
		};
	}

	redirectRoute() {
		const { location, userRole, history } = this.props;
		const { pathname, state } = location;
		const redirectUrl = this.props.redirectUrlCache ? this.props.redirectUrlCache : (state && state.redirectUrl ? state.redirectUrl : '/');

		/*
        User is guest
        Redirect to Login Page
        */
		if (!userRole || userRole.length === 0) {
			history.push({
				pathname: '/login',
				state: { redirectUrl: pathname }
			});
		} else {
			/*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
			setRedirectUrlCache(null)
			history.push({
				pathname: redirectUrl
			});
		}
	}

	render() {
		// console.info('Fuse Authorization rendered', this.state);
		return this.state.accessGranted ? <>{this.props.children}</> : null;
	}
}

function mapStateToProps({ auth }) {
	return {
		userRole: auth.user.role,
		redirectUrlCache: auth.user.redirectUrlCache
	};
}

const mapDispatchToProps = (dispatch) => ({
	setRedirectUrlCache: url => dispatch(setRedirectUrlCache(url))
})

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseAuthorization));
