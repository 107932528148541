import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from "@fuse/utils/axios";

const useStyles = makeStyles(theme => ({
	root: {
		'&.user': {
			'& .username, & .email': {
				transition: theme.transitions.create('opacity', {
					duration: theme.transitions.duration.shortest,
					easing: theme.transitions.easing.easeInOut
				})
			}
		}
	},
	avatar: {
		background: theme.palette.background.default,
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		}),
		bottom: 0,
		'& > img': {
			borderRadius: '50%'
		}
	}
}));


function UserNavbarHeader(props) {
	const user = useSelector(({ auth }) => auth.user);
	const classes = useStyles();
	const [imageLocation, setImageLocation] = useState(null);
	const [ message, setMessage ] = useState('');
	

	async function getUserDetails(){
		const datetimeStr = new Date().toLocaleString("en-US", { timeZone:  Intl.DateTimeFormat().resolvedOptions().timeZone});
		const dateTimeArray = datetimeStr.split(" ");
		const timeArray = dateTimeArray[1].split(":");
		if(dateTimeArray[2] === "AM") { 
			if( (parseInt(timeArray[0]) <= 11) && (parseInt(timeArray[0]) > 4) )  {
				setMessage("Good Morning, ");
			} else {
				setMessage("Good Night,  ");
			}
		} else {
			if(parseInt(timeArray[0]) === 12) {
				setMessage("Good Afternoon, ");
			
			} else if(parseInt(timeArray[0]) < 4) {
				setMessage("Good Afternoon, ");
			} else if(parseInt(timeArray[0]) >= 4) {
				setMessage("Good Evening, ");
			} else {
				setMessage("Good Morning, ");
			}
		}
		axios.get(`/end-user/view-client/${user.data.id}`)
		.then(results=>{
			setImageLocation(results?.data?.data?.imageUrl ?? "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg")
		})
	}
	useEffect(()=>{
		getUserDetails()
	},[])

	return (
		<AppBar
			position="static"
			color="default"
			classes={{ root: classes.root }}
			className="user relative flex flex-col items-center justify-center pt-24 pb-64 mb-32 z-0 shadow-0"
		>
			<Typography className="username text-18 whitespace-nowrap font-semibold mb-4" color="inherit">
				{message} {user.data.first_name}
			</Typography>
			<Typography className="email text-13 opacity-50 whitespace-nowrap font-medium" color="inherit">
				{/* {user.data.email} */}
			</Typography>
			<div className="flex items-center justify-center absolute bottom-0 -mb-44">
				<Avatar
					className={clsx(classes.avatar, 'avatar w-72 h-72 p-8 box-content')}
					alt="user photo"
					src={
						// user.data.photoURL && user.data.photoURL !== ''
						// 	? imageLocation
						// 	: 'assets/images/avatars/profile.jpg'
						imageLocation
					}
				/>
			</div>
		</AppBar>
	);
}

export default UserNavbarHeader;
