import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import {TextField} from '@material-ui/core';
import { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import useToast from '@fuse/hooks/useToast'
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { usePermission } from '@fuse/hooks';
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	const [currentFilter, setCurrentFilter] = useState('');
	const [currentFilterLabel, setCurrentFilterLabel] = useState('');
	const { hasPermission } = usePermission()
	const createPaymentTermPermission = hasPermission('add-payment_term')
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const addReportPermission = hasPermission('add-report')

	function handleSearchReset(){
		props.handleSearchReset();
		props.handleSearchSuggestions("", currentFilter)
		setSearchQuery("");
	}

	useEffect(() => {
		console.log(props.searchFilters);
		
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilter(props.searchFilters[0].field)
		}
	},[props.searchFilters])

	useEffect(() => {
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilterLabel(props.searchFilters.find((item) => item.field == currentFilter)?.label || "");
		}
	},[currentFilter]);

	useEffect(() => {
		props.onSearchChange(search, currentFilter)
	},[search, currentFilter])

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M3.00488 3.00293H21.0049C21.5572 3.00293 22.0049 3.45064 22.0049 4.00293V20.0029C22.0049 20.5552 21.5572 21.0029 21.0049 21.0029H3.00488C2.4526 21.0029 2.00488 20.5552 2.00488 20.0029V4.00293C2.00488 3.45064 2.4526 3.00293 3.00488 3.00293ZM4.00488 5.00293V19.0029H20.0049V5.00293H4.00488ZM8.50488 14.0029H14.0049C14.281 14.0029 14.5049 13.7791 14.5049 13.5029C14.5049 13.2268 14.281 13.0029 14.0049 13.0029H10.0049C8.62417 13.0029 7.50488 11.8836 7.50488 10.5029C7.50488 9.12222 8.62417 8.00293 10.0049 8.00293H11.0049V6.00293H13.0049V8.00293H15.5049V10.0029H10.0049C9.72874 10.0029 9.50488 10.2268 9.50488 10.5029C9.50488 10.7791 9.72874 11.0029 10.0049 11.0029H14.0049C15.3856 11.0029 16.5049 12.1222 16.5049 13.5029C16.5049 14.8836 15.3856 16.0029 14.0049 16.0029H13.0049V18.0029H11.0049V16.0029H8.50488V14.0029Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Payment Terms List
				</Typography>
			</div>
			<div style={{display:'flex'}}>
				{addReportPermission && <div className={classes.buttonContainer}>
							<Button
							onClick={() => dispatch(openReportViewerDialog())}
							variant="contained"
							color="secondary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'150px',marginRight: '10px'}}
							startIcon={
								<Icon fontSize="small" title="Add Report">
									add
								</Icon>
							}>
								Add Report
							</Button>
				</div>}
				{createPaymentTermPermission && <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'200px', marginRight: '20px'}}
						onClick = {()=>dispatch(openFormViewerDialog())}
						startIcon={
						<Icon fontSize="small" title="Add Payment Term">
							add
						</Icon>}>
						Add Payment Term
					</Button>
				</div>}
				{/* <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight:"20px"}}
						onClick = {()=>dispatch(openReportViewerDialog())}
						startIcon={
						<Icon fontSize="large" title="Save View">
							save
						</Icon>}>
							Save View
					</Button>
				</div> */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px"}}
						>
							<TextField
								placeholder="Search Payment Terms"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>

		</div>
	)
}

export default DynamicFieldsHeader;
