import axios from "@fuse/utils/axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  Icon,
  IconButton,
  makeStyles,
  Modal,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import useToast from "@fuse/hooks/useToast";
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subSection: {
    marginLeft: "20px !important",
    marginRight: "20px !important",
    marginBottom: "20px !important",
    padding: "10px",
    backgroundColor: "#f4f4f4",
    borderRadius: "4px",
  },
}));
function AddPhoneNumberModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const [formattedNumber, setFormattedNumber] = useState('')
  const [reminderCheckboxes, setReminderCheckBoxes] = useState({
    term45: true,
    term30: false,
    supportContact: false,
  });
  //Form validation schema
  const schema = yup.object({});
  const defaultValues = {
    phoneNumber: "",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  function managePhoneNumber(formData) {
    if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
    let payload = {
      phone_number: formattedNumber
    }
    axios
      .post("/user/update-user-phone-number", payload)
      .then((response) => {
          toast.success("Phone number added successfully.");
          console.log("props.setPhoneNumberAdded(true)", props.setPhoneNumberAdded)
          props.setPhoneNumberAdded(true)
          close();
      })
      .catch((err) => {
        console.log("212=>", err);
        toast.error("Something went wrong!");
      });
  }
  function formErrorData(error) {
    console.log("formErrorData: ", error);
  }
  function close() {
    reset(defaultValues);
    props.handlePhoneNumberCloseModal();
  }
  function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}
  return (
    <Modal
      open={props.open}
      // onClose={close}
      className={classes.modal}
    >
      <div
        style={{
          width: 700,
          backgroundColor: "white",
          borderRadius: 9,
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          maxHeight: "98vh",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // paddingLeft: "20px",
          }}
        >
        <h3>Please add your phone number to receive text message</h3>
          <IconButton
            onClick={() => {
              close();
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>
        <form
          onSubmit={handleSubmit(managePhoneNumber, formErrorData)}
        >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    marginBottom: "10px",
                    marginRight: "10px",
                    width: "100%",
                  }}
                >
                  <Controller
                    name="phoneNumber"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Phone Number"
                          fullWidth
                          inputProps={{ readOnly: false }}
                          required
                          value={formattedNumber}
                          style={{ backgroundColor: "#fff" }}
                          onChange={(e)=>{
														changeNumberFormat(e)
													}}
													onKeyPress={(event) => {
														if (!/[0-9/-]+/.test(event.key)) {
															event.preventDefault();
														}
													}}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
          <div style={{ display: "flex", gap: "20px", paddingTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ borderRadius: "4px" }}
              type="submit"
            >
              Add
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ borderRadius: "4px" }}
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
export default AddPhoneNumberModal;