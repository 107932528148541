import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useToast from '@fuse/hooks/useToast';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import {setAllHeaders, setVisibleHeaders, setDynamicHeaders, setStaticHeaders} from '../../store/reorderTableReducer'

function ModulesTable() {
    const headerList = useSelector(({modulesApp}) => modulesApp.reorderTableReducer)
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
    const router = useRouter();

    const [openDialog, setOpenDialog] = useState(false);
    const [deleteAttr, setDeleteAttr] = useState('');

    const { staticHeaders, dynamicHeaders, visibleHeaders } = headerList
    const { id } = router.params;

    const getHeaders = () => {
        try {
            return axios.get(`/dynamic-attributes/get-all-attributes/${id}`).then(res => {
                const {data} = res.data
                const {staticHeaders, dynamicHeaders, visibleHeaders} = data
    
                let parsedVisibleHeaders = JSON.parse(visibleHeaders)
                dispatch(setAllHeaders(staticHeaders, dynamicHeaders, parsedVisibleHeaders))
            })
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
        
    }

    const handleVisibleHeaderChange = (result) => {
        if (!result.destination) return;
        const newList = Array.from(visibleHeaders);
        const [reorderedItem] = newList.splice(result.source.index, 1);
        newList.splice(result.destination.index, 0, reorderedItem);

        dispatch(setVisibleHeaders(newList))
    }

    const saveVisibleHeaderOrder = () => {
        const postData = {
            "platform_module_id" : id,
            "attribute_order" : JSON.stringify(visibleHeaders)
        }

        axios.post("/dynamic-attributes/save-attribute-order", postData).then((res)=>{
            const {data} = res.data
            toast.success(data)
        }).catch((err) => {
            console.log(err)
            toast.error("Something went wrong please try again")
        })
    }

    const addVisibleHeader = (input) => {
        if(visibleHeaders.includes(input))
        {
            toast.error("Header already exists")
            return false
        }

        let type = ''

        const modStaticHeaders = staticHeaders.map((attr) => {
            if(attr.label == input)
            {
                type = "static"
                return{
                    isVisible : true,
                    label : attr.label
                }
            }
            else
            {
                return attr
            }
        })

        const modDynamicHeaders = dynamicHeaders.map((attr) => {
            if(attr.label == input)
            {
                type = "dynamic"
                return{
                    isVisible : true,
                    label : attr.label
                }
            }
            else
            {
                return attr
            }
        })

        if(type == "static")
        {
            dispatch(setStaticHeaders(modStaticHeaders))
        }
        else
        {
            dispatch(setDynamicHeaders(modDynamicHeaders))
        }

        const newList = visibleHeaders
        newList.push(input)
        dispatch(setVisibleHeaders(newList))
    }

    const removeField = () => {
        if(!deleteAttr) return
        const newList = visibleHeaders.filter(label => label != deleteAttr)
        let type = ''

        const modStaticHeaders = staticHeaders.map((attr) => {
            if(attr.label == deleteAttr)
            {
                type = "static"
                return{
                    isVisible : false,
                    label : attr.label
                }
            }
            else
            {
                return attr
            }
        })

        const modDynamicHeaders = dynamicHeaders.map((attr) => {
            if(attr.label == deleteAttr)
            {
                type = "dynamic"
                return{
                    isVisible : false,
                    label : attr.label
                }
            }
            else
            {
                return attr
            }
        })

        if(type == "static")
        {
            dispatch(setStaticHeaders(modStaticHeaders))
        }
        else
        {
            dispatch(setDynamicHeaders(modDynamicHeaders))
        }

        dispatch(setVisibleHeaders(newList))
        setOpenDialog(false)
    }

    const handleOpenDialog = (label) => {
        setDeleteAttr(label)
        setOpenDialog(true)
    }

    const closeDialog = () => {
        setOpenDialog(false)
    }

    useEffect(()=> {
		getHeaders()
	}, [])

	return (
		<>
            <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
				<DialogTitle id="form-dialog-title">Remove {deleteAttr} From Table</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
                <Button onClick={removeField} color="primary">
					Remove
				</Button>
				<Button onClick={closeDialog} color="primary">
					Cancel
				</Button>
				</DialogActions>
			</Dialog>
			<div>
                {/* <Button
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        style={{float:"right"}}
                        onClick={saveVisibleHeaderOrder}
                >
                        Save Order
                </Button> */}
                <Button
						onClick={saveVisibleHeaderOrder}
						variant="contained"
						color="primary"
						style={{float:"right"}}
						startIcon={<SaveIcon />}>
						Save Order
					</Button>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <List>
                            <Typography variant="h6">
                                Static Headers
                            </Typography>
                            {
                                staticHeaders.map( attr => {
                                    return (
                                    <div>
                                    <ListItem key={attr.label} button disabled={attr.isVisible}>
                                        <ListItemText
                                            primary={attr.label}
                                        />
                                        <IconButton edge="end" aria-label="hide Header" onClick={() => {addVisibleHeader(attr.label)}}>
                                            <AddIcon />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </div >
                                )})
                            }
                        </List>
                        <List>
                            <Typography variant="h6">
                                Dynamic Headers
                            </Typography>
                            {
                                dynamicHeaders.map( attr => {
                                    console.log(attr.isVisible)
                                    return(
                                    <div>
                                        <ListItem key={attr.label} button disabled={attr.isVisible}>
                                            <ListItemText
                                                primary={attr.label}
                                            />
                                            <IconButton edge="end" aria-label="hide Header" onClick={() => {addVisibleHeader(attr.label)}}>
                                                <AddIcon  />
                                            </IconButton>
                                        </ListItem>
                                        <Divider />
                                    </div >
                                )})
                            }
                        </List>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <DragDropContext onDragEnd={handleVisibleHeaderChange}>
                            <Droppable droppableId="visibleHeaders">
                            {(provided) => (

                                <List  {...provided.droppableProps} ref={provided.innerRef}>
                                    <Typography variant="h6">
                                        Visible Headers
                                    </Typography>
                                    {
                                        visibleHeaders.map( (label, index) => (
                                            
                                                <Draggable key={label} draggableId={label} index={index}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                                        <ListItem button>
                                                            <ListItemText
                                                                primary={label}
                                                            />
                                                                <IconButton edge="end" aria-label="delete" onClick={() => {handleOpenDialog(label)}}>
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                        </ListItem>
                                                        <Divider />
                                                        </div >
                                                    )}
                                                </Draggable>
                                            
                                        ))
                                    }
                                    {provided.placeholder}
                                </List>
                            )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                </Grid>
			</div>
		</>
	);
};

export default ModulesTable;
