module.exports = [
    {
        id:1,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
    {
        id:2,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
    {
        id:3,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
    {
        id:4,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
    {
        id:5,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
    {
        id:6,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
    {
        id:7,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
    {
        id:8,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
    {
        id:9,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
    {
        id:10,
        Name: 'FedEx',
        Agent: 'Mary 3',
        Active_Quotes: 4,
        Upcoming_Quotes: 20,
        Pending_Quotes: 14,
        Cancelled_Quotes: 0
    },
]