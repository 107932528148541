const initialState = {
	open: false,
	quickAddedAsset: false,
	unMountAssetList: false,
}

const actionTypes = {
    OPEN_REPORT_VIEWER_DIALOG: "OPEN_REPORT_VIEWER_DIALOG",
	QUICK_ADDED_ASSET: "QUICK_ADDED_ASSET",
    CLOSE_REPORT_VIEWER_DIALOG: "CLOSE_REPORT_VIEWER_DIALOG",
	UNMOUNT_ASSET_LIST: "UNMOUNT_ASSET_LIST"
}

export function openReportViewerDialog(){
    return {
        type: actionTypes.OPEN_REPORT_VIEWER_DIALOG,
    }
}

export function unMountAssetListFunc () {
	return {
        type: actionTypes.UNMOUNT_ASSET_LIST,
    }
}

export function closeReportViewerDialog(){
    return {
        type: actionTypes.CLOSE_REPORT_VIEWER_DIALOG,
    }
}


export function quickAddedAsset() {
	return {
		type: actionTypes.QUICK_ADDED_ASSET
	}
}

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.OPEN_REPORT_VIEWER_DIALOG:{
			return { 
				...state,
				open: true,
			}
		}
		case actionTypes.CLOSE_REPORT_VIEWER_DIALOG:{
			return { 
				...state,
				open: false,
			}
		}
		case actionTypes.QUICK_ADDED_ASSET: {
			return {
				...state,
				quickAddedAsset: !state.quickAddedAsset
			}
		}
		case actionTypes.UNMOUNT_ASSET_LIST: {
			return {
				...state,
				unMountAssetList: !state.unMountAssetList
			}
		}
			
	  default:
		return state
	}
}