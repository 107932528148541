//import Button from '@material-ui/core/Button';
//import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogContentText from '@material-ui/core/DialogContentText';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef} from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Radio from '@material-ui/core/Radio';
import useToast from '@fuse/hooks/useToast';
import axios from "@fuse/utils/axios";
import { useTheme } from '@material-ui/core/styles';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Button, Card, CardHeader, CardContent, Divider, List, ListItem, ListItemText, Icon, IconButton, Paper, Typography, Box, TextField, InputAdornment,CircularProgress,Accordion, AccordionSummary, AccordionDetails, Chip } from '@material-ui/core';

function showNote(props){
    const theme = useTheme();
    const id = props.quoteId;
    const dispatch = useDispatch();
    const toast = useToast(dispatch);
    // const [permission,setpermission]=useState('everyone');
    const [notes, setnotes] = useState();
    const [shownotes, setshownotes] = useState([]);
    const [viewStatus, setviewStatus]= useState(false);

    function addNoteandPermission(){
        axios.post('/quotes/update-note',{
            id,
            notes,
            permission: 'team_members'
        }).then(res=>{
            setnotes();
            props.closeDialog()
            toast.success(`${res.data.message}`)
        }).catch(err=>{
            console.log(err);
        })
    }

    // function permissionChange(event){
    //     setpermission(event.target.value)
    // }

    return (
        <Dialog onClose={props.closeDialog}  aria-labelledby="simple-dialog-title" open={props.open} maxWidth="sm" fullWidth={true}>

            <DialogTitle id="simple-dialog-title"> 
            <div>
                Notes
                {/* {!viewStatus &&
                    <Button style={{float: 'right'}}  color="primary" onClick={viewnotes}>
                        View note history
                    </Button>  
                } */}
                  
                {/* <span color="primary" style={{float: 'right'}} onClick={viewnotes}>View note history</span> */}
            </div>
            </DialogTitle>
            {shownotes.length > 0 ? (
            <DialogContent>
                <Paper 
                square 
                style={{background:theme.palette.secondary.main,paddingLeft:20,paddingTop:10,paddingBottom:10,paddingRight:50}}
				className="flex flex-1 items-center justify-center"
                >
                    <Grid container style={{width:"100%"}}>
                        <Grid item xs={4}>User Name</Grid>
                        <Grid item xs={3}>Notes</Grid>
                        <Grid item xs={3}>Permission</Grid>
                        <Grid item xs={2}>Date</Grid>
                    </Grid>
                </Paper>
                {shownotes.map(log => (
                <Accordion key={log.id}>
                    <AccordionSummary
                    //expandIcon={<ExpandMoreIcon />}
                    aria-controls={`log_line_${log.id}`}
                    id={`log_line_${log.id}`}
                    >
                        <Grid container style={{width:"100%"}}>
                            <Grid item xs={3}>{log.client_user.first_name}&nbsp;{log.client_user.last_name}<br/>
                            {
                                log.client_user.role && 
                                <Chip 
                                label={log.client_user.role.display_name} 
                                size="small" 
                                style={{borderRadius:2,marginTop:5}}
                                />
                            }
                            </Grid>
                            <Grid item xs style={{overflowWrap: 'anywhere'}}>{log.notes}</Grid>
                            <Grid item xs>{
                                log.permission.slice(1).split('_')[1] ? (
                                    log.permission[0].toUpperCase()+log.permission.slice(1).split('_')[0] +' '+ log.permission.slice(1).split('_')[1]
                                ):(
                                    log.permission[0].toUpperCase()+log.permission.slice(1).split('_')[0]
                                )
                            }
                            </Grid>
                            <Grid item xs>{dateReformatTo_mmm_dd_yyyy(log.created_at.split('T')[0])}</Grid>
                        </Grid>
                    </AccordionSummary>
                    <Divider/>
                    <AccordionDetails>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography>
                        {log.detail}
                    </Typography>
                    </Grid>
                    {/* {
                        log.comment && log.comment != null && log.comment != '' ? 
                        <Grid item xs={12}>
                            <Paper className={`p-10 rounded-sm`} variant="outlined">
                                <Typography component="h6" style={{fontWeight:'bold'}}>
                                    Comment:
                                </Typography>
                                <Typography>
                                    {log.comment}
                                </Typography>
                            </Paper>
                        </Grid>
                        :
                        <>
                        </>
                    } */}
                    </Grid>
                    </AccordionDetails>
                </Accordion>)
                )
                }
                <Divider/>
            </DialogContent>):(
            <DialogContent>
                <div style={{marginBottom: '10px'}}>Type your notes</div>
                <div style={{border: '1px solid purple'}}>
                <TextareaAutosize 
                aria-label="minimum height" 
                rowsMin={10} 
                placeholder="Notes" 
                style={{ width: '530px', margin: '10px'}} 
                value={notes}
                onChange= {(event)=>{
                    setnotes(event.target.value);
                }}/>
                </div>
                
                {/* <div style={{marginTop: '10px'}}>Permission to view (Please note admin can view all notes)</div>
                <div style={{marginTop: '10px'}}>
                    <Radio
                        checked={permission === 'everyone'}
                        onChange={permissionChange}
                        value="everyone"
                        name="radio-button-demo"
                    />Everyone
                    <Radio
                        checked={permission === 'only_manager'}
                        onChange={permissionChange}
                        value="only_manager"
                        name="radio-button-demo"
                    />Managers
                    <Radio
                        checked={permission === 'team_members'}
                        onChange={permissionChange}
                        value="team_members"
                        name="radio-button-demo"
                    />Team Members
                    <Radio
                        checked={permission === 'end_users'}
                        onChange={permissionChange}
                        value="end_users"
                        name="radio-button-demo"
                    />End Users
                </div> */}
                
            </DialogContent>)}
            <DialogActions>
                {
                    viewStatus ? (<Button  color="primary" onClick={()=>{
                        setshownotes([])
                        setviewStatus(false)
                    }}>
                    Back
                    </Button>):(
                    <Button disabled={!notes} color="primary" onClick={addNoteandPermission}>
                    Add Note
                    </Button>
                )
                }
                    
                    <Button  onClick = {()=>{
                        props.closeDialog()
                        setshownotes([])
                        setviewStatus(false)
                        }}color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
        </Dialog>
    )
}

export default showNote;