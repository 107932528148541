import React, { useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
// import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import ColorPicker from '@fuse/components/ColorPicker'
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))



const AddContractTypeFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const defaultValues = {
        contractTypeName: '',
        contract_description: ''
    }

    const schema = yup.object({
        contractTypeName: yup.string().trim().required('Contract Type Name is required!'),
        contract_description: yup.string(),
    })

    const { 
        control, 
        formState:{errors: contractTypeFormErrors}, 
        handleSubmit: contractTypeFormSubmit, 
        trigger,
        reset: contractTypeFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    const onSubmit = async (data) => {
        // await trigger();
        // if(Object.keys(contractTypeFormErrors).length !== 0) { 
        //     return;
        // }
        const payload = {
			contract_type: data.contractTypeName,
            contract_description: data.contract_description
		}

		axios.post('contracts/add-contract-types', payload).then(res => {
			
            
            if(res.status == 201){
                toast.error(res.data.message)
                // newDataAddedEvent('')
                return
            }
            toast.success('Contract Type added successfully')
            newDataAddedEvent(payload.contract_type.trim())

		}).catch(err => {
            console.log('error: ',err);
			toast.error(err.response.data.message)
		})
    }

    return (
        <>
            <Dialog
                className="cstm_dialog_modal small text-center"
                open={openAddStatusModalStateData}
                // onClose={handleOpenCloseAddModalEvent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='heading_holder flexbox_holder items-center w-full'>
                        <h2 className="main_heading">Add Contract Type</h2>
                        <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                            <i className="icon ti ti-x" />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-left" style={{width: '387px'}}>
                        <div className='mb-10' style={{width: '387px'}}>
                        {/* <Box className='light_blue_holder'> */}
                        <Typography className='status_text'>
                            Type Name
                            <span className='required_span'>*</span>
                        </Typography>
                            <Controller
                                name="contractTypeName"
                                className="mb-12"
                                width="387px"
                                defaultValue={''}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            variant='outlined'
                                            // label='Status Name'
                                            required
                                            fullWidth
                                            error={!!contractTypeFormErrors.contractTypeName}
                                            helperText={contractTypeFormErrors?.contractTypeName?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px',
                                                    fontSize: '10px'
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            />
                            {/* <Box/> */}

                        </div>

                        <div className='mb-10'  style={{width: '387px'}}>
                            <Typography className='status_text'>
                                Description
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Controller 
                                name='contract_description'
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label="Description"
                                        fullWidth
                                        multiline
                                        rows={8}
                                        inputProps={{ className: classes.textarea }}
                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                        error={!!contractTypeFormErrors.contract_description}
                                        helperText={contractTypeFormErrors?.contract_description?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="action_footer_holder">
                    <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                    <Button onClick={contractTypeFormSubmit(onSubmit)} className="primary_btn">
                        Save and Select
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default AddContractTypeFromQuickAction;