import { authRoles } from 'app/auth';
import Page from './index';

export default {
settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
	},
	// auth: authRoles.user,
	routes: [
		{
			path: '/import-csv',
			exact: true,
			component: Page.ImportCSV
		},
		{
			path: '/csv',
			exact: true,
			component: Page.Layout
		}
	]
};