

import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useCurrentUser } from '@fuse/hooks'
import Checkbox from '@mui/material/Checkbox';
import { Stack, Box, Stepper, Step, StepButton, Button, TextareaAutosize, DialogTitle, Divider, Icon, IconButton, TextField, Typography, InputAdornment, Drawer, CardContent, Paper, Grid, Item, CircularProgress } from '@mui/material'
import axios from '@fuse/utils/axios'
import Card from '@material-ui/core/Card';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Editor from '@fuse/components/Editor';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import { formatPriceField, generateProperLocationString } from '@fuse/utils/general'
import { usePermission } from '@fuse/hooks';
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import { countries } from '@fuse/utils/countryName'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'


const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
        helperText: {
            color: 'red',
            paddingLeft: '1px'
        },
        datePickerInput: {
            '& .MuiInputBase-input': {
                fontSize: '16px'
            }
        }
    }
))
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function QuickAddTicket(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
	const toast = useToast(dispatch)
    const currentUser = useCurrentUser()



    const [status, setStatus] = useState([])
    const [category, setCategory] = useState([])
    const [loading, setLoading] = useState(false)
    const [source, setSource] = useState([])
    const [locationMenu, setLocationMenu] = useState([])
    const [userMenu, setUsersMenu] = useState([])
    const [searchResult, setSearchResult] = useState(null)
    const [suggestion, setSuggestion] = useState([])
    

   

    



    const steps = ['My Information', 'Asset Information', 'Ticket Details'];

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleChangeNoReminderCheckbox = (event) => {
		setNoReminderCheckBox(event.target.checked)
	}

    

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        // handleNext();
    };


    // fetching the value for status dropdown list
	const fetchStatusSuggestions = (value = '') => {
		setLoading(true)
		axios.get("/service-request-status").then(response => {
			setLoading(false)
			setStatus((AlphabeticalSorting(response.data.data,'status_name')).filter((item)=>{ 
				return Boolean( item.status_name)
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

    const fetchCategorySuggestions = (value = '') => {
		setLoading(true)
		axios.get("/service-request-category").then(response => {
			setLoading(false)
			setCategory((AlphabeticalSorting(response.data.data,'category_name')).filter((item)=>{ 
				return Boolean( item.category_name)
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

    // fetching the value for source dropdown list
	const fetchSourceSuggestions = (value = '') => {
		setLoading(true)
		axios.get("/service-request-source").then(response => {
			setLoading(false)
			setSource((AlphabeticalSorting(response.data.data,'source_name')).filter((item)=>{ 
				return Boolean( item.source_name)
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

    const fetchLocationSuggestions = (value = '') => {
		setLoading(true)
		axios.get(`assets/fetch-location-suggestion?value=${value}`).then(response => {
			let locations = AlphabeticalSorting(response.data.data.locations,'location_name')
			setLoading(false)
			setLocationMenu(locations)
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}


    const fetchUserSuggestions = () => {
		axios.get('/assets/fetch-users-suggestion').then(response => {
			let users = AlphabeticalSorting(response.data.data.users,'first_name')
			setUsersMenu(users)
		}).catch(err => {
			console.log('164=>',err);
			// toast.error('Something went wrong!')
		})
	}


    const getSearchResult = (value) => {
		if(!value) return
		axios.get(`/service/assets/get-search-result?serial_number=${value}`).then( res => {
			const { data } = res.data;
			setSearchResult(data.result)
			setValue('location',{
				...res.data.data.result.location
			})
		}).catch(err => {
			toast.error(err.response.data.message)
			console.log(err)
		})
    }


    



    useEffect(() => {
		fetchStatusSuggestions()
		fetchCategorySuggestions()
		fetchSourceSuggestions()
		fetchLocationSuggestions()
		fetchUserSuggestions()
	}, [])



    const defaultValues = {
        assetSerialNumber: ''

    };

    

    const validationSchema = [
        // 0 Required Details
        yup.object({
            assetSerialNumber: yup.string().required('Please select the serial number')
        }),
        // 1 Contract Type
        yup.object({
            
        }),
        // 2 Service Level Agreement
        yup.object({
            
        })
    ];

    const currentValidationSchema = validationSchema[activeStep];


    const methods  = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(currentValidationSchema),
        mode: "onChange"
    });

    const { control, formState, handleSubmit, reset, setValue, clearErrors, getValues, trigger, register } = methods

    const { errors } = formState
    
    const handleNext = async () => {
        const newActiveStep = isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        await trigger();
        switch(activeStep) {
            // Required Details
            case 0:
                console.log(errors)
                // if(Object.keys(errors).length !== 0) {
                //     return;
                // }
                // if(contractStartDate.date === null) {
                //     toast.error('Contract start date is required.')
                //     return;
                // }
                // if(contractEndDate.date === null) {
                //     toast.error('Contract end date is required.')
                //     return;
                // }
                // setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            // Contract Type
            case 1:
                
                // if(Object.keys(errors).length !== 0) {
                //     return;
                // }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;            
            default:
                break; 
        }
        // console.log("isStepValid", isStepValid);
        // if (isStepValid) 
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        reset();
    };


    const handleCloseForm = () => {
        setActiveStep(0);
        reset(defaultValues);
        clearErrors()
        props.close('ticket')
    }


    const captureIsMasterOnchangeEvent = (event) => {
        setMasterValue(event.target.value)
    }


    const handleRadioChange = (event) => {
        // console.log(event.target.value)
		setPurchaseOrder(event.target.value);
	};

    


    const onSubmit = async (data) => {

        
        console.log(JSON.stringify(data));
        // console.log(data.contractNumber)
        // console.log(JSON.stringify(data).contractNumber)
    };


    const onCancel = () => {
		closeDialog()
	}

	const closeDialog = () => {
        clearErrors()
		reset(defaultValues)
	}

    return (
        <div>
            <Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
                <div className='heading_holder'>
                    <h4 className='heading'>Create Ticket</h4>
                    <IconButton onClick={handleCloseForm} style={{padding: '0px'}}>
                        <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                    </IconButton>
                </div>

                <div className='progressbar_holder'>
                    <div style={{width: activeStep === 0 ? '33.33%' : activeStep === 1 ? '66.67%' : activeStep === 2 ? '100%' : '0%'}} className='progress'>
                        &nbsp;
                    </div>
                </div>

                <div className='cstm_wizard_holder'>
                    <Box sx={{ width: '100%' }} className='box_holder'>
                        <Stepper activeStep={activeStep} className='stepper_holder'>
                            {steps.map((label, index) => (
                            <Step className='each_step_holder' key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                                </StepButton>
                            </Step>
                            ))}
                        </Stepper>
                        <div className='main_content_holder'>/;
                            <>
                                <h4 className='main_heading'>
                                    {activeStep === 0 && <span>My Information</span>}
                                    {activeStep === 1 && <span>Asset Information</span>}
                                    {activeStep === 2 && <span>Ticket Details</span>}
                                </h4>
                                <FormProvider {...methods}>
                                    <form>
                                        <div className='content_holder'>
                                            {/* Required Details */}
                                            {activeStep === 0 && 
                                                <>
                                                    
                                                </>
                                            }
                                        </div>
                                        <div className='btn_holder'>
                                            {activeStep !== 0 && 
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className='back_btn'
                                                >
                                                    Back
                                                </Button>
                                            }

                                            {activeStep !== steps.length - 1 ?
                                                (
                                                    <Button onClick={handleNext} className='next_btn'>
                                                        Next
                                                    </Button>
                                                ) : (
                                                    <Button onClick={handleSubmit(onSubmit)} className='next_btn'>
                                                        Proceed
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    </form>
                                </FormProvider>
                            </>
                            {/* )} */}
                        </div>
                    </Box>

                </div>
            </Drawer>
        </div >
    )
}

export default QuickAddTicket