exports.statusColors = {
    '#000000': 'Black',
    '#d44d56': 'Red',
    '#718096': 'Gray',
    '#4caf50': 'Green',
    '#1380e4': 'Blue',
    '#d69e2e': 'Yellow',
    '#dd6b20': 'Orange',
    '#8a56df': 'Purple',
    '#d53f8c': 'Pink'  
}