import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles,
    Modal, TextField,Typography,Divider,InputAdornment,Paper } from '@material-ui/core';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function ManufacturerDialog(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [formattedNumber, setFormattedNumber] = useState('')

    const schema = yup.object({
        manufacturer_name: yup.string().required('Manufacturer Name is required!'),
        url:yup.string().required('URL is required!'),
        // phone:yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
		// }),
    })

    const defaultValues = {
        manufacturer_name: '',
        url: '',
        phone: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    }

    function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

    const { 
        control, 
        formState:{errors}, 
        handleSubmit: manufactureFormSubmit, 
        reset: manufactureFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    function manageManufactureData(formData){
        // console.log('manageManufactureData: ',formData);
        if(formData.url.indexOf('.') == -1){
            toast.error('Please enter a valid URL')
            return
        }
        if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
        const payload = {
            entity_external_platform_id: String(Math.trunc(Math.random()*20000000)),
            manufacturer_name:formData.manufacturer_name,
            phone_number:formattedNumber || '',
            address_line_1:formData.address_1 || '',
            address_line_2:formData.address_2 || '',
            city:formData.city || '',
            state:formData.state ||'',
            zip:formData.zip || '',
            country:formData.country.name || '',
            // url:"www."+formData.url,
			url: formData.url.includes('www.') ? formData.url : "www."+formData.url,
        }

        axios.post('/manufacturers/add', payload).then(response => {
            // response.data.data.new_manufacturer
            if(response.status == 201){
                toast.error(response.data.message)
                return
            }else {
                if(response.data.message == 'URL already exists'){
                  toast.error(response.data.message)
                  return
                }
                manufactureFormReset(defaultValues)
                setFormattedNumber('')

                //Send newly added manufacturer value
                props.handleCloseDialog(response.data.data.new_manufacturer)
                toast.success('Manufacturer added Successfully.')
                props.refreshList()  
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })

    }

    function manufactureFromErrors(error){
        console.log('statusFormErrorData: ',error);
    }


    return (
        <Modal
            open={props.open}
            className={classes.modal}
        >
            <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Add Manufacturer</h1>
                    <IconButton onClick={()=>{
                        manufactureFormReset(defaultValues)
                        props.handleCloseDialog()
                    }}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <form onSubmit={manufactureFormSubmit(manageManufactureData, manufactureFromErrors)}>

                    <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>

                        <div style={{marginBottom: '10px', width: '100%' }}>
                            <Controller
                                name='url' 
                                control={control}
                                render={( { field} ) => (
                                <>
                                    <TextField 
                                        {...field}
                                        InputProps={{
                                            startAdornment: 
                                            <InputAdornment style={{color: "#1D2939",fontSize: "14px",marginRight:"0"}} disableTypography position="start">
                                                www.</InputAdornment>,
                                        }}
                                        variant='outlined'
                                        label='URL' 
                                        autoFocus
                                        fullWidth
                                        required
                                        style={{background: 'white'}}
                                        // error={!!errors.url}
                                        helperText={errors?.url?.message} 
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px'
                                            }
                                        }}
                                    />
                                </>
                                ) }
                            />
                        </div> 

                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='manufacturer_name' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                    {...field}
                                    variant='outlined'
                                    label='Manufacturer' 
                                    fullWidth
                                    required
                                    style={{background: 'white'}}
                                    FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px'
                                        }
                                    }}
                                    // error={!!errors.manufacturer_name}
                                    // helperText={errors?.manufacturer_name?.message} 
                                    />
                                )}
                            />
                        </div>
                        
                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='phone' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        value={formattedNumber}
                                        style={{background: 'white'}}
                                        onKeyPress={(event) => {
                                            if (!/[0-9/-]+/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e)=>{
											changeNumberFormat(e)
										}}
                                        onPaste={(e)=>{e.preventDefault()}}
                                        variant='outlined'
                                        label='Phone Number' 
                                        fullWidth
                                        error={!!errors.phone}
                                        helperText={errors?.phone?.message} 
                                    />
                                )}
                            />
                        </div>

                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='address_1' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='Address Line 1' 
                                        fullWidth 
                                        style={{background: 'white'}}
                                    />
                                ) }
                            />
                        </div>

                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='address_2' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='Address Line 2' 
                                        fullWidth
                                        style={{background: 'white'}}
                                    />
                                )}
                            />
                        </div>  
                        
                        <div className='flex justify-between'>
                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='city' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='City' 
                                        fullWidth
                                        style={{background: 'white'}}
                                    />
                                )}
                            />
                        </div> 

                        <div style={{marginBottom: '10px', width: '100%' }}>
                            <Controller
                                name='state' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='State/Province' 
                                        style={{background: 'white'}}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                        </div>
                        
                        <div className='flex justify-between'>
                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='zip' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='Zip' 
                                        style={{background: 'white'}}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>  
                        
                        <div style={{marginBottom: '10px',  width: '100%' }} className="relative">
                            <Controller
                                name="country"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={countries}
                                            // disablePortal
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">{children}</Paper>
                                            )}
                                            getOptionLabel={option => option.name ?? ''}
                                            renderInput={(params) => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            style={{background: 'white'}}
                                                            {...params}
                                                            label="Country"
                                                            error={!!errors.country}
                                                            helperText={errors?.country?.message}
                                                            FormHelperTextProps={{
                                                                style: {marginLeft : 0}
                                                            }}
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        </div>
                    </div>
                    <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
                        <Button
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                            type='submit' 
                        >
                            Add
                        </Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={()=>{
                                manufactureFormReset(defaultValues)
                                props.handleCloseDialog()
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>

        </Modal>
    )
}

export default ManufacturerDialog
