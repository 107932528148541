import useToast from '@fuse/hooks/useToast'
import { Button, 
  DialogTitle, Divider, Icon,
  IconButton, makeStyles, Paper, TextField, Typography,
  Drawer , InputAdornment
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { Box } from '@mui/material';
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
		},
		loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
		},
		subSection: {
		marginLeft:'20px',
				marginRight:'20px',
				marginBottom:'20px',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
		},
		endButtons: {
		borderRadius: '4px'
		},
		drawerWidth: {
			width: 520,
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			overflowX: 'hidden',
			overflowY: 'hidden'
		},
	}
))

function AddManufactureDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.manufacturersApp.formViewDialog)
	const { data:editFromState } = useSelector(({manufacturersApp}) => manufacturersApp.formViewDialog);
	const [openAlerModal, setOpenAlertModal] = useState(false);
	const [formattedNumber, setFormattedNumber] = useState('')
	//form validation
	const schema = yup.object({
		manufacturer: yup.string().required('Manufacturer is required!').matches(/\S/, 'Manufacturer cannot be only spaces!'),
		url:yup.string().required('URL is required!'),
		// phone:yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
		// }),
	})


	const defaultValues = {
		manufacturer: '',
		url: '',
		phone: '',
		address_1: '',
		address_2: '',
		city: '',
		state: '',
		zip: '',
		country: '',
	}

	const onCancel = () => {
		reset(defaultValues)
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setFormattedNumber('')
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setFormattedNumber('')
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	console.log("ERROR", formState.errors )
	useEffect(()=>{
		if(editFromState){
			setValue('url',editFromState?.url?.slice(4,editFromState?.url.length))
			// setValue('phone',editFromState.original_info.phone_number);
			setFormattedNumber(editFromState.original_info.phone_number ?? '')
			setValue('manufacturer',editFromState.manufacturer_name);
			setValue('address_1',editFromState.original_info.address_line_1);
			setValue('address_2',editFromState.original_info.address_line_2);
			setValue('city',editFromState.original_info.city);
			setValue('state',editFromState.original_info.state);
			setValue('zip',editFromState.original_info.zip);
			setValue('country',{name:editFromState.original_info.country,code:'N/A'});
		}
	},[editFromState])
	function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

	const { errors } = formState

	function editManufacture(payload){
		axios.post('/manufacturers/edit', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				if(response.data.message == 'URL already exists'){
					toast.error(response.data.message)
					return
				}
				reset(defaultValues)
				closeDialog()
				toast.success('Manufacturer updated Successfully.')
				dispatch(setQuickFilter(null))
				if(props.getManufacturers){
					props.getManufacturers()
				}
			}
		}).catch(err => {
			console.log('212=>',err);
			toast.error(err.response.data.message)
		})
	}

	const manageFormData = (formData) => {
		if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
		if(formData.url.indexOf('.') == -1){
			toast.error('Please enter a valid URL')
			return
		}
		let payload = {
			entity_external_platform_id: String(Math.trunc(Math.random()*20000000)),
			manufacturer_name:formData.manufacturer,
			phone_number:formattedNumber || '',
			address_line_1:formData.address_1 || '',
			address_line_2:formData.address_2 || '',
			city:formData.city || '',
			state:formData.state ||'',
			zip:formData.zip || '',
			country:formData.country?.name || '',
			// url:"www."+formData.url,
			url: formData.url.includes('www.') ? formData.url : "www."+formData.url,
		}
		if(editFromState){
			let payload = {
				manufacturer_name:formData.manufacturer,
				phone_number:formattedNumber || '',
				address_line_1:formData.address_1 || '',
				address_line_2:formData.address_2 || '',
				city:formData.city || '',
				state:formData.state ||'',
				zip:formData.zip || '',
				country:formData.country?.name || '',
				// url:"www."+formData.url,
				url: formData.url.includes('www.') ? formData.url : "www."+formData.url,
				edit_id:Number(editFromState.id)
			}
			editManufacture(payload)
		} else {
			axios.post('/manufacturers/add', payload).then(response => {
				if(response.status == 201){
					toast.error(response.data.message)
				} else {
					if(response.data.message == 'URL already exists'){
						toast.error(response.data.message)
						return
					}
					reset(defaultValues)
					closeDialog()
					toast.success('Manufacturer added Successfully.')
					dispatch(setQuickFilter(null))
					if(props.getManufacturers){
						props.getManufacturers()
					}
				}
			}).catch(err => {
				console.log('212=>',err);
				toast.error(err.response.data.message)
			})
		}    
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        onCancel()
    }

	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>  
					<h4 className='heading'>{ Boolean(editFromState) ? 'Update Manufacturer' : 'Add Manufacturer'}</h4>
					<IconButton style={{padding: '0px'}} onClick={ handleAlertModal }>
						<Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
					</IconButton>
				</div>
				<div className='progressbar_holder'>
					<div className='progress'>
						&nbsp;
					</div>
				</div>

				<div style={{ paddingTop: '30px' }} className='cstm_wizard_holder'>
					<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder'>
							<div className='content_holder'>
								<div className="text-left">
									<div className='mb-10'>
										<Typography className='status_text'>
											URL
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name='url' 
											control={control}
											render={( { field} ) => (
												<TextField 
													{...field}
													style={{background: 'white'}}
													InputProps={{
														startAdornment: 
														<InputAdornment 
															style={{color: "grey",marginRight:"0px"}} 
															disableTypography position="start"
														>
														www.
														</InputAdornment>,
													}}
													variant='outlined'
													fullWidth
													error={!!errors.url}
													helperText={errors?.url?.message}
													FormHelperTextProps={{
														style: { 
															margin : 0,
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/>
									</div>

									<div className='mb-10'>
										<Typography className='status_text'>
											Manufacturer
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name='manufacturer' 
											control={control}
											render={( { field} ) => (
												<TextField 
													{...field}
													style={{background: 'white'}}
													variant='outlined'
													fullWidth   
													required                     
													error={!!errors.manufacturer}
													helperText={errors?.manufacturer?.message}
													FormHelperTextProps={{
														style: { 
															margin : 0,
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/>
									</div>
									<div className='mb-10'>
										<Typography className='status_text'>
											Phone Number
										</Typography>
										<Controller
											name='phone' 
											control={control}
											render={( { field} ) => (
												<TextField 
													{...field}
													value={formattedNumber}
													style={{background: 'white'}}
													onKeyPress={(event) => {
													if (!/[0-9/-]+/.test(event.key)) {
														event.preventDefault();
													}
													}}
													onChange={(e)=>{
														changeNumberFormat(e)
													}}
													onPaste={(e)=>{
														e.preventDefault();
													}}
													variant='outlined'
													fullWidth
													FormHelperTextProps={{
													style: { 
														margin : 0,
														width: '100%',
														paddingTop: '2px'
													}
													}}
												/>
											)}
										/>
									</div>
									<div className='mb-10'>
										<Typography className='status_text'>
											Address Line 1
										</Typography>
										<Controller
											name='address_1' 
											control={control}
											render={( { field} ) => (
												<TextField 
													{...field}
													style={{background: 'white'}}
													variant='outlined'
													fullWidth
												/>
											)}
										/>
									</div>
									<div className='mb-10'>
										<Typography className='status_text'>
											Address Line 2
										</Typography>
										<Controller
											name='address_2' 
											control={control}
											render={( { field} ) => (
												<TextField 
													{...field}
													style={{background: 'white'}}
													variant='outlined'
													fullWidth
												/>
											)}
										/>
									</div>
									<div className='mb-10'>
										<Typography className='status_text'>
											City
										</Typography>
										<Controller
											name='city' 
											control={control}
											render={( { field} ) => (
												<TextField 
													{...field}
													style={{background: 'white'}}
													variant='outlined'
													fullWidth
												/>
											)}
										/>
									</div>
									<div className='mb-10'>
										<Typography className='status_text'>
											State/Province
										</Typography>
										<Controller
											name='state' 
											control={control}
											render={( { field} ) => (
												<TextField 
													{...field}
													style={{background: 'white'}}
													variant='outlined'
													fullWidth
												/>
											)}
										/>
									</div>
									<div className='mb-10'>
										<Typography className='status_text'>
											Zip
										</Typography>
										<Controller
											name='zip' 
											control={control}
											render={( { field} ) => (
												<TextField 
													{...field}
													style={{background: 'white'}}
													variant='outlined'
													fullWidth
												/>
											)}
										/>
									</div>
									<div className='mb-10'>
										<Typography className='status_text'>
											Country
										</Typography>
										<Controller
											name="country"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={countries}
													onChange={(event, value) => field.onChange(value)}
													PaperComponent={({ children }) => (
														<Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
													)}
													getOptionLabel={option => option.name ?? ''}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	style={{background: 'white'}}
																	FormHelperTextProps={{
																		style: {marginLeft : 0}
																	}}
																	inputRef={ref}
																	variant="outlined"
																	fullWidth
																/>
															</div>
														)
													}}
												/>
											</>
											)}
										/>
									</div>
								</div>
							</div>
							<div className='btn_holder'>
								<Button
									color="inherit"
									onClick={handleAlertModal}
									className='back_btn secondary_btn'
								>
									Cancel
								</Button>
								<Button onClick={handleSubmit(manageFormData)} className='next_btn'>
									Proceed
								</Button>
							</div>
						</div>
					</Box>
				</div>
			</Drawer>
			{openAlerModal &&
                <AlertModal  openAlerModal module="manufacturer" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
		</div>
	)
}

export default AddManufactureDialog
