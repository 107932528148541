import { Button, makeStyles } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        width: 200,
        wordWrap: 'break-word'
    },
    button: {
        marginLeft: 5,
        fontWeight: 'bold',
        cursor: 'pointer'
    }
}))

const TextMinimal = (props) => {
    const {text} = props;
    const [expanded, setExpanded] = useState(false)
    const classes = useStyles();
    if(!text){
        return(<>-</>)
    }
    if(text.length <= 20) return(
        <>{text}</>
    )
    return (
        <div className={classes.root}>
            {
                !expanded ?
                <span>{text.slice(0, 10)}...
                <span
                onClick={(e)=>{e.stopPropagation();setExpanded(true)}}
                className={classes.button}
                >
                    see more
                </span>
                </span>
                :
                <span>
                    {text}
                    <span
                    onClick={(e)=>{e.stopPropagation();setExpanded(false)}}
                    className={classes.button}
                    >
                        see less
                    </span>
                </span>
            }
        </div>
    )
}

export default TextMinimal