import React from 'react'
import {Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import loaderImg from "../icons/Loader.png";

export default function TestConnection({
  open, close, testConnectionFailed, testConnectionIsLoading, onClickReTry
}) {
  const onSubmit = () => {

  }

  return (
    <Dialog
      className="cstm_dialog_modal small text-center"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {testConnectionIsLoading &&
        <>
          <DialogTitle id="alert-dialog-title">
            <div className='heading_holder flex justify-end items-center w-full'>
              <Button className='transparent_icon_btn' onClick={() => close()}>
                <i className="icon ti ti-x" />
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="warning_content_holder">
              <div className='loader_animation_holder'>
                <img src={loaderImg} alt='loader' />
              </div>
              <h2 className="heading">Establishing Connection</h2>
              <p className="sub_content">Attempting to connect to the API. This may take a few seconds.</p>
            </div>
          </DialogContent>
          <div className="action_footer_holder justify-center">
            <Button style={{ maxWidth: '65px' }} className="primary_btn" onClick={() => close()}>
              Close
            </Button>
          </div>
        </>
      }
      {!testConnectionFailed && !testConnectionIsLoading &&
        <>
          <DialogTitle id="alert-dialog-title">
            <div className='heading_holder flex justify-end items-center w-full'>
              <Button className='transparent_icon_btn' onClick={() => close()}>
                <i className="icon ti ti-x" />
                <i className="ti ti-check white" />
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="warning_content_holder green">
              <div className="exclamation_icon_holder green">
                <i className="ti ti-check white" />
              </div>
              <h2 className="heading">Connection successful</h2>
              <p className="sub_content">Your API connection was established successfully. You can now proceed with your requests.</p>
            </div>
          </DialogContent>
          <div className="action_footer_holder justify-center">
            <Button style={{ maxWidth: '65px' }} className="primary_btn" onClick={() => close()}>
              Close
            </Button>
          </div>
        </>
      }
      {testConnectionFailed && !testConnectionIsLoading &&
        <>
          <DialogTitle id="alert-dialog-title">
            <div className='heading_holder flex justify-end items-center w-full'>
              <Button className='transparent_icon_btn' onClick={() => close()}>
                <i className="icon ti ti-x" />
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="warning_content_holder">
              <div className="exclamation_icon_holder red">
                <i className="ti ti-exclamation-mark white" />
              </div>
              <h2 className="heading">Connection Failed</h2>
              <p className="sub_content">Your API connection was failed. Please try again later.</p>
            </div>
          </DialogContent>
          <div className="action_footer_holder justify-center">
            <Button style={{ maxWidth: '65px' }} className="primary_btn" onClick={onClickReTry}>
              Retry
            </Button>
          </div>
        </>
      }
    </Dialog>
  )
}
