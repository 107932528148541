import Page from './index';
import { authRoles } from 'app/auth';
export default {
settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
	},
	// auth: authRoles.user,
	routes: [
		{
			path: '/contract-tags',
			exact: true,
			component: Page.Layout
		},
	]
};