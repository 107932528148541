import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@fuse/components/general/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, AccordionActions } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import useRouter from '@fuse/hooks/useRouter';
import ListView from '../components/listView';
import lodash from "lodash"
import { dateReformatTo_mmm_dd_yyyy, getFormattedDate } from '@fuse/utils/dateOperations'

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ContactsTable(props) {
	const table_name = "SHIPPING_DETAILS_CONTACT_LISTING";
	const classes = useStyles();

	const dispatch = useDispatch();
	const router = useRouter();

	const [data, setData] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [loading, setLoading] = useState(false);

	const { companyId } = props;

	const [contacts, setContacts] = useState([]);

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event);
		setPage(0)
	}

	function getData() {
		setLoading(true);

		// if(!props.viewPermission){
		// 	setLoading(false)
		// 	return;
		// }

		axios.get("/end-user/all/company",{
			params: {
				company_id: companyId,
			}
		}).then((res) => {
			const { data } = res.data;
			setContacts(formatContacts(data.end_users))
		}).catch((err) => {
			console.log(err)
		}).finally(() =>{
			setLoading(false)
		})
	}

	function formatContacts(data){
		return data.map((contact) => {
			return {
				...contact,
				contact_id: contact.entity_external_platform_id,
				user_company: contact.user_company.company_name,
				contact_name: contact.first_name + " " + contact.last_name,
				created_date: getFormattedDate(contact?.created_at) || "",
			}
		})
	}

	const columnHeaders = [
		{
			field: 'contact_name',
			label: 'Contact name',
		},
		{
			field: 'email',
			label: 'Email address',
		},
		{
			field: 'phone',
			label: 'Phone number',
		},
		{
			field: 'address',
			label: 'Street address',
		},
		{
			field: 'city',
			label: 'City',
		},
		{
			field: 'state',
			label: 'State',
		},
		{
			field: 'zip',
			label: 'Zip',
		},
		{
			field: 'country',
			label: 'Country',
		},
		{
			field: 'title',
			label: 'Contact title',
		},
		{
			field: 'sales_lead_name',
			label: 'Sales lead name',
		},
		{
			field: 'created_date',
			label: 'Created date',
		},
	];

	useEffect(() => {
	    getData()
	}, [])

	if (loading) {
		return (<FuseLoading />)
	}
	// else if(!props.viewPermission){
	// 	return (
	// 		<div>
	// 			You don't have the view permission for contacts
	// 		</div>
	// 	)
	// }
	else if (contacts.length <= 0) {
		return (
			<div
				style={{ width: "100%" }}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="w-full flex flex-1 items-center justify-center h-full"
			>
				<div style={{ height: "200px" }}>
					<Typography variant="body2" component="p" color="textSecondary">
						No data found
					</Typography>
				</div>
			</div>
		)
	}else{
		return (
			<div className="w-full flex flex-col">
				<ListView rows={contacts} columns={columnHeaders} onRowClicked={(params) => {
					router.push(`/end-user/detail/${params.node.data.id}`)
				}} table_name = { table_name } />
				<div className="p-10">
					<TablePagination
						className="flex-shrink-0 border-t-1 mt-10"
						component="div"
						count={contacts.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</div>
			</div>
		);
	}

	
}

export default withRouter(ContactsTable)
