import FusePageCardedOne from '@fuse/core/FusePageCardedOne';
import { makeStyles } from '@material-ui/core/styles';
import withReducer from 'app/store/withReducer';
import reducer from '../../../store';
import AssetsDashboardHeader from './header';
import AssetsDashboardList from './tables';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});
function AssetsDashboard(props) {
	const classes = useStyles();
	
	return (
		<FusePageCardedOne
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0',
				topBg:classes.topBg,
				contentCard:classes.contentCard
			}}
			// header={
			// 	<AssetsDashboardHeader />
			// }
			content={
				<div>
					<AssetsDashboardList/>
				</div>
			}
			innerScroll
		/>
	)
	
}
export default withReducer('assetsDashboardApp', reducer)(AssetsDashboard);