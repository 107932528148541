import {useTheme, makeStyles} from '@material-ui/core/styles'
import { useState } from 'react';
import { Grid, Card, CardContent, Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 12,
        height: '100%'
    },
	cardContentBottomPadding10: {
		["&:last-child"]: {paddingBottom: 10}
	},
}))

function InfoCardSmall({items, subTitleCols, label, className}){
    const classes = useStyles();
    const theme = useTheme();
	const [localitems, setLocalitems] = useState(items);
	
	return(
		<Grid item xs={12} sm className={`${className ? className : ''}`}>
			<Card className={`${classes.root}`}>
				<CardContent className={`p-10 pb-0 ${classes.cardContentBottomPadding10}`} style={{height: '100%',display: 'flex', flexDirection: 'column'}}>
					<span style={{flexGrow: 1}}>
					{
						<Grid container>
						{
						Object.entries(localitems).map(([key, value]) => {
							if(subTitleCols.includes(key)){
								return(
									<Grid item xs={12}>
									<span className={`font-semibold`} style={{fontSize: 15}}>{value}</span>
									</Grid>
								)
							}
							return(
								<Grid item xs={12}>
								<span style={{color: theme.palette.action.active}}>{value}</span>
								<br/>
								</Grid>
							)
						})
						}
						</Grid>
					}
					</span>
					{label &&
					<span style={{display: 'flex', alignItems:"center"}}>
						<Icon style={{color: label.icon_color, marginRight:10}}>{label.icon}</Icon>
						<span  style={{color: theme.palette.action.active}}>{label.label}</span>
					</span>
					}
				</CardContent>
			</Card>
		</Grid>
	)
	}

    export default InfoCardSmall