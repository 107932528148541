const initialState = {
	open: false,
	quickAddedTicket: false
}

const actionTypes = {
	QUICK_ADDED_TICKET: "QUICK_ADDED_TICKET",
}


export function quickAddedTicket() {
	return {
		type: actionTypes.QUICK_ADDED_TICKET
	}
}


export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.QUICK_ADDED_TICKET: {
			return {
				...state,
				quickAddedTicket: !state.quickAddedTicket
			}
		}
	  default:
		return state
	}
}