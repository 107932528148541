import { combineReducers } from '@reduxjs/toolkit';
import formViewDialog from './formViewDialogReducer';
import filterParamsReducer from './filterParamsReducer';

const reducer = combineReducers({
	filterParamsReducer,
	formViewDialog
});

export default reducer;
