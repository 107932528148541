import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";
import { formatPrice } from "@fuse/utils/currencyFormat";

const useStyles = makeStyles({
  row: {
    // border: '2px solid black',
    // height: '100%',
    width: "100%",
    marginBottom: "15px",
    display: "flex",
  },
  column: {
    // border: '2px solid black',
    width: "100%",
    textAlign: "left",
  },
});

function PaymentMethodTab({ paymentMethodData }) {
  const classes = useStyles();
  

  return (
    <>
        <div className='cstm_aggridtable_holder'>
            <table>
                <thead>
                    <tr>
                        <th>Value</th>
                        <th>Card Holder Name</th>
                        <th>Card Number</th>
                        <th>Payment Order Number(PO Number)</th>
                        <th>Payment Order Date(PO Date)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{paymentMethodData !== null ? paymentMethodData.value === 'purchaseOrder' ? 'Purchase Order' : 'Credit Card' : "N/A"}</td>
                        <td>{paymentMethodData !== null ? paymentMethodData.card_holder_name !== null ? paymentMethodData.card_holder_name : "N/A" : "N/A"}</td>
                        <td>{paymentMethodData !== null ? paymentMethodData.card_number !== null ? paymentMethodData.card_number : "N/A" : "N/A"}</td>
                        <td>{paymentMethodData !== null ? paymentMethodData.po_number !== null ? paymentMethodData?.po_number : "N/A" : "N/A"}</td>
                        <td>{paymentMethodData !== null ? paymentMethodData.po_date !== null ? dateReformatTo_mmm_dd_yyyy(paymentMethodData.po_date) : "N/A" : "N/A"}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        {/* <div
        className="flex flex-col text-center overflow-auto w-full"
        style={{ height: 250 }}
        >
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography
                        style={{ fontWeight: 600, color: "#848D9E" }}
                        variant="subtitle1"
                    >
                        Value
                    </Typography>
                    <Typography
                        style={{ fontWeight: 400 }}
                        variant="subtitle1"
                    >{paymentMethodData !== null ? paymentMethodData.value === 'purchaseOrder' ? 'Purchase Order' : 'Credit Card' : "N/A"}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography
                        style={{ fontWeight: 600, color: "#848D9E" }}
                        variant="subtitle1"
                    >
                        PO Date
                    </Typography>
                    <Typography
                        style={{ fontWeight: 400 }}
                        variant="subtitle1"
                    >{paymentMethodData !== null ? paymentMethodData.po_date !== null ? dateReformatTo_mmm_dd_yyyy(paymentMethodData.po_date) : "N/A" : "N/A"}</Typography>
                </div>
                <div className={classes.column}>
                <Typography
                    style={{ fontWeight: 600, color: "#848D9E" }}
                    variant="subtitle1"
                >
                    PO Number
                </Typography>
                <Typography style={{ fontWeight: 400 }} variant="subtitle1">
                    {paymentMethodData !== null ? paymentMethodData.po_number !== null ? paymentMethodData?.po_number : "N/A" : "N/A"}
                </Typography>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                <Typography
                    style={{ fontWeight: 600, color: "#848D9E" }}
                    variant="subtitle1"
                >
                    Card Holder Name
                </Typography>
                <Typography style={{ fontWeight: 400 }} variant="subtitle1">
                    {paymentMethodData !== null ? paymentMethodData.card_holder_name !== null ? paymentMethodData.card_holder_name : "N/A" : "N/A"}
                </Typography>
                </div>
                <div className={classes.column}>
                <Typography
                    style={{ fontWeight: 600, color: "#848D9E" }}
                    variant="subtitle1"
                >
                    Card Number
                </Typography>
                <Typography style={{ fontWeight: 400 }} variant="subtitle1">
                    {paymentMethodData !== null ? paymentMethodData.card_number !== null ? paymentMethodData.card_number : "N/A" : "N/A"}
                </Typography>
                </div>
            </div>
        </div> */}
    </>
  );
}

export default withRouter(PaymentMethodTab);
