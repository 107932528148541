import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { Button } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import QuickAddAsset from './QuickAddAsset'
import QuickAddContract from './QuickAddContract';
import QuickAddTicket from './QuickAddTicket';
import QuickAddUser from './QuickAddUser';
import QuickAddSubscription from './QuickAddSubscription'
import { usePermission } from '@fuse/hooks';
import { useDispatch, useSelector } from 'react-redux';
import AddServiceRequestFormDialog from 'app/main/service-request/components/addServiceRequestDialog';
import { openFormViewerDialog, closeFormViewerDialog } from 'app/main/service-request/store/formViewDialogReducer';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 170,
        // minHeight: 240,
        maxHeight:240,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
const QuickActionTable = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [ openAssets, setOpenAssets] = useState(false)
    const [ openContract, setOpenContract ] = useState(false);
    const [ openTicket, setOpenTicket ] = useState(false);
    const [ openUser, setOpenUser ] = useState(false)
    const [ openSubscription, setOpenSubscription ] = useState(false)
    const { hasPermission } = usePermission()
    const addUserPermission = hasPermission('add-end-user', true)
	const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)
	const dispatch = useDispatch()

    const handleClickForOpenModelSidebar = (moduleName) => {
        switch(moduleName) {
            case 'asset':
                setOpenAssets(true)
                handleClose()
                break;
            case 'contract':
                setOpenContract(true)
                handleClose()
                break;
            case 'ticket':
                setOpenTicket(true);
                handleClose()
                break;
            case 'user':
                setOpenUser(true)
                handleClose()
                break;
            case 'subscription':
                setOpenSubscription(true)
                handleClose()
                break;
            default:
              break;
        }
    }
	const close = (moduleName) => {
        switch(moduleName) {
            case 'asset':
                setOpenAssets(false)
                break;
            case 'contract':
                setOpenContract(false)
                break;
            case 'ticket':
                setOpenTicket(false);
                break;
            case 'user':
                setOpenUser(false)
                break;
            case 'subscription':
                setOpenSubscription(false)
                break;
            default:
              break;
        }
		
	}

    return (
        <>
        <div className='p-4'>
            <Button
                className='quick_button'
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
            >
                Quick Action
                <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_2228_3664)">
                            <path d="M4 6L8 10L12 6" fill="white" />
                            <path d="M4 6L8 10L12 6" stroke="#562658" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_2228_3664">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg></span>
            </Button>
            
                <StyledMenu
                    // className="quick_dropdown"
                    id="demo-customized-menu quick_dropdown"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleClickForOpenModelSidebar('asset')} disableRipple className='assets_items'>
                        {/* <EditIcon /> */}
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2228_901)">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 12H15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 9V15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2228_901">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <span style={{ marginLeft: '4px', fontSize: '12px', fontWeight: '500', lineHeight: '16px', color: '#1D2939', fontFamily: '"Inter", sans-serif' }}>Add Assets</span>
                    </MenuItem>
                    <MenuItem onClick={() => handleClickForOpenModelSidebar('contract')} disableRipple className='assets_items'>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2228_901)">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 12H15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 9V15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2228_901">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <span style={{ marginLeft: '4px', fontSize: '12px', fontWeight: '500', lineHeight: '16px', color: '#1D2939', fontFamily: '"Inter", sans-serif' }}>Add Contracts</span>
                    </MenuItem>
                    <MenuItem onClick={() => handleClickForOpenModelSidebar('ticket')} disableRipple className='assets_items'>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2228_901)">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 12H15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 9V15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2228_901">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <span style={{ marginLeft: '4px', fontSize: '12px', fontWeight: '500', lineHeight: '16px', color: '#1D2939', fontFamily: '"Inter", sans-serif' }}>Add Service Ticket</span>
                    </MenuItem>
                    {/* <MenuItem onClick={() => handleClickForOpenModelSidebar('subscription')} disableRipple className='assets_items'>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2228_901)">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 12H15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 9V15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2228_901">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <span style={{ marginLeft: '4px', fontSize: '12px', fontWeight: '500', lineHeight: '16px', color: '#1D2939', fontFamily: '"Inter", sans-serif' }}>Add Subscription</span>
                    </MenuItem> */}
                    {/* <MenuItem onClick={() => handleClickForOpenModelSidebar('ticket')} disableRipple className='assets_items'>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2228_901)">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 12H15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 9V15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2228_901">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <span style={{ marginLeft: '4px', fontSize: '12px', fontWeight: '500', lineHeight: '16px', color: '#1D2939', fontFamily: '"Inter", sans-serif' }}>Create a Ticket</span>
                    </MenuItem> */}
                    {(addUserPermission.length !=0) && <MenuItem onClick={() => handleClickForOpenModelSidebar('user')} disableRipple className='assets_items'>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2228_901)">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 12H15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 9V15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2228_901">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <span style={{ marginLeft: '4px', fontSize: '12px', fontWeight: '500', lineHeight: '16px', color: '#1D2939', fontFamily: '"Inter", sans-serif' }}>Create a User</span>
                    </MenuItem>}
                </StyledMenu>
            </div>
            {
                openAssets && <QuickAddAsset open={openAssets} close={close} />
            }
            {
                openContract && <QuickAddContract open={openContract} close={close} />
            }
            {
                openTicket && <AddServiceRequestFormDialog open={openTicket} close={close} />
            }
            {
                openUser && <QuickAddUser open={openUser} close={close}/>
            }
            {
                openSubscription && <QuickAddSubscription open={openSubscription} close={close}/>
            }
            
        </>
    );
};

export default QuickActionTable;
