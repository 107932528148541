import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import FuseLoading from '@fuse/core/FuseLoading';
import ContractsTab from './Tabs/Contracts';
import QuotesTab from './Tabs/Quotes';
import ContactsTab from './Tabs/Contacts';
import AssetsTab from './Tabs/Assets';
import { usePageTitle } from '@fuse/hooks';

const drawerWidth = 400;
const headerHeight = 200;

const views = ['List View', 'Grid View'];
const defaultCounts = { end_users: 0, locations: 0 };
const defaultSearches = { location: '', quote_indicator: '', quote_status: '', revenue: '', action: '' }

const useStyles = makeStyles(theme => ({
    '@global': {
        '#fuse-main': {
            height: '100vh'
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100%',
        position: 'relative',
        // flex: '1 1 auto',
        height: 'auto',
        backgroundColor: theme.palette.background.default
    },
    cardGen: {
        width: '100%',
    },
    topBg: {
        // backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
        // backgroundColor: theme.palette.primary.dark,
        backgroundSize: 'cover',
        pointerEvents: 'none',
        padding: "10px"
    },
    topBgFixed: {
        height: headerHeight,
        backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
        backgroundColor: theme.palette.primary.dark,
        backgroundSize: 'cover',
        pointerEvents: 'none'
    },
    contentCardWrapper: {
        maxWidth: 1400,
        display: 'flex',
        flexDirection: 'row',
        // flex: '1 0 auto',
        width: '100%',
        minWidth: '0',
        margin: '0 auto',
        // [theme.breakpoints.down('sm')]: {
        // 	padding: '16px 16px 0 16px'
        // },
        // [theme.breakpoints.down('xs')]: {
        // 	padding: '12px 12px 0 12px'
        // }
    },
    contentCard: {
        display: 'flex',
        position: 'relative',
        // flex: '1 1 100%',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.paper,
        minHeight: 0,
        overflow: 'hidden',
        borderRadius: '2px 2px 0 0',
    },
    drawerPaper: {
        width: drawerWidth,
        maxWidth: '100%',
        overflow: 'hidden',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            position: 'relative'
        }
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        // flex: '1 1 100%',
        zIndex: 10,
        background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
            theme.palette.background.paper,
            0.6
        )} 20%,${fade(theme.palette.background.paper, 0.8)})`
    },
    content: {
        display: 'flex',
        // flex: '1 1 100%',
        minHeight: 0
    },
    avatar: {
        backgroundColor: red[500],
    },
    formControl: {
    },
    pullRight: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    formControlMinWidth: {
        minWidth: 200,
    },
    buttonSquare: {
        borderRadius: '5px'
    },
    card: {
        borderRadius: "12px"
    },
    cardContainer: {
    },
    borderRightLight: {
        borderRight: "1px solid rgba(0,0,0,0.12)"
    },
    buttonSquare: {
        borderRadius: '5px'
    },
    activeTab: {
        borderBottom: "2px solid",
        borderBottomLeftRadius: "0px !important",
        borderBottomRightRadius: "0px !important",
    },
}));

function currencyFormat(number){
    return isNaN(Number(number)) ? number : Number(number).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    }); 
}

function TabButton(props) {
    const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
    const isActive = tab == tabState;
    return (
        <Button {...restProps} onClick={() => tabStateChange(tab)} className={restProps.className && isActive ? activeClass : ""}>
            {props.children}
        </Button>
    )
}

function TabContainer(props) {
    const { tabState, tab } = props;
    const shouldRender = tab == tabState;
    return shouldRender ? props.children : <></>
}

function Main(props) {
    const dispatch = useDispatch();
    const [counts, setCounts] = useState(defaultCounts);
    const [searches, setSearches] = useState(defaultSearches);
    const classes = useStyles(props);
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [shippingDetails, setShippingDetails] = useState(null);
    // const { hasPermission } = usePermission();

    // const viewContactPermission = hasPermission('view-end-user');
    // const viewContractPermission = hasPermission('view-contract');
    // const viewQuotePermission = hasPermission('view-quote');
    // const viewAssetPermission = hasPermission('view-assets');

    const [tabState, setTabState] = useState("contracts");
    const updatePageTitle = usePageTitle(dispatch);

    function formatString(str){
        if(!str) return ''
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

    useEffect(() => {
        updatePageTitle("Location Profile");
        const id = router.params.id;
        setLoading(true);
        axios.get(`/shipping/fetch/${id}`, {
            params: {
                total_counts: true,
            },
        }).then(res => {
            const { data } = res.data;
            const { shipping_location, total_counts } = data
            setShippingDetails(shipping_location)
            setCounts(total_counts)
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    if (loading) {
        return (
            <FuseLoading />
        )
    }

    return (<>
        <div className={clsx(classes.root)}>
            <FuseScrollbars className="flex-grow">
                {shippingDetails?.id &&
                    <Grid container spacing={1} style={{ padding: 14 }}>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Card className={classes.card} style={{ height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <h2 className="mt-10" style={{ fontWeight: "bold" }}>
                                        {formatString(shippingDetails?.location_name)}
                                    </h2>

                                    <p className="mt-10">{shippingDetails?.user_company.company_name}</p>

                                    <div className="mt-10" style={{ display: "flex" }}>
                                        <div><Icon fontSize="small">location_on_outlined</Icon></div>
                                        <div>
                                            <p>{String([`${shippingDetails?.address1}, ${shippingDetails?.address2 ? shippingDetails?.address2 : ''}`].filter(Boolean))}</p>
                                            <p>{String([`${shippingDetails?.city ? shippingDetails?.city : ''}, ${shippingDetails?.state ? shippingDetails?.state : ''}${shippingDetails?.zip ? '-'+shippingDetails?.zip : ''}, ${shippingDetails?.country ? shippingDetails?.country : ''}`].filter(Boolean))}</p>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={9} md={9} sm={9} xs={9}>
                            <Grid container spacing={1}>
                                <Grid item lg={3}>
                                    <Card className={classes.card} style={{ height: "100%" }}>
                                        <CardContent className={classes.cardContainer}>
                                            <div style={{ display: "flex" }}>
                                                <div className="py-10 pr-10" style={{ display: "flex" }}>
                                                    <Icon className="text-green" fontSize="large">event_note</Icon>
                                                </div>
                                                <div className="py-10">
                                                    <h3 style={{ fontWeight: "bold" }}>No. of Active Contracts</h3>
                                                    <h4 className='mt-10'>{counts.active_contracts || 0}</h4>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={3}>
                                    <Card className={classes.card} style={{ height: "100%" }}>
                                        <CardContent className={classes.cardContainer}>
                                            <div style={{ display: "flex" }}>
                                                <div className="py-10 pr-10" style={{ display: "flex" }}>
                                                    <Icon className="text-blue" fontSize="large">event_note</Icon>
                                                </div>
                                                <div className="py-10">
                                                    <h3 style={{ fontWeight: "bold" }}>No. of Expiring Contracts</h3>
                                                    <h4 className='mt-10'>{counts.expiring_contracts || 0}</h4>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={3}>
                                    <Card className={classes.card} style={{ height: "100%" }}>
                                        <CardContent className={classes.cardContainer}>
                                            <div style={{ display: "flex" }}>
                                                <div className="py-10 pr-10" style={{ display: "flex" }}>
                                                    <Icon className="text-orange" fontSize="large">event_note</Icon>
                                                </div>
                                                <div className="py-10">
                                                    <h3 style={{ fontWeight: "bold" }}>Contracts</h3>
                                                    <h4 className='mt-10'>{counts.contracts || 0}</h4>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={3}>
                                    <Card className={classes.card} style={{ height: "100%" }}>
                                        <CardContent className={classes.cardContainer}>
                                            <div style={{ display: "flex" }}>
                                                <div className="py-10 pr-10" style={{ display: "flex" }}>
                                                    <Icon className="text-red" fontSize="large">event_note</Icon>
                                                </div>
                                                <div className="py-10">
                                                    <h3 style={{ fontWeight: "bold" }}>Renewal rate</h3>
                                                    <h4 className='mt-10'>{counts.renewal_rate || 0}%</h4>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={3}>
                                    <Card className={classes.card} style={{ height: "100%" }}>
                                        <CardContent className={classes.cardContainer}>
                                            <div style={{ display: "flex" }}>
                                                <div className="py-10 pr-10" style={{ display: "flex" }}>
                                                    <Icon className="text-blue" fontSize="large">attach_money</Icon>
                                                </div>
                                                <div className="py-10">
                                                    <h3 style={{ fontWeight: "bold" }}>Actual revenue</h3>
                                                    <h4 className='mt-10'>{currencyFormat(counts.actual_revenue || 0)}</h4>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={3}>
                                    <Card className={classes.card} style={{ height: "100%" }}>
                                        <CardContent className={classes.cardContainer}>
                                            <div style={{ display: "flex" }}>
                                                <div className="py-10 pr-10" style={{ display: "flex" }}>
                                                    <Icon className="text-green" fontSize="large">attach_money</Icon>
                                                </div>
                                                <div className="py-10">
                                                    <h3 style={{ fontWeight: "bold" }}>Forecasted Revenue</h3>
                                                    <h4 className='mt-10'>{currencyFormat(counts.forecasted_revenue || 0)}</h4>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={3}>
                                    <Card className={classes.card} style={{ height: "100%" }}>
                                        <CardContent className={classes.cardContainer}>
                                            <div style={{ display: "flex" }}>
                                                <div className="py-10 pr-10" style={{ display: "flex" }}>
                                                    <Icon className="text-red" fontSize="large">location_on_outlined</Icon>
                                                </div>
                                                <div className="py-10">
                                                    <h3 style={{ fontWeight: "bold" }}>Assets</h3>
                                                    <h4 className='mt-10'>{counts.assets || 0}</h4>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={3}>
                                    <Card className={classes.card} style={{ height: "100%" }}>
                                        <CardContent className={classes.cardContainer}>
                                            <div style={{ display: "flex" }}>
                                                <div className="py-10 pr-10" style={{ display: "flex" }}>
                                                    <Icon fontSize="large">supervisor_account</Icon>
                                                </div>
                                                <div className="py-10">
                                                    <h3 style={{ fontWeight: "bold" }}>No. of end users</h3>
                                                    <h4 className='mt-10'>{counts.end_users || 0}</h4>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Card className={classes.card} style={{ margin: 14 }}>
                    <CardActions disableSpacing>
                        <TabButton
                            tab="quotes"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '120px' }}>
                            Quotes
                        </TabButton>
                        <TabButton
                            tab="contracts"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '120px' }}>
                            Contracts
                        </TabButton>
                        <TabButton
                            tab="assets"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '150px' }}
                            onClick={e => gotoContacts(e)}>
                            Assets
                        </TabButton>
                        <TabButton
                            tab="contacts"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '150px' }}>
                            End-users
                        </TabButton>
                        <TabButton
                            tab="system_information"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '150px' }}>
                            System information
                        </TabButton>
                    </CardActions>
                </Card>

                {shippingDetails?.id && (
                    <Card className={classes.card} style={{ margin: 14 }}>
                        <CardContent className={classes.cardContainer}>
                            <TabContainer tab="contacts" tabState={tabState}>
                                <ContactsTab shippingId={shippingDetails?.id}  />
                            </TabContainer>
                            <TabContainer tab="quotes" tabState={tabState}>
                                <QuotesTab shippingId={shippingDetails?.id} />
                            </TabContainer>
                            <TabContainer tab="contracts" tabState={tabState}>
                                <ContractsTab shippingId={shippingDetails?.id} />
                            </TabContainer>
                            <TabContainer tab="assets" tabState={tabState}>
                                <AssetsTab shippingId={shippingDetails?.id} />
                            </TabContainer>
                            <TabContainer tab="system_information" tabState={tabState}>
                                System information
                            </TabContainer>
                        </CardContent>
                    </Card>
                )}
            </FuseScrollbars>
        </div>
    </>);
}

function ArrowButton({ onClick, label }) {
    return (
        <span
            onClick={() => onClick}
            className="arrow-pointer">
            {label}
        </span>
    );
}

export default Main;
