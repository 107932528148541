import _ from '@lodash';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	  },
	  root: {
		// minWidth: 275,
	  },
	  title: {
		fontSize: 14,
		marginBottom: 10,
	  },
	  pos: {
		marginBottom: 12,
	  },
});

function GridView(props) {
    const classes = useStyles();

	function getFormatedHeaderName(header){
		return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
	}

	return (
		<Grid container spacing={3}  className="mb-3">{	
			props.endUsersAccounts.map((acc, index) =>(
				<Grid item xs={"auto"} key={index + 1}>
					<Card className={classes.root} variant="outlined">
						<CardContent>
							<Typography className={classes.title} gutterBottom>
								{acc.line_item_id}
							</Typography>

							{Object.entries(acc).map(([column, value], index) => (
								!["id", "line_item_id"].includes(column) && (
									<Typography key={index} gutterBottom>
										{getFormatedHeaderName(column)}: {value}
									</Typography>
								)
							))}
						</CardContent>
					</Card>
				</Grid>
			))
		}</Grid>
	);
}

export default GridView
