import { Dialog, DialogTitle, DialogContent, DialogContentText, MenuItem, DialogActions, Grid, Button, Icon, IconButton, Box, TextField,CircularProgress,TextareaAutosize } from '@material-ui/core';
import { useState,useEffect,useDispatch } from 'react';
import { openDialog, closeDialog } from '../../../store/fuse/dialogSlice';
import useToast from '@fuse/hooks/useToast';
import axios from "@fuse/utils/axios";
import { useTheme } from '@material-ui/core/styles';
import {actions} from '../shared/constants';

function InnerView(props){
    const toast = useToast(props.dispatch);
    const [loading, setLoading] = useState(false);
    const [selectedAction, setSelectedAction] = useState(props.action);
    const [actionReason, setActionReason] = useState('');
    const [comment, setComment] = useState('');

    function handleMakeRequest(){
        if(selectedAction == ''){
            toast.error('Please select an action');
            return
        }

        if(props.action_reasons && actionReason === ''){
            toast.error('Please select a reason for your action');
            return
        }

        if(comment == ''){
            toast.error('Please brief the reason for your action');
            return
        }

        if(props.onlyCallback){
            props.callback({comment, action_reason: actionReason});
            props.closeDialogView();
        }else{
            setLoading(true)
            
            axios.post('/quotes/change-request',{
                quote_id: props.quote.id,
                action_type: props.action_type,
                action: selectedAction,
                comment,
                action_reason: actionReason
            })
            .then(res => {
                if(res.data.statusCode == 200){
                    props.closeDialogView();
                }else{
                    toast.err(res.data.message)
                }
            })
            .catch(err => console.log(err))
            .finally(()=>{
                setLoading(false)
                props.callback();
                props.closeDialogView();
            })
        }
    }

    function resetState(){
        setSelectedAction('');
        setComment('');
    }

    if(loading){
        return(
            <Box display="flex" alignItems="center" justifyContent="center" justifyItems="center" style={{padding: 20}}>
                <CircularProgress color="primary"/>
            </Box>
        )
    }

    return(
        <>
            <DialogTitle onClose={props.closeMergeView} className={`mb-0 pb-0`}>
                {
                    
                    <Spreaded left={`Are you sure you?`} right={
                    <IconButton onClick={props.closeDialogView}>
                        <Icon>close</Icon>
                    </IconButton>} />
                }
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span style={{fontWeight:'bold'}}>{props.message}</span>
                    </Grid>
                    {
                        props.action_reasons &&
                        <Grid item xs={12}>
                            <TextField
                                select
                                value={actionReason}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                rowsMax={Infinity}
                                style={{width:'100%'}}
                                label="Select reason"
                            >
                                {
                                    props.action_reasons.map(reason => (
                                        <MenuItem onClick={()=>setActionReason(reason)} value={reason}>{reason}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    }
                    <Grid item xs={12}>
                    <TextField
                        value={comment}
                        fullWidth
                        variant="outlined"
                        onChange={(e)=>setComment(e.target.value)}
                        multiline
                        rowsMax={Infinity}
                        style={{width:'100%'}}
                        label="Brief the reason"
                    />
                    </Grid>
                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button
                variant="contained"
                color="primary"
                size="small"
                style={{borderRadius:5}}
                onClick={handleMakeRequest}
                >
                    Submit and {props.action_name}
                </Button>
            </DialogActions>
        </>
    )
}

function Spreaded({left, right}) {
    return(
        <Box display="flex" alignItems="center">
            <Box flexGrow={1} >{left}</Box>
            <Box>{right}</Box>
        </Box>
    )
}

function EnduserGenericQuoteActionView(props, dispatch) {
    props.closeDialogView = ()=>{dispatch(closeDialog())};
    props.dispatch = dispatch;
    dispatch(openDialog({children: (
		<InnerView {...props} />
		),
		maxWidth: 'sm',
		fullWidth:true}))
}

export default EnduserGenericQuoteActionView;