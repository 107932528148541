import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import {TextField,SvgIcon} from '@material-ui/core';
import { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import useToast from '@fuse/hooks/useToast'
import { openCsvDialog } from '../store/csvDialogReducer';
import { usePermission } from '@fuse/hooks';
import { UploadIcon } from '@fuse/icons';
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	const [currentFilter, setCurrentFilter] = useState('');
	const [currentFilterLabel, setCurrentFilterLabel] = useState('');
	const { hasPermission } = usePermission()
	const createContractPermission = hasPermission('add-contract')
	const updateContractPermission = hasPermission('update-contract')
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const addReportPermission = hasPermission('add-report')

	function handleSearchReset(){
		props.handleSearchReset();
		props.handleSearchSuggestions("", currentFilter)
		setSearchQuery("");
	}

	useEffect(() => {
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilter(props.searchFilters[0].field)
		}
	},[props.searchFilters])

	useEffect(() => {
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilterLabel(props.searchFilters.find((item) => item.field == currentFilter)?.label || "");
		}
	},[currentFilter]);

	useEffect(() => {
		props.onSearchChange(search, currentFilter)
	},[search, currentFilter])

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M3 18H21V6H3V18ZM1 5C1 4.44772 1.44772 4 2 4H22C22.5523 4 23 4.44772 23 5V19C23 19.5523 22.5523 20 22 20H2C1.44772 20 1 19.5523 1 19V5ZM9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11C8.55228 11 9 10.5523 9 10ZM11 10C11 11.6569 9.65685 13 8 13C6.34315 13 5 11.6569 5 10C5 8.34315 6.34315 7 8 7C9.65685 7 11 8.34315 11 10ZM8.0018 16C7.03503 16 6.1614 16.3907 5.52693 17.0251L4.11272 15.6109C5.10693 14.6167 6.4833 14 8.0018 14C9.52031 14 10.8967 14.6167 11.8909 15.6109L10.4767 17.0251C9.84221 16.3907 8.96858 16 8.0018 16ZM16.2071 14.7071L20.2071 10.7071L18.7929 9.29289L15.5 12.5858L13.7071 10.7929L12.2929 12.2071L14.7929 14.7071L15.5 15.4142L16.2071 14.7071Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{color:'black'}}
				>
					Contracts List
				</Typography>
			</div>
			<div style={{display:'flex'}}>

				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}

				{createContractPermission && updateContractPermission && <div className={classes.buttonContainer}>
					<Button
					onClick={() => dispatch(openCsvDialog())}
					variant="contained"
					color="secondary"
					className={`w-full ${classes.buttonSquare}`}
					style={{width:'150px',marginRight: '10px'}}
					startIcon={
						<SvgIcon fontSize="small"><UploadIcon color="default" /></SvgIcon>
					}>
						Upload CSV
					</Button>
				</div>}

				{createContractPermission && updateContractPermission && <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px', marginRight: '10px'}}
						onClick = {()=>dispatch(openFormViewerDialog())}
						startIcon={
						<Icon fontSize="small" title="Add Contract">
							add
						</Icon>}>
						Add Contract
					</Button>
				</div>}

				{/* <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight:"10px"}}
						onClick = {()=>dispatch(openReportViewerDialog())}
						startIcon={
						<Icon fontSize="large" title="Save View">
							save
						</Icon>}>
							Save View
					</Button>
				</div> */}

				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px", height: '34px'}}
						>
							<TextField
								placeholder="Search Contracts"
								className="flex flex-1 mx-8"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>

			</div>

			{/* <div className={`flex flex-1 items-center justify-end ${classes.noPaddingRight}`}>
				<ThemeProvider theme={mainTheme}>
				<Paper
					component={motion.div}
					initial={{ y: -20, opacity: 0 }}
					animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
					className={`flex items-center mr-10 max-w-512 shadow ${classes.buttonSquare}`}
				>
					<FormControl>
						<Select
						id="demo-simple-select-filled"
						style={{minWidth: "150px"}}
						label="Filter"
						value={currentFilter}
						className="pl-10"
						onChange={e => { 
							setCurrentFilter(e.target.value)
							setSearchQuery("")
						}}
						MenuProps={{
				            anchorOrigin: {
				              vertical: "bottom",
				              horizontal: "center"
				            },
				            transformOrigin: {
				              vertical: "top",
				              horizontal: "center"
				            },
				            getContentAnchorEl: null
          				}}

						>
							{props.searchFilters.map(({field, label}, ind) => {
								return(
								  <MenuItem key = {field} value={field}>{label}</MenuItem>
								)
							}
						)}
						</Select>
					</FormControl>
					</Paper>
					<Paper
						component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center max-w-512 shadow ${classes.buttonSquare}`}
						style={{minWidth:"400px"}}
					>
						<Autocomplete
							id="assets-search-box"
							freeSolo
							autoSelect
							autoComplete
							value={""}
							options={props.searchSuggestions}
							style={{width: "100%"}}
							getOptionLabel={option => option}
							noOptionsText=""
							onInputChange={event => {
								setSearchQuery(event.target.value || "")
								props.handleSearchSuggestions(event.target.value, currentFilter)
							}}
							onChange={(event, value) => {
								let query = value

								setSearchQuery(query)
							}}
							renderInput={(params) => (
							    <div ref={params.InputProps.ref}>
									<TextField
										{...params.inputProps}
										placeholder={`Search ${currentFilterLabel}`}
										className="flex flex-1 mx-8"
										// disableUnderline
										fullWidth
										value={search}
										InputProps={{
											disableUnderline: true
										}}
										onKeyDown={e => {
											if (e.key === 13 && search) {
												props.handleSearch(search, currentFilter)
											}
										}}
									/>
								</div>
							)}
							/>
						{Boolean(search && search.length) && 
							<IconButton
							aria-label="toggle password visibility"
							onClick={() => {props.handleSearch(search, currentFilter)}}
							size="small"
							className="mr-10">
								<Icon>search</Icon>
							</IconButton>
						}
					</Paper>
				</ThemeProvider>
				
				<div className={`pl-10 `} style={{width:"90px"}}>
					<Button
						variant="contained"
						color="secondary"
						size="small"
						onClick={handleSearchReset}
						className={`w-full ${classes.buttonSquare}`}>
							Reset
					</Button>
				</div>
			</div> */}
		</div>
	)
}

export default DynamicFieldsHeader;
