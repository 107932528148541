import React, {useEffect,useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';


const useStyles = makeStyles({

    root:{
      // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
      //   border: '2px solid black',
      //   display: 'none !important'
      // }
      '& .MuiDataGrid-columnsContainer':{
        background: '#8f8f8f26'
      }
    }
});

import { withRouter } from 'react-router-dom';
import axios from '@fuse/utils/axios';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { formatString } from '@fuse/utils/stringOperations';
import { Tooltip } from '@material-ui/core';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const columns = [
    { field: 'id', headerName: 'ID', width: 100 },

    { field: 'date', headerName: 'Date', width: 200},

    { field: 'action', headerName: 'Action',width: 100,
        renderCell: (params) =>{
            if(params.value === "CREATED") return (<span style={{color: "green",fontWeight: 'bold'}}>{params.value}</span>)
            else if(params.value === "UPDATED") return (<span style={{color: "orange",fontWeight: 'bold'}}>{params.value}</span>)
            else if(params.value === "DELETED" || params.value === "ARCHIVED") return (<span style={{color: "red",fontWeight: 'bold'}}>{params.value}</span>)
            else return (<span style={{color: "blue",fontWeight: 'bold'}}>{params.value}</span>)
        }
    },

    { field: 'field_name', headerName: 'Field Name',width: 200},

    { field: 'previous_value', headerName: 'Previous Value',width: 200,
        renderCell: (params) => {
            if(params.value?.length > 19){
                return(
                    <Tooltip title={params.value} placement="top-start">
                        <span>{`${params.value?.slice(0,20)}...`}</span>
                    </Tooltip>
                )
            }else{
                return (<span>{params.value}</span>)
            }
        }
    },

    { field: 'current_value', headerName: 'Current Value',width: 200,
        renderCell: (params) => {
            if(params.value?.length > 19){
                return(
                    <Tooltip title={params.value} placement="top-start">
                        <span>{`${params.value?.slice(0,20)}...`}</span>
                    </Tooltip>
                )
            }else{
                return (<span>{params.value}</span>)
            }
        }
    },

    { field: 'name', headerName: 'Name', width: 200},

    { field: 'role', headerName: 'Role',width: 200},

    { field: 'description', headerName: 'Description',width: 400,
        renderCell: (params) => {
            if(params.value?.length > 50){
                return(
                    <Tooltip title={params.value} placement="top-start">
                        <span>{`${params.value?.slice(0,51)}...`}</span>
                    </Tooltip>
                )
            }else{
                return (<span>{params.value}</span>)
            }
        }
    },
];


function Activities(props) {

    const classes = useStyles();

    const actionConst = {
        create: 'CREATED',
        update: 'UPDATED',
        archive: 'ARCHIVED',
        restore: 'RESTORED',
        delete: 'DELETE',
        set_master: 'SET MASTER',
        upload_csv_create: 'CSV UPLOAD',
        upload_csv_update: 'CSV UPLOAD'
    }

    const createDescription = (method, data) => {
        let descriptionText = ''

        switch(method){
            case 'create': {
                descriptionText = `${data.first_name} ${data.last_name} created this contract`
                break
            }

            case 'update': {
                descriptionText = `${data.first_name} ${data.last_name} updated ${formatString(data.field_name)} from ${formatDateValues(data.field_name, data.previous_value)} to ${formatDateValues(data.field_name, data.current_value)}`
                break
            }

            case 'delete': {
                descriptionText = `${data.first_name} ${data.last_name} delete this contract`
                break
            }

            case 'archive': {
                descriptionText = `${data.first_name} ${data.last_name} archived this contract`
                break
            }

            case 'restore': {
                descriptionText = `${data.first_name} ${data.last_name} restored this contract`
                break
            }

            case 'set_master': {
                descriptionText = `${data.first_name} ${data.last_name} set this contract as Master for contract ${data.current_value}`
                break
            }

            case 'upload_csv_create': {
                descriptionText = `${data.first_name} ${data.last_name} created this contract`
                break
            }

            case 'upload_csv_update': {
                descriptionText = `${data.first_name} ${data.last_name} updated this contract`
                break
            }

            default: {
                descriptionText = ''
                break
            }
        }

        return descriptionText
    }

    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const [logs, setLogs] = useState([])

    const formatDateValues = (field_name, value) => {
        if(!value) return '-'

        const dateFields = ['contract_start_date', 'contract_end_date', 'po_date']
        
        if(dateFields.includes(field_name)) {
            if(field_name === 'po_date') return dateReformatTo_mmm_dd_yyyy(value)
            else return dateReformatTo_mmm_dd_yyyy(new Date(value).toISOString())
        }else{
            return value
        }
    }

    const flattenLogs = (data) => {
        if(!data || data == []) return []
data.map(item => {
    console.log('164=>',item.action_name)
    console.log('165=>',actionConst[item.action_name])
})
        return data.map(item => ({
            id: item.entity_external_platform_id || '',
            date: dateReformatTo_mmm_dd_yyyy(item.created_at),
            name: `${item.user_companies_user?.first_name} ${item.user_companies_user?.last_name}`,
            role: item.user_companies_user?.roles_end_user.display_name,
            action: actionConst[item.action_name],
            field_name: formatString(item?.field_name) || '-',
            current_value: item?.current_value ? formatDateValues(item?.field_name, item?.current_value) : '-',
            previous_value: item?.previous_value ? formatDateValues(item?.field_name, item?.previous_value) : '-',
            description: createDescription(item.action_name, { 
                first_name: item.user_companies_user?.first_name, 
                last_name: item.user_companies_user?.last_name,
                field_name: item?.field_name,
                current_value: item?.current_value,
                previous_value: item?.previous_value
            })
        }))
    }

    const getAllLogs = () => {
        
        axios.get(`/contracts/contract-logs/${props.contract_id}`).then(res => {
            const data = flattenLogs(res.data.data)
            setLogs(data)
        }).catch(err => {
            console.log('106=>',err)
            toast.error('Something went wrong!')
        })
    }

    useEffect(()=>{
        if(!props.contract_id) return
        getAllLogs()
    },[props.contract_id])


    return (
        <div className={classes.root} style={{height: 369, width: '100%'}}>
            <DataGrid
                rows={logs}
                columns={columns}
                hideFooter={true}
            />
        </div>
    )
}

export default withRouter(Activities)
