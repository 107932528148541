import { useCurrentUser } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast';
import { Button, CircularProgress, Dialog, DialogActions,
     DialogContent, DialogTitle, Icon, makeStyles } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error';
import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { closeImageDialog } from '../store/imageDialogReducer';
import axiosInstance from '@fuse/utils/axios';

const useStyles = makeStyles(theme => ({
	dropZone: {
		border: "4px dashed #999",
		padding: 80
	},
	dropZoneDraging: {
		border: "4px dashed #6B2C57",
	}
}));

export default function AssetImageUpload(props) {

    const classes = useStyles(props)
    const uploadInput = useRef();
	const dispatch = useDispatch();
    const user = useCurrentUser();
    const toast = useToast(dispatch);
    const [loading, setLoading] = useState(false)
    const [uploadedFileInfo, setUploadedFileInfo] = useState({})
	const [isFileSelected, setIsFileSelected] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
    const [currentPage, setCurrentPage] = useState('upload')
    const [isDraging, setIsDraging] = useState(false);
    const dialogState = useSelector(({assetsApp}) => assetsApp.imageDialogReducer);

	function goToPage(pageId){
		setCurrentPage(pageId ?? 1)
	}

    function handleFileInput(event, file = null){
		if(!file) file = uploadInput.current.files[0];
		if (!file) return;

		setSelectedFile(file);
		setUploadedFileInfo({name: file.name, type: file.type, size: file.size});
		setIsFileSelected(true);
	}

    function handleUploadToS3() {
        setLoading(true)
        const formData = new FormData();
		formData.append("file", selectedFile);        
        axiosInstance.post('/assets/asset-image-upload', formData, {
            headers: {
				'Content-Type': 'multipart/form-data'
			}
        }).then(response => {
            setCurrentPage('summary')
        }).catch(err => {
            console.log(err);
            setCurrentPage('failure')
            if(err.response && err.response.status == 409){
                setCurrentPage('upload')
                toast.error(err.response.data.message)
            }
        }).finally(() => {
            setTimeout(() => {
                setLoading(false)
            }, 500)
        })
    }

    function handleSetDefaultSettings(){
		setCurrentPage('upload')
		setIsFileSelected(false)
	}

	function handleFileDragOver(event){
		event.preventDefault();
		if(!isDraging){
			setIsDraging(true)
		}
	}

	function handleFileDragLeave(event){
		event.preventDefault();		
		if(isDraging){
			setIsDraging(false)
		}
	}

	function handleFileOnDrop(event){		
		event.preventDefault();
		setIsDraging(false)
		if (event.dataTransfer.files.length) {
			const file = event.dataTransfer.files[0];
			setSelectedFile(file)
			handleFileInput({}, file);
		}
	}

	useEffect(() => {
		setSelectedFile(null);
		setTimeout(() => {
			handleSetDefaultSettings();
		}, 500);
	},[dialogState.open])

    return (
        <Dialog 
            open={dialogState.open} 
            aria-labelledby="form-dialog-title" 
            maxWidth="sm" 
            fullWidth={true} 
        >
            <DialogTitle id="form-dialog-title" >
                Upload Image for Asset
            </DialogTitle>

            <DialogContent >
                {currentPage === 'upload' &&
                    <div>
                        <div className="text-center" role="button">                            
                            {!loading && <>
                                <div 
                                    style={{
                                        padding: 80,
                                        border: `4px dashed #999`
                                    }}
                                    onClick={() => uploadInput.current.click()}
                                    onDragOver={handleFileDragOver}
                                    onDragLeave={handleFileDragLeave}
                                    onDrop={handleFileOnDrop}
                                >      
                                    <div>
                                        <Icon fontSize="large" color="action" title="Upload Image">
                                            cloud_upload
                                        </Icon>
                                    </div>
                                    <div className='my-5'>
                                        <span>Drag & Drop the Zip Files here</span>
                                    </div>
                                    <div className='my-20'>
                                        <span>Or</span> 
                                    </div>
                                    <div>
                                        <button className='browse-button'>Browse Zip Files</button>
                                    </div>
                                    {isFileSelected && <>
                                        <p style={{marginTop: 5}}>{uploadedFileInfo.name}</p>
                                        <Button style={{marginTop: 10}} 
                                            onClick={(event) => {
                                            event.stopPropagation();
                                            handleUploadToS3();
                                        }} 
                                        color="primary" variant="contained">
                                            Upload
                                        </Button>
                                    </>}

                                </div>
                            </>}
                            {loading && <>
                                <CircularProgress/>
                            </>}
                        </div>
                    </div>
                }
                {
                    currentPage === 'summary' &&
                    <>
                        <h4 className="text-green-500 mb-4"><CheckCircleOutline/> File Uploaded Successfully.</h4>
                    </>
                }
                {
                    currentPage == 'failure' &&
                    <>
                        <h4 className="text-red-500 mb-4"><ErrorIcon/> Something went wrong! Please re-upload.</h4>
                    </>
                }
                <input 
                    ref={uploadInput}
                    className="hidden"
                    id="button-file"
                    type="file"
                    onChange={handleFileInput}
                />
            </DialogContent>
            <DialogActions >
                <Button onClick={() => dispatch(closeImageDialog())} color="primary">
					Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}