import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'

export async function fetchContracts(){
  const res = await axios.get(`/end-user-contracts/list-all?contract_header_id=${''}`)
  return formatContracts(res.data.data)
}

export const colDefs = [
  {
    field: "contract_number",
    key: "contract_number",
    headerName: "Contract Number"    
  },
  {
    field: "contract_name",
    key: "contract_name",
    headerName: "Contract Name"    
  },
  {
    field: "start_date",
    key: "start_date",
    headerName: "Start Date"
  },
  {
    field: "end_date",
    key: "end_date",
    headerName: "End Date"
  },
  {
    field: "duration",
    key: "duration",
    headerName: "Duration",
    comparator: durationComparator,
  },
  {
    field: "type",
    key: "type",
    headerName: "Type"    
  },
  {
    field: "status",
    key: "status",
    headerName: "Contract Status"    
  },
  {
    field: "contract_value",
    key: "contract_value",
    headerName: "Contract Value"
  },
  {
    field: "SLA",
    key: "SLA",
    headerName: "SLA"    
  },
  {
    field: "service_provider",
    key: "service_provider",
    headerName: "Service Provider"
  },
  {
    field: "master",
    key: "master",
    headerName: "Master"   
  },{
    field: "parent_contract",
    key: "parent_contract",
    headerName: "Parent Contract"
  },
  {
    field: "new/renew",
    key: "new/renew",
    headerName: "New/Renew"
  },
  {
    field: "location",
    key: "location",
    headerName: "Location"
  }
]

// function calculateDuration(startDate, endDate) {
//   const start = new Date(startDate);
//   const end = new Date(endDate);

//   const yearDiff = end.getUTCFullYear() - start.getUTCFullYear();
//   const monthDiff = end.getUTCMonth() - start.getUTCMonth();
//   const dayDiff = end.getUTCDate() - start.getUTCDate();

//   let durationString = '';

//   if (yearDiff > 0) {
//     durationString += `${yearDiff}Y `;
//   }

//   if (monthDiff > 0) {
//     durationString += ` ${monthDiff}M `;
//   }

//   if (dayDiff > 0) {
//     durationString += `${dayDiff}D`;
//   }

//   if(!durationString.length) return `0D`
//   return durationString;
// }

function calculateDuration(startDate, endDate) {
  var start = new Date(startDate)
  var end = new Date(endDate)

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffInDays = Math.floor(Math.abs((start - end) / oneDay));

  const years = Math.floor(diffInDays / 365);
  const months = Math.floor((diffInDays % 365) / 30);
  const days = diffInDays % 30;

  const result = `${days}D ${months}M ${years}Y`;
  return result;
}

function convertToComparableValue(durationString) {
  const regex = /(\d+)([YMD])/g;
  let totalDays = 0;

  let match;
  while ((match = regex.exec(durationString)) !== null) {
    const value = parseInt(match[1]);
    const unit = match[2];

    if (unit === 'Y') {
      totalDays += value * 365; // Assuming 1 year = 365 days
    } else if (unit === 'M') {
      totalDays += value * 30; // Assuming 1 month = 30 days
    } else if (unit === 'D') {
      totalDays += value;
    }
  }

  return totalDays;
}

function durationComparator(valueA, valueB, nodeA, nodeB, isInverted) {
  const comparableValueA = convertToComparableValue(valueA);
  const comparableValueB = convertToComparableValue(valueB);

  if (comparableValueA === comparableValueB) {
    return 0;
  }

  return comparableValueA < comparableValueB ? -1 : 1;
}

function formatContracts(data){
  return data.map((contract) => {
    const dynamicAttributes = Object.entries(contract.dynamic_attributes).reduce((acc, [key, item]) => {
        acc[key] = item.attribute_value
        return acc;
    },{});
    let related_asset = [];
    let idx = 0;
    contract.assets.map((asset) => { 
      related_asset[idx] = asset.serial_number
      idx++
    })
    return { 
      id: contract.id,
      contract_value: formatPriceFieldOnBlur(contract.total_contract_value ?? 0),
      contract_number: contract.entity_external_platform_id || '',
      contract_name: contract.contract_name,
      start_date: contract.contract_start_date !== null ? dateReformatTo_mmm_dd_yyyy(contract.contract_start_date) : "",
      end_date: contract.contract_end_date !== null ? dateReformatTo_mmm_dd_yyyy(contract.contract_end_date) : "",
      duration: calculateDuration(contract.contract_start_date, contract.contract_end_date),
      type: contract?.contract_headers_type?.display_name || '',
      status: contract?.contract_header_status?.contract_status_name || '',
      number_of_assets: contract?.assets.length,
      SLA: contract?.service_level_agreement?.sla_name || '',
      service_provider: contract?.contract_service_provider?.service_provider_name || '',
      master: contract?.master === true ? "Yes" : "No",
      parent_contract: contract?.parent_contract?.contract_name || 'No Parent Contract',
      ['new/renew']: contract?.renewal || '',
      location: contract.shipping?`${contract.shipping.location_name}-${contract.shipping.address1}, ${contract.shipping.city}, ${contract.shipping.state}, ${contract.shipping.country}`: "",
      ...dynamicAttributes,
    }
  })
}