import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import useToast from "@fuse/hooks/useToast";
import axios from "@fuse/utils/axios";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const AccessoriesTab = () => {
  const [expanded, setExpanded] = React.useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [accessoryDetails, setAccessoryDetails] = useState(null);
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const openState = useSelector((state) => state.usersApp.formViewDialog);

  useEffect(() => {
    setUserDetails(openState.user_data?.client?.multiple_accessory_users);
  }, [openState]);

  console.log("userDetails", userDetails)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function getDetails(item) {
    try {
      axios.get(`/accessory/accessory-details/${item.id}`).then((results) => {
        setAccessoryDetails(results.data.data.details);
        // dispatch(setUserInfo(results.data.data))
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div>
          <Typography variant="h6" style={{ color: "#3A4252" }}>
            Accessories
          </Typography>
        </div>
        <div style={{ textAlign: "right" }}></div>
      </div>

      <div style={{ height: 290, overflow: "auto" }}>
        {userDetails?.length > 0
          ? userDetails?.map((item) => {
              return (
                <>
                  <Accordion
                    style={{ marginBottom: "20px" }}
                    square
                    expanded={expanded === item?.accessory_id}
                    onChange={handleChange(item?.accessory_id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      onClick={() => {
                        getDetails(item.accessory);
                      }}
                    >
                      <Typography>
                        {item?.accessory?.accessory_name || "N/A"}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item lg={6}>
                          <Typography
                            style={{ fontWeight: 600, color: "#848D9E" }}
                            variant="subtitle1"
                          >
                            Model
                          </Typography>
                          <Typography
                            style={{ fontWeight: 400, fontSize: "15px" }}
                            variant="subtitle1"
                          >
                            {accessoryDetails
                              ? accessoryDetails?.model_number
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid lg={6}>
                          <Typography
                            style={{ fontWeight: 600, color: "#848D9E" }}
                            variant="subtitle1"
                          >
                            Category
                          </Typography>
                          <Typography
                            style={{ fontWeight: 400, fontSize: "15px" }}
                            variant="subtitle1"
                          >
                            {accessoryDetails?.accessory_category
                              ?.accessory_category_name || ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item lg={6}>
                          <Typography
                            style={{ fontWeight: 600, color: "#848D9E" }}
                            variant="subtitle1"
                          >
                            Manufacturer's Name
                          </Typography>
                          <Typography
                            style={{ fontWeight: 400, fontSize: "15px" }}
                            variant="subtitle1"
                          >
                            {accessoryDetails
                              ? accessoryDetails?.manufacturer.manufacturer_name
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })
          : "No Accessory Found"}
      </div>
      {/* <SeatsTab /> */}
    </>
  );
};

export default AccessoriesTab;
