import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { useState,useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import EndUsersAccountsTable from './tablesPlain';
import useRouter from '@fuse/hooks/useRouter';
import axiosgeneral from "@fuse/utils/axios";
import {MenuItem, Typography} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField} from '@material-ui/core';
import { motion } from 'framer-motion';
import StepperCustom from '@fuse/components/general/Stepper'
import useToast from '@fuse/hooks/useToast';
import { exportToCsv } from "@fuse/utils";
import { dateReformatTo_mmm_dd_yyyy, monthDiff } from '@fuse/utils/dateOperations'
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import SaveReportDialog from './saveReportDialog';
import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { setfilterlist } from '../store/filterParamsReducer';
import {toReadable} from '@fuse/utils/stringOperations'
import { useCurrentUser } from '@fuse/hooks';

const drawerWidth = 400;
const headerHeight = 200;

const useStyles = makeStyles(theme => ({
	'@global': {
		'#fuse-main': {
			height: '100vh'
		}
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: '100%',
		position: 'relative',
		// flex: '1 1 auto',
		height: 'auto',
		width:'100%',
		backgroundColor: theme.palette.background.default
	},
	noQuoteText: {
		textAlign: 'center',
		marginTop: '5vh'
	},
	cardGen: {
		width: '100%',
	},
	topBg: {
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none',
		padding:"10px"
	},
	topBgFixed: {
		height: headerHeight,
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none'
	},
	contentCardWrapper: {
		maxWidth: 1400,
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 0 auto',
		width: '100%',
		minWidth: '0',
		margin: '0 auto',
		// [theme.breakpoints.down('sm')]: {
		// 	padding: '16px 16px 0 16px'
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	padding: '12px 12px 0 12px'
		// }
	},
	contentCard: {
		display: 'flex',
		position: 'relative',
		// flex: '1 1 100%',
		// flexDirection: 'column',
		width:"100%",
		backgroundColor: theme.palette.background.paper,
		minHeight: 0,
		overflow: 'hidden',
		borderRadius: '2px 2px 0 0',
	},
	drawerPaper: {
		width: drawerWidth,
		maxWidth: '100%',
		overflow: 'hidden',
		height: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'relative'
		}
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 1 100%',
		zIndex: 10,
		background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
			theme.palette.background.paper,
			0.6
		)} 20%,${fade(theme.palette.background.paper, 0.8)})`
	},
	content: {
		display: 'flex',
		// flex: '1 1 100%',
		minHeight: 0
	},
	avatar: {
		backgroundColor: red[500],
	},
	formControl: {
		padding: "0px"
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	formControlMinWidth: {
		minWidth: 200,
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	card: {
		borderRadius:"5px"
	},
	cardContainer:{
	},
	borderRightLight: {
		borderRight:"1px solid rgba(0,0,0,0.12)"
	},
	buttonSquare:{
		borderRadius: '5px'
	}
}));

function QuoteSummary(props) {
	const {data:userData} = useCurrentUser()
	const dispatch = useDispatch();
	const filters = ['Select filter', 'Quote ID'];
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	let [listItems,setListItems] = useState([]);
	let [totalRecords,setTotalRecords] = useState(0);
	let [rowsSelected,setRowsSelected] = useState([]);
	let [quoteIdsSelected,setQuoteIdsSelected] = useState([]);
	const grid = useRef();

	const toast = useToast(dispatch);
    
    const successAlert = (message) => {
        toast.success(message,{
        anchorOrigin: {
            vertical  : 'bottom',//top bottom
            horizontal: 'right'//left center right
        }
        })
    };

    const errorAlert = (message) => {
        toast.error(message,{
        anchorOrigin: {
            vertical  : 'bottom',//top bottom
            horizontal: 'right'//left center right
        }
        })
    };

	if(userData.email === 'sonder.phi@gmail.com') {
		return (
			<Typography variant='subtitle1' className={classes.noQuoteText}>You have no quotes to show!</Typography>
		)
	}
	
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState('all');
	const router = useRouter();
	const [currentFilter, setCurrentFilter] = useState(0);	
	const [currentSuggestions, setCurrentSuggestions] = useState([]);
	const [currentSearch, setCurrentSearch] = useState('_');
	const steps = ['Select quote', 'Review quote', 'Preview quote', 'Payment'];
	const allowedCols = ['id','quote_id','contract_id','status','agent_name','dynamic_attributes']

	useEffect(async ()=>{
		fetchItems(rowsPerPage)
	},[]);

	useEffect(()=>{
		const filter = filters[currentFilter];
		let suggestions = [];
		axiosgeneral.get(`/quotes/fetch-suggestions?filter=${filter}`)
		.then(res => {
			suggestions = Array.from(new Set(res.data.data));
			if(suggestions.length == 1 && suggestions[0] == ''){
				suggestions = [];
			}
			if(!router.query.report_name) setCurrentSearch('')
			setCurrentSuggestions(suggestions);
		})
		.catch(err => {
			return err;
		})
	},[currentFilter]);

	function fetchItems(rowsPerPage=10, page=0, filter='_', search='_'){
		setLoading(true);
		axiosgeneral.get(`/quotes?page=${page}${rowsPerPage !== 'all' ? `&limit=${rowsPerPage}` : ''}&filter=${filter}&search=${search == '' ? '_' : search}&queryfilter=${router.query.filter != undefined ? router.query.filter :'_'}`)
		.then(res => {
			setAllStates(res.data.data);
			showMessage({ message: res.data.message,autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
		})
		.catch(err => {
			return err;
		})
		.finally(()=>setLoading(false));
	}

	function onSearch(){
		if(!currentSearch){
			setCurrentSearch('');
			return
		}		
		dispatch(setfilterlist({"quote_id":{"filterType":"text","type":"contains","filter":`${currentSearch}`}}));
		fetchItems(10, 0, filters[currentFilter], currentSearch);
	}

	function onSelectSuggestion(val){
		setCurrentSearch(currentSuggestions[val]);
	}

	function onSelectSuggestionRaw(val){
		setCurrentSearch(val);
	}

	function setAllStates(data){
		setListItems(data.quotes);
		setTotalRecords(data.number_of_records);
	}

	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function flattenListItems(){
		return listItems.map(item => ({
			id: item.id,
			quote_id: item.quote_id,
			contract_id: item?.contract_header?.entity_external_platform_id || '-',
			generation_date: item.generation_date ? dateReformatTo_mmm_dd_yyyy(item.generation_date.split('T')[0]) : '-',
			expiry_date: item.expiry_date ? dateReformatTo_mmm_dd_yyyy(item.expiry_date.split('T')[0]) : '-',
			agent_name: item.agent ? `${item.agent.first_name} ${item.agent.last_name}` : '-',
			locations: item.shipping_location ? `${item.shipping_location.address1}, ${item.shipping_location.city}, ${item.shipping_location.zip}` : '-',
			status: item.enduser_brief_status ? toReadable(item.enduser_brief_status) : '-',
			activity: item.enduser_desc_status ? toReadable(item.enduser_desc_status) : '-',
			account_name: item?.user_company?.company_name || '-'
		}));
	}

	function clearSearch() {
		setRowsPerPage('all')
		setPage(0);
		setCurrentFilter(0);
		setCurrentSuggestions([]);
		setCurrentSearch('')
		fetchItems(rowsPerPage);
	}

	function onFilterChange(val){
		setCurrentFilter(val);
	}

	function gotoQuoteDetail(quote){
		router.push(`quotes/detail/${quote.id}`)
	}

	const onRowSelected = (event) => {
	};
	
	const onSelectionChanged = (event) => {
		const quote_ids = event.api.getSelectedNodes().map(row => row.data.quote_id);
		setRowsSelected([...listItems.filter(el => (quote_ids.includes(el.quote_id)))]);
		setQuoteIdsSelected(Array.from(new Set([...quote_ids])));
	};

	const handleAutoCompleteInputChange = (e)=>{
		try{
			if(e != null) {
				let currSearch = e.target.value;
				if(typeof(currSearch) != 'string'){return}
				currentSuggestions.forEach(suggestion => {
					if(suggestion.toLowerCase() == currSearch.toLowerCase()){
						currSearch = suggestion;
					}
				})
				onSelectSuggestionRaw(currSearch);
			}
		}catch(err){console.log(err)}
	}

	function handleDownloadFile(filename = "import.csv", blob = null){
		let link = document.createElement("a");
        if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
	}

	function fetchExportItems(filter='_', search='_'){
		axiosgeneral.get(`/quotes/export-all?filter=${filter}&search=${search == '' ? '_' : search}`)
		.then(res => {
			let rows = flattenExportItems(res.data.data.quotes)
			setListExportItems(rows)
		})
		.catch(err => {
			return err;
		})
	}

	// const flattenExportItems = (rows)=>{
	// 		return rows.map(item => ({
	// 			quote_id: item.quote_id,
	// 			contract_id: item?.contract_header?.entity_external_platform_id || '-',
	// 			generation_date: item.generation_date ? dateReformatTo_mmm_dd_yyyy(item.generation_date.split('T')[0]) : '-',
	// 			expiry_date: item.expiry_date ? dateReformatTo_mmm_dd_yyyy(item.expiry_date.split('T')[0]) : '-',
	// 			agent_name: item.agent ? `${item.agent.first_name} ${item.agent.last_name}` : '-',
	// 			shipping_location: item.shipping_location ? `${item.shipping_location.locatin_name}, ${item.shipping_location.address1}, ${item.shipping_location.city}, ${item.shipping_location.zip}` : '-',
	// 			status: item?.status || '-',
	// 			account_name: item?.user_company?.company_name || '-'
	// 		}));
	// }

	function toNumberFormat(numberString){
		const number = Number(numberString);
		let fixed = number.toFixed(2);
		let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
		let [formatedNumber] = number.toLocaleString().split(".");
		const result = `${formatedNumber}.${secondNumber}`;
		return result
	}

	function flattenExportItems(listItems){
		return listItems.map(acc => ({
			quote_id: acc.quote_id,
			asset_count: acc?.quote_line_items.length || '0',
			// location: acc.quote_shipping_address ? acc.quote_shipping_address : (acc.shipping_location ? acc.shipping_location.location_name : '-'),
			contract_id: acc?.contract_header?.entity_external_platform_id || '-',
			generation_date: acc.generation_date ? dateReformatTo_mmm_dd_yyyy(acc.generation_date.split('T')[0]) : '-',
			expiry_date: acc.expiry_date ? dateReformatTo_mmm_dd_yyyy(acc.expiry_date.split('T')[0]) : '-',
			months: acc.generation_date && acc.expiry_date ? `${monthDiff(new Date(acc.generation_date), new Date(acc.expiry_date))}`: '-' ,
			service_plan: acc?.services?.service_plan || '-',
			service_type: acc?.services?.service_type || '-',
			service_level: acc?.services?.service_level || '-',
			locations: acc.quote_shipping_address ? acc.quote_shipping_address : (acc.shipping_location ? `${acc.shipping_location.location_name}, ${acc.shipping_location.address1}, ${acc.shipping_location.city}, ${acc.shipping_location?.zip}` : '-'),
			billing_location: acc.quote_billing_address ? acc.quote_billing_address : (acc.billing_location ? `${acc.billing_location.location_name}, ${acc.billing_location.address1}, ${acc.billing_location.city}, ${acc.billing_location?.zip}` : '-'),
			total_price: `$${toNumberFormat(acc?.total?.total_sell_price || 0.00)}`,
			// no_of_requests: acc.no_of_requests
		}))
	}

	function handleExportToCSV(){
		axiosgeneral.get(`/quotes/export-all?filter=${filters[currentFilter]}&search=${currentSearch == '' ? '_' : currentSearch}`)
			.then(res => {
				let rows = flattenExportItems(res.data.data.quotes)
				const listingData = [];
				let headersList = Object.keys(rows[0]).map(header=>{
					return header;
				});
				listingData.push(headersList);
				rows.forEach(row=>{
					let data = [];
					for (let item in row){
						data.push(row[item])
					}
					listingData.push(data);		
				})
				const templatFileBlob = exportToCsv(listingData);
				const today = new Date();
				const timeString =  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
				handleDownloadFile(`quote_listing_${timeString}.csv`, templatFileBlob);
			})
			.catch(err => {
				console.log(err)
				return err;
			})

		
		// fetchExportItems(filters[currentFilter], currentSearch);
		// const rows = listExportItems;

		// const listingData = [];
		// let headersList = Object.keys(rows[0]).map(header=>{
		// 	return header;
		// });
		// listingData.push(headersList);
		// rows.forEach(row=>{
		// 	let data = [];
		// 	for (let item in row){
		// 		data.push(row[item])
		// 	}
		// 	listingData.push(data);		
		// })
		// const templatFileBlob = exportToCsv(listingData);
		// const today = new Date();
		// const timeString =  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
		// handleDownloadFile(`quote_listing_${timeString}.csv`, templatFileBlob);
	}


	return (
		<div className={clsx(classes.root)}>
			<SaveReportDialog />
			<FuseScrollbars className="flex-grow overflow-x-auto">
				{/* <DynamicFieldsHeader classes={classes} 
				content={<>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</>} /> */}

				<div className={clsx(classes.contentCardWrapper, 'container')}>
					<div className={clsx(classes.contentCard, 'shadow')}>
					
						<main style={{width:"100%"}}>
							<Grid container className="p-10">
								<Grid item xl={12} className={`${classes.cardGen} pb-10`}>
									<Card className={classes.card}>
										<CardContent className={classes.cardContainer}>
											<StepperCustom activeStep={activeStep} className={`p-0`} steps={steps}/>
										</CardContent>
									</Card>
								</Grid>
									
								<Grid item xl={12} className={`${classes.cardGen} mt-0 pt-0`}>
									<Card className={`${classes.card} mt-0 pt-0`}>
										<CardContent className={`${classes.cardContainer} p-5`}>
											<motion.div
												initial={{ opacity: 0 }}
												animate={{ opacity: 1, transition: { delay: 0.1 } }}
												className="flex flex-1 items-center justify-end h-full"
											>
												<FormControl variant="outlined" className={classes.formControl} margin='dense'>
													<InputLabel htmlFor="quote_indicator_filter">Filters</InputLabel>
													<Select
														disableUnderline
														id="filter-deropdown"
														style={{minWidth: "150px"}}
														label="Filter"
														value={currentFilter}
														className="pl-10"
														onChange={e => {onFilterChange(e.target.value)}}
													>
														{filters.map((el, ind) =>{
															return(
															<MenuItem value={ind} disabled={ind == 0}>{el}</MenuItem> 
															)
														}
														)}
													</Select>
												</FormControl>
												<Autocomplete						
													getOptionSelected={(option, value) => option.toLowerCase() === currentSearch.toLowerCase()}
													noOptionsText="No suggestion found"
													onInputChange={e => handleAutoCompleteInputChange(e)}
													onChange={e => {
														if(e != null && e.target.innerText != '') {onSelectSuggestionRaw(e.target.innerText)}
														if(e.target.innerText == '') fetchItems()
													}}
													value={currentSearch}
													filterOptions={(options, state)=>options.filter(opt => opt.toLowerCase().includes(currentSearch.toLowerCase()))}
													id="controllable-states-demo"
													options={currentSuggestions}
													style={{ width: 300 }}
													// renderInput={(params) => <TextField {...params} label="Controllable" variant="outlined" />}
													renderInput={(params) => {
														return(
														<div 
														ref={params.InputProps.ref}>
														<TextField
															{...params.inputProps}
															placeholder="Search quotes"
															className="flex flex-1 mx-8"
															disableUnderline
															variant="outlined"
															disabled={currentFilter == 0}
															size="small"
															fullWidth
															// InputProps={{
															// 	disableUnderline: true,
															// 	endAdornment: (
															// 		<InputAdornment position="end">
																		
															// 		</InputAdornment>
															// 	)
															// }}
														/>
														</div>
													)}}
												/>
													<Button
														variant="contained"
														color="secondary"
														disabled={currentSearch == ''}
														// type='submit'
														// disabled
														onKeyPress={e => (e.key == 'Enter') && onSearch() }
														onClick={()=>onSearch()}
														className={`ml-20 ${classes.buttonSquare}`}>
															{/* <input
																type='button'
																autoFocus
																value='Searchh'
																onKeyDown={e => (e.key == 'Enter') && onSearch() }
																onClick={()=>onSearch()}
															/> */}
															Search
													</Button>

													<Button
														variant="contained"
														color="secondary"
														// disabled
														
														onClick={()=>clearSearch()}
														className={`ml-10 ${classes.buttonSquare}`}>
															Reset
													</Button>

													<Button
														variant="contained"
														color="secondary"
														onClick = { ()=>handleExportToCSV()}
														className={`ml-10 ${classes.buttonSquare}`}>
															Export to CSV
													</Button>

													<Button
														variant="contained"
														color="secondary"
														onClick = {()=>dispatch(openReportViewerDialog())}
														className={`ml-10 ${classes.buttonSquare}`}>
															Save View
													</Button>							
											</motion.div>
										</CardContent>
										<Divider/>
										<CardContent className={classes.cardContainer}>
											<EndUsersAccountsTable 
											listItems={flattenListItems()}
											selectedView={selectedView}
											fetchItems={fetchItems}
											totalRecords={totalRecords}
											page={page}
											setPage={setPage}
											rowsPerPage={rowsPerPage}
											setRowsPerPage={setRowsPerPage}
											loading={loading}
											filters={filters}
											currentFilter={currentFilter}
											currentSearch={currentSearch}
											onRowSelected={onRowSelected}
											onSelectionChanged={onSelectionChanged}
											quoteIdsSelected={quoteIdsSelected}
											onRowClick={gotoQuoteDetail}
											successAlert={successAlert}
											errorAlert={errorAlert}
											grid={grid}
											table_name = { "QUOTES" }
											setCurrentFilter={setCurrentFilter}
											setCurrentSearch={setCurrentSearch}
											/>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</main>
					</div>
				</div>
			</FuseScrollbars>
		</div>
	);
}

export default withReducer('quotesApp', reducer)(QuoteSummary);
