import FusePageCardedOne from '@fuse/core/FusePageCardedOne';
import { makeStyles } from '@material-ui/core/styles';
import withReducer from 'app/store/withReducer';
import reducer from '../../../store';
import AssetsDashboardHeader from './header';
import AssetsDashboardList from './tables';
import { usePusher } from "@fuse/hooks";
import useCurrentUser from "@fuse/hooks/useCurrentUser";
import { useEffect } from "react";

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});
function AssetsDashboard(props) {
	const classes = useStyles();
	const { broadcastChannel } = usePusher();
  const new_incident_event = "permission_change";
  const user = useCurrentUser();

	useEffect(() => {
    if (broadcastChannel /* && invoices.length*/) {
      broadcastChannel.bind(new_incident_event, (data) => {
        if (data.user_companies_id == user.data.user_companies_id) {
          location.reload();
        }
      });
      return () => broadcastChannel.unbind(new_incident_event);
    }
  }, [broadcastChannel]);
	
	return (
		<FusePageCardedOne
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0',
				topBg:classes.topBg,
				contentCard:classes.contentCard
			}}
			// header={
			// 	<AssetsDashboardHeader />
			// }
			content={
				<div>
					<AssetsDashboardList/>
				</div>
			}
			innerScroll
		/>
	)
	
}
export default withReducer('assetsDashboardApp', reducer)(AssetsDashboard);