import Page from './index';
import { authRoles } from 'app/auth';

export default {
settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
	},
	// auth: authRoles.user,
	routes: [
		{
			path: '/quotes',
			exact: true,
			component: Page.Layout
		},
		{
			path: '/quotes/detail/:id',
			exact: true,
			component: Page.QuoteDetail
		},
		{
			path: '/quotes/payment/:id',
			exact: true,
			component: Page.PaymentView
		},
		{
			path: '/quotes/success',
			exact: true,
			component: Page.FinalPage
		},
		{
			path: '/quotes/validate-line-items/:token',
			exact: true,
			component: Page.ValidateLineItem
		}
	]
};