// import DemoContent from '@fuse/core/DemoContent';
// import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect, Fragment } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { connect } from 'react-redux';
import { hideMessage, showMessage } from '../../../store/fuse/messageSlice';
import { openDialog, closeDialog } from '../../../store/fuse/dialogSlice';
import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button, Icon } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
// import { openCreateDialog } from "../store/dialogReducer"
// import { openCsvDialog } from "../store/csvDialogReducer"
import { useDispatch } from 'react-redux';
// import CsvUpload from './CsvUpload';
import { motion } from 'framer-motion';
import {TextField,Menu,MenuItem} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Media from 'react-media';
import IconButton from '@material-ui/core/IconButton';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
// import { ReactComponent as Eye } from '../icons/eye.svg';
import { ReactComponent as GridView } from '../icons/grid_view.svg';
import { ReactComponent as ListView } from '../icons/list_view.svg';
import { ReactComponent as SearchIcon } from '../icons/search.svg';
import { ReactComponent as UploadIcon } from '../icons/upload.svg';
import SvgIcon from "@material-ui/core/SvgIcon";
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
// import ShowLogs from './logs';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [invoices, setManufacturers] = useState([]);
	const router = useRouter();
	const agents = getAgents(invoices);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	// const [loading1, setLoading1] = useState(false);
	const [logs,setLogs] = useState([]);

	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});

	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function getAgents(invoices){
		const agentSet = new Set();
		for(let i = 0; i < invoices.length ; i++){
			agentSet.add(invoices[i].Agent);
		}
		return Array.from(agentSet);
	}

	function applyFilters(filters){
		setFilters(filters);
		setManufacturers(invoices.filter(acc=>{
			let flag = true;
			if(filters.agent != '' && filters.agent != acc.Agent){
				flag = false;
			}
			return flag;
		}))
	}

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			getImportList()
		}
		setSelectedTab(value);
	};
	

	function toggleView(val){
		setSelectedView(val);
	}

	function gotoDetailsPage(id){
		router.push(`manufacturers/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	function getManufacturers(){
		return axios.get("/manufacturers").then((res) => {
				const { data } = res.data;
				setManufacturers(formatInvoices(data))
		}).catch((err) => {
			console.log(err)
		})
	}
	
	function handleSearchReset(){
		getManufacturers()
	}

	function getSearchFilters(){
		setSearchFilters([
			{field: "hardware_sku", label: "Hardware SKU"},
			{field: "service_plan", label: "Service plan"},
		])
	}

	function getCustomFilters(){
		axios.get('/manufacturers/get-filters')
		.then(res => {
			const { data } = res.data;
			setCustomFilters(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	function getSearchSuggetions(query, field){
		axios.get('/manufacturers/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	function handleSearch(query, field, filters) {
		axios.get('/manufacturers/search',{
			params: {
				query,
				field,
				filters
			}
		})
		.then(res => {
			const { data } = res.data;
			setManufacturers(data)
		})
		.catch(err => {
			console.log(err);
		})
	}
	
	function formatInvoices(data){
		return data.map((item) => {
			return {
				manufacturer_id: item.entity_external_platform_id,
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
				]),
			}
		})
	}

	function getDynamicAttributes(){
		return axios.get("/dynamic-attributes/fetch-all")
		.then((res) => {
			const { data } = res.data;

			const platformModuleItem = data.find(({name}) => name == "invoice_header");

			setPlatformModule(platformModuleItem);

			return axios.get(`/dynamic-attributes/fetch/${platformModuleItem.id}`)
				.then((res) => {
					const { data } = res.data;

					setPlatformModuleAttributes(data)

					return data;
				})
				.catch(err => {
					toast.error("Unable to fetch dynamic attributes")
					console.log(err)
				})
		})
		.catch(err => {
			toast.error("Unable to fetch dynamic attributes")
			setPlatformModule({});
			console.log(err)
		})
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			return getManufacturers();
		})
	}

	function getImportList(){
		axios.get('/logs/manufacturer')
		.then(({data})=>{
			setLogs(data.data);
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	useEffect(()=>{
		setLoading(true)
		// getManufacturers().finally(() => {
		// 	setLoading(false)
		// });
        setLoading(false)
	},[]);

	if(loading){
		return(<FuseLoading />)
	}else{
	return (<>
			{/* <CsvUpload refresh={refreshList}/> */}

			<FusePageCarded
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0',
				topBg:classes.topBg
			}}
			header={
				<DynamicFieldsHeader 
					handleSearch={handleSearch} 
					searchSuggestions={searchSuggestions} 
					clearSearch={clearFilters}
					searchFilters={searchFilters}
					handleSearchSuggestions={handelSearchSuggestions}
					handleSearchReset={handleSearchReset}
					onSearchChange={(searchValue, fieldValue) => {
						setCurrentSearchParameters({query: searchValue, field: fieldValue})
					}}
				/>
			}
			contentToolbar={
				<>
						<Tabs
							value={selectedTab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="off"
							className="w-full h-64"
						>
							<Tab key="end_user_tab_1" className="h-64" label="All catalogs" />
							{/* <Tab key="end_user_tab_2" className="h-64" label="Logs" /> */}
							
						</Tabs>
				    <div>
							<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
								{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={viewMenuOpened}
								onClose={handleViewMenuClose}
							>
								{	
									views.map((view,index) => {
										return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
									})
								}
							</Menu>
					</div>

				<div className="p-10">
				<Media queries={{
				medium: "(max-width: 900px)",
				large: "(min-width: 1200px)"
				}}>
					{
						matches => (
							<>
								{matches.large && 
									<Button
										// onClick={() => dispatch(openCsvDialog())}
										variant="contained"
										color="secondary"
										className={`w-full ${classes.buttonSquare}`}
										style={{width:'150px'}}
										startIcon={
											<SvgIcon fontSize="small"><UploadIcon  color="action" /></SvgIcon>
										}>
											Upload CSV
									</Button>
								}
								{
									matches.medium && 
									<IconButton
									// onClick={() => dispatch(openCsvDialog())}
									variant="contained"
									color="secondary"
									className={classes.buttonSquare}
									>
										<SvgIcon fontSize="small"><UploadIcon  color="action" /></SvgIcon>
									</IconButton>
								}
							</>
						)
					}
				</Media>
				</div>
				<div className="p-10">
				<Media queries={{
				medium: "(max-width: 900px)",
				large: "(min-width: 1200px)"
				}}>
					{
						matches => (
							<>
								{matches.large && 
									<Button
										variant="contained"
										color="secondary"
										className={`w-full ${classes.buttonSquare}`}
										style={{width:'150px'}}
										startIcon={
										<Icon fontSize="large" title="Save View">
											save
										</Icon>}>
											Save View
									</Button>
								}
								{
									matches.medium && 
									<IconButton
									onClick={() => dispatch(openCsvDialog())}
									variant="contained"
									color="secondary"
									className={classes.buttonSquare}
									>
										<Icon>
											cloud_upload
										</Icon>
									</IconButton>
								}
							</>
						)
					}
				</Media>
				</div>
		</>
			}
			content={

				<>
					<div className={selectedTab !== 0 ? 'hidden' : ''} style ={{textAlign:"center"}}>
					
                    {"no rows available"}
					
					</div>

					<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
						{"no data"}
					</div>
				</>
			}
			innerScroll
		/>
		
	</>)
	}
}


export default CardedFullWidth2TabbedSample;

