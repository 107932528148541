import { Button, Icon, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import MicrosoftLogin from 'react-microsoft-login';


const useStyles = makeStyles(theme => ({
    buttonSeries: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
    },
    button: {
        display: 'flex',
        padding: '10px',
        height: '50px',
        justifyContent: 'flex-start',
        borderRadius: '4px',
        backgroundColor: 'f4f4f4',
        marginBottom: '10px',
        width: '100%'
    },
    textLeft:{
        marginLeft: '55px',
        fontWeight: 500,
        fontSize: 14
    }
}));

function OptionsForm({ dispatch, setPageComponent, toast, signIn, authHandler }) {

  const classes = useStyles()

  const handlePageChange = (pageName) => {

    switch(pageName){
        case 'email': 
            setPageComponent(1)
            break;
        
        // case 'microsoft':
        //     break;

        case 'google':
            signIn()
            break;

        default: break;
    }

  }

//   const microsoftClientId=`${process.env.REACT_APP_MICROSOFT_CLIENT_ID2}`;
//   const microsoftClientRedirectUri=`${process.env.REACT_APP_SERVER}`== "LOCAL" ?  `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_REGISTRATION}` :  `${process.env.REACT_APP_SERVER}`== "DEV" ? `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_DEV_REGISTRATION}` : `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_STAGING}`

  let microsoftClientId = '', microsoftClientRedirectUri = ''
	
	switch(`${process.env.REACT_APP_SERVER}`){
		case "LOCAL": {
			microsoftClientRedirectUri = `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_REGISTRATION}`
			microsoftClientId = `${process.env.REACT_APP_MICROSOFT_CLIENT_ID2}`
			break;
		}

		case "DEV": {
			microsoftClientRedirectUri = `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_DEV_REGISTRATION}`
			microsoftClientId = `${process.env.REACT_APP_MICROSOFT_CLIENT_ID2}`
			break;
		}

		case "PRODUCTION": {
			microsoftClientRedirectUri = `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_DEV_REGISTRATION}`
			microsoftClientId = `${process.env.REACT_APP_MICROSOFT_CLIENT_ID2}`
			break;
		}
	}

  return (
    <div className={classes.buttonSeries}>

        <MicrosoftLogin clientId={microsoftClientId} redirectUri={window.location.href} authCallback={authHandler} prompt={'select_account'} >
            <Button 
                size='large' 
                variant='contained' 
                className={classes.button}
            >
                <img src='assets/images/logos/microsoft_logo.png' style={{height: '25px', width:'25px', marginLeft:'15px'}}></img>
                <Typography className={classes.textLeft}>Continue with Microsoft</Typography>
            </Button>
        </MicrosoftLogin>

        <Button 
            size='large' 
            variant='contained' 
            className={classes.button}
            onClick={() => handlePageChange('google')}
        >
            <img src='assets/images/logos/google_logo.png' style={{height: '25px', width:'25px', marginLeft:'15px'}}></img>
            <Typography className={classes.textLeft}>Continue with Google</Typography>
        </Button>

        <Button 
            size='large' 
            variant='contained' 
            className={classes.button}
            onClick={() => handlePageChange('email')}
        >
            <img src='assets/images/logos/mail_logo.png' style={{height: '30px', width:'33px', marginLeft:'12px'}}></img>
            <Typography className={classes.textLeft}>Continue with Email</Typography>
        </Button>

    </div>
  )
}

export default OptionsForm
