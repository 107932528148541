import useToast from '@fuse/hooks/useToast'
import {
  Button,
  DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Typography,
  Drawer
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { Box } from '@mui/material';
import Editor from '@fuse/components/Editor';
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => (
  {
    uploadImageButton: {
      padding: '6px 16px',
      fontSize: '1.3rem',
      minWidth: '64px',
      boxSizing: 'border-box',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      lineHeight: 1.75,
      borderRadius: '4px',
      textTransform: 'none',
      backgroundColor: '#6B2C57',
      color: '#ffffff',
      cursor: 'pointer'
    },
    loader: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    subSection: {
      marginLeft: '20px',
      marginRight: '20px',
      marginBottom: '20px',
      padding: '10px',
      backgroundColor: '#f4f4f4',
      borderRadius: '4px'
    },
    endButtons: {
      borderRadius: '4px'
    },
    drawerWidth: {
      width: 520,
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      overflowX: 'hidden',
      overflowY: 'hidden'
    },
  }
))

function AddCategoryDialog(props) {

  const classes = useStyles()
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
  const openState = useSelector((state) => state.categoryApp.formViewDialog)
  const { data: editFromState } = useSelector(({ categoryApp }) => categoryApp.formViewDialog);
  const [description, setDescription] = useState('')
  const [openAlerModal, setOpenAlertModal] = useState(false);
  const schema = yup.object({
    category: yup.string().required('Asset Category is required!').matches(/\S/, 'Asset Category cannot be only spaces!'),
  })

  const defaultValues = {
    category: '',
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const onCancel = () => {
    reset(defaultValues)
    dispatch(closeFormViewerDialog())
    dispatch(setFromData(null))
    setDescription('')
  }

  const closeDialog = () => {
    dispatch(closeFormViewerDialog())
    dispatch(setFromData(null))
    setDescription('')
  }

  function onEditorContentChange(content) {
    setDescription(content)
  }

  const manageFormData = (formData) => {

    let payload
    let api_url

    if (!editFromState) {
      //add payload
      payload = {
        entity_external_platform_id: String(Math.trunc(Math.random() * 20000000)),
        category_name: formData.category.trim(),
        category_type: 'hardware',
        description: description.trim()
      }
      api_url = '/category/add'

    } else {
      //edit payload
      api_url = `/category/edit?category_type=hardware`
      payload = {
        category_id: editFromState.id,
        category_name: formData.category.trim(),
        description: description.trim()
      }
    }

    axios.post(api_url, payload).then(response => {
      if (response.status == 201) {
        toast.error(response.data.message)
      } else {
        reset(defaultValues)
        closeDialog()
        toast.success(response.data.message)
        dispatch(setQuickFilter(null))
        props.refreshlist()
      }
    }).catch(err => {
      console.log('212=>', err);
      toast.error('Something went wrong!')
    })
  }

  useEffect(() => {
    if (editFromState) {
      setValue('category', editFromState.category_name)
      setDescription(editFromState.original_info.description)
    }
  }, [editFromState])

  const handleAlertModal=(_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenAlertModal(!openAlerModal)
}

const handleCloseAlertModal=() =>{
    setOpenAlertModal(false)
    onCancel()
}

  return (
    <>
    <div>
      <Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
        <div className='heading_holder'>
          <h4 className='heading'>{editFromState ? 'Update' : 'Add'} Asset Category</h4>
          <IconButton style={{ padding: '0px' }} onClick={handleAlertModal}>
            <Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
          </IconButton>
        </div>
        <div className='progressbar_holder'>
          <div className='progress'>
            &nbsp;
          </div>
        </div>

        <div style={{ paddingTop: '30px' }} className='cstm_wizard_holder'>
          <Box sx={{ width: '100%' }} className='box_holder'>
            <div className='main_content_holder'>
              <div className='content_holder'>
                <div className="text-left">
                  <div className='mb-10'>
                    <Typography className='status_text'>
                      Asset Category
                      <span className='required_span'>*</span>
                    </Typography>
                    <Controller
                      name='category'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant='outlined'
                          required
                          autoFocus
                          fullWidth
                          style={{ background: 'white' }}
                          error={!!errors.category}
                          helperText={errors?.category?.message}
                          FormHelperTextProps={{
                            style: {
                              margin: 0,
                              width: '100%',
                              paddingTop: '2px'
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className='mb-10'>
                    <Typography className='status_text'>
                      Description
                    </Typography>
                    <Editor
                      editorContent={description}
                      onEditorContentChange={onEditorContentChange}
                      moduleType='asset_category'
                    />
                  </div>
                </div>
              </div>
              <div className='btn_holder'>
                <Button
                  color="inherit"
                  onClick={handleAlertModal}
                  className='back_btn secondary_btn'
                >
                  Cancel
                </Button>
                <Button onClick={handleSubmit(manageFormData)} className='next_btn'>
                  Proceed
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </Drawer>

    </div>

  {openAlerModal &&
    <AlertModal  openAlerModal module="asset category" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
  }
  </>
  )
}

export default AddCategoryDialog
