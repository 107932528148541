import axios from '@fuse/utils/axios'
import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import OTPInput from 'react-otp-input' 

function OtpValidatingForm({ setPageComponent, toast, currentEmail, setCurrentEmail, otp, setOtp }) {

  const onAPICall = () => {

    const payload = {
      email: currentEmail,
      verificationCode: otp
    }

    axios.post('/end-user/verify-email', payload).then(res => {
      if(res.data.statusCode === 201){
        setOtp('')
        toast.error(res.data.message)
      }
      else if(res.data.statusCode === 202){
        toast.error(res.data.message)
        setOtp('')
        setCurrentEmail('')
        setPageComponent(1)
      }
      else {
        setOtp('')
        setPageComponent(3)
        toast.success('Verification Successful.')
      }
    }).catch(err => {
      toast.error('Something went wrong!')
    })
  }

  const onSubmitOTP = (event) => {
    // console.log('82=>',otp)
    event.preventDefault()
    onAPICall()
  }

  return (
    <>
      <form
            name="otpForm"
            noValidate
            className="flex flex-col justify-start w-full"
            onSubmit={onSubmitOTP}
        >
            
            <OTPInput
                inputStyle={{ width: '500%', border: '1px solid #999', height:'40px', borderRadius: '4px', fontSize: '20px' }}
                inputType='text'
                value={otp}
                onChange={setOtp}
                numInputs={6}
                // className={`my-otp-input`}
                renderSeparator={<span style={{ marginRight: '10px' }}></span>}
                renderInput={(props) => <input className='myOtp' {...props} />}
               containerStyle={`my-otp-input`}
            />
            
            <Button
                variant="contained"
                style={{ backgroundColor: '#F9B84B', borderRadius: '4px' }}
                className="w-50 mr-auto mt-16"
                aria-label="Continue"
                // disabled={_.isEmpty(dirtyFields) || !isValid}
                type="submit"
            >
                Continue
            </Button>
        </form>
    </>
  )
}

export default OtpValidatingForm
