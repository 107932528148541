const initialState = {
    open: false,
    data: null,
    accessory_data : null,
    edit_user_data:null,
}

const actionTypes = {
    OPEN_FORM_VIEWER_DIALOG: 'OPEN_FORM_VIEWER_DIALOG',
    CLOSE_FORM_VIEWER_DIALOG: 'CLOSE_FORM_VIEWER_DIALOG',
    SET_FROM_DATA: 'SET_FROM_DATA',
    OPEN_USER_VIEWER_DIALOG: 'OPEN_USER_VIEWER_DIALOG',
    CLOSE_USER_VIEWER_DIALOG: 'CLOSE_USER_VIEWER_DIALOG',
    SET_ACCESSORY_DETAILS: 'SET_ACCESSORY_DETAILS',
    SET_USER_FORM_DATA:'SET_USER_FORM_DATA',

}

export function openFormViewerDialog() {
    return {
        type: actionTypes.OPEN_FORM_VIEWER_DIALOG
    }
}
export function closeUserFormDialog() {
    return {
        type: actionTypes.CLOSE_USER_VIEWER_DIALOG
    }
} 

export function openUserFormDialog() {
    return {
        type: actionTypes.OPEN_USER_VIEWER_DIALOG
    }
}

export function setUserFromData(data=null) {
    return {
        type: actionTypes.SET_USER_FORM_DATA,
        payload: {
            edit_user_data:data
		}
    }
}

export function setFromData(data=null) {
    return {
        type: actionTypes.SET_FROM_DATA,
        payload: {
			data
		}
       }
}
export function setAccessoryData(data=null) {
    return {
        type: actionTypes.SET_ACCESSORY_DETAILS,
        payload: {
            accessory_data:data
		}
    }
}  



export function closeFormViewerDialog() {
    return {
        type: actionTypes.CLOSE_FORM_VIEWER_DIALOG
    }
}

export default function (state = initialState, action) {
    switch(action.type) {
        case actionTypes.OPEN_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open: true
            }
        }

        case actionTypes.CLOSE_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open:false
            }
        }

        case actionTypes.SET_USER_FORM_DATA: {
            return {
                ...state,
                edit_user_data: action.payload.edit_user_data
            }
        }

        case actionTypes.OPEN_USER_VIEWER_DIALOG: {
            return {
                ...state,
                user_form: true
            }
        }
        case actionTypes.SET_ACCESSORY_DETAILS: {
            return {
                ...state,
                accessory_data: action.payload.accessory_data
            }
        }

        case actionTypes.CLOSE_USER_VIEWER_DIALOG: {
            return {
                ...state,
                user_form:false
            }
        }

        case actionTypes.SET_FROM_DATA: {
            return {
                ...state,
                data: action.payload.data
            }
        }

        default:
            return state
    }
}