import axios from 'axios';

// function getAccessToken(){
//     return window.localStorage.getItem('jwt_access_token');
// }

// const isUserLoggedIn = Boolean(getAccessToken());

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_API_URL}`, //REACT_APP_BACKEND_API_URL / REACT_APP_BACKEND_URL_LOCAL
});

// if(isUserLoggedIn){
//     axiosInstance.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;
// }

export const token = {token: ''};

export default axiosInstance