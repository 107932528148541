
import { React, useState, Fragment, useRef, useEffect } from "react";
import { Button } from "@mui/material";
import { Editor } from '@tinymce/tinymce-react';
import axios from "@fuse/utils/axios";
import { usePusher, useRouter } from "@fuse/hooks";
import FuseLoading from "@fuse/core/FuseLoading";
import NoResultView from '@fuse/components/NoResultView'

function Conversation() {
  const BROADCAST_EVENT_NAME = 'service_req_chat_new_message_from_agent'

  const router = useRouter()
  const serviceRequestId = router.params.id;
  const editorRef = useRef(null);
  const { broadcastChannel } = usePusher();
	const [ replyMode, setReplyMode ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ chatMessages, setChatMessages ] = useState([]);

  const handleOpenReply = () => {
		setReplyMode(true)
	}

	const handleCloseReply = () => {
		setReplyMode(false)
	}

  function onEditorChange( content ){
    editorRef.current.currentContent = content
  }

  function formatDate( UTCdate ){
    //Convert UTC date into Local date
    const localDate = new Date(UTCdate);
    const now = new Date();

    // Check if the localDate is today
    const isToday = localDate.toDateString() === now.toDateString();
  
    // Define arrays for days and months
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    // Get components of the localDate
    const dayOfWeek = daysOfWeek[localDate.getDay()];
    const day = localDate.getDate();
    const month = monthsOfYear[localDate.getMonth()];
    const year = localDate.getFullYear();
  
    // Format the time to the nearest minute and handle AM/PM
    let hours = localDate.getHours();
    let minutes = localDate.getMinutes();
  
    // Round minutes to the nearest multiple of 5
    minutes = Math.round(minutes / 5) * 5;
  
    // Handle rounding up to the next hour if minutes are 60
    if (minutes === 60) {
      minutes = 0;
      hours++;
    }
  
    // Handle AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;  // Convert to 12-hour format
  
    // Pad minutes with leading zero if needed
    const minutesStr = String(minutes).padStart(2, '0');
    return {
      date: isToday ? "Today" : `${dayOfWeek}, ${month} ${day}, ${year}`,
      time: `${hours}:${minutesStr} ${ampm}`
    };
  }

  function formatMessages( messages ){
    return messages.map((message)=>{
      const { date, time } = formatDate(message.message_at)
      return {
        user_name: `${message.sender.first_name} ${message.sender.last_name}`,
        user_type: message.sender.portal === "SAM" ? 'You' : 'Agent',
        message_date: date,
        message_time: time,
        message_content: message.message
      }
    })
  }

  function fetchMessages(){
    setLoading(true)
    axios.get(`/service/${serviceRequestId}/chats`).then((res)=>{
      setChatMessages(formatMessages(res.data.data.chats))
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
  }

  function sendMessage(){
    if(!editorRef.current.currentContent.length) return
    axios.post(`/service/${serviceRequestId}/chat/send`,{
      message: editorRef.current.currentContent
    }).then(()=>{
      handleCloseReply()
      fetchMessages()
    }).catch((err)=>{

    })
  }

  function markServiceRequestWithNoUnReadMessages(){
    if(!serviceRequestId) return
    axios.put(`/service/${serviceRequestId}/mark-no-unread-messages`).then(()=>{
    }).catch((err)=>{})
  }

  useEffect(()=>{
		if(broadcastChannel){
			broadcastChannel.bind(BROADCAST_EVENT_NAME, data => {
				if(data.service_request_id === Number(serviceRequestId)){
					fetchMessages();
				}
			})
			return () => broadcastChannel.unbind(BROADCAST_EVENT_NAME)
		}
	},[broadcastChannel])

  useEffect(()=>{
    fetchMessages()
    return () => {
      markServiceRequestWithNoUnReadMessages()
    }
  },[]);

  if(!chatMessages.length){
    return (
      <NoResultView moduleName={'Conversation'}/>
    )
  }

  return (
    <div>
      <div className='flex flex-wrap justify-between items-center mb-20'>
        <h4 className='medium_heading'></h4>
      </div>

      {replyMode && <>
        {/* <div className='subject_holder mb-12'>
          <span className='cstm_label_holder pr-8'>Subject:</span>
          <span className='content_holder'>Tell us The Exact Problem</span>
        </div> */}
        <div>
          <span className='inline-block cstm_label_holder pb-12'>Description</span>
          <Editor
            apiKey="rizevt0is9zmdrbesb6gkhvjtzmi8wpejsr6f8qw0hoh6uj4"
            onEditorChange={onEditorChange}
            ref={editorRef}
            init={{
              height: 250,
              menubar: false,
              plugins: ['link' ,  'autolink ', 'quickbars',
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount', 'tinydrive'
              ],
              toolbar:
              'link'+
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | insertfile | ' +
              'removeformat',  
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px, }',
            }}
          />
          <div className='text-right my-20'>
            <Button className='primary_maroon_btn mr-12' onClick={sendMessage}>
              <i className='ti ti-send icon' />
              <span>Send</span>
            </Button>
            <Button className='primary_maroon_outlined_btn' onClick={() => handleCloseReply()}>
              <i className='ti ti-x icon' />
              <span>Cancel</span>
            </Button>
          </div>
        </div>
      </>}

      {loading ? <FuseLoading/> : 
        (!replyMode && !chatMessages.length) ? (
          <div style={{ display: 'flex', justifyContent: 'center',
            flexDirection: 'column', alignItems: 'center'
          }}>
            <h2>No messages</h2>
            <Button className='primary_maroon_btn' onClick={() => handleOpenReply()}>Start Conversation</Button>
          </div>
        ) : 
        chatMessages.map(( message, index ) => {
          return(
            <Fragment key={index}>
              <div className='conversion_holder agent_holder'>
                <div className='top_holder'>
                  <div className='left_holder'>
                    <div className='rounded_img_holder'>
                      <img src='https://react-material.fusetheme.com/assets/images/avatars/brian-hughes.jpg' alt='' />
                    </div>
                    <div className='name_holder'>
                      {message.user_name}
                    </div>
                    <div className='badge_holder'>
                      {message.user_type}
                    </div>
                  </div>
                  <div className='right_holder'>
                    <span className='date_holder'>{message.message_date}</span>
                    <span className='time_holder'>{message.message_time}</span>
                  </div>
                </div>
                <div className='bottom_holder'>
                  <p className='content_holder' dangerouslySetInnerHTML={{__html: message.message_content}}></p>
                </div>
              </div>
            </Fragment>
          )
        })
      }
    </div>
  )
}

export default Conversation