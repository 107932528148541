import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import { Box, Grid } from "@material-ui/core"
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
// import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Icon from '@material-ui/core/Icon';

import Comp1 from './Comp1';
import Comp2 from './Comp2';
import Comp3 from './Comp3';
import Comp4 from './Comp4';
import Comp5 from './Comp5';
import Comp6 from './Comp6';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  label: {
    color: "green",
  },
  stepperLabel:{
     ".MuiStepIcon-completed": { color: "green",fill:"green" },
  }
}));

function getSteps() {
  //   return ["Select campaign settings", "Create an ad group", "Create an ad"];
  return [
    {
      id: 0,
      label: "Manufacturers",
      subLabel: "Lorem Ipum Dolor Sit",
    },
    {
      id: 1,
      label: "Categories",
      subLabel: "Lorem Ipum Dolor Sit",
    },
    {
      id: 2,
      label: "Status",
      subLabel: "Lorem Ipum Dolor Sit",
    },
    {
      id: 3,
      label: "Models",
      subLabel: "Lorem Ipum Dolor Sit",
    },
    {
      id: 4,
      label: "Location",
      subLabel: "Lorem Ipum Dolor Sit",
    },
    {
      id: 5,
      label: "Assets",
      subLabel: "Lorem Ipum Dolor Sit",
    },
  ];
}

function getStepContent(step) {
  if (step === 0) {
    return `Lorem Ipum Dolor Sit`;
  } else if (step === 1) {
    return `Lorem Ipum Dolor Sit`;
  }
  else if (step === 2) {
    return `Lorem Ipum Dolor Sit`;
  }
  else if (step === 3) {
    return `Lorem Ipum Dolor Sit`;
  }
  else if (step === 4) {
    return `Lorem Ipum Dolor Sit`;
  }

  else {
    return `Lorem Ipum Dolor Sit`;
  }
}




const WizardScreenDailog = ({ open, fullWidth, maxWidth, handleClose }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {/* <DialogTitle id="max-width-dialog-title">Lorem Ipsum Dolor</DialogTitle> */}
        {/* <DialogTitle className='cursor-pointer text-4xl' style={{ textAlign: "right" }}><Icon onClick={handleClose}><span class="material-symbols-outlined">
                    close
                </span></Icon></DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <form>
              <Grid container spacing={2}>
                <Grid item lg={3} style={{ borderRight: "2px solid #f8f8f8" }}>
                  <Stepper className={classes.stepperLabel} activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                      <Step key={label} >
                        <StepLabel className='steplabel' sx={{
                          fontSize: '28px', // Increase the font size as desired
                        }} >{label.label}</StepLabel>

                        <StepContent >
                          <Typography className={classes.label}>
                            {getStepContent(index)}
                            {/* {label.id === index && <>{getStepContent(index)}</>} */}
                          </Typography>
                          {/* <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </Button>
                      </div>
                    </div> */}
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>

                {
                  activeStep !== steps.length && (
                    <Grid item lg={5}>
                      {activeStep === 0 && (
                        <Comp1
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          handleNext={handleNext}
                          handleBack={handleBack}
                        />
                      )}
                      {activeStep === 1 && (
                        <Comp2
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          handleNext={handleNext}
                          handleBack={handleBack}
                        />
                      )}
                      {activeStep === 2 && (
                        <Comp3
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          handleNext={handleNext}
                          handleBack={handleBack}
                        />
                      )}
                      {activeStep === 3 && (
                        <Comp4
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          handleNext={handleNext}
                          handleBack={handleBack}
                        />
                      )}
                      {activeStep === 4 && (
                        <Comp5
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          handleNext={handleNext}
                          handleBack={handleBack}
                        />
                      )}
                      {activeStep === 5 && (
                        <Comp6
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          handleNext={handleNext}
                          handleBack={handleBack}
                        />
                      )}
                      {activeStep === steps.length && (
                        <Paper square elevation={0} className={classes.resetContainer}>
                          <Typography>
                            All steps completed - you&apos;re finished
                          </Typography>
                          <Button onClick={handleReset} className={classes.button}>
                            Reset
                          </Button>
                        </Paper>
                      )}
                    </Grid>
                  )
                }



                {
                  activeStep !== steps.length && (
                    <Grid item lg={4} style={{ background: "#f8f8f8", padding: "20px",height:"500px",overflow:"auto" }}>
                      {activeStep === 0 && (
                        <>
                          <Typography variant='h5' className='relative z-10 text-black font-medium mb-20'>Ready to Begin? Let's go!</Typography>
                          <Typography variant='subtitle1' className='text-black font-medium'>Step1 - Add Manufacturers
                          </Typography>

                          <Box className='mt-28'>
                            <Typography className='mb-16'>Enter the name and website address of each manufacturer of your assets. Common manufacturers include Dell, HP, Apple, Cisco, and Juniper. After adding one, click “Add More” until you've added all of your asset manufacturers.
                            </Typography>
                            <Typography className='mb-16'>When finished, you can “Go Back” to the Getting Started Menu or click “Next” to go to the next step.
                            </Typography>
                            {/* <Typography>Once you've finished, you can choose to "Return to the Getting Started Menu" at any time. Remember, we're here to make asset management simpler and more efficient for you. So, let's get started with your manufacturers!</Typography> */}
                          </Box>
                        </>
                      )}
                      {activeStep === 1 && (
                        <>
                          <Typography variant='h5' className='relative z-10 text-black font-medium mb-20'>Nice work! Keep it up.
                          </Typography>
                          <Typography variant='subtitle1' className='text-black font-medium'>Step2 - Create Asset Categories</Typography>

                          <Box className='mt-28'>
                            <Typography className='mb-16'>Now that you have added your manufacturers, let's move on to categorizing your assets. This step is all about organization and making it easier for your to manage your assets</Typography>
                            <Typography className='mb-16'>Asset categories could include things like laptops, network switches, printers, and more. Think about the types of assets in your organization and how you would like to group them.
                            </Typography>
                            <Typography>Remember to click “Add More” until you have added all categories. Then, click “Next” to go to the next step.
                            </Typography>
                          </Box>
                        </>
                      )}
                      {activeStep === 2 && (
                        <>
                          <Typography variant='h5' className='relative z-10 text-black font-medium mb-20'>Fantastic! Your're making great progress.
                          </Typography>
                          <Typography variant='subtitle1' className='text-black font-medium'>Step3 - Add Asset Status Options</Typography>

                          <Box className='mt-28'>
                            <Typography className='mb-16'>In this step, you'll create statuses that will be assigned to each asset. Here are some examples:
                            </Typography>
                            <Box px={2}>
                              <Box className='flex'>
                                <Typography className='mb-4 mr-10'>1.
                                </Typography>
                                <Typography className='mb-4'><b>Ready for Deployment
                                </b>
                                </Typography>
                              </Box>
                              <Box className='flex'>
                                <Typography className='mb-4 mr-10'>2.
                                </Typography>
                                <Typography className='mb-4'><b>Assigned</b>
                                </Typography>
                              </Box>
                              <Box className='flex'>
                                <Typography className='mb-4 mr-10'>3.
                                </Typography>
                                <Typography className='mb-4'><b>Pending Reset
                                </b>
                                </Typography>
                              </Box>
                              <Box className='flex'>
                                <Typography className='mb-4 mr-10'>4.
                                </Typography>
                                <Typography className='mb-4'><b>Spare</b>
                                </Typography>

                              </Box>
                              <Box className='flex'>
                                <Typography className='mb-4 mr-10'>5.
                                </Typography>
                                <Typography className='mb-4'><b>Awaiting Repair
                                </b>
                                </Typography>

                              </Box>
                              <Box className='flex'>
                                <Typography className='mb-4 mr-10'>6.
                                </Typography>
                                <Typography className='mb-4'><b>Recycled</b>
                                </Typography>

                              </Box>
                            </Box>

                            <Typography className=''>Cick “Add More” until you have added all status options. Then, click “Next” to go to the next step.
                            </Typography>
                          </Box>
                        </>
                      )}
                      {
                        activeStep === 3 && (
                          <>
                            <Typography variant='h5' className='relative z-10 text-black font-medium mb-20'>Great job! Almost there</Typography>
                            <Typography variant='subtitle1' className='text-black font-medium'>Step4 - Identifying Your Asset Models</Typography>

                            <Box className='mt-28'>
                              <Typography className='mb-16'>Now, we're going to use the manufacturers and categories you've just created to help define your asset models. This step is crucial to ensure you can easily track each specific asset in your inventory.</Typography>
                              <Typography className='mb-16'>you will need to provide the following details:

                              </Typography>
                              <Box px={2}>
                                <Box className='flex'>
                                  <Typography className='mb-4 mr-10'>1.
                                  </Typography>
                                  <Typography className='mb-4'><b>Model Number</b>: This is the specific number given by the manufacturer to identify the model of the asset.
                                  </Typography>
                                </Box>
                                <Box className='flex'>
                                  <Typography className='mb-4 mr-10'>2.
                                  </Typography>
                                  <Typography className='mb-4'><b>Model Name</b>: This is the name given to the model, also provided by the manufacturer.
                                  </Typography>
                                </Box>
                                <Box className='flex'>
                                  <Typography className='mb-4 mr-10'>3.
                                  </Typography>
                                  <Typography className='mb-4'><b>Category</b>: Choose the category you've created that this model belongs to.
                                  </Typography>
                                </Box>
                                <Box className='flex'>
                                  <Typography className='mb-4 mr-10'>4.
                                  </Typography>
                                  <Typography className='mb-4'><b>Manufacturer</b>: Select the manufacturer of this model from the ones you've added in the first step.
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography>Remember, the more specific you are with these details, the easier it will be to manage your assets later.</Typography>
                            </Box>
                          </>
                        )
                      }
                      {
                        activeStep === 4 && (
                          <>
                            <Typography variant='h5' className='relative z-10 text-black font-medium mb-20'>Congratulations! Just one more step before adding assets.
                            </Typography>
                            <Typography variant='subtitle1' className='text-black font-medium'>Step5 - Identify Asset Locations
                            </Typography>

                            <Box className='mt-28'>

                              <Typography className='mb-16'>Knowing where your assets are located is vital for efficient asset management. In this step, you’ll provide the location of the asset.


                              </Typography>
                              {/* <Box px={2}>
                            <Box className='flex'>
                              <Typography className='mb-4 mr-10'>1.
                              </Typography>
                              <Typography className='mb-4'><b>Model Number</b>: This is the specific number given by the manufacturer to identify the model of the asset.
                              </Typography>
                            </Box>
                            <Box className='flex'>
                              <Typography className='mb-4 mr-10'>2.
                              </Typography>
                              <Typography className='mb-4'><b>Model Name</b>: This is the name given to the model, also provided by the manufacturer.
                              </Typography>
                            </Box>
                            <Box className='flex'>
                              <Typography className='mb-4 mr-10'>3.
                              </Typography>
                              <Typography className='mb-4'><b>Category</b>: Choose the category you've created that this model belongs to.
                              </Typography>
                            </Box>
                            <Box className='flex'>
                              <Typography className='mb-4 mr-10'>4.
                              </Typography>
                              <Typography className='mb-4'><b>Manufacturer</b>: Select the manufacturer of this model from the ones you've added in the first step.
                              </Typography>
                            </Box>
                          </Box> */}
                              {/* <Typography className='mb-16' >In this step, we'll ask you to provide the location information for your assets. This could be as broad as a city for distributed assets or as specific as a room or office number for assets in a single building.
                            Go ahead and enter your asset locations now.
                          </Typography> */}
                              <Typography>Remember, you can click “Add More” or “Go Back” to return to the main menu and complete this task at a later time.
                              </Typography>
                            </Box>
                          </>
                        )
                      }
                      {
                        activeStep === 5 && (
                          <>
                            <Typography variant='h5' className='relative z-10 text-black font-medium mb-20'>You've done it!
                            </Typography>
                            <Typography variant='subtitle1' className='text-black font-medium'>Step6 - Adding Assets</Typography>

                            <Box className='mt-28'>
                              <Typography className='mb-16'>You've done an incredible job following the setup process. Now, you're ready to enter individual assets.</Typography>
                              <Typography className='mb-16'>Before getting started, you'll want to collect some information about each asset.

                              </Typography>
                              <Box px={2}>
                                <Box className='flex'>
                                  <Typography className='mb-4 mr-10'>1.
                                  </Typography>
                                  <Typography className='mb-4'><b>Serial Number:</b> This is the unique identifier given to the asset by the manufacturer.
                                  </Typography>
                                </Box>
                                <Box className='flex'>
                                  <Typography className='mb-4 mr-10'>2.
                                  </Typography>
                                  <Typography className='mb-4'><b>Asset Name:</b> This is how you identify the asset.

                                  </Typography>
                                </Box>
                                <Box className='flex'>
                                  <Typography className='mb-4 mr-10'>3.
                                  </Typography>
                                  <Typography className='mb-4'><b>Asset Tag (if applicable):</b> Asset Tag (if applicable): If your organization tags assets, this is the internal tracking number.

                                  </Typography>
                                </Box>

                              </Box>
                              <Typography className='mb-8'>In this step, you'll see how all of the prior steps have contributed to make adding assets easy.  You will simply select the asset status, model and location from the drop down menu. Feel free to add any notes that help you track the asset.
                              </Typography>
                              <Typography>
                                Click “Add More” until all assets have been added. Or you can use the “Go Back” option to return to the main menu and complete this step at a later time. Once all assets have been added click “Save to Complete”
                              </Typography>
                            </Box>
                          </>
                        )
                      }
                    </Grid>
                  )
                }

                {
                  activeStep === steps.length && (
                    <Grid item lg={9}>
                      <Box className='ws_lastStep_bg w-full h-full'>
                        <Box className='ws_lastStep_bg_content text-center'>
                          <Typography className='final_text'>Congratulations!</Typography>
                          <Box className='flex justify-between items-center'>
                            <Typography style={{ width: "500px" }} className='mr-8 final_subText'>You’ve just increased your productivity by making asset management tracking easy.
                            </Typography>
                            <button className='ws_subscription_btn'>Add Subscriptions</button>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )
                }


              </Grid>
            </form>

          </DialogContentText>

        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  )
}

export default WizardScreenDailog
