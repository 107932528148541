import React, {useState, useRef, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import useToast from "@fuse/hooks/useToast";
import PreviewData from './PreviewData'
import { Tabs, Tab, Breadcrumbs, Button, Select, MenuItem, FormControlLabel, Checkbox, Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import uploadImg from "../images/upload.png";
import tickIcon from "../images/icons8-tick 1.gif";
import checkedIcon from "../images/check-verified-03.png";
import alertTriangle from "../images/alert-triangle.png"
import FuseLoading from '@fuse/core/FuseLoading';

export default function MapDataComponent({mappedFields, setUploadApiCall, uploadApiCall, 
	overWrite, handleNext, contentView, moduleName, setOverWrite}) {
  const formData_ = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.formData
  );
  const dispatch = useDispatch();
  const toast = useToast(dispatch);

  const [uploadSummary, setUploadSummary] = useState({});
  const [uploadErrors, setUploadErrors] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [successCsvData, setSuccessCsvData] = useState([]);
  const [errorCsvData, setErrorCsvData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
	

	const moduleUploadApiMap = {
		"asset":'/assets/csv-upload',
		"asset_category": '/category/csv-upload',
		"manufacturer": '/manufacturers/csv-upload',
		'model': '/models/csv-upload',
		'accessory': '/accessory/csv-upload',
		'asset_status': '/asset-status/csv-upload',
		'accessory_category': '/accessory-category/csv-upload',
		'contract': '/contracts/csv-upload',
		'service_provider': '/service-provider/csv-upload',
		'user': '/user/csv-upload',
		'location': '/shipping/csv-upload'
	}

	useEffect(()=>{
    if(uploadApiCall) handleFileUpload()
	},[uploadApiCall])

	function handleFileUpload() {
		setUploadApiCall(false)
		setLoading(true)
		const mappedData = mappedFields.filter((item) => item.value != "").reduce((acc, item) => {
			acc[item.field] = item.value == "Create new field" ? "" : item.value
			return acc;
		}, {})
		const formData = new FormData();
		formData.append("file", formData_);
		formData.append("mapped_data", JSON.stringify(mappedData));
		formData.append("overwrite", overWrite);
		setLoading(true);
		axios.post(moduleUploadApiMap[moduleName], formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(res => {
      handleClickOpenUploadModal()
			const { data } = res.data;
			const { summary, successData, errorData } = data;
			setUploadSummary(summary);
      setSuccessCsvData(successData)
      setErrorCsvData(errorData)
			if (summary.details && summary.details.length > 0) {
				setUploadErrors(summary.details.sort((el1, el2) => !el2.success ? 1 : -1));
			}
			setLoading(false)

		}).catch(err => {
			// handleNext('map')
			if (err.response) {
				setLoading(false)
				if (err.response.status == 409) {
					toast.error(err.response.data.message)
				}
			}
			console.log(err)
		})
	}

  const handleClickOpenUploadModal = () => {
		// setUploadApiCall(true)
		setOpenUploadModal(true);
	};

	const handleCloseUploadModal = () => {
		setOpenUploadModal(false);
	};
	return (
		<>
		{loading && <FuseLoading/>}
    {(viewDetails ) && <PreviewData tabValue={tabValue} successCsvData ={ successCsvData} errorCsvData = {errorCsvData} handleNext = {handleNext} contentView={contentView} setViewDetails = {setViewDetails} setOverWrite={setOverWrite}/>}

    {/* success upload modal */}
			<Dialog
				className="cstm_dialog_modal small"
				open={openUploadModal}
				onClose={handleCloseUploadModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<div className='heading_holder flex justify-end items-center w-full'>
						<Button className='transparent_icon_btn' onClick={handleCloseUploadModal}>
						<i className="icon ti ti-x" />
						</Button>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="warning_content_holder text-center mb-32">
						<img className="mx-auto" src={tickIcon} alt="tick" />
						<h2 className="heading">Uploaded Successfully</h2>
						<p className="sub_content">Congrats! Your CSV file has been successfully uploaded. You can see them by clicking on the below data</p>
					</div>
					<>
						<div className="each_list_holder" onClick={() => {
              setViewDetails(true)
              setOpenUploadModal(false)
							handleNext('preview')
							setTabValue(0)
              }}>
							<div className="left_holder">
								<div className="badge_container success_bg">
									<img src={checkedIcon} alt="icon" />
								</div>
								<div className="middle_container">
									<span className="success_text">{uploadSummary?.success}</span>
									<span>successful records</span>
								</div>
							</div>
							<div className="download_container">
								<i className="ti ti-download" />
							</div>
						</div>
						<div className="each_list_holder" onClick={() => {
              setViewDetails(true)
              setOpenUploadModal(false)
							handleNext('preview')
							setTabValue(0)
              }}>
							<div className="left_holder">
								<div className="badge_container success_bg">
									<img src={checkedIcon} alt="icon" />
								</div>
								<div className="middle_container">
									<span className="success_text">{uploadSummary?.update}</span>
									<span>updated records</span>
								</div>
							</div>
							<div className="download_container">
								<i className="ti ti-download" />
							</div>
						</div>
						<div className="each_list_holder" onClick={() => {
              setViewDetails(true)
              setOpenUploadModal(false)
							handleNext('preview')
							setTabValue(1)
              }}>
							<div className="left_holder">
								<div className="badge_container danger_bg">
									<img src={alertTriangle} alt="icon" />
								</div>
								<div className="middle_container">
									<span className="danger_text">{uploadSummary?.error}</span>
									<span>rejected records</span>
								</div>
							</div>
							<div className="download_container">
								<i className="ti ti-download" />
							</div>
						</div>
					</>
				</DialogContent>
			</Dialog>

		</>
	)
}