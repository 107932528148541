import FusePageSimple from '@fuse/core/FusePageSimple'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { usePageTitle } from '@fuse/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'

import Header from './header'

const useStyles = makeStyles({
    title:{
		fontSize: 14,
		fontWeight: 600,
		marginBottom:5
	},
    content:{
		padding:'15px',
        margin:'1rem auto'
	},
    root:{
        width:'97%',
        borderRadius:'4px',
        margin:'auto',
    },
    row:{
        display:'flex',
        marginBottom:'1rem'
    },
    box:{
        width: '80%',
        height: '40px',
        borderRadius: '4px'
    },
    txt:{
        padding: '10px',
        height:'40px'
    },
    marginDiv:{
        width: '95%',
        margin: '2rem auto',
        borderTop: '1px solid black'
    },
})

const requestQuote = () => {
    const dispatch = useDispatch();
    const classes = useStyles()
    const router = useRouter()
    const [details, setDetails] = useState({})
    const [assets, setAssets] = useState([])    
    const updatePageTitle = usePageTitle(dispatch);

    const fetchRequestDetail = () => {
        const id = router.params.id

        axios.get(`/quotes/request/get-request-detail?id=${id}`).then((res)=>{
            const {data} = res.data
            setDetails(data)
            setAssets(data.assets)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect( () => {
        fetchRequestDetail()
    }, [])

    console.log(details)

    const Title = (props)=>{
		return(
		<>
			<span style={{fontWeight: 'bold'}}>Request Quote</span>
			<span> </span>
		</>
		)
	}

    useEffect(()=>{
        updatePageTitle(<Title/>)
    },[])

    return (
        <FusePageSimple 
            // header={
            //     <Header />
            // }
            content={
                <Paper square style={{borderRadius:'4px'}} className={classes.root}>
                    <div className={classes.content}>
                        <Typography className={classes.title}>
                            Device Information
                        </Typography>

                        <TableContainer>
                            <Table aria-label="device information">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Serial No.</TableCell>
                                    <TableCell>SKU</TableCell>
                                    <TableCell>Asset Name</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {assets.map( row => {
                                        return (
                                            <TableRow key={row.asset_sku}>
                                                <TableCell>{row.serial_number}</TableCell>
                                                <TableCell>{row.asset_sku}</TableCell>
                                                <TableCell>{row.asset_name}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className={classes.marginDiv}>
                        </div>

                        <Typography className={classes.title}>
                            Contract Information -  {details.contract_type} Contract
                        </Typography>
                        {
                            details.contract_type == 'Existing' && 
                            <div className={classes.root}>
                                <div style={{border:'1px solid #cfc4c4', padding:'15px', borderRadius:'4px', marginBottom:'5px'}}>
                                    <Grid container>
                                        <Grid item xs={2} sm={4}>
                                            <span className={classes.title}>Contract No. : </span><span className={classes.pos}>{details.contract_id}</span>
                                        </Grid>
                                        <Grid item xs={2} sm={4}>
                                            <span className={classes.title}>Expire On : </span><span className={classes.pos}>{dateReformatTo_mmm_dd_yyyy(details.expire_on)}</span>
                                        </Grid>
                                        <Grid item xs={2} sm={4}>
                                            <span className={classes.title}>Status : </span><span className={classes.pos}>{JSON.stringify(details.status)}</span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        }
                        
                        <div className={classes.marginDiv}>
                        </div>

                        <Typography className={classes.title}>
                            Your Comment
                        </Typography>
                        <div className={classes.content}>
                            <Typography>{details.comment}</Typography>
                        </div>
                    </div>
                </Paper>
            }
        />
    )
}

export default requestQuote