import { combineReducers } from '@reduxjs/toolkit';
import dialogReducer from './dialogReducer';
import csvDialogReducer from './csvDialogReducer';
// import userReducer from './userReducer';

import reportViewDialogReducer from './reportViewDialogReducer';
import filterParamsReducer from './filterParamsReducer';
import formViewDialog from './formViewDialogReducer';
const reducer = combineReducers({
	dialogReducer,
	// userReducer,
	csvDialogReducer,
	reportViewDialogReducer,
	filterParamsReducer,
	formViewDialog
});

export default reducer;
