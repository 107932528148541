import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef} from 'react';
import { closeCsvDialog } from "../store/csvDialogReducer"
import useToast from '@fuse/hooks/useToast';
import axios from "@fuse/utils/axios";
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { Checkbox, Divider, FormControlLabel } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import AdjustIcon from '@material-ui/icons/Adjust';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { exportToCsv } from "@fuse/utils";
import exportExcel from '@fuse/utils/excel';

const useStyles = makeStyles(theme => ({
	dropZone: {
		border: "4px dashed #999",
		padding: 80
	},
	dropZoneDraging: {
		border: "4px dashed #6B2C57",
	}
}));

function CsvUpload(props) {	
	const classes = useStyles(props);
	const uploadInput = useRef();
	const dispatch = useDispatch();
	const scrollRef = useRef();
	const [menuProps, setMenuProps] = useState({
		PaperProps: {
			style: {
				height: 170,
				width: 250,
				transform: "translateY(100%)"
			},
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "center"
		},
		transformOrigin: {
			vertical: "bottom",
			horizontal: "center"
		},
		getContentAnchorEl: null
	})
	const user = useCurrentUser();
	const toast = useToast(dispatch);
	const dialogState = useSelector(({usersApp}) => usersApp.csvDialogReducer);
	const page = {
		UPLOAD_PAGE: 1,
		MAPPING_PAGE: 2,
		SUMMARY_PAGE: 3,
		SUMMARY_DETAILS_PAGE: 4
	}	
	const [currentPage, setCurrentPage] = useState(page.SUMMARY_PAGE);
	function goToPage(pageId){
		setCurrentPage(pageId ?? 1)
	}
	const [loading, setLoading] = useState(false);
	const [uploadedFileInfo, setUploadedFileInfo] = useState({});
	const [isFileSelected, setIsFileSelected] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [uploadSummary, setUploadSummary] = useState({});
	const [csvFields, setCsvFields] = useState([]);
	const [systemFields, setSystemFields] = useState([]);
	const [primaryFields, setPrimaryFields] = useState([]);
	const [allFields, setAllFields] = useState([]);
	const [mappedFields, setMappedFields] = useState([]);
	const [systemRequiredFields, setSystemRequiredFields] = useState([]);
	const [shouldOverwrite, setShouldOverwrite] = useState(false);
	const [uploadErrors, setUploadErrors] = useState([]);

	const ondivScroll = () => {
		if (scrollRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
			if(scrollTop>=0 && scrollTop<=180){
				setMenuProps({
					...menuProps,
					PaperProps: {
						style: {
							height: 170,
							width: 250,
							transform:"translateY(100%)"
						},
					},
				})
			}
		}
	}
	
	function handleFileInput(event, file = null){
		if(!file){
			file = uploadInput.current.files[0];
		}
		if (!file) {
			return;
		}
		setSelectedFile(file);
		setUploadedFileInfo({name: file.name, type: file.type, size: file.size});
		setIsFileSelected(true);
	}

	function handleFileUpload(){
		if(!selectedFile) return;
		const mappedData = mappedFields.filter((item) => item.value != "").reduce((acc, item) => {
			acc[item.field] = item.value == "Create new field" ? "" : item.value
			return acc;
		},{})
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("mapped_data", JSON.stringify(mappedData));
		formData.append("overwrite", shouldOverwrite);
		setLoading(true);

		axios.post('/accessory/csv-upload', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(res => {
			const { data } = res.data;
			const { summary } = data;
			setUploadSummary(summary);
			if(summary.details && summary.details.length > 0){
				setUploadErrors(summary.details.sort((el1, el2) => !el2.success ? 1 : -1));
			}

			//* Refresh List
			props.refresh()
			goToPage(page.SUMMARY_PAGE);
			if(parseInt(summary.success) > 0) {
				toast.success(`${parseInt(summary.success)} Accessory created successfully`);
			}
		}).catch(err => {
			if(err.response){
				if(err.response.status == 409){
					toast.error(err.response.data.message)
				}
			}
			console.log(err)
		}).finally(() => {
			setTimeout(() => {
				setLoading(false);
			}, 1000)
		});
	}

	function handleFieldMapping(){
		if(!selectedFile) return;
		const formData = new FormData();
		formData.append("file", selectedFile);
		setLoading(true);		
		axios.post('/accessory/get-csv-mapping-fields', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(res => {
			const { data } = res.data;
			const { csv_fields, system_fields, primary_fields, all_fields, system_required_fields, field_mapping } = data;
			setCsvFields(csv_fields)
			setSystemFields(system_fields);
			setAllFields(all_fields)
			setPrimaryFields(primary_fields)
			const filterdFields = field_mapping ? field_mapping : {};
			setMappedFields(csv_fields.map((item, index) => {
				return {
					field: item,
					value: filterdFields[item] ?? ""
				};
			}));
			setSystemRequiredFields(system_required_fields)
			goToPage(page.MAPPING_PAGE);
		}).catch(err => {
			if(err.response){
				if(err.response.status == 409){
					toast.error(err.response.data.message)
				}
			}
			console.log(err)
		}).finally(() => {
			setTimeout(() => {
				setLoading(false);
			}, 1000)
		});
	}

	function handleSetDefaultSettings(){
		setCurrentPage(page.UPLOAD_PAGE)
		setUploadedFileInfo({})
		setIsFileSelected(false)
		setUploadSummary({})
		setShouldOverwrite(false)
	}

	useEffect(() => {
		setSelectedFile(null);
		setTimeout(() => {
			handleSetDefaultSettings();
		}, 500);
	},[dialogState.open])

	function capitalize(s) {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	}

	function handleDownloadTemplateFile(filename = "import.csv", blob = null){
		let link = document.createElement("a");
		if (link.download !== undefined) {
			let url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filename);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	const accessoryTemplate = [
		[
			"Accessory Name",
			"Model Number",
			"Manufacturer Name",
			"Accessory Category Name",
			"Note"
		]
	]

	function handleInitDownload(){
		const templatFileBlob = exportToCsv(
			accessoryTemplate			
		);
		handleDownloadTemplateFile("accessory-import.csv", templatFileBlob);
	}
	
	const [isDraging, setIsDraging] = useState(false);

	function handleFileDragOver(event){
		event.preventDefault();
		if(!isDraging){
			setIsDraging(true)
		}
	}

	function handleFileDragLeave(event){
		event.preventDefault();		
		if(isDraging){
			setIsDraging(false)
		}
	}

	function handleFileOnDrop(event){		
		event.preventDefault();
		setIsDraging(false)
		if (event.dataTransfer.files.length) {
			const file = event.dataTransfer.files[0];
			setSelectedFile(file)
			handleFileInput({}, file);
		}
	}

	function CSVFieldMapInput(props){
		const {fieldValue, fields, itemIndex} = props;	
		return (
			<>
				<TextField 
					className="mt-5"
					variant="outlined"
					fullWidth 
					value={fieldValue} 
					inputProps={{
						readOnly: true,
						disabled: true,
					}}
					InputProps={{
						className: 'Mui-disabled text-black'
					}}
					onChange={() => {}}>
				</TextField>
			</>
		)
	}

	function SystemFieldMapInput(props){
		const {fieldValue, systemFields, itemIndex} = props;		
		const reformatCSVmappingField = (fieldName)=> fieldName.split('_').map(str=> str==='id' ? str.toUpperCase() : str.charAt(0).toUpperCase()+ str.slice(1)).join(' ')
		return (
			<>
				<FormControl style={{width: '100%'}}>
					<Select
						className="mt-5 w-100"
						variant="outlined"
						fullWidth 
						value={fieldValue}
						// MenuProps={menuProps}
						onChange={(event) => {
							setMappedFields((mapedItem) => {
								mapedItem[itemIndex] = {
									...mapedItem[itemIndex],
									value: event.target.value
								};
								return [...mapedItem]
							})
						}}
					>
						<MenuItem value="">None</MenuItem>
						<MenuItem disabled>
							<em><strong style={{fontWeight: 800}}>Primary fields</strong></em>
						</MenuItem>
						{primaryFields.map((option) => (
							<MenuItem key={option.field} 
								value={option.field} 
								disabled={mappedFields.map((item) => item.value).includes(option.field)}>

							  	<span>{option.label}</span>
								{option.field != 'email_address' && option.field != 'first_name' 
								&& option.field != 'last_name' && option.field != 'title' && (
								<span style={{color: "red"}}>*</span>)}
							</MenuItem>
						))}
						<MenuItem disabled>
							<em><strong style={{fontWeight: 800}}>System fields</strong></em>
						</MenuItem>
						{systemFields.map((optionField) => (
							<MenuItem key={optionField} 
								value={optionField} 
								disabled={mappedFields.map((item) => item.value).includes(optionField)}>
									{reformatCSVmappingField(optionField)} {systemRequiredFields.includes(optionField) && <span style={{color: "red"}}>*</span>}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</>
		)
	}

	function handelMappedDataDelete(index){
		const mappedFieldValue = mappedFields[index].value;
		if(!systemFields.includes(mappedFieldValue)){
			setMappedFields(item => item.filter((_, itemIndex) => itemIndex != index));
		}else{
			toast.error("System fields are required");
		}
	}

	function handleErrorLogDownload(){
		const headers = [
			{ header: "Row", key: "row_number", width: 5 },
			{ header: "Status", key: "status", width: 10 },
			{ header: "Errors", key: "errors", width: 200 }
		];
		
		const rows = uploadErrors.map(
			el => {
				return {row_number:el.row_number, status: el.success ? 'Success' : 'Error', errors: el.errors.join(', ')}
			}
		)
		exportExcel(headers, rows, `accessory_upload_error_log_${(new Date()).toISOString().split('T')[0]}`);
	}

	return (
		<Dialog 
			open={dialogState.open} 
			aria-labelledby="form-dialog-title" 
			maxWidth="sm" 
			fullWidth={true}
		>
			<DialogTitle id="form-dialog-title">
				Upload CSV for Accessory
				{!loading && currentPage === page.MAPPING_PAGE && (
					<Typography variant="body2" color="textSecondary" style={{
						marginTop: "11px",
						textAlign: "left",
						marginBottom: "-9px",
					}}>
						<span style={{color: "red"}}>*</span> Identify the column with a unique identifier in the file being uploaded as the Primary ID
					</Typography>
				)}
			</DialogTitle>
			<DialogContent ref={scrollRef} onScroll={ondivScroll}>
				{currentPage === page.UPLOAD_PAGE && <>
					<div>
						<div className="text-center" role="button">
							{!loading && <>
								<div style={{
									padding: 80,
									border: `4px dashed ${isDraging ? '#6B2C57' : '#999'}`
								}}
									onClick={() => uploadInput.current.click()} 
									onDragOver={handleFileDragOver} 
									onDragLeave={handleFileDragLeave} 
									onDrop={handleFileOnDrop}
								>									
									<Icon fontSize="large" color="action" title="Upload CSV">
										cloud_upload
									</Icon>
									{isFileSelected && <>
										<p style={{marginTop: 5}}>{uploadedFileInfo.name}</p>
										<Button style={{marginTop: 10}} onClick={(event) => {
											event.stopPropagation();
											handleFieldMapping();
										}} color="primary" variant="contained">
											Upload
										</Button>
									</>}
								</div>
							</>}
							{loading && <>
								<CircularProgress/>
							</>}
						</div>
					</div>
				</>}
				{currentPage === page.MAPPING_PAGE && <>
					<div style={{width: 540}}>
						<div>
							{!loading && <>
								<div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
									<div style={{padding: 5}}>
										<h4 className="text-center">Fields detected in CSV</h4>
										{mappedFields.map((({field}, index)=> <div key={index}>
											<div>
												<CSVFieldMapInput fieldValue={field} fields={csvFields} itemIndex={index}/>
											</div>
										</div>))}
									</div>

									<div style={{padding: 5}}>
										<h4 className="text-center">Available fields for Accessory</h4>
										{mappedFields.map((({value}, index)=> <div className="w-100" key={index}>
											<div className="w-100">
												<SystemFieldMapInput 
													fieldValue={value} 
													systemFields={systemFields}
													itemIndex={index}
												/>
											</div>
										</div>))}
									</div>
								</div>
							</>}

							{loading && <div className="text-center">
								<CircularProgress/>
							</div>}
						</div>
					</div>
				</>}
				{currentPage === page.SUMMARY_PAGE && <>
					<div>
						<div className="p-24 pt-4">
							<h2 className="text-green-500 text-center" style={{marginBottom: 10}}>
								<CheckCircleOutlineIcon/> File uploaded successfully
							</h2>
							<Divider/>
							<div style={{marginLeft: "auto", marginRight: "auto"}}>
								<h3 className="text-center" style={{marginTop: 10}}>{uploadedFileInfo.name}</h3>
								<h4 className="text-blue-500 mt-4 mb-8"><AdjustIcon/> Total: {uploadSummary.total}</h4>
								<h4 className="text-green-500 mb-8"><CheckCircleOutlineIcon/> Success: {uploadSummary.success}</h4>
								{parseInt(uploadSummary.update) ? (
									<h4 className="text-blue-500 mb-8"><CheckCircleOutlineIcon/> Updated: {uploadSummary.update}</h4>
								) : <></>}
								<h4 className="text-red-500 mb-8"><ErrorIcon/> Error: {uploadSummary.error}</h4>
							</div>
							{parseInt(uploadSummary.error) > 0 && <>
								<div className="text-center">
									<Button style={{marginTop: 5}} onClick={() => goToPage(page.SUMMARY_DETAILS_PAGE)} 
										color="primary" variant="contained">
										View summary
									</Button>
								</div>
							</>}
						</div>
					</div>
				</>}
				{currentPage === page.SUMMARY_DETAILS_PAGE && <>
					<div className="p-2" style={{height: 320, overflowY: "auto", overflowX: "hidden"}}>
						{uploadErrors.map((details,index) => (
							<>
							{details.success ? 
								details.updated 
								? <Alert severity="info" className="w-full" style={{
										padding: 18,
										borderRadius: 4,
										marginBottom: 5,
									}}>
										Row #{details.row_number} is successfully updated
								</Alert>
								: <Alert severity="success" className="w-full" style={{
										padding: 18,
										borderRadius: 4,
										marginBottom: 5,
								}}>
									Row #{details.row_number} is successfully inserted
								</Alert>
							: 
								<Accordion 
									style={{
									backgroundColor: "rgb(253, 236, 234)",
									padding: 0,
									marginBottom: 5,
								}}>
									<AccordionSummary
										style={{margin: 0, paddingLeft: 0}}
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`panel${index}a-content`}
										id={`panel${index}a-header`}>
											<Alert severity="error" className="w-full">
													Row #{details.row_number} has some errors
											</Alert>
									</AccordionSummary>

									<AccordionDetails style={{display: "block"}}>
										{details.errors.map((error) => (
											<>
											<Typography className="text-red-500">
												{error}
											</Typography>
											<br/>
											</>
										))}
									</AccordionDetails>
								</Accordion>
							}  
							</>
						))}
					</div>
				</>}
				<input
					ref={uploadInput}
					className="hidden"
					id="button-file"
					type="file"
					onChange={handleFileInput}
				/>
			</DialogContent>
			<DialogActions>				
				{currentPage === page.SUMMARY_DETAILS_PAGE && 
				<>
					<Button onClick={() => handleErrorLogDownload()} color="primary">
						Download Error Log
					</Button>
					<Button onClick={() => goToPage(page.SUMMARY_PAGE)} color="primary">
						Back
					</Button>
				</>}
				{currentPage === page.SUMMARY_PAGE && 
				<>
					<Button onClick={() => goToPage(page.MAPPING_PAGE)} color="primary">
						Back
					</Button>
				</>}
				{currentPage === page.MAPPING_PAGE && 
				<>
					<div style={{flex: "auto"}}>
						<FormControlLabel
							control={
								<Checkbox
									checked={shouldOverwrite}
									onChange={(event) => setShouldOverwrite(event.target.checked)}
								/>
							}
							className="ml-5"
							label="Overwrite"
						/>
					</div>
					<Button onClick={() => handleFileUpload()} color="primary">
						Import
					</Button>
					<Button onClick={() => goToPage(page.UPLOAD_PAGE)} color="primary">
						Back
					</Button>
				</>}
				{currentPage === page.UPLOAD_PAGE && 
				<>
					<Button onClick={handleInitDownload} color="primary">
						Download template file
					</Button>
				</>}
				<Button onClick={() => dispatch(closeCsvDialog())} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
  	)
}

export default CsvUpload;
