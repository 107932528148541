import { useCallback } from "react";
import { useSelector } from "react-redux";

export default function usePusher() {
  const { isReady, channel, channelName, broadcastChannel } = useSelector(({ fuse }) => fuse.pusher);

  const getUsers = useCallback(() => {
      return Object.values(channel?.members || {});
  }, []);

  const getUserInfo = useCallback((userIdentifier) => {
      return channel?.members?.get(userIdentifier) || null
  }, []);

  const isOnline = useCallback((userIdentifier) => {
      return Boolean(getUserInfo(userIdentifier))
  }, []);

  return {
    isReady,
    channel,
    channelName,
    broadcastChannel,
    onlineUsers: channel?.members?.count || 0,
    getUsers,
    getUserInfo,
    isOnline,
  }
}