import EndUsersAccountsTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { Button, Icon } from '@material-ui/core';
import { openCsvDialog } from "../store/csvDialogReducer"
import { useDispatch } from 'react-redux';
import CsvUpload from './CsvUpload';
import {Menu,MenuItem} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Media from 'react-media';
import IconButton from '@material-ui/core/IconButton';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { ReactComponent as GridView } from '../icons/grid_view.svg';
import { ReactComponent as ListView } from '../icons/list_view.svg';
import { ReactComponent as UploadIcon } from '../icons/upload.svg';
import SvgIcon from "@material-ui/core/SvgIcon";
import lodash from "lodash"

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [endUsersAccounts, setEndUsersAccounts] = useState([]);
	const router = useRouter();
	const agents = getAgents(endUsersAccounts);

	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function getAgents(endUsersAccounts){
		const agentSet = new Set();
		for(let i = 0; i < endUsersAccounts.length ; i++){
			agentSet.add(endUsersAccounts[i].Agent);
		}
		return Array.from(agentSet);
	}

	function search(query) {
		if(query == ''){
			setEndUsersAccounts(endUsersAccounts);	
		}else{
			setEndUsersAccounts(endUsersAccounts.filter(acc=>{
				let flag = false;
				let values = Object.keys(acc);
				for(let i = 0; i < values.length; i++){
					if(String(acc[values[i]]).toLowerCase().includes(query)){
						flag = true;
					}
				}
				return flag;
			}))
		}
	}

	function applyFilters(filters){
		setFilters(filters);
		setEndUsersAccounts(endUsersAccounts.filter(acc=>{
			let flag = true;
			if(filters.agent != '' && filters.agent != acc.Agent){
				flag = false;
			}
			return flag;
		}))
	}

	function clearFilters() {
		setFilters(defaultFilters);
		setEndUsersAccounts(endUsersAccounts);
	}

	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};

	const dispatch = useDispatch();

	function toggleView(val){
		setSelectedView(val);
	}

	function gotoDetailsPages(id){
		router.push(`contract/detail/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	function getContracts(){
		return axios.get("/billing/fetch-all").then((res) => {
				const { data } = res.data;
				setEndUsersAccounts(formatContracts(data))
		}).catch((err) => {
			console.log(err)
		})
	}
	
	function search(query) {
		if(!query) return;

		setLoading(true);

		axios.get("/contracts/search",{
			params: {
				query
			}
		}).then((res) => {
			const { data } = res.data;
			setEndUsersAccounts(formatContracts(data))
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setLoading(false);
		})
	}

	function formatContracts(data){
		return data.map((contract) => {
			const dynamicAttributes = Object.entries(contract.dynamic_attributes).reduce((acc, [key, item]) => {
				acc[key] = item.attribute_value

				return acc;
			},{});
			return {
				...lodash.omit(contract, [
					"user_companies_id",
					"user_companies_users_id",
					"user_company",
					"user_companies_user",
					"dynamic_attributes",
					"user_id",
					"platform_customer_id",
					"entity_external_platform_id",
					"created_at",
					"updated_at",
				]),
				company_name: contract.user_company.company_name,
				bill_to_code: contract.entity_external_platform_id,
				...dynamicAttributes,
			}
		})
	}

	function getDynamicAttributes(){
		return axios.get("/dynamic-attributes/fetch-all")
		.then((res) => {
			const { data } = res.data;
			const platformModuleItem = data.find(({name}) => name == "billing");
			setPlatformModule(platformModuleItem);
			return axios.get(`/dynamic-attributes/fetch/${platformModuleItem.id}`)
				.then((res) => {
					const { data } = res.data;
					setPlatformModuleAttributes(data)
					return data;
				})
				.catch(err => {
					toast.error("Unable to fetch dynamic attributes")
					console.log(err)
				})
		})
		.catch(err => {
			toast.error("Unable to fetch dynamic attributes")
			setPlatformModule({});
			console.log(err)
		})
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			return getContracts();
		})
	}

	useEffect(()=>{
		setLoading(true)
		getDynamicAttributes().then(() => {
			return getContracts();
		}).finally(() => {
			setLoading(false)
		});
	},[]);

	if(loading){
		return(<FuseLoading />)
	}else{
		return (<>
			<CsvUpload refresh={refreshList}/>
			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0'
				}}
				header={
					<DynamicFieldsHeader search={search} clearSearch={clearFilters}/>
				}
				contentToolbar={
					<>
						<Tabs
							value={selectedTab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="off"
							className="w-full h-64"
						>
							<Tab key="end_user_tab_1" className="h-64" label="Billing Locations" />
							{/* <Tab key="end_user_tab_2" className="h-64" label="My accounts" /> */}
							
						</Tabs>
						<div>
							<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
								{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={viewMenuOpened}
								onClose={handleViewMenuClose}
							>
								{	
									views.map((view,index) => {
										return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
									})
								}
							</Menu>
						</div>

						<div className="p-10">
							<Media queries={{
								medium: "(max-width: 900px)",
								large: "(min-width: 1200px)"
								}}
							>
								{
									matches => (
										<>
											{matches.large && 
												<Button
													onClick={() => dispatch(openCsvDialog())}
													variant="contained"
													color="secondary"
													className={`w-full ${classes.buttonSquare}`}
													style={{width:'150px'}}
													startIcon={
														<SvgIcon fontSize="small"><UploadIcon  color="action" /></SvgIcon>
													}>
														Upload CSV
												</Button>
											}
											{
												matches.medium && 
												<IconButton
												onClick={() => dispatch(openCsvDialog())}
												variant="contained"
												color="secondary"
												className={classes.buttonSquare}
												>
													<SvgIcon fontSize="small"><UploadIcon  color="action" /></SvgIcon>
												</IconButton>
											}
										</>
									)
								}
							</Media>
						</div>
						<div className="p-10">
							<Media queries={{
								medium: "(max-width: 900px)",
								large: "(min-width: 1200px)"
								}}
							>
								{
									matches => (
										<>
											{matches.large && 
												<Button
													variant="contained"
													color="secondary"
													className={`w-full ${classes.buttonSquare}`}
													style={{width:'150px'}}
													startIcon={
													<Icon fontSize="large" title="Save View">
														save
													</Icon>}>
														Save View
												</Button>
											}
											{
												matches.medium && 
												<IconButton
												onClick={() => dispatch(openCsvDialog())}
												variant="contained"
												color="secondary"
												className={classes.buttonSquare}
												>
													<Icon>
														cloud_upload
													</Icon>
												</IconButton>
											}
										</>
									)
								}
							</Media>
						</div>
					</>
				}
				contentToolbarSecondary={
					<Grid container spacing={3}  className="p-10">					
						<Grid item  xs={6} sm>
							<FormControl variant="outlined" fullWidth margin='dense'>
								<InputLabel htmlFor="cust_code_filter">Company Id</InputLabel>
								<Select
									native
									// value={filters.agent}
									// onChange={ev => applyFilters({...filters,agent:ev.target.value})}
									label="Company Id">
									{/* <option aria-label="None" value="" />
									{
										agents.map((agent,index) => {
											return <option key={`agent_${index}`} value={agent}>{agent}</option>
										})
									} */}
								</Select>
							</FormControl>
						</Grid>

						<Grid item  xs={6} sm >
							<FormControl variant="outlined" fullWidth margin='dense' >
								<InputLabel htmlFor="city_filter"> City</InputLabel>
								<Select
									native
									label ="City"
								></Select>
							</FormControl>
						</Grid>

						<Grid item  xs={6} sm >
							<FormControl variant="outlined" fullWidth margin='dense' >
								<InputLabel htmlFor="state_filter">State</InputLabel>
								<Select
									native
									label ="State"
								></Select>
							</FormControl>
						</Grid>

						<Grid item  xs={6} sm >
							<FormControl variant="outlined" fullWidth margin='dense' >
								<InputLabel htmlFor="country_filter">Country</InputLabel>
								<Select
									native
									label ="Country"
								></Select>
							</FormControl>
						</Grid>

						<Grid item xs sm className={`${classes.pullRight}`}>
							<Button
								onClick={clearFilters}
								variant="contained"
								color="secondary"
								className={classes.buttonSquare}>
									Clear search
							</Button>
						</Grid>
					</Grid>
				}
				content={
					<>
						<div className="p-10">
							<EndUsersAccountsTable 
								endUsersAccounts={endUsersAccounts}
								selectedView={selectedView}
								gotoDetailsPages={gotoDetailsPages}
								/>
						</div>
					</>
				}
				innerScroll
			/>
			
		</>)
	}
}


export default CardedFullWidth2TabbedSample;

