import Page from './index';
import { authRoles } from 'app/auth';

export default {
	settings: {
		layout: {
			config: {}
		}
	},
	// auth: authRoles.user,
	routes: [
		{
			path: '/roles',
			exact: true,
			component: Page.Layout
		},
	]
};
