import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { useDeepCompareEffect } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import _ from '@lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import headerImg from "./header-pattern.png"
import {Grid,Card} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import {TextField,Paper} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

import profileImg from "./user-profile-pic.png"
import EditUserProfile from './EditUserProfile';
import axios from "@fuse/utils/axios";
import useCurrentUser from '@fuse/hooks/useCurrentUser';



// import BasicInfoTab from "../components/tabs/BasicInfoTab"
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	name: yup
		.string()
		.required('You must enter a product name')
		.min(5, 'The product name must be at least 5 characters')
});

const useStyle = makeStyles({
	root:{
		padding:"20px"
	},
	header:{
		height:'10px',
		minHeight:'0px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	headerBg:{
		height:"100px",
		background:`url(${headerImg})`
		// background:"",
	},
	activeTab: {
        // borderBottom: "2px solid",
        // borderBottomLeftRadius: "0px !important",
        // borderBottomRightRadius: "0px !important",
        background:"rgba(107, 44, 87, 0.04);",
        borderRadius:"0",
	 },
	userImg:{
		width:"100px",height:"100px",borderRadius:"50%",objectFit:"cover",transform:"translateY(25%)"
	},
	large: {
		width: "200px",
		height: "300px",
		borderRadius: 0
	},
})





 

function Profile(props) {
	const classes = useStyle()
	const routeParams = useParams();
	const [tabValue, setTabValue] = useState(0);
	const [noProduct, setNoProduct] = useState(false);
	 const [tabState, setTabState] = useState("information");
	 const [userDetail, setUserDetail] = useState([]);
	const methods = useForm({
		mode: 'onChange',
		defaultValues: {},
		resolver: yupResolver(schema)
	});
	const { reset, watch, control, onChange, formState } = methods;
	const form = watch();
	const user = useCurrentUser();


	/**
	 * Tab Change
	 */
	function handleTabChange(event, value) {
		setTabValue(value);
	}

	async function getUserDetails(){
		axios.get(`/end-user/view-client/${user.data.id}`)
		.then(results=>{
			setUserDetail(results.data.data)
			// reset({
			// 	first_name : results.data.data.first_name,
			// 	last_name : results.data.data.last_name,
			// 	title: results.data.data.title,
			// 	company_name:results.data.data.user_company.company_name ,
			// 	email : results.data.data.email,
			// 	phone : results.data.data.phone,
			// 	access_role : results.data.data.roles_end_user?.display_name ?? '',
			// 	emp_no: results.data.data.emp_no ,
			// 	address: results.data.data.address ,
			// 	address_2: results.data.data.address_2 ,
			// 	city: results.data.data.city ,
			// 	state: results.data.data.state ,
			// 	zipcode: results.data.data.zip ,
			// 	country: {name:results.data.data.country,code:'N/A'},
			// 	department: results.data.data.department?.department_name ?? '',
			// })
			// setShowImage({
			// 	url:results.data.data.photoURL
			// })
		})
	}
	useEffect(()=>{

		// getUserDetails()
	},[])



	return (
		<>

		<EditUserProfile classes={classes} userDetail = {userDetail} refresh = {getUserDetails} />
		
		<div className={classes.root}>
			

	  

	  	

			

			
     

			
			

{/* 
			<FormProvider {...methods}>
		<FusePageCarded
				classes={{
					toolbar: 'p-0',
					// header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
					header: classes.header,
					topBg: classes.topBg
				}}
				// header={<ProfileHeader />}
				contentToolbar={
					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
						classes={{ root: 'w-full h-64' }}
					>
						<Tab className="h-64" label="Profile Information" />

						<Tab className="h-64" label="Security settings" />

						<Tab className="h-64" label="Email Notifications" />
						
					</Tabs>
				}
				content={
					<div className="p-16 sm:p-24 max-w-2xl">
						<div className={tabValue !== 0 ? 'hidden' : ''}>
							<BasicInfoTab />
						</div>

						<div className={tabValue !== 1 ? 'hidden' : ''}>
							<SecurityTab />
						</div>

						<div className={tabValue !== 2 ? 'hidden' : ''}>
							<EmailNotificationTab />
						</div>
					</div>
				}
				innerScroll
			/>
		</FormProvider> */}
		</div>
		</>
		

			

		
	);
}

export default Profile;