import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function LinkExpired() {
	return (
		<div>
			<img className="logo-icon w-48" src="assets/images/logos/dalos_logo.png" alt="logo" style={{marginLeft:'3rem',marginTop:'15px'}} />
			<div className="flex flex-col flex-1 items-center justify-center p-16">
				<div className="max-w-512 text-center">
					<motion.div
						initial={{ opacity: 0, scale: 0.6 }}
						animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
					>
						<Typography variant="h1" color="inherit" className="font-medium mb-16">
							LINK Expired
						</Typography>
					</motion.div>

					<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
						<Typography variant="h5" color="textSecondary" className="mb-16 font-normal">
							Reset Link for password update has expired!
						</Typography>
					</motion.div>

					<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.3 } }}>
						<Typography variant="subtitle1" color="textSecondary" className="mb-48">
							To create a new reset link go to reset password page
						</Typography>
					</motion.div>

					<Link className="font-normal" to="/reset-password">
						Forgot Password?
					</Link>
				</div>
			</div>
		</div>
	);
}

export default LinkExpired;