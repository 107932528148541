import { authRoles } from 'app/auth';
import LoginV2 from './LoginV2';
import Login from "./Login"
import Test from './Test'
import WizardScreen from './WizardScreen';

const LoginConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/login',
			component: LoginV2
		},
		{
			path:"/welcome",
			component: WizardScreen
		}
	]
};

export default LoginConfig;
