import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid } from '@mui/material'

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});
function AssetsCatalog() {
	const classes = useStyles();
	return (
		<div className="w-full items-center fixed z-50" style={{background:'#fcfcfc'}}>
			<Box sx={{marginTop:'22px', marginBottom:'15px'}}>
				<span className='dashboard_breadcrumb' style={{marginRight:'3px'}}>Dashboard</span> <span style={{marginRight:'3px'}} className='dashboard_breadcrumb'>/</span><span style={{marginRight:'3px'}} className='dashboard_breadcrumb'> Assets Dashboard </span><span style={{marginRight:'3px'}} className='dashboard_breadcrumb'>/</span>
				<span className='dashboard_breadcrumbTwo'>
				 Assets Catalog
				</span>
			</Box>
			<div className="flex items-center">
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 font-semibold assets_catalog_header"
					// style = {{ color:'black',fontSize:'24px', fontWeight:'600',lineHeight:'32px'}}
				>
					Assets Catalog
				</Typography>				
			</div>
			
		</div>
	)
}
export default AssetsCatalog;
