import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { DataGrid } from '@material-ui/data-grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Icon, TextField} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from "@fuse/utils/axios";
const useStyles = makeStyles((theme) => (
    {
    

    root: {
        // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
        //   border: '2px solid black',
        //   display: 'none !important'
        // }
        '& .MuiDataGrid-columnsContainer': {
            background: '#8f8f8f26'
        }
    },
    formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    width:"100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}
));

import { withRouter } from 'react-router-dom';
import { Ballot } from '@material-ui/icons';
import { Button, Grid, Typography } from '@material-ui/core';

const Accordion = withStyles({
    root: {
        border: '1px solid #562459',
        boxShadow: 'none',
        margin: '10px 0',
        // '&:not(:last-child)': {
        //     borderBottom: 0,
        // },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'transparent',
        // border: '1px solid #562459',
        // marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        // borderBottom:"1px solid #562459"
    },
}))(MuiAccordionDetails);

  

function ApplicationsTab(props) {
    const classes = useStyles();
    const [statusData, setStatusData] = useState([]);
    const [expanded, setExpanded] = React.useState();
     const [open, setOpen] = React.useState(false);
     const [displayFeedBackTextInput,setDisplayFeedBackTextInput] = useState(false);

     const [menuProps, setMenuProps] = useState({
		PaperProps: {
			style: {
				// marginTop:'56px',
				// maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				height: 150
				, width: 250,
				transform: "translateY(40%)"
			},
		},
		anchorOrigin: {
			vertical: "top",
			horizontal: "center"
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "center"
		},
		getContentAnchorEl: null
	})

  const handleClickOpen = () => {
    axios.get(`/service/send-mail-to-service-request`)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    function handleSelectMenuItem(e){
        if(e.target.value === 110){
            setDisplayFeedBackTextInput(true)
        }else{
            setDisplayFeedBackTextInput(false)
        }
    }


    useEffect(()=>{
        
    },[])


    return (
        // <div className={classes.root} style={{height: 290, width: '100%',overflow:"auto",textAlign:'center'}}>
        //     No Data found
        // </div>
        <>
         <div className={classes.root} style={{ height: 290, width: '100%', overflow: "auto",padding:"0 30px" }}>
            <Typography className='text-black' style={{fontSize:"15px"}}>It seems there's currently no active integration for your device management. But don't worry, we've got you covered. Whether you have your own system or need expert advice, we're here to help.</Typography>
            <div className='mt-20'>
                <Accordion square expanded={expanded === 'panel1'} className='rounded-xl' onChange={handleChange('panel1')}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                        <Typography style={{ color: "#562459", fontSize: "17px" }}>I Need a Device Management System</Typography>
                        
                    </AccordionSummary>
                    <AccordionDetails className='flex-col'>
                        <Typography style={{ fontSize: "15px", color: "#000" }}>
                            Not sure about which device management solution to use? We can help. Click here and one of our specialists will reach out to introduce you to our in-house device management system, tailored to effectively meet your needs.
                        </Typography>
                        <div className='mt-20'>

                            <Button color='primary' className='rounded-md font-bold' variant='contained' onClick={handleClickOpen}>Notify Sales Team</Button>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='rounded-xl' square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                        <Typography style={{ color: "#562459", fontSize: "17px" }}>I have My Own System</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='flex-col'>
                        <Typography className='mb-20' style={{ fontSize: "16px" }}>
                            Share with us your system details, and we'll contact you to discuss potential integration.
                        </Typography>
                        <div>
                            <Grid container spacing={1} className='items-center'>
                                <Grid item lg={4}>
                                    <Typography style={{fontSize:"14px"}}>Either choose from the listed<br /> device management</Typography>
                                </Grid>
                                <Grid item lg={8}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                                         <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            className='cstm-select'
                                            // value='Choose here'
                                            onChange={(e)=>handleSelectMenuItem(e)}
                                            MenuProps={menuProps}
                                            // label="Age"
                                            style={{width:"100%"}}
                                        >
                                          
                                            <MenuItem value={10}>Microsoft Intune</MenuItem>
                                            <MenuItem value={20}>vMware Workspace ONE</MenuItem>
                                            <MenuItem value={30}>IBM MaaS360</MenuItem>
                                            <MenuItem value={40}>MobileIron</MenuItem>
                                            <MenuItem value={50}>Cisco Meraki Systems Manager</MenuItem>
                                            <MenuItem value={60}>Sophos Mobile</MenuItem>
                                             <MenuItem value={70}>JAMF Pro</MenuItem>
                                            <MenuItem value={80}>Google Workspace Device Management</MenuItem>
                                            <MenuItem value={90}>SOTI MobiControl</MenuItem>
                                            <MenuItem value={100}>ManageEngine Mobile Device Manager Plus</MenuItem>
                                            <MenuItem value={110}>Others</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    displayFeedBackTextInput && (<>
                                        <Grid item lg={4}>
                                            <Typography style={{fontSize:"14px"}}>Or comment your own<br /> device management</Typography>
                                        </Grid>
                                        <Grid item lg={8}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                                            <TextField id="outlined-basic" placeholder='Comment here' variant="outlined" />
                                            </FormControl>
                                        </Grid>
                                    </>)
                                }
                            </Grid>
                        </div>
                        <div className='mt-20 flex justify-between'>
                            <Button className='wizard-screen-backBtn px-20'>Cancel</Button>
                            <Button className='rounded-xl px-20' variant='contained' color='primary' onClick={handleClickOpen}>Submit</Button>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>
            {/* <DataGrid
                rows={data}
                columns={columns}
                hideFooter={true}
            /> */}
        </div>

        <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{fontSize:"16px"}}>
           Our device management sales team has been notified, we will contact you shortly!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={handleClose} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
}

export default withRouter(ApplicationsTab)
