import { combineReducers } from '@reduxjs/toolkit';
import filterParamsReducer from './filterParamsReducer';
import formViewDialog from './formViewDialogReducer';

const reducer = combineReducers({
	filterParamsReducer,
	formViewDialog,
});

export default reducer;
