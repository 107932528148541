import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Select,MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	root: {},
	leftSection: {},
	rightSection: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	}
}));

/**
 * Form Validation Schema
 */


function attributeAddForm(props) {

	const classes = useStyles();
	
	const defaultValues = {
		attribute_name: '',
		attribute_type_id:''
	};

    const [selectedAttribute, setSelectedAttribute] = useState('');
	const [canHaveValues, setCanHaveValues] = useState(false);
	const [isVisible, setIsVisible] = useState(true);
	
	const [valuesError, setValuesError] = useState('');
	
	const schema = yup.object().shape({
		attribute_name: yup.string().required('You must enter field name'),
		attribute_type_id: yup.number()
			.required('Please select field type.'),
	});

	const { control, formState, handleSubmit, reset } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	function onSubmit(formData) {
        reset(defaultValues);
		formData.platform_module_id = props.platformModuleId
		formData.is_visible = isVisible
        props.addAttribute(!canHaveValues? {...formData,values:'_'}:formData);
    }
    
    function onAttributeChange(val) {
        console.log(isValid,dirtyFields);
        setSelectedAttribute(val)
	}
	
	function setCanHaveValuesValue(e,onchange){
		onchange(e);
		for(let i =0; i<props.attributeTypes.length; i++){
			if(e.target.value == props.attributeTypes[i].id){
				if(props.attributeTypes[i].can_have_values == 1){
					setCanHaveValues(true);
					setValuesError('You must provide some values');
				}else{
					setCanHaveValues(false);
					setValuesError('');
				}
			}
		}
	}

	return (
		<div
			className={clsx(
				classes.root,
				'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'
			)}
		>

						<form
							name="attributeAddForm"
							noValidate
							className="flex flex-col justify-center w-full"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Controller
								name="attribute_name"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-16"
										label="Field name"
										autoFocus
										type="text"
										error={!!errors.attribute_name}
										helperText={errors?.attribute_name?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>

                            <Controller
								name="attribute_type_id"
								control={control}
								render={({ field }) => {
									return(
									<TextField
										{...field}
										className="mb-16"
										label="Field type"
										autoFocus
										error={!!errors.attribute_type_id}
                                        helperText={errors?.attribute_type_id?.message}
										variant="outlined"
										required
                                        fullWidth
										select
										onChange={(e)=>setCanHaveValuesValue(e,field.onChange)}
									>
                                        {
                                            props.attributeTypes.map(attr => {
                                                return <MenuItem key={`attr_${attr.id}`} value={attr.id}>{attr.attribute_type}</MenuItem>
                                            })
                                        }
                                    </TextField>
								)}}
							/>

							<Controller
								name="values"
								control={control}
								render={({ field }) => {
								if(!canHaveValues){
									field.value =''
								}
								return(
									<TextField
										{...field}
										className="mb-16"
										label="Comma separated values"
										autoFocus
										type="text"
										error={valuesError == '' ? false : true}
										helperText={valuesError}
										variant="outlined"
										required
										fullWidth
										disabled={!canHaveValues}
										onChange={(e)=>{field.onChange(e);e.target.value != '' && canHaveValues ? setValuesError(''):setValuesError('You must provide some values!')}}
									/>
								)}
								}
							/>

							<Controller
							control={control}
							name="is_visible"
							render={({ field }) => (
								<label>
									Visible
									<Checkbox
										{...field}
										checked={isVisible}
										error={!!errors.is_visible}
										onChange={() => setIsVisible(!isVisible)}
									/>
								</label>
							)}
							/>
							
							<Button
								variant="contained"
								color="primary"
								className="w-full mx-auto mt-16"
								aria-label="Add field"
								disabled={_.isEmpty(dirtyFields) || !isValid || valuesError != ''}
								type="submit"
							>
                                Add field
							</Button>
						</form>
		</div>
	);
	
}

const mapStateToProps = state => {
	
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(attributeAddForm);
