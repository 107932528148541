import { authRoles } from 'app/auth';
import Page from './index';

export default {
settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
	},
	// auth: authRoles.user,
	routes: [
		{
			path: '/notification/assets',
			exact: true,
			component: Page.Layout
		},
		{
			path: '/notification/contracts',
			exact: true,
			component: Page.Layout
		},
		{
			path: '/notification/subscriptions',
			exact: true,
			component: Page.Layout
		},
		{
			path: '/notification/iot',
			exact: true,
			component: Page.Layout
		},
	]
};