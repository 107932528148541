import { combineReducers } from '@reduxjs/toolkit';
import dialogReducer from './dialogReducer';
import csvDialogReducer from './csvDialogReducer';
// import userReducer from './userReducer';
import formViewDialog from './formViewDialogReducer';
import reportViewDialogReducer from './reportViewDialogReducer';
import filterParamsReducer from './filterParamsReducer';

const reducer = combineReducers({
	formViewDialog,
	reportViewDialogReducer,
	filterParamsReducer
});

export default reducer;
