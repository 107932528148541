
import {Box, CircularProgress,Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, useTheme} from '@material-ui/core'
import { Pagination } from '@material-ui/lab';
import TablePagination from '@fuse/components/general/TablePagination';
import { useEffect, useState } from 'react';
import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy, getDateDifference, monthDiff } from '@fuse/utils/dateOperations';

const AllLineItems = (props) => {
    const theme = useTheme();
    const [totalRecords, setTotalRecords] = useState(0)
    const [lineItemsLoading, setLineItemsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [lineItems, setLineItems] = useState([])

    const getLineItems = () => {
        if(!props.quote.id) return
        setLineItemsLoading(true);
        axios.get(`/quotes/${props.quote.id}/line-items/paginated`,{
            params: {
                page, rowsPerPage
            }
        })
        .then(res => {
            if(res.data.statusCode === 200){
                const {quote_line_items, total, total_records} = res.data.data;
                setLineItems(quote_line_items);
                setTotalRecords(total_records);
            }
        })
        .catch(err => console.log(err))
        .finally(()=>setLineItemsLoading(false))
    }

    function toNumberFormat(numberString){
		const number = Number(numberString);
		let fixed = number.toFixed(2);
		let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
		let [formatedNumber] = number.toLocaleString().split(".");
		const result = `${formatedNumber}.${secondNumber}`;
		return result
	}

    useEffect(() => {
        getLineItems()
    },[props.quote.id])

    function formatString(str){
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

    useEffect(()=>{
        getLineItems()
    }, [page, rowsPerPage])

    if(!lineItems.length){
        return(
            <div style={{width: '100%',minHeight: 100,display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                No Record Found
            </div>
        )
    }

    return (
        <div style={{width: '100%'}}>
			{lineItemsLoading ?
                <Box display="flex" alignItems="center" justifyContent="center" justifyItems="center" style={{padding: 20}}>
                    <CircularProgress color="primary"/>
                </Box>
                :
                <TableContainer component={Paper} className={`rounded-none`} style={{"overflow-x": "auto",maxHeight:400}}>
                    <Table stickyHeader aria-label="sticky table" style={{"overflow-x": "auto"}}>
                    <TableHead>
                        <TableRow style={{background: theme.palette.action.selected}}>
                            <TableCell>Serial No.</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>Asset type</TableCell>
                            <TableCell>Model</TableCell>
                            <TableCell>Asset name</TableCell>
                            <TableCell>Manufacturer</TableCell>
                            <TableCell>Service name</TableCell>
                            <TableCell>Service SKU</TableCell>
                            <TableCell>Service plan</TableCell>
                            <TableCell>Sell price</TableCell>
                            <TableCell>Profit margin</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>MSRP</TableCell>
                            <TableCell>Profit amount</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>		
                            <TableCell>Quote length</TableCell>		
                            <TableCell>New / Renew</TableCell>
                            <TableCell>Location name</TableCell>
                            <TableCell>Street address</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Zip</TableCell>
                            <TableCell>Country/Region</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            lineItems.map(item => (
                                <TableRow>
                                    <TableCell>{item?.asset?.serial_number || '-'}</TableCell>
                                    <TableCell>{item?.asset?.hardware_sku || '-'}</TableCell>
                                    <TableCell>{formatString(item?.asset?.asset_type) || '-'}</TableCell>
                                    <TableCell>{item?.asset?.model || '-'}</TableCell>
                                    <TableCell>{item?.asset?.asset_name || '-'}</TableCell>
                                    <TableCell>{item?.asset?.manufacturer?.manufacturer_name || '-'}</TableCell>
                                    <TableCell>{item?.service?.service_name || '-'}</TableCell>
                                    <TableCell>{item?.service?.service_sku || '-'}</TableCell>
                                    <TableCell>{item?.service_plan || ''}</TableCell>
                                    <TableCell>${item.modified_sell_price ? toNumberFormat(item.modified_sell_price) : '0.00'}</TableCell>
                                    <TableCell>{item.modified_sell_price && item.cost ? ((parseFloat(item.modified_sell_price) - parseFloat(item.cost)) * 100.00 / parseFloat(item.cost)).toFixed(2) : '0.00'}%</TableCell>
                                    <TableCell>{parseFloat(item?.discount || 0).toFixed(0)}%</TableCell>
                                    <TableCell>${toNumberFormat(item?.cost || 0)}</TableCell>
                                    <TableCell>${toNumberFormat(item?.msrp || 0)}</TableCell>
                                    <TableCell>${item.modified_sell_price && item.cost ? toNumberFormat((parseFloat(item.modified_sell_price) - parseFloat(item.cost)).toFixed(2)) : 0.00}</TableCell>
                                    <TableCell>{item.start_date ? dateReformatTo_mmm_dd_yyyy(item.start_date.split('T')[0]) : '-'}</TableCell>
                                    <TableCell>{item.end_date ? dateReformatTo_mmm_dd_yyyy(item.end_date.split('T')[0]) : '-'}</TableCell>
                                    <TableCell>{item.start_date && item.end_date ? `${monthDiff(new Date(item.start_date), new Date(item.end_date))} M` : '-'}</TableCell>
                                    <TableCell>{item?.service?.new_renew || '-'}</TableCell>
                                    <TableCell>{item?.asset?.shipping?.location_name || '-'}</TableCell>
                                    <TableCell>{item?.asset?.shipping?.address1 || '-'}</TableCell>
                                    <TableCell>{item?.asset?.shipping?.city || '-'}</TableCell>
                                    <TableCell>{item?.asset?.shipping?.state || '-'}</TableCell>
                                    <TableCell>{item?.asset?.shipping?.zip || '-'}</TableCell>
                                    <TableCell>{item?.asset?.shipping?.country || '-'}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                    </Table>
                    
                </TableContainer>
                
            }
            {/* <div style={{width: '100%', padding: 10, display: 'flex', alignContent: 'flex-end', justifyContent: 'flex-end'}}>
                <Pagination size="small" color="primary" count={Math.floor(totalRecords / rowsPerPage)} page={page + 1} onChange={(event, value) => {
                    setPage(value - 1)
                }} />
            </div> */}
            <div className={`p-10`} style={{width: '100%'}}>
            <TablePagination
                page={page}
                component="div"
                count={totalRecords}
                onChangePage={(event, newPage)=>setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(rowsPerPage)=>{
                    setRowsPerPage(parseInt(rowsPerPage))
                    setPage(0)
                }}
            />
            </div>
        </div>
    )
}

export default AllLineItems