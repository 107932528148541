import {useTheme, makeStyles} from '@material-ui/core/styles'
import { useState } from 'react';
import { Grid, Card, CardContent, Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 12,
        height: '100%'
    },
	cardContentBottomPadding10: {
		["&:last-child"]: {paddingBottom: 10}
	},
}))

function InfoCard({items, label, subTitleCols, colIcons, showColKeys}){
    const theme = useTheme();
    const classes = useStyles();
	const [listItems, setItems] = useState(items);

		return (
			<Grid item xs={12} sm>
			<Card className={`${classes.root}`}>
				<CardContent className={`p-10 pb-0 ${classes.cardContentBottomPadding10}`} style={{height: '100%',display: 'flex', flexDirection: 'column'}}>
					{label &&
					<span style={{display: 'flex',alignItems: 'center',marginBottom: 10}}>
						<span>
						<Icon style={{color: 'white',height: 30, width: 30, borderRadius: 7, backgroundColor: label.icon_color, marginRight:10,padding: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{label.icon}</Icon>
						</span>
						<span style={{fontSize: 14}}>{label.label}</span>
					</span>
					}
					<span style={{flexGrow: 1}}>
					<Grid container>
					{
						Object.keys(listItems).map(key=>(
							<Grid item xs={12}>
							{subTitleCols.includes(key) ?
								<span style={{fontSize: 14, fontWeight: 'bold'}}>{listItems[key]}</span>
							:
								<span style={{display:"flex",alignItems: 'center'}}>
                                    {colIcons ?
                                    
									<Icon style={{fontSize:20,marginRight: 10, color: colIcons[key] && colIcons[key].color ? colIcons[key].color : 'transparent'}}>{colIcons[key] && colIcons[key].icon ? colIcons[key].icon : ''}</Icon>
                                    :
                                    <></>

                                    }
									<span style={{color: theme.palette.action.active}}>{`${showColKeys ? `${key} : ` : ''}`}{listItems[key]}</span>
								</span>
							}
							</Grid>
						))
					}
					</Grid>
					</span>
					
				</CardContent>
			</Card>
		</Grid>
		)
    }

    export default InfoCard;