import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

const initialState = {
	platformModules: [],
	attributeTypes: []
};

const platformModuleSlice = createSlice({
	name: 'platformModules',
	initialState,
	reducers: {
		setPlatformModules: (state, action) => {
			state.platformModules = action.payload;
		},
		setAttributeTypes: (state, action) => {
			state.attributeTypes = action.payload;
		}
	},
	extraReducers: {}
});

export const { setPlatformModules,setAttributeTypes } = platformModuleSlice.actions;

export default platformModuleSlice.reducer;
