import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import {TextField,MenuItem, FormControlLabel} from '@material-ui/core';
import { useState, useEffect, Fragment } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
// import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useToast from '@fuse/hooks/useToast'
import { usePermission } from '@fuse/hooks';
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	const [currentFilter, setCurrentFilter] = useState('');
	const [currentFilterLabel, setCurrentFilterLabel] = useState('');
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const { hasPermission } = usePermission()

	const createSLAggrementPermission = hasPermission('add-service_level_agreement')
	const addReportPermission = hasPermission('add-report')

	function handleSearchReset(){
		props.handleSearchReset();
		props.handleSearchSuggestions("", currentFilter)
		setSearchQuery("");
	}

	useEffect(() => {
		console.log(props.searchFilters);
		
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilter(props.searchFilters[0].field)
		}
	},[props.searchFilters])

	useEffect(() => {
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilterLabel(props.searchFilters.find((item) => item.field == currentFilter)?.label || "");
		}
	},[currentFilter]);

	useEffect(() => {
		props.onSearchChange(search, currentFilter)
	},[search, currentFilter])

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M5 3V19H21V21H3V3H5ZM20.2929 6.29289L21.7071 7.70711L16 13.4142L13 10.415L8.70711 14.7071L7.29289 13.2929L13 7.58579L16 10.585L20.2929 6.29289Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Service Level Agreements List
				</Typography>
			</div>
			<div style={{display:'flex'}}>

				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}

				{createSLAggrementPermission && <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'260px', marginRight: '20px'}}
						onClick = {()=>dispatch(openFormViewerDialog())}
						startIcon={
						<Icon fontSize="small" title="Add Service Level Agreement">
							add
						</Icon>}>
						Add Service Level Agreement
					</Button>
				</div>}
				{/* <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight:"20px"}}
						onClick = {()=>dispatch(openReportViewerDialog())}
						startIcon={
						<Icon fontSize="large" title="Save View">
							save
						</Icon>}>
							Save View
					</Button>
				</div> */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center  shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px",width:"260px"}}
						>
							<TextField
								placeholder="Search Service Level Agreements"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>	

			{/* <div className={`flex flex-1 items-center justify-end ${classes.noPaddingRight}`}>
				<ThemeProvider theme={mainTheme}>
				<Paper
					component={motion.div}
					initial={{ y: -20, opacity: 0 }}
					animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
					className={`flex items-center mr-10 max-w-512 shadow ${classes.buttonSquare}`}
				>
					<FormControl>
						<Select
						disableUnderline
						id="demo-simple-select-filled"
						style={{minWidth: "150px"}}
						label="Filter"
						value={currentFilter}
						className="pl-10"
						onChange={e => { 
							setCurrentFilter(e.target.value)
							setSearchQuery("")
						}}

						MenuProps={{
				            anchorOrigin: {
				              vertical: "bottom",
				              horizontal: "center"
				            },
				            transformOrigin: {
				              vertical: "top",
				              horizontal: "center"
				            },
				            getContentAnchorEl: null
          				}}

						>
							{props.searchFilters.map(({field, label}, ind) => {
								return(
								  <MenuItem value={field}>{label}</MenuItem>
								)
							}
						)}
						</Select>
					</FormControl>
					</Paper>
					<Paper
						component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center max-w-512 shadow ${classes.buttonSquare}`}
						style={{minWidth:"400px"}}
					>
						<Autocomplete
							id="assets-search-box"
							freeSolo
							autoSelect
							autoComplete
							value={""}
							options={props.searchSuggestions}
							style={{width: "100%"}}
							getOptionLabel={option => option}
							noOptionsText=""
							onInputChange={event => {
								if(event){
									setSearchQuery(event.target.value || "")
									props.handleSearchSuggestions(event.target.value, currentFilter)
								}
							}}
							onChange={(event, value) => {
								let query = value || ""

								setSearchQuery(query)
							}}
							renderInput={(params) => (
							    <div ref={params.InputProps.ref}>
									<TextField
										{...params.inputProps}
										placeholder={`Search ${currentFilterLabel}`}
										className="flex flex-1 mx-8"
										disableUnderline
										fullWidth
										value={search}
										InputProps={{
											disableUnderline: true
										}}
										onKeyDown={e => {
											if (e.keyCode === 13 && search) {
												props.handleSearch(search, currentFilter)
											}
										}}
									/>
								</div>
							)}
							/>
						{Boolean(search && search.length) && 
							<IconButton
							aria-label="toggle password visibility"
							onClick={() => {props.handleSearch(search, currentFilter)}}
							size="small"
							className="mr-10">
								<Icon>search</Icon>
							</IconButton>
						}
					</Paper>
				</ThemeProvider>
				
				<div className={`pl-10 `} style={{width:"90px"}}>
					<Button
						variant="contained"
						color="secondary"
						size="small"
						onClick={handleSearchReset}
						className={`w-full ${classes.buttonSquare}`}>
							Reset
					</Button>
				</div>
			</div> */}
		</div>
	)
}

export default DynamicFieldsHeader;
