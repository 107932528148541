import React, { useEffect, useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete';
// import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AddDepartmentFromQuickAction from './AddDepartmentFromQuickAction';
import Checkbox from '@mui/material/Checkbox';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


const AddUserFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [showRole, setShowRole] = useState(false)
    const [formattedNumber, setFormattedNumber] = useState('')
    const [department, setDepartment] = useState([])
	const [roles, setRoles] = useState([])
    const [ openAddDepartmentModal, setOpenAddDepartmentModal ] = useState(false)
    const [startDate,setStartDate] = useState({
		date: null,
		error: false
	})
	const [endDate,setEndDate] = useState({
		date: null,
		error: false
	})

    useEffect( () => {
        getDepartment()
		getRoles()
    }, [])


    function getDepartment() {
        axios.get("/department").then ( res => {
            const { data } = res.data
            setDepartment(data.map((item) => {
                return {
                    id: item.id,
                    department_name: item.department_name
                }
            }))
        }).catch (err => {
            toast.error('Unable to fetch roles!')
            console.log(err)
        })
			
	}

	function getRoles() {
        axios.get("/roles").then ( res => {
            const { data } = res.data
            let all_roles = [...data.preDefinedRoles, ...data.customRoles]
            setRoles(all_roles.map((item) => {
                return {
                    id: item.id,
                    display_name: item.display_name
                }
            }))
        }).catch (err => {
            console.log(err)
        })
	}

    const handleOpenCloseAddDepartment = () => {
        setOpenAddDepartmentModal(!openAddDepartmentModal)
    }

    const defaultValues = {
        first_name: '',
        last_name: '',
        title: '',
        email: '',
        phone:'',
        loginEnabled: false,
        role: [],
		empNo: '',
		address1: '',
		address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: null,
        department: null
    }

    const schema = yup.object({
        first_name: yup.string().trim().required('First Name is required!'),
        last_name: yup.string().trim().required('Last Name is required!'),
        title: yup.string().trim().required('Title is required!'),
        email: yup.string().trim().required('Email is required!'),
        loginEnabled: yup.boolean().required(),
        phone: yup.string().trim().when({
            is: (val) => !!val,
            then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
        }),
        role: yup.array().of(yup.object().when('loginEnabled', {
            is: true,
            then: yup.object().shape({
                id: yup.string().required('Role is required'),
                display_name: yup.string().required('Role is required')
            }).required(),
            otherwise: yup.object().nullable(),
        })),
        empNo: yup.string().notRequired(),
        address1: yup.string().notRequired(),
        address2: yup.string().notRequired(),
        city: yup.string().notRequired(),
        state: yup.string().notRequired(),
        zipcode: yup.string().notRequired(),
        country: yup.object().notRequired().nullable(),
        department: yup.object().notRequired().nullable(),
        
    })

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { 
        control, 
        formState:{errors: userFormErrors}, 
        handleSubmit: userFormSubmit, 
        trigger,
        reset: userFormReset,
        clearErrors,
        setError,
        getValues,
        setValue
    } = methods


    function userFormErrorData(error){
        console.log('userFormErrorData: ',error);
    }

    const checkEmailExist = async () => {
        try{
            const payload = {
                email: getValues().email.trim()
            }
            return (await axios.post(`/user/user-exist`, payload)).data.data.emailExist
        }catch(err){
            console.log(err)
        }
    }


    const onSubmit = async () => {
        await trigger();
        if(getValues().loginEnabled) {
            if(getValues().role.length === 0) {
                setError('role', {
                    message:  "Role is required"
                })
            } else {
                clearErrors("role")
            }
        } else {
            clearErrors("role")
        }
        if(Object.keys(userFormErrors).length !== 0) { 
            return;
        }

        if(await checkEmailExist()){
            toast.error('Email already exists!')
            return
        } 
        console.log(getValues())
        const roleIds = getValues().role.map( (role, index) => {
            return role.id
        })
        const payload = {
            first_name: getValues().first_name,
            last_name: getValues().last_name,
            title: getValues().title,
            email: getValues().email,
            login_enabled: getValues().loginEnabled,
            role_id: roleIds,
            emp_no: getValues().empNo.trim(),
            address: getValues().address1.trim(),
            address_2: getValues().address2.trim(),
            city: getValues().city.trim(),
            state: getValues().state.trim(),
            zipcode: getValues().zipcode.trim(),
            country: getValues().country?.name ?? '',
            department_id: getValues().department?.id ?? null,
            start_date: startDate.date ?? '',
            // end_date: endDate.date ?? '',
            phone: formattedNumber,
		}

		axios.post('/user/add', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				setRoles([])
                toast.success('User has been added.')
				newDataAddedEvent(response.data.data.data)
			}			
		}).catch(err => {
			console.log('212=>',err);
			toast.error(err.response.data.data);
		})
        
    }


    function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}


    const newDepartmentAddedEvent = (createdData) => {
        setOpenAddDepartmentModal(false)
        setValue("department", {
            id: createdData.id,
            department_name: createdData.department_name
        })
        clearErrors("department")
        getDepartment()
    }


    return (
        <>
            <Dialog
                className="cstm_dialog_modal medium text-center"
                open={openAddStatusModalStateData}
                // onClose={handleOpenCloseAddModalEvent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='heading_holder flexbox_holder items-center w-full'>
                        <h2 className="main_heading">Add User</h2>
                        <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                            <i className="icon ti ti-x" />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-left">
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                First Name
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='first_name' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        style={{background: 'white'}}
                                        variant='outlined'
                                        // label='First Name' 
                                        fullWidth	
                                        required
                                        error={!!userFormErrors.first_name}
                                        helperText={userFormErrors?.first_name?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Last Name
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='last_name' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        style={{background: 'white'}}
                                        variant='outlined'
                                        // label='First Name' 
                                        fullWidth	
                                        required
                                        error={!!userFormErrors.last_name}
                                        helperText={userFormErrors?.last_name?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Title
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='title' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        style={{background: 'white'}}
                                        variant='outlined'
                                        // label='First Name' 
                                        fullWidth	
                                        required
                                        error={!!userFormErrors.title}
                                        helperText={userFormErrors?.title?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Email
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='email' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        style={{background: 'white'}}
                                        variant='outlined'
                                        // label='Email Address' 
                                        fullWidth
                                        required
                                        error={!!userFormErrors.email}
                                        helperText={userFormErrors?.email?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>  

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Phone
                            </Typography>
                            <Controller
                                name='phone' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='Phone Number'
                                        value={formattedNumber}
                                        onChange={(e)=>{
                                            changeNumberFormat(e)
                                        }}
                                        fullWidth
                                        onKeyPress={(event) => {
                                            if (!/[0-9/-]+/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        // required
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        error={!!userFormErrors.phone}
                                        helperText={userFormErrors?.phone?.message}
                                        style={{ backgroundColor: '#fff' }}
                                    />
                                )}
                            />
                        </div>

                        <div style={{display: 'flex'}}>
                            {/* <Controller
                                name="loginEnabled"
                                className="mb-16"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <>
                                            <div className='flex items-center'>
                                                <Checkbox
                                                    {...field}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    checked={field.value}
                                                    onChange={(e, value) => {
                                                        field.onChange(value)
                                                        // if (e.target.checked == true) setShowRole(true)
                                                        // else setShowRole(false)
                                                    }}
                                                    // disabled={loginEnabledCondition()}
                                                />
                                                <Typography>Login Enabled</Typography>
                                            </div>
                                        </>
                                    )
                                }}
                            /> */}
                            
                            <input 
                                type="checkbox" 
                                id="vehicle1" 
                                name="Login Enabled" 
                                value={showRole} 
                                onChange={(e, value) => {
                                    if (e.target.checked == true) {
                                        setValue('loginEnabled', true)
                                        setShowRole(true)
                                    } else {
                                        setValue('loginEnabled', false)
                                        setShowRole(false)
                                    }
                                }} 
                            />
                            <Typography style={{marginTop: '10px', paddingLeft: '4px'}} className='status_text'>
                                Login Enabled
                                    {/* <span className='required_span'>*</span> */}
                            </Typography>
                        </div>
                        {
                            methods.watch("loginEnabled") && 
                            <div className='mb-10'>
                                <Typography className='status_text'>
                                    Role
                                    <span className='required_span'>*</span>
                                </Typography>
                                <Controller
                                    name="role"
                                    className={classes.dropdownCss}
                                    control={control}
                                    // style={{transform:"translateY(-50px)"}}
                                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                        <>
                                            <Autocomplete
                                                {...field}
                                                style={{ width: '100%' }}
                                                options={roles}
                                                disablePortal
                                                onChange={(event, value) => { field.onChange(value) }}
                                                multiple
                                                // PaperComponent={({ children }) => (
                                                //     <Paper style={{ marginTop: '20px' }}>
                                                //         {children}
                                                //         {createCStatusPermission && <Button
                                                //             style={{ width: '100%' }}
                                                //             variant="outlined"
                                                //             color="primary"
                                                //             onMouseDown={() => setContractStatusField(true)}
                                                //         >
                                                //             Add Contract Status
                                                //         </Button>}
                                                //     </Paper>
                                                // )}
                                                getOptionLabel={option => option.display_name ?? ''}
                                                renderInput={params => {
                                                    return (
                                                        <div ref={params.InputProps.ref}>
                                                            <TextField
                                                                required
                                                                {...params}
                                                                name="role"
                                                                // label='Contract Status'
                                                                variant='outlined'
                                                                fullWidth
                                                                error={!!userFormErrors?.role}
                                                                helperText={userFormErrors?.role?.message}
                                                                defaultValue={defaultValues.role}
                                                                inputRef={ref}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        margin: 0,
                                                                        backgroundColor: '#f4f4f4',
                                                                        width: '100%',
                                                                        paddingTop: '2px',
                                                                        fontSize: '10px'
                                                                    }
                                                                }}
                                                                style={{ width: '100%', backgroundColor: '#fff' }}
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        }
                       
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Employee Number                                                                    
                            </Typography>
                            <Controller 
                                name="empNo"
                                className='serial_numberField'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        style={{ background: 'white' }}
                                        variant='outlined'
                                        fullWidth
                                        required
                                        defaultValue={defaultValues.empNo}
                                        error={!!userFormErrors.empNo}
                                        helperText={userFormErrors?.empNo?.message}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                border: '1px solid #F2F4F7',
                                                fontSize: '10px'
                                            }
                                        }} 
                                    />
                                )}
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Address Line 1                                                                   
                            </Typography>
                            <Controller 
                                name="address1"
                                className='serial_numberField'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        style={{ background: 'white' }}
                                        variant='outlined'
                                        fullWidth
                                        required
                                        defaultValue={defaultValues.address1}
                                        error={!!userFormErrors.address1}
                                        helperText={userFormErrors?.address1?.message}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                border: '1px solid #F2F4F7',
                                                fontSize: '10px'
                                            }
                                        }} 
                                    />
                                )}
                            />
                        </div>


                        <div className='mb-10'>
                        <Typography className='status_text'>
                                Address Line 2                                                                   
                            </Typography>
                            <Controller 
                                name="address2"
                                className='serial_numberField'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        style={{ background: 'white' }}
                                        variant='outlined'
                                        fullWidth
                                        required
                                        defaultValue={defaultValues.address2}
                                        error={!!userFormErrors.address2}
                                        helperText={userFormErrors?.address2?.message}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                border: '1px solid #F2F4F7',
                                                fontSize: '10px'
                                            }
                                        }} 
                                    />
                                )}
                            />  
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                City                                                                   
                            </Typography>
                            <Controller 
                                name="city"
                                className='serial_numberField'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        style={{ background: 'white' }}
                                        variant='outlined'
                                        fullWidth
                                        required
                                        defaultValue={defaultValues.city}
                                        error={!!userFormErrors.city}
                                        helperText={userFormErrors?.city?.message}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                border: '1px solid #F2F4F7',
                                                fontSize:'10px'
                                            }
                                        }} 
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                State/Province                                                                   
                            </Typography>
                                <Controller 
                                    name="state"
                                    className='serial_numberField'
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            style={{ background: 'white' }}
                                            variant='outlined'
                                            fullWidth
                                            required
                                            defaultValue={defaultValues.state}
                                            error={!!userFormErrors.state}
                                            helperText={userFormErrors?.state?.message}
                                            FormHelperTextProps={{
                                                style: {
                                                    margin: 0,
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px',
                                                    border: '1px solid #F2F4F7',
                                                    fontSize:'10px'
                                                }
                                            }} 
                                        />
                                    )}
                                />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Zipcode                                                                  
                            </Typography>                       
                            <Controller 
                                name="zipcode"
                                className='serial_numberField'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        style={{ background: 'white' }}
                                        variant='outlined'
                                        fullWidth
                                        required
                                        defaultValue={defaultValues.zipcode}
                                        error={!!userFormErrors.zipcode}
                                        helperText={userFormErrors?.zipcode?.message}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                border: '1px solid #F2F4F7',
                                                fonstSize:'10px'
                                            }
                                        }} 
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10 relative'>
                            <Typography className='status_text'>
                                Country                                                                  
                            </Typography>
                            <Controller
                                name="country"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={countries}
                                            disablePortal
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">{children}</Paper>
                                            )}
                                            getOptionLabel={option => option.name ?? ''}
                                            renderInput={(params) => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            {...params}
                                                            style={{ background: 'white' }}
                                                            //label="Country"
                                                            error={!!userFormErrors.country}
                                                            helperText={userFormErrors?.country?.message}
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    fontSize: '10px'
                                                                }
                                                            }}
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className='mb-10 relative'>
                            <Typography className='status_text'>
                                Department                                                                  
                            </Typography>
                            <Controller
                                name="department"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={department}
                                            disablePortal
                                            getOptionLabel={option => option.department_name ?? ''}
                                            onChange={(event, value) => {
                                                field.onChange(value)
                                            }}
                                            PaperComponent={({ children }) => (
                                            	<Paper className="autocomplete_holder">{children}
                                            		{
                                                        <div className='p-16'>
                                                            <Button
                                                                className='secondary_btn w-full'
                                                                variant="outlined"
                                                                color="primary"
                                                                onMouseDown={() => handleOpenCloseAddDepartment()}
                                                            >Add Department</Button>
                                                        </div>
                                                    }   
                                            	</Paper>
                                            )}
                                            renderInput={params => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            {...params}
                                                            style={{ background: 'white' }}
                                                            name="department"
                                                            //label='Department'
                                                            variant='outlined'
                                                            fullWidth
                                                            error={!!userFormErrors.department}
                                                            helperText={userFormErrors?.department?.message}
                                                            inputRef={ref}
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    fontSize: '10px'
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Start Date                                                                  
                            </Typography>
                            
                                <CustomDatePickerV2
                                    state={startDate}
                                    setState={setStartDate}
                                    required={false}
                                    //label='Start Date'
                                    //maxDate={endDate.date}
                                    //condition='End Date'									
                                />
                        </div>

{/* 
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                End Date                                                                  
                            </Typography>
                            <CustomDatePickerV2
                                state={endDate}
                                setState={setEndDate}
                                required={false}	
                                //label='End Date'
                                minDate={startDate.date}
                                condition='Start Date'											
                            />
                        </div> */}

                    </div>
                </DialogContent>
                <DialogActions className="action_footer_holder">
                    <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                    <Button onClick={onSubmit} className="primary_btn">
                        Save and Select
                    </Button>
                </DialogActions>
            </Dialog>

            {
                openAddDepartmentModal && <AddDepartmentFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddDepartment}
                    openAddStatusModalStateData={openAddDepartmentModal}
                    newDataAddedEvent={newDepartmentAddedEvent}
                />
            }
        </>
    );
}



export default AddUserFromQuickAction