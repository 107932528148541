/**
 * Authorization Roles
 */
const authRoles = {
	admin: ['Admin'],
	staff: ['Admin', 'staff'],
	user: ['Admin','Normal user', 'Power user', 'Super-User', 'Support', 'View-Only'],
	onlyGuest: []
};

export default authRoles;
