import { useRouter } from '@fuse/hooks';
import { makeStyles, TextField, Button, InputAdornment, Icon, Tooltip, Select, MenuItem, FormControl, InputLabel, FormHelperText, Typography } from '@material-ui/core'
import { QuestionAnswer } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React, { useState } from 'react'
import { countries } from '@fuse/utils/countryName'
import { timezone_list, feedback_list, industry_list } from '../constants'
import axios from '@fuse/utils/axios';

const useStyles = makeStyles(theme => ({
	root: {
        display: 'flex',
        flexDirection:'column',
        maxHeight: '490px',
        overflowY: 'scroll',
        padding: '0 10px',
        '& .MuiInputBase-input-144':{
            height: '3px',
            fontSize: '14px',
        },
        '& .MuiFormControl-root-158': {
            width: '100%',
        },
    },
    formSubHead: {
        marginBottom: '10px',
        fontWeight: 300
    },
    menuPaper: {
        maxHeight: '100px',
    },
    adornedEnd: {
        paddingRight: 0
    }
    
}));

const country_list = countries.map(country => country.name)

function DetailsForm({  dispatch, setPageComponent, toast, email, firstName, lastName, currentEmail, isGoogleSignup, isMicrosoftSignup, googleID, microsoftID, photoURL }) {

    const [showPassword, setShowPassword] = useState(false)
    const handleChangeShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const handleChangeShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const blankSpaceRegex = /^[A-Za-z][A-Za-z0-9]*$/

    const schema = yup.object().shape({
        // email: yup.string().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,"You must enter a valid email").required('You must enter a email'),
        first_name: isGoogleSignup ? yup.string() : yup.string().required('First Name is required!').min(2, 'Minimum 2 letters are required!'),
        last_name: isGoogleSignup ? yup.string() : yup.string().required('Last Name is required!').min(2, 'Minimum 2 letters are required!'),
        password: ( isGoogleSignup || isMicrosoftSignup ) ? yup.string() : yup.string().required('Password is required!').matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm, 'Password must contain at least 8 characters including one lowercase character, one uppercase character, one number, one special character!').min(8, 'Password must contain 8 characters'),
        confirmPassword: ( isGoogleSignup || isMicrosoftSignup ) ? yup.string() : yup.string().required('Password Confirmation is required!').oneOf([yup.ref('password'), null], 'Passwords must match'),
    
        country: yup.string().required('Country is required!'),
        timezone: yup.string().required('Timezone is required!'),
        company_name: yup.string().required('Company Name is required!'),
        subdomain: yup.string().required('Sub-Domain is required!'),
        // // region: yup.string().required('Password Conformation is required!'),
        industry: yup.string().required('Industry Specification is required!'),
        feedback: yup.string().required('Feedback is required!')
    });
    
    const defaultValues = {
        first_name: '',
        last_name: '',
        confirmPassword: '',
        password: '',
        company_name: '',
        subdomain: '',
        country: '',
        timezone: '',
        // region: '',
        industry: '',
        feedback: '',
    }

    const { control, formState, handleSubmit, reset} = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { errors } = formState;

  const classes = useStyles()
  const router = useRouter()

  const onAPICall = (formData) => {

    const {confirmPassword, ...payload} = formData

    payload.email = email

    if(isMicrosoftSignup){
        payload.microsoft_id = microsoftID
    }

    if(isGoogleSignup){

        payload.google_id = googleID
        payload.photoURL = photoURL

        if(!payload.first_name ){
            payload.first_name = firstName
        }

        if(!payload.last_name ){
            payload.last_name = lastName
        }
    }

    axios.post('/end-user/registration', payload).then(res => {
        if(res.data.statusCode === 201){
            toast.error(res.data.message)
        }
        else{
            reset(defaultValues)
            router.push('/login')
            toast.success('Registration successful! We sent an email to login')
        }
        
    }).catch((err) => {
        console.log('92=>',err)
        console.log('119=>',err.statusCode)
        if(err.response.status === 400){
            toast.error('Please check if there\'s any blank field!')
        }else{
            toast.error('Something went wrong!')
        }
    })
  }

  const onSubmit = (formData) => {
    // console.log('73=>',formData)
    onAPICall(formData)
  }

   const onError = (err) => {
    console.log('78=>',err)
   }

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit, onError)}>
        
        <div style={{ display: 'flex', flexDirection:'column' }}>
            <p className={classes.formSubHead}>PERSONAL DETAILS</p>

            <div style={{ display: 'flex', flexDirection:'column' }}>

                <div style={{ display: 'flex', width:'100%', marginBottom: '10px'}}>
                    <Controller 
                        name='first_name'
                        control={control}
                        render={({field: {value, ...field}}) => (
                            <TextField
                                {...field}
                                value={isGoogleSignup ? firstName : field.value}
                                disabled={ isGoogleSignup }
                                variant='outlined'
                                label='First Name'
                                // placeholder='First Name'
                                autoComplete='off'
                                required
                                style={{ width: '100%', marginRight: '10px' }}
                                // error={!!errors.first_name}
                                // helperText={errors?.first_name?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment><Icon style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}>person_outlined</Icon></InputAdornment>
                                    )
                                }}

                            />
                        )}
                    />
                    
                    <Controller 
                        name='last_name'
                        control={control}
                        render={({field:{value, ...field}}) => (
                            <TextField
                                {...field}
                                value={isGoogleSignup ? lastName : field.value}
                                disabled={ isGoogleSignup }
                                // error={!!errors.last_name}
                                // helperText={errors?.last_name?.message}
                                variant='outlined'
                                label='Last Name'
                                autoComplete='off'
                                required
                                // placeholder='Last Name'
                                style={{ width: '100%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment><Icon style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}>person_outlined</Icon></InputAdornment>
                                    )
                                }}

                            />
                        )}
                    />
                </div>

                {/* <Tooltip title="Email can't be edited" arrow style={{ padding: 10 }} placement='right'> */}
                <TextField
                    variant='outlined'
                    label='Email'
                    // placeholder='Email'
                    disabled
                    required
                    style={{ width: '100%', marginBottom: '10px' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment><Icon style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}>mail_outlined</Icon></InputAdornment>
                        )
                    }}

                    value={email}
                />
                {/* </Tooltip> */}
            
                { ( isGoogleSignup || isMicrosoftSignup )  ? 

                    <></>
                    :
                    <div style={{ display: 'flex', width:'100%',  marginBottom: '10px'}}>
                        <Controller 
                            name='password'
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    error={!!errors.password}
                                    helperText={errors?.password?.message}
                                    variant='outlined'
                                    label='Password *'
                                    inputProps={{
                                        autoComplete: 'new-password',
                                        form: {
                                            autoComplete: 'off',
                                        },
                                    }}
                                    type={showPassword ? 'text' : 'password'}
                                    // placeholder='Password'
                                    style={{ width: '100%', marginRight:'10px' }}
                                    InputProps={{
                                        // startAdornment: (
                                        //     <InputAdornment><Icon style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}>lock_outlined</Icon></InputAdornment>
                                        // ),
                                        startAdornment: (
                                            <Tooltip title={showPassword ? 'Click to hide' : 'Click to show'} placement='top' >
                                                <InputAdornment>
                                                    <Icon onClick={() => handleChangeShowPassword()} style={{ opacity: '0.7', marginRight: '10px', fontSize: '20px', cursor:'pointer' }}>{showPassword ? 'visibility_outline' : 'visibility_off_outlined'}</Icon>
                                                </InputAdornment>
                                            </Tooltip>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <Controller 
                            name='confirmPassword'
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    error={!!errors.confirmPassword}
                                    helperText={errors?.confirmPassword?.message}
                                    variant='outlined'
                                    label='Confirm Password *'
                                    autoComplete='off'
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    style={{ width: '100%' }}
                                    // placeholder='Confirm Password'
                                    inputProps={{
                                        autoComplete: 'new-password',
                                        form: {
                                        autocomplete: 'off',
                                        },
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <Tooltip title={showConfirmPassword ? 'Click to hide' : 'Click to show'} placement='top' >
                                                <InputAdornment>
                                                    <Icon onClick={() => handleChangeShowConfirmPassword()} style={{ opacity: '0.7', marginRight: '10px', fontSize: '20px', cursor:'pointer' }}>{showConfirmPassword ? 'visibility_outline' : 'visibility_off_outlined'}</Icon>
                                                </InputAdornment>
                                            </Tooltip>
                                        ),
                                    }}

                                />
                            )}
                        />
                        
                    </div>

                }
            </div>
            
        </div>

        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <p className={classes.formSubHead}>ORGANISATION DETAILS</p>

            <Controller 
                name='company_name'
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        // error={!!errors.company_name}
                        // helperText={errors?.company_name?.message}
                        variant='outlined'
                        label='Company Name'
                        autoComplete='off'
                        required
                        // placeholder='Company Name'
                        style={{ width: '100%', marginBottom: '10px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment><Icon style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}>apartment_outlined</Icon></InputAdornment>
                            )
                        }}
                    />
                )}
            />
            

            <Controller 
                name='subdomain'
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        // error={!!errors.subdomain}
                        // helperText={errors?.subdomain?.message}
                        variant='outlined'
                        label='Sub Domain'
                        autoComplete='off'
                        required
                        // placeholder='Sub Domain'
                        style={{ width: '100%', marginBottom: '10px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment><Icon style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}>alternate_email_outlined</Icon></InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment>
                                    <Typography style={{ width: '100%', backgroundColor: '#d3d3d3', padding: '15px', fontSize: '15px', fontStyle: 'italic' }}>.dalos.co</Typography>
                                </InputAdornment>
                            ),
                            classes: {
                                adornedEnd: classes.adornedEnd
                            }
                        }}
                    />
                )}
            />
            

            <div style={{ display: 'flex', width:'100%',  marginBottom: '10px'}}>
                <Controller 
                    name='country'
                    control={control}
                    render={({field}) => (
                        <FormControl style={{ marginRight: '10px', width: '100%', maxWidth: '205px' }} >
                            <InputLabel id="country_label" style={{ margin: '0 15px', transform: 'translate(0, -4.5px) scale(0.75)', transformOrigin: 'top left', }}>Country *</InputLabel>
                            <Select
                                {...field}
                                // error={!!errors.country}
                                labelId='country_label'
                                variant='outlined'
                                placeholder='Country'
                                autoComplete='off'
                                label='Country'
                                required
                                MenuProps={{
                                    style: { height: '383px', top: '-170px', left: '-40px' }
                                }}  
                                startAdornment={
                                    <InputAdornment><Icon style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}>language</Icon></InputAdornment>
                                }
                                style={{ width: '100%' }}
                            >
                                <MenuItem disabled value={''}>Select Country</MenuItem>
                                {country_list.map(country => (
                                    <MenuItem value={country}>{country}</MenuItem>
                                ))}
                                
                            </Select>
                            <FormHelperText style={{ color: '#f44336' }}>{errors?.country?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
                

                <Controller 
                    name='timezone'
                    control={control}
                    render={({field}) => (
                        <FormControl style={{ width: '100%', maxWidth: '205px' }}>
                            <InputLabel id="timezone_label" style={{ marginLeft: '15px', transform: 'translate(0, -4.5px) scale(0.75)', transformOrigin: 'top left', }}>Timezone *</InputLabel>
                            <Select
                                {...field}
                                // error={!!errors.timezone}
                                labelId='timezone_label'
                                variant='outlined'
                                autoComplete='off'
                                label='Timezone'
                                placeholder='Timezone'
                                required
                                MenuProps={{
                                    style: { height: '383px', top: '-170px', left: '-40px' }
                                }}
                                startAdornment={
                                    <InputAdornment><Icon style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}>query_builder_outlined</Icon></InputAdornment>
                                }
                                style={{ width: '100%'}}
                            >
                                <MenuItem disabled value={''}>Select Timezone</MenuItem>
                                {timezone_list.map(timezone => (
                                    <MenuItem value={timezone}>{timezone}</MenuItem>
                                ))}
                                
                            </Select>
                            <FormHelperText style={{ color: '#f44336' }}>{errors?.timezone?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
                
            </div>

            
            <Controller 
                name='industry'
                control={control}
                render={({field}) => (
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="industry_label" style={{ marginLeft: '15px', transform: 'translate(0, -4.5px) scale(0.75)', transformOrigin: 'top left', }}>Industry List *</InputLabel>
                        <Select
                            {...field}
                            // error={!!errors.industry}
                            labelId='industry_label'
                            variant='outlined'
                            placeholder='Industry List'
                            label='Industry List'
                            required
                            autoComplete='off'
                            MenuProps={{
                                style: { height: '483px', top: '-150px', left: '-40px' }
                            }}
                            startAdornment={
                                <InputAdornment><Icon style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}>category_outlined</Icon></InputAdornment>
                            }
                            style={{ width: '100%'}}
                        >
                            <MenuItem disabled value={''}>Select Industry</MenuItem>
                            {industry_list.map(industry => (
                                <MenuItem value={industry}>{industry}</MenuItem>
                            ))}
                            
                        </Select>
                        <FormHelperText style={{ color: '#f44336' }}>{errors?.industry?.message}</FormHelperText>
                    </FormControl>
                )}
            />

            <Controller 
                name='feedback'
                control={control}
                render={({field}) => (
                    <FormControl style={{ marginTop: '10px', width: '100%' }}>
                        <InputLabel id="feedback_label" style={{ marginLeft: '15px', transform: 'translate(0, -4.5px) scale(0.75)', transformOrigin: 'top left', }}>How did you here about Dalos? *</InputLabel>
                        <Select
                            {...field}
                            // error={!!errors.feedback}
                            labelId='feedback_label'
                            variant='outlined'
                            required
                            // placeholder='How did you here about Dalos?'
                            label='How did you here about Dalos?'
                            autoComplete='off'
                            MenuProps={{
                                style: { height: '483px', top: '-130px', left: '-40px' }
                            }}
                            startAdornment={
                                <InputAdornment><InputAdornment><QuestionAnswer style={{ opacity: '0.7', fontSize: '20px', marginRight: '10px' }}/></InputAdornment></InputAdornment>
                            }
                            style={{ width: '100%'}}
                        >
                            <MenuItem disabled value={''}>Select One</MenuItem>
                            {feedback_list.map(feedback => (
                                <MenuItem value={feedback}>{feedback}</MenuItem>
                            ))}
                            
                        </Select>
                        <FormHelperText style={{ color: '#f44336' }}>{errors?.feedback?.message}</FormHelperText>
                    </FormControl>
                )}
            />

        </div>

        <Button
            variant="contained"
            style={{ backgroundColor: '#F9B84B', borderRadius: '4px' }}
            className="w-50 mr-auto"
            aria-label="Continue"
            type="submit"
        >
            Submit
        </Button>
      
    </form>
    
  )
}

export default DetailsForm
