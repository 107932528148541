import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Icon, IconButton, makeStyles, Modal, Paper,
    Typography,CircularProgress, TextareaAutosize,Tooltip } from '@material-ui/core';
import axios from '@fuse/utils/axios';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab';
import useToast from '@fuse/hooks/useToast';
import { useDispatch } from 'react-redux';
import AddCategoryDialog from '@fuse/components/Modal/QuickAddCategory';
import ManufacturerDialog from '@fuse/components/Modal/QuickAddManufacturer';
import AddModelAttributeDialog from '@fuse/components/Modal/QuickAddModelAttribute'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import CustomDatePicker from '@fuse/components/customDatePicker'
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    uploadImageButton: {
        padding: '6px 16px',
        fontSize: '1.3rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 500,
        lineHeight: 1.75,
        borderRadius: '4px',
        textTransform: 'none',
        backgroundColor: '#6B2C57',
        color: '#ffffff',
        cursor: 'pointer'
    },
    subSection: {
        margin:'20px', 
        padding: '10px', 
        backgroundColor: '#f4f4f4', 
        borderRadius:'4px'
    },
    loader: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
    },
}))

const schema = yup.object({
    model_no: yup.string().required('Model ID is required!'),
    model_name: yup.string().required('Model Name is required!'),
    manufacturer_id: yup.number().required('Manufacturer is required!'),
    category_id: yup.number().required('Category is required!'),
})

const defaultValues = {
    model_no: '',
    model_name: '',
    manufacturer_id: 0,
    category_id: 0,
    // eol_date: '',
    // eosl_date: ''
}

export default function ModelDialog(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [categoryMenu, setCategoryMenu] = useState([])
    const [manufacturerMenu, setManufacturerMenu] = useState([])
    const [manufacturerSearch, setManufacturerSearch] = useState('')
    const [categorySearch, setCategorySearch] = useState('')
    const [loading, setLoading] = useState(false)
    const { control, formState, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const [modelImageName, setModelImageName] = useState('')
    const [modelImageLocation, setModelImageLocation] = useState('')
    const [eolDate,setEolDate] = useState({
		date: null,
		error: false
	})
	const [eoslDate,setEoslDate] = useState({
		date: null,
		error: false
	})
    /**-------------------Add Category--------------------*/
    const [ addCategoryOpen,setAddCategoryOpen ] = useState(false)
    function handleAddCatgoryDialogOpen(){
        setAddCategoryOpen(true)
    }

    function handleCloseDialog(new_category=null){
        setAddCategoryOpen(false)

        //Set Quick added CATEGORY value into category field
        if(new_category){
            setCategorySearch({
                id:  new_category.id,
                category_name: new_category.category_name,
                entity_external_platform_id: new_category.entity_external_platform_id
            })
        }
    }

    /**-------------------Add Manufacturer--------------------*/
    const [ addManufactureDialogOpen,setAddManufactureDialogOpen ] = useState(false)

    function handleAddManufactureDialogOpen(){
        setAddManufactureDialogOpen(true)
    }

    function handleCloseManufactureDialog(new_manufacturer=null){
        setAddManufactureDialogOpen(false)

        //Set Quick added MANUFACTURER value into manufacturer field
        if(new_manufacturer){
            setManufacturerSearch({
                id:  new_manufacturer.id,
                manufacturer_name: new_manufacturer.manufacturer_name,
                entity_external_platform_id: new_manufacturer.entity_external_platform_id
            })
        }
    }

    /**----------------Add Model Attribute------------------ */
    const spanTemp = {
		attribute: null,
		attribute_value: '',
	}
    const [dynamicAttributesMenu,setDynamicAttributesMenu] = useState([])
    const [allSpan, setAllSpan] = useState([])

    async function fetchAvailableDynamicField(){
		try {
            const res = await axios.get('/model-custom-attributes');
            const { data } = res.data;
            setDynamicAttributesMenu(AlphabeticalSorting(data, 'attribute_name'));
        } catch (err) {
            console.log(err);
        }
	}

    const addNewSpan = () => {
		let newSpan = {
			...spanTemp
		}
		setAllSpan((allSpan) => [...allSpan, newSpan])
	}

	const deleteSpan = (index) => {
		setAllSpan(allSpan.filter(span => allSpan[index] !== span )  )
	}

	const handleSpanInput = (index, value, propName) => {
		setAllSpan((spans) => {
			spans[index][propName] = value
			return [...spans]
		} )
	}

    /**---------------Dynamic Attributes------------------------ */
	const [openDynamicAttributeDialog,setOpenDynamicAttributeDialog] = useState(false)
	const [indexOfQuickAddButtonClick,setIndexOfQuickAddButtonClick] = useState(-1)

	function handleDynamicAttributeDialogOpen(index){
		setOpenDynamicAttributeDialog(true)
		setIndexOfQuickAddButtonClick(index)
	}

	function handleDynamicAttributeDialogClose(new_attribute=null){
		setOpenDynamicAttributeDialog(false)

		//Set Quick added Attribute value into Attribute field
		if(new_attribute){
			allSpan[indexOfQuickAddButtonClick] = {
				attribute:{
					attribute_name: new_attribute.attribute_name,
					id: new_attribute.id
				},
				attribute_value: ''
			}
		}
		setIndexOfQuickAddButtonClick(-1)
	}
    /**--------------------------------------------------------------*/

    const { errors } = formState

    const fetchAllCategoriesManufacturer = () => {
        axios.get(`/assets/fetch-category-manufacturer-suggestion`).then(response => {
            const data = response.data.data
            setManufacturerMenu(AlphabeticalSorting(data.manufacturers,'manufacturer_name'))
            setCategoryMenu(AlphabeticalSorting(data.categories,'category_name'))
        }).catch(err => {
            console.log('29=>',err)
        }) 
    }

    const manageManufacturerSearch = (value) => {
        if(!value) return ''
        const currentManufacturer = manufacturerMenu.filter(item => item.manufacturer_name === value)
        setManufacturerSearch(currentManufacturer[0])
    }

    const manageCategorySearch = (value) => {
        if(!value) return ''
        const currentCategory = categoryMenu.filter(item => item.category_name === value)
        setCategorySearch(currentCategory[0])
    }

    const onClose = (new_model=null) => {
        resetFields()
        //Send newly added model value
        props.handleClose(new_model)
    }

    const resetFields = () => {
        reset(defaultValues)
		setEolDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setEoslDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
        setModelImageLocation('')
        setModelImageName('')
        setManufacturerSearch('')
        setCategorySearch('')
    }

    const onChangeModelImageUpload = (event) => {
        setLoading(true)
        const selectedFile = event.target.files[0]        
        if(!selectedFile) return
		if (selectedFile.size > 2000000) {
			toast.error("File size cannot exceed 2Mb!");
            setLoading(false)
			return;
		} else{
            let formData = new FormData()
            formData.append("file", selectedFile)
            axios.post('/assets/hardware-asset-image-upload', formData ).then(response => {
                if(response.status === 201) {
                    toast.error(response.data.message)
                }
                else{
                    setModelImageName(selectedFile.name)
                    setModelImageLocation(response.data.data.imageLocation)
                }
            }).catch(err => {
                console.log('136=>',err);
                toast.error('Something went wrong!')
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const handleSubmitForm = (formData) => {
        
        const payload = {
            model_no: formData.model_no,
            model_name: formData.model_name,
            manufacturer_id: manufacturerSearch.id,
            category_id: categorySearch.id,
            entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
            // eol_date: formData.eol_date,
            // eosl_date: formData.eosl_date,
            eol_date: eolDate.date ?? '',
            eosl_date: eoslDate.date ?? '',
            image_link: modelImageLocation,
            image_name: modelImageName,
        }

        // console.log('97=>',payload)
        // if(modelImageName === '') {
        //     toast.error('Can not proceed without uploading model image!')
        //     return
        // }

        let dynamic_attributes = []
		for(let span of allSpan){
			if(span.attribute){
                dynamic_attributes.push({
                    attribute_id: span.attribute.id,
                    attribute_value: String(span.attribute_value)
                })
			}

		}

        if(dynamic_attributes.length){
            payload.dynamic_attributes = dynamic_attributes
        }

        axios.post('/models/add', payload).then(res => {
            if(res.status == 201){
                toast.error(res.data.message)
                return
            } 
            onClose(res.data.data.new_model)
            toast.success('Model Created Successfully.')
            props.fetchModelSuggestions()
        }).catch(err => {
            console.log('err: ',err);
            // toast.error('Something went wrong!')
        })
        
    }

    const errorData = (error) => {
        console.log('87=>',error)
    }

    useEffect(() => {
        fetchAllCategoriesManufacturer()
        fetchAvailableDynamicField()
        addNewSpan()

    },[])

    if(addCategoryOpen){
        return(
            <AddCategoryDialog
                open={addCategoryOpen}
                handleCloseDialog={handleCloseDialog}
                refreshList={fetchAllCategoriesManufacturer}
            />
        )
    }
    
    if(addManufactureDialogOpen){
        return(
            <ManufacturerDialog
                open={addManufactureDialogOpen}
                handleCloseDialog={handleCloseManufactureDialog}
                refreshList={fetchAllCategoriesManufacturer}
            />
        )
    }

    if(openDynamicAttributeDialog){
        return(
            <AddModelAttributeDialog
				open={openDynamicAttributeDialog}
				handleCloseDialog={handleDynamicAttributeDialogClose}
				refreshList={fetchAvailableDynamicField}
			/>
        )
    }
    return (
        <Modal
            open={props.openModelDialogState}
            // onClose={() => onClose() }
            className={classes.modal}
        >
            <div 
                style={{
                    width:700, 
                    backgroundColor: 'white', 
                    borderRadius:9,
                    display:'flex', 
                    flexDirection:'column', 
                    padding:'20px',
                    maxHeight: '98vh',
                    overflow: 'auto'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Add Model</h1>
                    <IconButton onClick={ () => onClose() }>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
                
                <form onSubmit={ handleSubmit(handleSubmitForm, errorData)}>
                    <div style={{ backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px' }} >

                        <Controller
                            name="model_no"
                            className="mb-16"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Model Number'
                                        fullWidth
                                        required
                                        error={!!errors.model_no}
                                        helperText={errors?.model_no?.message}
                                        FormHelperTextProps={{
                                            style: {marginLeft : 0}
                                        }}
                                        style={{ marginBottom: '10px',background: 'white' }}
                                    />
                                </>
                            )}
                        />

                        <Controller
                            name="model_name"
                            className="mb-16"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Model Name'
                                        fullWidth
                                        required
                                        error={!!errors.model_name}
                                        helperText={errors?.model_name?.message}
                                        FormHelperTextProps={{
                                            style: {marginLeft : 0}
                                        }}
                                        style={{ marginBottom: '10px',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                        <div className="relative">
                            <Controller
                                name="manufacturer_id"
                                className="mb-16"
                                control={control}
                                render={({ field: {onChange, ref, ...field}, fieldState: { error } }) => {
                                    return (
                                        <Autocomplete
                                            style={{ width: '100%' }}
                                            options={manufacturerMenu}
                                            disablePortal
                                            value={manufacturerSearch}
                                            onInputChange={(event) => {
                                                if(event) {
                                                manageManufacturerSearch(event.target.innerText)
                                                }
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">{children}
                                                    <Button 
                                                        style={{width:'100%'}} 
                                                        variant="outlined" 
                                                        color="primary" 
                                                        onMouseDown={() => handleAddManufactureDialogOpen()}
                                                    >Add Manufacturer</Button>
                                                </Paper>
                                            )}
                                            getOptionLabel={option => option.manufacturer_name ?? ''}
                                            renderInput={(params) => {
                                            return (
                                                <div ref={params.InputProps.ref}>
                                                <TextField
                                                    name='manufacturer_id'
                                                    {...params}
                                                    label="Manufacturer Name"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    inputRef={ref}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    style={{ marginBottom: '10px',background: 'white' }}
                                                />
                                                </div>
                                            )
                                            }}
                                        />
                                    )
                                }}
                            />
                        </div>
                        <div className="relative">
                            <Controller
                                name="category_id"
                                className="mb-16"
                                control={control}
                                render={({ field: {onChange, ref, ...field}, fieldState: { error } }) => (
                                    <Autocomplete
                                        style={{ width: '100%' }}
                                        options={categoryMenu}
                                        disablePortal
                                        value={categorySearch}
                                        onInputChange={(event) => {
                                        if(event) {
                                            manageCategorySearch(event.target.innerText)
                                        }
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper className="autocomplete_holder">{children}
                                                <Button 
                                                    style={{width:'100%'}} 
                                                    variant="outlined" 
                                                    color="primary" 
                                                    onMouseDown={() => handleAddCatgoryDialogOpen()}
                                                >Add Category</Button>
                                            </Paper>
                                        )}
                                        getOptionLabel={option => option.category_name ?? ''}
                                        renderInput={(params) => {
                                        return (
                                            <div ref={params.InputProps.ref}>
                                            <TextField
                                                name='category_id'
                                                {...params}
                                                label="Category Name"
                                                error={!!error}
                                                helperText={error?.message}
                                                inputRef={ref}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                style={{ marginBottom: '10px',background: 'white' }}
                                            />
                                            </div>
                                        )
                                        }}
                                    />
                                )}
                            />
                        </div>

                        {/* <Controller
                            name='eol_date'
                            className="mb-16" 
                            control={control}
                            render={( { field} ) => (
                                <TextField 
                                    {...field}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    label='EOL' 
                                    autoFocus
                                    fullWidth
                                    type='date'
                                    error={!!errors.eol_date}
                                    helperText={errors?.eol_date?.message} 
                                    style={{ marginBottom: '10px',background: 'white' }}
                                />
                            )}
                        /> */}

                        <div style={{ display: 'flex', justifyContent:'center', marginBottom: '10px', width:'100%' }}>
                            <div className='cstm-date' style={{ marginRight: '10px', width: '100%' }}>
                                {/* <CustomDatePicker
                                    state={eolDate}
                                    setState={setEolDate}
                                    required={false}
                                    label='EOL Date'								
                                /> */}
                                <CustomDatePickerV2
                                    state={eolDate}
                                    setState={setEolDate}
                                    required={false}
                                    label='EOL Date'								
                                />
                            </div>
                            <div className='cstm-date' style={{ width: '100%' }}>
                                {/* <CustomDatePicker
                                    state={eoslDate}
                                    setState={setEoslDate}
                                    required={false}	
                                    label='EOSL Date'											
                                /> */}
                                 <CustomDatePickerV2
                                    state={eoslDate}
                                    setState={setEoslDate}
                                    required={false}	
                                    label='EOSL Date'											
                                />
                            </div>
                        </div>

                        {/* <Controller
                            name='eosl_date'
                            className="mb-16" 
                            control={control}
                            render={( { field} ) => (
                                <TextField 
                                    {...field}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    label='EOSL' 
                                    autoFocus
                                    fullWidth
                                    type='date'
                                    style={{ marginBottom: '10px',background: 'white' }}
                                    error={!!errors.eosl_date}
                                    helperText={errors?.eosl_date?.message} 
                                />
                            )}
                        /> */}

                        <div style={{ display: 'flex', justifyContent:'center' }}>
                            <div style={{marginBottom: '10px', width: '100%' }}>
                                <Controller
                                    name='description' 
                                    control={control}
                                    render={( { field} ) => (
                                        <TextareaAutosize 
                                            {...field}
                                            style={{background: 'white',width: '100%',minHeight: '20%',border: 'solid 2px #c8c5c5'}}
                                            variant='outlined'
                                            label='Description' 
                                            fullWidth
                                            multiline
                                            placeholder="Description"
                                            error={!!errors.description}
                                            helperText={errors?.description?.message} 
                                        />
                                    ) }
                                />
                            </div>                              
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', marginBottom: '10px'}}>
                            <Typography variant='subtitle1' style={{marginBottom: '10px'}}>Upload Image</Typography>
                            <div style={{display:'flex', alignItems: 'center'}}>
                                <input
                                    accept="image/*"
                                    id="model_image_upload"
                                    type="file"
                                    onChange = {(event) => onChangeModelImageUpload(event)}
                                    hidden
                                />
                                <label htmlFor='model_image_upload' className={classes.uploadImageButton}>
                                Select File
                                </label>
                                <div style={{marginLeft: '10px'}}>
                                    { loading ? 
                                        <>
                                        <div className={classes.loader}>
                                        <CircularProgress />
                                        </div>
                                        </> : <Typography>{modelImageName}</Typography>
                                    }
                                </div>
                            </div>
                            <Typography>{"Accepts jpg, jpeg, png, gif. 2Mb max (rec. 700x430px)"}</Typography>

                            {/**Dynamic attribute */}
                            { 
								allSpan.map((span, index) => {
									return ( 
										<div style={{ display: 'flex', justifyContent:'center', width:'100%', border:'1px solid #ddd', borderRadius: '5px', padding: '10px', marginTop: '15px' ,marginBottom: '10px' }} key={index}>
											<div style={{display:'flex', flexDirection: 'column', width:'100%'}}>
												<div style={{ display: 'flex', justifyContent:'center', width:'100%' }}>

													<div style={{ margin: '5px', width: '100%' }} className="relative">
                                                        <Typography className='status_text'>
                                                            Attribute
                                                        </Typography>
														<Controller
															name="allSpan"
															className="mb-16"
															control={control}
															render={({ field: { ref, ...field }, fieldState: { error } }) => (
																<>
																	<Autocomplete
																		{...field}
																		style={{ width: '100%' }}
																		options={dynamicAttributesMenu}
																		// disablePortal
                                                                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
																		getOptionLabel={option => option.attribute_name ?? ''}
																		// onChange={(event, value) => field.onChange(value)}
																		value={span.attribute}
																		disabled={Boolean(span.has_related_value_in_assets)}
																		onChange={(event,value) => {
																			// onChange(event)
																			handleSpanInput(index, value, "attribute")
																		}}
																		getOptionDisabled={option => Boolean(allSpan.find((i)=>i.attribute?.id == option.id))}
																		PaperComponent={({ children }) => (
																			<Paper className="autocomplete_holder">{children}
																				<Button 
																					style={{width:'100%'}} 
																					variant="outlined" 
																					color="primary" 
																					onMouseDown={() => handleDynamicAttributeDialogOpen(index)}
																				>Add Attribute</Button>
																			</Paper>
																		)}
																		renderInput={params => {
																			return (
																				<div >
																					<TextField
																						{...params}
																						style={{background: 'white'}}
																						label=''
																						variant='outlined'
                                                                                        error={!!error}
                                                                                        helperText={error?.message}
																						fullWidth
                                                                                        FormHelperTextProps={{
                                                                                            style: { 
                                                                                                margin : 0, 
                                                                                                backgroundColor: '#f4f4f4',
                                                                                                width: '100%',
                                                                                                paddingTop: '2px'
                                                                                            }
                                                                                        }}
																					/>
																				</div>
																			)
																		}}
																	/>
																</>
															)}
														/>
													</div>

													<div style={{ margin: '5px', width: '100%' }}>
                                                        <Typography className='status_text'>
                                                            Value
                                                        </Typography>
														<Controller
															name='allSpan' 
															control={control}
															render={( { field: { onChange, ...field } } ) => (
																<TextField 
																	{...field}
																	style={{background: 'white'}}
                                                                    className='value_input'
																	value={span.attribute_value}
																	onChange={event => {
																		handleSpanInput(index, event.target.value, "attribute_value")
																	}}
																	variant='outlined'
																	label='' 
																	fullWidth
																/>
															) }
														/>
													</div>
													
													{ 
														(index === 0 ? 
															<Tooltip title='Add More' placement='top'>
																<IconButton onClick={() => addNewSpan()}>
																	<Icon>add_circle_outline</Icon>
																</IconButton>
															</Tooltip>
															:
															<Tooltip title='Remove Span' placement='top'>
																<IconButton disabled={Boolean(span.has_related_value_in_assets)} onClick={() => deleteSpan(index)} >
																	<Icon>cancel_circle</Icon>
																</IconButton>
															</Tooltip>
														)
													}
												</div>
											</div>
										</div>
									)
								})
							} 
                        </div>
                    </div>

                    <div style={{display:'flex',gap:'10px', marginTop: '20px'}}>
                        <Button 
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }} type='submit'
                        >Add</Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={() => onClose()}
                        >Cancel</Button>
                    </div>
                </form>

            </div>
        </Modal>
    );
}
