import { useCallback, useState, useEffect } from 'react';
import useCurrentUser from "./useCurrentUser";
import { useSelector } from "react-redux";

function getLocation(location){
    // console.log("loc",typeof location)
    // let loc_obj = JSON.parse(location)
    // console.log("loc2",loc_obj)
    let loc_id_arr = location.map(item=>item.id)
    return loc_id_arr
}

export default function usePermission() {
    const user = useCurrentUser();
    
    const hasPermission = useCallback((permission,is_location_present=false) => {
        if(user.role[0] == "Super-User" && !is_location_present && user.data.company_creator){
            return true;
        } else if(user.role[0] == "Super-User" && is_location_present && user.data.company_creator){
            const arr = user.data?.permissions?.filter(e=>e.permission==permission)
            let location_id = []
            for(let x of arr){
                if(x?.location == 'all'){
                    location_id = 'all'
                    return location_id
                }else{
                    location_id = location_id.concat(getLocation(x?.location))
                }                
            }
            return location_id
        }
        if(!is_location_present){
            if(permission == ('view-service_level_agreement')){
                return Boolean(user.data?.permissions?.find(e=>e.permission==permission))?true:
                Boolean(user.data?.permissions?.find(e=>e.permission=='view-service_level_aggrement'))?true:false
            }
            else if(permission == ('update-service_level_agreement')){
                return Boolean(user.data?.permissions?.find(e=>e.permission==permission))?true:
                Boolean(user.data?.permissions?.find(e=>e.permission=='update-service_level_aggrement'))?true:false
            }
            else if(permission == ('delete-service_level_agreement')){
                return Boolean(user.data?.permissions?.find(e=>e.permission==permission))?true:
                Boolean(user.data?.permissions?.find(e=>e.permission=='delete-service_level_aggrement'))?true:false
            }
            else if(permission == ('add-service_level_agreement')){
                return Boolean(user.data?.permissions?.find(e=>e.permission==permission))?true:
                Boolean(user.data?.permissions?.find(e=>e.permission=='add-service_level_aggrement'))?true:false
            }
            
            return Boolean(user.data?.permissions?.find(e=>e.permission==permission))
        }else{
            const arr = user.data?.permissions?.filter(e=>e.permission==permission)
            let location_id = []
            for(let x of arr){
                if(x?.location == 'all'){
                    location_id = 'all'
                    return location_id
                }else{
                    location_id = location_id.concat(getLocation(x?.location))
                    //  location_id.concat(JSON.parse(x?.location))
                }                
            }
            return location_id
        }
        // return user
    }, [user]);

    const isRole = useCallback((roleName) => {
        return Boolean(user.data.role.role_name === roleName)
    }, [user]);

    const getRole = useCallback(() => {
        return {...user.data.role};
    }, [user]);

    const getPermissions = useCallback(() => {
        return Object.keys(user.permissions);
    }, [user]);

    return {
        isRole,
        getRole,
        getPermissions,
        hasPermission,
    }
}
