import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import useRouter from '@fuse/hooks/useRouter';
import axiosgeneral from "@fuse/utils/axios";
import {TextField, FormControlLabel, InputAdornment, Typography, CircularProgress} from '@material-ui/core';
import { motion } from 'framer-motion';
import StepperCustom from '@fuse/components/general/Stepper'
import useToast from '@fuse/hooks/useToast';
import { dateReformatTo_mmm_dd_yyyy, getFormattedDate } from '@fuse/utils/dateOperations'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import InfoCard from '@fuse/components/general/InfoCard';
import InfoCardSmall from '@fuse/components/general/InfoCardSmall';

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
  } from '@material-ui/pickers';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

const drawerWidth = 400;
const headerHeight = 200;

const useStyles = makeStyles(theme => ({
	'@global': {
		'#fuse-main': {
			height: '100vh'
		}
	},
    title:{
		fontSize: 14,
		fontWeight: 600,
		marginBottom:15
	},
	input: {
		display: 'none',
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: '100%',
		position: 'relative',
		// flex: '1 1 auto',
		height: 'auto',
		width:'100%',
		backgroundColor: theme.palette.background.default
	},
	cardGen: {
		width: '100%',
	},
	loader: {
		display: 'flex',
		'& > * + *': {
		  marginLeft: theme.spacing(2),
		},
	},
	topBg: {
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none',
		padding:"10px"
	},
	topBgFixed: {
		height: headerHeight,
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none'
	},
	contentCardWrapper: {
		maxWidth: 1400,
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 0 auto',
		width: '100%',
		minWidth: '0',
		margin: '0 auto',
		// [theme.breakpoints.down('sm')]: {
		// 	padding: '16px 16px 0 16px'
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	padding: '12px 12px 0 12px'
		// }
	},
	contentCard: {
		display: 'flex',
		position: 'relative',
		// flex: '1 1 100%',
		// flexDirection: 'column',
		width:"100%",
		backgroundColor: theme.palette.background.paper,
		minHeight: 0,
		overflow: 'hidden',
		borderRadius: '2px 2px 0 0',
	},
	drawerPaper: {
		width: drawerWidth,
		maxWidth: '100%',
		overflow: 'hidden',
		height: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'relative'
		}
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 1 100%',
		zIndex: 10,
		background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
			theme.palette.background.paper,
			0.6
		)} 20%,${fade(theme.palette.background.paper, 0.8)})`
	},
	content: {
		display: 'flex',
		// flex: '1 1 100%',
		minHeight: 0
	},
	avatar: {
		backgroundColor: red[500],
	},
	formControl: {
		padding: "0px"
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	formControlMinWidth: {
		minWidth: 200,
	},
	card: {
		borderRadius:"5px"
	},
	cardContainer:{
	},
	borderRightLight: {
		borderRight:"1px solid rgba(0,0,0,0.12)"
	},
	buttonSquare:{
		borderRadius: '5px'
	}
}));

function QuoteDetail(props) {
	const dispatch = useDispatch();
	const theme = useTheme()
	const classes = useStyles();
	const [quoteLoading, setQuoteLoading] = useState(false);
	const [quotePriceLoading, setQuotePriceLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(3);
	let [quote,setQuote] = useState({});
	let [listItems,setListItems] = useState({});
	const [prices, setPrices] = useState({
		total_sell_price: 0,
	})

	const toast = useToast(dispatch);
    
    const successAlert = (message) => {
        toast.success(message,{
        anchorOrigin: {
            vertical  : 'bottom',//top bottom
            horizontal: 'right'//left center right
        }
        })
    };

    const errorAlert = (message) => {
        toast.error(message,{
        anchorOrigin: {
            vertical  : 'bottom',//top bottom
            horizontal: 'right'//left center right
        }
        })
    };


	const router = useRouter();

	const id = router.params.id;

	const [fileName, setFileName] = useState('');
	const [fileLink, setFileLink] = useState('');
	const [memo, setMemo] = useState('');
	const [creationDate, setCreationDate] = useState(getFormattedDate((new Date()).toISOString()));
	const [orderId, setOrderId] = useState('');

	const steps = ['Select quote', 'Review quote', 'Preview quote', 'Payment'];

	useEffect(async ()=>{
        fetchQuoteDetail()
		fetchQuotePrices()
		fetchItems()
	},[]);

	function fetchQuoteDetail(){
		setQuoteLoading(true);
		axiosgeneral.get(`/quotes/${id}`)
		.then(res => {
			const {data} = res.data;

			setQuote(data);
		})
		.catch(err => console.log(err))
		.finally(()=>setQuoteLoading(false));
	}

	function fetchQuotePrices(){
		setQuotePriceLoading(true);
		axiosgeneral.get(`/quotes/${id}/prices`)
		.then(res => {
			const {data} = res.data;

			setPrices(data);
		})
		.catch(err => console.log(err))
		.finally(()=>setQuotePriceLoading(false));
	}
	useEffect(()=>{
		console.log(quotePriceLoading)
	},[quotePriceLoading])

	function fetchItems(){
		setLoading(true);

		axiosgeneral.get(`/quotes/${id}/price-summary`)
		.then(res => {
			const {statusCode, data} = res.data;
			setListItems(data);
			showMessage({ message: res.data.message,autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
		})
		.catch(err => {
			return err;
		})
		.finally(()=>setLoading(false));
	}

	function getTimeIn12hrsForm(time){
		let period = 'am';

		const splitted_time = time.split(':');

		if(parseFloat(splitted_time.join('.')) > 12.00){
			period = 'pm';
		}

		return `${time} ${period}`
	}

	const handlePurchaseOrderUpload = (file) => {
		if(file.length)
		{
			const formData = new FormData();
			formData.append("file", file[0]);
			setFileName(file[0]['name'])
	
			setLoading('true')
	
			axiosgeneral.post('/quotes/upload-purchase-order', formData).then( res => {
				setLoading(false)
				if(res.data.statusCode === 201){
					setFileName('')
					errorAlert(res.data.message)
				} else {
					const {data} = res.data
					setFileLink(data.url)
				}
			}).catch(err => {
				setFileName('')
				errorAlert(err.response.data.message)
			})
		}
	}

	const handleQuoteAcceptance = () => {
		
		if(orderId == '')
		{
			errorAlert("Purchase order id is mandatory")
		}
		else if(creationDate == '01/01/1900')
		{
			errorAlert("Creation date is mandatory")
		}
		else if(fileLink == '')
		{
			errorAlert('Please upload purchase order pdf')
		}
		else
		{
			const data = {
				fileLink,
				memo,
				creationDate,
				orderId,
				quote_id : quote.quote_id,
				quote_table_id : quote.id
			}
	
			axiosgeneral.post('quotes/accept-quote', {data}).then( res => {
				router.push('/quotes/success')
			}).catch( err => {
				console.log(err)
			})
		}		
	}

	function toNumberFormat(numberString){
		const number = Number(numberString);
		let fixed = number.toFixed(2);
		let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
		let [formatedNumber] = number.toLocaleString().split(".");
		const result = `${formatedNumber}.${secondNumber}`;
		return result
	}

	const handleDateChange = (date) => {
		setCreationDate(date);
	  }

	return (
		<div className={clsx(classes.root)}>
			<FuseScrollbars className="flex-grow overflow-x-auto">
			{/* <DynamicFieldsHeader classes={classes} 
			content={<>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</>} /> */}

			<div className={clsx(classes.contentCardWrapper, 'container')}>
				<div className={clsx(classes.contentCard, 'shadow')}>
				
				<main style={{width:"100%"}}>
					<Grid container className="p-10">
					<Grid item xl={12} className={`${classes.cardGen} pb-10`}>
					<Card className={classes.card}>
						<CardContent className={classes.cardContainer}>
							<StepperCustom activeStep={activeStep} className={`p-0`} steps={steps}/>
						</CardContent>
					</Card>
					</Grid>
					
				<Grid item xl={12} className={`${classes.cardGen} mt-0 pt-0`}>
				{/* <Card className={`${classes.card} mt-0 pt-0`}> */}
					{/* <CardContent className={`${classes.cardContainer} p-5`}> */}
					{quoteLoading || quotePriceLoading ? 
					(
						<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 0.1 } }}
						className="flex flex-1 items-center justify-center h-full p-20"
						>
							<CircularProgress/>
						</motion.div>
					)
					:
					(
					<Grid container className={`w-full pb-10`}>
						<Grid sm={6} xs={12}>
							<Grid container spacing={2} className={`w-full`}>
								<InfoCard 
									label={{
										label: "Contact Info",
										icon: "contacts",
										icon_color: '#c6ec9d'
									}}
									
									subTitleCols={['Name']}

									colIcons={{
										Email: {
											icon: 'email',
											color: theme.palette.primary.main
										},
										Phone: {
											icon: 'phone',
											color: theme.palette.primary.main
										},
									}}
									items={{
										Name: quote.quote_user_first_name != null ? `${quote.quote_user_first_name} ${quote.quote_user_last_name != null ? quote.quote_user_last_name : ''}` : (quote.user_companies_user != null ? `${quote.user_companies_user.first_name} ${quote.user_companies_user.last_name}` : ''),
										Email: quote.quote_user_email != null ? quote.quote_user_email : (quote.user_companies_user != null ? quote.user_companies_user.email : ''),
										Phone: quote.quote_user_phone != null ? quote.quote_user_phone : (quote.user_companies_user != null ? quote.user_companies_user.phone : '')
									}}
								/>
								<InfoCard 
									label={{
										label: "Location",
										icon: "room",
										icon_color: '#ecd39d'
									}}
									
									subTitleCols={[]}

									colIcons={{
										Location_name: {
											icon: 'room',
											color: theme.palette.primary.main
										}
									}}
									items={{
										Location_name: quote.quote_shipping_location_name != null ? quote.quote_shipping_location_name : (quote.shipping_location != null ? quote.shipping_location.location_name : ''),
										['Address Line 1']: quote.quote_shipping_address != null ? quote.quote_shipping_address : (quote.shipping_location != null ? quote.shipping_location.address1 : ''),
										City: quote.quote_shipping_address != null ? quote.quote_shipping_city : (quote.shipping_location != null ? quote.shipping_location.city : ''),
										State: quote.quote_shipping_address != null ? quote.quote_shipping_state : (quote.shipping_location != null ? quote.shipping_location.state : ''),
										Zip: quote.quote_shipping_address != null ? quote.quote_shipping_zip : (quote.shipping_location != null ? quote.shipping_location?.zip : '')
									}}
								/>
							</Grid>
						</Grid>
						<Grid sm={6} xs={12}>
							<Grid container className={`w-full pb-2`} style={{height: '50%'}}>
										<InfoCardSmall 
										label={{
											label: 'Quote Number',
											icon: 'person_outline',
											icon_color: '#2f95e4'
										}}
										className='pr-2'
										subTitleCols={['no']}
										items={{
											no: quote?.quote_id || ''
										}}
										/>
										<InfoCardSmall 
										label={{
											label: 'Company Name',
											icon: 'business',
											icon_color: '#50b8df'
										}}
										className='px-2'
										subTitleCols={['no']}
										items={{
											no: quote.user_company != null ? quote.user_company.company_name : '',
										}}
										/>
										<InfoCardSmall 
										label={{
											label: 'No. of Assets',
											icon: 'border_all',
											icon_color: '#f4c033'
										}}
										className='pl-2'
										subTitleCols={['no']}
										items={{
											no: quote?.quote_line_items || 0
										}}
										/>
									</Grid>
									<Grid container className={`w-full pt-2`}  style={{height: '50%'}}>
										<InfoCardSmall 
										label={{
											label: 'Contract Number',
											icon: 'description',
											icon_color: '#c6ec9d'
										}}
										className='pr-2'
										subTitleCols={['no']}
										items={{
											no: quote?.contract_header?.entity_external_platform_id || ''
										}}
										/>
										<InfoCardSmall 
										label={{
											label: 'Price',
											icon: 'attach_money',
											icon_color: '#f06e6e'
										}}
										className='pl-2'
										subTitleCols={['no']}
										items={{
											no: `$${toNumberFormat(prices?.total_sell_price) || '0.00'}`
										}}
										/>
								</Grid>
							</Grid>
						</Grid>
					)}
					{/* </CardContent> */}
					{/* <CardContent className={classes.cardContainer}> */}
						<Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Paper square>
                                    <TableContainer className={classes.container} style={{height:'420px'}}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={4} className={classes.title}>
                                                        Assets Renewed:
                                                    </TableCell>
                                                </TableRow>
												<TableRow>
                                                    <TableCell className={classes.title}>
														Type
                                                    </TableCell>
													<TableCell className={classes.title}>
														Qty
                                                    </TableCell>
                                                    <TableCell className={classes.title}>
                                                        MSRP
                                                    </TableCell>
                                                    <TableCell className={classes.title}>
                                                        Price
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
												{
													Object.entries(listItems).map( ([type, {
														total_sell_price = 0,
														total_msrp = 0,
														count = 0
													}], idx) => {
														const modType = type.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
														return (
															<TableRow hover role="checkbox" tabIndex={-1} key={idx}>
																<TableCell>{modType}</TableCell>
																<TableCell>{count}</TableCell>
																<TableCell>${toNumberFormat(total_msrp ?? 0)}</TableCell>
																<TableCell>${toNumberFormat(total_sell_price ?? 0)}</TableCell>
															</TableRow>
														)
													})
												}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper square style={{padding:'15px', height: '100%'}}>
                                    <Typography className={classes.title}>Choose Payment Method</Typography>
                                    <Divider></Divider>
                                    <FormControl component="fieldset"  style={{width:'100%'}}>
                                        <RadioGroup row aria-label="position" name="position" value="purchase_order">
                                            <FormControlLabel style={{width:'50%', margin:'auto'}} value="purchase_order" control={<Radio color="primary" />} label="Purchase Order" />
                                            <FormControlLabel style={{width:'50%', margin:'auto'}} value="credit_card" control={<Radio color="primary" />} label="Credit Card" />
                                        </RadioGroup>
                                    </FormControl>
                                    <div style={{margin:'10px', padding:'15px', background: theme.palette.action.selected, borderRadius:'4px'}}>
                                        <div style={{display:'flex'}}>
                                            <Typography style={{width:'50%'}}>Purchase Order # </Typography>
                                            <Typography>Creation Date (MM/DD/YYYY)</Typography>
                                        </div>
                                        <div style={{display:'flex'}}>
                                            <TextField
                                                id="filled-margin-dense"
                                                placeholder="i.e. 647223567"
                                                type="text"
												value={orderId}
												onChange={(e) => setOrderId(e.target.value)}
                                                style={{width:"49%", margin:'auto'}}
                                            />
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													style={{width:'49%', margin:'auto'}}
													margin="normal"
													id="date-picker-dialog"
													format="MM/dd/yyyy"
													invalidLabel="MM/dd/yyyy"
													value={creationDate}
													onChange={handleDateChange}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
											</MuiPickersUtilsProvider>
                                        </div>
                                        <Typography>Memo</Typography>
                                        <div>
                                            <TextareaAutosize
                                                rowsMin={6}
                                                rowsMax={6}
                                                aria-label="your memo"
                                                placeholder="Your Memo"
												value={memo}
												onChange={(e)=>setMemo(e.target.value)}
                                                style={{width:'100%'}}
                                            />
                                        </div>
										<Paper elevation={0} style={{background: 'transparent'}}>
											<input className={classes.input} id="icon-button-file" type="file" onChange={(e) => handlePurchaseOrderUpload(e.target.files)}/>
											<label htmlFor="icon-button-file">
												<Typography >
													Upload Purchase Order (.pdf)
													<IconButton color="primary" aria-label="upload purchase order" component="span">	
														<PublishIcon />
													</IconButton>
													{/* {
														fileName != '' && !loading && <span> - {fileName}</span>
													} */}
													{ loading ? 
														<>
															<div className={classes.loader}>
															<CircularProgress />
															</div>
														</> : (fileName ? <Typography>{fileName}</Typography> : '')
													}
												</Typography>
												
											</label>
										</Paper>
                                    </div>
                                    
                                </Paper>
                            </Grid>
                        </Grid>
					{/* </CardContent>
				</Card> */}
				
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, transition: { delay: 0.1 } }}
					className="flex flex-1 items-center justify-end pt-10"
				>
					<Button
					color="secondary"
					variant="outlined"
					className={`${classes.buttonSquare} ml-10`}
					>
						Send by Email
					</Button>
					<Button
					color="secondary"
					variant="contained"
					className={`${classes.buttonSquare} ml-10`}
					onClick={handleQuoteAcceptance}
					>
						Approve
					</Button>
				</motion.div>
				</Grid>
				</Grid>
				</main>
				</div>
			</div>
			</FuseScrollbars>
		</div>
	);
}

export default QuoteDetail;
