import axiosInstance from '@fuse/utils/axios';
import React, { useEffect, useState } from 'react';

function AssetSpecsTab({ assetDetails }) {
  const [assetSpecs, setAssetSpecsData] = useState([])

  useEffect(() => {
    if (!assetDetails) return
    axiosInstance.get(`/assets/${assetDetails.id}/information?model_id=${assetDetails.model_id}`).then((res) => {
      setAssetSpecsData(res.data.data.asset.asset_specs)
    }).catch((err) => {
      console.log('error', err)
    })
  }, [assetDetails])

  return (
    <div className='cstm_aggridtable_holder'>
      {
        Boolean(assetSpecs.length) ? (
          <table style={{ maxWidth: '650px' }}>
            <thead>
              <tr>
                <th>Attributes</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {
                assetSpecs.map((item, index)=>(
                  <tr key={index}>
                    <td>{item.attribute.attribute_name}</td>
                    <td>{Boolean(item.attribute_value.length) ? item.attribute_value : "N/A"}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        ):(
          <table style={{ maxWidth: '650px' }}>
            <thead>
              <tr>
                <th>Attributes</th>
                <th>Value</th>
              </tr>
            </thead>
            <tr rowSpan="2">
                <td style={{textAlign: 'end'}}>No Data Found</td>
              </tr>
          </table>
        )
      }
    </div>
  )
}

export default AssetSpecsTab
