import useToast from '@fuse/hooks/useToast'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	FormControl, InputLabel, MenuItem, Select,
	Checkbox, OutlinedInput, ListItemText, Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from '@fuse/utils/axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import AlertModal from 'helper/alertModal';
import { closeFormViewerDialog, editFormViewerDialog } from '../store/formViewDialogReducer'


const CustomCheckbox = withStyles({
	root: {
		color: '#555555',
	}
})(Checkbox)

const Accordion = withStyles({
	root: {
		border: '1px solid rgba(107, 44, 87, 0.5)',
		borderRadius: '5px',
		backgroundColor: 'rgba(107, 44, 87, 0.100)',
		color: '#1E2125',
		boxShadow: 'none',
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			marginBottom: '10px',
			borderBottom: '1px solid rgba(107, 44, 87, 0.5)',
		},
		marginBottom: '10px'
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		margin: 0,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: 0,
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);

const CustomTextField = withStyles((theme) => ({
	root: {
		border: '1px solid rgba(0, 0, 0, .6)',
		borderRadius: '5px',
		background: 'white',
		'& input': {
			// border: '1px solid rgba(0, 0, 0, .8)',
			color: '#000000'
		},
		'& label': {
			color: '#999999',
			backgroundColor: '#fff'
		},
		'& label.Mui-focused': {
			color: '#000000',
			backgroundColor: '#fff'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'white'
		},
		'& .MuiOutlinedInput-root': {
			// '& > fieldset': {
			//     borderColor: 'white'
			// },
			// '&:hover fieldset': {
			//     borderColor: 'white'
			// },
			'&.Mui-focused fieldset': {
				borderColor: 'rgba(0, 0, 0, .6)',

			}
		}
	}
}))(TextField)

const CustomSelect = withStyles((theme) => ({
  	root: {
		border: '1px solid rgba(0, 0, 0, .8)',
		borderRadius: '5px',
		'& input': {
			// border: '1px solid rgba(0, 0, 0, .8)',
			color: '#000000'
		},
		'& label': {
			color: '#000000',
			backgroundColor: '#fff'
		},
		'& label.Mui-focused': {
			color: '#000000',
			backgroundColor: '#fff'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'white'
		},
		'& .MuiOutlinedInput-root': {
			'& > fieldset': {
				borderColor: 'white'
			},
			// '&:hover fieldset': {
			//     borderColor: 'white'
			// },
			'&.Mui-focused fieldset': {
				borderColor: 'black',
			}
		}
	}
}))(Select)

const useStyles = makeStyles(theme => (
	{
			uploadImageButton: {
					padding: '6px 16px',
					fontSize: '1.3rem',
					minWidth: '64px',
					boxSizing: 'border-box',
					transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					fontFamily: '"Inter", sans-serif',
					fontWeight: 500,
					lineHeight: 1.75,
					borderRadius: '4px',
					textTransform: 'none',
					backgroundColor: '#6B2C57',
					color: '#ffffff',
					cursor: 'pointer'
			},
			loader: {
					display: 'flex',
					'& > * + *': {
							marginLeft: theme.spacing(2),
					},
			},
			subSection: {
					marginLeft: '20px',
					marginRight: '20px',
					marginBottom: '20px',
					padding: '10px',
					backgroundColor: '#F9FAFB',
					borderRadius: '4px'
			},
			endButtons: {
					borderRadius: '4px'
			},
			drawerWidth: {
					width: 520,
					borderTopLeftRadius: '8px',
					borderBottomLeftRadius: '8px',
					overflowX: 'hidden',
					overflowY: 'hidden'
			},
	}
))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
		// top:'500px',
		// marginTop: '60px',
		// maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		// width: 250,
		backgroundColor: '#f1f1f1',
		color: 'black',
		maxHeight: '230px'
		, width: 250,
		// transform: "translateY(-100%)"
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "center"
	},
	transformOrigin: {
		vertical: "bottom",
		horizontal: "center"
	},
	getContentAnchorEl: null
};

function AddRolesDialog(props) {
	const classes = useStyles()
	const scrollRef = useRef()

 	const [menuProps, setMenuprops] = useState({
		PaperProps: {
			style: {
				// top:'500px',
				// marginTop: '60px',
				// maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				// width: 250,
				backgroundColor: '#f1f1f1',
				// color: 'black',
				maxHeight: '230px',
				width: 250,
				fontFamily: 'Inter, sans-serif',
				fontSize: '14px',
				fontWeight: '400',
				color: '#1D2939'
				// transform: "translateY(-100%)"
			},
		},
		// anchorOrigin: {
		// 	vertical: "bottom",
		// 	horizontal: "center"
		// },
		// transformOrigin: {
		// 	vertical: "bottom",
		// 	horizontal: "center"
		// },
		getContentAnchorEl: null
	})

	const dispatch = useDispatch()
	const toast = useToast(dispatch)

	const toSnakeCase = (payloadString) => {
		const test = payloadString.split(' ').join('_').toLowerCase()
		return test
	}

	const openState = useSelector((state) => state.rolesApp.formViewDialog)
	// const { data } = useSelector((state) => state.rolesApp.formViewDialog);

	const [loading, setLoading] = useState(false)
	const [type, setType] = useState()
	const [editD, setEditD] = useState()
	const [name, setName] = useState()
	const [disableType, setDisableType] = useState(false)
	const [locationValue, setLocationValue] = useState([])
	const [locationArr, setLocationArr] = useState([])
	const [permissionLocation, setPermissionLocation] = useState([])
	const [permissionLocationUnique, setpermissionLocationUnique] = useState([])
	const [locationIdArr, setLocationIdArr] = useState([])
	const [addUpdatedateLoading, setAddUpdatedateLoading] = useState(false)
	// let {editData=[]} = props

	const [checkState, setCheckState] = useState({
		users_checked: false,
		roles_checked: false,
		categories_checked: false,
		models_checked: false,
		manufacturer_checked: false,
		asset_status_checked: false,
		departments_checked: false,
		locations_checked: false
	})

	const handleCheckChange = (event) => {
		if (!event) return
		setCheckState({ ...checkState, [event.target.name]: event.target.checked })
	}

	// const  nonSystemModules = ['Assets', 'Contracts', 'Subscriptions']
	const systemModules = ['Users', 'Roles', 'Categories', 'Models', 'Manufacturers', 'Asset Status', 'Departments', 'Locations']
	const dummyLocations = locationArr
	const [roleName, setRoleName] = React.useState('');
	const [roleDesc, setRoleDesc] = React.useState('');
	const [userData, setUserData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: true
	});
	const [roleData, setRoleData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: true
	});
	const [categoryData, setCategoryData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: true
	});
	const [modelData, setModelData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: true
	});
	const [manufacturerData, setManufacturerData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: true
	});
	const [assetStatusData, setAssetStatusData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: true
	});
	const [departmentData, setDepartmentData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: true
	});
	const [assetCategory, setAssetCategory] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [locationData, setLocationData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: true
	});
	const [subcriptionData, setSubcriptionData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [contractData, setContractData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [assetData, setAssetData] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [accessory, setAccessory] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [modelAttribute, setModelAttribute] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [accessoryCategory, setAccessoryCategory] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [paymentTerm, setPaymentTerm] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [contractStatus, setContractStatus] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [contractType, setContractType] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [serviceProvider, setServiceProvider] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [serviceLevelAggrement, setServiceLevelAggrement] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [subscriptionCategory, setSubscriptionCategory] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [subscriptionStatus, setSubscriptionStatus] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [cloudProvider, setCloudProvider] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [serviceRequest, setServiceRequest] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [serviceRequestCategory, setServiceRequestCategory] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [serviceRequestStatus, setServiceRequestStatus] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [serviceRequestSource, setServiceRequestSource] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	const [locationType, setLocationType] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: true
	});
	const [report, setReport] = React.useState({
		view: false,
		create: false,
		update: false,
		delete: false,
		is_system_module: false
	});
	// newly_Added = "asset category","accessory","model attribute","accessory category"

	const [locationName, setLocationName] = React.useState([]);
	const [roleNameError, setRoleNameError] = React.useState(false);
	const [roleDescError, setRoleDescError] = React.useState(false);
	const [roleLocationError, setRoleLocationError] = React.useState(false);
	// const [locationName, setLocationName] = React.useState([]);
	// const [locationName, setLocationName] = React.useState([]);
	const [openAlerModal, setOpenAlertModal] = useState(false);

	const handleChange = (event) => {
		const {
		target: { value },
		} = event;
		if(value) setRoleLocationError(false)
		// console.log('214=>',event)
		setLocationName(
		// On autofill we get a stringified value.
		typeof value === 'number' ? value.split(',') : value,
		);
	};

	// form validation
	// const schema = yup.object({
	// 	role_name: yup.string().required('Role Name is required!'),
	// 	description: yup.string().required('Description is required!'),

	// })
	const defaultStatus = {
		status: ''
	}
	const defaultValues = {
		status: ''
	}

	const onCancel = () => {
		reset(defaultValues)
		setType(null)
		// dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setDefault()
		// if(props.editCloseForm){
		//   props?.editCloseForm(true)
		// }
		// if(props.closeForm){
		//   props?.closeForm(true)
		// }
	}

	function getUltimateData(module, arr) {
		const data = {
			create: false,
			update: false,
			view: false,
			delete: false
		}
		if (arr?.length > 0) {
			arr.forEach((item) => {
				if (item.action_name.includes('view')) {
					data.view = true
				}
				if (item.action_name.includes('add')) {
					data.create = true
				}
				if (item.action_name.includes('update')) {
					data.update = true
				}
				if (item.action_name.includes('delete')) {
					data.delete = true
				}
			})
		}
		return data
	}

	function seperateValueForEdit() {
		if (openState.edit_data) {
			setRoleName(openState.edit_data.data.role_name)
			setRoleDesc(openState.edit_data.data.description)
			const location_id = JSON.parse(openState.edit_data.data.action_location).map(item => item.id)
			setLocationName(location_id)
			const data_asset = getUltimateData('assets', openState.edit_data.edit_asset)
			setAssetData(
				prevState => ({
				...prevState,
				create: data_asset.create,
				update: data_asset.update,
				view: data_asset.view,
				delete: data_asset.delete,
			}))

			// role
			const data_role = getUltimateData('role', openState.edit_data.edit_role)
			setRoleData(
				prevState => ({
				...prevState,
				create: data_role.create,
				update: data_role.update,
				view: data_role.view,
				delete: data_role.delete,
			}))

			const data_sub = getUltimateData('subcription', openState.edit_data.edit_subcription)
			// let demo = data_.create
			setSubcriptionData(
				prevState => ({
				...prevState,
				create: data_sub.create,
				update: data_sub.update,
				view: data_sub.view,
				delete: data_sub.delete,
				}))
			const data_user = (getUltimateData('end-user', openState.edit_data.edit_user))
			setUserData(prevState => ({
				...prevState,
				create: data_user.create,
				update: data_user.update,
				view: data_user.view,
				delete: data_user.delete,
			}))
			const data_contract = (getUltimateData('contracts', openState.edit_data.edit_contract))

			setContractData(prevState => ({
				...prevState,
				create: data_contract.create,
				update: data_contract.update,
				view: data_contract.view,
				delete: data_contract.delete,
			}))
			const data_category = (getUltimateData('category', openState.edit_data.edit_category))

			setCategoryData(prevState => ({
				...prevState,
				create: data_category.create,
				update: data_category.update,
				view: data_category.view,
				delete: data_category.delete,
			}))

			const data_model = (getUltimateData('model', openState.edit_data.edit_model))

			setModelData(prevState => ({
				...prevState,
				create: data_model.create,
				update: data_model.update,
				view: data_model.view,
				delete: data_model.delete,
			}))

			const data_manufacturerData = (getUltimateData('manufacturer', openState.edit_data.edit_manufacturer))

			setManufacturerData(prevState => ({
				...prevState,
				create: data_manufacturerData.create,
				update: data_manufacturerData.update,
				view: data_manufacturerData.view,
				delete: data_manufacturerData.delete,
			}))

			const data_assetStatus = (getUltimateData('asset_status', openState.edit_data.edit_asset_status))

			setAssetStatusData(prevState => ({
				...prevState,
				create: data_assetStatus.create,
				update: data_assetStatus.update,
				view: data_assetStatus.view,
				delete: data_assetStatus.delete,
			}))

			const data_location = (getUltimateData('location', openState.edit_data.edit_location))

			setLocationData(prevState => ({
				...prevState,
				create: data_location.create,
				update: data_location.update,
				view: data_location.view,
				delete: data_location.delete,
			}))

			const data_department = (getUltimateData('department', openState.edit_data.edit_department))

			setDepartmentData(prevState => ({
				...prevState,
				create: data_department.create,
				update: data_department.update,
				view: data_department.view,
				delete: data_department.delete,
			}))
			const data_edit_asset_category = (getUltimateData('asset_category', openState.edit_data.edit_asset_category))

			setAssetCategory(prevState => ({
				...prevState,
				create: data_edit_asset_category.create,
				update: data_edit_asset_category.update,
				view: data_edit_asset_category.view,
				delete: data_edit_asset_category.delete,
			}))
			const data_accessory = (getUltimateData('accessory', openState.edit_data.edit_accessory))

			setAccessory(prevState => ({
				...prevState,
				create: data_accessory.create,
				update: data_accessory.update,
				view: data_accessory.view,
				delete: data_accessory.delete,
			}))
			const data_model_attribute = (getUltimateData('model_attribute', openState.edit_data.edit_model_attribute))

			setModelAttribute(prevState => ({
				...prevState,
				create: data_model_attribute.create,
				update: data_model_attribute.update,
				view: data_model_attribute.view,
				delete: data_model_attribute.delete,
			}))
			const data_accessory_category = (getUltimateData('accessory_category', openState.edit_data.edit_accessory_category))

			setAccessoryCategory(prevState => ({
				...prevState,
				create: data_accessory_category.create,
				update: data_accessory_category.update,
				view: data_accessory_category.view,
				delete: data_accessory_category.delete,
			}))
			const data_edit_payment_term = (getUltimateData('edit_payment_term', openState.edit_data.edit_payment_term))

			setPaymentTerm(prevState => ({
				...prevState,
				create: data_edit_payment_term.create,
				update: data_edit_payment_term.update,
				view: data_edit_payment_term.view,
				delete: data_edit_payment_term.delete,
			}))
			const data_contract_status = (getUltimateData('contract_status', openState.edit_data.edit_contract_status))

			setContractStatus(prevState => ({
				...prevState,
				create: data_contract_status.create,
				update: data_contract_status.update,
				view: data_contract_status.view,
				delete: data_contract_status.delete,
			}))
			const contract_type = (getUltimateData('contract_type', openState.edit_data.edit_contract_type))

			setContractType(prevState => ({
				...prevState,
				create: contract_type.create,
				update: contract_type.update,
				view: contract_type.view,
				delete: contract_type.delete,
			}))
			const service_provider = (getUltimateData('service_provider', openState.edit_data.edit_service_provider))

			setServiceProvider(prevState => ({
				...prevState,
				create: service_provider.create,
				update: service_provider.update,
				view: service_provider.view,
				delete: service_provider.delete,
			}))
			const service_level_aggrement = (getUltimateData('service_level_aggrement', openState.edit_data.edit_service_level_aggrement))

			setServiceLevelAggrement(prevState => ({
				...prevState,
				create: service_level_aggrement.create,
				update: service_level_aggrement.update,
				view: service_level_aggrement.view,
				delete: service_level_aggrement.delete,
			}))
			const subscription_category = (getUltimateData('subscription_category', openState.edit_data.edit_subscription_category))

			setSubscriptionCategory(prevState => ({
				...prevState,
				create: subscription_category.create,
				update: subscription_category.update,
				view: subscription_category.view,
				delete: subscription_category.delete,
			}))
			const subscription_status = (getUltimateData('subscription_status', openState.edit_data.edit_subscription_status))

			setSubscriptionStatus(prevState => ({
				...prevState,
				create: subscription_status.create,
				update: subscription_status.update,
				view: subscription_status.view,
				delete: subscription_status.delete,
			}))
			const cloud_provider = (getUltimateData('cloud_provider', openState.edit_data.edit_cloud_provider))

			setCloudProvider(prevState => ({
				...prevState,
				create: cloud_provider.create,
				update: cloud_provider.update,
				view: cloud_provider.view,
				delete: cloud_provider.delete,
			}))
			const service_request = (getUltimateData('cloud_provider', openState.edit_data.edit_service_request))

			setServiceRequest(prevState => ({
				...prevState,
				create: service_request.create,
				update: service_request.update,
				view: service_request.view,
				delete: service_request.delete,
			}))
			const service_request_category = (getUltimateData('service_request_category', openState.edit_data.edit_service_request_category))

			setServiceRequestCategory(prevState => ({
				...prevState,
				create: service_request_category.create,
				update: service_request_category.update,
				view: service_request_category.view,
				delete: service_request_category.delete,
			}))
			const service_request_status = (getUltimateData('service_request_status', openState.edit_data.edit_service_request_status))

			setServiceRequestStatus(prevState => ({
				...prevState,
				create: service_request_status.create,
				update: service_request_status.update,
				view: service_request_status.view,
				delete: service_request_status.delete,
			}))
			const service_request_source = (getUltimateData('service_request_source', openState.edit_data.edit_service_request_source))

			setServiceRequestSource(prevState => ({
				...prevState,
				create: service_request_source.create,
				update: service_request_source.update,
				view: service_request_source.view,
				delete: service_request_source.delete,
			}))
			const location_type = (getUltimateData('location_type', openState.edit_data.edit_location_type))
			setLocationType(prevState => ({
				...prevState,
				create: location_type.create,
				update: location_type.update,
				view: location_type.view,
				delete: location_type.delete,
			}))


			const report = (getUltimateData('report', openState.edit_data.edit_report))
			setReport(prevState => ({
				...prevState,
				create: report.create,
				update: report.update,
				view: report.view,
				delete: report.delete,
			}))
		}
	}

  function setDefault() {
		setRoleName('')
		setRoleDesc('')
		dispatch(editFormViewerDialog(null))
		setLocationName([])
		setLocationIdArr([])
		setRoleNameError(false)
		setRoleDescError(false)
		setRoleLocationError(false)
		setUserData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: true
		});
		setRoleData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: true
		});
		setCategoryData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: true
		});
		setModelData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: true
		});
		setManufacturerData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: true
		});
		setAssetStatusData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: true
		});
		setDepartmentData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: true
		});
		setLocationData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: true
		});
		setSubcriptionData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setContractData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setAssetData({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setAssetCategory({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setAccessory({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setModelAttribute({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setAccessoryCategory({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setPaymentTerm({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setContractStatus({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setContractType({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setServiceProvider({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setServiceLevelAggrement({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setSubscriptionCategory({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setSubscriptionStatus({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setCloudProvider({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setServiceRequest({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setServiceRequestCategory({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setServiceRequestStatus({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setServiceRequestSource({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
		setLocationType({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: true
		});
		setReport({
			view: false,
			create: false,
			update: false,
			delete: false,
			is_system_module: false
		});
  }

	function getLocation_() {
		// if(!viewPermission){
		// 	return;
		// }
		return axios.get("/shipping/fetch-all").then((res) => {
			const { data } = res.data;
			const location = []
			for (let i = 0; i < data.length; i++) {
				location.push({
				id: data[i].id,
				name: `${data[i]?.location_name}-${data[i]?.address1}, ${data[i]?.city}, ${data[i]?.state}, ${data[i]?.country}`
				})
			}
			setLocationArr(location)
			}).catch((err) => {
				console.log(err)
			}).finally(() => {
		})
	}

	useEffect(() => {
		seperateValueForEdit()
	}, [openState.edit_data])

	useEffect(() => {
		if (openState.open) {
			getLocation_()
		}
	}, [openState.open])

	const closeDialog = () => {
		// editData = []
		// dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setDefault()
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		// resolver: yupResolver(schema)
	});

  	const { errors } = formState

	function checkData(key, actual_val, obj) {
		const final_data = {
			add: [],
			delete: []
		}
		if(actual_val == 'location_type'){
			console.log("099", obj, openState.edit_data)
		}


		// view
		if ((openState.edit_data[key]?.find(item => item.action_name == `view-${actual_val}`) && !obj.view)) {
			final_data.delete.push(`view-${actual_val}`)
		}
		if ((!openState.edit_data[key]?.find(item => item.action_name == `view-${actual_val}`) && obj.view)) {
			final_data.add.push(`view-${actual_val}`)
		}

		// update
		if ((openState.edit_data[key]?.find(item => item.action_name == `update-${actual_val}`) && !obj.update)) {
			final_data.delete.push(`update-${actual_val}`)
		}
		if ((!openState.edit_data[key]?.find(item => item.action_name == `update-${actual_val}`) && obj.update)) {
			final_data.add.push(`update-${actual_val}`)
		}

		// add
		if ((openState.edit_data[key]?.find(item => item.action_name == `add-${actual_val}`) && !obj.create)) {
			final_data.delete.push(`add-${actual_val}`)
		}
		if ((!openState.edit_data[key]?.find(item => item.action_name == `add-${actual_val}`) && obj.create)) {
			final_data.add.push(`add-${actual_val}`)
		}
		// delete
		if ((openState.edit_data[key]?.find(item => item.action_name == `delete-${actual_val}`) && !obj.delete)) {
			final_data.delete.push(`delete-${actual_val}`)
		}
		if ((!openState.edit_data[key]?.find(item => item.action_name == `delete-${actual_val}`) && obj.delete)) {
			final_data.add.push(`delete-${actual_val}`)
		}

		return final_data
	}

	const manageFormData = (formData) => {
		console.log("locationIdArr", locationIdArr)
		if(!roleName.trim()) setRoleNameError(true)
		if(!roleDesc.trim()) setRoleDescError(true)
		if(!locationIdArr?.length) setRoleLocationError(true)
		if(!roleName.trim() || !roleDesc.trim() || !locationIdArr?.length) return
		const result_arr = []
		for (let i = 0; i < locationIdArr.length; i++) {
			for (let j = 0; j < locationArr.length; j++) {
				if (locationIdArr[i] == locationArr[j].id) {
				result_arr.push(locationArr[j])
				}
			}
		}
		setAddUpdatedateLoading(true)

		if (openState.edit_data) {
			const edit_data_asset = checkData('edit_asset', 'assets', assetData)
			const edit_data_contract = checkData('edit_contract', 'contract', contractData)
			const edit_data_subcription = checkData('edit_subcription', 'subcription', subcriptionData)
			const edit_user_data = checkData('edit_user', 'end-user', userData)
			const edit_data_role = checkData('edit_role', 'role', roleData)
			const edit_data_category = checkData('edit_category', 'category', categoryData)
			const edit_data_model = checkData('edit_model', 'model', modelData)
			const edit_data_manufacture = checkData('edit_manufacturer', 'manufacture', manufacturerData)
			const edit_data_asset_status = checkData('edit_asset_status', 'asset_status', assetStatusData)
			const edit_data_department = checkData('edit_department', 'department', departmentData)
			const edit_data_location = checkData('edit_location', 'location', locationData)
			const edit_data_asset_category = checkData('edit_asset_category', 'asset_category', assetCategory)
			const edit_data_accessory = checkData('edit_accessory', 'accessory', accessory)
			const edit_data_model_attribute = checkData('edit_model_attribute', 'model_attribute', modelAttribute)
			const edit_data_accessory_category = checkData('edit_accessory_category', 'accessory_category', accessoryCategory)
			const edit_data_payment_term = checkData('edit_payment_term', 'payment_term', paymentTerm)
			const edit_data_contract_status = checkData('edit_contract_status', 'contract_status', contractStatus)
			const edit_data_contract_type = checkData('edit_contract_type', 'contract_type', contractType)
			const edit_data_service_provider = checkData('edit_service_provider', 'service_provider', serviceProvider)
			const edit_data_service_level_aggrement = checkData('edit_service_level_aggrement', 'service_level_aggrement', serviceLevelAggrement)
			const edit_data_subscription_category = checkData('edit_subscription_category', 'subscription_category', subscriptionCategory)
			const edit_data_subscription_status = checkData('edit_subscription_status', 'subscription_status', subscriptionStatus)
			const edit_data_cloud_provider = checkData('edit_cloud_provider', 'cloud_provider', cloudProvider)
			const edit_data_service_request= checkData('edit_service_request', 'service_request', serviceRequest)
			const edit_data_service_request_category= checkData('edit_service_request_category', 'service_request_category', serviceRequestCategory)
			const edit_data_service_request_status= checkData('edit_service_request_status', 'service_request_status', serviceRequestStatus)
			const edit_data_service_request_source= checkData('edit_service_request_source', 'service_request_source', serviceRequestSource)
			const edit_data_location_type= checkData('edit_location_type', 'location_type', locationType)
			const edit_data_report= checkData('edit_report', 'report', report)
			const payload = {
				edit_id: Number(openState.edit_data.data.id),
				role_name: roleName,
				description: roleDesc,
				action_location: JSON.stringify(result_arr),
				asset: edit_data_asset,
				contract: edit_data_contract,
				subcription: edit_data_subcription,
				user: edit_user_data,
				role: edit_data_role,
				category: edit_data_category,
				model: edit_data_model,
				manufacturer: edit_data_manufacture,
				asset_status: edit_data_asset_status,
				department: edit_data_department,
				location: edit_data_location,
				asset_category:edit_data_asset_category,
				accessory:edit_data_accessory,
				model_attribute:edit_data_model_attribute,
				accessory_category:edit_data_accessory_category,
				payment_term:edit_data_payment_term,
				contract_status:edit_data_contract_status,
				contract_type:edit_data_contract_type,
				service_provider:edit_data_service_provider,
				service_level_aggrement: edit_data_service_level_aggrement,
				subscription_category:edit_data_subscription_category,
				subscription_status:edit_data_subscription_status,
				cloud_provider:edit_data_cloud_provider,
				service_request:edit_data_service_request,
				service_request_category:edit_data_service_request_category,
				service_request_status:edit_data_service_request_status,
				service_request_source:edit_data_service_request_source,
				location_type: edit_data_location_type,
				report: edit_data_report,
			}

			axios.post('/user-role/update', payload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					closeDialog()
					toast.success('Role updated successfully.')
					setDefault()
				}
				setAddUpdatedateLoading(false)
				
			}).catch(err => {
				console.log('212=>', err);
				toast.error('Something went wrong!')
			})
			return
		}

		const payload = {
			role_name: roleName,
			role_description: roleDesc,
			asset: assetData,
			contract: contractData,
			subcription: subcriptionData,
			// permission_location:locationData,
			user: userData,
			role: roleData,
			category: categoryData,
			model: modelData,
			manufacture: manufacturerData,
			asset_status: assetStatusData,
			department: departmentData,
			location: locationData,
			asset_category:assetCategory,
			accessory,
			model_attribute:modelAttribute,
			accessory_category:accessoryCategory,
			payment_term:paymentTerm,
			contract_status:contractStatus,
			contract_type:contractType,
			service_provider:serviceProvider,
			service_level_aggrement: serviceLevelAggrement,
			subscription_category:subscriptionCategory,
			subscription_status:subscriptionStatus,
			cloud_provider:cloudProvider,
			service_request:serviceRequest,
			service_request_category:serviceRequestCategory,
			service_request_status:serviceRequestStatus,
			service_request_source:serviceRequestSource,
			location_type:locationType,
			report,
			permission_location: JSON.stringify(result_arr)
		}
		axios.post('/user-role/add', payload).then(response => {
		if (response.status == 201) {
			toast.error(response.data.message)
		} else {
			//   onSuccess()
			closeDialog()
			toast.success('Role added successfully.')
			setDefault()
		}
		setAddUpdatedateLoading(false)
		}).catch(err => {
		console.log('212=>', err);
		toast.error('Something went wrong!')
		})
	}

	const errorData = (error) => {
		console.log('363=>', error)
	}

	function handelCreateCheckbox(acess, module) {
		if (module == 'Assets') {
		setAssetData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Contracts') {
		setContractData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Subscriptions') {
		setSubcriptionData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}

		if (module == 'Users') {
		setUserData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Roles') {
		setRoleData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Categories') {
		setCategoryData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Models') {
		setModelData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Manufacturers') {
		setManufacturerData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Asset Status') {
		setAssetStatusData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Departments') {
		setDepartmentData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Locations') {
		setLocationData(prevState => ({
			...prevState,
			create: !prevState.create
		}))
		}
		if (module == 'Asset-Category') {
			setAssetCategory(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Accessory') {
			setAccessory(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Model-Attribute') {
			setModelAttribute(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Accessory-Category') {
			setAccessoryCategory(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Payment-Term') {
			setPaymentTerm(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Contract-Status') {
			setContractStatus(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Contract-Type') {
			setContractType(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Service-Provider') {
			setServiceProvider(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Service-Level-Aggrement') {
			setServiceLevelAggrement(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Subscription-Category') {
			setSubscriptionCategory(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Subscription-Status') {
			setSubscriptionStatus(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Cloud-Provider') {
			setCloudProvider(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Service-Request') {
			setServiceRequest(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Service-Request-Category') {
			setServiceRequestCategory(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Service-Request-Status') {
			setServiceRequestStatus(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Service-Request-Source') {
			setServiceRequestSource(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'Location-Type') {
			setLocationType(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
		if (module == 'report') {
			setReport(prevState => ({
				...prevState,
				create: !prevState.create
			}))
		}
	}

	function handelReadCheckbox(acess, module) {
		if (module == 'Assets') {
			setAssetData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		
		if (module == 'Contracts') {
			setContractData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Subscriptions') {
			setSubcriptionData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Users') {
			setUserData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Roles') {
			setRoleData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Categories') {
			setCategoryData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Models') {
			setModelData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Manufacturers') {
			setManufacturerData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Asset Status') {
			setAssetStatusData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Departments') {
			setDepartmentData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Locations') {
			setLocationData(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		// new implementation 
		if (module == 'Asset-Category') {
			setAssetCategory(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Accessory') {
			setAccessory(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Model-Attribute') {
			setModelAttribute(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Accessory-Category') {
			setAccessoryCategory(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Payment-Term') {
			setPaymentTerm(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Contract-Status') {
			setContractStatus(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Contract-Type') {
			setContractType(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Service-Provider') {
			setServiceProvider(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Service-Level-Aggrement') {
			setServiceLevelAggrement(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Subscription-Category') {
			setSubscriptionCategory(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Subscription-Status') {
			setSubscriptionStatus(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Cloud-Provider') {
			setCloudProvider(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Service-Request') {
			setServiceRequest(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Service-Request-Category') {
			setServiceRequestCategory(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Service-Request-Status') {
			setServiceRequestStatus(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Service-Request-Source') {
			setServiceRequestSource(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'Location-Type') {
			setLocationType(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}
		if (module == 'report') {
			setReport(prevState => ({
				...prevState,
				view: !prevState.view
			}))
		}

	}

	// update
	function handelUpdateCheckbox(acess, module) {
		if (module == 'Assets') {
			setAssetData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Location-Type') {
			setLocationType(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Contracts') {
			setContractData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Subscriptions') {
			setSubcriptionData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Users') {
			setUserData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Roles') {
			setRoleData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Categories') {
			setCategoryData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Models') {
			setModelData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Manufacturers') {
			setManufacturerData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Asset Status') {
			setAssetStatusData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Departments') {
			setDepartmentData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Locations') {
			setLocationData(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Asset-Category') {
			setAssetCategory(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Accessory') {
			setAccessory(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Model-Attribute') {
			setModelAttribute(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Accessory-Category') {
			setAccessoryCategory(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Payment-Term') {
			setPaymentTerm(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Contract-Status') {
			setContractStatus(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Contract-Type') {
			setContractType(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Service-Provider') {
			setServiceProvider(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Service-Level-Aggrement') {
			setServiceLevelAggrement(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Subscription-Category') {
			setSubscriptionCategory(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Subscription-Status') {
			setSubscriptionStatus(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Cloud-Provider') {
			setCloudProvider(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Service-Request') {
			setServiceRequest(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Service-Request-Category') {
			setServiceRequestCategory(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Service-Request-Status') {
			setServiceRequestStatus(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'Service-Request-Source') {
			setServiceRequestSource(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		if (module == 'report') {
			setReport(prevState => ({
				...prevState,
				update: !prevState.update
			}))
		}
		
	}

	// delete
	function handelDeleteCheckbox(acess, module) {
		if (module == 'Assets') {
			setAssetData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Location-Type') {
			setLocationType(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Contracts') {
			setContractData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Subscriptions') {
			setSubcriptionData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}

		if (module == 'Users') {
			setUserData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Roles') {
			setRoleData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Categories') {
			setCategoryData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Models') {
			setModelData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Manufacturers') {
			setManufacturerData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Asset Status') {
			setAssetStatusData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Departments') {
			setDepartmentData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Locations') {
			setLocationData(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Asset-Category') {
			setAssetCategory(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Accessory') {
			setAccessory(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Model-Attribute') {
			setModelAttribute(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Accessory-Category') {
			setAccessoryCategory(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Payment-Term') {
			setPaymentTerm(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Contract-Status') {
			setContractStatus(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Contract-Type') {
			setContractType(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Service-Provider') {
			setServiceProvider(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Service-Level-Aggrement') {
			setServiceLevelAggrement(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Subscription-Category') {
			setSubscriptionCategory(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Subscription-Status') {
			setSubscriptionStatus(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Cloud-Provider') {
			setCloudProvider(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Service-Request') {
			setServiceRequest(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Service-Request-Category') {
			setServiceRequestCategory(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Service-Request-Status') {
			setServiceRequestStatus(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'Service-Request-Source') {
			setServiceRequestSource(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
		if (module == 'report') {
			setReport(prevState => ({
				...prevState,
				delete: !prevState.delete
			}))
		}
	}

	function getLocation(val) {
		let final = ''
		for (let i = 0; i < val.length; i++) {
		const data = dummyLocations.find(e => e.id == val[i])
			if (data) {
				if (final == '') {
					final += `${data.name}`
				} else {
					final += `,${data.name}`
				}
			}
		}
		return final
	}

	const ondivScroll = () => {
		if (scrollRef.current) {
		const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
			if (scrollTop + clientHeight / 2 >= scrollHeight / 2 || scrollTop >= 120) {
				setMenuprops({
					...menuProps,
					PaperProps: {
						style: {
							// marginTop: '60px',
							backgroundColor: '#f1f1f1',
							color: 'black',
							maxHeight: '230px'
							, width: 250,
							// transform: "translateY(0%)"
						},
					},
					anchorOrigin: {
						vertical: "top",
						horizontal: "center"
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "center"
					},
					getContentAnchorEl: null
				})
			}
			if (scrollTop === 0) {
				setMenuprops({
					...menuProps,
					PaperProps: {
						style: {
							backgroundColor: '#f1f1f1',
							color: 'black',
							maxHeight: '230px'
							, width: 250,
							// transform: "translateY(-50%)"
						},
					},
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "center"
					},
					transformOrigin: {
						vertical: "bottom",
						horizontal: "center"
					},
					getContentAnchorEl: null
				})
			}
		}
	}

	const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        onCancel()
    }

	return (
		<>
		<div className={classes.root}>

			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>
					<h4 className='heading'> Add Role</h4>
					<IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
							<Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
					</IconButton>
        </div>
				<div className='progressbar_holder'>
					<div className='progress'>
							&nbsp;
					</div>
				</div>
				{/* <div ref={scrollRef} onScroll={ondivScroll} style={{ height: "100%", overflow: "auto" }}> */}
					<div style={{paddingTop:'30px'}} className='cstm_wizard_holder'>
						<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder'>
								<div className='content_holder'>
									<div className="text-left">
										<form onSubmit={handleSubmit(manageFormData, errorData)} >
											<div className='mb-10'>
												<Typography className='status_text'>
																Role Name
														<span className='required_span'>*</span>
												</Typography>
												{/* <div style={{ width: '100%' }}> */}
													<CustomTextField
														name='role_name'
														// label="Role Name"
														// style={{ marginBottom: '10px' }}
														// FormHelperTextProps={{
														// 	style: { marginLeft: 0 }
														// }}
														variant="outlined"
														// required
														fullWidth
														value={roleName}
														onChange={(e) => {
															if(e.target.value?.trim()) setRoleNameError(false)
															setRoleName(e.target.value)}}
													
													style={{ marginBottom: '10px', width: '100%', backgroundColor: '#fff' }}
													/>
													{roleNameError && <p style={{color:'red'}}>Role name is required</p>}
													<Typography className='status_text'>
															Role Description
														<span className='required_span'>*</span>
													</Typography>
													
													<CustomTextField
														name='role_Desc'
														// label="Role Description"
														// FormHelperTextProps={{
														// 	style: { marginLeft: 0 }
														// }}
														className='customTextField'
														variant="outlined"
														// required
														fullWidth
														multiline
														value={roleDesc}
														onChange={(e) => {
															if(e.target.value?.trim()) setRoleDescError(false)
															setRoleDesc(e.target.value)}}
														rows={4}
														style={{ marginBottom: '10px', width: '100%', backgroundColor: '#fff', border:'.25px solid #cdc8c8' }}
													
													/>
													{roleDescError && <p style={{color:'red'}}>Role Description is required</p>}
												{/* </div> */}
											</div>
											<div className='mb-10'>
												{/* <DialogTitle style={{ padding: '16px 0px' }}>Module Permissions</DialogTitle> */}
												<Typography style={{ padding: '16px 0px' }} className='status_text'>
													Module Permissions
													{/* <span className='required_span'>*</span> */}
												</Typography>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon style={{ color: 'rgba(107, 44, 87, 0.5)' }} />}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<p>Assets</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox
																	checked={assetData.create}
																	onClick={(e) => handelCreateCheckbox("create", 'Assets')}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Assets')}
																	checked={assetData.view}
																/>
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Assets')}
																	checked={assetData.update}
																/>
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Assets')}
																	checked={assetData.delete}
																/>
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Manufacturers</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Manufacturers')}
																	checked={manufacturerData.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Manufacturers')}
																	checked={manufacturerData.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Manufacturers')}
																	checked={manufacturerData.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Manufacturers')}
																	checked={manufacturerData.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Asset Category</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Asset-Category')}
																	checked={assetCategory.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Asset-Category')}
																	checked={assetCategory.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Asset-Category')}
																	checked={assetCategory.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Asset-Category')}
																	checked={assetCategory.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<p>Models</p>
													</AccordionSummary>

													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>

															<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Models')}
																checked={modelData.create}
															/>
															<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
															<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Models')}
																checked={modelData.view} />
															<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
															<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Models')}
																checked={modelData.update} />
															<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
															<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Models')}
																checked={modelData.delete} />
															<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Accessory</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Accessory')}
																	checked={accessory.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Accessory')}
																	checked={accessory.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Accessory')}
																	checked={accessory.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Accessory')}
																	checked={accessory.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
															style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<p>Asset Status</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Asset Status')}
																	checked={assetStatusData.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Asset Status')}
																	checked={assetStatusData.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Asset Status')}
																	checked={assetStatusData.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Asset Status')}
																	checked={assetStatusData.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Model Attribute</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Model-Attribute')}
																	checked={modelAttribute.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Model-Attribute')}
																	checked={modelAttribute.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Model-Attribute')}
																	checked={modelAttribute.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Model-Attribute')}
																	checked={modelAttribute.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Accessory Category</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Accessory-Category')}
																	checked={accessoryCategory.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Accessory-Category')}
																	checked={accessoryCategory.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Accessory-Category')}
																	checked={accessoryCategory.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Accessory-Category')}
																	checked={accessoryCategory.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon style={{ color: 'rgba(107, 44, 87, 0.5)' }} />}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<p>Contracts</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox
																	checked={contractData.create}
																	onClick={(e) => handelCreateCheckbox("create", 'Contracts')} />
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox checked={contractData.view}
																	onClick={() => { handelReadCheckbox('read', 'Contracts') }}
																/>
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Contracts')}
																	checked={contractData.update}
																/>
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Contracts')}
																	checked={contractData.delete}
																/>
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												{/* payment term */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Payment Term</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Payment-Term')}
																	checked={paymentTerm.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Payment-Term')}
																	checked={paymentTerm.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Payment-Term')}
																	checked={paymentTerm.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Payment-Term')}
																	checked={paymentTerm.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>

												{/* Contract Status */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Contract Status</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Contract-Status')}
																	checked={contractStatus.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Contract-Status')}
																	checked={contractStatus.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Contract-Status')}
																	checked={contractStatus.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Contract-Status')}
																	checked={contractStatus.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>

												{/* Contract Type */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Contract Type</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Contract-Type')}
																	checked={contractType.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Contract-Type')}
																	checked={contractType.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Contract-Type')}
																	checked={contractType.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Contract-Type')}
																	checked={contractType.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>

												{/* Service Provider */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Service Provider</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Service-Provider')}
																	checked={serviceProvider.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Service-Provider')}
																	checked={serviceProvider.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Service-Provider')}
																	checked={serviceProvider.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Service-Provider')}
																	checked={serviceProvider.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>

												{/* Service  Level Aggrement */}
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Service Level Agreement</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Service-Level-Aggrement')}
																	checked={serviceLevelAggrement.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Service-Level-Aggrement')}
																	checked={serviceLevelAggrement.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Service-Level-Aggrement')}
																	checked={serviceLevelAggrement.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Service-Level-Aggrement')}
																	checked={serviceLevelAggrement.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon style={{ color: 'rgba(107, 44, 87, 0.5)' }} />}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Subcriptions</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox
																	checked={subcriptionData.create}
																	onClick={(e) => handelCreateCheckbox("create", 'Subscriptions')} />
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Subscriptions')}
																	checked={subcriptionData.view}
																/>
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Subscriptions')}
																	checked={subcriptionData.update}
																/>
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Subscriptions')}
																	checked={subcriptionData.delete}
																/>
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Subscription Category</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Subscription-Category')}
																	checked={subscriptionCategory.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Subscription-Category')}
																	checked={subscriptionCategory.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Subscription-Category')}
																	checked={subscriptionCategory.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Subscription-Category')}
																	checked={subscriptionCategory.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>

												{/* Subscription Status */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Subscription Status</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Subscription-Status')}
																	checked={subscriptionStatus.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Subscription-Status')}
																	checked={subscriptionStatus.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Subscription-Status')}
																	checked={subscriptionStatus.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Subscription-Status')}
																	checked={subscriptionStatus.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>

												{/* Cloud Provider  */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Cloud Provider</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Cloud-Provider')}
																	checked={cloudProvider.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Cloud-Provider')}
																	checked={cloudProvider.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Cloud-Provider')}
																	checked={cloudProvider.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Cloud-Provider')}
																	checked={cloudProvider.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>

												{/* Service Request */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Service Tickets</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Service-Request')}
																	checked={serviceRequest.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Service-Request')}
																	checked={serviceRequest.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Service-Request')}
																	checked={serviceRequest.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Service-Request')}
																	checked={serviceRequest.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												
												{/* Service Request Category */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Service Tickets Category</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Service-Request-Category')}
																	checked={serviceRequestCategory.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Service-Request-Category')}
																	checked={serviceRequestCategory.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Service-Request-Category')}
																	checked={serviceRequestCategory.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Service-Request-Category')}
																	checked={serviceRequestCategory.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>

												{/* Service Request Status */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Service Tickets Status</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Service-Request-Status')}
																	checked={serviceRequestStatus.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Service-Request-Status')}
																	checked={serviceRequestStatus.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Service-Request-Status')}
																	checked={serviceRequestStatus.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Service-Request-Status')}
																	checked={serviceRequestStatus.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>

												{/* Service Request Source */}

												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Service Tickets Source</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Service-Request-Source')}
																	checked={serviceRequestSource.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Service-Request-Source')}
																	checked={serviceRequestSource.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Service-Request-Source')}
																	checked={serviceRequestSource.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Service-Request-Source')}
																	checked={serviceRequestSource.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Reports</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'report')}
																	checked={report.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'report')}
																	checked={report.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'report')}
																	checked={report.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'report')}
																	checked={report.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
											</div>
											<div className='mb-10'>
												{/* <DialogTitle style={{ padding: '16px 0px' }}>Location Permissions</DialogTitle> */}
												<Typography style={{ padding: '16px 0px' }} className='status_text'>
													Location Permissions
													<span className='required_span'>*</span>
												</Typography>
												<FormControl fullWidth className='cstm_select_formcontrol min_width_auto relative'>
													{/* <InputLabel id="demo-multiple-checkbox-label" style={{ marginLeft: '30px', transform: 'translate(-15px, -4.5px) scale(0.75)', transformOrigin: 'top left', color: '#000000', backgroundColor: '#fff', padding: '0 5px' }}>Location</InputLabel> */}
													<Select
														style={{ width: '100%' }}
														className='customSelect'
														labelId="demo-multiple-checkbox-label"
														id="demo-multiple-checkbox"
														multiple
														// placeholder='Location*'
														// required
														value={locationName}
														onChange={handleChange}
														input={<OutlinedInput style={{ width: '100%' }} />}
														renderValue={(selected) => {
															setLocationIdArr(selected)
															return getLocation(selected)
														}}
														MenuProps={menuProps}
													>
														{dummyLocations.length > 0 ? dummyLocations.map((name) => (
															<MenuItem key={Math.random()} value={name.id}>
															<CustomCheckbox checked={locationName.indexOf(name.id) > -1} />
																<span>{name.name}</span>
															</MenuItem>
														)) : <MenuItem>
															<ListItemText primary="No options" />
														</MenuItem>}
													</Select>
													{roleLocationError && <p style={{color:'red'}}>Location permission is required</p>}
												</FormControl>
											</div>
											<div className='mb-10'>
												{/* <DialogTitle style={{ padding: '16px 0px' }}>System Settings</DialogTitle> */}
												<Typography style={{ padding: '16px 0px' }} className='status_text'>
														System Settings
													{/* <span className='required_span'>*</span> */}
												</Typography>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<p>Roles</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Roles')}
																	checked={roleData?.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Roles')}
																	checked={roleData?.view}
																/>
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Roles')} checked={roleData?.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Roles')} checked={roleData?.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Locations</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Locations')}
																	checked={locationData.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Locations')}
																	checked={locationData.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Locations')}
																	checked={locationData.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Locations')}
																	checked={locationData.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
													<p>Location Types</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Location-Type')}
																	checked={locationType.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Location-Type')}
																	checked={locationType.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Location-Type')}
																	checked={locationType.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Location-Type')}
																	checked={locationType.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<p>Departments</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Departments')}
																	checked={departmentData.create}
																/>
																<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Departments')}
																	checked={departmentData.view} />
																<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Departments')}
																	checked={departmentData.update} />
																<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
																<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Departments')}
																	checked={departmentData.delete} />
																<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
												<Accordion className='cstm_purple_accordion'>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																style={{ color: 'rgba(107, 44, 87, 0.5)' }}
															/>
														}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<p>Users</p>
													</AccordionSummary>
													<AccordionDetails>
														<div className='flex justify-start w-full'>
															<div className='flex items-center mr-20'>

															<CustomCheckbox onClick={(e) => handelCreateCheckbox("create", 'Users')}
																checked={userData.create}
															/>
															<p>Create</p>
															</div>
															<div className='flex items-center mr-20'>
															<CustomCheckbox onClick={(e) => handelReadCheckbox("read", 'Users')}
																checked={userData.view}
															/>
															<p>Read</p>
															</div>
															<div className='flex items-center mr-20'>
															<CustomCheckbox onClick={(e) => handelUpdateCheckbox("update", 'Users')}
																checked={userData.update}

															/>
															<p>Update</p>
															</div>
															<div className='flex items-center mr-20'>
															<CustomCheckbox onClick={(e) => handelDeleteCheckbox("delete", 'Users')}
																checked={userData.delete}

															/>
															<p>Delete</p>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>


												
												

											</div>
											<div className='btn_holder'>
												<Button
													onClick={handleAlertModal}
													color="inherit"
													className='back_btn secondary_btn'
												>
												Cancel
												</Button>
												<Button disabled={addUpdatedateLoading} type='submit'  className='next_btn'>  
																																			
													Proceed
												</Button>
											</div>
										</form>
									</div>
								</div>
								{/* <DialogActions className="action_footer_holder"> */}
								
							</div>
						</Box>
					</div>
				{/* </div> */}
			</Drawer>
		</div>

		{openAlerModal &&
                <AlertModal  openAlerModal module="roles" action={roleName ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
		</>
	)
}

export default AddRolesDialog