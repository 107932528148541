import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import { CardContent, Icon } from '@material-ui/core';
import useToast from '@fuse/hooks/useToast';
import { useRouter } from '@fuse/hooks';

import OtpValidatingForm from './forms/OtpValidatingForm'
import EmailVerificationForm from './forms/EmailVerificationForm'
import DetailsForm from './forms/DetailsForm'
import OptionsForm from './forms/OptionsForm'
import axios from '@fuse/utils/axios';
import { useGoogleLogin } from '@react-oauth/google';


const useStyles = makeStyles(theme => ({
	root: {},
	leftSection: {
        padding: '50px',
        width: '100%',
    },
	rightSection: {
        backgroundImage: 'url("assets/images/backgrounds/sam_registration_rightside.png")',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'initial', 
        backgroundSize: '111% 101%',
	},
    logo: {
        fontSize: 80,
        fontWeight: 600,
        // marginBottom: '20px',
        color: '#6b2c57'
    },
    subLogo: {
        fontSize: 35,
        fontWeight: 600,
        marginBottom: '30px',
        color: '#6b2c57'
    },
}));

function Component() {

	const classes = useStyles();
    const router = useRouter();
    const dispatch =  useDispatch()
    const toast = useToast(dispatch)

    // const [sendVerificationCode, setSendVerificationCode] = useState(false)
    /*
        0: optionsForm
        1: emailForm,
        2: verificationCodeForm,
        3: detailsForm,
        
    */
    const [pageComponent, setPageComponent] = useState(0)

    const [currentEmail, setCurrentEmail] = useState('')

    const [firstName, setFirstName] = useState('')

    const [lastName, setLastName] = useState('')

    const [isGoogleSignup, setIsGoogleSignup] = useState(false)

    const [googleID, setGoogleID] = useState('')

    const [isMicrosoftSignup, setIsMicrosoftSignup] = useState(false)

    const [microsoftID, setMicrosoftID] = useState('')

    const [userPhotoUrl, setUserPhotoUrl] = useState('')

    const [otp, setOtp] = useState('')

    const onChangingEmail = () => {
        setPageComponent(1)
        setOtp('')
    }

    const onChangingOption = () => {
        setPageComponent(0)
        setCurrentEmail('')
    }

    const submitGoogle = (google_id, idToken) => {
        axios.post('/auth/google-registration', {google_id, idToken}).then(res => {
            if(res.data.statusCode === 201){
                toast.error(res.data.message)
            }else{
                const userDetails = res.data.data.userDetails
                setFirstName(userDetails.first_name)
                setLastName(userDetails.last_name)
                setCurrentEmail(userDetails.email)
                setUserPhotoUrl(userDetails.photoURL)
                setIsGoogleSignup(true)
                setGoogleID(res.data.data.google_id)
                setPageComponent(3)
            }    
        }).catch(err => {
            console.log('115=>',err)
            toast.error('Something went wrong!')
        })
    }

    const onSuccess = (res) =>{
        const google_id= (new Date().getTime()).toString();
        const idToken = res.access_token

        submitGoogle(google_id, idToken);
    };

    const onFailure = (res) => {
        console.log('Login failed: res:', res);
    };

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID2;
    
	const signIn = useGoogleLogin({
		onSuccess,
		onFailure,
	});

    const submitMicrosoft = ({uniqueId, username}) => {
        axios.post('/auth/microsoft-registration', {uniqueId, username}).then(res => {
            if(res.data.statusCode === 201){
                toast.error(res.data.message)
            }else{
                setCurrentEmail(res.data.data.email)
                setIsMicrosoftSignup(true)
                setMicrosoftID(res.data.data.microsoft_id)
                setPageComponent(3)
            }
        })
    }

    const authHandler = (err, data) => {
		if(err) return

        const uniqueId = data?.uniqueId;
        const username = data?.account.userName;
        submitMicrosoft({uniqueId, username})
	};


	return (
		<div
			className={clsx(
				classes.root,
				'flex flex-col flex-auto items-center justify-center flex-shrink-0  md:p-24'
			)}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				className="flex w-full max-w-400 md:max-w-2xl rounded-20 shadow-2xl overflow-hidden"
                style={{ height:'720px' }}
			>
				<Card
					className={clsx(
						classes.leftSection,
						`flex flex-col w-full max-w-sm items-center justify-center shadow-0 ${pageComponent === 3 ? 'p-0' : ''}`
					)}
					square
				>
					<CardContent className={`flex flex-col items-center justify-center w-full max-w-800 ${ pageComponent !== 3 ? 'py-80' : 'p-30' }`}>
						<motion.div style={{width: '100%'}} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="d-flex flex-column items-center px-10 mb-20">
                                <div className={classes.logo}>dalos</div>
                               
                                { pageComponent === 0 ? 
                                    <h1 style={{ fontWeight: 600, marginBottom: '10px' }}>Sign Up to the SAM Portal</h1>
                                    :
                                    <div className={clsx(classes.subLogo, `${ pageComponent === 3 ? 'mb-0' : ''}`)}>Welcome</div>
                                }
                                <div>
                                    <Typography>

                                        { pageComponent === 0 && <>Sign Up with your Email or another service to continue.</>}
                                        
                                        { pageComponent === 1 && 
                                            <>
                                                Enter your Email Address and we will send a verification code.
                                                <a 
                                                    style={{ fontSize:'12px', marginLeft: '8px', color: 'blue', cursor: 'pointer' }}
                                                    onClick={onChangingOption}
                                                >Change Option</a>
                                            </>
                                        }

                                        { pageComponent === 2 && 
                                            <>
                                                Enter your verification code sent to your email &nbsp;
                                                <b>{currentEmail}</b> 
                                                <a 
                                                    style={{ fontSize:'12px', marginLeft: '8px', color: 'blue', cursor: 'pointer' }}
                                                    onClick={onChangingEmail}
                                                >Change</a>
                                            </> 
                                        }

                                        {
                                            pageComponent === 3 && <p style={{ fontWeight: 600 }}>Enter your Personal and Organisation Details.</p>
                                        }

                                    </Typography>
                                </div>
							</div>
						</motion.div>

                        { pageComponent === 0 && 
                            <OptionsForm 
                                dispatch={dispatch}
                                toast={toast}
                                setPageComponent={setPageComponent}
                                currentEmail={currentEmail}
                                signIn={ signIn }
                                authHandler={authHandler}
                            />
                        }
                        
                        { pageComponent === 1 && 
                            <EmailVerificationForm
                                setCurrentEmail={setCurrentEmail}
                                dispatch={dispatch}
                                toast={toast}
                                setPageComponent={setPageComponent}
                            />
                        }

                        { pageComponent === 2 && 
                            <OtpValidatingForm
                                dispatch={dispatch}
                                setPageComponent={setPageComponent}
                                toast={toast}
                                currentEmail={currentEmail}
                                setCurrentEmail={setCurrentEmail}
                                otp={otp}
                                setOtp={setOtp}
                            />
                        }

                        { pageComponent === 3 && 
                            <DetailsForm
                                dispatch={dispatch}
                                setPageComponent={setPageComponent}
                                toast={toast}
                                email={currentEmail}
                                firstName={firstName}
                                photoURL={userPhotoUrl}
                                lastName={lastName}
                                currentEmail={currentEmail}
                                isGoogleSignup={isGoogleSignup}
                                isMicrosoftSignup={isMicrosoftSignup}
                                googleID={googleID}
                                microsoftID={microsoftID}
                            />
                        }
						
                    </CardContent>

					{ pageComponent !== 3 && <div className="flex flex-col items-center justify-center pb-32">
						<span className="font-normal">Already have an account?</span>
						<Link className="font-normal" style={{ color:'blue' }} to="/login">
							Login Here
						</Link>
					</div>}

				</Card>

				<div className={clsx(classes.rightSection, 'hidden md:flex flex-1 items-center justify-center p-64')}>
					{/* <div className="max-w-320">
						<motion.div
							initial={{ opacity: 0, y: 40 }}
							animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
						>
							<Typography color="inherit" className="text-32 sm:text-44 font-semibold leading-tight">
								Dalos
							</Typography>
						</motion.div>

						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
							<Typography variant="subtitle1" color="inherit" className="mt-32 font-medium">
								Maximize your service revenue.
							</Typography>
						</motion.div>
					</div> */}
				</div>
			</motion.div>
		</div>
	);
	
}


export default Component;