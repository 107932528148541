import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { Card, Box, Stepper, Step, StepButton, Button, TextareaAutosize, DialogTitle, Divider, Icon, IconButton, TextField, Typography, InputAdornment, Drawer, CardContent, Paper, Grid, Item, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, FormLabel, RadioGroup } from '@mui/material'
import axios from '@fuse/utils/axios'
import { quickAddedContractStatus } from '../../main/contract-status/store/reportViewDialogReducer'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'
import ColorPicker from '@fuse/components/ColorPicker'
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
        helperText: {
            color: 'red',
            paddingLeft: '1px'
        },
        datePickerInput: {
            '& .MuiInputBase-input': {
                fontSize: '16px'
            }
        }
    }
))


function QuickAddContractStatus(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
	const toast = useToast(dispatch)
    const { data: editFromState } = useSelector((state) => state.contractStatusApp.formViewDialog);
    const [ formName, setFormName ] = useState('Add Contract Tag')
    const [color, setColor] = useState({name:'black',code:"#000000"});
    const [openAlerModal, setOpenAlertModal] = useState(false);
    

    const schema = yup.object({
        contractStatus: yup.string().trim().required('Tag is required!'),
        statusDescription: yup.string()
    })
 
    const defaultValues = {
        contractStatus: '',
        statusDescription: ''
    }
 
    const { 
        control, 
        formState:{errors: statusFormErrors}, 
        handleSubmit: statusFormSubmit, 
        trigger,
        getValues,
        setValue,
        reset: statusFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const handleCloseForm = () => {
        
        props.close('asset')
    }


    const contractStatusError = (error) => {
        console.log(error)
    }


    const onSubmit = async(data) => {
        // await trigger()
        // console.log(statusFormErrors)
        // if(Object.keys(statusFormErrors).length !== 0) {
        //     return;
        // }

        // let payload = {
		// 	contractStatus: formData.contract_status,
		// 	status_description: formData.status_description,
		// 	status_color: color
		// }
        const payload = {
			
			contractStatus: data.contractStatus,
            status_description: data.statusDescription,
            status_color: color
		}

        
        if(Boolean(editFromState)) payload.id = editFromState.id
        
        if(Boolean(editFromState)) {
            axios.put('/contract-status/update', payload).then(res => {
				if(res.status === 201){
					toast.error(res.data.message)
				}else{
					toast.success('Contract Tag updated successfully')
                    dispatch(quickAddedContractStatus())
                    handleCloseForm()
				}
			}).catch(err => {
				console.log('error:update api=>', err)
				toast.error('Something went wrong!')
			})
            
        } else {
            payload.entity_external_platform_id = generateRandomNumberString(),
            axios.post('/contract-status/add', payload).then(res => {
                if(res.status === 201){
                    toast.error(res.data.message)
                }else{
                    // newDataAddedEvent(res.data.data)
                    toast.success('Contract Tag added successfully')
                    dispatch(quickAddedContractStatus())
                    handleCloseForm()
                }
            }).catch(err => {
                console.log('59: ',err);
                toast.error('Unable to create Contract Tag')
            })
        }
		
    }


    useEffect( () => {
        if(!editFromState) return
        setFormName("Edit Contract Tag")
        setValue('contractStatus', editFromState.contract_tag_name);
        setValue('statusDescription', editFromState.status_description === "-" ? "" : editFromState.tag_description)
        setColor(editFromState.tag_color)
    }, [editFromState])

    const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        handleCloseForm()
    }

    return (


        <>
            <div>
    			<Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
    				<div className='heading_holder'>
    					<h4 className='heading'>{formName}</h4>
    					<IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
    							<Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
    					</IconButton>
                    </div>
    				<div className='progressbar_holder'>
    					<div className='progress'>
    							&nbsp;
    					</div>
    				</div>
					<div style={{paddingTop:'30px'}} className='cstm_wizard_holder'>
						<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder'>
								<div className='content_holder'>
									<div className="text-left">
										<div className='mb-10'>
                                            <Typography className='status_text'>
                                                Tag
                                                <span className='required_span'>*</span>
                                            </Typography>
                                            <Controller 
                                                name="contractStatus"
                                                className='serial_numberField'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        style={{ background: 'white' }}
                                                        variant='outlined'
                                                        fullWidth
                                                        required
                                                        defaultValue={defaultValues.contractStatus}
                                                        error={!!statusFormErrors.contractStatus}
                                                        helperText={statusFormErrors?.contractStatus?.message}
                                                        FormHelperTextProps={{
                                                            style: {
                                                                margin: 0,
                                                                backgroundColor: '#f4f4f4',
                                                                width: '100%',
                                                                paddingTop: '2px',
                                                                border: '1px solid #F2F4F7',
                                                                fontSize: '10px',
                                                            }
                                                        }} 
                                                    />
                                                )}
                                            />
										</div>
                                        <div className='mb-10'>
                                            <Typography className='status_text'>
                                                Description
                                                {/* <span className='required_span'>*</span> */}
                                            </Typography>
                                            <Controller 
                                                name='statusDescription'
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        variant='outlined'
                                                        // label="Description"
                                                        fullWidth
                                                        multiline
                                                        rows={8}
                                                        inputProps={{ className: classes.textarea }}
                                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                                        error={!!statusFormErrors.statusDescription}
                                                        helperText={statusFormErrors?.statusDescription?.message}
                                                        FormHelperTextProps={{
                                                            style: { 
                                                                margin : 0, 
                                                                backgroundColor: '#f4f4f4',
                                                                width: '100%',
                                                                paddingTop: '2px',
                                                                fontSize: '10px'
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='mb-10'>
                                            <Typography className='status_text'>
                                                Choose Color
                                                {/* <span className='required_span'>*</span> */}
                                            </Typography>
                                            <ColorPicker setColor={setColor} color={color}/>
                                        </div> 
									</div>
								</div>
								{/* <DialogActions className="action_footer_holder"> */}
								<div className='btn_holder'>
									<Button
										color="inherit"
										onClick={handleAlertModal}
										className='back_btn secondary_btn'
									>
									Cancel
									</Button>
									<Button onClick={statusFormSubmit(onSubmit, contractStatusError)} className='next_btn'>                                                
										Proceed
									</Button>
								</div>
							</div>
						</Box>
					</div>
    			</Drawer>
		      </div>
              {openAlerModal &&
                <AlertModal  openAlerModal module="contract tag" action={editFromState ? 'updating' : 'adding'}  handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
        </>

    )
}

export default QuickAddContractStatus