import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-columnsContainer': {
            background: '#8f8f8f26'
        }
    }
});

import { withRouter } from 'react-router-dom';

const action_to_color = {
    "CREATED": "green",
    "UPDATED": "orange",
    "ARCHIVED": "red",
    "RESTORED": "blue"
}

const columns = [
    { field: 'action_id', headerName: 'ID', width: 100 },
    { field: 'action_date', headerName: 'Date', width: 200 },
    {
        field: 'action', headerName: 'Action', width: 200,
        renderCell: (params) => {
           return (<span style={{ color: action_to_color[params.value], fontWeight: 'bold' }}>{params.value}</span>)
        }
    },
    { field: 'field_name', headerName: 'Field Name', width: 200 },
    {
        field: 'previous_value', headerName: 'Previous value', width: 200,
        renderCell: (params) => {
            if (params.value?.length > 20) {
                return (
                    <Tooltip title={params.value} placement="top-start">
                        <span style={{ cursor: 'pointer' }}>{`${params.value?.slice(0, 21)}...`}</span>
                    </Tooltip>
                )
            } else {
                return (<span>{Boolean(params.value) ? params.value : 'N/A'}</span>)
            }
        },
    },
    {
        field: 'current_value', headerName: 'Current value', width: 200,
        renderCell: (params) => {
            if (params.value?.length > 20) {
                return (
                    <Tooltip title={params.value} placement="top-start">
                        <span style={{ cursor: 'pointer' }}>{`${params.value?.slice(0, 21)}...`}</span>
                    </Tooltip>
                )
            } else {
                return (<span>{Boolean(params.value) ? params.value : 'N/A'}</span>)
            }
        },
    },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'user_role', headerName: 'Role', width: 200 },
    {
        field: 'description', headerName: 'Description', width: 400,
        renderCell: (params) => {
            if (params.value?.length > 50) {
                return (
                    <Tooltip title={params.value} placement="top-start">
                        <span className='activitiesTab-descText'>{params.value}</span>
                    </Tooltip>
                )
            } else {
                return (<span>{params.value}</span>)
            }
        }
    }    
];

const ActivitiesTab = ({ accessoryId }) => {
    const classes = useStyles();
    const [activities, setActivities] = useState([])

    function formatActivities(data) {
        return data.map((item) => {
            return {
                id:item.id,
                action_id: `act${item.id}`,
                action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
                name: item.user_name,
                action: item.action_name,
                description: item.comment,
                previous_value: item.previous_data,
                current_value: item.current_data,
                user_role: item.user_role_name,
                field_name: item.action_field_name
            }
        })
    }

    useEffect(() => {
        if (!accessoryId) return
        axios.get(`/accessory/fetch-accessory-activities/${accessoryId}`).then((res) => {
            setActivities(formatActivities(res.data.data.activities))
        }).catch((err) => {
            console.log('error', err);
        })
    }, [accessoryId])


    return (
        <div className={classes.root} style={{ height: 290, width: '100%',overflow:"auto" }}>
            <DataGrid
                rows={activities}
                columns={columns}
                hideFooter={true}
            />
        </div>
    )
}

export default ActivitiesTab
