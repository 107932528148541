import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";
import { formatPrice } from "@fuse/utils/currencyFormat";

const useStyles = makeStyles({
    row: {
        // border: '2px solid black',
        // height: '100%',
        width: "100%",
        marginBottom: "15px",
        display: "flex",
    },
    column: {
        // border: '2px solid black',
        width: "100%",
        textAlign: "left",
    },
});

function PaymentMethodTab({ paymentMethodData }) {
    const classes = useStyles();


    return (
        <>
            <div className='cstm_aggridtable_holder'>
                <table style={{ maxWidth: '650px' }}>
                    <thead>
                        <tr>
                            <th>Attributes</th>
                            <th style={{ width: '350px' }}>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Value</td>
                            <td>{paymentMethodData !== null ? paymentMethodData.value === 'purchaseOrder' ? 'Purchase Order' : 'Credit Card' : "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Card Holder Name</td>
                            <td>{paymentMethodData !== null ? paymentMethodData.card_holder_name !== null ? paymentMethodData.card_holder_name : "N/A" : "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Card Number</td>
                            <td>{paymentMethodData !== null ? paymentMethodData.card_number !== null ? paymentMethodData.card_number : "N/A" : "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Payment Order Number(PO Number)</td>
                            <td>{paymentMethodData !== null ? paymentMethodData.po_number !== null ? paymentMethodData?.po_number : "N/A" : "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Payment Order Date(PO Date)</td>
                            <td>{paymentMethodData !== null ? paymentMethodData.po_date !== null ? dateReformatTo_mmm_dd_yyyy(paymentMethodData.po_date) : "N/A" : "N/A"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default withRouter(PaymentMethodTab);
