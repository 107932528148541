import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles, Modal, TextField } from '@material-ui/core';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { generateRandomNumberString } from '@fuse/utils/stringOperations'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function QuickAddContractStatus(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const schema = yup.object({
       
    })

    const defaultValues = {
        contract_status: ''
    }

    const { 
        control, 
        formState:{errors}, 
        handleSubmit, 
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    function manageData(formData){
        // console.log('manageData: ',formData);
        const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			contractStatus: formData.contract_status
		}

		axios.post('/contract-status/add', payload).then(res => {
			if(res.status === 201){
				toast.error(res.data.message)
			}else{
				close(res.data.data)
				toast.success('Contract Status added successfully')
			}
		}).catch(err => {
            console.log('59: ',err);
			// toast.error('Something went wrong!')
		})

    }

    function formErrorData(error){
        console.log('formErrorData: ',error);
    }

    function close(new_status=null){
        reset(defaultValues)
        props.handleCloseDialog(new_status)
    }

    return (
        <Modal
            open={props.open}
            // onClose={()=>close()}
            className={classes.modal}
        >
            <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Create Contract Status</h1>
                    <IconButton onClick={()=>close()}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <form onSubmit={handleSubmit(manageData,formErrorData)}>
                    <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>
                        
                        <Controller
                            name="contract_status"
                            className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Contract Status'
                                        required
                                        style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
                        <Button
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                            type='submit'
                        >Add</Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={()=>close()}
                        >Cancel</Button>
                    </div>
                </form>
            </div>

        </Modal>
    )
}

export default QuickAddContractStatus
