import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import CsvUpload from './CsvUpload';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import ActivitieLogs from '@fuse/components/ActivitieLogs';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
// import SaveReportDialog from './saveReportDialog';
import AddAccessory from './addAccessory';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { usePermission } from '@fuse/hooks';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { Tab, Tabs } from '@material-ui/core';
// import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import {stripHtml} from '../../../../helper/common';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];

function CardedFullWidth2TabbedSample (props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [accessory, setAccessory] = useState([]);
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [logs,setLogs] = useState([]);
	const [ logsLoading,setLogsLoading ] = useState(false)
	const {broadcastChannel} = usePusher()
		const new_incident_event = "permission_change"
		const user = useCurrentUser();
	const { hasPermission } = usePermission()
	const viewAccessoryPermission = hasPermission('view-accessory')
	const createAccessoryPermission = hasPermission('add-accessory')
	const updateAccessoryPermission = hasPermission('update-accessory')
	const deleteAccessoryPermission = hasPermission('delete-accessory')
	
	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			// getLogs()
		}
		setSelectedTab(value);
	};

	function getAccessory(){
		axios.get("/accessory").then((res) => {
			const { data } = res.data;
			setAccessory(formatData(data))
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setLoading(false)
		});
	}

	function formatData(data){
		return data.map((item) => {
			return {
				id: item?.id,
				accessory_name: item?.accessory_name,
				model_number: item?.model_number,
				manufacturer: item?.manufacturer,
				manufacturer_name: item?.manufacturer?.manufacturer_name,
				accessory_category: item?.accessory_category,
				accessory_category_name: item?.accessory_category?.accessory_category_name,
				note: stripHtml(item?.note),
				source: item.source
			}
		})
		// return data;
	}

	function refreshList(){
		getAccessory();
	}

	function formatLogs(data){
        return data.map((item)=>{
            return {
                id:item.id,
                action_id: `act${item.id}`,
                action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
								accessory_name: item?.accessory?.accessory_name ||  item.accessory_name,
                name: item.user_name,
                action: item.action_name,
                description: item.comment,
                previous_value: item.previous_data,
                current_value: item.current_data,
								field_name: item.action_field_name,
								user_role: item.user_role_name
            	}
        })
    }

	function getLogs(){
		setLogsLoading(true)
		axios.get('/accessory/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(()=>{
			console.log('cannot fetch logs');
		}).finally(()=>{
			setLogsLoading(false)
		})
	}

	function callToast(success,error,value){
        if(success){
            toast.success(value)
        }
        if(error){
            toast.error(value);
        }
    }

	useEffect(()=>{
		setLoading(true)
		getAccessory()
	},[]);
	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
		return (<>
			<CsvUpload 
				refresh={refreshList}
			/>
			<SaveReportDialog tableName={"accessory"}/>
			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				// header={
				// 	<DynamicFieldsHeader />
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Accessories" />
				// 			<Tab key="end_user_tab_2" className="h-64" label="Activities" />
				// 		</Tabs>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
						{
							loading && <FuseLoading />
						}
						{
							!loading && <SalesOrderTable 
							endUsersAccounts={accessory}
							selectedView={selectedView}
							refresh={refreshList}
							viewAccessoryPermission = {viewAccessoryPermission}
							createAccessoryPermission = {createAccessoryPermission}
							updateAccessoryPermission = {updateAccessoryPermission}
							deleteAccessoryPermission = {deleteAccessoryPermission}
						/>
						}
						</div>
						<AddAccessory getAccessory = {getAccessory}/>
						
						{/* {viewAccessoryPermission && <div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
							{logsLoading ? <FuseLoading/> : <ActivityAgGridListing module='accessory' logs = {logs} /> }
						</div>} */}
					</>
				}
				innerScroll
			/>
		</>)
	}
}

export default withReducer('accessoryApp', reducer)(CardedFullWidth2TabbedSample);
