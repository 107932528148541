const initialState = {
	open: false,
}

const actionTypes = {
    OPEN_MAP_VIEWER_DIALOG: "OPEN_MAP_VIEWER_DIALOG",
    CLOSE_MAP_VIEWER_DIALOG: "CLOSE_MAP_VIEWER_DIALOG"
}

export function openMapViewerDialog(){
    return {
        type: actionTypes.OPEN_MAP_VIEWER_DIALOG,
    }
}

export function closeMapViewerDialog(){
    return {
        type: actionTypes.CLOSE_MAP_VIEWER_DIALOG,
    }
}

export default function (state = initialState, action) {
	switch (action.type) {
	  case actionTypes.OPEN_MAP_VIEWER_DIALOG:{
		  return { 
			  ...state,
			  open: true,
		  }
	  }
	  case actionTypes.CLOSE_MAP_VIEWER_DIALOG:{
			return { 
				...state,
				open: false,
			}
	  }
	  default:
		return state
	}
}