import React,{useEffect,useState} from 'react';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import axios from "@fuse/utils/axios";
import {CircularProgress} from '@material-ui/core';



const useStyles = makeStyles({
    mainContainer:{
        paddingRight:3,
        paddingBottom:3
    }
});

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom:'1px solid rgba(0, 0, 0, .125)'
  },
}))(MuiAccordionDetails);

const SubscriptionTab = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [subDetails, setSubDetails] = useState(null);
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const openState = useSelector((state) => state.usersApp.formViewDialog)
    useEffect(()=>{
      setUserDetails(openState.user_data?.client?.multiple_subscription_users)
    },[openState])
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function getDetails(item){
    try{
      setLoading(true)
      axios.get(`/subscription/subscription-details/${item.id}`)
			.then(results=>{
        setSubDetails(results.data.data.subDetails)
        setLoading(false)
				// dispatch(setUserInfo(results.data.data))
			}
		)
    }catch(e){
      console.log(e)
    }
  }

  return (
   <>
   <div>
    <div style={{display:"flex",justifyContent:'space-between',alignItems:'center',marginBottom:"20px"}}>

                <div >
                    <Typography variant="h6" style={{color:'#3A4252'}}>Subscription</Typography>
                  
                </div>
                 <div  style={{textAlign:"right"}}>
                   
                </div>
               
               

    </div>

    <div style={{height:"290px",overflow:"auto"}}>
      {userDetails?.length>0?userDetails?.map(item=>{
        return(
      <>
        <Accordion  style={{marginBottom:"20px"}} square expanded={expanded === item.subscription.id} onChange={handleChange(item.subscription.id)}>
          <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header"
          onClick={()=>{getDetails(item.subscription)}}
          >

            <Typography>{item?.subscription?.subscription_name || 'N/A'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
          

            <Grid container spacing={3} className={classes.mainContainer}>
              <Grid item lg={7}>
                <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Category</Typography>
                      <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{subDetails?subDetails?.software_category?.category_name:''}</Typography>
              </Grid>
              <Grid lg={5} style={{transform:"translateY(10px)"}}>
                      <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Subscription Status</Typography>
                      <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">
                      <span style={{
                          // border: '2px solid black',
                          height:'10px',
                          width:'10px',
                          marginRight: '10px',
                          borderRadius: '50%',
                          backgroundColor: subDetails?.software_asset_status?.status_color.code,
                          display: "inline-block",
                      }}></span>                                                                                       
                        {subDetails?subDetails?.software_asset_status?.status_name:''}</Typography>
              </Grid>
              <Grid item lg={7}>
                <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Start Date</Typography>
                      <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{dateReformatTo_mmm_dd_yyyy(item.subscription.term_start_date)}</Typography>
              </Grid>
              <Grid lg={5} style={{transform:"translateY(10px)"}}>
                      <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">End Date</Typography>
                      <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{dateReformatTo_mmm_dd_yyyy(item.subscription.term_end_date)}</Typography>
              </Grid>
            
            </Grid>
          </AccordionDetails>
        </Accordion>

      </>)

      }):'No Subscription Found'}
     
    
    </div>
    </div>
   </>
  )
}

export default SubscriptionTab
