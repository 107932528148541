export const assetTemplate = [
  [
    "Serial Number",
    "Asset Name",
    "Asset Tag",
    "Status Name",
    "Model Number",
    // "Model Name",
    "Location",
    "Notes",
    "Image Name",
    "First Name",
    "Last Name",
    "Title",
    "Email Address",
    "Contract Number",
    "Purchase Price",
    "Purchase Date",
    "Install Date",
    "Warranty End Date",
    "Order Number"
  ]
]
export const categoryTemplate = [
  [
    "Category_Name",
  ]
]

export const manufacturerTemplate =  [
  [
    "Manufacturer Name",
    "URL",
    "Phone Number",
    "Address 1",
    "Address 2",
    "City",
    "State",
    "Zip",
    "Country",
  ]
]

export const modelTemplate = [
  [
    "Model Number",
    "Model Name",
    "Category Name",
    "Manufacturer Name",
    "EOL",
    "EOSL",
    "Description",
    "Image Name"
  ]
]

export const accessoryTemplate = [
  [
    "Accessory Name",
    "Model Number",
    "Manufacturer Name",
    "Accessory Category Name",
    "Note"
  ]
]

export const assetStatusTemplate =  [
  [
    "Status Name",
    "Status Description",
  ]
]

export const accessoryCategory =  [
  [
    "Accessory Category Name"
  ]
]

export const contractTemplate = [
  [
    "Contract Number",
    "Contract Name",
    "Contract Description",
    "Start Date",
    "End Date",
    'Total Contract Value',
    "Contract Status",
    "Location",
    "Contract Type",
    "New/Renew",
    "Master",
    "Parent Contract",
    "Service Level Agreement",
    "Amount",
    "Currency",
    "Payment Term",
    "PO Number",
    "PO Date",
    "Service Provider",
    "Reminder",
    "Text Contact Number"
  ],
]

export const serviceProviderTemplate = [
  [
    "Service Provider Name",
    "URL",
    "Phone Number",
    "Address 1",
    "City",
    "State",
    "Zip",
    "Country",
  ]
]

export const shippingTemplate =  [
  [
    "Location_Name",
    "Address1",
    "Address2",
    "City",
    "State",
    "Zip",
    "Country",
    'Location_Type',
    "Note"
  ]
]

export const userTemplate = [
  [
    "First Name",
    "Last Name",
    "Phone",
    "Email",
    "Title",
    "Address",
    "City",
    "State",
    "Zip",
    "Country",
    "Employee Number",
    "Department Name",
    "Address_2",
    "Start Date",
    "End Date",
  ]
]