import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@fuse/components/general/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, AccordionActions } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import useRouter from '@fuse/hooks/useRouter';
import ListView from '../components/listView';
import lodash from "lodash"
import { dateReformatTo_mmm_dd_yyyy, getFormattedDate } from '@fuse/utils/dateOperations'
// import { toNumberFormat } from '@fuse/utils/parsePrice';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function quotesTable(props) {
	const table_name = "SHIPPING_DETAILS_QUOTE_LISTING";
	const classes = useStyles();

	const dispatch = useDispatch();
	const router = useRouter();

	const [data, setData] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [loading, setLoading] = useState(false);

	const { shippingId } = props;

	const [quotes, setQuotes] = useState([]);

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event);
		setPage(0)
	}

	function getData() {
		setLoading(true);

		// if(!props.viewPermission){
		// 	setLoading(false);
		// 	return ;
		// }

		axios.get("/quotes",{
			params: {
				shipping_id: shippingId,
				redirectAction: '_',
				page,
				limit: rowsPerPage
			}
		}).then((res) => {
			const { data } = res.data;
			setQuotes(formatQuotes(data.quotes))
		}).catch((err) => {
			console.log(err)
		}).finally(() =>{
			setLoading(false)
		})
	}

    function monthDiff(d1, d2) {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

	function formatQuotes(data){
		return data.map((quoteItem) => {

			return {
				...quoteItem,
                quote_id: quoteItem?.quote_id,
                company_name: quoteItem?.user_company?.company_name,
                status: quoteItem?.status,
				shipping_location: quoteItem.quote_shipping_address 
									? quoteItem.quote_shipping_address 
									: quoteItem.shipping_location 
										? `${quoteItem.shipping_location.address1}, ${quoteItem.shipping_location.city}, ${quoteItem.shipping_location?.zip}` 
										: "",
				generation_date: dateReformatTo_mmm_dd_yyyy(quoteItem.generation_date.split('T')[0]),
				expiry_date: dateReformatTo_mmm_dd_yyyy(quoteItem.expiry_date.split('T')[0]),
				contract_number: quoteItem?.contract_header?.entity_external_platform_id,
                quote_length: (monthDiff(new Date(quoteItem?.generation_date), new Date(quoteItem?.expiry_date)) || 0).toString() + " months",
                total_sell_price: (quoteItem?.total_sell_price || 0.00),
				created_date: getFormattedDate(quoteItem?.created_at) || "",
			}
		})
	}

	const columnHeaders = [
		{
			field: 'quote_id',
			label: 'Quote number',
			cellClicked: function (params, value) {
				console.log(params.node.data);
				router.push(`/quotes/detail/${params.node.data.id}`)
			}
		},
		{
			field: 'company_name',
			label: 'Company name',
		},
		{
			field: 'asset_count',
			label: 'Number of assets',
		},
		{
			field: 'status',
			label: 'Status',
		},
		{
			field: 'shipping_location',
			label: 'Location name',
		},
		{
			field: 'contract_number',
			label: 'Contract number',
			cellClicked: function (params, value) {
				router.push(`/contracts/details/${params.node.data?.contract_header?.id}`)
			}
		},
		{
			field: 'generation_date',
			label: 'Start date',
		},
		{
			field: 'expiry_date',
			label: 'End date',
		},
		{
			field: 'quote_length',
			label: 'Quote length',
		},
		{
			field: 'total_sell_price',
			label: 'Amount',
		},
		{
			field: 'created_date',
			label: 'Created date',
		},
	];

	useEffect(() => {
	    getData()
	}, [])

	if (loading) {
		return (<FuseLoading />)
	}
	// else if(!props.viewPermission){
	// 	return (
	// 		<div>
	// 			You don't have the view permission for contracts
	// 		</div>
	// 	)
	// }
	else if (quotes.length <= 0) {
		return (
			<div
				style={{ width: "100%" }}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="w-full flex flex-1 items-center justify-center h-full mt-16">
				<div style={{ height: "200px" }}>
					<Typography variant="body2" component="p" color="textSecondary">
						No data found
					</Typography>
				</div>
			</div>
		)
	}else{
		return (
			<div className="w-full flex flex-col">
				<ListView rows={quotes} columns={columnHeaders} flexGrow={false} table_name = { table_name } />
				<div className="p-10">
					<TablePagination
						className="flex-shrink-0 border-t-1 mt-10"
						component="div"
						count={quotes.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</div>
			</div>
		);
	}
	}

	

export default withRouter(quotesTable)
