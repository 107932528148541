

import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Stack, Box, Stepper, Step, StepButton, Button, TextareaAutosize, DialogTitle, Divider, Icon, IconButton, TextField, Typography, InputAdornment, Drawer, CardContent, Paper, Grid, Item, CircularProgress } from '@mui/material'
import axios from '@fuse/utils/axios'
import Card from '@material-ui/core/Card';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Editor from '@fuse/components/Editor';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import { formatPriceField, generateProperLocationString } from '@fuse/utils/general'
import { usePermission } from '@fuse/hooks';
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import { countries } from '@fuse/utils/countryName'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';



const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
        helperText: {
            color: 'red',
            paddingLeft: '1px'
        },
        datePickerInput: {
            '& .MuiInputBase-input': {
                fontSize: '16px'
            }
        }
    }
))
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function QuickAddSubscription(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
	const toast = useToast(dispatch)



    const [subscriptionCategory,setSubscriptionCategory] = useState([])
    const [provider,setProvider] = useState([])
    const [noReminderCheckBox, setNoReminderCheckBox] = useState(false)
    const [noOfDays, setNoOfDays] = useState('')
    const [department,setDepartment] = useState([])
    const [dropDownHeight,setDropDownHeight] = useState(100)
	const [translateDropdown,setTranslateDropdown] = useState(23)
    const [substatus,setSubStatus] = useState([])
    const [paymentTerm, setPaymentTerm] = useState([]);
    const [paymentTermMenu, setPaymentTermMenu] = useState([])

    const [startDate,setStartDate] = useState({
		date: null,
		error: false
	})
	const [endDate,setEndDate] = useState({
		date: null,
		error: false
	})
    const [billingDate,setBillingDate] = useState({
		date: null,
		error: false
	})
    const [currentCountryCurrency, setCurrentCountryCurrency] = useState({
		"name": "United States",
		"code": "US",
		"currency": "USD"
	})
    const [paymentDate,setPaymentDate] = useState({
		date: null,
		error: false
	})
    const [purchaseOrder,setPurchaseOrder] = useState('purchaseOrder')
    const [openPhoneNoModal, setOpenPhoneNoModal] = useState(false)
	const [phoneNumberPresent, setPhoneNumberPresent] = useState(false)


    const { hasPermission } = usePermission()


	const createCStatusPermission = hasPermission('add-contract_status')
	const createCLocationPermission = hasPermission('add-location')

    const handleCurrentCountry = (countryCode) => {
		if(countryCode !== null) {
			const current = (countries.filter(country => country.code === countryCode))[0]
			setCurrentCountryCurrency(current)
			setValue('billingCycleCurrency', current)
		} else {
			setCurrentCountryCurrency({ "name": "United States", "code": "US", "currency": "USD" })
			setValue('billingCycleCurrency', { "name": "United States", "code": "US", "currency": "USD" })
		}
		
	}


    const [reminderCheckboxes, setReminderCheckBoxes] = useState({
		term45: false,
		term30: false,
		supportContact: false,
		email: false
	})

	




    const handleChangeExport = (event) => {
        setAge(event.target.value);
    };
    // const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log('new value', newValue)
        // setValue(newValue);
    };



    const steps = ['Required Details', 'Subscription Terms', 'Billing Cycle', 'Payment Method', 'Reminder'];

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleChangeNoReminderCheckbox = (event) => {
		setNoReminderCheckBox(event.target.checked)
	}

    

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        // handleNext();
    };


    const handleChangeReminderCheckBoxes = (event) => {

		

		if ((event.target.name === 'term45' && event.target.checked === false && reminderCheckboxes.term30 === false) || (event.target.name === 'term30' && event.target.checked === false && reminderCheckboxes.term45 === false)) {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				[event.target.name]: event.target.checked,
				supportContact: false
			})
			setValue('support_phone_number', '')
			setNoReminderCheckBox(true)
		}
		else {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				[event.target.name]: event.target.checked
			})
		}

	}


    const handleChangeTextMeOption = (event) => {
		event.target.checked && axios.get(`user/get-phone-number`).then(response => {
			if(!response.data.data.phoneNumber.phone){
				setOpenPhoneNoModal(true)
				setPhoneNumberPresent(false)
			}else{
				setPhoneNumberPresent(true)
			}
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
		if (reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true) {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: event.target.checked
			})
		}

		if (event.target.checked === false) {
			setValue('support_phone_number', '')
		}
	}

    const handleChangeEmailOption = (event) => {
		if(reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true){
			setReminderCheckBoxes({
				...reminderCheckboxes,
				email: event.target.checked
			})
		}
	}

    function fetchSubscriptionCategory() {
		axios.get(`category?category_type=software`).then(response => {
			setSubscriptionCategory(response.data.data.map((item) => {
				return { id: item.id, name: item.category_name }
			}))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

    function fetchDepartment() {
		axios.get(`/department`).then(response => {
			setDepartment(response.data.data.map((item) => {
				return { id: item.id, name: item.department_name }
			}))
			if(response.data.data.length <2){
				setDropDownHeight(100)
				setTranslateDropdown(55)
			}
			else if(response.data.data.length <3){
				setDropDownHeight(130)
				setTranslateDropdown(45)
			}
			else if(response.data.data.length <4){
				setDropDownHeight(170)
				setTranslateDropdown(35)
			}
			else if(response.data.data.length <5){
				setDropDownHeight(200)
				setTranslateDropdown(22)
			}
			else{
				setDropDownHeight(200)
				setTranslateDropdown(22)
			}
			// setContractsMenu((response.data.data.map((item)=>item.display_name)))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

    async function fetchSubstatus(){
		axios.get(`/asset-status?type=software`).then(response => {
			setSubStatus(response.data.data.map((item) => {
				return { id: item.id, name: item.status_name }
			}))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

    async function fetchPaymetTerm(){
		axios.get(`subscription/get-payment-term`).then(response => {
			setPaymentTerm(response.data.data.map((item) => {setProvider
				return { id: item.id, name: item.display_name }
			}))
            setPaymentTermMenu(
                response.data.data.map((item) => {setProvider
                    return { id: item.id, name: item.display_name }
                })
            )
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

    function fetchCloudProvider() {
		axios.get(`/cloud-provider`).then(response => {
			setProvider(response.data.data.map((item) => {
				return { id: item.id, name: item.provider_name }
			}))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}



    useEffect(() => {
		fetchSubscriptionCategory()
		fetchCloudProvider()
		fetchDepartment()
		fetchSubstatus()
		fetchPaymetTerm()
	}, [])


    


    

    


    const defaultValues = {
        subscriptionName: "",
        providerName: "",
        category: "",
        website: "",
        noOfSeats: '',
        startDate: null,
        endDate: null,



        contractStatus: {},
        currentShippingLocation: {},
        contractType: "",
        isContractNew: "",
        isMaster: "",
        parentContract: null,
        sla: null,
        provider: null,
        billingCycleCurrency: { "name": "United States", "code": "US", "currency": "USD" },
		billingCycleAmount: '',
        every: 1,
        paymentTerm: null,
        paymentMethod: "CreditCard",
        cardHolderName: "",
        cardNumber: "",
        poNumber: ""

    };

    const capturePaymentMethodChangeEvent = (paymentMethodValue) => {
        if(paymentMethodValue === "CreditCard") {
            setValue('poNumber', '');
            setPaymentDate({
                date: null,
                error: false
            })
        } else {
            setValue('cardHolderName', '');
            setValue('cardNumber', '');
        }
    }

    

    const validationSchema = [
        // 0 Required Details
        yup.object({
            subscriptionName: yup.string().trim().required('Subscription Name is required!'),
            website: yup.string().trim().required('Website is required!'),
            providerName: yup.object().shape({
                id:  yup.string().required('Provider Name is required.'),
                name: yup.string().required('Provider Name is required.')
		    }),
            category: yup.object().shape({
                id:  yup.string().required('Category is required.'),
                name: yup.string().required('Category is required.')
		    }),
            noOfSeats: yup.string().trim().required('No Of Seats is required!'),
            department: yup.array(
                yup.object().shape({
                    id: yup.string().required('Department is required!'),
                    name: yup.string().required('Department is required!')
                })
            ).required('Department is required!'),
            status: yup.object().shape({
                id:  yup.string().required('Status is required.'),
                name: yup.string().required('Status is required.')
		    }).required('Status is required!')
        }),
        // 1 Subscription Terms
        yup.object({
            
        }),
        // 2 Billing Cycle
        yup.object({
            billingCycleAmount: yup.string().typeError('Invalid value').required('The Billing Cycle Amount is required!').test('billingCycleAmount', 'Invalid Value', (value) => {
                // if(/^\d+(\.\d{1,2})?$/.test(amountValue.toString()) || /^[0-9]{1,3}(?:\,[0-9]{3})*(?:\.[0-9]{1,2})?$/.test(amountValue.toString())) {
                    return /^\d+([\,]\d+)*([\.]\d+)?$/.test(value.toString())
                // }
            }),
            billingCycleCurrency: yup.object().shape({
                name:  yup.string().required('Currency is required.'),
                code: yup.string().required('Currency is required.'),
                currency: yup.string().required('Currency is required.')
		    }),
            every: yup.number().required('Every Field Value is required.'),
            paymentTerm: yup.object().shape({
                id: yup.string().required('Payment Term is required.'),
                name: yup.string().required('Payment Term is required.')
            }).required('Payment Term is required.'),
        }),
        // 3 Payment Method
        yup.object({
            paymentMethod: yup.string().required('payment Method is required!'),
            cardHolderName:  yup.string().when('paymentMethod', {
                is: 'CreditCard',
                then: yup.string().required('Card Holder Name is required'),
                otherwise: yup.string().nullable(),
            }),
		    cardNumber: yup.string().when('paymentMethod', {
                is: 'CreditCard',
                then: yup.string().required('Card number is required').test('cardNumber', 'Invalid Card Number Value', (cardNumberValue) => {
                    if(cardNumberValue.length === 4 ) {
                        return /^[0-9]*$/.test(cardNumberValue)
                    }
                    return false
                }),
                otherwise: yup.string().nullable(),
            }),
            poNumber: yup.string().when('paymentMethod', {
                is: 'purchaseOrder',
                then: yup.string().required('PO number is required'),
                otherwise: yup.string().nullable(),
            })
        })

    ];

    const currentValidationSchema = validationSchema[activeStep];


    const methods  = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(currentValidationSchema),
        mode: "onChange"
    });

    const { control, formState, handleSubmit, reset, setValue, clearErrors, getValues, trigger, register } = methods

    const { errors } = formState
    
    const handleNext = async () => {
        
        await trigger();
        switch(activeStep) {
            // Required Details
            case 0:
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            // Subscription Terms
            case 1:
                if(startDate.date === null && endDate.date === null) {
                    toast.error('Subscription start date and Subscription end date are required.')
                }
                if(startDate.date === null) {
                    toast.error('Subscription start date is required.')
                    return;
                }
                if(endDate.date === null) {
                    toast.error('Subscription end date is required.')
                    return;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            // Billing Cycle
            case 2:
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                if(billingDate.date === null) {
                    toast.error('Billing date is required.')
                    return;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            // Payment Method
            case 3:
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                if(methods.watch("paymentMethod") === "purchaseOrder") {
                    if(paymentDate.date === null) {
                        toast.error('Payment date is required.')
                        return;
                    }
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            default:
                break; 
        } 
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        reset();
    };


    const handleCloseForm = () => {
        setActiveStep(0);
        reset(defaultValues);
        clearErrors()
        props.close('subscription')
    }


    const captureIsMasterOnchangeEvent = (event) => {
        setMasterValue(event.target.value)
    }


    const handleRadioChange = (event) => {
        // console.log(event.target.value)
		setPurchaseOrder(event.target.value);
	};

    


    const onSubmit = async (data) => {

        if(!noReminderCheckBox  && !reminderCheckboxes.term45) {
            toast.error('Please select any of the checkbox')
            return 
        }
        if(!noReminderCheckBox && reminderCheckboxes.term45) {
            if(noOfDays === '') {
                toast.error('Please select the number of days!')
                return 
            }
        }
        console.log(JSON.stringify(data));
        // console.log(data.contractNumber)
        // console.log(JSON.stringify(data).contractNumber)
        
    };


    const onCancel = () => {
		closeDialog()
	}

	const closeDialog = () => {
        clearErrors()
		reset(defaultValues)
	}

    

    const handleSubmitEvent = async() => {
        if(!noReminderCheckBox  && !reminderCheckboxes.term45) {
            toast.error('Please select any of the checkbox')
            return 
        }
        if(!noReminderCheckBox && reminderCheckboxes.term45) {
            if(noOfDays === '') {
                toast.error('Please select the number of days!')
                return 
            }
        }
        const inputValues = getValues();
        const departmentIdsStrArray = [];
        const departmentIds = inputValues.department.map( (singleDepartment, index) => {
    
            departmentIdsStrArray.push(singleDepartment.name) 
            return singleDepartment.id
        })
        let payload = {
			subscription_name: inputValues.subscriptionName,
			entity_external_platform_id: String(Math.trunc(Math.random() * 200)),
			provider_id: inputValues.providerName.id,
			website: "www." + inputValues.website || '',
			subscription_category_id: inputValues.category.id,
			status_id: inputValues.status.id,
			department_id: departmentIdsStrArray.toString(),
			department_id_: departmentIds,
			no_of_seats:Number(inputValues.noOfSeats),
			term_start_date: startDate.date || '',
			term_end_date: endDate.date || '',
			billing: {
				amount: String(inputValues.billingCycleAmount).replaceAll(',',''),
				location: inputValues.billingCycleCurrency.name,
				cycle: String(inputValues.every) || '',
				month: inputValues.paymentTerm.name || '',
				date: billingDate.date || '',
				currency: inputValues.billingCycleCurrency.currency,
				payment_term_id: inputValues.paymentTerm.id
			},
			payment: {
				value: inputValues.paymentMethod,
				po_number: inputValues.poNumber,
				po_date: paymentDate.date || '',
				card_number:inputValues.cardNumber || '',
				card_holder_name: inputValues.cardHolderName
			},
			support_phone_number: reminderCheckboxes.supportContact ? formattedNumber : null,
			no_reminder: noReminderCheckBox,
			reminder: {term45: reminderCheckboxes.term45, term30: reminderCheckboxes.term30},
			textMessage: reminderCheckboxes.supportContact,
			manually_added: true
		}
        axios.post('/subscription/add-subscription-manually', payload).then(response => {
            if (response.status == 201) {
                toast.error(response.data.message)
            } else {
                reset(defaultValues)
                closeDialog()
                toast.success('Subscription Added Successfully.')
                props.close('subscription')
            }
        }).catch(err => {
            console.log('212=>', err);
            toast.error('Something went wrong!')
        })
    }

    return (
        <div>
            <Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
                <div className='heading_holder'>
                    <h4 className='heading'>Add Subscription</h4>
                    <IconButton onClick={handleCloseForm} style={{padding: '0px'}}>
                        <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                    </IconButton>
                </div>

                <div className='progressbar_holder'>
                    <div style={{width: activeStep === 0 ? '33.33%' : activeStep === 1 ? '66.67%' : activeStep === 2 ? '100%' : '0%'}} className='progress'>
                        &nbsp;
                    </div>
                </div>

                <div className='cstm_wizard_holder'>
                    <Box sx={{ width: '100%' }} className='box_holder'>
                        <Stepper activeStep={activeStep} className='stepper_holder'>
                            {steps.map((label, index) => (
                            <Step className='each_step_holder' key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                                </StepButton>
                            </Step>
                            ))}
                        </Stepper>
                        <div className='main_content_holder'>
                            <>
                                <h4 className='main_heading'>
                                    {activeStep === 0 && <span>Required Details</span>}
                                    {activeStep === 1 && <span>Subscription Terms</span>}
                                    {activeStep === 2 && <span>Billing Cycle</span>}
                                    {activeStep === 3 && <span>Payment Method</span>}
                                    {activeStep === 4 && <span>Reminder</span>}
                                </h4>
                                <FormProvider {...methods}>
                                    <form>
                                        <div className='content_holder'>
                                            {
                                                activeStep === 0 &&
                                                    
                                                <>
                                                    <Box className='light_blue_holder'>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6}>
                                                            
                                                                <Typography className='status_text'>
                                                                    Subscription Name
                                                                    <span className='required_span'>*</span>
                                                                </Typography>
                                                                <Controller 
                                                                    name="subscriptionName"
                                                                    className='serial_numberField'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            style={{ background: 'white' }}
                                                                            variant='outlined'
                                                                            autoFocus
                                                                            fullWidth
                                                                            required
                                                                            defaultValue={defaultValues.subscriptionName}
                                                                            error={!!errors.subscriptionName}
                                                                            helperText={errors?.subscriptionName?.message}
                                                                            FormHelperTextProps={{
                                                                                style: {
                                                                                    margin: 0,
                                                                                    backgroundColor: '#f4f4f4',
                                                                                    width: '100%',
                                                                                    paddingTop: '2px',
                                                                                    border: '1px solid #F2F4F7'
                                                                                }
                                                                            }} 
                                                                        />
                                                                    )}
                                                                />    
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography className='status_text'>
                                                                    Provider Name
                                                                    <span className='required_span'>*</span>
                                                                </Typography>
                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller
                                                                        name="providerName"
                                                                        className="mb-16"
                                                                        control={control}
                                                                    
                                                                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                            <>
                                                                                <Autocomplete

                                                                                    {...field}
                                                                                    style={{ width: '100%' }}
                                                                                    options={provider}
                                                                                    disablePortal
                                                                                    // disabled = {disableField}
                                                                                    onChange={(event, value) => { field.onChange(value) }}
                                                                                    getOptionLabel={option => option.name ?? ''}
                                                                                    renderInput={params => {
                                                                                        return (
                                                                                            <div ref={params.InputProps.ref}>
                                                                                                <TextField
                                                                                                    // inputRef={provider_ref}
                                                                                                    {...params}
                                                                                                    // label='Provider Name'
                                                                                                    variant='outlined'
                                                                                                    fullWidth
                                                                                                    required
                                                                                                    FormHelperTextProps={{
                                                                                                        style: { marginLeft: 0 }
                                                                                                    }}
                                                                                                    defaultValue={defaultValues.providerName}
                                                                                                    error={!!errors.providerName}
                                                                                                    helperText={errors?.providerName?.message && 'Provider Name is required!'}
                                                                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                                
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography className='status_text'>
                                                                    Website
                                                                    <span className='required_span'>*</span>
                                                                </Typography>
                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller
                                                                        name="website"
                                                                        className="mb-16"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <TextField
                                                                                    // inputRef={website_ref}
                                                                                    {...field}
                                                                                    variant='outlined'
                                                                                    // label='Website'
                                                                                    fullWidth
                                                                                    required
                                                                                    defaultValue={defaultValues.website}
                                                                                    error={!!errors.website}
                                                                                    helperText={errors?.website?.message}
                                                                                    InputProps={{
                                                                                        startAdornment:
                                                                                            <InputAdornment style={{color: "#1D2939",fontSize: "14px",marginRight:"0"}} disableTypography position="start">
                                                                                                www.</InputAdornment>,
                                                                                    }}
                                                                                    FormHelperTextProps={{
                                                                                        style: { marginLeft: 0 }
                                                                                    }}
                                                                                    style={{ backgroundColor: '#fff' }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography className='status_text'>
                                                                    Subscription Category
                                                                    <span className='required_span'>*</span>
                                                                </Typography>
                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller
                                                                        name="category"
                                                                        className="mb-16"
                                                                        control={control}
                                                                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                            <>
                                                                                <Autocomplete
                                                                                    {...field}
                                                                                    style={{ width: '100%' }}
                                                                                    options={subscriptionCategory}
                                                                                    disablePortal
                                                                                    // disabled = {disableField}
                                                                                    onChange={(event, value) => { field.onChange(value) }}
                                                                                    // PaperComponent={({ children }) => (
                                                                                    //     <Paper style={{ marginTop: '0px', position: 'relative', zIndex: "1223646466" }}>{children}
                                                                                    //         {<Button
                                                                                    //             style={{ width: '100%' }}
                                                                                    //             variant="outlined"
                                                                                    //             color="primary"
                                                                                    //             onMouseDown={() => handleCategoryDialogOpen()}
                                                                                    //         >Add Subscription Category</Button>}
                                                                                    //     </Paper>
                                                                                    // )}
                                                                                    getOptionLabel={option => option.name ?? ''}
                                                                                    renderInput={params => {
                                                                                        return (
                                                                                            <div ref={params.InputProps.ref}>
                                                                                                <TextField
                                                                                                    {...params}
                                                                                                    // label='Select Category'
                                                                                                    variant='outlined'
                                                                                                    fullWidth
                                                                                                    required																	
                                                                                                    defaultValue={defaultValues.category}
                                                                                                    error={!!errors.category}
                                                                                                    helperText={errors?.category?.message && 'Category is required!'}
                                                                                                    FormHelperTextProps={{
                                                                                                        style: { marginLeft: 0 }
                                                                                                    }}
                                                                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                                
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography className='status_text'>
                                                                    No of Seats
                                                                    <span className='required_span'>*</span>
                                                                </Typography>
                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller
                                                                        name="noOfSeats"
                                                                        className="mb-16"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <TextField
                                                                                    // inputRef={website_ref}
                                                                                    {...field}
                                                                                    variant='outlined'
                                                                                    // label='No of Seats'
                                                                                    fullWidth
                                                                                    required
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9/-]+/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    defaultValue={defaultValues.noOfSeats}
                                                                                    error={!!errors.noOfSeats}
                                                                                    helperText={errors?.noOfSeats?.message}
                                                                                    FormHelperTextProps={{
                                                                                        style: { marginLeft: 0 }
                                                                                    }}
                                                                                    style={{ backgroundColor: '#fff' }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography className='status_text'>
                                                                    Purchasing Department
                                                                    <span className='required_span'>*</span>
                                                                </Typography>
                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller
                                                                        name="department"
                                                                        className={classes.dropdownCss}
                                                                        control={control}
                                                                        // style={{transform:"translateY(-50px)"}}
                                                                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                            <>
                                                                                <Autocomplete
                                                                                    {...field}
                                                                                    style={{ width: '100%' }}
                                                                                    options={department}
                                                                                    disablePortal
                                                                                    onChange={(event, value) => { field.onChange(value) }}
                                                                                    multiple   
                                                                                    getOptionLabel={option => option.name ?? ''}
                                                                                        renderInput={params => {
                                                                                            return (
                                                                                                <div ref={params.InputProps.ref}>
                                                                                                    <TextField
                                                                                                        required
                                                                                                        {...params}
                                                                                                        name="department"
                                                                                                        // label='Contract Status'
                                                                                                        variant='outlined'
                                                                                                        fullWidth
                                                                                                        error={!!errors?.department}
                                                                                                        helperText={errors?.department?.message && 'Department is required!'}
                                                                                                        defaultValue={defaultValues.department}
                                                                                                        inputRef={ref}
                                                                                                        FormHelperTextProps={{
                                                                                                            style: {
                                                                                                                margin: 0,
                                                                                                                backgroundColor: '#f4f4f4',
                                                                                                                width: '100%',
                                                                                                                paddingTop: '2px'
                                                                                                            }
                                                                                                        }}
                                                                                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography className='status_text'>
                                                                    Status
                                                                    <span className='required_span'>*</span>
                                                                </Typography>
                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller
                                                                        name="status"
                                                                        className="mb-16"
                                                                        control={control}
                                                                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                            <>
                                                                                <Autocomplete
                                                                                    {...field}
                                                                                    style={{ width: '100%' }}
                                                                                    options={substatus}
                                                                                    disablePortal
                                                                                    onChange={(event, value) => { field.onChange(value) }}
                                                                                    getOptionLabel={option => option.name ?? ''}
                                                                                    renderInput={params => {
                                                                                        return (
                                                                                            <div ref={params.InputProps.ref}>
                                                                                                <TextField
                                                                                                    {...params}
                                                                                                    // label='Select Status'
                                                                                                    variant='outlined'
                                                                                                    fullWidth
                                                                                                    required
                                                                                                    FormHelperTextProps={{
                                                                                                        style: { marginLeft: 0 }
                                                                                                    }}
                                                                                                    error={!!errors?.status}
                                                                                                    helperText={errors?.status?.message && 'Status is required!'}
                                                                                                    defaultValue={defaultValues.status}
                                                                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </>
                                            }
                                            {
                                                activeStep === 1 && 

                                                <>
                                                    <Box className='light_blue_holder'>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6}>
                                                                
                                                                <Typography className='status_text'>
                                                                    Start Date
                                                                    <span className='required_span'>*</span>
                                                                </Typography>
                                                                <CustomDatePickerV2
                                                                    state={startDate}
                                                                    setState={setStartDate}
                                                                    required={true}
                                                                    label='Start Date*'
                                                                    maxDate={endDate.date}
                                                                    condition='End Date'									
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                
                                                                <Typography className='status_text'>
                                                                    End Date
                                                                    <span className='required_span'>*</span>
                                                                </Typography>
                                                                <CustomDatePickerV2
                                                                    state={endDate}
                                                                    setState={setEndDate}
                                                                    required={true}	
                                                                    label='End Date*'
                                                                    minDate={startDate.date}
                                                                    condition='Start Date'									
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </>
                                            }
                                            {
                                                activeStep === 2 && 
                                                <>
                                                    <Box className='light_blue_holder'>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Amount
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller
                                                                            name="billingCycleAmount"
                                                                            className="mb-16"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <>
                                                                                    <TextField
                                                                                        // inputRef={paymentAmountInputRef}
                                                                                        required
                                                                                        {...field}
                                                                                        variant='outlined'
                                                                                        // label='Amount'
                                                                                        fullWidth
                                                                                        defaultValue={defaultValues.billingCycleAmount}
                                                                                        error={!!errors.billingCycleAmount}
                                                                                        helperText={errors?.billingCycleAmount?.message}
                                                                                        FormHelperTextProps={{
                                                                                            style: {
                                                                                                margin: 0,
                                                                                                backgroundColor: '#f4f4f4',
                                                                                                width: '100%',
                                                                                                paddingTop: '2px'
                                                                                            }
                                                                                        }}
                                                                                        onBlur={()=>{
                                                                                            setValue('billingCycleAmount', (!isNaN(getValues('billingCycleAmount')) ? (clearErrors('billingCycleAmount', ''), formatPriceFieldOnBlur(getValues('billingCycleAmount'))) : getValues('billingCycleAmount'))  )																		
                                                                                        }}
                                                                                        // onKeyPress={(event) => {
                                                                                        //     if (!/[0-9,.]+/.test(event.key)) {
                                                                                        //         event.preventDefault();
                                                                                        //     }
                                                                                        // }}
                                                                                        onChange={(event) => { field.onChange(event.target.value ?? '') }}
                                                                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                        InputProps={{
                                                                                            startAdornment:
                                                                                                <InputAdornment>
                                                                                                    <Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
                                                                                                </InputAdornment>
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Currency
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller
                                                                            name="country"
                                                                            className="mb-16"
                                                                            control={control}
                                                                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                <>
                                                                                    <Autocomplete
                                                                                        {...field}
                                                                                        style={{ width: '100%' }}
                                                                                        options={countries}
                                                                                        disablePortal
                                                                                        getOptionLabel={option => option.currency ?? ''}
                                                                                        // renderOption={option =>
                                                                                        //     <>
                                                                                        //         <InputAdornment style={{ marginRight: '15px' }}>
                                                                                        //             <img
                                                                                        //                 loading="lazy"
                                                                                        //                 width="20"
                                                                                        //                 src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                                        //                 srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                                        //                 alt=""
                                                                                        //             />
                                                                                        //         </InputAdornment>
                                                                                        //         {option.currency}
                                                                                        //     </>}
                                                                                        onChange={(event, value) => {
                                                                                            field.onChange(value)
                                                                                            if (value) handleCurrentCountry(value.code)
                                                                                        }}
                                                                                        PaperComponent={({ children }) => (
                                                                                            <Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
                                                                                        )}
                                                                                        renderInput={params => {
                                                                                            return (
                                                                                                <div ref={params.InputProps.ref}>
                                                                                                    <TextField
                                                                                                        {...params}
                                                                                                        label='Currency'
                                                                                                        variant='outlined'
                                                                                                        fullWidth
                                                                                                        // required
                                                                                                        inputRef={ref}
                                                                                                        FormHelperTextProps={{
                                                                                                            style: { marginLeft: 0 }
                                                                                                        }}
                                                                                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                        InputProps={{
                                                                                                            startAdornment:
                                                                                                                <InputAdornment style={{ marginRight: '15px' }}>
                                                                                                                    <img
                                                                                                                        loading="lazy"
                                                                                                                        width="20"
                                                                                                                        src={`https://flagcdn.com/w20/${currentCountryCurrency?.code.toLowerCase()}.png`}
                                                                                                                        srcSet={`https://flagcdn.com/w40/${currentCountryCurrency?.code.toLowerCase()}.png 2x`}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </InputAdornment>
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Every 
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller
                                                                            name="every"
                                                                            className="mb-16"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <>
                                                                                    <TextField
                                                                                        {...field}
                                                                                        variant='outlined'
                                                                                        // label=""
                                                                                        // inputRef={billingEveryRef}
                                                                                        type='number'
                                                                                        fullWidth
                                                                                        min="0"
                                                                                        // onChange={(e) => {
                                                                                        // 	let val = parseInt(e.target.value, 10);
                                                                                        // 	if (isNaN(val)) {
                                                                                        // 	  setEveryFielVal('');
                                                                                        // 	} else {
                                                                                        // 	  // is A Number
                                                                                        // 	  val = val >= 0 ? val : 0;
                                                                                        // 	  setEveryFielVal(val);
                                                                                        // 	}
                                                                                        //   }}
                                                                                        // value = {everyFielVal}
                                                                                        InputLabelProps={{ shrink: true }}
                                                                                        required
                                                                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                        error={!!errors.every}
                                                                                        helperText={errors?.every?.message}
                                                                                        defaultValue={defaultValues.every}
                                                                                        // formhelpertextprops={{
                                                                                        // 	style: { marginLeft: 0 }
                                                                                        // }}
                                                                                    />
                                                                                </>
													                        )}
												                        />

                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Payment Term 
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller
                                                                            name="paymentTerm"
                                                                            className="mb-16"
                                                                            control={control}
                                                                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                <>
                                                                                    <Autocomplete
                                                                                        {...field}
                                                                                        style={{ width: '100%' }}
                                                                                        // options={[{id: 1, name: '1 Month'}, {id: 1, name: '3 Months'}, {id: 1, name: '6 Months'}, {id: 1, name: '12 Months'}]}
                                                                                        options={paymentTerm}
                                                                                        disablePortal
                                                                                        getOptionLabel={option => option.name ?? ''}
                                                                                        onChange={(event, value) => field.onChange(value)}
                                                                                        // PaperComponent={({ children }) => (
                                                                                        //     <Paper>
                                                                                        //         {children}
                                                                                        //         {createCPaymentTermPermission && <Button
                                                                                        //             style={{ width: '100%' }}
                                                                                        //             variant="outlined"
                                                                                        //             color="primary"
                                                                                        //             onMouseDown={() => setOpenPaymentTermQuickAddDialog(true)}
                                                                                        //         >
                                                                                        //             Add Payment Term
                                                                                        //         </Button>}
                                                                                        //     </Paper>
                                                                                        // )}
                                                                                        renderInput={params => {
                                                                                            return (
                                                                                                <div ref={params.InputProps.ref}>
                                                                                                    <TextField
                                                                                                        required
                                                                                                        {...params}
                                                                                                        // inputRef={paymentTermInputRef}
                                                                                                        name="paymentTerm"
                                                                                                        // label='Payment Term'
                                                                                                        variant='outlined'
                                                                                                        fullWidth
                                                                                                        error={!!errors.paymentTerm}
                                                                                                        helperText={errors?.paymentTerm?.message && 'Payment Term is required!'}
                                                                                                        defaultValue={defaultValues.paymentTerm}
                                                                                                        // inputRef={ref}
                                                                                                        FormHelperTextProps={{
                                                                                                            style: {
                                                                                                                margin: 0,
                                                                                                                backgroundColor: '#f4f4f4',
                                                                                                                width: '100%',
                                                                                                                paddingTop: '2px'
                                                                                                            }
                                                                                                        }}
                                                                                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        />      
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Billing date
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <CustomDatePickerV2
                                                                        state={billingDate}
                                                                        setState={setBillingDate}
                                                                        required={true}
                                                                        //label='Contract Start Date*'
                                                                        // maxDate={contractEndDate.date}
                                                                        // condition='Contract End Date'									
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </>
                                            }
                                            {
                                                activeStep === 3 &&
                                                <>
                                                    <Box className='light_blue_holder'>
                                                        <Grid container spacing={2}>
                                                            <FormControl>
                                                                <FormLabel id="demo-radio-buttons-group-label">Payment Method</FormLabel>
                                                                <input type="radio" value="CreditCard" {...register("paymentMethod")} onClick={ () => capturePaymentMethodChangeEvent("CreditCard")}/> Credit Card
                                                                <input type="radio" value="purchaseOrder" {...register("paymentMethod")} onClick={() => capturePaymentMethodChangeEvent("purchaseOrder")} /> Purchase Order
                                                            </FormControl>
                                                            <pre>
                                                                {methods.watch("paymentMethod")}
                                                            </pre>
                                                            {
                                                                methods.watch("paymentMethod") === "purchaseOrder" && 
                                                                    
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Box>
                                                                            <Typography className='status_text'>
                                                                                PO Number
                                                                            </Typography>
                                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                                <Controller
                                                                                    name="poNumber"
                                                                                    className="mb-16"
                                                                                    control={control}
                                                                                    render={({ field }) => (
                                                                                        <>
                                                                                            <TextField
                                                                                                {...field}
                                                                                                variant='outlined'
                                                                                                // label='PO Number'
                                                                                                fullWidth
                                                                                                className='input-height'
                                                                                                required
                                                                                                // inputRef={poNumberRef}
                                                                                                // inputProps={{ readOnly: false }}
                                                                                                error={!!errors.poNumber}
                                                                                                helperText={errors?.poNumber?.message}
                                                                                                defaultValue={defaultValues.poNumber}
                                                                                                FormHelperTextProps={{
                                                                                                    style: { marginLeft: 0 }
                                                                                                }}
                                                                                                style={{ backgroundColor: '#fff' }}
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                />
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box>
                                                                            <Typography className='status_text'>
                                                                                Payment Date
                                                                            </Typography>
                                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                            <CustomDatePickerV2
                                                                                state={paymentDate}
                                                                                setState={setPaymentDate}
                                                                                required={true}	
                                                                                // label='PO Date*'										
                                                                            />
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Grid>
                                                                </>
                                                            }
                                                            {
                                                                methods.watch("paymentMethod") === "CreditCard" && 
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Box>
                                                                            <Typography className='status_text'>
                                                                                Card Holder's Name
                                                                            </Typography>
                                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                                <Controller
                                                                                    name="cardHolderName"
                                                                                    className="mb-16"
                                                                                    control={control}
                                                                                    render={({ field }) => (
                                                                                        <>
                                                                                            <TextField
                                                                                                {...field}
                                                                                                variant='outlined'
                                                                                                // label="Card Holder's Name"
                                                                                                fullWidth
                                                                                                // required={ purchaseOrder === 'CreditCard' ? true : false}	
                                                                                                // inputRef={cardHolderNameRef}
                                                                                                inputProps={{ readOnly: false }}
                                                                                                style={{ backgroundColor: '#fff' }}
                                                                                                error={!!errors.cardHolderName}
                                                                                                helperText={errors?.cardHolderName?.message}
                                                                                                defaultValue={defaultValues.cardHolderName}
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                />
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box>
                                                                            <Typography className='status_text'>
                                                                                Card Number
                                                                            </Typography>
                                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                                <Controller
                                                                                    name="cardNumber"
                                                                                    className="mb-16"
                                                                                    control={control}
                                                                                    render={({ field }) => (
                                                                                        <>
                                                                                            <TextField
                                                                                                {...field}
                                                                                                required={ purchaseOrder === 'CreditCard' ? true : false}	
                                                                                                variant='outlined'
                                                                                                // onKeyPress={(event) => {
                                                                                                //     if (!/[0-9/-]+/.test(event.key)) {
                                                                                                //         event.preventDefault();
                                                                                                //     }
                                                                                                // }}
                                                                                                label="Last 4 Digits of Credit Card"
                                                                                                // onChange={(e)=>{
                                                                                                //     if(e.target.value.length > 4){
                                                                                                //         return
                                                                                                //     }
                                                                                                //     setCardNumber(e.target.value)
                                                                                                // }}
                                                                                                fullWidth
                                                                                                inputProps={{ readOnly: false }}
                                                                                                style={{ backgroundColor: '#fff' }}
                                                                                                error={!!errors.cardNumber}
                                                                                                helperText={errors?.cardNumber?.message}
                                                                                                defaultValue={defaultValues.cardNumber}
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                />
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Grid>
                                                                </>
                                                            }
                                                            
                                                        </Grid>
                                                    </Box>
                                                </>
                                            }
                                            {
                                                activeStep === 4 && 

                                                <>
                                                    <Box className='light_blue_holder'>
                                                        <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px' }}>
                                                            <Typography style={{ fontSize: '17px' }}>Dalos will notify you automatically before subscription expiration.</Typography>
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
                                                            <Checkbox
                                                                checked={noReminderCheckBox}
                                                                onChange={handleChangeNoReminderCheckbox}
                                                                disabled={reminderCheckboxes.supportContact === true || reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true}
                                                            />
                                                            {/* <Controller 
                                                                name="noReminderCheckBox"
                                                                // className="mb-16"
                                                                control={control}
                                                                render={({ field: props }) => (
                                                                    <Checkbox
                                                                        {...props}
                                                                        checked={props.value}
                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                        disabled={methods.watch("reminderCheckBox") ? true : false}
                                                                    /> 
                                                                )}
                                                            /> */}
                                                            <Typography style={{ fontSize: '17px' }}>Do not remind me</Typography>
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
                                                            <Checkbox
                                                                disabled={noReminderCheckBox === true}
                                                                name={'term45'}
                                                                checked={reminderCheckboxes.term45}
                                                                onChange={handleChangeReminderCheckBoxes}
                                                            />
                                                            <Typography style={{ fontSize: '17px' }}>Remind me before       
                                                                <Controller
                                                                    name="no_of_days"
                                                                    className="mb-16"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                variant='standard'
                                                                                label=''
                                                                                fullWidth
                                                                                value={noOfDays}
                                                                                onChange={(e)=>{
                                                                                    setNoOfDays(e.target.value)
                                                                                }}
                                                                                onKeyPress={(event) => {
                                                                                    if (!/[0-9/-]+/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        margin: 0,
                                                                                        backgroundColor: '#f4f4f4',
                                                                                        width: '100%',
                                                                                        paddingTop: '2px',
                                                                                        fontSize: '17px'
                                                                                    }
                                                                                }}
                                                                                disabled={!reminderCheckboxes.term45}
                                                                                style={{ width: '10%', backgroundColor: '#fff', padding: '0 5px' }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
								                            no of days of contract expiry</Typography>
							                            </div>

                                                        <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
                                                            <Checkbox
                                                                disabled={noReminderCheckBox === true || (reminderCheckboxes.term45 === false && reminderCheckboxes.term30 === false )}

                                                                name={'supportContact'}
                                                                checked={reminderCheckboxes.supportContact}
                                                                onChange={handleChangeTextMeOption}
                                                            />
                                                            <Typography style={{ fontSize: '17px' }}>Text Me</Typography>
                                                            <Checkbox
                                                                disabled={noReminderCheckBox === true || (reminderCheckboxes.term45 === false && reminderCheckboxes.term30 === false )}
                                                                name={'supportContact'}
                                                                checked={reminderCheckboxes.email}
                                                                onChange={handleChangeEmailOption}
                                                            />
                                                            <Typography style={{ fontSize: '17px' }}>Email Me</Typography>
                                                        </div>
                                                    </Box>
                                                </>
                                            }
                                        </div>
                                        <div className='btn_holder'>
                                            {activeStep !== 0 && 
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className='back_btn'
                                                >
                                                    Back
                                                </Button>
                                            }

                                            {activeStep !== steps.length - 1 ?
                                                (
                                                    <Button onClick={handleNext} className='next_btn'>
                                                        Next
                                                    </Button>
                                                ) : (
                                                    <Button onClick={handleSubmitEvent} className='next_btn'>
                                                        Proceed
                                                    </Button>
                                                )
                                            }
                                            {/* {activeStep !== steps.length &&
                                                (completed[activeStep] ? (
                                                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                        Step {activeStep + 1} already completed
                                                    </Typography>
                                                ) : (
                                                    <Button onClick={handleComplete}>
                                                        {completedSteps() === totalSteps() - 1
                                                        ? 'Proceed'
                                                        : 'Complete Step'}
                                                    </Button>
                                                ))
                                            } */}
                                            {/* {activeStep === steps.length - 1 &&                                            
                                                <Button onClick={handleComplete} className='next_btn'>                                                
                                                    Proceed
                                                </Button>                                            
                                            } */}
                                        </div>
                                    </form>
                                </FormProvider>
                            </>
                        </div>
                    </Box>

                </div>
            </Drawer>
        </div >
    )
}

export default QuickAddSubscription