function AlphabeticalSorting(arr,propery_name){
    if(!Boolean(propery_name)) return
    return arr.sort((a, b) => {
        return (a[propery_name] ?? '').localeCompare(b[propery_name], undefined, {
            numeric: true,
            sensitivity: 'base'
        })
    });
}

export default AlphabeticalSorting