const excel = require("exceljs");
import FileSaver from 'file-saver';

const exportExcel = (headers, rows, filename = 'Tutorial') => {
    let workbook = new excel.Workbook();
let worksheet = workbook.addWorksheet(filename);

worksheet.columns = headers;

// Add Array Rows
worksheet.addRows(rows);

workbook.xlsx.writeBuffer().then(function (data) {
    var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    FileSaver.saveAs(blob, `${filename}.xlsx`);
});
}

export default exportExcel;
