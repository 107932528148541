import { Grid, Divider, Paper, Typography, Box, CircularProgress,Accordion, AccordionSummary, AccordionDetails, Chip, withStyles, TableContainer,Table, TableHead, TableCell, TableRow, TableBody, IconButton, Icon, Collapse, makeStyles, Card, CardContent } from '@material-ui/core';
import { useState,useEffect,useDispatch } from 'react';
import useToast from '@fuse/hooks/useToast';
import axios from "@fuse/utils/axios";
import { useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import TextMinimal from '@fuse/components/general/TextMinimal';
import download from 'downloadjs'

const useStyles = makeStyles(theme => ({
    positive_action: {
        color: 'green'
    },
    negative_action: {
        color: 'red'
    },
}))

function InnerView(props){
    const [logs, setLogs] = useState([]);
    const [quote, setQuote] = useState(null);
    // const toast = useToast(props.dispatch);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    useEffect(()=>{
        fetchQuoteLogs()
    },[]);

    function fetchQuoteLogs(){
        setLoading(true);
        axios.get(`/quotes/${props.quote.id}/change-requests-actions-logs`)
        .then(res => {
            const {logs, quote} = res.data.data;
            setQuote(quote);
            setLogs(logs.map(log => {
                let detail = '';
                if(log.prev_value == null){
                    log.prev_value = '';
                }

                if(log.curr_value == null){
                    log.curr_value = '';
                }

                switch(log.action){
                    case 'pro_rate':
                        detail = `Pro-rate requested`
                        break;
                    case 'others':
                        detail = `Requested other changes`
                        break;
                    case 'service_plan_upgrade':
                        detail = `Service plan upgrade requested`
                        break;
                    case 'accepted':
                        detail = `The quote was accepted`
                        break;
                    case 'denied':
                        detail = `The quote was declined`
                        break;
                    case 'remove_line_item':
                        detail = `Requested to remove line item`
                        break;
                    case 'add_line_item':
                        detail = `Requested to add line item`
                        break;
                    case 'merge_to':
                        let merged_quotes = '';
                        
                        log.quote.merged_quotes.forEach(merged_quote => {
                            merged_quotes += `${merged_quote.quote_id}, `
                        })

                        detail = `Quotes ${merged_quotes} were merged to quote ${log.quote.quote_id}`
                        break;
                    case 'Add note':
                        detail = `A note was added against this quote`
                        break;
                    case 'transfer_line_item':
                    case 'validation_request':
                        if(log.requested_user_name != null && log.requested_user_name != '')
                        {
                            detail = `Requested to add a new user`
                        }
                    default:
                        break;
                }
                const action = log.action.split('_').join(' ');
                return {...log,
                    fetching_histories: false,
                    history_on:false, 
                    histories: [],
                    user_name: log.user_companies_user != null ? `${log.user_companies_user.first_name} ${log.user_companies_user.last_name}` : '',
                    comment: log.comment, 
                    role: log.user_companies_user != null && log.user_companies_user.roles_end_user && log.user_companies_user.roles_end_user != null && log.user_companies_user.roles_end_user.display_name, 
                    action: action.charAt(0).toUpperCase() + action.slice(1), date: dateReformatTo_mmm_dd_yyyy(log.created_at.split('T')[0]), detail}
            }))
        })
        .catch(err => console.log(err))
        .finally(()=> setLoading(false));
    }

    const toggleHistory = (log_id) => {
        setLogs(logs => logs.map(log => {
            if(log.id === log_id){
                return {...log, history_on: !log.history_on}
            }
            return log;
        }))
        fetchHistories(log_id);
    }

    const fetchHistories = (req_id) => {
        setLogs(logs => logs.map(log => {
            if(req_id === log.id){
                return {...log, fetching_histories: true}
            }
            return log;
        }))
        axios.get(`/quotes/${props.quote.id}/change-requests-actions-logs/${req_id}/history`)
        .then(res => {
            let {histories} = res.data.data;
            histories = reformatHistories(histories);
            setLogs(logs => logs.map(log => {
                if(req_id === log.id){
                    return {...log, histories, fetching_histories: false}
                }
                return log;
            }))
        })
        .catch(err => console.log(err))
    }

    const reformatHistories = (histories) => {
        return histories.map(log => {
            let detail = '';
            if(log.prev_value == null){
                log.prev_value = '';
            }

            if(log.curr_value == null){
                log.curr_value = '';
            }

            let agent_biased_action = true;
            let action_type = 'nutral';

            function toNumberFormat(numberString){
                const number = Number(numberString);
                let fixed = number.toFixed(2);
                let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
                let [formatedNumber] = number.toLocaleString().split(".");
                const result = `${formatedNumber}.${secondNumber}`;
                return result
            }

            switch(log.action){
                case 'line_item_profit_margin_updated':
                    detail = `Profit margin changed from ${parseFloat(log.prev_value).toFixed(2)} % to ${parseFloat(log.curr_value).toFixed(2)} %`
                    break;
                case 'line_item_sell_price_updated':
                    detail = `Sell price changed from ${toNumberFormat(log.prev_value)} to ${toNumberFormat(log.curr_value)}`
                    break;
                case 'line_item_discount_updated':
                    detail = `Discount changed from ${toNumberFormat(log.prev_value)} % to ${toNumberFormat(log.curr_value)} %`
                    break;
                case 'line_item_service_plan_updated':
                    detail = `Service plan from ${log.prev_value} to ${log.curr_value}`
                    break;
                case 'profit_margin_updated':
                    detail = `Profit margin changed from ${parseFloat(log.prev_value).toFixed(2)} % to ${parseFloat(log.curr_value).toFixed(2)} %`
                    break;
                case 'sell_price_updated':
                    detail = `Sell price changed from ${toNumberFormat(log.prev_value)} to ${toNumberFormat(log.curr_value)}`
                    break;
                case 'discount_updated':
                    detail = `Discount changed from ${parseFloat(log.prev_value).toFixed(2)} % to ${parseFloat(log.curr_value).toFixed(2)} %`
                    break;
                case 'service_plan_updated':
                    detail = `Service plan from ${log.prev_value} to ${log.curr_value}`
                    break;
                case 'approved':
                    detail = `The quote was approved and sent to customer`
                    break;
                case 'denied':
                    detail = `The quote was declined`
                    break;
                case 'removed_line_items':
                    detail = `Line items removed`
                    break;
                case 'added_line_item':
                    detail = `Line item added`
                    break;
                case 'merge_to':
                    let merged_quotes = '';
                    
                    log.quote.merged_quotes.forEach(merged_quote => {
                        merged_quotes += `${merged_quote.quote_id}, `
                    })

                    detail = `Quotes ${merged_quotes} were merged to quote ${log.quote.quote_id}`
                    break;

                case 'prorated':
                    detail = `Prorated from ${dateReformatTo_mmm_dd_yyyy(log.prev_value.split('T')[0])} to ${dateReformatTo_mmm_dd_yyyy(log.curr_value.split('T')[0])}`
                    break; 
                case 'customer_accepted':
                case 'customer_denied':
                        detail = log.action === 'customer_accepted' ? 'Quote was accepted by the customer' : 'Quote was declined by the customer'
                        agent_biased_action = false;
                        action_type = log.action === 'customer_accepted' ? 'positive' : 'negative'
                        break;
                case 'Add note':
                    detail = `A note was added against this quote`
                    break; 
                case 'validation_confirmed':
                    agent_biased_action = false
                    break
                case 'validation_denied':
                    agent_biased_action = false
                    break
                default:
                    break;
            }
            const action = log.action.split('_').join(' ');
            return {...log, 
                user_name: log.client_user != null ? `${log.client_user.first_name} ${log.client_user.last_name}` : '',
                comment: log.comments, 
                role: log.client_user != null && log.client_user.role && log.client_user.role != null && log.client_user.role.role_name, 
                action: action.charAt(0).toUpperCase() + action.slice(1), date: dateReformatTo_mmm_dd_yyyy(log.created_at.split('T')[0]), 
                agent_biased_action,
                action_type,
                customer_name: log.user_companies_user != null ? `${log.user_companies_user.first_name} ${log.user_companies_user.last_name}` : '',
                customer_role: log?.user_companies_user?.roles_end_user?.display_name || '',
                detail
            }
        })
    }

    if(loading){
        return(
            <Box display="flex" alignItems="center" justifyContent="center" justifyItems="center" style={{padding: 20}}>
                <CircularProgress color="primary"/>
            </Box>
        )
    }

    return(
        <div style={{width: '100%'}}>
            {logs.length > 0 ?
                <TableContainer style = {{maxHeight:400}}>
                <Table stickyHeader aria-label = "sticky table" >
                <TableHead
                style={{background:theme.palette.action.selected}}
                >
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Action Type</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Reason</TableCell>
                        <TableCell>Serial No.</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Comment</TableCell>
                        <TableCell>History</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {logs.map(log => (
                <>
                <TableRow key={log.id}>
                            <TableCell>{log.date}</TableCell>
                            <TableCell>{log.action == 'Request to add user' ? log.requested_user_name : log.user_name}</TableCell>
                            <TableCell>
                            {
                                log.role && log.action != 'Request to add user' &&
                                <Chip 
                                label={log.role.charAt(0).toUpperCase()+log.role.slice(1)} 
                                size="small" 
                                style={{borderRadius:2,marginTop:5}}
                                />
                            }
                            </TableCell>
                            <TableCell>{log.action_type ? (log.action_type.charAt(0).toUpperCase()+log.action_type.slice(1)) : ''}</TableCell>
                            <TableCell>{log.action}</TableCell>
                            <TableCell>{log.action_reason}</TableCell>
                            <TableCell>{log.initial_serials}</TableCell>
                            <TableCell>{log.action == 'Accepted' ? <PurchaseOrder    order_id = {log.quote.quote_purchase_order.purchase_order_id}
                                                                                    file_link = {log.quote.quote_purchase_order.file_link}
                                                                                    date = {log.quote.quote_purchase_order.creation_date}
                                                                    /> : log.detail}</TableCell>
                            <TableCell>
                                <TextMinimal text={log?.comment || '-'} />
                            </TableCell>
                            <TableCell>
                                {
                                    log.action != 'Request to add user' && log.action != 'Accepted' &&
                                    <IconButton
                                        size="small"
                                        onClick={()=>{toggleHistory(log.id)}}
                                        >
                                            <Icon>{log.history_on ? 'expand_less' : 'expand_more'}</Icon>
                                    </IconButton>
                                }
                            </TableCell>
                </TableRow>
                <TableRow>
                <TableCell style={{ padding:0, minHeight: 50 }} colSpan={9}>
                
                  <Collapse in={log.history_on} timeout="auto" unmountOnExit>
                  {log.fetching_histories ?
                  <div style={{width: '100%', display: 'flex', justifyContent: 'center', padding: 10}}>
                      <CircularProgress color="primary"/>
                  </div>
                  :
                  <>
                  {log.histories.length <= 0 ?
                  <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        No history found
                    </div>
                    :
                    <Card variant="outlined" style={{margin: 5, borderRadius: 5}}>
                    <CardContent style={{padding: 0}}>
                    <Table>
                        <TableHead style={{background: theme.palette.action.hover}}>
                            <TableCell>Date</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Serial No.</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Comment</TableCell>
                        </TableHead>
                        <TableBody>
                        {log.histories.map(hist => (
                        <TableRow key={hist.id}>
                                    <TableCell>{hist.date}</TableCell>
                                    <TableCell>{hist.agent_biased_action ? hist.user_name : hist.customer_name}</TableCell>
                                    <TableCell>
                                    {hist.agent_biased_action ?
                                    <> 
                                    {
                                        hist.role && 
                                        <Chip 
                                        label={hist.role.charAt(0).toUpperCase()+hist.role.slice(1)} 
                                        size="small" 
                                        style={{borderRadius:2,marginTop:5}}
                                        />
                                    }
                                    </>
                                    :
                                    <>
                                    {
                                        hist.customer_role && 
                                        <Chip 
                                        label={hist.customer_role} 
                                        size="small" 
                                        style={{borderRadius:2,marginTop:5}}
                                        />
                                    }
                                    </>
                                    }
                                    </TableCell>
                                    <TableCell className={hist.action_type === 'positive' ? classes.positive_action : (hist.action_type === 'negative' ? classes.negative_action : '')}>{hist.action}</TableCell>
                                    <TableCell>{hist.serials}</TableCell>
                                    <TableCell>{((log.action == 'Transferred line item' || log.action == 'Validation request') && log.requested_user_name != null) ? <NewUser name={log.requested_user_name} email={log.requested_user_email} location={log.requested_user_location}/> : hist.detail}</TableCell>
                                    <TableCell>
                                        <TextMinimal text={hist?.comment || '-'} />
                                    </TableCell>
                        </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </CardContent>
                    </Card>
                    }
                    </>
                    }
                  </Collapse>
                </TableCell>
                </TableRow>
                </>
                ))}
                </TableBody>
                </Table>
                </TableContainer>
            :
            (
                    <Grid container style={{flexGrow:1, marginTop: 30, marginBottom: 30}} spacing={4}>
							<Grid item xs={12}>
									<Grid container justify="center">
										No Logs Found
									</Grid>
							</Grid>
                    </Grid>
            )
            }
        </div>
    )
}

function PurchaseOrder(props){
    return (
        <div>
            <strong>Date</strong>: {props.date.split("-").length > 1 ? dateReformatTo_mmm_dd_yyyy(props.date.split("T")[0]) : props.date}<br/> <strong>Order ID</strong> : {props.order_id} <br/> <strong>File</strong> : <a href={props.file_link}>Download PDF</a>
        </div>
    )
}
function NewUser(props){
    return (
        <div>
            <strong>Name</strong>: {props.name}<br/> <strong>Email</strong> : {props.email} <br/> <strong>Location</strong> : {props.location}
        </div>
    )
}

function Spreaded({left, right}) {
    return(
        <Box display="flex" alignItems="center">
            <Box flexGrow={1} >{left}</Box>
            <Box>{right}</Box>
        </Box>
    )
}

function LogView(props) {
    return(
		<InnerView {...props} />
    )
}

export default LogView;