import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";

function flattenAssets(assets){
  return assets.map((asset)=>{
    let {
      asset_table_id, serial_number, asset_tag, asset_name,user_name,
      asset_status_name, asset_status_color, contract_number, contract_status_name,
      contract_status_color, model_name, model_no, category_name, manufacturer_name,
      location_name, address, notes, created_at, updated_at,source,connection_status,department_name,
      childcount=undefined
    } = asset;
    return {
      id: asset_table_id,
      serial_no: serial_number,
      asset_tag: asset_tag,
      name: asset_name,				
      user_name: user_name,
      department_name: department_name,
      status: asset_status_name,
      asset_status_color: asset_status_color,
      contract_number: contract_number,
      contract_status: contract_status_name,
      contract_status_color: contract_status_color,
      model_name: model_name,
      model_number: model_no,
      category: category_name,
      manufacturer: manufacturer_name,
      location_name: location_name,
      address: address,
      notes: notes,
      created_date: dateReformatTo_mmm_dd_yyyy(created_at),
      updated_date: dateReformatTo_mmm_dd_yyyy(updated_at),
      source: source,
      network_status: connection_status,
      childCount: childcount
    }
  })
}

export function onFetchAsset(params,gridRef){
  const {startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode} = params.request;

  axios.post(`/assets?manufacturer_id=${''}&model_id=${''}&category_id=${''}&contract_header_id=${''}&location_id=${''}`,{
    dataSourceParams: {
      startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode, report: true
    }
  }).then((res)=>{
    if(res.data.data.rows.length === 0) gridRef.current.api.showNoRowsOverlay();
    params.successCallback(flattenAssets(res.data.data.rows), res.data.data.lastRow);
  }).catch((err)=>{
    console.log('error',err)
    params.failCallback();
  })
}

export const colDefs = [
  {
    field: "serial_no",
    key: "serial_no",
    headerName: "Serial Number",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "asset_tag",
    key: "asset_tag",
    headerName: "Asset Tag",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "name",
    key: "name",
    headerName: "Asset Name",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "user_name",
    key: "user_name",
    headerName: "User",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "department_name",
    key: "department_name",
    headerName: "Department",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "status",
    key: "status",
    headerName: "Status",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "contract_number",
    key: "contract_number",
    headerName: "Contract Number",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "contract_status",
    key: "contract_status",
    headerName: "Contract Status",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "model_name",
    key: "model_name",
    headerName: "Model Name",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "model_number",
    key: "model_number",
    headerName: "Model Number",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "category",
    key: "category",
    headerName: "Category",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },{
    field: "manufacturer",
    key: "manufacturer",
    headerName: "Manufacturer",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "location_name",
    key: "location_name",
    headerName: "Location Name",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "address",
    key: "address",
    headerName: "Address",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "notes",
    key: "notes",
    headerName: "Notes",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "created_date",
    key: "created_date",
    headerName: "Created Date",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "updated_date",
    key: "updated_date",
    headerName: "Updated Date",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "source",
    key: "source",
    headerName: "Source",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  },
  {
    field: "network_status",
    key: "network_status",
    headerName: "Network Status",
    // filter: "text",
    // sortable: true,
    // floatingFilter: true
  }
]