import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField } from '@material-ui/core';
import { useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import useToast from '@fuse/hooks/useToast'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {

	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	let suggRef = useRef(null);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M4.5 7.65311V16.3469L12 20.689L19.5 16.3469V7.65311L12 3.311L4.5 7.65311ZM12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM6.49896 9.97065L11 12.5765V17.625H13V12.5765L17.501 9.97066L16.499 8.2398L12 10.8445L7.50104 8.2398L6.49896 9.97065Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex md:text-18 mx-12 font-semibold"
					style = {{ color : 'black' }}
				>
					Archived Assets List
				</Typography>
			</div>

			<div style={{display:'flex'}}>
				<div className={classes.buttonContainer}>
				<ThemeProvider theme={mainTheme}>
					<Paper
						component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
						style={{minWidth:"40px"}}
					>
						<TextField
							placeholder="Search Assets"
							className="flex flex-1 mx-8 p-2"
							fullWidth
							InputProps={{
								disableUnderline: true,
								
							}}
							value={query_data ?? '' }
							onChange={(e)=>{
								dispatch(setQuickFilter(e.target.value))
							}}
						/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>
		</div>
	);
}

export default DynamicFieldsHeader;
