import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import {TextField,MenuItem, FormControlLabel} from '@material-ui/core';
import React from 'react';
import { useState, useEffect, Fragment } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import { openReportViewerDialog } from '../store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
	
});



function DynamicFieldsHeader(props) {
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	const [currentFilter, setCurrentFilter] = useState('');
	const [currentFilterLabel, setCurrentFilterLabel] = useState('');

	function handleSearchReset(){
		props.handleSearchReset();
		setSelectedDatefrom(new Date())
		setSelectedDateto(new Date())
		//props.handleSearchSuggestions("", currentFilter)
		//setSearchQuery("");
	}
	const [selectedDatefrom, setSelectedDatefrom] = useState(new Date());
	const [selectedDateto, setSelectedDateto] = useState(new Date());

	const handleDateChangefrom = (date) => {
		//console.log(date)
		setSelectedDatefrom(date);
	};

	const handleDateChangeto = (date) => {
		//console.log(date)
		setSelectedDateto(date);
	};

	const handleSearch = ()=>{
		let date_diffrence = parseInt((new Date(selectedDateto) - new Date(selectedDatefrom)) / (1000 * 60 * 60 * 24), 10); 
		//sconsole.log(date_diffrence)
		if(date_diffrence > -1 ){
			axios.post(`/sales-orders/date-wise/search`,{
				from_date: new Date(selectedDatefrom).toISOString().split('T')[0],
				to_date: new Date(selectedDateto).toISOString().split('T')[0],
			})
			.then(res => {
				const { data } = res.data;
				//console.log(data)
				props.searchList(data)
			})
			.catch(err => {
				console.log(err);
			})
		}else{
			toast.error('From date can not be greater than to date')
		}
	}



	
  
	// useEffect(() => {
	// 	console.log(props.searchFilters);
		
	// 	if(props.searchFilters && props.searchFilters.length){
	// 		setCurrentFilter(props.searchFilters[0].field)
	// 	}
	// },[props.searchFilters])

	// useEffect(() => {
	// 	if(props.searchFilters && props.searchFilters.length){
	// 		setCurrentFilterLabel(props.searchFilters.find((item) => item.field == currentFilter)?.label || "");
	// 	}
	// },[currentFilter]);

	// useEffect(() => {
	// 	props.onSearchChange(search, currentFilter)
	// },[search, currentFilter])

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<Icon
					component={motion.span}
					initial={{ scale: 0 }}
					animate={{ scale: 1, transition: { delay: 0.2 } }}
					className="text-24 md:text-32"
					style = {{ color: "black" }}
				>
					point_of_sale
				</Icon>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-20 mx-12 font-semibold"
					style = {{ color: "black" }}
				>
					Sales orders
				</Typography>
			</div>

			

			<div className={`flex flex-1 items-center justify-end ${classes.noPaddingRight}`}>
				<ThemeProvider theme={mainTheme}>
				{/* <Paper
					component={motion.div}
					initial={{ y: -20, opacity: 0 }}
					animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
					className={`flex items-center mr-10 max-w-512 shadow ${classes.buttonSquare}`}
				>
					<form className={classes.container} noValidate>
					<TextField
						id="date"
						label="From"
						//placeholder="From"
						type="date"
						//format="dd/mm/yyyy"
						//defaultValue="2017-05-24"
						className={classes.textField}
						InputLabelProps={{
						shrink: true,
						}}
					/>
					</form>
					
				</Paper>
				<Paper component={motion.div}
					initial={{ y: -20, opacity: 0 }}
					animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
					className={`flex items-center mr-10 max-w-512 shadow ${classes.buttonSquare}`}>
					<form className={classes.container} noValidate>
						<TextField
							id="date"
							label="To"
							//placeholder=""
							type="date"
							format="dd-mm-yyyy"
							//defaultValue="2017-05-24"
							className={classes.textField}
							InputLabelProps={{
							shrink: true,
							}}
						/>
					</form>
				</Paper> */}
				

				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					
					<Grid container justifyContent="space-around" style={{float: 'right'}}>

						<Paper component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center mr-10 max-w-512 shadow ${classes.buttonSquare}`}
						style={{padding: '0px 10px', minHeight:"10px"}}>
							From
							<KeyboardDatePicker
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							//label="From"
							disableUnderline="true"
							value={selectedDatefrom}
							onChange={handleDateChangefrom}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							className='mt-0'
							style={{paddingLeft:'10px', paddingTop: '9px'}}
							/>

						</Paper>

						<Paper component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center mr-10 max-w-512 shadow ${classes.buttonSquare}`}
							style={{padding: '0px 10px', minHeight:"10px"}}>
								To
								<KeyboardDatePicker
								format="MM/dd/yyyy"
								margin="normal"
								id="date-picker-inline"
								//label="From"
								disableUnderline="true"
								value={selectedDateto}
								onChange={handleDateChangeto}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								style={{paddingLeft:'10px', paddingTop: '9px'}}/>

						</Paper>	
						
					</Grid>
				</MuiPickersUtilsProvider>
				<Button
						variant="contained"
						color="secondary"
						size="small"
						onClick={handleSearch}
						
						className={` ${classes.buttonSquare}`}>
							Search
					</Button>

				{/* </Paper> */}
				
					{/* <Paper
						component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center max-w-512 shadow ${classes.buttonSquare}`}
						style={{minWidth:"400px"}}
					>
						<Autocomplete
							id="assets-search-box"
							freeSolo
							autoSelect
							autoComplete
							value={""}
							options={props.searchSuggestions}
							style={{width: "100%"}}
							getOptionLabel={option => option}
							noOptionsText=""
							onInputChange={event => {
								if(event){
									setSearchQuery(event.target.value || "")
									props.handleSearchSuggestions(event.target.value, currentFilter)
								}
							}}
							onChange={(event, value) => {
								let query = value || ""

								setSearchQuery(query)
							}}
							renderInput={(params) => (
							    <div ref={params.InputProps.ref}>
									<TextField
										{...params.inputProps}
										placeholder={`Search ${currentFilterLabel}`}
										className="flex flex-1 mx-8"
										disableUnderline
										fullWidth
										value={search}
										InputProps={{
											disableUnderline: true
										}}
										onKeyDown={e => {
											if (e.keyCode === 13 && search) {
												props.handleSearch(search, currentFilter)
											}
										}}
									/>
								</div>
							)}
							/>
						{Boolean(search && search.length) && 
							<IconButton
							aria-label="toggle password visibility"
							onClick={() => {props.handleSearch(search, currentFilter)}}
							size="small"
							className="mr-10">
								<Icon>search</Icon>
							</IconButton>
						}
					</Paper> */}
				</ThemeProvider>
				
				<div className={`pl-10 `} style={{width:"90px"}}>
					<Button
						variant="contained"
						color="secondary"
						size="small"
						onClick={handleSearchReset}
						className={`w-full ${classes.buttonSquare}`}>
							Reset
					</Button>
				</div>

				<div className={`pl-10 `} style={{width:"130px"}}>
					<Button
						variant="contained"
						color="secondary"
						size="small"
						onClick = {()=>dispatch(openReportViewerDialog())}
						className={`w-full ${classes.buttonSquare}`}>
							Save View
					</Button>
				</div>

			</div>
		</div>
	)
}

export default DynamicFieldsHeader;
