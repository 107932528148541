const initialState = {
	open: false,
	quickContractStatusAdded: false,
}

const actionTypes = {
    OPEN_REPORT_VIEWER_DIALOG: "OPEN_REPORT_VIEWER_DIALOG",
    CLOSE_REPORT_VIEWER_DIALOG: "CLOSE_REPORT_VIEWER_DIALOG",
	QUICK_ADDED_CONTRACT_STATUS: "QUICK_ADDED_CONTRACT_STATUS",
}

export function openReportViewerDialog(){
    return {
        type: actionTypes.OPEN_REPORT_VIEWER_DIALOG,
    }
}

export function closeReportViewerDialog(){
    return {
        type: actionTypes.CLOSE_REPORT_VIEWER_DIALOG,
    }
}


export function quickAddedContractStatus() {
	return {
		type: actionTypes.QUICK_ADDED_CONTRACT_STATUS
	}
}

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.OPEN_REPORT_VIEWER_DIALOG:{
			return { 
				...state,
				open: true,
			}
		}
		case actionTypes.CLOSE_REPORT_VIEWER_DIALOG:{
			return { 
				...state,
				open: false,
			}
		}
		case actionTypes.QUICK_ADDED_CONTRACT_STATUS: {
			return {
				...state,
				quickContractStatusAdded: !state.quickContractStatusAdded
			}
		}
	  default:
		return state
	}
}