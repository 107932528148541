export const abbreviations = {
    id: 'ID',
    sku: 'SKU',
    no: 'No.',
    of: 'of',
    msrp: 'MSRP'
}

export function getAbbreviatedCapitalized(phrase){
    return phrase.split(/_|-| /)
            .map(word => {
                if(word in abbreviations) return abbreviations[word]
                return word.charAt(0).toUpperCase() + word.substring(1)
            })
            .join(' ')
}

export function formatPriceField(value){
    value.match(/^\d(\.\d)*$/)
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
}

export function generateProperLocationString({
    location_name,
    address1,
    address2,
    city,
    state,
    country,
    zip = ''
}){
    if(location_name === "") {
        return ''
    }
    if (zip === '') {
        if(Boolean(address2)){
            return `${location_name}-${address1},${address2},${city},${state},${country}`
        }
        return `${location_name}-${address1}, ${city}, ${state}, ${country}`
    } else {
        if(Boolean(address2)){
            return `${location_name}-${address1}, ${address2}, ${city}, ${state}, ${country}, ${zip}`
        }
        return `${location_name}-${address1}, ${city}, ${state}, ${country}, ${zip}`
    }
    
}