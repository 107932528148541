import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListView from './listView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListingList(props) {
  	const classes = useStyles();

	if (false) {
		return (
			<div>
				<Card>
					<CardContent>You don't have permission to view any Subscription Providers at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		)
	}

	return (
		<div className="w-full flex flex-col">
			<ListView />
		</div>
	);
}

export default withRouter(ListingList)
