import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { usePermission } from '@fuse/hooks';
import Drawer from '@material-ui/core/Drawer';
import { Button, Icon, TextField } from '@material-ui/core';
import ListView from 'app/main/assets/components/listView';
import axios from "@fuse/utils/axios";
import { useDispatch } from "react-redux";
import useToast from "@fuse/hooks/useToast";
import GeneralModal from '@fuse/components/Modal/GeneralModal';

const useStyles = makeStyles({
  drawer: {
    width: '90vw',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    gap: '15px'
  },
  table: {
    // border: '2px solid black',
    // height: '80%'
    paddingLeft: '13px',
    paddingRight: '13px'
  },
  closeIcon: {
    cursor: 'pointer',
    transform: "translateY(5px)"
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  heading: {
    fontSize: '20px'
  }
});

function AddAssetsInContract({ open, close, getContractDetails, contractDetails }) {
  const classes = useStyles();
  const { hasPermission } = usePermission()
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
  const [ selectedRows, setSelectedRows ] = useState([])
  const [ openConfirmationModel, setOpenConfirmationModal ] = useState(false)
  let viewAssetPermission = hasPermission('view-assets',true)
	let createAssetPermission = hasPermission('add-assets',true)
	let updateAssetPermission = hasPermission('update-assets',true)
	let deleteAssetPermission = hasPermission('delete-assets',true)

  const handleConfirm = () => {
    const assets = JSON.stringify(selectedRows.map(row => {return {id:row.id, serial_no: row.serial_no }}))
    axios.post(`/contracts/import-asset-into-contract/${contractDetails.id}`,{
      assets: assets
    }).then((res)=>{
      close()
      setOpenConfirmationModal(false)
      setSelectedRows([])
      getContractDetails()
      toast.success(res.data.message)
    }).catch((err)=>{
      console.log('error',err)
    })
	}

	const handleClose = () => {
		setOpenConfirmationModal(false)
	}

  function handleImport( selectedRows=[] ){
    setOpenConfirmationModal(true)
    setSelectedRows(selectedRows)
  }

  if (viewAssetPermission != 'all' && viewAssetPermission?.length == 0) {
		return (
			<div>
				<Card>
					<CardContent>You don't have permission to view any Assets at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		)
	}

  return (
    <Drawer anchor={'right'} open={open}>
      <div className={classes.drawer}>
        <div className={classes.header}>
          <div className={classes.headerContainer}>
            <span className={classes.heading}></span>
            <Icon className={ classes.closeIcon } onClick={close}>
              <span class="material-symbols-outlined">close</span>
            </Icon>
          </div>
          {/* <div className={classes.headerContainer}>
            <TextField
							placeholder="Search Assets"
              variant="outlined"
							InputProps={{
								disableUnderline: true
							}}
							onChange={(e)=>{
								// handleDebouncedInputValue(e)
							}}
						/>
            <Button
              variant="contained"
              color="primary"
              onClick={handleImport}
            >Import
            </Button>
          </div> */}
        </div>
        <div className={classes.table}>
          <ListView
            createAssetPermission={createAssetPermission} 
            updateAssetPermission={updateAssetPermission} 
            deleteAssetPermission={deleteAssetPermission}
            importAssetInOtherModule={true}
            handleImport={handleImport}
          />
        </div>
        <GeneralModal
          open={ openConfirmationModel }
          title={''}
          text={<h4>{selectedRows.length} {selectedRows.length === 1 ? 'asset' : 'assets'} will be associated to contract <b>{contractDetails.contractNumber}</b></h4>}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          actionButtonName={"Import"}
        />
      </div>
    </Drawer>
  )
}

export default AddAssetsInContract