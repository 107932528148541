import Page from './index';
import { authRoles } from 'app/auth';

export default {
settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
	},
	// auth: authRoles.user,
	routes: [
		{
			path: '/archived-service-tickets',
			exact: true,
			component: Page.Layout
		},
		{
			path: '/archive-service-tickets/:id',
			exact: true,
			component: Page.Details
		}
	]
};