import _ from '@lodash';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import ListView from './listView';
import GridView from './gridView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function EndUsersAccountsTable(props) {

	return (
		<div className="w-full flex flex-col">
			
			
			<SwitchView 
				gotoDetailsPage={props.gotoDetailsPage} 
			/>
			<div className="p-10">

			</div>
		</div>
	);

}

function SwitchView({
		gotoDetailsPage,
	}) {
		return(
			<ListView
				//endUsersAccounts={endUsersAccounts} 
				gotoDetailsPage={gotoDetailsPage}
			/>
		)
}

export default withRouter(EndUsersAccountsTable)
