import Page from './index';
import { authRoles } from 'app/auth';

export default {
settings: {
		layout: {
			config: {
				
			}
		}
	},
	// auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/dynamic-fields',
			exact: true,
			component: Page.Layout
		}
	]
};