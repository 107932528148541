import useToast from '@fuse/hooks/useToast'
import {
	Accordion, AccordionDetails, AccordionSummary, Button,
	DialogTitle, Divider, Icon,
	IconButton, TextareaAutosize, makeStyles, MenuItem, Paper, TextField, Typography,
	Drawer, Checkbox, InputAdornment, FormControlLabel, Radio
} from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'
import ContractTypeQuickAdd from './quickAdds/contractType'
import ContractStatusQuickAdd from './quickAdds/contractStatus'
import SLAQuickAdd from './quickAdds/serviceLevelAgreement'
import PaymentTermQuickAdd from './quickAdds/paymentTerm'
import ServiceProviderQuickAdd from './quickAdds/serviceProvider'
import ShippingLocationQuickAdd from './quickAdds/shippingLocation'
import { formatPriceField, generateProperLocationString } from '@fuse/utils/general'
import { usePermission } from '@fuse/hooks';
import CustomDatePicker from '@fuse/components/customDatePicker'
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import QuickAddPaymentTerm from "@fuse/components/Modal/QuickAddPaymentTerm";
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import AddPhoneNumberModal from '@fuse/components/Modal/AddPhoneNumberModal'
import Editor from '@fuse/components/Editor';


/*
CODE CONTENT: 
Accordions Expand Control
Main Form
Service Provider Quick Add
Shipping Location Quick Add
Other Quick Adds
Utility Functions
UseEffect Declarations
*/

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft: '20px !important',
			marginRight: '20px !important',
			marginBottom: '20px !important',
			padding: '10px',
			backgroundColor: '#f4f4f4',
			borderRadius: '4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		customDrawerPaper: {
			maxWidth: '700px'
		}
	}
))

function AddContractFormDialog(props) {

	const [contractStartDate,setContractStartDate] = useState({
		date: null,
		error: false
	})
	const [contractEndDate,setContractEndDate] = useState({
		date: null,
		error: false
	})
	const [poDate,setPoDate] = useState({
		date: null,
		error: false
	})
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.contractsApp.formViewDialog)
	const { data: editFormState } = useSelector((state) => state.assetsApp.formViewDialog);
	const [loading, setLoading] = useState(false)
	const [shippingLocationMenu, setShippingLocationMenu] = useState([])
	const [contractStatusMenu, setContractStatusMenu] = useState([])
	const [serviceProviderMenu, setServiceProviderMenu] = useState([])
	const [paymentTermMenu, setPaymentTermMenu] = useState([])
	const [slaMenu, setSlaMenu] = useState([])
	const [contractTypeField, setContractTypeField] = useState(false)
	const [slaField, setSlaField] = useState(false)
	const [contractStatusField, setContractStatusField] = useState(false)
	const [paymentTermField, setPaymentTermField] = useState(false)
	const [serviceProviderField, setServiceProviderField] = useState(false)
	const [contractsMenu, setContractsMenu] = useState([])
	const [childContract, setChildContract] = useState(false)
	const [parentContractMenu, setParentContractMenu] = useState([])
	// const [startDate, setStartDate] = useState('')
	// const [endDate, setEndDate] = useState('')
	const [newContractType, setNewContractType] = useState('')
	const [createNewShippingLocation, setCreateNewShippingLocation] = useState(false)
	const [newSla, setNewSla] = useState('')
	const [newContractStatus, setNewContractStatus] = useState('')
	const [newPaymentTerm, setNewPaymentTerm] = useState('')
	const [contractTypeAccordionExpand, setContractTypeAccordionExpand] = useState(false)
	const [slaAccordionExpand, setSlaAccordionExpand] = useState(false)
	const [serviceProviderAccordionExpand, setServiceProviderAccordionExpand] = useState(false)
	const [paymentAccordionExpand, setPaymentAccordionExpand] = useState(false)
	const [formattedNumber, setFormattedNumber] = useState('')
	const [ contractDescriptionText,setContractDescriptionText ] = useState('')

	const [noOfDays, setNoOfDays] = useState('')
	const [phoneNumberAdded, setPhoneNumberAdded] = useState(false)

	const [
    openPaymentTermQuickAddDialog,
    setOpenPaymentTermQuickAddDialog,
  ] = useState(false);
	const [currentCountryCurrency, setCurrentCountryCurrency] = useState({
		"name": "United States",
		"code": "US",
		"currency": "USD"
	})
	const contractTypeInputRef = useRef(""),
		renewalInputRef = useRef(""),
		masterInputRef = useRef(""),
		slaInputRef = useRef(""),
		serviceProviderInputRef = useRef("");
	const billingAmountRef = useRef("");
	const billingEveryRef = useRef("");
	const paymentTermInputRef = useRef("");
	const cardHolderNameRef = useRef("");
	const cardNumberRef = useRef("");
	const poNumberRef = useRef("");
	const totalContractValueRef = useRef("");


	const { hasPermission } = usePermission()
	const createCStatusPermission = hasPermission('add-contract_status')
	const createCLocationPermission = hasPermission('add-location')
	const createCTypePermission = hasPermission('add-contract_type')
	const createSLAPermission = hasPermission('add-service_level_agreement')
	const createCPaymentTermPermission = hasPermission('add-payment_term')
	const createCServiceProviderPermission = hasPermission('add-service_provider')

	const [reminderCheckboxes, setReminderCheckBoxes] = useState({
		term45: false,
		term30: false,
		supportContact: false,
		email: false
	})

	const [noReminderCheckBox, setNoReminderCheckBox] = useState(false)
	const [currentCountry, setCurrentCountry] = useState('us')
	/**
	 * added by Sujay Ghosh at 29 Jan 2024
	 */
	const [checkForBilling, setCheckForBilling] = useState(false)
	const [everyFielVal, setEveryFielVal] = useState(1);
	const [paymentTerm, setPaymentTerm] = useState([]);
	const [billingDate,setBillingDate] = useState({
		date: null,
		error: false
	})

	const [openPhoneNoModal, setOpenPhoneNoModal] = useState(false)
	const [phoneNumberPresent, setPhoneNumberPresent] = useState(false)
	const [paymentDate,setPaymentDate] = useState({
		date: null,
		error: false
	})
	const [checkPaymentMethod, setCheckPaymentMethod] = useState(false)
	const [purchaseOrder,setPurchaseOrder] = useState('purchaseOrder')
	const [cardNumber, setCardNumber] = useState('');


	/*--------------------------------------Accordions Expand Control---------------------------------------*/
	const resetAccordionsExpand = () => {
		setContractTypeAccordionExpand(false)
		setSlaAccordionExpand(false)
		setPaymentAccordionExpand(false)
		setServiceProviderAccordionExpand(false)
	}

	const manageContractTypeAccordion = () => {
		setContractTypeAccordionExpand(!contractTypeAccordionExpand)
	}

	const manageSLAAccordion = () => {
		setSlaAccordionExpand(!slaAccordionExpand)
	}

	const managePaymentAccordion = () => {
		setPaymentAccordionExpand(!paymentAccordionExpand)
	}

	const manageServiceProviderAccordion = () => {
		setServiceProviderAccordionExpand(!serviceProviderAccordionExpand)
	}

	const handlePhoneNumberCloseModal = () => {
		setOpenPhoneNoModal(false)
		// console.log("phoneNumberAdded", phoneNumberAdded)
		// if(!phoneNumberAdded && !phoneNumberPresent){
		// 	setReminderCheckBoxes({
		// 		...reminderCheckboxes,
		// 		supportContact: false
		// 	})
		// }
	}
	useEffect(()=>{
		if(!phoneNumberAdded && !phoneNumberPresent && !openPhoneNoModal){
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: false
			})
		}else{
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: true
			})
		}
	},[phoneNumberAdded,phoneNumberPresent,openPhoneNoModal])


	const onSubmitAccordionExpand = () => {

		// const isErrorOccured= false
		if (slaInputRef.current.value === '') {
			// isErrorOccured=true
			setSlaAccordionExpand(true)
		}

		if (serviceProviderInputRef.current.value === '') {
			// isErrorOccured=true
			setServiceProviderAccordionExpand(true)
		}


		//paymentmethod validation

		if(purchaseOrder == "purchaseOrder") {
			if(poNumberRef.current.ref === '' || paymentDate.date === null) {
				// isErrorOccured=true
				setCheckPaymentMethod(true)
			}
		} 
		if(purchaseOrder == "creditCard") {
			if(cardHolderNameRef.current.ref === '' || cardNumberRef.date === null) {
				// isErrorOccured=true
				setCheckPaymentMethod(true)
			}
		} 


		//billing cycle validation
		if (
			billingAmountRef.current.value === '' ||
			billingEveryRef.current.value === '' ||
			paymentTermInputRef.current.value === ''
		) {
			// isErrorOccured=true
			setCheckForBilling(true)
		}

		// if(!isErrorOccured) {
		// 	setValue('billingCycleAmount', billingAmountRef.current.value.toString().replaceAll(',',''))
		// 	setValue('totalContractValue', totalContractValueRef.current.value.toString().replaceAll(',',''))
		// }



		

	}

	function summeryClickSubBilling() {
		// let expand = !checkForBilling
		setCheckForBilling(prevState => {
			return !prevState;
		})
	}

	function summeryClickSubPayment() {
		// let expand = !checkPaymentMethod
		setCheckPaymentMethod(prevState => {
			return !prevState;
		})
	}


	const handleRadioChange = (event) => {
		if(editFormState == null) {
			if(event.target.value == 'purchaseOrder'){
				setCardNumber('')
				setValue('cardHolderName','')
				setValue('cardNumber','')
			}if(event.target.value == 'CreditCard'){
				setPaymentDate({
					date: null,
					error: false
				});
				setValue('poNumber', '');
			}
		}
		setPurchaseOrder(event.target.value);
	};


	async function fetchPaymetTerm(){
		axios.get(`subscription/get-payment-term`).then(response => {
			setPaymentTerm(response.data.data.map((item) => {
				return { id: item.id, name: item.display_name }
			}))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}
	/*---------------------------------------------------------------------------------------------------------*/

	/*-----------------------------------------------Main Form-----------------------------------------*/

	const schema = yup.object({
		contractId: yup.string().required('Contract Number is required!'),
		renewal: yup.string().required('Renewal Status is required!'),
		contractName: yup.string().required('Contract Name is required!'),
		// contractDescription: yup.string().max(200, 'Maximum 200 characters!').nullable(),
		totalContractValue: yup.string().typeError('Invalid value').required('The Total Contract Value is required!').test('totalContractValue', 'Invalid Value', (value) => {
			// if(/^\d+(\.\d{1,2})?$/.test(value.toString()) || /^[0-9]{1,3}(?:\,[0-9]{3})*(?:\.[0-9]{1,2})?$/.test(value.toString())) {
			// 	return true;
			// }
			// return false;
			return /^\d+([\,]\d+)*([\.]\d+)?$/.test(value.toString())
		}),
		// contractStartDate: yup.string().required('Contract Start Date is required!'),
		// contractEndDate: yup.string().required('Contract End Date is required!'),
		contractStatus: yup.object().nullable().required('Contract Status is required!'),
		// department: yup.string().required('Department is required!'),
		contractType: yup.string().nullable().required('Contract Type is required!'),
		currentShippingLocation: yup.object().nullable().required('Location is required!'),
		// currentBillingLocation: yup.object().nullable().required('Billing Location is required!'),
		master: Boolean(editFormState) ? yup.object().nullable() : yup.object().nullable().required('Master is required!'),
		parentContract: yup.object().nullable(),
		// parentContract: yup.object().nullable().when("master", (master,schema)=>{
		// 	if(master) return schema.required('Parent Contract is required!')
		// }),
		provider: yup.object().nullable().required('Service Provider is required!'),
		sla: yup.object().nullable().required('Service Level Agreement is required!'),
		// paymentMethod: 
		poNumber:yup.string().when('purchaseOrder', {
			is: 'purchaseOrder',
			then: yup.string().required('PO Number is required'),
			otherwise: yup.string().nullable(),
		}),
		cardHolderName:  yup.string().nullable(),
		
		// yup.string().when('purchaseOrder', {
		// 	is: 'CreditCard',
		// 	then: yup.string().required('Card Holder Name is required'),
		// 	otherwise: yup.string().nullable(),
		// }),
		cardNumber: yup.string().nullable(),
		
		// yup.number().when('purchaseOrder', {
		// 	is: 'CreditCard',
		// 	then: yup.number().required('Card Number is required').typeError('Invalid Value').test('cardNumber', 'Invalid Value', (value) => {
		// 		if(value.toString().length > 4) {
		// 			return false;
		// 		} 
		// 		return true
		// 	}),
		// 	otherwise: yup.number().nullable(),
		// }),
		every: yup.number().required('Every Field Value is required.').typeError('Invalid Value').test('every', 'Invalid Value', (value) => {
			return /^[0-9]+$/.test(value.toString())
		}),
		billingCycleAmount: yup.string().typeError('Invalid value').required('The Billing Cycle Amount is required!').test('billingCycleAmount', 'Invalid Value', (amountValue) => {
			// if(/^\d+(\.\d{1,2})?$/.test(amountValue.toString()) || /^[0-9]{1,3}(?:\,[0-9]{3})*(?:\.[0-9]{1,2})?$/.test(amountValue.toString())) {
			// 	return true;
			// }
			// return false;
			return /^\d+([\,]\d+)*([\.]\d+)?$/.test(amountValue.toString())
		}),
		billingCycleCurrency: yup.object().shape({
			name:  yup.string().required('Currency is required.'),
			code: yup.string().required('Currency is required.'),
			currency: yup.string().required('Currency is required.')
		}),
		// support_phone_number: yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Phone number should be in 000-000-0000 format').max(12, 'Phone Number can\'t exceed 10 digits'),
		// }),
		// support_phone_number: reminderCheckboxes.supportContact && yup.string().required('Phone Number is required!').matches(/^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format').max(12, 'Phone Number can\'t exceed 10 digits'),
		paymentTerm: yup.object().nullable().required('Payment Term is required!'),
		
		// 
		// country: yup.object().nullable().required('Currency is required!'),
		// paymentAmount: yup.string().matches(/[1-9]\d*(\.[0-9]\d{0,2})?$/, 'Enter a valid Payment Amount').required('Payment Amount is required!'),
		// paymentAmount: yup.string().required('Payment Amount is required!'),
		// paymentMethodRadioButton: 
	})

	const defaultValues = {
		contractName: '',
		contractDescription: '',
		totalContractValue: '',
		provider: null,
		sla: null,
		renewal: '',
		contractId: '',
		// contractStartDate: '',
		// contractEndDate: '',
		contractStatus: null,
		// department: '',
		contractType: '',
		currentShippingLocation: null,
		// currentBillingLocation: null,
		master: {},
		parentContract: null,
		// notes:'',
		support_phone_number: '',
		poNumber: '',
		cardHolderName: '',
		cardNumber: '',
		billingCycleCurrency: { "name": "United States", "code": "US", "currency": "USD" },
		billingCycleAmount: '',
		paymentTerm: null,
		every: 1
	}

	const { control, formState, handleSubmit, reset, setValue, clearErrors, getValues } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { errors } = formState

	const onSuccess = () => {
		reset(defaultValues)
		resetAccordionsExpand()
	}

	const onCancel = () => {
		reset(defaultValues)
		closeDialog()
	}

	const closeDialog = () => {
		setContractStartDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setFormattedNumber("")

		setReminderCheckBoxes({
			term45: false,
			term30: false,
			supportContact: false,
			email: false
		})
		setFormattedNumber("")
		setNoReminderCheckBox(false)
		setContractEndDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setPoDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
		setCheckForBilling(false)
		setCheckPaymentMethod(false)
		setCardNumber('')
		setCurrentCountry("US")
		setPaymentDate({
			date: null,
			error: false
		})
		setBillingDate({
			date: null,
			error: false
		})
		resetAccordionsExpand()
		setNoOfDays("")
		// setReminderCheckBoxes({
		// 	...reminderCheckboxes,
		// 	supportContact: false
		// })
	}

	const manageFormData = (formData) => {

		if (!editFormState && !formData.master?.value && !(formData.parentContract)) {
			toast.error('Parent Contract required!')
			return
		}
		if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}

		// if (reminderCheckboxes.supportContact === true && formattedNumber === '') {
		// 	toast.error('Contact number is required for text messages')
		// 	return
		// }

		if (!reminderCheckboxes.supportContact && !reminderCheckboxes.term30 && !reminderCheckboxes.term45 && !noReminderCheckBox) {
			toast.error('Please select a reminder option.')
			return
		}

		if (formData.contractDescription.length > 200) {
			toast.error('Description should be maximum 200 characters.')
			return
		}

		if(contractStartDate.error || contractEndDate.error || poDate.error){
			return
		}

		const end = new Date(contractEndDate.date);
		const start = new Date(contractStartDate.date);

		const duration = ((end-start)/(1000*60*60*24))
		if(reminderCheckboxes.term45 && noOfDays>duration){
			toast.error('Reminder no of days should me lesser than contract duration')
			return 
		}
		if(reminderCheckboxes.term45 && !noOfDays){
			toast.error('Reminder no of days is required')
			return
		}
		if(purchaseOrder == 'CreditCard' && cardNumber.length < 4){
			toast.error('Please enter last four digit number of your credit card.')
			return
		}

		if(reminderCheckboxes.term45 && !reminderCheckboxes.supportContact && !reminderCheckboxes.email ){
			toast.error('Please select a text me or email me option.')
			return
		}

		let payload = {
			contract_id: formData.contractId.trim(),
			contract_name: formData.contractName.trim(),
			contract_description: contractDescriptionText.trim(),
			contract_start_date: contractStartDate.date ?? '',
			contract_end_date: contractEndDate.date ?? '',
			contract_status: formData.contractStatus,
			shipping_id: formData.currentShippingLocation.id,
			total_contract_value: formData.totalContractValue.replaceAll(',',''),
			// billing_id: formData.currentBillingLocation.id,
			contract_type: formData.contractType,
			master: formData.master?.value,
			parent_contract_id: formData.master?.value ? 0 : formData.parentContract?.id,
			// notes: formData.notes,
			// department: formData.department,
			provider: formData.provider,
			sla: formData.sla,
			renewal: formData.renewal,
			support_phone_number: reminderCheckboxes.supportContact ? formattedNumber : null,
			// poNumber: formData.poNumber,
			// poDate: poDate.date ?? '',
			// country: formData.country,
			no_reminder: noReminderCheckBox,
			reminder: { term45: reminderCheckboxes.term45, term30: reminderCheckboxes.term30 },
			textMessage: reminderCheckboxes.supportContact,
			paymentAmount: formData.paymentAmount?.replaceAll(',',''),
			paymentTerm: formData.paymentTerm,
			no_of_days: noOfDays,
			notification_type_name: (reminderCheckboxes.supportContact && reminderCheckboxes.email)?'both':reminderCheckboxes.supportContact?'sms':reminderCheckboxes.email?'email':'',
			// paymentAmount: formData.paymentAmount.replaceAll(',',''),
			// paymentTerm: formData.paymentTerm,
			billing: {
				amount: formData.billingCycleAmount.replaceAll(',',''),
				location: formData.billingCycleCurrency.name,
				cycle: String(formData.every) || '',
				month: formData.paymentTerm.name || '',
				date: billingDate.date || '',
				currency: formData.billingCycleCurrency.currency,
				payment_term_id: formData.paymentTerm.id
			},
			payment: {
				value: purchaseOrder,
				po_number:  purchaseOrder === 'purchaseOrder' ? formData.poNumber !== '' ? formData.poNumber : null : null,
				po_date: purchaseOrder === 'purchaseOrder' ? paymentDate.date !== '' ?  paymentDate.date : null: null,
				// card_number:formData.cardNumber !== '' ? formData.cardNumber : null,
				card_number: purchaseOrder === 'CreditCard' ? cardNumber  === '' ? null : cardNumber : null,
				card_holder_name: purchaseOrder === 'CreditCard' ? formData.cardHolderName !== '' ? formData.cardHolderName: null : null,
			},
		}


		if (Boolean(editFormState)) {
			payload.id = editFormState.id
			payload.billing.subscription_billing_id = editFormState.subscription_billing_id !== "No Subscription Billing ID" ? editFormState.subscription_billing_id : null
			payload.payment.subscription_payment_id = editFormState.subscription_payment_id !== "No Subscription Payment ID" ? editFormState.subscription_payment_id : null

			// const { contract_id, ...updatePayload } = payload
			axios.put('/contracts/update-contract-manually', payload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					dispatch(setFromData(null))
					onSuccess()
					closeDialog()
					toast.success('Contract Updated Successfully.')
					props.fetchAllManuallyAddedContract()
					fetchParentContract()
				}

			}).catch(err => {
				toast.error(err.message)
				console.log('212=>', err);
			})
		} else {
			axios.post('/contracts/add-contract-manually', payload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					onSuccess()
					closeDialog()
					toast.success('Contract Added Successfully.')
					props.fetchAllManuallyAddedContract()
					fetchParentContract()
				}

			}).catch(err => {
				toast.error(err.message)
				// console.log('212=>', err);
			})
		}
	}

	const errorData = (error) => {
		console.log('363=>', error)
	}

	/*------------------------------------------------------------------------------------------------*/

	/*-----------------------------------------Service Provider Quick Add---------------------------------------*/
	const addNewServiceProvider = (formData, formattedNumber) => {
		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			address1: formData.address1,
			serviceProvider: formData.serviceProvider,
			phone_number: formattedNumber || '',
			city: formData.city,
			state: formData.state,
			country: formData.country?.name,
			zip: formData.zip,
			url: `www.${formData.url}`
		}

		axios.post('/service-provider/add', payload).then(res => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				setValue('provider', res.data.data)
				setServiceProviderField(false)
				fetchAllSuggestion()
				toast.success('Service Provider added successfully')
			}

		}).catch(err => {
			console.log('504=>', err)
			// toast.error('Something went wrong!')
		})

	}

	function serviceProviderErrorData(error) {
		console.log('serviceProviderErrorData: ', error);
	}
	/*-----------------------------------------------------------------------------------------------*/

	/*-------------------------------------------Shipping Location Quick Add--------------------------------------------*/
	function manageShippingFormData(formData) {
		// console.log('manageShippingFormData: ',formData);
		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			address: formData.shipAddress,
			address2: formData.address2,
			location: formData.shipLocationName,
			city: formData.shipCity,
			state: formData.shipState,
			zip: formData.shipZip,
			country: formData.shipCountry.name,
			note: formData.note,
			location_type_id: formData.locationType.id
		}

		axios.post('/shipping/add', payload).then(response => {
			if (response.status === 201) {
				toast.error(response.data.message)
			} else {
				setValue('currentShippingLocation', response.data.data.new_shipping_location)
				setCreateNewShippingLocation(false)
				toast.success('Location Added Successfully.')
				fetchAllSuggestion()
			}

		}).catch(err => {
			// console.log('234: ',err.response);
			// toast.error('Something went wrong!')
		})

	}

	function shippingFormErrorData(error) {
		console.log('shippingFormErrorData: ', error);
	}
	/*-----------------------------------------------------------------------------------------------*/

	/*----------------------------------------------Other Quick Adds----------------------------------------------*/
	function addNewContractType(event) {
		event.preventDefault()
		// if(!newContractType.trim()){
		// 	// toast.error('Enter a valid name')
		// 	setErrorContractType('Please enter a value!')
		// 	return
		// }

		axios.post('contracts/add-contract-types', {
			contract_type: newContractType.trim()
		}).then((res) => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				// setErrorContractType(null)
				setValue('contractType', newContractType)
				fetchContractType()
				setContractTypeField(false)
				setNewContractType('')
				toast.success('Contract Type added successfully.')
			}
		}).catch((err) => {
			console.log(err.response);
			toast.error(err.response.data.message)
		})
	}

	const addNewSla = (event) => {
		event.preventDefault()
		// if(!newSla.trim()){
		// 	// toast.error('Invalid value!')
		// 	setErrorSla('Please enter a value!')
		// 	return
		// }

		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			sla: newSla.trim()
		}

		axios.post('/service-level-agreement/add', payload).then(res => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				// setErrorSla('Please enter a value!')
				setValue('sla', res.data.data)
				setSlaField(false)
				setNewSla('')
				fetchAllSuggestion()
				toast.success('Service Level Agreement added successfully')
			}
		}).catch(err => {
			// toast.error('Something went wrong!')
		})
	}

	const addNewPaymentTerm = (event) => {
		event.preventDefault()
		// if(!newPaymentTerm.trim()){
		// 	setErrorPaymentTerm('Please enter a value!')
		// 	return
		// }

		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			paymentTerm: newPaymentTerm
		}

		axios.post('/payment-term/add', payload).then(res => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				setValue('paymentTerm', res.data.data)
				setNewPaymentTerm('')
				setPaymentTermField(false)
				fetchAllSuggestion()
				toast.success('Payment Term added successfully')
			}

		}).catch(err => {
			// toast.error('Something went wrong!')
		})
	}

	const addNewContractStatus = (event) => {
		event.preventDefault()
		// if(!newContractStatus.trim()){
		// 	setErrorContractStatus('Please enter a value!')
		// 	return
		// }

		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			contractStatus: newContractStatus
		}

		axios.post('/contract-status/add', payload).then(res => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				setValue('contractStatus', res.data.data)
				setNewContractStatus('')
				setContractStatusField(false)
				fetchAllSuggestion()
				setReminderCheckBoxes({
					term45: false,
					term30: false,
					supportContact: false,
					email: false
				})
				setNoReminderCheckBox(false)
				setFormattedNumber("")
				toast.success('Contract Status added successfully')
			}
		}).catch(err => {
			// toast.error('Something went wrong!')
		})

	}
	/*----------------------------------------------------------------------------------------------------------*/

	/*-----------------------------------------Utility Functions-------------------------------------*/
	// function formatDate(date) {
	// 	if (!date) return
	// 	let yourDate = new Date(date)
	// 	const offset = yourDate.getTimezoneOffset()
	// 	return (new Date(yourDate.getTime() - (offset * 60 * 1000))).toISOString().split('T')[0]
	// }

	const handleChangeNoReminderCheckbox = (event) => {
		setNoReminderCheckBox(event.target.checked)
	}

	const handleChangeReminderCheckBoxes = (event) => {

		

		if ((event.target.name === 'term45' && event.target.checked === false && reminderCheckboxes.term30 === false) || (event.target.name === 'term30' && event.target.checked === false && reminderCheckboxes.term45 === false)) {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				[event.target.name]: event.target.checked,
				supportContact: false
			})
			setValue('support_phone_number', '')
			setNoReminderCheckBox(true)
		}
		else {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				[event.target.name]: event.target.checked
			})
		}

	}

	useEffect(()=>{
		if(noReminderCheckBox){
			setNoOfDays('')
			setValue('support_phone_number', '')
			setFormattedNumber('')
		}
	},[noReminderCheckBox])

	const handleChangeTextMeOption = (event) => {
		event.target.checked && axios.get(`user/get-phone-number`).then(response => {
			if(!response.data.data.phoneNumber.phone){
				setOpenPhoneNoModal(true)
				setPhoneNumberPresent(false)
			}else{
				setPhoneNumberPresent(true)
			}
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
		if (reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true) {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: event.target.checked
			})
		}

		if (event.target.checked === false) {
			setValue('support_phone_number', '')
		}
	}

	const handleChangeEmailOption = (event) => {
		if(reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true){
			setReminderCheckBoxes({
				...reminderCheckboxes,
				email: event.target.checked
			})
		}
	}

	const handleCurrentCountry = (countryCode) => {
		if(countryCode !== null) {
			const current = (countries.filter(country => country.code === countryCode))[0]
			setCurrentCountryCurrency(current)
			setValue('billingCycleCurrency', current)
		} else {
			setCurrentCountryCurrency({ "name": "United States", "code": "US", "currency": "USD" })
			setValue('billingCycleCurrency', { "name": "United States", "code": "US", "currency": "USD" })
		}
		
	}

	const fetchAllSuggestion = () => {
		axios.get(`contracts/get-all-suggestion`).then(response => {
			const { paymentTerm, shipping, contractStatus, sla, serviceProvider } = response.data.data
			setContractStatusMenu(contractStatus)
			setPaymentTermMenu(paymentTerm)
			setServiceProviderMenu(serviceProvider)
			setSlaMenu(sla)
			setShippingLocationMenu(shipping)
		}).catch(err => {
			console.log('err', err);
			//   toast.error('Something went wrong!')
		})
	}

	function fetchContractType() {
		axios.get(`/contracts/get-contract-types`).then(response => {
			// console.log(response.data.data)
			const check = response.data.data.map((item) => item.display_name)
			setContractsMenu(check)
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

	function fetchParentContract() {
		axios.get(`contracts/get-parent-contracts`).then(response => {
			// console.log(response.data.data)
			setParentContractMenu(response.data.data)
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

	// const setReminderData = () => {

	// }
	/*----------------------------------------------------------------------------------------------*/

	/*--------------------------------------UseEffect Declarations----------------------------------*/
	useEffect(() => {
		if (!editFormState) {
			setReminderCheckBoxes({
				term45: false,
				term30: false,
				supportContact: false
			})
			setNoReminderCheckBox(false)
			setFormattedNumber("");
			return
		}
		if(editFormState.subscription_billing !== null) {
			const getCountryDetails = (countries.filter(country => country.currency === editFormState.subscription_billing?.currency))[0]
			handleCurrentCountry(getCountryDetails?.code)	
		} else {
			handleCurrentCountry(null)	
		}
		// handleCurrentCountry(getCountryDetails?.code)
		setValue('contractId', editFormState.contract_number)
		setValue('contractName', editFormState.contract_name),
		setContractDescriptionText(editFormState.contract_description == 'No Contract Description'? '':editFormState.contract_description)
		setValue('totalContractValue', editFormState.total_contract_value === "No Total Contract Value" ? '' : formatPriceFieldOnBlur(String(editFormState.total_contract_value)))

		setContractStartDate((prev) => {
			return {
				...prev,
				date: editFormState.original_info.contract_start_date
			}
		})
		setContractEndDate((prev) => {
			return {
				...prev,
				date: editFormState.original_info.contract_end_date
			}
		})
		setPoDate((prev) => {
			return {
				...prev,
				date: editFormState.original_info.payment_order_date
			}
		})
		setValue('contractStatus', editFormState.contract_status_object)
		setValue('currentShippingLocation', editFormState.location_object)
		setValue('contractType', editFormState?.contract_type_object?.display_name)
		setValue('renewal', editFormState['new/renew'])
		setValue('master', editFormState.master_object)
		setValue('parentContract', editFormState.parent_contract_object)
		setValue('sla', editFormState.service_level_agreement_object)
		setValue('cardHolderName', editFormState.card_holder_name == 'No Card Holder Name'? '' : editFormState.card_holder_name)
		setCardNumber(editFormState.card_number == 'No Card Number'? '' : editFormState.card_number)
		setValue('cardNumber', editFormState.card_number == 'No Card Number'? '' : editFormState.card_number)
		setPurchaseOrder(editFormState.value);
		setValue('poNumber', editFormState.po_number === 'No PO Number' ? '' : editFormState.po_number);
		setValue('paymentTerm', editFormState.payment_term)
		setValue('every', editFormState.every)
		setValue('billingCycleAmount', editFormState.amount === 'No Amount' ? '' : formatPriceFieldOnBlur(String(editFormState.amount)))
		setValue('totalContractValue', editFormState.total_contract_value === "No Total Contract Value" ? '' : formatPriceFieldOnBlur(String(editFormState.total_contract_value)))
		// setValue('amount',  formatPriceFieldOnBlur(String(editFormState.amount)))
		
		// setValue('paymentAmount', formatPriceFieldOnBlur(editFormState.contract_payment_object?.payment_amount))
		// setValue('country', getCountryDetails)
		//setValue('poNumber', editFormState.contract_payment_object?.po_number)
		// setValue('poDate', (editFormState.original_info.payment_order_date))
		// setEveryFielVal(editFormState.billing_cycle_)
		// setValue('month', { name: editFormState.month, id: editFormState.payment_term_id });
		// setValue('po_number', editFormState.po_number == 'No PO Number'?'': editFormState.po_number);
		// setCardNumber(editFormState.card_number == 'No Card Number'?'':editFormState.card_number)
		// setValue('holder_name', editFormState.card_holder_name == 'No Card Holder Name'?'':editFormState.card_holder_name);
		// setPurchaseOrder(editFormState.value || 'purchaseOrder')			
		// const data = countries.find(item => item.currency == editFormState.currency)
		// setCurrentCountry(data?.code)
		// setValue('currency', { "name": data?.name, "code": data?.code, "currency": editFormState?.currency });
		setPaymentDate((prev) => {
			return {
				...prev,
				date: editFormState.po_date_exact
			}
		})
		setBillingDate((prev) => {
			return {
				...prev,
				date: editFormState.billing_cycle_date_exact
			}
		})
		setValue('provider', editFormState.service_provider_object)
		

		const avoidCases = ['No Text Contact Number', '-', '']

		// setValue('support_phone_number', avoidCases.includes(editFormState.text_contact_number) ? '' : editFormState.text_contact_number)
			setFormattedNumber(avoidCases.includes(editFormState.text_contact_number) ? '' : editFormState.text_contact_number)
			setNoOfDays(editFormState.original_info?.remind_me_before || '')
		if (!avoidCases.includes(editFormState.text_contact_number)) {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: true
			})
			switch (editFormState.original_info.reminder) {
				case 'no_reminder':
					setNoReminderCheckBox(true)
					break;
	
				case 'remind_before_45_days_and_30_days':
					setReminderCheckBoxes({
						supportContact: true,
						term30: true,
						term45: true
					})
					break;
	
				case 'remind_before_45_days':
				
				if(editFormState.original_info.notification_type_name === 'both'){
					setReminderCheckBoxes({
						term45: true,
						term30: false,
						supportContact: true,
						email: true
					})
				}else if(editFormState.original_info.notification_type_name === 'sms'){
					setReminderCheckBoxes({
						term45: true,
						term30: false,
						supportContact: true,
						email: false
					})
				}else if(editFormState.original_info.notification_type_name === 'email'){
					setReminderCheckBoxes({
						term45: true,
						term30: false,
						supportContact: false,
						email: true
					})
				}
					break;
	
				case 'remind_before_30_days':
					setReminderCheckBoxes({
						supportContact: true,
						term30: true,
						term45: false
					})
					break;
			}
		} else {
			switch (editFormState.original_info.reminder) {
				case 'no_reminder':
					setNoReminderCheckBox(true)
					break;
	
				case 'remind_before_45_days_and_30_days':
					setReminderCheckBoxes({
						...reminderCheckboxes,
						term30: true,
						term45: true
					})
					break;
	
				case 'remind_before_45_days':
					if(editFormState.original_info.notification_type_name === 'both'){
						setReminderCheckBoxes({
							term45: true,
							term30: false,
							supportContact: true,
							email: true
						})
					}else if(editFormState.original_info.notification_type_name === 'sms'){
						setReminderCheckBoxes({
							term45: true,
							term30: false,
							supportContact: true,
							email: false
						})
					}else if(editFormState.original_info.notification_type_name === 'email'){
						setReminderCheckBoxes({
							term45: true,
							term30: false,
							supportContact: false,
							email: true
						})
					}
					break;
	
				case 'remind_before_30_days':
					setReminderCheckBoxes({
						...reminderCheckboxes,
						term30: true,
						term45: false
					})
					break;
			}

		}

	}, [editFormState])

	function onEditorContentChange (content) {
    setContractDescriptionText(content)
  }

	useEffect(() => {
		fetchAllSuggestion()
		fetchContractType()
		fetchPaymetTerm()
		fetchParentContract()
	}, [])
	function handlPaymentTermCloseDialog(new_payment_term) {
    if (new_payment_term) {
      setValue("paymentTerm", new_payment_term);
    }
    setOpenPaymentTermQuickAddDialog(false);
    fetchAllSuggestion();
  }

	function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}
	/*----------------------------------------------------------------------------------------------*/

	return (
		<div>
			<AddPhoneNumberModal 
				open={openPhoneNoModal}
				handlePhoneNumberCloseModal = {handlePhoneNumberCloseModal}
				setPhoneNumberAdded = {setPhoneNumberAdded}
			/>
			{/* Contract Type */}
			<ContractTypeQuickAdd
				contractTypeField={contractTypeField}
				modal={classes.modal}
				setNewContractType={setNewContractType}
				setContractTypeField={setContractTypeField}
				addNewContractType={addNewContractType}
				newContractType={newContractType}
			/>

			{/* Service Level Agreement */}
			<SLAQuickAdd
				slaField={slaField}
				modal={classes.modal}
				setNewSla={setNewSla}
				setSlaField={setSlaField}
				addNewSla={addNewSla}
				newSla={newSla}
			/>

			{/* Contract Status */}
			<ContractStatusQuickAdd
				contractStatusField={contractStatusField}
				modal={classes.modal}
				setContractStatusField={setContractStatusField}
				setNewContractStatus={setNewContractStatus}
				addNewContractStatus={addNewContractStatus}
				newContractStatus={newContractStatus}
			/>

			{/* Payment Term */}
			<QuickAddPaymentTerm
				open={openPaymentTermQuickAddDialog}
				handleCloseDialog={handlPaymentTermCloseDialog}
			/>

			{/* <PaymentTermQuickAdd
				paymentTermField={paymentTermField}
				modal={classes.modal}
				setNewPaymentTerm={setNewPaymentTerm}
				setPaymentTermField={setPaymentTermField}
				addNewPaymentTerm={addNewPaymentTerm}
				newPaymentTerm={newPaymentTerm}
			/> */}

			{/* Service Provider */}
			<ServiceProviderQuickAdd
				serviceProviderField={serviceProviderField}
				classes={classes}
				setServiceProviderField={setServiceProviderField}
				serviceProviderErrorData={serviceProviderErrorData}
				addNewServiceProvider={addNewServiceProvider}
			/>

			{/* Shipping Location */}
			<ShippingLocationQuickAdd
				createNewShippingLocation={createNewShippingLocation}
				modal={classes.modal}
				setCreateNewShippingLocation={setCreateNewShippingLocation}
				manageShippingFormData={manageShippingFormData}
				shippingFormErrorData={shippingFormErrorData}
			/>

			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.customDrawerPaper }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<DialogTitle>{Boolean(editFormState) ? 'Update Contract' : 'Add Contract'}</DialogTitle>
					<IconButton style={{ marginRight: '15px' }} onClick={onCancel}>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={handleSubmit(manageFormData, errorData)} >

					{/*--------------------Contract Description----------------------- */}
					<Accordion defaultExpanded={true} className={classes.subSection}>
						<Typography style={{ fontSize: '16px' }}>	Required Information *</Typography>
						<Divider style={{ marginBottom: '10px' }} />
						{/* <AccordionSummary
							// expandIcon={<ExpandMore />}
							style={{ pointerEvents: 'none' }}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Contract Description</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> 
							</div>
						</AccordionSummary> */}

						<AccordionDetails style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="contractId"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													label='Contract Number'
													fullWidth
													inputProps={{ readOnly: false }}
													error={!!errors.contractId}
													helperText={errors?.contractId?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}
													disabled={Boolean(editFormState)}
												/>
											</>
										)}
									/>
								</div>

								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="contractName"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													label='Contract'
													fullWidth
													error={!!errors.contractName}
													helperText={errors?.contractName?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center'}}>
							<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Editor
                    editorContent={contractDescriptionText}
                    onEditorContentChange={onEditorContentChange}
										placeholderText={'Contract Description'}
                  />        
                </div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<div className='cstm-date' style={{ marginRight: '10px', width: '100%' }}>
									{/* <Controller
										name="contractStartDate"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													label="Start Date"
													type='date'
													InputLabelProps={{ shrink: true }}
													onChange={(event)=>{
														field.onChange(event.target.value)
														setStartDate(event.target.value)
													}}
													fullWidth
													error={!!errors.contractStartDate}
													helperText={errors?.contractStartDate?.message}
													FormHelperTextProps={{
														style: { 
															margin : 0, 
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													inputProps={{ max: `${endDate}` }}
													style={{ width: '100%', backgroundColor: '#fff' }}
												/>												
											</>
										)}
									/> */}
									{/* <CustomDatePicker
										state={contractStartDate}
										setState={setContractStartDate}
										required={true}
										label='Contract Start Date*'
										maxDate={contractEndDate.date}
										condition='Contract End Date'									
									/> */}
									<CustomDatePickerV2
										state={contractStartDate}
										setState={setContractStartDate}
										required={true}
										label='Contract Start Date*'
										maxDate={contractEndDate.date}
										condition='Contract End Date'									
									/>
								</div>

								<div className='cstm-date' style={{ width: '100%' }}>
									{/* <Controller
										name="contractEndDate"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													label="End Date"
													type='date'
													InputLabelProps={{ shrink: true }}
													fullWidth
													onChange={(event) => {
														field.onChange(event.target.value)
														setEndDate(event.target.value)
													}}
													error={!!errors.contractEndDate}
													helperText={errors?.contractEndDate?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													inputProps={{ min: `${startDate}` }}
													style={{ width: '100%', backgroundColor: '#fff' }}
												/>
											</>
										)}
									/> */}
									{/* <CustomDatePicker
										state={contractEndDate}
										setState={setContractEndDate}
										required={true}	
										label='Contract End Date*'
										minDate={contractStartDate.date}
										condition='Contract Start Date'											
									/> */}
									<CustomDatePickerV2
										state={contractEndDate}
										setState={setContractEndDate}
										required={true}
										label='Contract End Date*'
										minDate={contractStartDate.date}
										condition='Contract Start Date'									
									/>
								</div>

							</div>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="contractStatus"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={contractStatusMenu}
													disablePortal
													onChange={(event, value) => { field.onChange(value) }}
													PaperComponent={({ children }) => (
														<Paper className="autocomplete_holder autocomplete_paper_holder">
															{children}
															{createCStatusPermission && 
																<div className='p-16'>
																	<Button
																		className='secondary_btn w-full'
																		variant="outlined"
																		color="primary"
																		onMouseDown={() => setContractStatusField(true)}
																	>
																		Add Contract Status
																	</Button>
																</div>
															}
														</Paper>
													)}
													getOptionLabel={option => option.contract_status_name ?? ''}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	required
																	{...params}
																	name="contractStatus"
																	label='Contract Status'
																	variant='outlined'
																	fullWidth
																	error={!!error}
																	helperText={error?.message}
																	inputRef={ref}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="currentShippingLocation"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={shippingLocationMenu}
													disablePortal
													onChange={(event, value) => { field.onChange(value) }}
													PaperComponent={({ children }) => (
														<Paper className="autocomplete_holder autocomplete_paper_holder">
															{children}
															{createCLocationPermission && <Button
																style={{ width: '100%' }}
																variant="outlined"
																color="primary"
																onMouseDown={() => setCreateNewShippingLocation(true)}
															>
																Add Location
															</Button>}
														</Paper>
													)}
													getOptionLabel={option => {
														return generateProperLocationString({
															location_name: option?.location_name ?? '',
															address1: option?.address1 ?? '',
															address2: option?.address2 ?? '',
															city: option?.city ?? '',
															state: option?.state ?? '',
															country: option?.country ?? ''
														})
														// `${option.location_name ?? ''} - ${option.address1}, ${option.city}, ${option.state}, ${option.country}`
													}}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	required
																	{...params}
																	name="currentShippingLocation"
																	label='Location'
																	variant='outlined'
																	fullWidth
																	error={!!error}
																	helperText={error?.message}
																	inputRef={ref}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="totalContractValue"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextField
												inputRef={totalContractValueRef}
												{...field}
												variant='outlined'
												label='Total Contract Value'
												fullWidth
												required
												autocomplete="off"
												error={!!errors.totalContractValue}
												helperText={errors?.totalContractValue?.message}
												onBlur={()=>{
													setValue('totalContractValue', (!isNaN(getValues('totalContractValue')) ? (clearErrors('totalContractValue', ''), formatPriceFieldOnBlur(getValues('totalContractValue'))) : getValues('totalContractValue'))  )																		
												}}
												// disabled = {disableField}
												// InputProps={{
												// 	startAdornment:
												// 		<InputAdornment style={{ color: "grey", marginRight: '0' }} disableTypography position="start">
												// 			www.</InputAdornment>,
												// }}
												FormHelperTextProps={{
													style: { marginLeft: 0 }
												}}
												style={{ backgroundColor: '#fff' }}
											/>
										</>
									)}
								/>
							</div>

						</AccordionDetails>
					</Accordion>

					{/*---------------Contract Type------------------- */}
					<Accordion className={classes.subSection} expanded={contractTypeAccordionExpand}>
						<AccordionSummary onClick={manageContractTypeAccordion} expandIcon={<ExpandMore />}>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Contract Type</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>

						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

							<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
								<div style={{ display: 'flex', marginBottom: '10px', width: '100%' }}>
									{/* {contractTypeField == 'dropdown' && */}
									<>
										<Controller
											name="contractType"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field }, fieldState: { error } }) => (
												<>
													<Autocomplete
														{...field}
														style={{ width: '100%' }}
														options={contractsMenu}
														disablePortal
														// getOptionLabel={option => option.display_name }
														onChange={(event, value) => field.onChange(value)}
														PaperComponent={({ children }) => (
															<Paper className="autocomplete_holder autocomplete_paper_holder">
																{children}
																{createCTypePermission && 
																	<div className='p-16'>
																		<Button
																			className='secondary_btn w-full'
																			variant="outlined"
																			color="primary"
																			onMouseDown={() => setContractTypeField(true)}
																		>
																			Add Contract Type
																		</Button>
																	</div>
																}
															</Paper>
														)}
														renderInput={params => {
															return (
																<div ref={params.InputProps.ref}>
																	<TextField
																		required
																		{...params}
																		name="contractType"
																		label='Contract Type'
																		variant='outlined'
																		fullWidth
																		error={!!error}
																		helperText={error?.message}
																		inputRef={contractTypeInputRef}
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#f4f4f4',
																				width: '100%',
																				paddingTop: '2px'
																			}
																		}}
																		style={{ width: '100%', backgroundColor: '#fff' }}
																	/>
																</div>
															)
														}}
													/>
													{/* <div style={{ width: '84px', padding: '10px', border: '1px solid #aaa', borderLeft: '0', borderRadius: '0 4px 4px 0' ,height:'53px'}}>
														<Button 
															variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
															onClick={()=>setContractTypeField(true)}
														>New</Button>
													</div> */}
												</>
											)}
										/>
									</>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="renewal"
									className="mb-16"
									control={control}
									render={({ field }) => {
										return (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													select
													label="New/Renew"
													fullWidth
													error={!!errors.renewal}
													helperText={errors?.renewal?.message}
													inputRef={renewalInputRef}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ width: '100%', backgroundColor: '#fff' }}
												>
													{['New', 'Renew'].map((option) => (
														<MenuItem key={option} value={option}>
															{option}
														</MenuItem>
													))}
												</TextField>
											</>
										)
									}}
								/>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="master"
									className="mb-16"
									control={control}
									render={({ field: { ref, ...field }, fieldState: { error } }) => {
										return (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
													disablePortal
													disabled={!!editFormState}
													getOptionLabel={option => option.label ?? ''}
													onChange={(event, value) => {
														setChildContract(!value.value)
														field.onChange(value)
													}}
													PaperComponent={({ children }) => (
														<Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
													)}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	required
																	{...params}
																	name="master"
																	label='Master'
																	variant='outlined'
																	fullWidth
																	error={!!error}
																	helperText={error?.message}
																	inputRef={masterInputRef}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	// disabled={!!editFormState}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)
									}}
								/>
							</div>

							{(childContract || editFormState?.master === 'No') &&
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<Controller
										name="parentContract"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={parentContractMenu}
													disablePortal
													getOptionLabel={option => `${option.entity_external_platform_id ?? ''}-${option.contract_name}`}
													onChange={(event, value) => field.onChange(value)}
													PaperComponent={({ children }) => (
														<Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
													)}
													disabled={!!editFormState}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	name="parentContract"
																	label='Parent Contract'
																	variant='outlined'
																	fullWidth
																	error={!!error}
																	helperText={error?.message}
																	inputRef={ref}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	disabled={!!editFormState}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							}
						</AccordionDetails>
					</Accordion>

					{/*---------------Service Level Agreement------------------- */}
					<Accordion expanded={slaAccordionExpand} className={classes.subSection}>
						<AccordionSummary
							onClick={manageSLAAccordion}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Service Level Agreement</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>

						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="sla"
									className="mb-16"
									control={control}
									render={({ field: { ref, ...field }, fieldState: { error } }) => (
										<>
											<Autocomplete
												{...field}
												style={{ width: '100%' }}
												options={slaMenu}
												disablePortal
												getOptionLabel={option => option.sla_name ?? ''}
												onChange={(event, value) => field.onChange(value)}
												PaperComponent={({ children }) => (
													<Paper className="autocomplete_holder autocomplete_paper_holder">
														{children}
														{createSLAPermission && <Button
															style={{ width: '100%' }}
															variant="outlined"
															color="primary"
															onMouseDown={() => setSlaField(true)}
														>
															Add Service Level Agreement
														</Button>}
													</Paper>
												)}
												renderInput={params => {
													return (
														<div ref={params.InputProps.ref}>
															<TextField
																required
																{...params}
																name="sla"
																label='Service Level Agreement'
																variant='outlined'
																fullWidth
																error={!!error}
																helperText={error?.message}
																inputRef={slaInputRef}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
																style={{ width: '100%', backgroundColor: '#fff' }}

															/>
														</div>
													)
												}}
											/>
											{/* <div style={{ width: '84px', padding: '10px', border: '1px solid #aaa', borderLeft: '0', borderRadius: '0 4px 4px 0' ,height:'53px'}}>
												<Button 
													variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
													onClick={()=>setContractTypeField(true)}
												>New</Button>
											</div> */}
										</>
									)}
								/>
							</div>
						</AccordionDetails>
					</Accordion>


					{/*----------------------billing cycle------------------- */}
					<Accordion expanded={checkForBilling} className={classes.subSection}>
						<AccordionSummary onClick={summeryClickSubBilling}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Billing Cycle</Typography>
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div className='w-full'>
								<div style={{ display: 'flex', justifyContent: 'center', }}>
									<div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
										<div style={{ marginBottom: '10px', marginRight: '10px' }}>
											<Controller
												name="billingCycleAmount"
												className="mb-16"
												control={control}
												render={({ field }) => {
													return (
														<>
															<TextField
																inputRef={billingAmountRef}
																{...field}
																variant='outlined'
																label='Amount'
																fullWidth
																autocomplete="off"
																required
																FormHelperTextProps={{
																	style: { marginLeft: 0 }
																}}
																onBlur={()=>{	
																	setValue('billingCycleAmount', (!isNaN(getValues('billingCycleAmount')) ?   (clearErrors('billingCycleAmount', ''), formatPriceFieldOnBlur(getValues('billingCycleAmount'))) : getValues('billingCycleAmount'))  )
																}}
																// onKeyPress={(event) => {
																// 	if (!/[0-9/-]+/.test(event.key)) {
																// 		event.preventDefault();
																// 	}
																// }}
																error={!!errors.billingCycleAmount}
																helperText={errors?.billingCycleAmount?.message}
																onChange={(event) => { field.onChange(event.target.value ?? '')}}
																style={{ width: '100%', backgroundColor: '#fff' }}
																InputProps={{
																	startAdornment:
																		<InputAdornment>
																			<Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
																		</InputAdornment>
																}}
															/>
														</>
													)
												}}
											/>
										</div>
										<div style={{ display: 'flex', marginBottom: '10px', width: '50%' }}>
											<Controller
												name="billingCycleCurrency"
												className="mb-16"
												control={control}
												render={({ field: { ref, ...field }, fieldState: { error } }) => (
													<>
														<Autocomplete
															{...field}
															style={{ width: '100%' }}
															options={countries}
															disablePortal
															getOptionLabel={option => option.currency ?? ''}
															renderOption={option =>
																<>
																	<InputAdornment style={{ marginRight: '15px' }}>
																		<img
																			loading="lazy"
																			width="20"
																			src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
																			srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
																			alt=""
																		/>
																	</InputAdornment>
																	{option.currency}
																</>}
															onChange={(event, value) => {
																field.onChange(value)
																if (value) handleCurrentCountry(value.code)
															}}
															PaperComponent={({ children }) => (
																<Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
															)}
															renderInput={params => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			{...params}
																			label='Currency'
																			variant='outlined'
																			fullWidth
																			// required
																			inputRef={ref}
																			FormHelperTextProps={{
																				style: { marginLeft: 0 }
																			}}
																			style={{ width: '100%', backgroundColor: '#fff' }}
																			InputProps={{
																				startAdornment:
																					<InputAdornment style={{ marginRight: '15px' }}>
																						<img
																							loading="lazy"
																							width="20"
																							src={`https://flagcdn.com/w20/${currentCountryCurrency?.code.toLowerCase()}.png`}
																							srcSet={`https://flagcdn.com/w40/${currentCountryCurrency?.code.toLowerCase()}.png 2x`}
																							alt=""
																						/>
																					</InputAdornment>
																			}}
																		/>
																	</div>
																)
															}}
														/>
													</>
												)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='w-full'>
								<div style={{ display: 'flex', justifyContent: 'center', }}>
									<div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div style={{ marginRight: '10px', marginBottom: '10px', }}>
												<span color="white" variant="contained"
													style={{
														backgroundColor: "#f5f5f5",
														fontSize: '16px',
														color: 'gray',
														borderRadius: '4px'
													}}>
													Every*:
												</span>
											</div>

											<div style={{ marginRight: '10px', marginBottom: '10px', width: '70px' }}>
												<Controller
													name="every"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<TextField
																{...field}
																variant='outlined'
																label=""
																inputRef={billingEveryRef}
																type='number'
																fullWidth
																min="0"
																// onChange={(e) => {
																// 	let val = parseInt(e.target.value, 10);
																// 	if (isNaN(val)) {
																// 	  setEveryFielVal('');
																// 	} else {
																// 	  // is A Number
																// 	  val = val >= 0 ? val : 0;
																// 	  setEveryFielVal(val);
																// 	}
																//   }}
																// value = {everyFielVal}
																InputLabelProps={{ shrink: true }}
																required
																style={{ width: '100%', backgroundColor: '#fff' }}
																error={!!errors.every}
																helperText={errors?.every?.message}
																// formhelpertextprops={{
																// 	style: { marginLeft: 0 }
																// }}
															/>
														</>
													)}
												/>
											</div>
										</div>
										<div style={{ marginRight: '10px', marginBottom: '10px', width: '500px' }}>
											<Controller
												name="paymentTerm"
												className="mb-16"
												control={control}
												render={({ field: { ref, ...field }, fieldState: { error } }) => (
													<>
														<Autocomplete
															{...field}
															style={{ width: '100%' }}
															// options={[{id: 1, name: '1 Month'}, {id: 1, name: '3 Months'}, {id: 1, name: '6 Months'}, {id: 1, name: '12 Months'}]}
															options={paymentTermMenu}
															disablePortal
															getOptionLabel={option => option.display_name ?? ''}
															onChange={(event, value) => field.onChange(value)}
															PaperComponent={({ children }) => (
																<Paper className="autocomplete_holder autocomplete_paper_holder">
																	{children}
																	{createCPaymentTermPermission && <Button
																		style={{ width: '100%' }}
																		variant="outlined"
																		color="primary"
																		onMouseDown={() => setOpenPaymentTermQuickAddDialog(true)}
																	>
																		Add Payment Term
																	</Button>}
																</Paper>
															)}
															renderInput={params => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			required
																			{...params}
																			inputRef={paymentTermInputRef}
																			name="paymentTerm"
																			label='Payment Term'
																			variant='outlined'
																			fullWidth
																			error={!!error}
																			helperText={error?.message}
																			// inputRef={ref}
																			FormHelperTextProps={{
																				style: {
																					margin: 0,
																					backgroundColor: '#f4f4f4',
																					width: '100%',
																					paddingTop: '2px'
																				}
																			}}
																			style={{ width: '100%', backgroundColor: '#fff' }}
																		/>
																	</div>
																)
															}}
														/>
													</>
												)}
											/>
										</div>
										<div style={{ marginRight: '10px', marginBottom: '10px', width: '100%' }}>
											{/* <Controller
												name="renewal_date"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<TextField
															{...field}
															variant='outlined'
															label="Date"
															type='date'
															inputRef={billing_date_ref}
															InputLabelProps={{ shrink: true }}
															fullWidth
															required
															onChange={(event) => {
																field.onChange(event.target.value)
																setEndDate(event.target.value)
															}}

															FormHelperTextProps={{
																style: { marginLeft: 0 }
															}}
															inputProps={{ min: `${startDate}` }}
															style={{ width: '100%', backgroundColor: '#fff' }}
														/>
													</>
												)}
											/> */}
											{/* <CustomDatePicker
												state={billingDate}
												setState={setBillingDate}
												required={true}	
												label='Date*'										
											/> */}
											<CustomDatePickerV2
												state={billingDate}
												setState={setBillingDate}
												required={true}	
												label='Date*'										
											/>
										</div>
									</div>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>


					{/*---------------Payment Method------------------- */}
					<Accordion expanded={checkPaymentMethod} className={classes.subSection}>
						<AccordionSummary onClick={summeryClickSubPayment}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Payment Method</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', marginBottom: '10px' }}>
								<RadioGroup aria-label="quiz" name="quiz" value={purchaseOrder} onChange={handleRadioChange}>
									<div style={{ display: 'flex' }}>
										<div style={{ marginRight: '20px' }}>
											<FormControlLabel value="CreditCard" control={<Radio color="primary" />} label="Credit Card" />
										</div>
										<div style={{ marginLeft: '20px' }}>
											<FormControlLabel value="purchaseOrder" control={<Radio color="primary" />} label="Purchase Order" />
										</div>
									</div>
								</RadioGroup>
							</div>
							<div>
							{purchaseOrder == 'purchaseOrder' && <div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="poNumber"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='PO Number'
													fullWidth
													className='input-height'
													required
													inputRef={poNumberRef}
													inputProps={{ readOnly: false }}
													error={!!errors.poNumber}
													helperText={errors?.poNumber?.message}
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									{/* <Controller
										name="po_date"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label="PO Date"
													type='date'
													inputRef={po_date_ref}
													required
													InputLabelProps={{ shrink: true }}
													onChange={(event) => {
														field.onChange(event.target.value)
														setStartDate(event.target.value)
													}}
													fullWidth
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													inputProps={{ max: `${endDate}` }}
													style={{ width: '100%', backgroundColor: '#fff' }}
												/>
											</>
										)}
									/> */}
									{/* <CustomDatePicker
										state={paymentDate}
										setState={setPaymentDate}
										required={true}	
										label='PO Date*'										
									/> */}
									<CustomDatePickerV2
										state={paymentDate}
										setState={setPaymentDate}
										required={ purchaseOrder === 'purchaseOrder' ? true : false}	
										label='PO Date*'										
									/>
								</div>
							</div>}
							{purchaseOrder == 'CreditCard' && <div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="cardHolderName"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label="Card Holder's Name"
													fullWidth
													required={ purchaseOrder === 'CreditCard' ? true : false}	
													inputRef={cardHolderNameRef}
													inputProps={{ readOnly: false }}
													style={{ backgroundColor: '#fff' }}
													error={!!errors.cardHolderName}
													helperText={errors?.cardHolderName?.message}
												/>
											</>
										)}
									/>
								</div>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="cardNumber"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													required={ purchaseOrder === 'CreditCard' ? true : false}	
													variant='outlined'
													inputRef={cardNumberRef}
													value={cardNumber}
													onKeyPress={(event) => {
														if (!/[0-9/-]+/.test(event.key)) {
															event.preventDefault();
														}
													}}
													label="Last 4 Digits of Credit Card"
													onChange={(e)=>{
														if(e.target.value.length > 4){
															return
														}
														setCardNumber(e.target.value)
													}}
													fullWidth
													inputProps={{ readOnly: false }}
													style={{ backgroundColor: '#fff' }}
													error={!!errors.cardHolderName}
													helperText={errors?.cardHolderName?.message}
												/>
											</>
										)}
									/>
								</div>
							</div>}
							</div>
						</AccordionDetails>
					</Accordion>


					{/*---------------Provider------------------- */}
					<Accordion expanded={serviceProviderAccordionExpand} className={classes.subSection}>
						<AccordionSummary
							onClick={manageServiceProviderAccordion}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Service Provider</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>

						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="provider"
									className="mb-16"
									control={control}
									render={({ field: { ref, ...field }, fieldState: { error } }) => (
										<>
											<Autocomplete
												{...field}
												style={{ width: '100%' }}
												options={serviceProviderMenu}
												disablePortal
												getOptionLabel={option => option.service_provider_name ?? ''}
												onChange={(event, value) => field.onChange(value)}
												PaperComponent={({ children }) => (
													<Paper className="autocomplete_holder autocomplete_paper_holder">
														{children}
														{createCServiceProviderPermission && <Button
															style={{ width: '100%' }}
															variant="outlined"
															color="primary"
															onMouseDown={() => setServiceProviderField(true)}
														>
															Add Service Provider
														</Button>}
													</Paper>
												)}
												renderInput={params => {
													return (
														<div ref={params.InputProps.ref}>
															<TextField
																required
																{...params}
																name="provider"
																label='Service Provider'
																variant='outlined'
																fullWidth
																error={!!error}
																helperText={error?.message}
																inputRef={serviceProviderInputRef}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		// paddingTop: '2px'
																	}
																}}
																style={{ width: '100%', backgroundColor: '#fff' }}
															/>
														</div>
													)
												}}
											/>
											{/* <div style={{ width: '84px', padding: '10px', border: '1px solid #aaa', borderLeft: '0', borderRadius: '0 4px 4px 0' ,height:'53px'}}>
												<Button 
													variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
													onClick={()=>setContractTypeField(true)}
												>New</Button>
											</div> */}
										</>
									)}
								/>
							</div>
						</AccordionDetails>
					</Accordion>

					{/*---------------Reminder------------------- */}
					<Accordion className={classes.subSection}>
						<AccordionSummary
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Reminder</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px' }}>
								<Typography className='status_text'>Dalos will notify you automatically before contract expiration.</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }} className='normal_txt_holder'>
								<Checkbox
									checked={noReminderCheckBox}
									onChange={handleChangeNoReminderCheckbox}
									disabled={reminderCheckboxes.supportContact === true || reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true}
								/>
								<Typography className='px-8'>Do not remind me</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }} className='normal_txt_holder'>
								<Checkbox
									disabled={noReminderCheckBox === true}
									name={'term45'}
									checked={reminderCheckboxes.term45}
									onChange={handleChangeReminderCheckBoxes}
								/>
								<Typography className='px-8'>Remind me before        
								<Controller
										name="no_of_days"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='standard'
													label=''
													fullWidth
													value={noOfDays}
													onChange={(e)=>{
														setNoOfDays(e.target.value)
													}}
													onKeyPress={(event) => {
														if (!/[0-9/-]+/.test(event.key)) {
															event.preventDefault();
														}
													}}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px',
															fontSize: '17px'
														}
													}}
													disabled={!reminderCheckboxes.term45}
													style={{ width: '60px', backgroundColor: '#fff', padding: '0 5px' }}
												/>
											</>
										)}
									/>
								 no of days of contract expiry</Typography>
							</div>

							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }} className='normal_txt_holder'>
								<Checkbox
									style={{padding: 0}}
									disabled={noReminderCheckBox === true || (reminderCheckboxes.term45 === false && reminderCheckboxes.term30 === false )}

									name={'supportContact'}
									checked={reminderCheckboxes.supportContact}
									onChange={handleChangeTextMeOption}
								/>
								<Typography className='px-8'>Text Me</Typography>
								<Checkbox
									disabled={noReminderCheckBox === true || (reminderCheckboxes.term45 === false && reminderCheckboxes.term30 === false )}
									name={'supportContact'}
									checked={reminderCheckboxes.email}
									onChange={handleChangeEmailOption}
								/>
								<Typography className='px-8'>Email Me</Typography>
							</div>
						</AccordionDetails>
					</Accordion>
					<div style={{ padding: 0, margin: 20, display: 'flex', justifyContent: 'left' }}>
						<Button onClick={onSubmitAccordionExpand} className={classes.endButtons} style={{ marginRight: '10px' }} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{Boolean(editFormState) ? 'Update' : 'Add'}</Button>
						<Button className={classes.endButtons} variant='outlined' color='primary' onClick={onCancel}>Cancel</Button>
					</div>
				</form>
			</Drawer>
		</div>
	)
}

export default AddContractFormDialog
