import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles, Modal, TextField } from '@material-ui/core';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { generateRandomNumberString } from '@fuse/utils/stringOperations'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function QuickAddPaymentTerm(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const schema = yup.object({
       
    })

    const defaultValues = {
        payment_term: '',
        description: '',
		term_duration: ''
    }

    const { 
        control, 
        formState:{errors}, 
        handleSubmit, 
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    function manageFormData(formData){
        // console.log('manageData: ',formData);
        const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			paymentTerm: formData.payment_term,
            description: formData.description,
			term_duration: formData.term_duration,
		}

		axios.post('/payment-term/add', payload).then(res => {
			if(res.status === 201){
				toast.error(res.data.message)
                return
			}
            close(res.data.data)
            toast.success('Payment Term added successfully')

		}).catch(err => {
            console.log('error: ',err);
			toast.error(err.response.data.message)
		})

    }

    function formErrorData(error){
        console.log('formErrorData: ',error);
    }

    function close(new_payment_term=null){
        reset(defaultValues)
        props.handleCloseDialog(new_payment_term)
    }

    return (
        <Modal
            open={props.open}
            // onClose={()=>close()}
            className={classes.modal}
        >
            <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Create Payment Term</h1>
                    <IconButton onClick={()=>close()}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <form onSubmit={handleSubmit(manageFormData,formErrorData)}>
                    <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>
                        
                        <Controller
                            name="payment_term"
                            className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Payment Term'
                                        required
                                        style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                        <div style={{ borderRadius:9, padding: '0', width:'100%', marginBottom: '10px'}}>
								<Controller 
									name='term_duration'
									control={control}
									render={({field}) => (
										<TextField
											{...field}
											variant='outlined'
											required
											label="Term Duration(months)"
											fullWidth
											onKeyPress={(event) => {
												if (!/[0-9]+/.test(event.key)) {
													event.preventDefault();
												}
											}}
											style={{ width: '100%', backgroundColor: '#fff' }}
											// error={!!errors.payment_term}
											// helperText={errors?.payment_term?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
										/>
									)}
								/>
							
							</div>

							<div style={{ borderRadius:9, padding: '0', width:'100%'}}>
								<Controller 
									name='description'
									control={control}
									render={({field}) => (
										<TextField
											{...field}
											variant='outlined'
											label="Description"
											fullWidth
											multiline
											rows={8}
											inputProps={{ className: classes.textarea }}
											style={{ width: '100%', backgroundColor: '#fff' }}
											error={!!errors.description}
											helperText={errors?.description?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
										/>
									)}
								/>
							
							</div>
                    </div>
                    <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
                        <Button
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                            type='submit'
                        >Add</Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={()=>close()}
                        >Cancel</Button>
                    </div>
                </form>
            </div>

        </Modal>
    )
}

export default QuickAddPaymentTerm
