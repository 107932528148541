import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();

	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	} else {
		return (
			<>
				<FusePageCarded
					classes={{
						root: classes.layoutRoot,
						toolbar: 'p-0',
						topBg:classes.topBg,
						contentCard:classes.contentCard
					}}
					content={
						<>
							<div className={selectedTab !== 0 ? 'hidden' : ''}>
								{
									loading && <FuseLoading />
								}
								{
									!loading && <SalesOrderTable
									/>
								}
							</div>
						</>
					}
					innerScroll
				/>
			</>
		)
	}
}

export default withReducer('UserAuditLogApp', reducer)(CardedFullWidth2TabbedSample);
