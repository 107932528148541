import React, {useEffect,useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
    row:{
        // border: '2px solid black',        
        width: '100%',
        // marginBottom:"10px",
        display: 'flex',
        paddingBottom:"10px",
    },
    column:{
        // border: '2px solid black',
        width: '100%',
        textAlign: 'left'
    }
});

const InfoTab = () => {
    const classes = useStyles();
    const [userDetails, setUserDetails] = useState(null);
    const [role, setRole] = useState([]);
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const openState = useSelector((state) => state.usersApp.formViewDialog)
    useEffect(()=>{
        // console.log("openState.user_data?.client",openState.user_data)
        setUserDetails(openState.user_data?.client)
        setRole(openState.user_data?.role_location)
    },[openState])

    // if(!userDetails){
    //     return(<>loading...</>)
    // }
    return (        
        <div style={{height: 290, width: '100%',textAlign:'center',display: 'flex',flexDirection: 'column'}}>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography variant="h6" style={{color:'#3A4252'}}>User Information</Typography>
                
                </div>
                <div className={classes.column} style={{textAlign:"right"}}>
                    {/* <Button variant="outlined"><Icon><span class="material-symbols-outlined" style={{fontSize:"17px"}}>
                    edit
                    </span></Icon> Edit</Button> */}
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">First Name</Typography>
                    <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.first_name || 'N/A'}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Last Name</Typography>
                    <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.last_name || 'N/A'}</Typography>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Email Address</Typography>
                    <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.email || 'N/A'}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Phone</Typography>
                    <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.phone || 'N/A'}</Typography>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Title</Typography>
                    <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.title || 'N/A'}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Department</Typography>
                    <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.department?.department_name || 'N/A'}</Typography>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Start Date</Typography>
                    <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.start_date ? dateReformatTo_mmm_dd_yyyy(userDetails?.start_date) : 'N/A'}</Typography>
                </div>
                {/* <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">End Date</Typography>
                    <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.end_date ? dateReformatTo_mmm_dd_yyyy(userDetails?.end_date) : 'N/A'}</Typography>
                </div> */}
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Role</Typography>
                    {role?.length == 0 && <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">N/A</Typography>}
                    {role?.map(item=>{
                        return (<>
                            <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{item.name}</Typography>
                        </>)
                    })}
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Location</Typography>
                    {role?.length == 0 && <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">N/A</Typography>}
                    {role?.map(item=>{
                        return(<>
                            <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{item.location_str}</Typography>
                        </>)
                    })}
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Employee Number</Typography>
                    <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.emp_no ?userDetails?.emp_no : 'N/A'}</Typography>
                </div>
            </div>
        </div>
    )
}

export default InfoTab
