import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { setfilterQuery } from '../store/filterParamsReducer';
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { useDebounce } from '@fuse/hooks'


const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const { hasPermission } = usePermission()
	const createModelAttributePermission = hasPermission('add-model_attribute')
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const addReportPermission = hasPermission('add-report')


	return (
		<div className="flex flex-1 w-full items-center justify-between">
			{/* Main Heading */}
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM12 3.311L4.5 7.65311V16.3469L12 20.689L19.5 16.3469V7.65311L12 3.311ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Model Attributes List
				</Typography>
			</div>

			<div style={{display:'flex'}}>
			{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}
				{/* Add Custom Attributes*/}
				{createModelAttributePermission && <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'250px', marginRight: '10px'}}
						onClick = {()=>dispatch(openFormViewerDialog())}
						startIcon={
						<Icon fontSize="small" title="Add Model Dynamic Attribute">
							add
						</Icon>}>
							Add Model Dynamic Attribute
					</Button>
				</div>}
				{/* Save view */}
				{/* <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight:"20px"}}
						onClick = {()=>dispatch(openReportViewerDialog())}
						startIcon={
						<Icon fontSize="large" title="Save View">
							save
						</Icon>}>
							Save View
					</Button>
				</div> */}
				{/* Search */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px"}}
						>
							<TextField
								placeholder="Search Attributes"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>
		</div>
	)
}

export default DynamicFieldsHeader;
