const initialState = {
    open: false,
    data: null,
    count: null,
    openModal: false
}

const actionTypes = {
    OPEN_FORM_VIEWER_DIALOG: 'OPEN_FORM_VIEWER_DIALOG',
    CLOSE_FORM_VIEWER_DIALOG: 'CLOSE_FORM_VIEWER_DIALOG',
    SET_FROM_DATA: 'SET_FROM_DATA',
    SERVICE_TICKETS_COUNT: 'SERVICE_TICKETS_COUNT',
    OPEN_BULK_ARCHIVE_MODAL: 'OPEN_BULK_ARCHIVE_MODAL',
    CLOSE_BULK_ARCHIVE_MODAL: 'CLOSE_BULK_ARCHIVE_MODAL',
}

export function openFormViewerDialog() {
    return {
        type: actionTypes.OPEN_FORM_VIEWER_DIALOG
    }
} 

export function closeFormViewerDialog() {
    return {
        type: actionTypes.CLOSE_FORM_VIEWER_DIALOG
    }
}

export function openBulkArchiveModal() {
    return {
        type: actionTypes.OPEN_BULK_ARCHIVE_MODAL
    }
} 

export function closeBulkArchiveModal() {
    return {
        type: actionTypes.CLOSE_BULK_ARCHIVE_MODAL
    }
}

export function setFromData(data=null) {
    return {
        type: actionTypes.SET_FROM_DATA,
        payload: {
			data
		}
    }
}


export function setServiceTicketsCount(count=null) {
    return {
        type: actionTypes.SERVICE_TICKETS_COUNT,
        payload: {
			count
		}
    }
} 

export default function (state = initialState, action) {
    switch(action.type) {
        case actionTypes.OPEN_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open: true
            }
        }

        case actionTypes.CLOSE_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open:false
            }
        }

        case actionTypes.SET_FROM_DATA: {
            return {
                ...state,
                data: action.payload.data
            }
        }
        case actionTypes.SERVICE_TICKETS_COUNT: {
            return {
                ...state,
                count: action.payload.count
            }
        }

        case actionTypes.SERVICE_TICKETS_COUNT: {
            return {
                ...state,
                count: action.payload.count
            }
        }

        case actionTypes.OPEN_BULK_ARCHIVE_MODAL: {
            return {
                ...state,
                openModal: true
            }
        }

        case actionTypes.CLOSE_BULK_ARCHIVE_MODAL: {
            return {
                ...state,
                openModal:false
            }
        }

        default:
            return state
    }
}