export default function generateGroupByFileData(data, gridRef, moduleName){
  const groupColumn = gridRef?.current?.columnApi?.getRowGroupColumns()
  

  let name = ''
  if(moduleName == 'asset'){
    name = groupColumn[0].colDef.field.includes('location')?'location_name':
    groupColumn[0].colDef.field.includes('category')?'category_name':
    groupColumn[0].colDef.field.includes('department')?'department_name':
    groupColumn[0].colDef.field.includes('manufacturer')?'manufacturer_name':groupColumn[0].colDef.field
  }else{
    name = groupColumn[0].colDef.field == 'category_name'?'category': groupColumn[0].colDef.field
  }  
  
  const groupedData = data.reduce((acc, item) => {
    if (!acc[item[name]]) {
        acc[item[name]] = [];
    }
    acc[item[name]].push(item);
    return acc;
  }, {});

  return groupedData
  // const groupColumn = gridRef?.current?.columnApi?.getRowGroupColumns()
  // if(groupColumn.length === 1) return createGroupedStructure(groupBy(data, groupColumn[0].colDef.field), []);
  // if(groupColumn.length >= 1){
  //   const otherKey = groupColumn.slice(1).map(item=>(item.colDef.field))
  //   return createGroupedStructure(groupBy(data, groupColumn[0].colDef.field), otherKey);
  // } 
  
}

// function groupBy(array, key) {
//   return array.reduce((groups, item) => {
//       const value = item[key];
//       if (!groups[value]) {
//           groups[value] = [];
//       }
//       groups[value].push(item);
//       return groups;
//   }, {});
// }

// function createGroupedStructure(groups, keys) {
//   console.log(groups, keys)
//   if (keys.length === 0) {
//       return groups;
//   }
//   const [currentKey, ...remainingKeys] = keys;
//   const grouped = {};
//   for (const [key, items] of Object.entries(groups)) {
//       grouped[key] = createGroupedStructure(groupBy(items, currentKey), remainingKeys);
//   }
//   return grouped;
// }