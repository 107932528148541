import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Zoom from '@material-ui/core/Zoom';

function FinalPage(props){

	return (
		<>
			<div style= {{textAlign:'center',fontSize:'24px',marginBottom:'2rem', fontWeight:600, color:'#404040', padding:'70px 0px'}} >
				<Zoom in = {true} >
				<Icon style={{color:'green', fontSize:120}}>check_circle_outline</Icon>
				</Zoom>
				<br/>
					Quote Approved
{/*				<br />
				Service Book has been updated for <span style={{color:'purple'}}>{props.count}  { props.count > 1 ? "Asset SKUs" : "Asset SKU" }</span>
*/}				<br />
				You have successfully approved quote
			</div>
		</>
	)
}

export default FinalPage;
