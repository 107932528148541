import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import CsvUpload from './CsvUpload';
import {Card, CardContent, Menu,MenuItem} from '@material-ui/core';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
// import SaveReportDialog from './saveReportDialog';
import AddAccessoryCategory from './addAccessoryCategory';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { usePermission } from '@fuse/hooks';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
// import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import SaveReportDialog from '../../reports/components/saveReportDialog';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample (props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [accessoryCategory, setAccessoryCategory] = useState([]);
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [logs,setLogs] = useState([]);
	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const { hasPermission } = usePermission()
	const viewAccessoryCategoryPermission = hasPermission('view-accessory_category')
	const createAccessoryCategoryPermission = hasPermission('add-accessory_category')
	const updateAccessoryCategoryPermission = hasPermission('update-accessory_category')
	const deleteAccessoryCategoryPermission = hasPermission('delete-accessory_category')
	
	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			// getActivities()
		}
		setSelectedTab(value);
	};
	
	function gotoDetailsPage(id){
		router.push(`manufacturers/${id}`)
	}

	async function getAccessoryCategory(){
		try {
			try {
				const res = await axios.get("/accessory-category");
				const { data } = res.data;
				setAccessoryCategory(formatData(data));
			} catch (err) {
				console.log(err);
			}
		} finally {
			setLoading(false);
		}
	}
	
	function handleSearchReset(){
		getAccessoryCategory()
	}

	function getSearchSuggetions(query, field){
		axios.get('/manufacturers/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	function handleSearch(query, field, filters) {
		axios.get('/manufacturers/search',{
			params: {
				query,
				field,
				filters
			}
		})
		.then(res => {
			const { data } = res.data;
			setManufacturers(data)
		})
		.catch(err => {
			console.log(err);
		})
	}
	
	function formatData(data){
		return data.map((item) => {
			const tempDescription = item.description;
			const tempElement = document.createElement('div');
			tempElement.innerHTML = tempDescription;
			item.description = item.description == '' ?  'No Description' : tempElement.textContent
			return {
				accessory_category: item.accessory_category_name,
				source: item.source === null ? 'No Source' : item.source,
				...item,
				original_info:{
					description: tempDescription
				}
			}
		})
	}

	function refreshList(){
		getAccessoryCategory()
	}

	// function formatLogs(data){
	// 	return data.map((item)=>{
	// 		return {
	// 			id:item.id,
	// 			action_id: `act${item.id}`,
	// 			action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
	// 			category_name: item?.accessory_category?.accessory_category_name || item.category_name,
	// 			name: item.user_name,
	// 			action: item.action_name,
	// 			description: item.comment,
	// 			previous_value: item.previous_data,
	// 			current_value: item.current_data,
	// 			field_name: item.action_field_name,
	// 			user_role: item.user_role_name
	// 		}
	// 	})
	// }

	// function getActivities(){
	// 	axios.get(`/accessory-category/activities`)
	// 	.then(({data})=>{
	// 		setLogs(formatLogs(data.data.activities));
	// 	})
	// 	.catch((err)=>{
	// 		console.log('cannot fetch logs',err);
	// 	})
	// }

	function callToast(success,error,value){
		if(success){
			toast.success(value)
		}
		if(error){
			toast.error(value);
		}
	}

	useEffect(()=>{
		setLoading(true)
		getAccessoryCategory()
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
		return (<>
			{(createAccessoryCategoryPermission) && <CsvUpload 
				refresh={refreshList}
				viewAccessoryCategoryPermission = { viewAccessoryCategoryPermission }
				createAccessoryCategoryPermission = { createAccessoryCategoryPermission }
				updateAccessoryCategoryPermission = { updateAccessoryCategoryPermission }
				deleteAccessoryCategoryPermission = {deleteAccessoryCategoryPermission}
			/>}


			<SaveReportDialog tableName={"accessory category"}/>

			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				// header={
				// 	<DynamicFieldsHeader 
				// 		handleSearch={handleSearch} 
				// 		searchSuggestions={searchSuggestions} 
				// 		clearSearch={clearFilters}
				// 		searchFilters={searchFilters}
				// 		handleSearchSuggestions={handelSearchSuggestions}
				// 		handleSearchReset={handleSearchReset}
				// 		onSearchChange={(searchValue, fieldValue) => {
				// 			setCurrentSearchParameters({query: searchValue, field: fieldValue})
				// 		}}
				// 	/>
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Accessory Categories" />
				// 			{viewAccessoryCategoryPermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}
				// 		</Tabs>
				// 		<div>
				// 			{/* <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
				// 				{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
				// 			</IconButton> */}
				// 			<Menu
				// 				id="simple-menu"
				// 				anchorEl={anchorEl}
				// 				keepMounted
				// 				open={viewMenuOpened}
				// 				onClose={handleViewMenuClose}
				// 			>
				// 				{	
				// 					views.map((view,index) => {
				// 						return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
				// 					})
				// 				}
				// 			</Menu>
				// 		</div>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
						{
							loading && <FuseLoading />
						}
						{
							!loading && <SalesOrderTable 
							endUsersAccounts={accessoryCategory}
							selectedView={selectedView}
							gotoDetailsPage={gotoDetailsPage}
							refresh={refreshList}
							viewAccessoryCategoryPermission = { viewAccessoryCategoryPermission }
							createAccessoryCategoryPermission = { createAccessoryCategoryPermission }
							updateAccessoryCategoryPermission = { updateAccessoryCategoryPermission }
							deleteAccessoryCategoryPermission = {deleteAccessoryCategoryPermission}
						/>
						}
						</div>
						<AddAccessoryCategory getAccessoryCategory = {getAccessoryCategory}/>
						
						{viewAccessoryCategoryPermission && 
							<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
								{/* <ActivitieLogs logs = {logs} /> */}
								{/* {Boolean(logs.length) ? <ActivityAgGridListing module='accessory_category' logs={logs}/> : <FuseLoading/> } */}
							</div>
						}
						{!viewAccessoryCategoryPermission && <div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
							<Card>
								<CardContent>You don't have the view permission for Accessory Category.</CardContent>
							</Card>
						</div>}
					</>
				}
				innerScroll
			/>
		
		</>)
	}
}

export default withReducer('accessoryCategoryApp', reducer)(CardedFullWidth2TabbedSample);
