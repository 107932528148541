import { React, useState, useEffect, useRef, Fragment, useCallback } from 'react';
import { Typography, Button, Box, FormControl, Select, MenuItem, InputLabel, Icon, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AssetActionComponent from './AssetActionComponent'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import axios from '@fuse/utils/axios';
import useToast from '@fuse/hooks/useToast';
import { useDispatch } from 'react-redux';
import { getAbbreviatedCapitalized } from '@fuse/utils/general';
import { usePermission } from '@fuse/hooks';
import PhoneNumberVerificationModal from '@fuse/components/Modal/PhoneNumberVerificationModal';

export default function Assets() {
  const tableHeaders = [
    {
      field: "parameters",
      headerName: "Parameters",
      minWidth: 350,
      maxWidth: 350
    },
    {
      field: "status",
      headerName: "Notification Status",
      headerComponentFramework: CustomFilter		
    },
    {
      field: "get_notified_via",
      headerName: "Get Notified Via",
      headerComponentFramework: CustomFilter	
    },
    {
      field: "actions",
      headerName: "Actions",
      // cellRendererFramework: AssetActionComponent,
      minWidth: 140,
      maxWidth: 140
    }
  ];
  const spanTemplate = {
    location_id: -1,
    category_id: -1,
    network_status: -1,
    get_notification_via: -1
  }
  const dispatch = useDispatch()
  const toast = useToast(dispatch);
	const gridRef = useRef();
  const { hasPermission } = usePermission()
  let createAssetPermission = hasPermission('add-assets',true)
  const [createNotification, setCreateNotification] = useState(false);
  const [openDeleteNotification, setOpenDeleteNotification] = useState(false);
  const [ allSpan, setAllSpan ] = useState([{...spanTemplate}])
  const [ locationMenu, setLocationMenu ] = useState([]);
  const [ categoryMenu, setCategoryMenu ] = useState([]);
  const [rowData, setRowData] = useState([]);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);
  const [ isPhoneVerified, setIsPhoneVerified ] = useState(false)
  const [ openPhoneVerificationModal, setOpenPhoneVerificationModal ] = useState(false);

  const onGridReady = params => {
    // Following line to make the currently visible columns fit the screen  
    params.api.sizeColumnsToFit();
 
    // Following line dymanic set height to row on content
    params.api.resetRowHeights();
  };

  const handleCreateNotification = () => {
    setCreateNotification(true)
  }

  const handleRemoveNotification = () => {
    setCreateNotification(false)
    setAllSpan([{ ...spanTemplate }])
    handleCloseOpenDeleteNotification()
  }

  const handleClickOpenDeleteNotification = () => {
    setOpenDeleteNotification(true);
  };

  const handleCloseOpenPhoneVerificationModal = () => {
    setOpenPhoneVerificationModal(false)
    checkUserPhoneNumber()
  }

  const handleCloseOpenDeleteNotification = () => {
    setOpenDeleteNotification(false);
  };

  const handleSaveNotification = () => {
    //Validate Inputs
    let error = false, payload = allSpan.map(i=>({...i}));
    for( let item of payload){
      if( item.location_id === -1 || item.category_id === -1 ||
        item.network_status === -1 || item.get_notification_via === -1
      ){
        error = true;
        break;
      }
      if(item.location_id === 0)item.location_id = null;
      if(item.category_id === 0) item.category_id = null;
    }

    if( error ){
      toast.error("Please fill out all required fields")
      return
    }
    axios.post(`notifications/asset-notifications-settings/add`,{
      settings: payload
    }).then(response => {
      fetchNotificationSettings()
      handleRemoveNotification()
      toast.success(response.data.message)
		}).catch(err => {
			console.log(err);

		})
  }

  const handleAddSpan = () => {
    setAllSpan((prev) => [
      ...prev,
      {...spanTemplate}
    ])
  }

  const handleDeleteSpan = (index) => {
		setAllSpan(allSpan.filter(span => allSpan[index] !== span )  )
	}

  const handleSpanInputChange = (index, propName, value) => {
    if(propName === 'get_notification_via' && (value === 'both' || value === 'sms') && !isPhoneVerified){
      setOpenPhoneVerificationModal(true)
      return
    }
    setAllSpan((spans) => {
      spans[index][propName] = value
      return [...spans]
    })
  };

  const fetchLocationSuggestions = (value = '') => {
		axios.get(`/user/fetch-user-location`).then(response => {
			let locations = (AlphabeticalSorting(response.data.data.locations,'location_name')).map((loc) =>{
        return {
          id: loc.id,
          label: loc.location_name
        }
      });
			setLocationMenu(locations)
		}).catch(err => {
			console.log(err);
		})
	}

  const fetchCategorySuggestions = () => {
		axios.get('/category?category_type=hardware').then(response => {
			setCategoryMenu(
        (AlphabeticalSorting(response.data.data,'category_name')).filter((item)=>{
          return Boolean( item.category_name)
        }).map( category => ({ id: category.id, label: category.category_name}))
      )
		}).catch(err => {
			console.log(err);
		})
	}

  const fetchNotificationSettings = () => {
    axios.get('/notifications/asset-notifications-settings/list').then(response => {
      const { result } = response.data.data;
      const formattedData = result.map((item)=>{
        return {
          id: item.id,
          get_notified_via: getAbbreviatedCapitalized(item.get_notification_via),
          location_name: item.asset_location?.location_name ?? "All",
          location_id: item.location_id ?? 0,
          category_name: item.asset_category?.category_name ?? "All",
          category_id: item.category_id ?? 0,
          status: item.network_status,
          keep_notification: item.keep_notification
        }
      })
      setRowData(formattedData)
		}).catch(err => {
			console.log(err);
		})
  }

  function checkUserPhoneNumber(){
    axios.get('/user/get-phone-number').then((res)=>{
      setIsPhoneVerified(Boolean(res.data.data.phoneNumber?.phone))
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    fetchLocationSuggestions();
    fetchCategorySuggestions();
    fetchNotificationSettings();
    checkUserPhoneNumber()
  },[]);

  return (
    <>
      {createNotification && <>
        {/* Creating Notification Form  */}
        <div className="flex items-center justify-between mb-16">
          <div>
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="font-semibold assets_catalog_header"
              // style = {{ color:'black'}}
            >
              Create Notifications
            </Typography>		
            <p className='sub_content_holder'>Select parameters as you see fit for the notifications</p>	
          </div>

          <div>
            <Button
              style={{marginRight: '12px !important'}}
              className='button primary_btn mr-12'
              onClick={handleSaveNotification}
            >
              Save
            </Button> 
            <Button className='button danger_btn' onClick={() => handleClickOpenDeleteNotification()}>
              <i className="icon ti ti-x" />
              Discard
            </Button>
          </div>
        </div>

        <div style={{ borderBottom: '1px solid #EAECF0' }} className='mb-36'>
          {
            allSpan.map(( item, index ) => {
              return(
                <Fragment key={index}>
                  <div className='py-16'>
                    <div className='flex items-center'>
                      <i className='ti ti-grip-vertical' />

                      <div className='flexbox_holder items-center w-full pl-20'>
                        <Box className='each_filter_holder pr-20'>
                          <InputLabel className='label_holder'>Notify me when an asset at</InputLabel>
                          <FormControl fullWidth className='cstm_select_formcontrol relative'>
                            <Select
                              style={{maxWidth: '160px'}}
                              labelId="location-label"
                              id="location"
                              value={item.location_id}
                              label=""
                              onChange={(e)=>handleSpanInputChange(index, 'location_id', e.target.value)}
                              MenuProps={{
                                sx: {
                                  maxHeight: 400,
                                  '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                  },
                                },
                              }}
                            >
                              <MenuItem disabled value={-1}>Location</MenuItem>
                              <MenuItem value={0}>All</MenuItem>
                              {locationMenu.map( loc => <MenuItem value={loc.id}>{loc.label}</MenuItem>)}
                            </Select>
                          </FormControl>
                        </Box>

                        <Box className='each_filter_holder pr-20'>
                          <InputLabel className='label_holder'>in</InputLabel>
                          <FormControl fullWidth className='cstm_select_formcontrol'>
                            <Select
                              style={{maxWidth: '160px'}}
                              labelId="category-label"
                              id="category"
                              value={item.category_id}
                              label=""
                              onChange={(e)=>handleSpanInputChange(index, 'category_id', e.target.value)}
                              MenuProps={{
                                sx: {
                                  maxHeight: 400,
                                  '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                  },
                                },
                              }}
                            >
                              <MenuItem disabled value={-1}>Category</MenuItem>
                              <MenuItem value={0}>All</MenuItem>
                              {categoryMenu.map( item => <MenuItem value={item.id}>{item.label}</MenuItem>)}
                            </Select>
                          </FormControl>
                        </Box>

                        <Box className='each_filter_holder pr-20'>
                          <InputLabel className='label_holder'>has the following network status</InputLabel>
                          <FormControl fullWidth className='cstm_select_formcontrol'>
                            <Select
                              labelId="status-label"
                              id="status"
                              value={item.network_status}
                              label=""
                              onChange={(e)=>handleSpanInputChange(index, 'network_status', e.target.value)}
                              MenuProps={{
                                sx: {
                                  maxHeight: 400,
                                  '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                  },
                                },
                              }}
                            >
                              <MenuItem disabled value={-1}>Status</MenuItem>
                              <MenuItem value="both">Both</MenuItem>
                              <MenuItem value="online">Online</MenuItem>
                              <MenuItem value="offline">Offline</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        <Box className='each_filter_holder'>
                          <InputLabel className='label_holder'>send my notification via</InputLabel>
                          <FormControl fullWidth className='cstm_select_formcontrol'>
                            <Select
                              labelId="notification-label"
                              id="notification"
                              value={item.get_notification_via}
                              label=""
                              onChange={(e)=>handleSpanInputChange(index, 'get_notification_via', e.target.value)}
                              MenuProps={{
                                sx: {
                                  maxHeight: 400,
                                  '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                  },
                                },
                              }}
                            >
                              <MenuItem disabled value={-1}>Notification</MenuItem>
                              <MenuItem value="both">Both</MenuItem>
                              <MenuItem value="sms">SMS</MenuItem>
                              <MenuItem value="email">Email</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    </div>

                    <div className='flex justify-end items-center pt-16'>
                    {(allSpan.length - 1 === index) &&
                      <Button
                        className='add_anotherButton'
                        onClick={ handleAddSpan }
                        startIcon={
                          <Icon fontSize="small" title="Add Another">
                            add
                          </Icon>}
                      >
                        Add Another
                      </Button>
                    }
                      { allSpan.length !== 1 && <Button className='transparent_icon_btn' onClick={()=>handleDeleteSpan(index)}>
                        <i style={{ fontSize: '20px', fontWeight: '500', color: '#F04438' }} className='ti ti-trash pl-8' />
                      </Button>}
                    </div>
                  </div>
                </Fragment>
              )
            })
          }
        </div>
        {/* END of Creating Notification Form  */}
        </>
      }

      <div className="flex items-center justify-between mb-16">
				<Typography
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="font-semibold assets_catalog_header"
					// style = {{ color:'black'}}
				>
					Your Notification Settings
				</Typography>			
        {(createAssetPermission.length > 0 ||
        createAssetPermission == "all") && <>
        {!createNotification && <Button className='button primary_btn' onClick={() => handleCreateNotification()}>
          <i className="icon ti ti-plus" />
          Create Notification
        </Button>}
        </>}
				
      </div>

      <div 
        className="ag-theme-alpine" 
        style={{
          width: "100%",
          height: windowHeight+105,
          fontSize:'12px'
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          animateRows
          onGridReady = { onGridReady }
          paginationPageSize={10}
          pagination
        >
          {tableHeaders.map(header => {
              if( header.field === "parameters"){
                return(
                  <AgGridColumn 
                    key = {header.field} 
                    field = {header.field}
                    headerName = {header.headerName}  
                    width = { header.width }
                    minWidth = { header.minWidth }
                    maxWidth = { header.maxWidth }
                    sortable = {false}
                    flex = {1}
                    filter = "text"
                    cellRendererFramework={(event) => (<ParametersComponent data={event.data}/>)}
                    wrapText
                    autoHeight
                    // floatingFilter = {true}
                    headerComponentFramework = {header.headerComponentFramework}								
                  />
                )
              }else if( header.field === "status"){
                return(
                  <AgGridColumn 
                    key = {header.field} 
                    field = {header.field}
                    headerName = {header.headerName}  
                    width = { header.width }
                    minWidth = { header.minWidth }
                    maxWidth = { header.maxWidth }
                    sortable = {false}
                    flex = {1}
                    filter = "text"
                    cellRendererFramework={(event) => (<StatusComponent keep_notification={event.data.keep_notification}/>)}
                    wrapText
                    headerComponentFramework = {header.headerComponentFramework}								
                  />
                )
              }else if(header.field === "actions"){
                return(
                  <AgGridColumn 
                    key = {header.field} 
                    field = {header.field}
                    headerName = {header.headerName}  
                    width = { header.width }
                    minWidth = { header.minWidth }
                    maxWidth = { header.maxWidth }
                    sortable = {false}
                    flex = {1}
                    filter = "text"
                    wrapText
                    cellRendererFramework={(event) => (
                      <AssetActionComponent
                        data={event.data}
                        locationMenu={locationMenu}
                        categoryMenu={categoryMenu}
                        fetchNotificationSettings={fetchNotificationSettings}
                        setOpenPhoneVerificationModal={setOpenPhoneVerificationModal}
                        isPhoneNumberVerified={isPhoneVerified}
                      />)}
                    headerComponentFramework = {header.headerComponentFramework}								
                  />
                )
              }else{
                return(
                  <AgGridColumn 
                    key = {header.field} 
                    field = {header.field}
                    headerName = {header.headerName}  
                    width = { header.width }
                    minWidth = { header.minWidth }
                    maxWidth = { header.maxWidth }
                    sortable = {false}
                    flex = {1}
                    filter = "text"
                    wrapText
                    headerComponentFramework = {header.headerComponentFramework}								
                  />
                )
              }
					})}
        </AgGridReact>
      </div>


      {/* discard notification modal */}
      <Dialog
        className="cstm_dialog_modal small text-center"
        open={openDeleteNotification}
        // onClose={handleCloseOpenDeleteNotification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flex justify-end items-center w-full'>
            <Button className='transparent_icon_btn' onClick={handleCloseOpenDeleteNotification}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="warning_content_holder">
            <div className="exclamation_icon_holder yellow">
              <i className="ti ti-exclamation-mark" />
            </div>
            <h2 className="heading">Are you sure to discard the notification?</h2>
            <p className="sub_content">Your progress will be lost if you proceed with this.</p>
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={handleCloseOpenDeleteNotification} className="secondary_btn">No, go back!</Button>
          <Button onClick={handleRemoveNotification} className="primary_btn">
            Yes, I'm sure!
          </Button>
        </DialogActions>
      </Dialog>

      {/* verify phone number */}
      {openPhoneVerificationModal && (
        <PhoneNumberVerificationModal
          open={openPhoneVerificationModal}
          close={handleCloseOpenPhoneVerificationModal}
        />
      )}
    </>
  );
}

function ParametersComponent({ data }) {
  return (
    <div className="flex flex-wrap py-8">
      <span className="cstm_badge_holder">
        Location : { data.location_name }
      </span>
      <span className="cstm_badge_holder">
        Category : { data.category_name }
      </span>
      <span className="cstm_badge_holder">
        Status : { getAbbreviatedCapitalized(data.status) }
      </span>
      <span className="cstm_badge_holder">
        Notification : { data.get_notified_via.toLowerCase() === 'sms' ? data.get_notified_via.toUpperCase() : getAbbreviatedCapitalized(data.get_notified_via)}
      </span>
    </div>
  )
}

function StatusComponent({ keep_notification }) {
  return (
    <>
      { keep_notification === "enabled" && <span className="cstm_badge_holder enabled">
        Enabled
      </span>}
      { keep_notification === "disabled" && <span className="cstm_badge_holder disabled">
        Disabled
      </span>}
    </>
  )
}