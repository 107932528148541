import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser, setImageUrl } from 'app/auth/store/userSlice';
import { purgeStoredState } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import axios from "@fuse/utils/axios";
import usePusher from '@fuse/hooks/usePusher';
import StatusIcon from "app/main/chat/components/StatusIcon";
import { resetAllPages } from 'app/auth/store/pagesAccessSlice';


import userIcon from '../../main/profile/components/tabs/user.svg'

const chatUserStatusList = [
	{ name: "default", label: "Default" },
	{ name: "active", label: "Active" },
	{ name: "away", label: "Away" },
	{ name: "do-not-disturb", label: "Do not disturb" },
];

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const { channel, channelName } = usePusher();

	const [userMenu, setUserMenu] = useState(null);
	const [statusMenuElement, setStatusMenuElement] = useState(null);
	const [chatStatus, setChatStatus] = useState(null);
	const [imageLocation, setImageLocation] = useState(null);
	const  PAGES_ACCESS_BY_USER = (useSelector(({ auth }) => auth.pagesAccessByUser.PAGES_ACCESS_BY_USER))

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	function handleSetInitialUserStatus() {
		axios.get("/chat/get-user-status").then((res) => {
			const { data } = res.data;
			const { name, label } = data ?? {};

			setChatStatus(name && label ? { name, label } : { name: "default", label: "Default" })
		}).catch((err) => {
			console.log(err);
		});
	}

	function handleStatusMenuOpen(event) {
		setStatusMenuElement(event.currentTarget)
	}

	function handleStatusMenuClose() {
		setStatusMenuElement(null);
		userMenuClose();
	}

	function handleSelectStatus({ name, label }) {
		setChatStatus({ name, label });

		axios.put("/chat/update-user-status", {
			name,
			label
		}).catch((err) => {
			console.log(err);
		});

		handleStatusMenuClose();
	}

	function getCurrentLoggedinUser(){
		return user.data;
	}

	function setupChatStatusChannel() {
		if (!channel) return () => { };

		const onStatusChangeEventName = `${channelName}-on-status-change`;

		channel.bind(onStatusChangeEventName, function (data) {
			const {
				user_id,
				user_type,
			} = data;

			const currentUser = getCurrentLoggedinUser();

			const whoChanged = `${user_id}-${user_type}`;

			if (whoChanged === `${currentUser?.identifier}`) {
				handleSetInitialUserStatus();
			}
		});

		return () => {
			channel.unbind(onStatusChangeEventName);
		}
	}

	function formatTimeDiff(diff) {
        const seconds = Math.floor((diff / 1000) % 60);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      
        return `${hours}h ${minutes}m ${seconds}s`;
    }

	function savePageAccessTime(){
		if(!PAGES_ACCESS_BY_USER.length) return
        const accessPage  = PAGES_ACCESS_BY_USER.filter(i => i.totalSessionTimeInSec !== 0)
        const res = []
        accessPage.forEach(curr => {
            res.push(`${curr.moduleName}: ${formatTimeDiff(curr.totalSessionTimeInSec)}`)
        })
		axios.post('auth/set-session-page-wise',{
			rowId: window.localStorage.getItem('endUserLoginLogId'),
			pages_access: res.join(", ")
		}).then((res)=>{

		}).catch((err)=>{

		})

	}

	useEffect(() => {
		handleSetInitialUserStatus();
		getUserDetails()
	}, []);

	async function getUserDetails(){
		axios.get(`/end-user/view-client/${user.data.id}`)
		.then(results=>{
			// console.log(results)
			setImageLocation(results?.data?.data?.imageUrl)
			dispatch(setImageUrl(results?.data?.data?.imageUrl))
		})
	}
	useEffect(() => {
		const channelCleanup = setupChatStatusChannel();

		return () => {
			channelCleanup();
		}
	}, [channel])

	return (
		<>
			<Button className="min-h-40 min-w-40 px-0 profile_buttonimg py-0 md:py-6" onClick={userMenuClick}>
				{/* <div className="hidden md:flex flex-col mx-4 items-end">
					<Typography component="span" className="font-semibold flex">
						{user.data.displayName}
					</Typography>
					<Typography className="text-11 font-medium capitalize" color="textSecondary">
						{user.data.company_creator && user.role.toString()=='Super-User'?'Super-User':''}
						{(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && 'Guest'}
					</Typography>
				</div> */}

				{/* {user.data.photoURL ? ( */}
					<Avatar className="md:mx-4 profile_img" alt="user photo" src={user.imageUrl === null ? userIcon : user.imageUrl} />
				{/* ) : (
					<Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
				)} */}

				{/* <div
					className="absolute right-0 bottom-0 -m-4 z-10 cursor-pointer"
					aria-haspopup="true"
					role="button"
					tabIndex={0}
					style={{
						right: "20px",
						top: "36px",
					}}
				>
					{chatStatus && <StatusIcon status={chatStatus} isOnline={true} />}
				</div> */}
				<span>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clip-path="url(#clip0_2895_3621)">
							<path d="M4 6L8 10L12 6" stroke="#1D2939" stroke-linecap="round" stroke-linejoin="round" />
						</g>
						<defs>
							<clipPath id="clip0_2895_3621">
								<rect width="16" height="16" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</span>
				{/* <Icon style={{fontWeight: 300, color:'#1D2939'}}> expand_more  </Icon> */}
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'profile_dropddown_holder'
				}}
			>
				{!user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" className='anchor_holder' role="button">
							{/* <ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon> */}
							<ListItemText primary="Login" />
						</MenuItem>
						<MenuItem component={Link} to="/register" className='anchor_holder' role="button">
							{/* <ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon> */}
							<ListItemText primary="Register" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem component={Link} to="/profile-settings" className='anchor_holder' onClick={userMenuClose} role="button">
							{/* <ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon> */}
							<ListItemText primary="My Profile" />
						</MenuItem>
						{/* <MenuItem component={Link} to="/apps/mail" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>mail</Icon>
							</ListItemIcon>
							<ListItemText primary="Inbox" />
						</MenuItem> */}
						{/* <MenuItem onClick={handleStatusMenuOpen} role="button">
							<ListItemIcon className="min-w-40">
								<StatusIcon
									status={chatStatus}
									defaultIcon={chatStatus?.name == "default" || chatStatus == null} />
							</ListItemIcon>
							<ListItemText primary="Status" />
						</MenuItem> */}
						<Menu
							id="nav-chat-status-menu"
							anchorEl={statusMenuElement}
							keepMounted
							open={Boolean(statusMenuElement)}
							onClose={handleStatusMenuClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							{chatUserStatusList.map((statusItem) => (
								<MenuItem key={statusItem.name} onClick={() => handleSelectStatus(statusItem)} className={chatStatus?.name == statusItem.name || (statusItem.name == "default" && chatStatus == null) ? "bg-grey-100" : ""}>
									{/* <ListItemIcon style={{ minWidth: 30 }}>
										<StatusIcon
											status={statusItem}
											defaultIcon={statusItem.name == "default"} />
									</ListItemIcon> */}

									{statusItem.label}
								</MenuItem>
							))}
						</Menu>
						<MenuItem
							className='anchor_holder red_txt'
							onClick={() => {
								savePageAccessTime()
								purgeStoredState({ storage: sessionStorage });
								dispatch(logoutUser());
								userMenuClose();
								dispatch(resetAllPages())
							}}
						>
							{/* <ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon> */}
							<ListItemText primary="Logout" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;
