exports.mapColorBasedOnRandomNumber = {
    '1': {
        icon: 'computer'
    },
    '2': {
        bgColor: '#F5E5CE',
        iconBgColor: '#E6BC8D'
    },
    '3': {
        bgColor: '#D6D6D6',
        iconBgColor: '#999999'
    },
    '4': {
        bgColor: '#FBA8A6',
        iconBgColor: '#F66D69'
    },
}

exports.mapIconBasedOnRandomNumber = {
    '1': 'computer',
    '2': 'computer',
    '3': 'handhelds',
    '4': 'handhelds',
}