import EndUsersAccountsTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { Button, Icon } from '@material-ui/core';
import { openCsvDialog } from "../store/csvDialogReducer"
import { useDispatch } from 'react-redux';
import CsvUpload from './CsvUpload';
import {Menu,MenuItem} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Media from 'react-media';
import IconButton from '@material-ui/core/IconButton';
import axiosgeneral from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { ReactComponent as GridView } from '../icons/grid_view.svg';
import { ReactComponent as ListView } from '../icons/list_view.svg';
import { ReactComponent as UploadIcon } from '../icons/upload.svg';
import SvgIcon from "@material-ui/core/SvgIcon";
 
const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	let [endUsersAccountsMod,setEndUsersAccounts] = useState([]);
	let [endUsersAccounts,setEndUsersAccountsRaw] = useState([]);
	const router = useRouter();
	const [agents, setAgents] = useState([]);

	useEffect(async ()=>{
		fetchCompanies();
	},[]);

	function fetchCompanies() {
		setLoading(true);
		axiosgeneral.get('/user-company/fetch-companies')
		.then(res => {
			setAllStates(res.data.data);
			props.showMessage({ message: res.data.message,autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
		})
		.catch(err => {
			return err;
		})
		setLoading(false);
	}

	function setAllStates(data){
		setEndUsersAccounts(data);
		setEndUsersAccountsRaw(data);
		setAgents(getAgents(data));
	}

	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function flattenEndUserAccounts(){
		if(endUsersAccountsMod.length > 0){
			
			const flattendEndUserAccs = endUsersAccountsMod.map(acc => {
				let modObj = {};
				let dynAttr = {};
				let keys = Object.keys(acc);
				for(let i = 0; i<keys.length; i++){
					if(keys[i] != 'created_at' && keys[i] != 'updated_at' && keys[i] != 'platform_customer_id'){
						if(keys[i] != 'dynamic_attributes'){
							modObj[keys[i]] = acc[keys[i]];
						}else{
							dynAttr = acc[keys[i]];
						}
					}
				}

				if(Object.keys(dynAttr).length > 0){
					keys = Object.keys(dynAttr);
					for(let i = 0; i<keys.length; i++){
						if(keys[i] != 'id' && keys[i] != 'created_at' && keys[i] != 'updated_at'){
							modObj[keys[i]] = dynAttr[keys[i]]['attribute_value'];
						}
					}
				}
				return modObj;
			});
			return flattendEndUserAccs;
		}
		return [];
	}

	function getAgents(endUsersAccounts){
		const agentSet = new Set();
		for(let i = 0; i < endUsersAccounts.length ; i++){
			agentSet.add(endUsersAccounts[i].Agent);
		}
		return Array.from(agentSet);
	}

	function search(query) {
		if(query == ''){
			setEndUsersAccounts(endUsersAccounts);	
		}else{
			setEndUsersAccounts(endUsersAccounts.filter(acc=>{
				let flag = false;
				let values = Object.keys(acc);
				for(let i = 0; i < values.length; i++){
					if(String(acc[values[i]]).toLowerCase().includes(query)){
						flag = true;
					}
				}
				return flag;
			}))
		}
	}

	function applyFilters(filters){
		setFilters(filters);
		setEndUsersAccounts(endUsersAccounts.filter(acc=>{
			let flag = true;
			if(filters.agent != '' && filters.agent != acc.Agent){
				flag = false;
			}
			return flag;
		}))
	}

	function clearFilters() {
		setFilters(defaultFilters);
		setEndUsersAccounts(endUsersAccounts);
	}

	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};
	const dispatch = useDispatch();

	function toggleView(val){
		setSelectedView(val);
	}

	function gotoCompanyDetail(id){
		router.push(`companies/detail/${id}`)
	}

	return (<>
		<CsvUpload fetchCompanies={fetchCompanies}/>

		{loading ? <FuseLoading /> : <FusePageCarded
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0'
			}}
			header={
				<DynamicFieldsHeader search={search} clearSearch={clearFilters}/>
			}
			contentToolbar={
				<>
					<Tabs
						value={selectedTab}
						onChange={handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="off"
						className="w-full h-64"
					>
						<Tab key="end_user_tab_1" className="h-64" label="All accounts" />
						<Tab key="end_user_tab_2" className="h-64" label="My accounts" />
						
					</Tabs>
					<div>
						<IconButton aria-controls="simple-menu" className={classes.buttonSquare} aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
							{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
							{viewMenuOpened?<Icon>arrow_drop_up</Icon>:<Icon>arrow_drop_down</Icon>}
							
						</IconButton>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={viewMenuOpened}
							onClose={handleViewMenuClose}
						>
							{	
								views.map((view,index) => {
									return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
								})
							}
						</Menu>
					</div>
				
					<div className="p-10">
						<Media queries={{
							medium: "(max-width: 900px)",
							large: "(min-width: 1200px)"
							}}
						>
							{
								matches => (
									<>
										{matches.large && 
											<Button
												onClick={() => dispatch(openCsvDialog())}
												variant="contained"
												color="secondary"
												className={`w-full ${classes.buttonSquare}`}
												style={{width:'150px'}}
												startIcon={
													<SvgIcon fontSize="small"><UploadIcon  color="action" /></SvgIcon>
												}>
													Upload CSV
											</Button>
										}
										{
											matches.medium && 
											<IconButton
											onClick={() => dispatch(openCsvDialog())}
											variant="contained"
											color="secondary"
											className={classes.buttonSquare}
											>
												<SvgIcon fontSize="small"><UploadIcon  color="action" /></SvgIcon>
											</IconButton>
										}
									</>
								)
							}
						</Media>
					</div>
					<div className="p-10">
						<Media queries={{
							medium: "(max-width: 900px)",
							large: "(min-width: 1200px)"
							}}
						>
							{
								matches => (
									<>
										{matches.large && 
											<Button
												variant="contained"
												color="secondary"
												className={`w-full ${classes.buttonSquare}`}
												style={{width:'150px'}}
												startIcon={
												<Icon fontSize="large" title="Save View">
													save
												</Icon>}>
													Save View
											</Button>
										}
										{
											matches.medium && 
											<IconButton
											onClick={() => dispatch(openCsvDialog())}
											variant="contained"
											color="secondary"
											className={classes.buttonSquare}
											>
												<Icon>
													cloud_upload
												</Icon>
											</IconButton>
										}
									</>
								)
							}
						</Media>
					</div>
				</>
			}
			contentToolbarSecondary={
				<Grid container spacing={3}  className="p-10">
					<Grid item xs={6} sm>
					
					<FormControl variant="outlined" fullWidth margin='dense'>
						<InputLabel htmlFor="quote_status_filter">Quote Indicator</InputLabel>
						<Select
						native
						// value={filters.agent}
						// onChange={ev => applyFilters({...filters,agent:ev.target.value})}
						label="Quote Indicator"
						inputProps={{
							name: 'quote_indicator_filter',
							id: 'quote_indicator_filter',
						}}
						>
						{/* <option aria-label="None" value="" />
						{
							agents.map((agent,index) => {
								return <option key={`agent_${index}`} value={agent}>{agent}</option>
							})
						} */}
						</Select>
					</FormControl>
				</Grid>
					<Grid item xs={6} sm>
					
					<FormControl variant="outlined" fullWidth margin='dense'>
						<InputLabel htmlFor="quote_status_filter">Quote Status</InputLabel>
						<Select
						native
						// value={filters.agent}
						// onChange={ev => applyFilters({...filters,agent:ev.target.value})}
						label="Quote Status"
						inputProps={{
							name: 'quote_status_filter',
							id: 'quote_status_filter',
						}}
						>
						{/* <option aria-label="None" value="" />
						{
							agents.map((agent,index) => {
								return <option key={`agent_${index}`} value={agent}>{agent}</option>
							})
						} */}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6} sm>
						
					<FormControl variant="outlined" fullWidth margin='dense'>
						<InputLabel htmlFor="revenue_filter">Revenue</InputLabel>
						<Select
						native
						// value={filters.agent}
						// onChange={ev => applyFilters({...filters,agent:ev.target.value})}
						label="Revenue"
						inputProps={{
							name: 'revenue_filter',
							id: 'revenue_filter',
						}}
						>
						{/* <option aria-label="None" value="" />
						{
							agents.map((agent,index) => {
								return <option key={`agent_${index}`} value={agent}>{agent}</option>
							})
						} */}
						</Select>
					</FormControl>
				</Grid>
					<Grid item xs={6} sm>	
						<FormControl variant="outlined" fullWidth margin='dense'>
								<InputLabel htmlFor="agents_filter">Agents</InputLabel>
								<Select
								native
								value={filters.agent}
								onChange={ev => applyFilters({...filters,agent:ev.target.value})}
								label="Agents"
								inputProps={{
									name: 'agents_filter',
									id: 'agents_filter',
								}}
								>
								<option aria-label="None" value="" />
								{
									agents.map((agent,index) => {
										return <option key={`agent_${index}`} value={agent}>{agent}</option>
									})
								}
								</Select>
							</FormControl>
				</Grid>
				<Grid item xs sm
					className={`${classes.pullRight}`}>
				<Button
					onClick={clearFilters}
					variant="contained"
					color="secondary"
					className={classes.buttonSquare}
				>
						Clear search
				</Button>
				</Grid>
				</Grid>
			}
			content={
				<>
				<div className="p-10">
					<EndUsersAccountsTable 
						endUsersAccounts={flattenEndUserAccounts()}
						selectedView={selectedView}
						gotoCompanyDetail={gotoCompanyDetail}
						/>
				</div>
				</>
			}
			innerScroll
		/>}
	</>)
}


export default CardedFullWidth2TabbedSample;

