import PaymentTermTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import useToast from '@fuse/hooks/useToast';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
// import SaveReportDialog from './saveReportDialog';
import AddPaymentTermDialog from './addPaymentTermDialog';
import { usePermission } from '@fuse/hooks';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import QuickAddPaymentTerm from 'app/fuse-layouts/shared-components/QuickAddPaymentTerm';
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer';



const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});


function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {	
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [paymentTerms, setPaymentTerms] = useState([]);
	const router = useRouter();
	const agents = getAgents(paymentTerms);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});
	const { hasPermission } = usePermission()
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const viewPaymentTermPermission = hasPermission('view-payment_term')
	const createPaymentTermPermission = hasPermission('add-payment_term')
	const updatePaymentTermPermission = hasPermission('update-payment_term')
	const deletePaymentTermPermission = hasPermission('delete-payment_term')
	const { open: openAddPaymentForm } = useSelector((state) => state.paymentTermApp.formViewDialog)


	function getAgents(paymentTerms){
		const agentSet = new Set();
		for(let i = 0; i < paymentTerms.length ; i++){
			agentSet.add(paymentTerms[i].Agent);
		}
		return Array.from(agentSet);
	}

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};
	
	async function getPaymentTerms(){
		try {
			const res = await axios.get("/payment-term");
			const { data } = res.data;
			setPaymentTerms(formatPaymentTerms(data));
		} catch (err) {
			console.log(err);
		}
	}

	function getSearchSuggetions(query, field){
		axios.get('/manufacturers/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	function handleSearch(query, field, filters) {
		axios.get('/manufacturers/search',{
			params: {
				query,
				field,
				filters
			}
		})
		.then(res => {
			const { data } = res.data;
			setPaymentTerms(data)
		})
		.catch(err => {
			console.log(err);
		})
	}
	
	function formatPaymentTerms(data){
		return data.map((item) => {
			return {
				id: item.id,
				payment_term_name: item.display_name,
				term_duration: item.term_duration,
				description: item.description,
				system_generated: item.system_generated,
			}
		})
	}

	function refreshList(){
		getPaymentTerms();
	}

	function callToast(success,error,value){
		if(success){
			toast.success(value)
		}
		if(error){
			toast.error(value);
		}
	}


	function handleAddPaymentTermFormClose () {
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}
	

	useEffect(()=>{
		setLoading(true)
		getPaymentTerms().finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	return (
		<>
			<SaveReportDialog tableName={"payment term"}/>
			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				// header={
				// 	<DynamicFieldsHeader 
				// 		handleSearch={handleSearch} 
				// 		searchSuggestions={searchSuggestions} 
				// 		clearSearch={clearFilters}
				// 		searchFilters={searchFilters}
				// 		handleSearchSuggestions={handelSearchSuggestions}
				// 		// handleSearchReset={handleSearchReset}
				// 		onSearchChange={(searchValue, fieldValue) => {
				// 			setCurrentSearchParameters({query: searchValue, field: fieldValue})
				// 		}}
				// 	/>
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Payment Terms" />
				// 			{viewPaymentTermPermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}							
				// 		</Tabs>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							{
								loading && <FuseLoading />
							}
							{
								!loading && <PaymentTermTable 
								endUsersAccounts={paymentTerms}
								selectedView={selectedView}
								refresh={refreshList}
								viewPaymentTermPermission = { viewPaymentTermPermission }
								createPaymentTermPermission = { createPaymentTermPermission }
								updatePaymentTermPermission = { updatePaymentTermPermission }
								deletePaymentTermPermission = { deletePaymentTermPermission }
							/>
							}
						</div>
						{/* <AddPaymentTermDialog getPaymentTerms = {getPaymentTerms}/> */}
						{
							openAddPaymentForm && <QuickAddPaymentTerm open={ openAddPaymentForm } close={handleAddPaymentTermFormClose} />
						}
					</>
				}
				innerScroll
			/>
		</>
	)
}

export default withReducer('paymentTermApp', reducer)(CardedFullWidth2TabbedSample);
