import { combineReducers } from '@reduxjs/toolkit';
import moduleReducer from './moduleReducer';
import reorderTableReducer from './reorderTableReducer';

const reducer = combineReducers({
	moduleReducer,
	reorderTableReducer
});

export default reducer;
