const initialState = {
	open: false,
	mode: "create",
}

const actionTypes = {
    OPEN_CREATE_DIALOG: "OPEN_CREATE_DIALOG",
    CLOSE_CREATE_DIALOG: "CLOSE_CREATE_DIALOG"
}

export function openCreateDialog(mode = "create"){
    return {
        type: actionTypes.OPEN_CREATE_DIALOG,
		payload: {
			mode
		}
    }
}

export function closeCreateDialog(mode = "create"){
    return {
        type: actionTypes.CLOSE_CREATE_DIALOG,
		payload: {
			mode
		}
    }
}

export default function (state = initialState, action) {
	switch (action.type) {
	  case actionTypes.OPEN_CREATE_DIALOG:{
		  return { 
			  ...state,
			  mode: action.payload.mode,
			  open: true,
		  }
	  }
	  case actionTypes.CLOSE_CREATE_DIALOG:{
			return { 
				...state,
				mode: action.payload.mode,
				open: false,
			}
	  }
	  default:
		return state
	}
}