import _ from '@lodash';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import ListView from './listView';
import useRouter from '@fuse/hooks/useRouter';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function EndUsersAccountsTable(props) {
  	const classes = useStyles();
	const router = useRouter();

	let locationTypes;
	
	if(router.query.report_name){
		locationTypes = props.locationTypes.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage);
	}else{
		locationTypes = props.locationTypes;
	}

	function handleChangePage(event, value) {
		props.setPage(value);
		if(!router.query.report_name){
			props.fetchServiceRequest(props.rowsPerPage, value, props.filters[props.currentFilter], props.currentSearch);
		}
	}

	function handleChangeRowsPerPage(rowsPerPage) {
		props.setPage(0)
		props.setRowsPerPage(rowsPerPage);
		if(!router.query.report_name){
			props.fetchServiceRequest(rowsPerPage, 0, props.filters[props.currentFilter], props.currentSearch);
		}
	}


	if(props.loading){
		return (<FuseLoading/>)
	}

	if (!props.viewLocationTypePermission) {
		return (
			<div>
				<Card>
					<CardContent>You don't have permission to view any Location Type at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		)
	}

	// if(locationTypes.length <= 0){
	// 	return (
	// 		<motion.div
	// 			initial={{ opacity: 0 }}
	// 			animate={{ opacity: 1, transition: { delay: 0.1 } }}
	// 			className="flex flex-1 items-center justify-center h-full"
	// 		>
	// 			<div style={{height: "200px"}}>
	// 				<Typography variant="body2" component="p" color="textSecondary">
	// 					There are currently no location type available to view. Please check back later.
	// 				</Typography>
	// 			</div>
	// 		</motion.div>
	// 	)
	// }

	return (
		<div className="w-full flex flex-col">
			<SwitchView
				getLocationTypes = {props.getLocationTypes}
				locationTypes={locationTypes}
				addLocationTypePermission = {props.addLocationTypePermission}
				viewLocationTypePermission = {props.viewLocationTypePermission}
				updateLocationTypePermission = {props.updateLocationTypePermission}
				deleteLocationTypePermission = {props.deleteLocationTypePermission}
			/>
			{/* <div className = "p-10">
				<TablePagination
					className="flex-shrink-0 border-t-1"
					component="div"
					count={props.totalRecords}
					rowsPerPage={'all'}
					page={props.tableName === 'manuallyAddedServiceRequest' ? props.setPage : props.page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div> */}
		</div>
	);

}

function SwitchView({
	locationTypes,getLocationTypes, addLocationTypePermission, viewLocationTypePermission, 
	updateLocationTypePermission, deleteLocationTypePermission
}) {
	return(
		<ListView 
			locationTypes={locationTypes}
			getLocationTypes = {getLocationTypes}
			addLocationTypePermission = {addLocationTypePermission}
			viewLocationTypePermission = {viewLocationTypePermission}
			updateLocationTypePermission = {updateLocationTypePermission}
			deleteLocationTypePermission = {deleteLocationTypePermission}	
		/>
	)
}

export default withRouter(EndUsersAccountsTable)
