import FusePageCardedThree from '@fuse/core/FusePageCardedThree';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import withReducer from 'app/store/withReducer';
import reducer from '../../../store';
import AssetsCatalogHeader from './header';
import AssetsCatalogList from './tables';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});
function AssetsCatalog(props) {
	const classes = useStyles();
	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<>
			<FusePageCardedThree
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				header={
					<AssetsCatalogHeader />
				}
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							<AssetsCatalogList />
						</div>
					</>
				}
				innerScroll
			/>
			
		</>
	)
	
}
export default withReducer('assetsCatalogApp', reducer)(AssetsCatalog);