import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";

export async function fetchSubscription(){
  const res = await axios.get('/subscription');
  return formatSubscription(res.data.data)
}

export const colDefs = [
  {
    field: "subscription_name",
    key: "subscription_name",
    headerName: "Subscription Name"
  },
  {
    field: "provider_name",
    key: "provider_name",
    headerName: "Provider Name"
  },
  {
    field: "status",
    key: "status",
    headerName: "Status"
  },
  {
    field: "no_of_seats",
    key: "no_of_seats",
    headerName: "No of seats" 
  },
  {
    field: "category",
    key: "category",
    headerName: "Category"
  },
  {
    field: "department",
    key: "department",
    headerName: "Department"
  },
  {
    field: "start_date",
    key: "start_date",
    headerName: "Start Date"
  },
  {
    field: "end_date",
    key: "end_date",
    headerName: "End Date"
  },
  {
    field: "duration",
    key: "duration",
    headerName: "Duration",
    // comparator: durationComparator,
  },
  {
    field: "amount",
    key: "amount",
    headerName: "Amount"    
  },
  {
    field: "billing_cycle",
    key: "billing_cycle",
    headerName: "Billing Cycle"
  },
  {
    field: "billing_cycle_date",
    key: "billing_cycle_date",
    headerName: "Billing Cycle Date"   
  },
  {
    field: "website",
    key: "website",
    headerName: "Website"
  },
  {
    field: "po_date",
    key: "po_date",
    headerName: "PO Date"
  },
  {
    field: "po_number",
    key: "po_number",
    headerName: "PO Number"
  },
  {
    field: "card_number",
    key: "card_number",
    headerName: "Card Number"
  },
  {
    field: "card_holder_name",
    key: "card_holder_name",
    headerName: "Card Holder Name"
  }
]

function calculateDuration(startDate, endDate) {
  var start = new Date(startDate)
  var end = new Date(endDate)

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffInDays = Math.floor(Math.abs((start - end) / oneDay));

  const years = Math.floor(diffInDays / 365);
  const months = Math.floor((diffInDays % 365) / 30);
  const days = diffInDays % 30;

  const result = `${days}D ${months}M ${years}Y`;
  return result;
}

function formatSubscription(data){
  return data.map((subscription) => {
    return {
      subscription_name: subscription.subscription_name,
      provider_name: subscription.cloud_provider.provider_name || '',
      status: subscription?.software_asset_status?.status_name,
      no_of_seats: subscription.no_of_seats,
      category: subscription.software_category.category_name,
      department: subscription.department_id,
      start_date : subscription.term_start_date ? dateReformatTo_mmm_dd_yyyy(subscription.term_start_date) : '',
      end_date : subscription.term_end_date ? dateReformatTo_mmm_dd_yyyy(subscription.term_end_date) : '',
      duration: calculateDuration(subscription.term_start_date, subscription.term_end_date),
      amount: subscription.subscription_billing?.amount || '',
      billing_cycle: `${subscription?.subscription_billing?.billing_cycle} ${subscription?.subscription_billing?.month || ''}` || '',
      billing_cycle_date: subscription?.subscription_billing?.billing_cycle_date ? 
        dateReformatTo_mmm_dd_yyyy(subscription.subscription_billing?.billing_cycle_date)
        :'No Billing Cycle Date',
      website: subscription.website,
      po_date: subscription.subscription_payment.po_date ? 
        dateReformatTo_mmm_dd_yyyy(subscription.subscription_payment.po_date)
        : 'No PO Date',
      po_number: subscription.subscription_payment.po_number || 'No PO Number',
      card_number: subscription.subscription_payment.card_number || 'No Card Number',
      card_holder_name: subscription.subscription_payment.card_holder_name || 'No Card Holder Name',
    }
  })
}