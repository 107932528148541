const initialState = {
    open: false,
    data: null
}

const actionTypes = {
    OPEN_SERVICE_REQUEST_FORM_VIEWER_DIALOG: 'OPEN_SERVICE_REQUEST_FORM_VIEWER_DIALOG',
    CLOSE_FORM_VIEWER_DIALOG: 'CLOSE_FORM_VIEWER_DIALOG',
    SET_FROM_DATA: 'SET_FROM_DATA'
}

export function openFormViewerDialog() {
    return {
        type: actionTypes.OPEN_SERVICE_REQUEST_FORM_VIEWER_DIALOG
    }
} 

export function closeFormViewerDialog() {
    return {
        type: actionTypes.CLOSE_FORM_VIEWER_DIALOG
    }
}

export function setFromData(data=null) {
    return {
        type: actionTypes.SET_FROM_DATA,
        payload: {
			data
		}
    }
} 

export default function (state = initialState, action) {
    switch(action.type) {
        case actionTypes.OPEN_SERVICE_REQUEST_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open: true
            }
        }

        case actionTypes.CLOSE_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open:false
            }
        }

        case actionTypes.SET_FROM_DATA: {
            return {
                ...state,
                data: action.payload.data
            }
        }

        default:
            return state
    }
}