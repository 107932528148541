import { useEffect } from "react"
import { useDispatch } from 'react-redux';
import usePusher from '@fuse/hooks/usePusher';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { initPusher, updateUserCount, resetPusher } from 'app/store/fuse/pusherSlice';
import { Redirect } from "react-router";
import { updateSyncStatus } from "app/main/connectors/store/dataSyncReducer";
import store from "app/store";

const excludedRoutes = ['/login', '/registration','/reset/','/welcome', '/reset-password']

export const isExcludedRoute = (pathName = window.location.pathname) => {
    // return excludedRoutes.includes(pathName)
    let excluded = false;
        excludedRoutes.forEach(excludedRoute => {
			if(!excluded && pathName.includes(excludedRoute)){
				excluded = true;
			}
		})
		return excluded

}

export default function AuthCheckContainer(props) {
	const user = useCurrentUser().data;
	const { broadcastChannel } = usePusher();
	const dispatch = useDispatch()

	// useEffect(()=>{
	// 	if(broadcastChannel){
	// 		broadcastChannel.bind('data_sync_status', (data) => {
	// 			console.log('data_sync_status_data: ',data);
	// 			if(user.id == data.user_companies_user_id){
	// 				const isProgressBarVisible = store.getState().ConnectorsApp.dataSyncReducer.isProgressBarVisible
	// 				console.log('isProgressBarVisible: ',isProgressBarVisible);
	// 				if(isProgressBarVisible) dispatch(updateSyncStatus(data))
	// 			}
	// 		})
	// 		return () => broadcastChannel.unbind('data_sync_status')
	// 	}
	// },[broadcastChannel]);

	const isGuest = !user.token;

    if(isGuest && !isExcludedRoute()){
		<Redirect to={'/login'} />
		// if(window.location.pathname === '/'){
		// 	<Redirect to={'/login'} />
		// }else{
		// 	return <Redirect to={'/login'} />
		// }
    }

	
	return props.children;
}