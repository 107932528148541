import { React, useState, useEffect, useRef, Fragment } from 'react';
import { Typography, Button, Box, FormControl, Select, MenuItem, InputLabel, Icon, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
// import ParametersComponent from './ParametersComponent'
// import StatusComponent from './StatusComponent'
import ActionComponent from './ActionComponent'
import axios from '@fuse/utils/axios';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import { MenuBook } from '@material-ui/icons';
import useToast from '@fuse/hooks/useToast'
import {useDispatch} from "react-redux";
import ContractParametersComponent from './ContractParametersComponent';
import ContractStatusComponent from './ContractStatusComponent'
import ContractActionComponent from './ContractActionComponent';
import { usePermission } from '@fuse/hooks';
import PhoneNumberVerificationModal from '@fuse/components/Modal/PhoneNumberVerificationModal';

export default function Contracts() {
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
  const { hasPermission } = usePermission()
  const createContractPermission = hasPermission('add-contract')

  const [location, setLocation] = useState('default');
  const [category, setCategory] = useState('default');
  const [model, setModel] = useState('default');
  const [notification, setNotification] = useState('default');
  const [createNotification, setCreateNotification] = useState(false);
  const [openDeleteNotification, setOpenDeleteNotification] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [manufactureData, setManufactureData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [contractNotificationData, setContractNotificationData] = useState([{
    key: new Date().getTime(),
    location: "default",
    model: "default",
    modelList: [],
    manufacture: "default",
    notificationType: "default",
    numberOfDays: 0
  }]);
  const [ isPhoneVerified, setIsPhoneVerified ] = useState(false)
  const [ openPhoneVerificationModal, setOpenPhoneVerificationModal ] = useState(false);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);
	const gridRef = useRef();

	const tableHeaders = [
    {
      field: "parameters",
      headerName: "Parameters",
      minWidth: 350,
      maxWidth: 350
    },
    {
      field: "status",
      headerName: "Status",
      headerComponentFramework: CustomFilter		
    },
    {
      field: "get_notification_via",
      headerName: "Get Notified Via",
      headerComponentFramework: CustomFilter	
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRendererFramework: ActionComponent,
      minWidth: 140,
      maxWidth: 140
    }
  ];

  const onGridReady = params => {
    // Following line to make the currently visible columns fit the screen  
    params.api.sizeColumnsToFit();
 
    // Following line dymanic set height to row on content
    params.api.resetRowHeights();
  };

  const handleLocationChange = (event, index) => {
    const contractNotificationCopyData = contractNotificationData;
    contractNotificationCopyData[index].location = event.target.value.toString()
    setContractNotificationData([...contractNotificationCopyData])
  };

  const handleManufacturerChange = (event, index) => {
    const contractNotificationCopyData = contractNotificationData;
    contractNotificationCopyData[index].manufacture = event.target.value.toString()
    if(event.target.value.toString() !== "default" && event.target.value.toString() !== "0") {
      axios.get(`/models/get-model/${event.target.value}`).then(response => {
        const { modelData } = response.data.data
        contractNotificationCopyData[index].model = "0";
        contractNotificationCopyData[index].modelList = modelData;
        setContractNotificationData([...contractNotificationCopyData])
      }).catch(err => {
        console.log('29=>',err)
      })
    } else {
      contractNotificationCopyData[index].model = event.target.value.toString();
      contractNotificationCopyData[index].modelList = [];
      setContractNotificationData([...contractNotificationCopyData])
    }
  };

  const handleCloseOpenPhoneVerificationModal = () => {
    setOpenPhoneVerificationModal(false)
    checkUserPhoneNumber()
  }

  const handleModelChange = (event, index) => {
    const contractNotificationCopyData = contractNotificationData;
    contractNotificationCopyData[index].model = event.target.value.toString();
    setContractNotificationData([...contractNotificationCopyData])
  };

  const handleNotificationChange = (event, index) => {
    if((event.target.value === 'Both' || event.target.value === 'SMS') && !isPhoneVerified) {
      setOpenPhoneVerificationModal(true)
      return
    }
    const contractNotificationCopyData = contractNotificationData;
    contractNotificationCopyData[index].notificationType = event.target.value
    setContractNotificationData([...contractNotificationCopyData])
  };

  const handleNumberOfDaysInputChange = (event, index) => {
    const contractNotificationCopyData = contractNotificationData;
    contractNotificationCopyData[index].numberOfDays = event.target.value
    setContractNotificationData([...contractNotificationCopyData])
  }

  const handleCreateNotification = () => {
    setCreateNotification(true)
  }

  const handleRemoveNotification = () => {
    setContractNotificationData([{
      key: new Date().getTime(),
      location: "default",
      model: "default",
      modelList: [],
      manufacture: "default",
      notificationType: "default",
      numberOfDays: 0
    }])
    setCreateNotification(false)
    handleCloseOpenDeleteNotification()
  }

  const handleClickOpenDeleteNotification = () => {
    setOpenDeleteNotification(true);
  };

  const handleCloseOpenDeleteNotification = () => {
    setOpenDeleteNotification(false);
  };

  const addContractNotificationData = () => {
    const newData = {
      key: new Date().getTime(),
      location: "default",
      model: "default",
      modelList: [],
      manufacture: "default",
      notificationType: "default",
      numberOfDays: 0
    }
    setContractNotificationData([...contractNotificationData, newData]);
  }

  const removeContractNotificationData = (index) => {
    const removeKeyData = contractNotificationData[index].key;
    const newArray = contractNotificationData.filter(item => {
      return item.key !== removeKeyData
    })
    setContractNotificationData(newArray)
  }

  const fetchAllCategoriesManufacturer = () => {
    axios.get(`/assets/fetch-category-manufacturer-suggestion`).then(response => {
        const {manufacturers} = response.data.data
        const manufactureDataFromApi = AlphabeticalSorting(manufacturers, 'manufacturer_name')
        setManufactureData(manufactureDataFromApi)
    }).catch(err => {
        console.log('29=>',err)
    }) 
  }

  const fetchLocationData = () => {
    // axios.get(`/assets/fetch-location-suggestion?value=`).then(response => {
    //   const { locations } = response.data.data
    //   setLocationData(AlphabeticalSorting(locations, 'location_name'))
    // }).catch(err => {
    //   console.log('29=>',err)
    // })
    axios.get(`/user/fetch-user-location`).then(response => {
      const { locations } = response.data.data
      setLocationData(AlphabeticalSorting(locations, 'location_name'))
    }).catch(err => {
      console.log('29=>',err)
    })
  }

  const fetchContractNotifications = () => {
    axios.get(`/contracts/list-contract-notification`).then(response => {
      const { listContractNotificationData } = response.data.data
      const formattedData = listContractNotificationData.map((item)=>{
        // console.log(item.contract_manufacturer_model)
        return {
          id: item.id,
          contract_notification_id: item.id,
          notificationType: item.get_notification_via,
          get_notification_via: item.get_notification_via,
          location_id: item.location_id === null ? "0" : item.location_id,
          model_id: item.model_id === null ? "0" : item.model_id,
          manufacturer_id: item.manufacturer_id === null ? "0" : item.manufacturer_id,
          days: item.days,
          status: item.status,
          status_name: item.status === "1" ? "Enabled" : "Disabled",
          location_name: item.contract_location === null ? "All" : item.contract_location.location_name,
          model_name: item.contract_manufacturer_model === null ? "All" : `${item.contract_manufacturer_model.model_no}-${item.contract_manufacturer_model.model_name}`,
          manufacturer_name: item.contract_manufacturer === null ? "All" : item.contract_manufacturer.manufacturer_name,
        }
      })
      setRowData(formattedData)
    }).catch(err => {
      console.log('29=>',err)
    })
  }

  function checkUserPhoneNumber(){
    axios.get('/user/get-phone-number').then((res)=>{
      setIsPhoneVerified(Boolean(res.data.data.phoneNumber?.phone))
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(() => {
    fetchAllCategoriesManufacturer()
    fetchLocationData()
    fetchContractNotifications();
    checkUserPhoneNumber()
  }, [])


  const saveContractNotificationEvent = () => {
    let isError = false
    const payloadData = []
    for(let index = 0; index < contractNotificationData.length; index++) {
      if( 
        ( (/*contractNotificationData[index].location === "0" || */contractNotificationData[index].location === "default") 
          ||/*&&*/ (/*contractNotificationData[index].model === "0" || */contractNotificationData[index].model === "default")
          ||/*&&*/ (/*contractNotificationData[index].manufacture === "0" ||*/ contractNotificationData[index].manufacture === "default")
        )
        || (contractNotificationData[index].numberOfDays == 0) 
      ) {
        toast.error(`Please fill out all the required fields`);
        return;
      }
      if(contractNotificationData[index].notificationType === "default") {
        toast.error(`Please Select the valid Notification Type`);
        return;
      }
      payloadData.push({
        location_id: contractNotificationData[index].location === "0" ? null : contractNotificationData[index].location,
        notification_type: contractNotificationData[index].notificationType,
        model_id: contractNotificationData[index].model === "0" ? null : contractNotificationData[index].model,
        manufacturer_id: contractNotificationData[index].manufacture === "0" ? null : contractNotificationData[index].manufacture,
        days: contractNotificationData[index].numberOfDays
      });
    }
    if(!isError) {
      const payload = {
        contract_notification: payloadData
      }
      axios.post(`/contracts/create-contract-notification`, payload).then(response => {
        if(response.data.statusCode === 200) {
          toast.success("Contract Notification has been added.");
          fetchContractNotifications()
          handleRemoveNotification()
        }
      }).catch(err => {
        console.log('29=>',err)
        toast.error("Something went wrong");
      })
    }
  }

  return (
    <>
      {createNotification && <>
        {/* Creating Notification Form  */}
        <div className="flex items-center justify-between mb-16">
          <div>
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="font-semibold assets_catalog_header"
              // style = {{ color:'black'}}
            >
              Create Notifications
            </Typography>		
            <p className='sub_content_holder'>Select parameters as you see fit for the notifications</p>	
          </div>

          <div>
            <Button style={{marginRight: '12px !important'}} onClick={saveContractNotificationEvent} className='button primary_btn mr-12'>
              Save
            </Button> 
            <Button className='button danger_btn' onClick={() => handleClickOpenDeleteNotification()}>
              <i className="icon ti ti-x" />
              Discard
            </Button>
          </div>
        </div>

        <div style={{ borderBottom: '1px solid #EAECF0' }} className='mb-36'>
          {/* repeat this py-16 while adding another */}
          {/* <div className='py-16'> */}
            {
              contractNotificationData.map( (data, index) => {
                return (
                  <Fragment key={index}>
                    <div className='py-16'>
                      <div className='flex items-center' key={data.key}>
                      {/* {index} */}
                      <i className='ti ti-grip-vertical' />

                      <div className='flexbox_holder items-center w-full pl-20'>
                        <Box className='each_filter_holder pr-20'>
                          <InputLabel className='label_holder'>Notify me</InputLabel>
                          <FormControl fullWidth className='cstm_select_formcontrol'>
                            <TextField 
                              type="text"
                              InputLabelProps={{ shrink: true }}
                              variant='outlined'
                              label='' 
                              value={data.numberOfDays}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(event) => handleNumberOfDaysInputChange(event, index)}
                              placeholder='Days'
                              fullWidth 
                            />
                          </FormControl>
                        </Box>

                        <Box className='each_filter_holder pr-20'>
                          <InputLabel className='label_holder'>days before a contract for</InputLabel>
                          <FormControl fullWidth className='cstm_select_formcontrol'>
                            <Select
                              labelId="category-label"
                              id="category"
                              value={data.manufacture}
                              label=""
                              onChange={ (event) => handleManufacturerChange(event, index) }
                              MenuProps={{
                                sx: {
                                  maxHeight: 400,
                                  '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                  },
                                },
                              }}
                            >
                              <MenuItem disabled value="default">
                                <span>Manufacturers</span>
                              </MenuItem>
                              <MenuItem value="0">All</MenuItem>
                              {
                                manufactureData.map( (singleManufacture, manufactureIndex) => {
                                  return (
                                    <MenuItem key={`${manufactureIndex}_manu`} value={singleManufacture.id}>{singleManufacture.manufacturer_name}</MenuItem>
                                  );
                                })
                              }
                            </Select>
                          </FormControl>
                        </Box>

                        <Box className='each_filter_holder pr-20'>
                          <InputLabel className='label_holder'>for model</InputLabel>
                          <FormControl fullWidth className='cstm_select_formcontrol'>
                            <Select
                              labelId="model-label"
                              id="model"
                              value={data.model}
                              label=""
                              onChange={(event) => handleModelChange(event, index)}
                              MenuProps={{
                                sx: {
                                  maxHeight: 400,
                                  '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                  },
                                },
                              }}
                            >
                              <MenuItem disabled value="default">
                                <span>Model</span>
                              </MenuItem>
                              <MenuItem value="0">All</MenuItem>
                              {
                                data.modelList.map( (singleModel, modelIndex) => {
                                    return (
                                        <MenuItem key={`${modelIndex}_model`} value={singleModel.id}>{singleModel.model_no} </MenuItem>
                                    )
                                } )
                              }
                            </Select>
                          </FormControl>
                        </Box>

                        <Box className='each_filter_holder pr-20'>
                          <InputLabel className='label_holder'>at</InputLabel>
                          <FormControl fullWidth className='cstm_select_formcontrol'>
                            <Select
                              labelId="location-label"
                              id="location"
                              value={data.location}
                              label=""
                              onChange={(event) => handleLocationChange(event, index) }
                              MenuProps={{
                                sx: {
                                  maxHeight: 400,
                                  '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                  },
                                },
                              }}
                            >
                              <MenuItem disabled value="default">
                                <span>Location</span>
                              </MenuItem>
                              <MenuItem value="0">All</MenuItem>
                              {
                                locationData.map( (singleLocation, locationIndex) => {
                                  return (
                                      <MenuItem key={`${locationIndex}_loca`} value={singleLocation.id}>{singleLocation.location_name}</MenuItem>
                                  );
                                })
                              }
                            </Select>
                          </FormControl>
                        </Box>

                        <Box className='each_filter_holder'>
                          <InputLabel className='label_holder'>expires. Send my notifications via </InputLabel>
                          <FormControl fullWidth className='cstm_select_formcontrol'>
                            <Select
                              labelId="notification-label"
                              id="notification"
                              value={data.notificationType}
                              label=""
                              onChange={(event) => handleNotificationChange(event, index)}
                              MenuProps={{
                                sx: {
                                  maxHeight: 400,
                                  '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                  },
                                },
                              }}
                            >
                              <MenuItem disabled value="default">
                                <span>Notification</span>
                              </MenuItem>
                              <MenuItem value="SMS">SMS</MenuItem>
                              <MenuItem value="Email">Email</MenuItem>
                              <MenuItem value="Both">Both</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      {/* {
                        index !== 0 && 
                        <Button className='transparent_icon_btn' onClick={() => removeContractNotificationData(index)}>
                          <i style={{ fontSize: '20px', fontWeight: '500', color: '#F04438' }} className='ti ti-trash pl-8' />
                        </Button>
                      } */}
                      
                      </div>


                      <div className='flex justify-end items-center pt-16'>
                        <Button
                          className='add_anotherButton'
                          onClick={addContractNotificationData}
                          startIcon={
                            <Icon fontSize="small" title="Add Another">
                              add
                            </Icon>}
                        >
                          Add Another
                        </Button>
                        { contractNotificationData.length !== 1 && <Button className='transparent_icon_btn' onClick={() => removeContractNotificationData(index)}>
                          <i style={{ fontSize: '20px', fontWeight: '500', color: '#F04438' }} className='ti ti-trash pl-8' />
                        </Button> }
                        
                      </div>
                    </div>
                  </Fragment>
                  

                  
                );
              })
            }
            

            
          {/* </div> */}
          {/* END of repeat this py-16 while adding another */}
        </div>
        {/* END of Creating Notification Form  */}
        </>
      }

      <div className="flex items-center justify-between mb-16">
				<Typography
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="font-semibold assets_catalog_header"
					// style = {{ color:'black'}}
				>
					Your Notification Settings
				</Typography>			
        {createContractPermission && <>
          {!createNotification && <Button className='button primary_btn' onClick={() => handleCreateNotification()}>
          <i className="icon ti ti-plus" />
          Create Notification
        </Button>}
        </>}
				
      </div>

      <div 
        className="ag-theme-alpine" 
        style={{
          width: "100%",
          height: windowHeight+105,
          fontSize:'12px'
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          animateRows
          onGridReady = { onGridReady }
          paginationPageSize={10}
          pagination
        >
          {tableHeaders.map(header => {
              if( header.field === "parameters"){
                return(
                  <AgGridColumn 
                    key = {header.field} 
                    field = {header.field}
                    headerName = {header.headerName}  
                    width = { header.width }
                    minWidth = { header.minWidth }
                    maxWidth = { header.maxWidth }
                    sortable = {false}
                    flex = {1}
                    filter = "text"
                    cellRendererFramework={(event) => (<ContractParametersComponent data={event.data}/>)}
                    wrapText
                    autoHeight
                    // floatingFilter = {true}
                    headerComponentFramework = {header.headerComponentFramework}								
                  />
                )
              } else if( header.field === "status"){
                return(
                  <AgGridColumn 
                    key = {header.field} 
                    field = {header.field}
                    headerName = {header.headerName}  
                    width = { header.width }
                    minWidth = { header.minWidth }
                    maxWidth = { header.maxWidth }
                    sortable = {false}
                    flex = {1}
                    filter = "text"
                    cellRendererFramework={(event) => (<ContractStatusComponent status={event.data.status}/>)}
                    wrapText
                    // headerComponentFramework = {header.headerComponentFramework}								
                  />
                )
              } else if(header.field === "actions"){
                return (
                  <AgGridColumn 
                    key = {header.field} 
                    field = {header.field}
                    headerName = {header.headerName}  
                    width = { header.width }
                    minWidth = { header.minWidth }
                    maxWidth = { header.maxWidth }
                    sortable = {false}
                    flex = {1}
                    filter = "text"
                    wrapText
                    cellRendererFramework={(event) => (
                      <ContractActionComponent
                        data={event.data}
                        locationMenu={locationData}
                        locationList={locationData}
                        manufacturerList={manufactureData}
                        categoryMenu={manufactureData}
                        fetchNotificationSettings={fetchContractNotifications}
                        setOpenPhoneVerificationModal={setOpenPhoneVerificationModal}
                        isPhoneNumberVerified={isPhoneVerified}
                      />
                    )}
                    headerComponentFramework = {header.headerComponentFramework}								
                  />
                )
              } else {
                return(
                  <AgGridColumn 
                    // key = {header.field} 
                    field = {header.field}
                    headerName = {header.headerName}  
                    width = { header.width }
                    minWidth = { header.minWidth }
                    maxWidth = { header.maxWidth }
                    sortable = {false}
                    flex = {1}
                    filter = "text"
                    cellRendererFramework = {header.cellRendererFramework}
                    wrapText
                    // floatingFilter = {true}
                    headerComponentFramework = {header.headerComponentFramework}								
                  />
                )
              }
						})}
        </AgGridReact>
      </div>

      {/* discard notification modal */}
      <Dialog
        className="cstm_dialog_modal small text-center"
        open={openDeleteNotification}
        onClose={handleCloseOpenDeleteNotification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flex justify-end items-center w-full'>
            <Button className='transparent_icon_btn' onClick={handleCloseOpenDeleteNotification}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="warning_content_holder">
            <div className="exclamation_icon_holder yellow">
              <i className="ti ti-exclamation-mark" />
            </div>
            <h2 className="heading">Are you sure to discard the notification?</h2>
            <p className="sub_content">Your progress will be lost if you proceed with this.</p>
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={handleCloseOpenDeleteNotification} className="secondary_btn">No, go back!</Button>
          <Button onClick={handleRemoveNotification} className="primary_btn">
            Yes, I'm sure!
          </Button>
        </DialogActions>
      </Dialog>


      {openPhoneVerificationModal && (
        <PhoneNumberVerificationModal
          open={openPhoneVerificationModal}
          close={handleCloseOpenPhoneVerificationModal}
        />
      )}
    </>
  );
}
