import useToast from '@fuse/hooks/useToast'
import {
  Button,
  DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Tooltip, Typography,
  Drawer
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { Box } from '@mui/material';
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => ({
  uploadImageButton: {
    padding: '6px 16px',
    fontSize: '1.3rem',
    minWidth: '64px',
    boxSizing: 'border-box',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: '4px',
    textTransform: 'none',
    backgroundColor: '#6B2C57',
    color: '#ffffff',
    cursor: 'pointer'
  },
  loader: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  subSection: {
    margin: '0 20px',
    padding: '10px',
    backgroundColor: '#f4f4f4',
    borderRadius: '4px'
  },
  endButtons: {
    borderRadius: '4px'
  },
  drawerWidth: {
    width: 520,
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
}))

function AddCustomAttributeDialog(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
  const openState = useSelector((state) => state.modelCustomAttributeApp.formViewDialog)
  const [openAlerModal, setOpenAlertModal] = useState(false);
  const { data: editFromState } = useSelector(({ modelCustomAttributeApp }) => modelCustomAttributeApp.formViewDialog);
  const spanTemp = {
    attribute_name: '',
  }
  const [allSpan, setAllSpan] = useState([{...spanTemp}]);

  //Adding a new span
  const addNewSpan = () => {
    let newSpan = {
      ...spanTemp
    }
    setAllSpan((allSpan) => [...allSpan, newSpan])
  }

  //Deleting a span
  const deleteSpan = (index) => {
    setAllSpan(allSpan.filter((span, inx) => inx !== index))
  }

  //Handle value change in span input
  const handleSpanInput = (index, value, propName) => {
    setAllSpan((spans) => {
      spans[index][propName] = value
      return [...spans]
    })
  }

  const onCancel = () => {
    setAllSpan([])
    dispatch(setFromData(null))
    dispatch(closeFormViewerDialog())
  }

  const manageFormData = () => {
    console.log(allSpan)
    for(let span of allSpan){
      if(!Boolean(span.attribute_name.trim())){
        toast.error("Dynamic attribute name is required.")
        return
      }
    }

    let payload
    if (editFromState) { //edit
      payload = {
        attribute_name: allSpan[0].attribute_name,
      }
    } else {//create
      payload = {
        attribute_names: allSpan,
      }
    }
    let api_url

    if (editFromState) { //edit
      api_url = `/model-custom-attributes/update/${editFromState.id}`
    } else {
      api_url = '/model-custom-attributes/add'
    }
    axios.post(api_url, payload).then((res) => {
      if (res.status == 201) {
        toast.error(res.data.message)
        // onCancel()
      } else {
        toast.success(res.data.message)
        dispatch(setQuickFilter(null))
        props.getModelDynamicAttributes()
        onCancel()
      }
    }).catch((err) => {
      console.log('error', err);
      toast.error("Something went wrong!")
    })
  }

  useEffect(() => {
    if (editFromState) {
      setAllSpan([{
        attribute_name: editFromState.attribute_name
      }])
    }
  }, [editFromState])

  const handleAlertModal=(_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenAlertModal(!openAlerModal)
}

const handleCloseAlertModal=() =>{
    setOpenAlertModal(false)
    onCancel()
}

  return (
    <>
    <div>
      <Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
        <div className='heading_holder'>
          <h4 className='heading'>{editFromState ? 'Update' : 'Add'} Model Dynamic Attributes</h4>
          <IconButton style={{ padding: '0px' }} onClick={handleAlertModal}>
            <Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
          </IconButton>
        </div>
        <div className='progressbar_holder'>
          <div className='progress'>
            &nbsp;
          </div>
        </div>
        {/*----------------------------------------------------Item Information---------------------------------------------------------------- */}
        <div style={{ paddingTop: '30px' }} className='cstm_wizard_holder'>
          <Box sx={{ width: '100%' }} className='box_holder'>
            <div className='main_content_holder'>
              <div className='content_holder'>
                <div className="text-left">
                  <div className='mb-10'>
                    <Typography className='status_text'>
                      Model Attribute
                      <span className='required_span'>*</span>
                    </Typography>
                  </div>
                  {
                    allSpan.map((span, index) => {
                      return (
                        <div className='flex justify-between items-center mb-10'>
                          <TextField
                            variant='outlined'
                            autoFocus
                            style={{ background: 'white', width: 'calc(100% - 60px)' }}
                            required
                            value={span.attribute_name}
                            onChange={event => {
                              handleSpanInput(index, event.target.value, "attribute_name")
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: 0,
                                width: '100%',
                                paddingTop: '2px'
                              }
                            }}
                          />
                          {
                            (!editFromState && (index === 0 ?
                              <Tooltip title='Add More' placement='top'>
                                <IconButton style={{ flexGrow: 1, maxWidth: "48px" }} onClick={() => addNewSpan()}>
                                  <Icon>add_circle_outline</Icon>
                                </IconButton>
                              </Tooltip>
                              :
                              <Tooltip title='Remove Span' placement='top'>
                                <IconButton style={{ flexGrow: 1, maxWidth: "48px" }} onClick={() => deleteSpan(index)} >
                                  <Icon>cancel_circle</Icon>
                                </IconButton>
                              </Tooltip>
                            ))
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className='btn_holder'>
                <Button
                  color="inherit"
                  onClick={handleAlertModal}
                  className='back_btn secondary_btn'
                >
                  Cancel
                </Button>
                <Button onClick={manageFormData} className='next_btn'>
                  Proceed
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </Drawer>
    </div>
    {openAlerModal &&
                <AlertModal  openAlerModal module="dynamic attributes" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
            </>
  )
}

export default AddCustomAttributeDialog
