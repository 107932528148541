import { React, useState, useRef, useEffect } from "react";
import { Tabs, Tab, Breadcrumbs, Button, Select, MenuItem, FormControlLabel, Checkbox, Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Link } from "react-router-dom";
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import axios from "@fuse/utils/axios";
import { useDispatch, useSelector } from 'react-redux';
import useToast from "@fuse/hooks/useToast";
import StatusComponent from './StatusComponent'
import excel from "../images/ms_excel.png";
import uploadImg from "../images/upload.png";
import uploadGif from "../images/upload.gif"
import tickIcon from "../images/icons8-tick 1.gif";
import checkedIcon from "../images/check-verified-03.png";
import alertTriangle from "../images/alert-triangle.png"
import { setFormData, setCsvUploaded, setCsvMetaData } from '../../reports/store/reportViewDialogReducer';
import MapDataComponent from './MapDataComponent'
import { usePermission } from '@fuse/hooks';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';


export default function CSV() {
	const gridRef = useRef();
	const [overWrite, setOverWrite] = useState(false);
	const [contentView, setContentView] = useState('import');
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);
	const [recordTabValue, setRecordTabValue] = useState(0);
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [csvFields, setCsvFields] = useState([]);
	const [systemFields, setSystemFields] = useState([]);
	const [dynamicFields, setDynamicFields] = useState([]);
	const [primaryFields, setPrimaryFields] = useState([]);
	const [allFields, setAllFields] = useState([]);
	const [mappedFields, setMappedFields] = useState([]);
	const [systemRequiredFields, setSystemRequiredFields] = useState([]);
	const [uploadApiCall, setUploadApiCall] = useState(false);
	const [csvUploadSuccess, setCsvUploadSuccess] = useState(false);
	const [fileName, setFileName] = useState('');
	const [moduleName, setModuleName] = useState('');
	const [disableImportPageNextBtn, setDisableImportPageNextBtn] = useState(false);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [moduleUpdatePermission, setModuleUpdatePermission] = useState([]);
	const [fileAlreadyUploaded, setFileAlreadyUploaded] = useState(false);
	const [clearFile, setClearFile] = useState(false);

	const { hasPermission } = usePermission()
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
  const user = useCurrentUser();

	const moduleApiMap = {
		"asset":'/assets/get-csv-mapping-fields',
		"asset_category": '/category/get-csv-mapping-fields',
		'manufacturer': '/manufacturers/get-csv-mapping-fields',
		'model': '/models/get-csv-mapping-fields',
		'accessory': '/accessory/get-csv-mapping-fields',
		'asset_status': '/asset-status/get-csv-mapping-fields',
		'accessory_category': '/accessory-category/get-csv-mapping-fields',
		'contract': '/contracts/get-csv-mapping-fields',
		'service_provider': '/service-provider/get-csv-mapping-fields',
		'user': '/user/get-csv-mapping-fields',
		'location': '/shipping/get-csv-mapping-fields'
	}

	useEffect(()=>{
		setModuleName((window.location.search).split('=')[1])
		return()=>{
			dispatch(setFormData(null))
		}
	},[])

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	useEffect(()=>{
		setModuleUpdatePermission(
			{
				"asset": hasPermission('update-assets'),
				"asset_category": hasPermission('update-asset_category'),
				'manufacturer': hasPermission('update-manufacture'),
				'model': hasPermission("update-model"),
				'accessory': hasPermission('update-accessory'),
				'asset_status': hasPermission('update-asset_status'),
				'accessory_category':  hasPermission('update-accessory_category'),
				'contract': hasPermission('update-contract'),
				'service_provider': hasPermission("update-service_provider"),
				'user': hasPermission('update-end-user'),
				'location': hasPermission('update-location')
			}
		)
	},[moduleName])

	const generateLabel = (header) => {
			return header?.split('_').map(word => {
				return word.charAt(0).toUpperCase() + word.substring(1)
			}).join(' ')
		
		
	}

	const handleOverWriteChange = () => {
		setOverWrite(!overWrite)
	}

	const handleNextFromMapData = () => {
		setUploadApiCall(true)
		setContentView('map')
		// setOpenUploadModal(true);
	};

	const handleNext = (event) => {
		if(event == 'import'){ 
			setFileAlreadyUploaded(true) 
			setClearFile(false)
		}
		else setFileAlreadyUploaded(false)
		const data = JSON.parse(localStorage.getItem("mapData"))
		const fileData = JSON.parse(localStorage.getItem("fileData"))
		setFileName(fileData?.meta?.name)
		const { csv_fields, system_fields, dynamic_fields, primary_fields, all_fields, system_required_fields, field_mapping } = data;
		setCsvFields(csv_fields)
		setSystemFields(system_fields);
		setDynamicFields(dynamic_fields);
		setAllFields(all_fields)
		setPrimaryFields(primary_fields)
		const filterdFields = field_mapping || {};
		setMappedFields(csv_fields.map((item, index) => {
			return {
				field: item,
				value: filterdFields[item] ?? ""
			};
		}));
		setSystemRequiredFields(system_required_fields)
		setContentView(event)

		if(event === 'preview') {
			setOpenUploadModal(false);
		}		
	}

	const changeUploadState = (props) => {
		setFileAlreadyUploaded(false)
		props?.fileWithMeta?.cancel();
		setClearFile(true)
		setTimeout(() => {
			props?.fileWithMeta?.remove();
		}, 100)
		dispatch(setFormData(null))
	}

	const Preview = ( props, alreadyUploaded ) => {
		// if(clearFile){
		// 	return ''
		// }
		return (
		  <span className="preview_span_holder">
			<div className="info_outer_holder">
				<div className="info_holder">
					{!alreadyUploaded && <h4 className="heading">
						{props.meta.status !== 'done' ? 'Uploading...' : 'Uploaded'}
					</h4>}
					{alreadyUploaded && <h4 className="heading">
						{'Uploaded'}
					</h4>}
					{!alreadyUploaded && <div className="inner_holder">
						<span>{Math.round(props.meta.percent)}%</span>
						<span>{props.meta.name}</span>
					</div>}
					{alreadyUploaded && <div className="inner_holder">
						<span>{100}%</span>
						<span>{props.meta.name}</span>
					</div>}
				</div>
				<div className="icon_holder" onClick={(props) => changeUploadState(props)}>
					<i className="ti ti-x" />
				</div>
			</div>
			{!alreadyUploaded && <div className="progressbar_holder">
				<div className="progress" style={{height: '8px', width: `${Math.round(props.meta.percent)}%`}}>&nbsp;</div>
			</div>}
			{alreadyUploaded && <div className="progressbar_holder">
				<div className="progress" style={{height: '8px', width: `${100}%`}}>&nbsp;</div>
			</div>}
		  </span>
		)
	}

	

	const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }, fileAlreadyUploaded) => {
		if(previews.length == 0) dispatch(setCsvUploaded(false))
		else if(fileAlreadyUploaded)	dispatch(setCsvUploaded(true))
		else if(previews.length && previews[0]?.props?.meta?.percent == 100 && previews[0]?.props?.meta?.status == 'done') {
			dispatch(setCsvUploaded(true))
		}
		return (
		  <div className={`react_dropzone_holder ${files.length > 0 ? 'filled' : 'blank'}`}>

			{(previews[0]?.props?.meta?.percent < 100 && previews[0]?.props?.meta?.status !== 'done') && !fileAlreadyUploaded ? 
				<div className="loading_holder">
					<img src={uploadGif} alt="gif" />
					<p className="text_holder">File is uploading</p>
				</div> : 
				<>
					<img className="image" src={uploadImg} alt="icon" />
					<div className="temp_holder">
						<h4 className="heading headingDisable">Drag and drop here to upload</h4>
						<div className="or_holder after_or">
							<span>or</span>
						</div>
					</div>

					<div {...dropzoneProps}>
						{files.length < maxFiles && input}
						<div className="outsource_holder">
							<div className="or_holder">
								<span>or</span>
							</div>
							<Button onClick={()=>{setClearFile(false)}} className="browse_btn light_grey_btn">Browse</Button>
						</div>
					</div>
				</>
			}
			
			<div className="preview_holder">
				{previews}
			</div>

			{files.length > 0 && submitButton}
		  </div>
		)
	}
	  
	const CustomLayout = () => {
		const formData = useSelector(
			(state) => {
				return state.reportApp.reportViewDialogReducer.formData}
		);
		const getUploadParams = ({meta}) => {
			return { url: 'https://httpbin.org/post' }
		}

		function handleFieldMapping(fileWithMeta) {
			// if(clearFile) fileWithMeta?.xhr?.abort()
			// setSelectedFile(fileWithMeta.file)
			// dispatch(setCsvMapData(fileWithMeta.file))
			if (!fileWithMeta) return;
			const formData = new FormData();
			dispatch(setFormData(fileWithMeta.file))
			formData.append("file", fileWithMeta.file);
			axios.post(moduleApiMap[moduleName], formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(res => {
				const { data } = res.data;
				localStorage.setItem("mapData", JSON.stringify(data))
				localStorage.setItem("fileData", JSON.stringify(fileWithMeta))
				setDisableImportPageNextBtn(false)
			}).catch(err => {
				if (err.response) {
					if (err.response.status == 409) {
						setDisableImportPageNextBtn(true)
						toast.error(err.response.data.message)
					}
				}
				console.log(err)
			})
		}
		return (
			<Dropzone
				getUploadParams={getUploadParams}
				onChangeStatus={handleFieldMapping}
				LayoutComponent={(props)=>(Layout(props, fileAlreadyUploaded))}
				// onSubmit={handleSubmit}
				inputContent={(files, extra) => ('Drag and drop here to upload')}
				inputWithFilesContent={(files, extra) => ('Browse')}
				accept=".csv"
				multiple = {false}
				initialFiles={clearFile?'':(formData && [formData])}
				// initialFiles={formData && [formData]}
				maxFiles={1}
				PreviewComponent={(props)=>(Preview(props, fileAlreadyUploaded))}
			/>
		)
	}

	const reformatCSVmappingField = (fieldNameVal) => {
		
		const fieldName = fieldNameVal
		return fieldName.split('_').map(str => str === 'id' ? str.toUpperCase() : str.charAt(0).toUpperCase() + str.slice(1)).join(' ')
	}

  	return (
		<div className="page_content_holder">	
		
			<div role="presentation" className="cstm_breadcrumbs_holder">
				<Breadcrumbs aria-label="breadcrumb">
					<Link underline="hover" color="inherit" to="/roles">
						System Settings
					</Link>
					
					<span>CSV Upload</span>
				</Breadcrumbs>
				{<MapDataComponent uploadApiCall = {uploadApiCall} mappedFields = {mappedFields} setUploadApiCall = {setUploadApiCall} 
					overWrite = {overWrite} handleNext = {handleNext} contentView = {contentView} moduleName={moduleName} setOverWrite = {setOverWrite}/>}

				{ contentView === 'import' && <div>
					{/* <div className="flex flex-wrap justify-between mb-36">
						<h4 className="main_page_heading mt-12">Import CSV File</h4>
						<Button className="primary_maroon_btn" onClick={() => handleNext('map')}>Nextt</Button>
					</div> */}
					<NextButtonInImportPage handleNext = {handleNext} disableImportPageNextBtn={disableImportPageNextBtn}/>

					<div className="white_card_holder">
						<div className="mb-16">
							<h4 className="sub_main_heading_holder">Upload CSV for {generateLabel(moduleName)}</h4>
							<p className="sub_txt">Add your documents here, and you can upload up to 1 file max {moduleName == 'asset'? 'and you can add up to 2,500 assets at a time':''}</p>
						</div>
						<CustomLayout />
					</div>
				</div> }
				
				{ contentView === 'map' && <div>
					<div className="flex flex-wrap justify-between mb-36">
						<h4 className="main_page_heading mt-12">Map Data</h4>
						<div>
							<Button className="primary_maroon_outlined_btn mr-10" onClick={() => {
								setOverWrite(false)
								handleNext('import')}}>Back</Button>
							<Button className="primary_maroon_btn" onClick={() => handleNextFromMapData()}>Next</Button>
						</div>
					</div>

					<div className="badge_info_holder mb-36">
						<img src={excel} alt="icon" />
						<span className="text">{fileName}</span>
						<Button className="grey_btn" onClick={() => {
							dispatch(setFormData(null))
							handleNext('import')
							setOverWrite(false)
							}}>
							<i className="ti ti-reload" />
						</Button>
					</div>

					<div className="table_responsive cstm_table_holder mb-36">
						<table>
							<thead>
								<tr>
									<th>Fields Detected in CSV</th>
									<th>Available Fields for {generateLabel(moduleName)}</th>
								</tr>
							</thead>
							<tbody>
								{mappedFields.map((({ field, value }, index) =><tr key={index}>
									<td>{field}</td>
									<td>
										<div className="select_holder">
											{moduleName != 'contract' && <Select
												className="w-full"
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={value}
												onChange={(event) => {
													setMappedFields((mapedItem) => {
														mapedItem[index] = {
															...mapedItem[index],
															value: event.target.value
														};
														return [...mapedItem]
													})
												}}
												// disabled={overWrite}
											>
											<MenuItem value="">None</MenuItem>
											<MenuItem disabled>
												<em><strong style={{ fontWeight: 800 }}>Primary fields</strong></em>
											</MenuItem>
											{primaryFields.map((option) => (
												<MenuItem key={option.field}
													value={option.field}
													disabled={mappedFields.map((item) => item.value).includes(option.field)}>

													<span>{option.label}</span>
													{option.field != 'email_address' && option.field != 'first_name'
														&& option.field != 'last_name' && option.field != 'title' && (
															<span style={{ color: "red" }}>*</span>)}
												</MenuItem>
											))}
											<MenuItem disabled>
												<em><strong style={{ fontWeight: 800 }}>System fields</strong></em>
											</MenuItem>
											{systemFields.map((optionField) => (
												<MenuItem key={optionField}
													value={optionField}
													disabled={mappedFields.map((item) => item.value).includes(optionField)}>
													{reformatCSVmappingField(optionField)} {systemRequiredFields.includes(optionField) && <span style={{ color: "red" }}>*</span>}
												</MenuItem>
											))}
											</Select>}
											{moduleName == 'contract' &&  <Select
											assName="mt-5 w-100"
											variant="outlined"
											fullWidth 
											value={value}
											// MenuProps={menuProps}
											onChange={(event) => {
												setMappedFields((mapedItem) => {
													mapedItem[index] = {
														...mapedItem[index],
														value: event.target.value
													};
													return [...mapedItem]
												})
																	}}>
												<MenuItem value="">None</MenuItem>

												<MenuItem disabled>
													<em><strong style={{fontWeight: 800}}>Primary fields</strong></em>
												</MenuItem>

												{primaryFields.map((option) => (
													<MenuItem key={option.field} 
													value={option.field} 
													disabled={mappedFields.map((item) => item.value).includes(option.field)}>
														<span>{option.label}</span> <span style={{ color: "red" }}>*</span>
													</MenuItem>
												))}

												<MenuItem disabled>
													<em><strong style={{fontWeight: 800}}>System fields</strong></em>
												</MenuItem>
												{systemFields.map((optionField) => (
													<MenuItem key={optionField.field} 
													value={optionField.field} 
													disabled={mappedFields.map((item) => item.value).includes(optionField.field)}>
														{optionField.label} {systemRequiredFields.includes(optionField.field) && <span style={{color: "red"}}>*</span>}
													</MenuItem>
												))}

												{dynamicFields.length && <MenuItem disabled>
													<em><strong style={{fontWeight: 800}}>Custom fields</strong></em>
												</MenuItem>}

												{dynamicFields.length && dynamicFields.map((optionField) => (
													<MenuItem key={optionField} 
													value={optionField}
													disabled={mappedFields.map((item) => item.value).includes(optionField)}>{optionField}</MenuItem>
												))}

												<MenuItem value="Create new field"><span style={{color: "#388e3c"}}>Create new field</span></MenuItem>
											</Select>}
										</div>
									</td>
								</tr>))}
							</tbody>
						</table>
					</div>

					<div>
						{/* <Button className="light_grey_btn">Add Another</Button> */}
						{moduleUpdatePermission[moduleName] && <span className="checkbox_holder">
							<FormControlLabel
								control={
									<Checkbox checked={overWrite} onChange={handleOverWriteChange} />
								}
								label="Overwrite"
							/>
						</span>}
					</div>
				</div> }
			</div>
		</div>
	);
}

function NextButtonInImportPage({handleNext, disableImportPageNextBtn}){
	const[fileUploaded, setFileUploaded] = useState(false)
	const file_uploaded = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.file_uploaded
  );

	useEffect(()=>{
		setFileUploaded(file_uploaded)
	},[file_uploaded, disableImportPageNextBtn])

	return (
		<>
			<div className="flex flex-wrap justify-between mb-36">
				<h4 className="main_page_heading mt-12">Import CSV File</h4>
				<Button disabled={(!fileUploaded || disableImportPageNextBtn)} className={!(!fileUploaded || disableImportPageNextBtn)?"primary_maroon_btn":"light_grey_btn"} onClick={() => handleNext('map')}>Next</Button>
			</div>
		</>
	)
}

