const initialState = {
    open: false,
    default_loc: [],
    edit_data: null,
    data: false,
    refresh: false
}

const actionTypes = {
    OPEN_FORM_VIEWER_DIALOG: 'OPEN_FORM_VIEWER_DIALOG',
    CLOSE_FORM_VIEWER_DIALOG: 'CLOSE_FORM_VIEWER_DIALOG',
    ADD_LOCATION_DETAILS: 'ADD_LOCATION_DETAILS',
    EDIT_FORM_VIEWER_DIALOG: 'EDIT_FORM_VIEWER_DIALOG',
    CALL_GET_DATA_URL: 'CALL_GET_DATA_URL',
    REFRESH_TABLE_AFTER_DELETE: 'REFRESH_TABLE_AFTER_DELETE',
}

export function openFormViewerDialog() {
    return {
        type: actionTypes.OPEN_FORM_VIEWER_DIALOG
    }
} 

export function refreshTableAfterDelete() {
    return {
        type: actionTypes.REFRESH_TABLE_AFTER_DELETE
    }
} 

export function getDataUrl(edit_data=null) {
    return {
        type: actionTypes.CALL_GET_DATA_URL,
        payload: {
			edit_data
		}
    }
} 

export function editFormViewerDialog(edit_data=null) {
    return {
        type: actionTypes.EDIT_FORM_VIEWER_DIALOG,
        payload: {
			edit_data
		}
    }
} 

export function addLocation(default_loc=null) {
    return {
        type: actionTypes.ADD_LOCATION_DETAILS,
        payload: {
			default_loc
		}
    }
}

export function closeFormViewerDialog() {
    return {
        type: actionTypes.CLOSE_FORM_VIEWER_DIALOG
    }
}

export default function (state = initialState, action) {
    switch(action.type) {
        case actionTypes.OPEN_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open: true
            }
        }

        case actionTypes.CLOSE_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open:false
            }
        }

        case actionTypes.ADD_LOCATION_DETAILS: {
            return {
                ...state,
                default_loc: action.payload.default_loc,
                }

        }

        case actionTypes.EDIT_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                edit_data: action.payload.edit_data,
                }

        }
        case actionTypes.REFRESH_TABLE_AFTER_DELETE: {
            return {
                ...state,
                refresh: true
            }

        }

        case actionTypes.CALL_GET_DATA_URL: {
            return {
                ...state,
                data:true
                }

        }
        
        


        default:
            return state
    }
}