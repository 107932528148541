import { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Paper, MenuItem, Popper, Fade, List } from '@material-ui/core/';

const autoSuggestionEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const refInput = useRef(null);
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [suggestions, setSuggestions] = useState(props.suggestions[props.suggestionField]);
    const [open, setOpen] = useState(false);
    const [placeHolder, setPlaceHolder] = useState('Fetching suggestions ...');
 
    useEffect(() => {
        // focus on the input
        setTimeout(() => refInput.current.focus());
    }, []);

    useEffect(() => {
        setPlaceHolder('Fetching suggestions ...')
    },[value])

    useEffect(() => {
        if(!suggestions || suggestions.length <= 0){
            setPlaceHolder('No Suggestions Found')
        }
    },[suggestions])
 
    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                // this simple editor doubles any value entered into the input
                return value;
            },
 
            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            },
 
            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                // our editor will reject any value greater than 1000
                return value > 1000;
            }
        };
    });
 
    return (
        <>
        <input 
            ref={refInput}
            value={value}
            style={{width: "100%"}}
            onChange={(e)=>{
                setValue(e.target.value);
                if(!open){
                    setAnchorEl(e.currentTarget);
                    setOpen(true);
                }
                props.getSuggestions(props.suggestionField, e.target.value,(suggestions)=>{
                    setSuggestions(suggestions)
                });
                if(props.updateColValue){
                    props.updateColValue(props.colDef.field, event.target.value)
                }
            }}
        />
        <Popper open={open} anchorEl={anchorEl} placement="bottom-start" transition>
            {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Paper style={{borderRadius:5,maxHeight:150, overflow: 'auto',minWidth:200}}>
                    <List>
                    {
                        suggestions && suggestions.length > 0 ?
                        suggestions.map((suggestion, index)=>(
                            <MenuItem key={index} value={suggestion[props.suggestionField]}
                            onClick={()=>{props.onSuggestionSelect(props.suggestionField, props, suggestion, (val)=>setValue(val))}}
                            >
                                {suggestion[props.suggestionField]}
                            </MenuItem>
                        ))
                        :
                        <MenuItem key={1} disabled
                            >
                                {placeHolder}
                        </MenuItem>
                    }
                    </List>
                </Paper>
            </Fade>
            )}
        </Popper>
        </>
    );
 });

 export default autoSuggestionEditor;