import _ from '@lodash';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import ListView from './listView';
import GridView from './gridView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function EndUsersAccountsTable(props) {
    const classes = useStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [loading, setLoading] = useState(false);
	let endUsersAccountsMod = props.endUsersAccounts
		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event.target.value);
	}

	if(loading){
		return (<FuseLoading/>)
	}

	if(endUsersAccountsMod.length <= 0){
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-center h-full"
			>
				<div style={{height: "200px"}}>
					<Typography variant="body2" component="p" color="textSecondary">
						No data found
					</Typography>
				</div>
			</motion.div>
		)
	}

	return (
		<div className="w-full flex flex-col">			
			{/* <motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-end h-full"
			>
				<Button
				color="primary"
					onClick={()=>props.openAddAttributeDialog(props.platformModuleId)}
				>
					Add field
				</Button>
			</motion.div> */}
			<SwitchView 
				selectedView={props.selectedView} 
				endUsersAccounts={endUsersAccountsMod} 
				gotoCompanyDetail={props.gotoCompanyDetail} 
			/>
			<TablePagination
				className="flex-shrink-0 border-t-1 mt-10"
				component="div"
				count={props.endUsersAccounts.length}
				rowsPerPage={rowsPerPage}
				page={page}
				backIconButtonProps={{
					'aria-label': 'Previous Page'
				}}
				nextIconButtonProps={{
					'aria-label': 'Next Page'
				}}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</div>
	);

}

function SwitchView({endUsersAccounts,selectedView}) {
	if(selectedView == 0){
		return(<ListView endUsersAccounts={endUsersAccounts}/>)
	}else{
		return(<GridView endUsersAccounts={endUsersAccounts}
		/>)		
	}
}

export default withRouter(EndUsersAccountsTable)
