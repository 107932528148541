import _ from '@lodash';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { useDispatch } from 'react-redux';
import { useRouter } from '@fuse/hooks';
import { setContractId } from '../../../../contracts/store/filterParamsReducer'
import { getContractStatus } from 'helper/status';

const useStyles = makeStyles({
    layoutRoot: {},
    head: {
        position: "sticky",
        top: 0
    },
    root: {
        // minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    row:{
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom:"25px",
        display: 'flex'
    },
    column:{
        // border: '2px solid black',
        width: '100%',
        textAlign: 'left'
    }
});

function ContractsTable({assetDetails}) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const router = useRouter()
    const [contractData,setContractData] = useState(null)
    const [loading,setLoading] = useState(true)

    function redirectToContractDetailsPage(params){
        dispatch(setContractId(params.id))
        router.push('/contracts')
    }

    useEffect(()=>{
        if(!assetDetails) return
        axios.get(`/assets/${assetDetails.id}/contarct`).then((res)=>{
            setContractData(res.data.data.asset_contact.contract_header)
        }).catch((err)=>{
            console.log('err',err);
        }).finally(()=>{
            setLoading(false)
        })

    },[assetDetails])

    if(loading){
		return (
            <div style={{height: 290}}>
                <FuseLoading/>
            </div>
        )
	}

    return (
        <>
            <div className='cstm_aggridtable_holder'>
				<table style={{maxWidth: '650px'}}>
					<thead>
						<tr>							
							<th>Attributes</th>
                            <th>Value</th>
						</tr>
					</thead>
					<tbody>
						<tr>
                            <td>Contract Name</td>
							<td>{contractData?.contract_name ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>Contract Number</td>
                            {
                                contractData ? (
                                    <td>
                                        <a style={{color: "black",cursor: 'pointer'}} onClick={()=>redirectToContractDetailsPage(contractData)}>{contractData?.entity_external_platform_id}</a>
                                    </td>
                                ) : (
                                    <td>N/A</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td>Tag</td>
                            <td>
                                <span>
                                    {contractData?.contract_header_status?.contract_status_name ?? 'N/A'}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>
                                <span>
                                    {getContractStatus(contractData?.contract_end_date)?.status ?? 'N/A'}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Contract Type</td>
                            <td>{contractData?.contract_headers_type?.display_name ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>Service Provider Name</td>
                            <td>{contractData?.contract_service_provider?.service_provider_name ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>Service Level Agreement</td>
                            <td>{contractData?.service_level_agreement?.sla_name ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>Start Date</td>
                            <td>{contractData?.contract_start_date ? dateReformatTo_mmm_dd_yyyy(contractData.contract_start_date) : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>End Date</td>
                            <td>{contractData?.contract_end_date ? dateReformatTo_mmm_dd_yyyy(contractData.contract_end_date) : 'N/A'}</td>
						</tr>
					</tbody>
				</table>
			</div>
        </>
    )
}

export default withRouter(ContractsTable)
