import React from 'react';

import { Drawer as DrawerMaterial, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    drawerPaper: {
      marginTop: "70px",
      borderRadius: "20px 0px 0px 0px"
    }
}))

export default function Drawer(props) {
    const {children} = props
    const classes = useStyles()

  return (<DrawerMaterial 
            {...props} 
            classes={{
                paper: classes.drawerPaper
                }}
            >
                {children}
        </DrawerMaterial>
    )
}