import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withReducer from 'app/store/withReducer';
import clsx from 'clsx';
import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
// import endUsersAccounts from '../shared/endUsersAccounts';
import EndUsersAccountsTable from '../../tables';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import lodash from "lodash"
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import reducer from '../../../store/index';
import EmailDialog from './emailDialog'
import { openEmailDialog, closeEmailDialog } from '../../../store/emailDialogReducer';

import download from 'downloadjs';

const drawerWidth = 400;
const headerHeight = 200;

const views = ['List View','Grid View'];

const defaultCounts = {end_users:0, locations:0};

const defaultSearches = {location:'',quote_indicator:'',quote_status:'',revenue:'',action:''}

const useStyles = makeStyles(theme => ({
	'@global': {
		'#fuse-main': {
			height: '100vh'
		}
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: '100%',
		position: 'relative',
		// flex: '1 1 auto',
		height: 'auto',
		backgroundColor: theme.palette.background.default
	},
	cardGen: {
		width: '100%',
	},
	topBg: {
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none',
		padding:"10px"
	},
	topBgFixed: {
		height: headerHeight,
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none'
	},
	contentCardWrapper: {
		maxWidth: 1400,
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 0 auto',
		width: '100%',
		minWidth: '0',
		margin: '0 auto',
		// [theme.breakpoints.down('sm')]: {
		// 	padding: '16px 16px 0 16px'
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	padding: '12px 12px 0 12px'
		// }
	},
	contentCard: {
		display: 'flex',
		position: 'relative',
		// flex: '1 1 100%',
		flexDirection: 'row',
		backgroundColor: theme.palette.background.paper,
		minHeight: 0,
		overflow: 'hidden',
		borderRadius: '2px 2px 0 0',
	},
	drawerPaper: {
		width: drawerWidth,
		maxWidth: '100%',
		overflow: 'hidden',
		height: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'relative'
		}
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 1 100%',
		zIndex: 10,
		background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
			theme.palette.background.paper,
			0.6
		)} 20%,${fade(theme.palette.background.paper, 0.8)})`
	},
	content: {
		display: 'flex',
		// flex: '1 1 100%',
		minHeight: 0
	},
	avatar: {
		backgroundColor: red[500],
	},
	formControl: {
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	formControlMinWidth: {
		minWidth: 200,
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	card: {
		borderRadius:"12px"
	},
	cardContainer:{
	},
	borderRightLight: {
		borderRight:"1px solid rgba(0,0,0,0.12)"
	},
	buttonSquare:{
		borderRadius: '5px'
	}
}));

function Main(props) {
	const table_name = "INVOICE_DETAILS_LISTING";
	const dispatch = useDispatch();
	const classes = useStyles(props);
	const router = useRouter();
	const [loading, setLoading] = useState(false);
	const [endUsersAccountsMod,setEndUsersAccounts] = useState([]);
	const [flattenedEndUserAccounts,setFlattenedEndUserAccounts] = useState({});
	const [invoice, setInvoice] = useState(null);
	const [formattedInvoice, setFormatedInvoice] = useState(null);

	function toNumberFormat(numberString){
		const number = Number(numberString);
		let fixed = number.toFixed(2);
		let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
		let [formatedNumber] = number.toLocaleString().split(".");
		const result = `$${formatedNumber}.${secondNumber}`;
		return result
	}

	useEffect(()=>{
		const id = router.params.id;

		setLoading(true);

		axios.get(`/invoices/${id}`)
		.then(res => {
			const { data } = res.data;
			setInvoice(data)
			setFormatedInvoice(formatInvoice(data))
		})
		.catch(err => {
			console.log(err);
		}).finally(() => {
            setLoading(false);
        })
		
	},[]);

	function formatInvoice(invoice){
		const dynamicAttributes = Object.entries(invoice.dynamic_attributes).reduce((acc, [key, item]) => {
			acc[key] = item.attribute_value

			return acc;
		},{});

		return {
			...lodash.omit(invoice, [
				"id",
				"platform_customer_id",
				"dynamic_attributes",
				"invoice_items",
				"created_at",
				"updated_at",
			]),
			...dynamicAttributes,
		}
	}

	function formatInvoiceItems(data){
		return data.map((invoiceItem) => {
			return {
				...lodash.omit(invoiceItem, [
					"id",
				    "invoice_number",
					"invoice_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
				]),
			}
		})
	}

	function toWord(str = ""){
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}


	function downloadPDF(){
		const id = router.params.id;

		// console.log('on pdf download');
		axios.get(`/invoices/download-pdf/${id}`,{
			responseType:'blob'
		}).then(response=>{
           const content = response.headers['content-type'];
           download(response.data, `invoice_detail_${id}_${(new Date()).toISOString().split('T')[0]}.pdf`, content)

		})
	}

	const onGridReady = (params) =>{
		loadInitialState(params);
	}

	const onColumnMoved = (params) =>{
		const rows = params.columnApi.getColumnState();
		saveView(rows);
	}

	function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
			saveView(rows)
		}
	}
	
	function saveView (rows){
        const order = JSON.stringify(rows);
        axios.post('/user-view/save-view-order',{
            table_name : table_name,
            order : order
        })
        .then((results)=>{
			console.log('view updated')
        })
        .catch(err=>{
            console.log(err);
        })
    }

	function loadInitialState (params){
        axios.get(`/user-view/show-view-order?table_name=${table_name}`)
        .then(results=>{
			const { data } = results.data;
			if(data !== null){
				const order = JSON.parse(data.order);
				params.columnApi.applyColumnState(order);
			}
        })
        .catch(err=>{
            console.log(err);
        })
    }

	if(loading){
		return(
			<FuseLoading/>
		)
	}

	return (<>
		
		<div className={clsx(classes.root)}>
			<EmailDialog invoice_id={router.params.id} />	
			<FuseScrollbars className="flex-grow overflow-x-auto">
			<div className={classes.card} >
				{formattedInvoice && 
					<Grid container spacing={2} style={{paddingTop:10}}>
						{/* {Object.keys(formattedInvoice).map((header) => (
							<Grid item xs={6} sm={3} lg={4}>
									<Card className={classes.card}>
										<CardContent className={classes.cardContainer}>
											{toWord(header)}
											
											<span style={{fontWeight:"bold"}}>
												{formattedInvoice[header]}
											</span>
										</CardContent>
									</Card>
							</Grid>
						))} */}
						<Grid item xs={12}>
						<span style={{marginLeft: "10px", fontSize: "20px"}}>
							
							<span style={{fontWeight:"bold"}}>Invoice:</span> {formattedInvoice.invoice_number}
						</span>	
						
						<Button 
						className={classes.buttonSquare}
						style={{float: "right", marginRight: "15px"}}
						onClick= {downloadPDF} 
						variant="contained" 
						color="secondary" >Export to PDF</Button>
						<Button 
						className={classes.buttonSquare}
						style={{float: "right", marginRight: "15px"}}
						onClick= {()=>{
							dispatch(openEmailDialog())
						}} 
						variant="contained" 
						color="secondary" >Email PDF</Button>
						</Grid>
						<Grid container spacing={1} style={{padding:6}}>
							<Grid item xs={6} sm={3} lg={3}>
								<Card className={classes.card}>										
									<span style={{margin: "24px"}}></span>			
									<CardContent className={classes.cardContainer} style={{marginLeft: "5px"}}>
										<span>
											<h5 style={{fontWeight:"bold", textTransform: "uppercase"}}>Invoice Summary </h5>
											Customer Po: {formattedInvoice.customer_po}<br />
											Order number: {formattedInvoice.order_no}<br />
											Invoice number: {formattedInvoice.invoice_number}<br />
											Invoice date: {dateReformatTo_mmm_dd_yyyy(formattedInvoice.invoice_date.split('T')[0])}<br />
											Invoice status: {formattedInvoice.invoice_status}<br />
											Due date: {dateReformatTo_mmm_dd_yyyy(formattedInvoice.due_date.split('T')[0])}<br />
										</span>										
									</CardContent>
								</Card>
							</Grid>
							
							<Grid item xs={6} sm={3} lg={3}>
								<Card className={classes.card}>
									<span style={{margin: "24px"}}></span>
									<CardContent className={classes.cardContainer}>
										<span>
											<h5 style={{fontWeight:"bold", textTransform: "uppercase"}}>Tax, shipping &amp; handling </h5>
											Tax amount: {toNumberFormat(formattedInvoice.tax_amount)}<br />
											Shipping charges: {toNumberFormat(formattedInvoice.shipping_charges)}<br />
											Total invoice: {formattedInvoice.invoice_amount}<br />
											Carrier: {formattedInvoice.carrier}<br />
											Carrier status: {formattedInvoice.carrier_status}<br />
											Payment type: {formattedInvoice.payment_type}<br />
										</span>										
									</CardContent>

								</Card>
							</Grid>

							<Grid item xs={6} sm={3} lg={3}>
								<Card className={classes.card}>
									
									<CardContent className={classes.cardContainer}>
										<span style={{margin: "24px"}}></span>
										<span>
											<h5 style={{fontWeight:"bold", textTransform: "uppercase"}}>billing address </h5>
											{formattedInvoice.billing_address_line}<br />
											{formattedInvoice.billing_address_line_1}<br />
											{formattedInvoice.billing_city}<br />
											{formattedInvoice.billing_country}<br />
											{formattedInvoice.billing_state}<br />
											{formattedInvoice.billing_zip_code}<br />
										</span>
												
									</CardContent>

								</Card>
							</Grid>

							<Grid item xs={6} sm={3} lg={3}>
								<Card className={classes.card}>
									
									<CardContent className={classes.cardContainer}>
										<span style={{margin: "24px"}}></span>
										<span>
											<h5 style={{fontWeight:"bold", textTransform: "uppercase"}}>location </h5>
											{formattedInvoice.shipping_address_line}<br />
											{formattedInvoice.shipping_address_line_1}<br />
											{formattedInvoice.shipping_city}<br />
											{formattedInvoice.shipping_country}<br />
											{formattedInvoice.shipping_state}<br />
											{formattedInvoice.shipping_zip_code}<br />
										</span>
												
									</CardContent>

								</Card>
							</Grid>
						</Grid>
					</Grid>
						
				}
			  </div>

			  

			  {invoice && 
				<Card className={classes.card} style={{margin: 10}}>
					<CardContent className={classes.cardContainer}>
						<div className="ag-theme-alpine" style={{height: 400, width: "100%", margin: 5}}>
							<AgGridReact 
								rowData={formatInvoiceItems(invoice.invoice_items)}
								onGridReady = { onGridReady }
								onColumnMoved = { onColumnMoved }
								onColumnVisible = { onColumnVisible }
							>
								{Object.keys(formatInvoiceItems(invoice.invoice_items)[0] ?? {}).map(item => {
									return (
										<AgGridColumn 
										field={item} 
										headerName={toWord(item)}
										sortable={ true } filter={ true }
										></AgGridColumn>
									)
								})}
							</AgGridReact>
						</div>
				    </CardContent>
				</Card>
			  }
			</FuseScrollbars>
		</div>

	</>);
}

function ArrowButton({onClick,label}) {
	return(
		<span
		onClick={()=>onClick}
		className="arrow-pointer">
				{label}
		</span>
	);
}

export default withReducer('emailInvoce', reducer)(Main);
