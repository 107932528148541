import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@fuse/components/general/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, AccordionActions } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import useRouter from '@fuse/hooks/useRouter';
import ListView from '../components/listView';
import lodash from "lodash"
import { dateReformatTo_mmm_dd_yyyy, getFormattedDate } from '@fuse/utils/dateOperations'

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ContractsTable(props) {
	const table_name = "SHIPPING_DETAILS_ASSET_LISTING";
	const classes = useStyles();

	const dispatch = useDispatch();
	const router = useRouter();

	const [data, setData] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [loading, setLoading] = useState(false);

	const { shippingId } = props;

	const [assets, setAssets] = useState([]);

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event);
		setPage(0)
	}
	let paginatedAssets = assets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	function getData() {
		setLoading(true);
		// if(!props.viewPermission){
		// 	setLoading(false);
		// 	return ;
		// }

		// axios.get("/assets",{
		// 	params: {
		// 		shipping_id: shippingId,
		// 	}
		// }).then((res) => {
		// 	const { data } = res.data;
		// 	setAssets(formatAssets(data.assets))
		// }).catch((err) => {
		// 	console.log(err)
		// }).finally(() =>{
		// 	setLoading(false)
		// })

		axios.post("/assets",{
				shipping_id: shippingId,
		}).then((res) => {
			const { data } = res.data;
			setAssets(formatAssets(data.assets))
		}).catch((err) => {
			console.log(err)
		}).finally(() =>{
			setLoading(false)
		})
	}

	function formatAssets(data){
		return data.map((asset) => {
			return {
				...asset,
				manufacturer_name: asset?.manufacturer?.manufacturer_name || "",
				asset_type: asset?.asset_type.split('_').map(word =>  word.charAt(0).toUpperCase() + word.substring(1)).join(' ') ,
				location_name: asset?.shipping?.location_name || "",
				contract_id: asset?.contract?.entity_external_platform_id || "",
				quote_id: asset?.quote_line_item?.quote?.quote_id || "",
				eosl: getFormattedDate(asset?.eosl) || "",
				created_date: getFormattedDate(asset?.created_at) || "",
			}
		})
	}

	const columnHeaders = [
		{
			field: 'entity_external_platform_id',
			label: 'Serial number',
		},
		{
			field: 'asset_type',
			label: 'Asset Type'
		},
		{
			field: 'hardware_sku',
			label: 'SKU',
		},
		{
			field: 'short_description',
			label: 'Description',
		},
		{
			field: 'model',
			label: 'Model',
		},
		{
			field: 'manufacturer_name',
			label: 'Manufacturer',
		},
		{
			field: 'location_name',
			label: 'Location name',
		},
		{
			field: 'contract_id',
			label: 'Contract number',
			cellClicked: function (params, value){
				router.push(`/contracts/details/${params.node.data?.contract?.id}`)
			},
		},
		{
			field: 'quote_id',
			label: 'Quote number',
			cellClicked: function (params, value){
				router.push(`/quotes/detail/${params.node.data?.quote_line_item?.quote?.id}`)
			},
		},
		{
			field: 'eosl',
			label: 'EOSL date',
		},
		{
			field: 'created_date',
			label: 'Created date',
		},
	];

	useEffect(() => {
	    getData()
	}, [])

	if (loading) {
		return (<FuseLoading />)
	}
	// else if(!props.viewPermission){
	// 	return (
	// 		<div>You don't have the view permission for assets</div>
	// 	)
	// }
	else if (assets.length <= 0) {
		return (
			<div
				style={{ width: "100%" }}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="w-full flex flex-1 items-center justify-center h-full"
			>
				<div style={{ height: "200px" }}>
					<Typography variant="body2" component="p" color="textSecondary">
						No data found
					</Typography>
				</div>
			</div>
		)
	}else{
		return (
			<div className="w-full flex flex-col">
				<ListView rows={paginatedAssets} columns={columnHeaders} onRowClicked={(params) => {
					router.push(`/asset-infos/detail/${params.node.data.id}`)
				}} table_name = { table_name }  />
				<div className="p-10">
					<TablePagination
						className="flex-shrink-0 border-t-1 mt-10"
						component="div"
						count={assets.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</div>
			</div>
		);
	}

	
}

export default withRouter(ContractsTable)
