import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField, SvgIcon } from '@material-ui/core';
import { useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';


const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {

	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	let suggRef = useRef(null);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const { hasPermission } = usePermission()
	const addLocationTypePermission =hasPermission('add-location_type');
	const addReportPermission = hasPermission('add-report')

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				{/* <svg width="22" height="22" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M35.85 41V29L45.85 35L35.85 41ZM25.85 41V29L35.85 35L25.85 41ZM19.4 44L18.4 37.7C17.7667 37.4667 17.1 37.15 16.4 36.75C15.7 36.35 15.0833 35.9333 14.55 35.5L8.65 38.2L4 30L9.4 26.05C9.33333 25.75 9.29167 25.4083 9.275 25.025C9.25833 24.6417 9.25 24.3 9.25 24C9.25 23.7 9.25833 23.3583 9.275 22.975C9.29167 22.5917 9.33333 22.25 9.4 21.95L4 18L8.65 9.8L14.55 12.5C15.0833 12.0667 15.7 11.65 16.4 11.25C17.1 10.85 17.7667 10.55 18.4 10.35L19.4 4H28.6L29.6 10.3C30.2333 10.5333 30.9083 10.8417 31.625 11.225C32.3417 11.6083 32.95 12.0333 33.45 12.5L39.35 9.8L44 18L38.6 21.85C38.6333 22.1833 38.6583 22.5417 38.675 22.925C38.6917 23.3083 38.7 23.65 38.7 23.95V26H35.7V23.95C35.7 23.2833 35.6667 22.6917 35.6 22.175C35.5333 21.6583 35.4333 21.15 35.3 20.65L40 17.2L38 13.6L32.7 15.9C31.9333 15.0333 31.0667 14.3083 30.1 13.725C29.1333 13.1417 28.0667 12.7667 26.9 12.6L26.2 7H21.8L21.1 12.6C19.9667 12.8333 18.9083 13.2333 17.925 13.8C16.9417 14.3667 16.0667 15.0667 15.3 15.9L10 13.6L8 17.2L12.7 20.65C12.5667 21.2167 12.4583 21.775 12.375 22.325C12.2917 22.875 12.25 23.4333 12.25 24C12.25 24.5667 12.2917 25.125 12.375 25.675C12.4583 26.225 12.5667 26.7833 12.7 27.35L8 30.8L10 34.4L15.3 32.1C16.1667 32.9667 17.1583 33.6917 18.275 34.275C19.3917 34.8583 20.5667 35.2833 21.8 35.55V44H19.4ZM21.8 30.1V26.7C21.4 26.3667 21.0833 25.975 20.85 25.525C20.6167 25.075 20.5 24.5667 20.5 24C20.5 23.0333 20.8417 22.2083 21.525 21.525C22.2083 20.8417 23.0333 20.5 24 20.5C24.9667 20.5 25.7917 20.8417 26.475 21.525C27.1583 22.2083 27.5 23.0333 27.5 24C27.5 24.3667 27.45 24.725 27.35 25.075C27.25 25.425 27.1 25.7333 26.9 26H30.2C30.3 25.6667 30.375 25.3417 30.425 25.025C30.475 24.7083 30.5 24.3667 30.5 24C30.5 22.2 29.8667 20.6667 28.6 19.4C27.3333 18.1333 25.8 17.5 24 17.5C22.2 17.5 20.6667 18.1333 19.4 19.4C18.1333 20.6667 17.5 22.2 17.5 24C17.5 25.4333 17.9 26.6917 18.7 27.775C19.5 28.8583 20.5333 29.6333 21.8 30.1Z" fill="#040404" />
				</svg> */}
				<Icon fontSize="big" title="Add Asset" style={{color: 'black'}}>
					location_city
				</Icon>
				

				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex md:text-18 mx-12 font-semibold"
					style = {{ color : 'black' }}
				>
					Location Types List
				</Typography>
			</div>

			<div style={{display:'flex'}}>
				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}
				{addLocationTypePermission && <Button
					variant="contained"
					color="secondary"
					className={`w-full ${classes.buttonSquare}`}
					style={{width:'210px', marginRight: '10px'}}
					onClick = {()=>dispatch(openFormViewerDialog())}
					startIcon={
					<Icon fontSize="small" title="Add Asset">
						add
					</Icon>}>
						Add Location Type
				</Button>}
				
				<div className={classes.buttonContainer}>
				<ThemeProvider theme={mainTheme}>
					<Paper
						component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
						style={{minWidth:"40px"}}
					>
						<TextField
							placeholder="Search Location Type"
							className="flex flex-1 mx-8"
							fullWidth
							InputProps={{
								disableUnderline: true,
								
							}}
							value={query_data ?? '' }
							onChange={(e)=>{
								dispatch(setQuickFilter(e.target.value))
							}}
						/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>
		</div>
	);
}

export default DynamicFieldsHeader;
