const initialState = {
	open: false,
}

const actionTypes = {
    OPEN_IMAGE_DIALOG: "OPEN_IMAGE_DIALOG",
    CLOSE_IMAGE_DIALOG: "CLOSE_IMAGE_DIALOG"
}

export function openImageDialog(){
    return {
        type: actionTypes.OPEN_IMAGE_DIALOG,
    }
}

export function closeImageDialog(){
    return {
        type: actionTypes.CLOSE_IMAGE_DIALOG,
    }
}

export default function (state = initialState, action) {
	switch (action.type) {
	  case actionTypes.OPEN_IMAGE_DIALOG:{
		  return { 
			  ...state,
			  open: true,
		  }
	  }
	  case actionTypes.CLOSE_IMAGE_DIALOG:{
			return { 
				...state,
				open: false,
			}
	  }
	  default:
		return state
	}
}