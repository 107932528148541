import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { Card, Box, Stepper, Step, StepButton, Button, TextareaAutosize, DialogTitle, Divider, Icon, IconButton, TextField, Typography, InputAdornment, Drawer, CardContent, Paper, Grid, Item, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, FormLabel, RadioGroup } from '@mui/material'
import axios from '@fuse/utils/axios'
import { quickAddServiceLevelAgreement } from '../../main/service-level-agreement/store/reportViewDialogReducer'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
        helperText: {
            color: 'red',
            paddingLeft: '1px'
        },
        datePickerInput: {
            '& .MuiInputBase-input': {
                fontSize: '16px'
            }
        }
    }
))



const QuickAddServiceLevelAgreement = (props) => {

	

    const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.slaApp.formViewDialog)
    const [ formName, setFormName ] = useState('Add Service Level Agreement')
	const { data:editFromState } = useSelector(({slaApp}) => slaApp.formViewDialog);
    const [openAlerModal, setOpenAlertModal] = useState(false);


    //form validation
	const schema = yup.object({
		sla_name: yup.string().trim().required('Service Level Agreement name is required!'),
		description: yup.string()
	})

	const defaultValues = {
		sla_name: '',
		description: ''
	}


    const { 
        control, 
        formState:{errors: statusFormErrors}, 
        handleSubmit: statusFormSubmit, 
        trigger,
        getValues,
        setValue,
        reset: statusFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const handleCloseForm = () => {
        
        props.close('servicelevelagreement')
    }

    const contractStatusError = (error) => {
        console.log(error)
    }


    const onSubmit = async(data) => {
        let payload = {
			sla: data.sla_name,
			description: data.description
		}
		
		if(!editFromState){
			payload = {
				...payload,
				entity_external_platform_id: generateRandomNumberString(),
			}

			axios.post('/service-level-agreement/add', payload).then(response => {
				if(response.status == 201){
					toast.error(response.data.message)
				} else {
					// onSuccess()
                    handleCloseForm()
					toast.success('Service Level Agreement added successfully.')
					dispatch(quickAddServiceLevelAgreement())
				}
			}).catch(err => {
				console.log('212=>',err);
				toast.error('Something went wrong!')
			})

		}else{
			payload = {
				...payload,
				id: editFromState.id,
			}

			axios.put('/service-level-agreement/update', payload).then(response => {
				if(response.status == 201){
					toast.error(response.data.message)
				} else {
					// onSuccess()
                    handleCloseForm()
					toast.success('Service Level Agreement updated successfully.')
					dispatch(quickAddServiceLevelAgreement())
				}
			}).catch(err => {
				console.log('212=>',err);
				toast.error('Something went wrong!')
			})

		}
    }


    useEffect( () => {
        if(!editFromState) return
        setFormName("Edit Service Level Agreement")
        setValue('sla_name', editFromState.service_level_agreement_name)
		setValue('description', editFromState.description)
    }, [editFromState])

    const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        handleCloseForm()
    }

    return (
        <>
            <div>
                <Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
                    <div className='heading_holder'>
                        <h4 className='heading'>{formName}</h4>
                        <IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
                                <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                        </IconButton>
                    </div>
                    <div className='progressbar_holder'>
                        <div className='progress'>
                                &nbsp;
                        </div>
                    </div>
                    <div style={{paddingTop:'30px'}} className='cstm_wizard_holder'>
                        <Box sx={{ width: '100%' }} className='box_holder'>
                            <div className='main_content_holder'>
                                <div className='content_holder'>
                                    <div className="text-left">
                                        <div className='mb-10'>
                                            <Typography className='status_text'>
                                                Service Level Agreement
                                                <span className='required_span'>*</span>
                                            </Typography>
                                            <Controller 
                                                name="sla_name"
                                                className='serial_numberField'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        style={{ background: 'white' }}
                                                        variant='outlined'
                                                        autoFocus
                                                        fullWidth
                                                        required
                                                        defaultValue={defaultValues.sla_name}
                                                        error={!!statusFormErrors.sla_name}
                                                        helperText={statusFormErrors?.sla_name?.message}
                                                        FormHelperTextProps={{
                                                            style: {
                                                                margin: 0,
                                                                backgroundColor: '#f4f4f4',
                                                                width: '100%',
                                                                paddingTop: '2px',
                                                                border: '1px solid #F2F4F7',
                                                                fontSize: '10px',
                                                            }
                                                        }} 
                                                    />
                                                )}
                                            />
                                        </div> 

                                        <div className='mb-10'>
                                            <Typography className='status_text'>
                                                Description
                                                {/* <span className='required_span'>*</span> */}
                                            </Typography>
                                            <Controller 
                                                name='description'
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        variant='outlined'
                                                        // label="Description"
                                                        fullWidth
                                                        multiline
                                                        rows={8}
                                                        inputProps={{ className: classes.textarea }}
                                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                                        error={!!statusFormErrors.description}
                                                        helperText={statusFormErrors?.description?.message}
                                                        FormHelperTextProps={{
                                                            style: { 
                                                                margin : 0, 
                                                                backgroundColor: '#f4f4f4',
                                                                width: '100%',
                                                                paddingTop: '2px',
                                                                fontSize: '10px'
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                    {/* <DialogActions className="action_footer_holder"> */}
                                <div className='btn_holder'>
                                    <Button
                                        color="inherit"
                                        onClick={handleAlertModal}
                                        className='back_btn secondary_btn'
                                    >
                                    Cancel
                                    </Button>
                                    <Button onClick={statusFormSubmit(onSubmit, contractStatusError)} className='next_btn'>                                                
                                        Proceed
                                    </Button>
                                </div>
                            </div>
                        </Box>
                    </div>
                </Drawer>
            </div>

            {openAlerModal &&
                <AlertModal  openAlerModal module="service level agreement"  action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
        </>
    )



}


export default QuickAddServiceLevelAgreement