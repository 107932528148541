const initialState = {
    filter : {},
    rowGroupModel : [],
    query:null,
    serial_number: null,
    service_request_id: null
};

const actionTypes = {
    SET_FILTER_LIST : 'SET_SERVICE_REQUEST_FILTER_LIST',
    SET_ROW_GROUP_MODEL : 'SET_ROW_GROUP_MODEL',
    FILTER_ASSET_DATA: 'FILTER_ASSET_DATA',
    SET_SERIAL_NUMBER: 'SET_SERIAL_NUMBER',
    FILTER_SERVICE_REQUEST_DATA: 'FILTER_SERVICE_REQUEST_DATA',
    SET_SERVICE_REQUEST_ID: 'SET_SERVICE_REQUEST_ID'
}

export function setfilterlist(data){
    return {
        type:actionTypes.SET_FILTER_LIST,
        payload: {
            data
        }
    }
}

export function setfilterQuery(query = null){
    return {
        type:actionTypes.FILTER_SERVICE_REQUEST_DATA,
        payload: {
            query
        }
    }
}

export function setrowGroupModel (data){
    return {
        type:actionTypes.SET_ROW_GROUP_MODEL,
        payload: {
            data
        }
    }
}

export function setSerialNumberForServiceRequest (data){
    return {
        type:actionTypes.SET_SERIAL_NUMBER,
        payload: {
            data
        }
    }
}

export function setServiceRequestId (data){
    return {
        type:actionTypes.SET_SERVICE_REQUEST_ID,
        payload: {
            data
        }
    }
}

export default function (state = initialState , action){
    switch(action.type){
        case actionTypes.SET_FILTER_LIST : {
            return {
                ...state,
                filter : {...action.payload.data}
            }
        }
        case actionTypes.FILTER_SERVICE_REQUEST_DATA : {
            return {
                ...state,
                query: action.payload.query
            }
        }
        case actionTypes.SET_ROW_GROUP_MODEL :{
            return {
                ...state,
                rowGroupModel : [...action.payload.data]
            }
        }
        case actionTypes.SET_SERIAL_NUMBER :{
            return {
                ...state,
                serial_number : action.payload.data
            }
        }
        case actionTypes.SET_SERVICE_REQUEST_ID :{
            return {
                ...state,
                service_request_id : action.payload.data
            }
        }
        default : return state
    }
}