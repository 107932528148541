import useToast from '@fuse/hooks/useToast'
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog,
	DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Icon,
	IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Tooltip, Typography,
	Drawer,TextareaAutosize 
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'
import ColorPicker from '@fuse/components/ColorPicker'

const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
	display: 'flex',
	'& > * + *': {
		marginLeft: theme.spacing(2),
	},
	},
	subSection: {
		marginLeft:'20px',
		marginRight:'20px',
		marginBottom:'20px',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
	},
	endButtons: {
			borderRadius: '4px'
	},drawerWidth: {
		width: 600
	},
	textarea: {
		resize: "both",
		width: "100%"
	},
}))

function AddContractStatusDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const { data:editFormState, open } = useSelector(({contractStatusApp}) => contractStatusApp.formViewDialog);
	const [loading, setLoading] = useState(false)

	const defaultColor = {name:'black',code:"#000000"}
	const [color, setColor] = useState(defaultColor)

	//form validation
	const schema = yup.object({
		contract_status: yup.string().required('Contract Status is required!'),
		status_description: yup.string()
	})

	const defaultValues = {
		contract_status: '',
		status_description: ''
	}

	const onSuccess = () => {
		reset(defaultValues)
		dispatch(setFromData(null))
		closeDialog()
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setColor(defaultColor)
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(()=>{
		if(editFormState){
			setValue('contract_status', editFormState.contract_status_name)
			setValue('status_description', editFormState.status_description === "-" ? "" : editFormState.status_description)
			setColor(editFormState.status_color)
		}
	},[editFormState])

  const { errors } = formState

	const manageFormData = (formData) => {
		let payload = {
			contractStatus: formData.contract_status,
			status_description: formData.status_description,
			status_color: color
		}

		if(editFormState){
			payload.id = editFormState.id
			// console.log('108=>',payload)
			axios.put('/contract-status/update', payload).then(res => {
				if(res.status === 201){
					toast.error(res.data.message)
				}else{
					onSuccess()
					props.getContractStatus()
					toast.success('Contract Status updated successfully!')
				}
			}).catch(err => {
				console.log('error:update api=>', err)
				toast.error('Something went wrong!')
			})

		}else{
			payload.entity_external_platform_id = generateRandomNumberString()

			axios.post('/contract-status/add', payload).then(res => {
				if(res.status === 201){
					toast.error(res.data.message)
				}else{
					onSuccess()
					props.getContractStatus()
					toast.success('Contract Status added successfully!')
				}
			})
		}
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	return (
		<div>

			<Drawer anchor='right' open={open} classes={{ paper: classes.drawerWidth }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{ Boolean(editFormState) ? 'Update Contract Status' : 'Add Contract Status'}</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onSuccess }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

				{/*----------------------Item Information------------------------- */}
					
					<div className={classes.subSection}>
						<div style={{ width: '100%' }}>
							<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
							<Divider style={{marginBottom: '10px'}}/>
						</div>

						<div>
							<div style={{ borderRadius:9, padding: '0', width:'100%', marginBottom: '10px'}}>
								<Controller 
									name='contract_status'
									control={control}
									render={({field}) => (
										<TextField
											{...field}
											required
											variant='outlined'
											label="Contract Status"
											fullWidth
											style={{ width: '100%', backgroundColor: '#fff' }}
											error={!!errors.contract_status}
											helperText={errors?.contract_status?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
										/>
									)}
								/>
							
							</div>

							<div style={{ borderRadius:9, padding: '0', width:'100%', marginBottom: '10px'}}>
								<Controller 
									name='status_description'
									control={control}
									render={({field}) => (
										<TextField
											{...field}
											variant='outlined'
											label="Status Description"
											fullWidth
											style={{ width: '100%', backgroundColor: '#fff' }}
											error={!!errors.status_description}
											helperText={errors?.status_description?.message}
											multiline
											rows={8}
											inputProps={{ className: classes.textarea }}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
										/>
									)}
								/>
							
							</div>

							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%',display:'flex',alignItems:'center' }}>
									<Typography style={{paddingRight: '10px',fontSize:'16px'}}>Color</Typography>
									<ColorPicker setColor={setColor} color={color}/>
								</div> 
							</div>
						</div>
					</div>
					<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
						<Button className={classes.endButtons} style={{marginRight: '10px'}} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFormState ? 'Update' : 'Add'}</Button>
						<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onSuccess }>Cancel</Button>
					</div>						
				</form>        
			</Drawer>
		</div>
	)
}

export default AddContractStatusDialog
