import FusePageSimple from '@fuse/core/FusePageSimple';
import { useRef } from 'react';
import HeadersList from './HeadersList';
import Header from './Header';

function Main() {
	const pageLayout = useRef(null);

	return (
		<>
			<FusePageSimple
                header={<Header/>}
                content={
                    <div className="p-24">
                        <HeadersList/>
                    </div>
                }
            />
		</>
	);
}

export default Main;
