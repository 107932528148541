const monthInitials = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
}

export function dateReformatTo_mmm_dd_yyyy(dateString, withTimeStamp=false) {
    if(!dateString) return ''
    const [pureDate, pureTime] = dateString.split('T')
    const splited = pureDate.split('-');

    const day = splited[2];

    let formattedDate = `${monthInitials[parseInt(splited[1])]} ${day}, ${splited[0]}`

    if(withTimeStamp && pureTime){
        let [hour, minute, second] = pureTime.split(':').map(t => parseInt(t));
        const amOrPm = hour > 12 ? 'PM' : 'AM';
        formattedDate += ` ${hour % 12}:${minute}:${second} ${amOrPm}`
    }

    return formattedDate;
}

export const getFormattedDate = (dateString = '') => {
    if(dateString === '' || !dateString) return null
    const { month, day, year} = getFormattedDateProps(dateString)

    return `${month}/${day}/${year}`;
}

export const getFormattedDateyyyy_mm_dd = (dateString = '') => {
    if(dateString === '' || !dateString) return null
    const { month, day, year} = getFormattedDateProps(dateString)

    return `${year}-${month}-${day}`;
}
 
export function getFormattedDateProps(dateString = ""){
    const newDate = new Date(dateString);
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();
    let year = newDate.getFullYear();
  
    if(month < 10) month =  "0" + month
    if(day < 10) day =  "0" + day
  
    return {
        month,
        day,
        year,
    }
}

export function getMMDDYYYY(dateString = ""){
    const newDate = new Date(dateString);
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();
    let year = newDate.getFullYear();
  
    if(month < 10) month =  "0" + month
    if(day < 10) day =  "0" + day
  
    return {
        month,
        day,
        year,
    }
}

// export const dateReformatTo_mmm_dd_yyyy = (dateString)=>{
//     const splited = dateString.split('-');

//     return `${monthInitials[parseInt(splited[1])]} ${splited[2]}, ${splited[0]}`;
// }

export function checkValidDateFormat_mm_dd_yyyy(dateString) {
    const splited = dateString.split('-');

    if(splited.length < 3){
        return false;
    }

    return !isNaN(new Date(`${splited[2]}-${splited[0]}-${splited[1]}`))
}

export function monthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

export function getDateDifference(start_date, end_date)
//get difference between two dates , eg: 1y 2m 3d
{
    if(!start_date || !end_date) return null;

    let diffDays = (new Date(end_date) - new Date(start_date)) / (1000 * 3600 * 24);

    let diffMonths = 0;
    
    let diffYears = 0;

    if(diffDays >= 30){
        diffMonths = Math.floor(diffDays / 30);
        diffDays = diffDays % 30
    }

    if(diffMonths >= 12){
        diffYears = Math.floor(diffMonths / 12);
        diffMonths = diffMonths % 12
    }

    return `${diffYears > 0 ? `${diffYears} Y ` : ''}${diffMonths > 0 ? `${diffMonths} M ` : ''}${diffDays > 0 ? `${diffDays} D` : ''}`
}

export function formatDateInto_yyyy_mm_dd(date){
    if(!date) return
    let arr = date.split('-')
    return `${arr[2]}-${arr[0]}-${arr[1]}`
}

/**
 * @param {String} date1 pass that date want to comapre with
 * @param {String} date2 pass that date want to comapre
 * @param {String} operation valid options (is_less_than/is_greater_than)
 * @returns { Object} 
 * {
 *  isValid: Boolean (true/false),
 *  message: String (default:Error/empty string)
 * }
 */
export function comapreDates (date1=null,date2=null,operation='',message='Error') {
    try {
        const avaliable_operation = {
            is_less_than: true,
            is_greater_than: true
        }
        let error = false
        
        if( !date1 || !date2 || !operation.length || !avaliable_operation[operation]) return
        let temp_date_1 = new Date(date1)
        temp_date_1.setHours(0)
        temp_date_1.setMinutes(0)
        temp_date_1.setSeconds(0)
    
        let temp_date_2 = new Date(date2)
        temp_date_2.setHours(0)
        temp_date_2.setMinutes(0)
        temp_date_2.setSeconds(0)
    
        if(operation === 'is_less_than'){
            error = temp_date_1 > temp_date_2
        }
    
        if(operation === 'is_greater_than'){
            error = temp_date_1 < temp_date_2
        }
    
        return {
            hasError: error,
            message: error ? message : ''
        }        
    } catch (err) {
        console.log(err)
    }
}

export function formatDateTo_MM_DD_YYYY_HH_MM_SS (val) {
    const date = new Date(val)
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
}

export function formatDateTimeIntoRelativeTime(dateString){
    // relative time format (e.g., "2 hours ago", "3 months ago")
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);
    const intervals = [
        { label: 'year', seconds: 31536000 }, // 365 days
        { label: 'month', seconds: 2592000 },  // 30 days
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 }
    ];
    
    for (const interval of intervals) {
        const count = Math.floor(diffInSeconds / interval.seconds);
        if (count >= 1) {
            return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
        }
    }
    return 'just now';
}

export function getLocalTimeFromUTC(utcDate){
    const localDate = new Date(utcDate)
    const date = localDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    const time = localDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    })

    return `${date} : ${time}`
}