import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import axios from "@fuse/utils/axios";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useRouter from '@fuse/hooks/useRouter';
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast'
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import Header from './Header';
import Main from './Main';
import { Menu,MenuItem } from '@material-ui/core';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { usePermission } from '@fuse/hooks';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [invoices, setAssetStatus] = useState([]);
	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [logs,setLogs] = useState([]);
	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});
	const [loadingActivityLog,setLoadingActivityLog] = useState(false);
	const [addButtonClick, setAddButtonClick] = useState(false);
	const { hasPermission } = usePermission()
	const viewRolePermission = hasPermission('view-role')
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();

	// function handleViewMenuOpen(event) {
	// 	setAnchorEl(event.currentTarget);
	// 	setViewMenuOpened(true);
	// }

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function getAgents(invoices){
		const agentSet = new Set();
		for(let i = 0; i < invoices.length ; i++){
			agentSet.add(invoices[i].Agent);
		}
		return Array.from(agentSet);
	}

	// function applyFilters(filters){
	// 	setFilters(filters);
	// 	setAssetStatus(invoices.filter(acc=>{
	// 		let flag = true;
	// 		if(filters.agent != '' && filters.agent != acc.Agent){
	// 			flag = false;
	// 		}
	// 		return flag;
	// 	}))
	// }

	// function clearFilters() {
	// 	const {query, field} = currentSearchParameters;
	// 	handleSearch(query, field, {});
	// 	setSelectedFilters({});
	// }

	const handleTabChange = (event, value) => {
		if(value ==1){
			getLogs()
		}
		setSelectedTab(value);
	};
	

	// function toggleView(val){
	// 	setSelectedView(val);
	// }

	// function gotoDetailsPage(id){
	// 	router.push(`manufacturers/${id}`)
	// }


	async function getAssetStatus(){
		// if(!viewManufacturersPermission){
		// 	return;
		// }
		try {
			const res = await axios.get("/asset-status");
			const { data } = res.data;
			setAssetStatus(formatData(data));
		} catch (err) {
			console.log(err);
		}
	}
	
	// function handleSearchReset(){
	// 	getAssetStatus()
	// }

	// function getSearchFilters(){
	// 	setSearchFilters([
	// 		{field: "hardware_sku", label: "SKU"},
	// 		{field: "service_plan", label: "Service plan"},
	// 	])
	// }


	// function getCustomFilters(){
	// 	axios.get('/manufacturers/get-filters')
	// 	.then(res => {
	// 		const { data } = res.data;
	// 		setCustomFilters(data)
	// 	})
	// 	.catch(err => {
	// 		console.log(err);
	// 	})
	// }

	// function getSearchSuggetions(query, field){
	// 	axios.get('/manufacturers/search-suggestions',{
	// 		params: {
	// 			query,
	// 			field
	// 		}
	// 	})
	// 	.then(res => {
	// 		const { data } = res.data;
	// 		setSearchSuggestions(data)
	// 	})
	// 	.catch(err => {
	// 		console.log(err);
	// 	})
	// }

	// const handelSearchSuggestions = debounce((query, field) => {
	// 	getSearchSuggetions(query, field)
	// }, 500)

	// function handleSearch(query, field, filters) {
	// 	axios.get('/manufacturers/search',{
	// 		params: {
	// 			query,
	// 			field,
	// 			filters
	// 		}
	// 	})
	// 	.then(res => {
	// 		const { data } = res.data;
	// 		setManufacturers(data)
	// 	})
	// 	.catch(err => {
	// 		console.log(err);
	// 	})
	// }
	
	function formatData(data){
		return data.map((item) => {
			return {
				type_: item.type.charAt(0).toUpperCase()+ item.type.slice(1),
				status_name_: item.status_name,
				id_: item.entity_external_platform_id,
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
				]),
			}
		})
	}

	// function refreshList(){
	// 	getDynamicAttributes().then(() => {
	// 		return getAssetStatus();
	// 	})
	// }

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				role_name : item?.role?.display_name || item.role_name,
				name: item.userName,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
			}
		})
	}

	function getLogs(){
		setLoadingActivityLog(true)
		axios.get('/user-role/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
			setLoadingActivityLog(false)
		})
		.catch(()=>{
			console.log('cannot fetch logs');
		})
	}

	// function callToast(success,error,value){
	// 		if(success){
	// 				toast.success(value)
	// 		}
	// 		if(error){
	// 				toast.error(value);
	// 		}
	// }

	// function closeForm(data){
	// 	if(data){
	// 		setAddButtonClick(false)
	// 	}
	// }

	// useEffect(()=>{
	// 	setLoading(true)
	// 	getAssetStatus().finally(() => {
	// 		setLoading(false)
	// 	});
	// },[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id && user.role[0] != 'Super-User'){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
		return (<>

			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				header={
					<Header />
				}
				contentToolbar={
					<>
						<Tabs
							value={selectedTab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="off"
							className="w-full h-64"
						>
							<Tab key="end_user_tab_1" className="h-64" label="Predefined Roles" />
							{viewRolePermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}
							
						</Tabs>
						<div>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={viewMenuOpened}
								onClose={handleViewMenuClose}
							>
								{	
									views.map((view,index) => {
										return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
									})
								}
							</Menu>
						</div>
					</>
				}
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							{
								loading && <FuseLoading />
							}
							{
								// 	!loading && <SalesOrderTable 
								// 	endUsersAccounts={invoices}
								// 	selectedView={selectedView}
								// 	gotoDetailsPage={gotoDetailsPage}
								// 	refresh={refreshList}
								// 	// viewManufacturersPermission = { viewManufacturersPermission }
								// />
								!loading && <Main />
							}
						</div>
						<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
							{/* <ActivitieLogs logs = {logs} /> */}
							{selectedTab === 1 && <ActivityAgGridListing module='role' logs={logs} loadingActivityLog={loadingActivityLog}/> }
						</div>
					</>
				}
				innerScroll
			/>		
		</>)
	}
}

export default withReducer('rolesApp', reducer)(CardedFullWidth2TabbedSample);
