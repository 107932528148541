const initialState = {
	open: false,
}

const actionTypes = {
    OPEN_EMAIL_DIALOG: "OPEN_EMAIL_DIALOG",
    CLOSE_EMAIL_DIALOG: "CLOSE_EMAIL_DIALOG"
}

export function openEmailDialog(){
    return {
        type: actionTypes.OPEN_EMAIL_DIALOG,
    }
}

export function closeEmailDialog(){
    return {
        type: actionTypes.CLOSE_EMAIL_DIALOG,
    }
}

export default function (state = initialState, action) {
	switch (action.type) {
	  case actionTypes.OPEN_EMAIL_DIALOG:{
		  return { 
			  ...state,
			  open: true,
		  }
	  }
	  case actionTypes.CLOSE_EMAIL_DIALOG:{
			return { 
				...state,
				open: false,
			}
	  }
	  default:
		return state
	}
}