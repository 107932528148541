import React from "react";


export default function Iot() {

  return (
    <>
      <h1>Iot</h1>
    </>
  );
}
