import {
    Button, DialogTitle, Icon, IconButton, makeStyles, Typography, Drawer, CardContent
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import axios from '@fuse/utils/axios'
import { Box } from '@mui/material'
import Card from '@material-ui/core/Card';
import FuseLoading from '@fuse/core/FuseLoading';
import { formatDateTimeIntoRelativeTime } from '@fuse/utils/dateOperations';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@fuse/hooks';
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#f4f4f4',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 420
        },
    }
))
function AddDashboardAlert({
    close,
    open,
    loading=false,
    notifications=[],
    markAllAsRead=()=>{},
    moduleName,
    updateReadStatusOnClickNotification=()=>{},
    getNotifications,
    onClickRefreshBtn
}) {
    const classes = useStyles()
    const router = useRouter()
	const dispatch = useDispatch();
    const moduleNotificationCount = {
        'contract': 'contractNotificationCount',
        'asset': 'assetNotificationCount'
    }
    let notificationCount = useSelector(({ fuse }) => fuse.notifications[moduleNotificationCount[moduleName]]);
    if(typeof notificationCount === 'string') notificationCount = Number(notificationCount.replaceAll(',', ''))

    const handleCloseForm = () => {
        close()
    }

    function handleRedirectionOnClickNotificationItem( notification ){
        if(!Boolean(moduleName)) return
        const payload = JSON.parse(notification.payload)
        updateReadStatusOnClickNotification(notification.id)
        if(!Object.keys(payload).length) return
        if(moduleName === 'asset') {
            router.push(`/assets-list/${payload.id}`);
        }else if(moduleName === 'contract'){
            router.push(`/contract-details/${payload.contract_id}`);
        }
        close()
    }

    return (
        <div>
            <Drawer anchor='right' open={open} classes={{ paper: classes.drawerWidth }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <DialogTitle>Alerts</DialogTitle>
                    <IconButton style={{ marginRight: '15px' }} onClick={handleCloseForm}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
                <hr />
                <form style={{ padding: '14px' }} id="infinite_scroll" className='scrollable_form_holder submission_form'>
                    {!!notifications.length && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <Typography className='alert_recent'>Recent</Typography>
                        </Box>
                        <Box>
                            <span style={{ cursor: "pointer"}} onClick={onClickRefreshBtn}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_2022_60838)">
                                        <path d="M10 6.66675V10.0001L11.6667 11.6667" stroke="#667085" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M2.5415 9.16672C2.72825 7.33344 3.58339 5.63307 4.94378 4.39003C6.30417 3.14699 8.07458 2.44829 9.91724 2.42725C11.7599 2.4062 13.5458 3.06428 14.9342 4.27592C16.3227 5.48756 17.2164 7.16796 17.445 8.9965C17.6735 10.825 17.2209 12.6737 16.1735 14.1899C15.126 15.706 13.557 16.7834 11.7659 17.2166C9.97477 17.6498 8.08685 17.4084 6.46233 16.5384C4.83782 15.6685 3.59044 14.231 2.95817 12.5001M2.5415 16.6667V12.5001H6.70817" stroke="#667085" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2022_60838">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </Box>
                    </Box>}
                    {
                        loading ? (<FuseLoading/> ) : (notifications.length) ? (
                            <InfiniteScroll
                                dataLength={notifications.length}
                                next={getNotifications}
                                hasMore={!(notificationCount === notifications.length)}
                                loader={<h4>Loading...</h4>}
                                scrollableTarget="infinite_scroll"
                            >
                                {
                                    notifications.map((notification, index)=>{
                                        return(
                                            <React.Fragment key={index}>
                                                <Box sx={{ marginTop: '10px' }}>
                                                    <Card  onClick={()=>handleRedirectionOnClickNotificationItem(notification)} style={{ borderRadius: '5px', border: '1px solid #F2F4F7', boxShadow: '0px 24px 48px -12px #1018280A', cursor: 'pointer' }}>
                                                        <CardContent style={{ padding: '12px' }}>
                                                            <Box className="alert_img" sx={{ display: 'flex', alignItems: 'center' }}>
                                                                {/* <Box><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfKT5yB_J4090MaWaCe-FJd5MP6_-nsgq91g&usqp=CAU' className='alert_img' /></Box> */}
                                                                <Box sx={{ marginLeft: '4px' }}>
                                                                    <p>{notification.title}</p>
                                                                </Box>
                                                                {!notification.read && <span style={{top: '-8px'}} className="dot" />}
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                                                <Typography className='justNow_text'>{formatDateTimeIntoRelativeTime(notification.notification_at)}</Typography>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        ) : (
                            <span style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 'larger',
                                height: '16vh' 
                            }}>No notifications found</span>
                        )
                    }
                    {Boolean(notifications.length) && <div style={{ marginTop: '7px', marginRight: '5px', display: 'flex', justifyContent: 'center' }} className='sidebar_btn_holder'>
                        <Button
                            className='mark_button mt-0'
                            // type='submit'
                            variant='contained'
                            onClick={markAllAsRead}
                        >
                            Mark All As Read
                        </Button>
                    </div>}
                </form>
            </Drawer>
        </div>
    )
}
export default AddDashboardAlert