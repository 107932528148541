import React, {useEffect,useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';


const useStyles = makeStyles({

    root:{
      // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
      //   border: '2px solid black',
      //   display: 'none !important'
      // }
      '& .MuiDataGrid-columnsContainer':{
        background: '#8f8f8f26'
      }
    }
});

import { withRouter } from 'react-router-dom';

const columns = [
    { field: 'install_date', headerName: 'Install Date', width: 100 },
    { field: 'description', headerName: 'Description', width: 100},
    { field: 'release', headerName: 'Release', width: 200},
    { field: 'file', headerName: 'File',width: 200,
        renderCell: (params) =>{
            return (<span style={{color: "blue"}}>{params.value}</span>)
        }
    },
    { field: 'purpose', headerName: 'Purpose',width: 300},
    { field: 'install_by', headerName: 'Installed by',width: 200}
];

const data = [
    { 
        id:1,
        install_date: '7-Jan-23',
        description: 'Firmware Upgrade EX3400 Series',
        release: '22.4R1',
        file: 'tgz (366.57MB)',
        purpose: 'Upgrade',
        install_by: 'Jeff Lewis'
    },
    { 
        id:2,
        install_date: '27-Dec-22',
        description: 'Firmware Re-Installp',
        release: '22.3R1',
        file: 'tgz (366.57MB)',
        purpose: 'Reinstall',
        install_by: 'Geoff Kelleway'
    }
]
function FilesTab(props) {
    const classes = useStyles();

    useEffect(()=>{
        
    },[])


    return (
        <div className={classes.root} style={{height: 369, width: '100%'}}>
            <DataGrid
                rows={data}
                columns={columns}
                hideFooter={true}
            />
        </div>
    )
}

export default withRouter(FilesTab)
