import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

function StatusIcon(props) {

	const {
		status = {},
		isOnline = false,
		defaultIcon = false
	} = props;

	let icon = <></>;
	let title = "";

	switch (status?.name) {
		case "active": {
			title = status?.label;
			icon = <Icon className="block text-16 text-green bg-white rounded-full">radio_button_checked</Icon>;

			break;
		}
		case "away": {
			title = status?.label;
			icon = <Icon className="block text-16 text-yellow-800 bg-white rounded-full">radio_button_checked</Icon>;

			break;
		}
		case "do-not-disturb": {
			title = status?.label;
			icon = <Icon className="block text-16 text-red bg-white rounded-full">remove_circle_outline</Icon>;

			break;
		}
		default: {
			if (isOnline) {
				title = "Active";
				icon = <Icon className="block text-16 text-green bg-white rounded-full">radio_button_checked</Icon>;
			} else {
				if (defaultIcon) {
					title = "Default";
					icon = <Icon className="block text-16 bg-white rounded-full">radio_button_checked</Icon>;
				} else {
					title = "Offline";
					icon = <Icon className="block text-16 text-red bg-white rounded-full">radio_button_checked</Icon>;
				}
			}
		}
	}

	return (
		<>
			<Tooltip title={title}>
				{icon}
			</Tooltip>
		</>
	);
}

export default StatusIcon;
