import React, {useEffect,useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles({

    root:{
      // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
      //   border: '2px solid black',
      //   display: 'none !important'
      // }
      '& .MuiDataGrid-columnsContainer':{
        background: '#8f8f8f26'
      }
    },
});

import { withRouter } from 'react-router-dom';
import { Ballot } from '@material-ui/icons';

  
const columns = [
  { field: 'port', headerName: 'Port', width: 100 },
  { field: 'status', headerName: 'Status', width: 100},
  { field: 'agg_ethernet', headerName: 'Agg. Ethernet', width: 200},
  { field: 'wired_client', headerName: 'Wired Client',width: 200},
  { field: 'client_manufacturer', headerName: 'Client Manufacturer',width: 300},
  { field: 'wireless_clients', headerName: 'Wireless Clients',width: 200},
  { field: 'power', headerName: 'Power',width: 100},
  { field: 'profile', headerName: 'Profile',width: 100},
  { field: 'type', headerName: 'Type',width: 100},
  { field: 'vlan', headerName: 'VLAN',width: 100},
  { field: 'speed', headerName: 'Speed',width: 100},
  { field: 'full_duplex', headerName: 'Full Duplex',width: 100},
  { field: 'rx_bytes', headerName: 'RX Bytes',width: 100},
  { field: 'tx_bytes', headerName: 'TX Bytes',width: 100},
  { field: 'descr', headerName: 'DESCR',width: 100},
];

const data = [
    { 
        id:1,
        port: 'ge-0/0/33',
        status: 'Up',
        agg_ethernet: '--',
        wired_client: 'c0:51:7e:3d:a3:ad',
        client_manufacturer: 'Hangzhou Hikvision Digital Technology Co.',
        wireless_clients: '--',
        power: '--',
        profile: 'Default',
        type: 'Access',
        vlan: 1,
        speed: '1000 mbps',
        full_duplex:'--',
        rx_bytes: '4.3 TB',
        tx_bytes: '179.5 GB',
        descr: '--',
    },
    { 
        id:2,
        port: 'ge-0/0/47',
        status: 'Up',
        agg_ethernet: '--',
        wired_client: 'b8:27:eb:90:cb:8f',
        client_manufacturer: 'Raspberry Pi Foundation',
        wireless_clients: '--',
        power: '--',
        profile: 'IoT',
        type: 'Access',
        vlan: 1,
        speed: '100 mbps',
        full_duplex:'--',
        rx_bytes: '91.7 MB',
        tx_bytes: '2 GB',
        descr: '--',
    },
    { 
        id:3,
        port: 'ge-0/1/0',
        status: 'Up',
        agg_ethernet: '--',
        wired_client: '8c:36:7a:8f:4c:15',
        client_manufacturer: 'Palo Alto Networks',
        wireless_clients: '--',
        power: '--',
        profile: 'Uplink',
        type: 'Trunk',
        vlan: 1,
        speed: '1000 mbps',
        full_duplex:'--',
        rx_bytes: '318.1 GB',
        tx_bytes: '4.7 TB',
        descr: '--',
    },
    { 
        id:4,
        port: 'ge-0/0/32',
        status: 'Up',
        agg_ethernet: '--',
        wired_client: '00:0a:83:06:17:5c',
        client_manufacturer: 'SALTO SYSTEMS S.L.',
        wireless_clients: '--',
        power: '--',
        profile: 'salto',
        type: 'Access',
        vlan: 1,
        speed: '1000 mbps',
        full_duplex:'--',
        rx_bytes: '4.8 GB',
        tx_bytes: '45.1 GB',
        descr: '--',
    },
    { 
        id:5,
        port: 'ge-0/0/0',
        status: 'Up',
        agg_ethernet: '--',
        wired_client: '00:02:c1:8d:ee:e0',
        client_manufacturer: 'Innovative Electronic Designs',
        wireless_clients: '--',
        power: '--',
        profile: 'Default',
        type: 'Access',
        vlan: 1,
        speed: '1000 mbps',
        full_duplex:'--',
        rx_bytes: '1.3 GB',
        tx_bytes: '3.7 GB',
        descr: '--',
    }
]

function StatusTab(props) {
    const classes = useStyles();
    const [statusData,setStatusData] = useState([])

    useEffect(()=>{
        
    },[])


    return (
        <div className={classes.root} style={{height: 290, width: '100%',overflow:"auto"}}>
            <DataGrid
                rows={data}
                columns={columns}
                hideFooter={true}
            />
        </div>
    )
}

export default withRouter(StatusTab)
