import axios from "@fuse/utils/axios";
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import { closeReportViewerDialog } from '../store/reportViewDialogReducer';
import useToast from '@fuse/hooks/useToast';

export default function SaveReportDialog(props) {

    const dispatch = useDispatch();
    const toast = useToast(dispatch);
    const [ reportName,setReportName ] = useState('');
    const [initialState,setInitalState] = useState(false);
    const dialogState = useSelector(({contractsApp}) => contractsApp.reportViewDialogReducer);
    const filterParamsState = useSelector(({contractsApp}) => contractsApp.filterParamsReducer);

    const handleCloseDialog = () =>{
        setInitalState(false);
        setReportName('');
        dispatch(closeReportViewerDialog())
    }

    const handleInputChange = (params) =>{
        if(initialState == false){
            setInitalState(true);
        }
        setReportName(params.target.value);
    }

    const handlesaveReport = () =>{
        
        const report_name = reportName.trim();
        const table_name = "contracts";
        const filter =  JSON.stringify(filterParamsState.filter);
        const row_model = JSON.stringify(filterParamsState.rowGroupModel);

        const payload = {
            report_name,table_name,filter,row_model
        }
        console.log(payload);

        axios.post('/reports/add-report',payload)
        .then(results=>{
            console.log(results);
            toast.success(results.data.message);
        })
        .catch(err=>{
            console.log(err);
            toast.error("report save unsuccessfull");
        })
        .finally(()=>{
            setInitalState(false);
            setReportName('');
            dispatch(closeReportViewerDialog())
        })

    }

    return (
        <>
            <div>
                <Dialog open = { dialogState.open } fullWidth ={ true } maxWidth = 'sm'>
                    <DialogTitle>Create Report</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Add the name of the report to save the current filter state.
                        </DialogContentText>
                        <TextField
                            value = { reportName }
                            error = { (reportName.trim().length == 0 && initialState == true) ? true : false }
                            autoFocus
                            id="report_name_contract"
                            label="Report Name"
                            fullWidth
                            variant="standard"
                            onChange = { handleInputChange }
                            helperText={ (reportName.trim().length == 0 && initialState == true) ? "report name cannot be empty or all spaces" : "" }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ handleCloseDialog }>Cancel</Button>
                        <Button onClick={ handlesaveReport } disabled = { ( reportName.trim().length !== 0  && (Object.keys(filterParamsState.filter).length !== 0 || filterParamsState.rowGroupModel.length !== 0 ) ) ? false : true } >Add</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
