import { combineReducers } from '@reduxjs/toolkit';
import filterParamsReducer from './filterParamsReducer';
import formViewDialog from './formViewDialogReducer';
import reportViewDialogReducer from './reportViewDialogReducer';

const reducer = combineReducers({
	filterParamsReducer,
	formViewDialog,
	reportViewDialogReducer
});

export default reducer;
