import Page from './index';
import { authRoles } from 'app/auth';

export default {
settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
	},
	// auth: authRoles.user,
	routes: [
		{
			path: '/assets-list',
			exact: true,
			component: Page.Layout
		},
		{
			path: '/assets-list/:id',
			exact: true,
			component: Page.AssetDetailsDialouge
		},
		// {
		// 	path: '/asset-infos/detail/:id',
		// 	exact: true,
		// 	component: Page.AssetDetail
		// },
		{
			path: '/assets-list/:serial_no',
			exact: true,
			component: Page.Layout
		}
	]
};