const initialState = {
	open: false,
	quickAddedContractType: false
}

const actionTypes = {
    OPEN_REPORT_VIEWER_DIALOG: "OPEN_REPORT_VIEWER_DIALOG",
    CLOSE_REPORT_VIEWER_DIALOG: "CLOSE_REPORT_VIEWER_DIALOG",
	QUICK_ADDED_CONTRACT_TYPE: "QUICK_ADDED_CONTRACT_TYPE",
}

export function openReportViewerDialog(){
    return {
        type: actionTypes.OPEN_REPORT_VIEWER_DIALOG,
    }
}

export function closeReportViewerDialog(){
    return {
        type: actionTypes.CLOSE_REPORT_VIEWER_DIALOG,
    }
}


export function quickAddedContractType() {
	return {
		type: actionTypes.QUICK_ADDED_CONTRACT_TYPE
	}
}

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.OPEN_REPORT_VIEWER_DIALOG:{
			return { 
				...state,
				open: true,
			}
		}
		case actionTypes.CLOSE_REPORT_VIEWER_DIALOG:{
				return { 
					...state,
					open: false,
				}
		}
		case actionTypes.QUICK_ADDED_CONTRACT_TYPE: {
			return {
				...state,
				quickAddedContractType: !state.quickAddedContractType
			}
		}
	  default:
		return state
	}
}