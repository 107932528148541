import useToast from '@fuse/hooks/useToast'
import {makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import { setFromData } from '../store/formViewDialogReducer';
import Editor from '@fuse/components/Editor';
import LocationTypeDialog from '@fuse/components/Modal/QuickAddLocationType'
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AddLocationTypeFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddLocationTypeFromQuickAction'
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => (
	{
			uploadImageButton: {
					padding: '6px 16px',
					fontSize: '1.3rem',
					minWidth: '64px',
					boxSizing: 'border-box',
					transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					fontFamily: '"Inter", sans-serif',
					fontWeight: 500,
					lineHeight: 1.75,
					borderRadius: '4px',
					textTransform: 'none',
					backgroundColor: '#6B2C57',
					color: '#ffffff',
					cursor: 'pointer'
			},
			loader: {
					display: 'flex',
					'& > * + *': {
							marginLeft: theme.spacing(2),
					},
			},
			subSection: {
					marginLeft: '20px',
					marginRight: '20px',
					marginBottom: '20px',
					padding: '10px',
					backgroundColor: '#F9FAFB',
					borderRadius: '4px'
			},
			endButtons: {
					borderRadius: '4px'
			},
			drawerWidth: {
					width: 520,
					borderTopLeftRadius: '8px',
					borderBottomLeftRadius: '8px',
					overflowX: 'hidden',
					overflowY: 'hidden'
			},
	}
))

const contractList = ['ABCDEFG1', 'JDFJEWFU432', '2WGBAIU2G3', 'HBWQIEU3BI3']

function AddLocationDialog(props) {

  const classes = useStyles()
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
  const openState = useSelector((state) => state.shippingsApp.formViewDialog)
  const { data:editFromState } = useSelector(({shippingsApp}) => shippingsApp.formViewDialog);
  const [loading, setLoading] = useState(false)
  const [ locationNote,setLocationNote ] = useState('')
  const [ locationTypes, setLocationTypes ] = useState([])
  const [ newType, setNewType ] = useState({})
  const [ openAddLocationTypeModal, setOpenAddLocationTypeModal ] = useState(false)
  const [openAlerModal, setOpenAlertModal] = useState(false);

  useEffect(()=>{
    if(editFromState){
      console.log("editFromState.location_type", editFromState.location_type)
      setValue('location_name',editFromState.location_name)
      setValue('address1',editFromState.address_1)
      setValue('address2',editFromState.address_2)
      setValue('city',editFromState.city);
      setValue('state',editFromState.state);
      setValue('zip',editFromState.zip);
      setValue('country',{name: editFromState.country, code: "N/A", currency: 'N/A'});
      setLocationNote(editFromState.note)
      setValue('locationType',(editFromState.location_type))
    }
    getLocationTypes()
  },[editFromState])

  //form validation
  const schema = yup.object({
    location_name: yup.string().trim().required('Location Name is required!'),
    address1: yup.string().trim().required('Address 1 is required!'),
    address2: yup.string().trim().nullable(),
    city: yup.string().trim().required('City is required!'),
    state: yup.string().trim().required('State/Province is required!'),
    zip: yup.string().trim().required('Zip is required!'),
    country: yup.object().shape({
        name: yup.string().required('Country is required!'),
        code: yup.string().required('Country is required!'),
        currency: yup.string().required('Country is required!')
    }),
    note:  yup.string().trim().nullable(),
    locationType: yup.object().shape({
        id: yup.string().required('Location Type is required!'),
        name: yup.string().required('Location Type is required!')
    }),
})

  const defaultValues = {
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    location_name: '',
    location_type: null
  }

  const onCancel = () => {
    shippingLocationFormReset(defaultValues)
    dispatch(setFromData(null))
    dispatch(closeFormViewerDialog())
  }

  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  function generateString(length) {
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const closeDialog = () => {
    dispatch(setFromData(null))
    dispatch(closeFormViewerDialog())
  }

  const { 
    control: shippingLocationFormControl, 
    formState:{errors: shippingLocationFormErrors}, 
    handleSubmit: shippingLocationFormSubmit, 
    trigger,
    clearErrors,
    setValue,
    reset: shippingLocationFormReset 
} = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
});

  // const { errors } = formState

  function getLocationTypes(){
		axios.get("/location-type").then((res) => {
			const { data } = res.data;
			setLocationTypes((()=>{
        return data.map((item)=>{
          return {
            id: item.id,
            name: item.name
          }
        })
      })())
		}).catch((err) => {
			console.log(err)
		})
	}

  function editFrom(payload){
    axios.post('/shipping/edit', payload).then(response => {
      if(response.status == 201){
        toast.error(response.data.message)
      } else {
      //   onSuccess()
        reset(defaultValues)
        closeDialog()
        toast.success('Location updated Successfully.')
        if(props.getContracts){
          props.getContracts()
        }
      //   props.fetchAllHardwareAssets()
      }  
    }
  ).catch(err => {
      console.log('212=>',err);
      toast.error('Something went wrong!')
    })
  }

  const onSubmit = async (data) => {
    await trigger();
    if(Object.keys(shippingLocationFormErrors).length !== 0) { 
        return;
    }
    const payload = {
        entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
        address: data.address1,
        address2: data.address2 ?? '',
        location: data.location_name,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: data.country.name || '',
        note: '',
        location_type_id: data.locationType.id
    }

    if(editFromState){
			payload.edit_id = editFromState.id
      delete payload.entity_external_platform_id
			axios.post(`/shipping/edit`, payload, {
			}).then(results=>{
        if(results.status == 201){
          toast.error(response.data.message)
          return
        }
				toast.success(results.data.message)
        onCancel()
				// setDefault()
				props.getContracts()
			}).catch(err=>{
				console.log(err)
				// toast.error(err.response.data.message);
			})
		}else{
      axios.post('/shipping/add', payload).then(response => {
        if(response.status == 201){
          toast.error(response.data.message)
          return
        }
  
        shippingLocationFormReset(defaultValues)
        onCancel()
  
        //Send newly added location value
        // props.handleCloseLocationDialog(response.data.data.new_shipping_location)
        toast.success('Location added successfully.')
        // newDataAddedEvent(response.data.data.new_shipping_location)
        props.getContracts()
          
      }).catch(err => {
        toast.error(err.response.data.data)
      })
    }
  
    
}

  const errorData = (error) => {
    console.log('363=>',error)
  }

  function onEditorContentChange (content) {
    setLocationNote(content)
  }

  const [createNewLocationType,setCreateNewLocationType] = useState(false)

  const handleLocationTypeDialogClose = (new_location_type=null) => {
		setCreateNewLocationType(false)

		//Set Quick added LOCATION TYPE value into field
		if(new_location_type){
      setValue('location_type',{id:new_location_type.id,name:new_location_type.name})
    }
	}
  const handleLocationTypeDialogOpen = () => {
		setCreateNewLocationType(true)
	}

  const handleOpenCloseAddLocationType = () => {
    setOpenAddLocationTypeModal(!openAddLocationTypeModal);
}

useEffect(()=>{
    setValue('locationType',(newType))
},[newType])

  const newLocationTypeAdded = (createdData = null) => {
    setNewType({id:createdData.id,name:createdData.name})
    // getLocationTypes()
    
    setOpenAddLocationTypeModal(false)
    getLocationTypes()
    // clearErrors('locationType')
    
}

const handleAlertModal=(_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenAlertModal(!openAlerModal)
}

const handleCloseAlertModal=() =>{
    setOpenAlertModal(false)
    onCancel()
}

  return (
    <div>
      {
        openAddLocationTypeModal && <AddLocationTypeFromQuickAction 
        handleOpenCloseAddModalEvent={handleOpenCloseAddLocationType}
        openAddStatusModalStateData={openAddLocationTypeModal}
        newDataAddedEvent={newLocationTypeAdded}
        />
            }
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>
					<h4 className='heading'>{editFromState?'Update':'Add'} Location</h4>
					<IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
							<Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
					</IconButton>
        </div>
				<div className='progressbar_holder'>
					<div className='progress'>
							&nbsp;
					</div>
				</div>
					<div style={{paddingTop:'30px'}} className='cstm_wizard_holder'>
						<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder'>
								<div className='content_holder'>
									<div className="text-left">
										<div className='mb-10'>
											<Typography className='status_text'>
													Location Name
													<span className='required_span'>*</span>
											</Typography>
											<Controller
                            name="location_name"
                            className="mb-16"
                            defaultValue={defaultValues.location_name}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                    {...field}
                                    variant='outlined'
                                    // label='Location Name'
                                    required
                                    error={!!shippingLocationFormErrors.location_name}
                                    helperText={shippingLocationFormErrors?.location_name?.message}
                                    FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px',
                                            fontSize: '10px'
                                        }
                                    }}
                                    style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                      /> 
										</div>

										<div className='mb-10'>
                        <Typography className='status_text'>
                            Address 1
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="address1"
                            className="mb-16"
                            defaultValue={defaultValues.address1}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                    {...field}
                                    variant='outlined'
                                    // label='Address Line 1'
                                    required
                                    // fullWidth
                                    error={!!shippingLocationFormErrors.address1}
                                    helperText={shippingLocationFormErrors?.address1?.message}
                                    FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px',
                                            fontSize: '10px'
                                        }
                                    }}
                                    style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Address 2
                            {/* <span className='required_span'>*</span> */}
                        </Typography>
                        <Controller
                            name="address2"
                            className="mb-16"
                            defaultValue={defaultValues.address2}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                    {...field}
                                    variant='outlined'
                                    // label='Address Line 1'
                                    required
                                    // fullWidth
                                    error={!!shippingLocationFormErrors.address2}
                                    helperText={shippingLocationFormErrors?.address2?.message}
                                    FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px',
                                            fontSize: '10px'
                                        }
                                    }}
                                    style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            City
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="city"
                            className="mb-16"
                            defaultValue={defaultValues.city}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='City'
                                        // fullWidth
                                        required
                                        error={!!shippingLocationFormErrors.city}
                                        helperText={shippingLocationFormErrors?.city?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{ marginBottom: '10px', width: '100%', marginRight: '10px',background: 'white'  }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            State/Province
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="state"
                            className="mb-16"
                            defaultValue={''}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='State/Province'
                                        required
                                        // fullWidth
                                        error={!!shippingLocationFormErrors.state}
                                        helperText={shippingLocationFormErrors?.state?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{ marginBottom: '10px', width: '100%', marginRight: '10px',background: 'white'  }} 
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Zip
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="zip"
                            className="mb-16"
                            defaultValue={''}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='State/Province'
                                        required
                                        // fullWidth
                                        error={!!shippingLocationFormErrors.zip}
                                        helperText={shippingLocationFormErrors?.zip?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{ marginBottom: '10px', width: '100%', marginRight: '10px',background: 'white'  }} 
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='autocomplete_holder mb-10'>
                        <Typography className='status_text'>
                            Country
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="country"
                            className="mb-16"
                            control={shippingLocationFormControl}
                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                <>
                                    <Autocomplete
                                        {...field}
                                        style={{ width: '100%' }}
                                        options={countries}
                                        // disablePortal
                                        onChange={(event, value) => field.onChange(value)}
                                        PaperComponent={({ children }) => (
                                        <Paper className='autocomplete_holder'>{children}</Paper>
                                        )}
                                        getOptionLabel={option => option.name ?? ''}
                                        renderInput={(params) => {
                                        return (
                                            <div ref={params.InputProps.ref}>
                                                <TextField
                                                    style={{background: 'white'}}
                                                    {...params}
                                                    // label="Country"
                                                    // required
                                                    error={!!shippingLocationFormErrors.country}
                                                    helperText={shippingLocationFormErrors?.country?.message && 'Country is required'}
                                                    FormHelperTextProps={{
                                                        style: { 
                                                            margin : 0, 
                                                            backgroundColor: '#f4f4f4',
                                                            width: '100%',
                                                            paddingTop: '2px',
                                                            fontSize: '10px',
                                                        }
                                                    }}
                                                    inputRef={ref}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                        )
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='autocomplete_holder mb-10'>
                        <Typography className='status_text'>
                            Location Type
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="locationType"
                            // className="mb-16"
                            control={shippingLocationFormControl}
                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                <>
                                    <Autocomplete
                                        {...field} 
                                        style={{ width: '100%'}}
                                        options={locationTypes}
                                        // disablePortal
                                        onChange={(event, value) => field.onChange(value)}
                                        PaperComponent={({ children }) => (
                                            <Paper className='autocomplete_holder'>{children}
                                                <div className='p-16'>
                                                    <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddLocationType()}>
                                                        <i className='ti ti-plus' />
                                                        <span>Add Location Type</span>
                                                    </Button>
                                                </div>
                                            </Paper>
                                        )}
                                        getOptionLabel={option => option.name ?? ''}
                                        renderInput={(params) => {
                                            return (
                                                <div ref={params.InputProps.ref}>
                                                    <TextField
                                                        name="locationType"
                                                        style={{background: 'white'}}
                                                        {...params}
                                                        // label="Location Type"
                                                        // required
                                                        error={!!shippingLocationFormErrors.locationType}
                                                        helperText={shippingLocationFormErrors?.locationType?.message && 'Location type is required'}
                                                        FormHelperTextProps={{
                                                            style: { 
                                                                margin : 0, 
                                                                backgroundColor: '#f4f4f4',
                                                                width: '100%',
                                                                paddingTop: '2px',
                                                                fontSize: '10px'
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>
                                            )
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
									</div>
								</div>
								{/* <DialogActions className="action_footer_holder"> */}
								<div className='btn_holder'>
									<Button
										color="inherit"
										onClick={handleAlertModal}
										className='back_btn secondary_btn'
									>
									Cancel
									</Button>
									<Button onClick={shippingLocationFormSubmit(onSubmit)} className='next_btn'>                                                
										Proceed
									</Button>
								</div>
							</div>
						</Box>
					</div>
			</Drawer>

            {openAlerModal &&
                <AlertModal  openAlerModal module="location" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
		</div>
  )
}

export default AddLocationDialog
