import { showMessage } from 'app/store/fuse/messageSlice';

export default (dispatch) => {

    function toast(message, options){
        dispatch(showMessage({
            message,
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical  : 'bottom',
                horizontal: 'right'
            },
        }));
    }

    toast.success = function(message, delay = 3000){
        dispatch(showMessage({
            message,
            autoHideDuration: delay,
            anchorOrigin: {
                vertical  : 'bottom',
                horizontal: 'right'
            },
            variant: "success",
            // ...delay
        }));
    }

    toast.error = function(message, delay = 3000){
        dispatch(showMessage({
            message,
            autoHideDuration: delay,
            anchorOrigin: {
                vertical  : 'bottom',
                horizontal: 'right'
            },
            variant: "error",
            // ...delay
        }));
    }

    return toast;
}
  