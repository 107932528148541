import axios from "@fuse/utils/axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  Icon,
  IconButton,
  makeStyles,
  Modal,
  TextField,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Paper,
  MenuItem,
  InputAdornment,
  Checkbox,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import useToast from "@fuse/hooks/useToast";
import { countries } from "@fuse/utils/countryName";
import CustomDatePicker from "@fuse/components/customDatePicker";
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import ExpandMore from '@material-ui/icons/ExpandMore'
import RadioGroup from '@material-ui/core/RadioGroup';
//Quick add componenets related imports
import QuickAddContractStatus from "@fuse/components/Modal/QuickAddContractStatus";
import QuickAddLocation from "@fuse/components/Modal/QuickAddLocationDialog";
import QuickAddContractType from "@fuse/components/Modal/QuickAddContractType";
import QuickAddSrviceLevelAggrement from "@fuse/components/Modal/QuickAddServiceLevelAggrement";
import QuickAddPaymentTerm from "@fuse/components/Modal/QuickAddPaymentTerm";
import QuickAddServiceProviderDialog from "@fuse/components/Modal/QuickAddServiceProvider";
import { usePermission } from '@fuse/hooks';
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import AddPhoneNumberModal from '@fuse/components/Modal/AddPhoneNumberModal'
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subSection: {
    marginLeft: "20px !important",
    marginRight: "20px !important",
    marginBottom: "20px !important",
    padding: "10px",
    backgroundColor: "#f4f4f4",
    borderRadius: "4px",
  },
}));

function QuickAddContract(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const { hasPermission } = usePermission()
	const createCPaymentTermPermission = hasPermission('add-payment_term')
  //component state
  // const [startDate,setStartDate] = useState('')
  // const [endDate,setEndDate] = useState('')
  const [shippingLocationMenu, setShippingLocationMenu] = useState([]);
  const [contractStatusMenu, setContractStatusMenu] = useState([]);
  const [serviceProviderMenu, setServiceProviderMenu] = useState([]);
  const [paymentTermMenu, setPaymentTermMenu] = useState([]);
  const [srviceLevelAggrementMenu, setSrviceLevelAggrementMenu] = useState([]);
  const [contractsMenu, setContractsMenu] = useState([]);
  const [childContract, setChildContract] = useState(false);
  const [parentContractMenu, setParentContractMenu] = useState([]);
  const [openPhoneNoModal, setOpenPhoneNoModal] = useState(false)
	const [phoneNumberPresent, setPhoneNumberPresent] = useState(false)
  const [phoneNumberAdded, setPhoneNumberAdded] = useState(false)
  const [currentCountryCurrency, setCurrentCountryCurrency] = useState({
		"name": "United States",
		"code": "US",
		"currency": "USD"
	})
  const [cardNumber, setCardNumber] = useState('');
  const [noOfDays, setNoOfDays] = useState('')
  const [reminderCheckboxes, setReminderCheckBoxes] = useState({
    term45: true,
    term30: false,
    supportContact: false,
    email: false
  });
  const [noReminderCheckBox, setNoReminderCheckBox] = useState(false);
  const [checkPaymentMethod, setCheckPaymentMethod] = useState(true)
  const [purchaseOrder,setPurchaseOrder] = useState('purchaseOrder')
  const [checkForBilling, setCheckForBilling] = useState(true)
  //Quick add dialogs related state
  const [
    openContractStatusQuickAddDialog,
    setOpenContractStatusQuickAddDialog,
  ] = useState(false);
  const [openLocationQuickAddDialog, setOpenLocationQuickAddDialog] = useState(
    false
  );
  const [
    openContractTypeQuickAddDialog,
    setOpenContractTypeQuickAddDialog,
  ] = useState(false);
  const [
    openSrviceLevelAggrementQuickAddDialog,
    setOpenSrviceLevelAggrementQuickAddDialog,
  ] = useState(false);
  const [
    openPaymentTermQuickAddDialog,
    setOpenPaymentTermQuickAddDialog,
  ] = useState(false);
  const [
    openServiceProviderQuickAddDialog,
    setOpenServiceProviderQuickAddDialog,
  ] = useState(false);

  //Form validation schema
  const schema = yup.object({
    contractId: yup.string().required('Contract Number is required!'),
		renewal: yup.string().required('Renewal Status is required!'),
		contractName: yup.string().required('Contract Name is required!'),
		// contractDescription: yup.string().max(200, 'Maximum 200 characters!').nullable(),
		totalContractValue: yup.string().typeError('Invalid value').required('The Total Contract Value is required!').test('totalContractValue', 'Invalid Value', (value) => {
			return /^\d+([\,]\d+)*([\.]\d+)?$/.test(value.toString())
		}),
    contractStatus: yup.object().nullable().required('Contract Status is required!'),
		contractType: yup.string().nullable().required('Contract Type is required!'),
		currentShippingLocation: yup.object().nullable().required('Location is required!'),
		master: yup.object().nullable().required('Master is required!'),
		parentContract: yup.object().nullable(),
    provider: yup.object().nullable().required('Service Provider is required!'),
		sla: yup.object().nullable().required('Service Level Agreement is required!'),
		poNumber:yup.string().when('purchaseOrder', {
			is: 'purchaseOrder',
			then: yup.string().required('PO Number is required'),
			otherwise: yup.string().nullable(),
		}),
		cardHolderName:  yup.string().nullable(),
    cardNumber: yup.string().nullable(),
		
		// yup.number().when('purchaseOrder', {
		// 	is: 'CreditCard',
		// 	then: yup.number().required('Card Number is required').typeError('Invalid Value').test('cardNumber', 'Invalid Value', (value) => {
		// 		if(value.toString().length > 4) {
		// 			return false;
		// 		} 
		// 		return true
		// 	}),
		// 	otherwise: yup.number().nullable(),
		// }),
		every: yup.number().required('Every Field Value is required.').typeError('Invalid Value').test('every', 'Invalid Value', (value) => {
			return /^[0-9]+$/.test(value.toString())
		}),
		billingCycleAmount: yup.string().typeError('Invalid value').required('The Billing Cycle Amount is required!').test('billingCycleAmount', 'Invalid Value', (amountValue) => {
			return /^\d+([\,]\d+)*([\.]\d+)?$/.test(amountValue.toString())
		}),
		billingCycleCurrency: yup.object().shape({
			name:  yup.string().required('Currency is required.'),
			code: yup.string().required('Currency is required.'),
			currency: yup.string().required('Currency is required.')
		}),
    support_phone_number: yup.string().when({
      is: (val) => !!val,
      then: yup
        .string()
        .matches(
          /[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
          "Phone number should be in 000-000-0000 format"
        ),
    }),
  });

  const defaultValues = {
    contractId: "",
    contractName: "",
    contractDescription: "",
    // contractStartDate: "",
    // contractEndDate: "",
    contractStatus: null,
    currentShippingLocation: null,
    contractType: "",
    renewal: "",
    master: {},
    parentContract: null,
    srviceLevelAggrement: null,
    paymentAmount: "",
    paymentTerm: null,
    poNumber: "",
    cardHolderName: "",
    cardNumber: "",
    // poDate: "",
    provider: null,
    support_phone_number: "",
    totalContractValue: "",
    billingCycleAmount: "",
    billingCycleCurrency: { "name": "United States", "code": "US", "currency": "USD" },
    every: 1,
    paymentTerm: null,
  };

	const [contractStartDate,setContractStartDate] = useState({
		date: null,
		error: false
	})
  const [billingDate,setBillingDate] = useState({
		date: null,
		error: false
	})
	const [contractEndDate,setContractEndDate] = useState({
		date: null,
		error: false
	})
	const [poDate,setPoDate] = useState({
		date: null,
		error: false
	})
  const [paymentDate,setPaymentDate] = useState({
		date: null,
		error: false
	})

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  function manageContarctData(formData) {
    // console.log('manageStatusData: ',formData);
    if (!formData.master?.value && !formData.parentContract) {
      toast.error("Parent Contract required!");
      return;
    }

    if (formData.contractDescription.length > 200) {
			toast.error('Description should be maximum 200 characters')
      return
    }
    const end = new Date(contractEndDate.date);
		const start = new Date(contractStartDate.date);
		const duration = ((end-start)/(1000*60*60*24))
		if(reminderCheckboxes.term45 && noOfDays>duration){
			toast.error('Reminder no of days should me lesser than contract duration')
			return 
		}
		if(reminderCheckboxes.term45 && !noOfDays){
			toast.error('Reminder no of days is required')
			return
		}

    if(reminderCheckboxes.term45 && !reminderCheckboxes.supportContact && !reminderCheckboxes.email ){
			toast.error('Please select a text me or email me option.')
			return
		}

    if (formData.contractDescription.length > 200) {
			toast.error('Description should be maximum 200 characters')
      return
    }

    let payload = {
      contract_id: formData.contractId,
			contract_name: formData.contractName,
			contract_description: formData.contractDescription,
			contract_start_date: contractStartDate.date ?? '',
			contract_end_date: contractEndDate.date ?? '',
			contract_status: formData.contractStatus,
			shipping_id: formData.currentShippingLocation.id,
			total_contract_value: formData.totalContractValue.replaceAll(',',''),
			// billing_id: formData.currentBillingLocation.id,
			contract_type: formData.contractType,
			master: formData.master?.value,
			parent_contract_id: formData.master?.value ? 0 : formData.parentContract?.id,
			// notes: formData.notes,
			// department: formData.department,
			contract_description: formData.contractDescription,
			provider: formData.provider,
			sla: formData.sla,
			renewal: formData.renewal,
			support_phone_number: reminderCheckboxes.supportContact ? formData.support_phone_number : null,
			// poNumber: formData.poNumber,
			// poDate: poDate.date ?? '',
			// country: formData.country,
			no_reminder: noReminderCheckBox,
			reminder: { term45: reminderCheckboxes.term45, term30: reminderCheckboxes.term30 },
			textMessage: reminderCheckboxes.supportContact,
			no_of_days: noOfDays,
			// paymentAmount: formData.paymentAmount.replaceAll(',',''),
			// paymentTerm: formData.paymentTerm,
			billing: {
				amount: formData.billingCycleAmount.replaceAll(',',''),
				location: formData.billingCycleCurrency.name,
				cycle: String(formData.every) || '',
				month: formData.paymentTerm.name || '',
				date: billingDate.date || '',
				currency: formData.billingCycleCurrency.currency,
				payment_term_id: formData.paymentTerm.id
			},
			payment: {
				value: purchaseOrder,
				po_number:  purchaseOrder === 'purchaseOrder' ? formData.poNumber !== '' ? formData.poNumber : null : null,
				po_date: purchaseOrder === 'purchaseOrder' ? paymentDate.date !== '' ?  paymentDate.date : null: null,
				// card_number:formData.cardNumber !== '' ? formData.cardNumber : null,
				card_number: purchaseOrder === 'CreditCard' ? cardNumber  === '' ? null : cardNumber : null,
				card_holder_name: purchaseOrder === 'CreditCard' ? formData.cardHolderName !== '' ? formData.cardHolderName: null : null,
			},
      
    };
    // console.log('payload: ',payload);
    axios
      .post("/contracts/add-contract-manually", payload)
      .then((response) => {
        if (response.status == 201) {
          toast.error(response.data.message);
        } else {
          toast.success("Contract Added Successfully.");
          close(response.data.data.created_contract);
          setNoOfDays('')
          setReminderCheckBoxes({
            term45: true,
            term30: false,
            supportContact: false,
            email: false
          });

          setPoDate({
            date: null,
            error: false
          })
          setPaymentDate({
            date: null,
            error: false
          })
          setBillingDate({
            date: null,
            error: false
          })
        }
      })
      .catch((err) => {
        console.log("212=>", err);
        toast.error("Something went wrong!");
      });
  }

  useEffect(()=>{
		if(noReminderCheckBox){
			setNoOfDays('')
			setValue('support_phone_number', '')
		}
	},[noReminderCheckBox])

  const handleCurrentCountry = (countryCode) => {
		if(countryCode !== null) {
			const current = (countries.filter(country => country.code === countryCode))[0]
			setCurrentCountryCurrency(current)
			setValue('billingCycleCurrency', current)
		} else {
			setCurrentCountryCurrency({ "name": "United States", "code": "US", "currency": "USD" })
			setValue('billingCycleCurrency', { "name": "United States", "code": "US", "currency": "USD" })
		}
		
	}

  function summeryClickSubPayment() {
		setCheckPaymentMethod(prevState => {
			return !prevState;
		})
	}

  function summeryClickSubBilling() {
		// let expand = !checkForBilling
		setCheckForBilling(prevState => {
			return !prevState;
		})
	}

  const handleRadioChange = (event) => {
		if(event.target.value == 'purchaseOrder'){
			// setCardNumber('')
			// setValue('cardHolderName','')
			// setValue('cardNumber','')
		}if(event.target.value == 'CreditCard'){
			// setPaymentDate({
			// 	date: null,
			// 	error: false
			// });
			// setValue('poNumber', '');
		}
		setPurchaseOrder(event.target.value);
	};

  function contractFormErrorData(error) {
    console.log("contractFormErrorData: ", error);
  }

  function close(new_contarct = null) {
    reset(defaultValues);
		setContractStartDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setContractEndDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setPoDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
    props.handleCloseDialog(new_contarct);
  }

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  const handleChangeNoReminderCheckbox = () => {
    setNoReminderCheckBox(!noReminderCheckBox);
  };

  const handleChangeReminderCheckBoxes = (event) => {
    if (
      (event.target.name === "term45" &&
        event.target.checked === false &&
        reminderCheckboxes.term30 === false) ||
      (event.target.name === "term30" &&
        event.target.checked === false &&
        reminderCheckboxes.term45 === false)
    ) {
      setReminderCheckBoxes({
        ...reminderCheckboxes,
        [event.target.name]: event.target.checked,
        supportContact: false,
      });
      setValue("support_phone_number", "");
      setNoReminderCheckBox(true);
    } else {
      setReminderCheckBoxes({
        ...reminderCheckboxes,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const handleChangeEmailOption = (event) => {
		if(reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true){
			setReminderCheckBoxes({
				...reminderCheckboxes,
				email: event.target.checked
			})
		}
	}

  //async calls
  const fetchAllSuggestion = () => {
    axios
      .get(`contracts/get-all-suggestion`)
      .then((response) => {
        const {
          paymentTerm,
          shipping,
          contractStatus,
          sla,
          serviceProvider,
        } = response.data.data;
        setContractStatusMenu(contractStatus);
        setPaymentTermMenu(paymentTerm);
        setServiceProviderMenu(serviceProvider);
        setSrviceLevelAggrementMenu(sla);
        setShippingLocationMenu(shipping);
      })
      .catch((err) => {
        console.log("err", err);
        //   toast.error('Something went wrong!')
      });
  };

  function fetchContractType() {
    axios
      .get(`contracts/get-contract-types`)
      .then((response) => {
        // console.log(response.data.data)
        setContractsMenu(response.data.data.map((item) => item.display_name));
      })
      .catch((err) => {
        console.log("err", err);
        // toast.error('Something went wrong!')
      });
  }

  function fetchParentContract() {
    axios
      .get(`contracts/get-parent-contracts`)
      .then((response) => {
        // console.log(response.data.data)
        setParentContractMenu(response.data.data);
      })
      .catch((err) => {
        console.log("err", err);
        // toast.error('Something went wrong!')
      });
  }

  useEffect(() => {
    fetchAllSuggestion();
    fetchContractType();
    fetchParentContract();
  }, []);

  /**Quick Add Contarct Status */
  function handleContractStatusCloseDialog(new_status = null) {
    if (new_status) {
      setValue("contractStatus", new_status);
    }
    setOpenContractStatusQuickAddDialog(false);
    fetchAllSuggestion();
  }

  if (openContractStatusQuickAddDialog) {
    return (
      <QuickAddContractStatus
        open={openContractStatusQuickAddDialog}
        handleCloseDialog={handleContractStatusCloseDialog}
      />
    );
  }

  /**Quick Add Location */
  function handleLocationCloseDialog(new_location = null) {
    if (new_location) {
      setValue("currentShippingLocation", new_location);
    }
    setOpenLocationQuickAddDialog(false);
  }

  if (openLocationQuickAddDialog) {
    return (
      <QuickAddLocation
        fetchLocationSuggestions={fetchAllSuggestion}
        createNewShippingLocation={openLocationQuickAddDialog}
        handleCloseLocationDialog={handleLocationCloseDialog}
      />
    );
  }

  /**Quick Add Contract Type */
  function handleContractTypeCloseDialog(new_contarct_type) {
    if (new_contarct_type) {
      setValue("contractType", new_contarct_type);
    }
    setOpenContractTypeQuickAddDialog(false);
    fetchContractType();
  }

  if (openContractTypeQuickAddDialog) {
    return (
      <QuickAddContractType
        open={openContractTypeQuickAddDialog}
        handleCloseDialog={handleContractTypeCloseDialog}
      />
    );
  }

  /**Quick Add Service Level Agreement */
  function handlSrviceLevelAggrementCloseDialog(
    new_service_level_aggrement = null
  ) {
    if (new_service_level_aggrement) {
      setValue("srviceLevelAggrement", new_service_level_aggrement);
    }
    setOpenSrviceLevelAggrementQuickAddDialog(false);
    fetchAllSuggestion();
  }

  if (openSrviceLevelAggrementQuickAddDialog) {
    return (
      <QuickAddSrviceLevelAggrement
        open={openSrviceLevelAggrementQuickAddDialog}
        handleCloseDialog={handlSrviceLevelAggrementCloseDialog}
      />
    );
  }

  /**Quick Add Payment Term */
  function handlPaymentTermCloseDialog(new_payment_term) {
    if (new_payment_term) {
      setValue("paymentTerm", new_payment_term);
    }
    setOpenPaymentTermQuickAddDialog(false);
    fetchAllSuggestion();
  }

  if (openPaymentTermQuickAddDialog) {
    return (
      <QuickAddPaymentTerm
        open={openPaymentTermQuickAddDialog}
        handleCloseDialog={handlPaymentTermCloseDialog}
      />
    );
  }

  /**Quick Add Service Provider*/
  function handlServiceProviderCloseDialog(new_service_provider) {
    if (new_service_provider) {
      setValue("provider", new_service_provider);
    }
    setOpenServiceProviderQuickAddDialog(false);
    fetchAllSuggestion();
  }

  if (openServiceProviderQuickAddDialog) {
    return (
      <QuickAddServiceProviderDialog
        open={openServiceProviderQuickAddDialog}
        handleCloseDialog={handlServiceProviderCloseDialog}
      />
    );
  }

  const handlePhoneNumberCloseModal = () => {
		setOpenPhoneNoModal(false)
		// console.log("phoneNumberAdded", phoneNumberAdded)
		// if(!phoneNumberAdded && !phoneNumberPresent){
		// 	setReminderCheckBoxes({
		// 		...reminderCheckboxes,
		// 		supportContact: false
		// 	})
		// }
	}
	useEffect(()=>{
		if(!phoneNumberAdded && !phoneNumberPresent && !openPhoneNoModal){
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: false
			})
		}else{
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: true
			})
		}
	},[phoneNumberAdded,phoneNumberPresent,openPhoneNoModal])

  const handleChangeTextMeOption = (event) => {
    event.target.checked && axios.get(`user/get-phone-number`).then(response => {
			if(!response.data.data.phoneNumber.phone){
				setOpenPhoneNoModal(true)
				setPhoneNumberPresent(false)
			}else{
				setPhoneNumberPresent(true)
			}
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
    if (
      reminderCheckboxes.term30 === true ||
      reminderCheckboxes.term45 === true
    ) {
      setReminderCheckBoxes({
        ...reminderCheckboxes,
        supportContact: event.target.checked,
      });
    }

    if (event.target.checked === false) {
      setValue("support_phone_number", "");
    }
  };

  return (
    <Modal
      open={props.open}
      // onClose={close}
      className={classes.modal}
    >
      <div
        style={{
          width: 700,
          backgroundColor: "white",
          borderRadius: 9,
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          maxHeight: "98vh",
          overflow: "auto",
        }}
      >
        <AddPhoneNumberModal 
          open={openPhoneNoModal}
          handlePhoneNumberCloseModal = {handlePhoneNumberCloseModal}
          setPhoneNumberAdded = {setPhoneNumberAdded}
			  />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          <h1>Add Contract</h1>
          <IconButton
            onClick={() => {
              close();
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>

        <form
          onSubmit={handleSubmit(manageContarctData, contractFormErrorData)}
        >
          {/*---------------Contract Description*---------*/}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary style={{ pointerEvents: "none" }}>
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Contract Description</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    marginBottom: "10px",
                    marginRight: "10px",
                    width: "100%",
                  }}
                >
                  <Controller
                    name="contractId"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Contract Number"
                          fullWidth
                          inputProps={{ readOnly: false }}
                          required
                          error={!!errors.contractId}
													helperText={errors?.contractId?.message}
                          style={{ backgroundColor: "#fff" }}
                        />
                      </>
                    )}
                  />
                </div>

                <div style={{ marginBottom: "10px", width: "100%" }}>
                  <Controller
                    name="contractName"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Contract Name"
                          fullWidth
                          error={!!errors.contractName}
													helperText={errors?.contractName?.message}
                          required
                          style={{ backgroundColor: "#fff" }}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Controller
                  name="contractDescription"
                  className="mb-16"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextareaAutosize
                        {...field}
                        variant="outlined"
                        label="Contract Description"
                        placeholder="Contract Description"
                        fullWidth
                        aria-label="maximum height"
                        className="customTextArea"
                        error={!!errors.contractDescription}
												helperText={errors?.contractDescription?.message}
                        style={{
                          backgroundColor: "#fff",
                          width: "100%",
                          border: "1px solid #aaa",
                          borderRadius: "5px",
                          height: "100px",
                          padding: "12px 13px",
                        }}
                      />
                    </>
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <div className='cstm-date' style={{ marginRight: "10px", width: "100%" }}>
                  {/* <Controller
                    name="contractStartDate"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Start Date"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                            setStartDate(event.target.value);
                          }}
                          fullWidth
                          required
                          inputProps={{ max: `${endDate}` }}
                          style={{ width: "100%", backgroundColor: "#fff" }}
                        />
                      </>
                    )}
                  /> */}
									<CustomDatePickerV2
										state={contractStartDate}
										setState={setContractStartDate}
										required={true}
										label='Contract Start Date*'
										maxDate={contractEndDate.date}
										condition='Contract End Date'									
									/>
                </div>

                <div className='cstm-date' style={{ width: "100%" }}>
                  {/* <Controller
                    name="contractEndDate"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="End Date"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(event) => {
                            field.onChange(event.target.value);
                            setEndDate(event.target.value);
                          }}
                          required
                          inputProps={{ min: `${startDate}` }}
                          style={{ width: "100%", backgroundColor: "#fff" }}
                        />
                      </>
                    )}
                  /> */}
									<CustomDatePickerV2
										state={contractEndDate}
										setState={setContractEndDate}
										required={true}	
										label='Contract End Date*'
										minDate={contractStartDate.date}
										condition='Contract Start Date'											
									/>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginBottom: "10px", width: "100%" }} className="relative">
                  <Controller
                    name="contractStatus"
                    className="mb-16"
                    control={control}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          {...field}
                          style={{ width: "100%" }}
                          options={contractStatusMenu}
                          disablePortal
                          onChange={(event, value) => {
                            field.onChange(value);
                          }}
                          PaperComponent={({ children }) => (
                            <Paper className="autocomplete_holder">
                              {children}
                              <Button
                                style={{ width: "100%" }}
                                variant="outlined"
                                color="primary"
                                onMouseDown={() =>
                                  setOpenContractStatusQuickAddDialog(true)
                                }
                              >
                                Add Contract Status
                              </Button>
                            </Paper>
                          )}
                          getOptionLabel={(option) =>
                            option.contract_status_name ?? ""
                          }
                          renderInput={(params) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params}
                                  name="contractStatus"
                                  label="Contract Status"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  inputRef={ref}
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginBottom: "10px", width: "100%" }} className="relative">
                  <Controller
                    name="currentShippingLocation"
                    className="mb-16"
                    control={control}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          {...field}
                          style={{ width: "100%" }}
                          options={shippingLocationMenu}
                          disablePortal
                          onChange={(event, value) => {
                            field.onChange(value);
                          }}
                          PaperComponent={({ children }) => (
                            <Paper className="autocomplete_holder">
                              {children}
                              <Button
                                style={{ width: "100%" }}
                                variant="outlined"
                                color="primary"
                                onMouseDown={() =>
                                  setOpenLocationQuickAddDialog(true)
                                }
                              >
                                Add Location
                              </Button>
                            </Paper>
                          )}
                          getOptionLabel={(option) =>
                            `${option.location_name} - ${option.address1}, ${option.city}, ${option.state}, ${option.country}`
                          }
                          renderInput={(params) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params}
                                  name="currentShippingLocation"
                                  label="Location"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="totalContractValue"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextField
												// inputRef={totalContractValueRef}
												{...field}
												variant='outlined'
												label='Total Contract Value'
												fullWidth
												required
												autocomplete="off"
												error={!!errors.totalContractValue}
												helperText={errors?.totalContractValue?.message}
												onBlur={()=>{
                          
													setValue('totalContractValue', (!isNaN(getValues('totalContractValue')) ? (clearErrors('totalContractValue', ''), formatPriceFieldOnBlur(getValues('totalContractValue'))) : getValues('totalContractValue'))  )																		
												}}
												// disabled = {disableField}
												// InputProps={{
												// 	startAdornment:
												// 		<InputAdornment style={{ color: "grey", marginRight: '0' }} disableTypography position="start">
												// 			www.</InputAdornment>,
												// }}
												FormHelperTextProps={{
													style: { marginLeft: 0 }
												}}
												style={{ backgroundColor: '#fff' }}
											/>
										</>
									)}
								/>
							</div>

            </AccordionDetails>
          </Accordion>

          {/*---------------Contract Type------------------*/}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary
              style={{ pointerEvents: "none" }} /*expandIcon={<ExpandMore />}*/
            >
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Contract Type</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  className="relative"
                >
                  <>
                    <Controller
                      name="contractType"
                      className="mb-16"
                      control={control}
                      render={({
                        field: { ref, ...field },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            {...field}
                            style={{ width: "100%" }}
                            options={contractsMenu}
                            disablePortal
                            // getOptionLabel={option => option.display_name }
                            onChange={(event, value) => field.onChange(value)}
                            PaperComponent={({ children }) => (
                              <Paper className="autocomplete_holder">
                                {children}
                                <Button
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  color="primary"
                                  onMouseDown={() =>
                                    setOpenContractTypeQuickAddDialog(true)
                                  }
                                >
                                  Add Contract Type
                                </Button>
                              </Paper>
                            )}
                            renderInput={(params) => {
                              return (
                                <div ref={params.InputProps.ref}>
                                  <TextField
                                    {...params}
                                    name="contractType"
                                    label="Contract Type"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    inputRef={ref}
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#fff",
                                    }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </>
                      )}
                    />
                  </>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Controller
                  name="renewal"
                  className="mb-16"
                  control={control}
                  render={({ field }) => {
                    // console.log('1914=>',field)
                    return (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          select
                          label="New/Renew"
                          fullWidth
                          required
                          error={!!errors.renewal}
													helperText={errors?.renewal?.message}
                          style={{ width: "100%", backgroundColor: "#fff" }}
                        >
                          {["New", "Renew"].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    );
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Controller
                  name="master"
                  className="mb-16"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <div className="relative">
                        <Autocomplete
                          {...field}
                          style={{ width: "100%" }}
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                          disablePortal
                          getOptionLabel={(option) => `${option?.label ?? ""}`}
                          onChange={(event, value) => {
                            // console.log('622: ',value);
                            setChildContract(!value.value);
                            field.onChange(value);
                          }}
                          PaperComponent={({ children }) => (
                            <Paper className="autocomplete_holder">
                              {children}
                            </Paper>
                          )}
                          renderInput={(params) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params}
                                  name="master"
                                  label="Master"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  inputRef={ref}
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </div>

              {childContract && (
                <div style={{ display: "flex", justifyContent: "center" }} className="relative">
                  <Controller
                    name="parentContract"
                    className="mb-16"
                    control={control}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          {...field}
                          style={{ width: "100%" }}
                          options={parentContractMenu}
                          disablePortal
                          getOptionLabel={(option) =>
                            `${option.entity_external_platform_id}-${option.contract_name}`
                          }
                          onChange={(event, value) => field.onChange(value)}
                          PaperComponent={({ children }) => (
                            <Paper className="autocomplete_holder">
                              {children}
                            </Paper>
                          )}
                          renderInput={(params) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params}
                                  name="parentContract"
                                  label="Parent Contract"
                                  variant="outlined"
                                  fullWidth
                                  inputRef={ref}
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </>
                    )}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          {/*------------Service Level Agreement-----------*/}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary
              style={{ pointerEvents: "none" }} /*expandIcon={<ExpandMore />}*/
            >
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Service Level Agreement</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
                className="relative"
              >
                <Controller
                  name="sla"
                  className="mb-16"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        {...field}
                        style={{ width: "100%" }}
                        options={srviceLevelAggrementMenu}
                        disablePortal
                        getOptionLabel={(option) => option.sla_name ?? ""}
                        onChange={(event, value) => field.onChange(value)}
                        PaperComponent={({ children }) => (
                          <Paper className="autocomplete_holder">
                            {children}
                            <Button
                              style={{ width: "100%" }}
                              variant="outlined"
                              color="primary"
                              onMouseDown={() =>
                                setOpenSrviceLevelAggrementQuickAddDialog(true)
                              }
                            >
                              Add Service Level Agreement
                            </Button>
                          </Paper>
                        )}
                        renderInput={(params) => {
                          return (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                {...params}
                                name="sla"
                                label="Service Level Agreement"
                                variant="outlined"
                                fullWidth
                                required
                                inputRef={ref}
                                style={{
                                  width: "100%",
                                  backgroundColor: "#fff",
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/*----------------------billing cycle------------------- */}
					<Accordion expanded={checkForBilling} className={classes.subSection}>
						<AccordionSummary onClick={summeryClickSubBilling}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Billing Cycle</Typography>
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div className='w-full'>
								<div style={{ display: 'flex', justifyContent: 'center', }}>
									<div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
										<div style={{ marginBottom: '10px', marginRight: '10px' }}>
											<Controller
												name="billingCycleAmount"
												className="mb-16"
												control={control}
												render={({ field }) => {
													return (
														<>
															<TextField
																// inputRef={billingAmountRef}
																{...field}
																variant='outlined'
																label='Amount'
																fullWidth
																autocomplete="off"
																required
																FormHelperTextProps={{
																	style: { marginLeft: 0 }
																}}
																onBlur={()=>{	
																	setValue('billingCycleAmount', (!isNaN(getValues('billingCycleAmount')) ?   (clearErrors('billingCycleAmount', ''), formatPriceFieldOnBlur(getValues('billingCycleAmount'))) : getValues('billingCycleAmount'))  )
																}}
																// onKeyPress={(event) => {
																// 	if (!/[0-9/-]+/.test(event.key)) {
																// 		event.preventDefault();
																// 	}
																// }}
																error={!!errors.billingCycleAmount}
																helperText={errors?.billingCycleAmount?.message}
																onChange={(event) => { field.onChange(event.target.value ?? '')}}
																style={{ width: '100%', backgroundColor: '#fff' }}
																InputProps={{
																	startAdornment:
																		<InputAdornment>
																			<Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
																		</InputAdornment>
																}}
															/>
														</>
													)
												}}
											/>
										</div>
										<div style={{ display: 'flex', marginBottom: '10px', width: '50%' }} className="relative">
											<Controller
												name="billingCycleCurrency"
												className="mb-16"
												control={control}
												render={({ field: { ref, ...field }, fieldState: { error } }) => (
													<>
														<Autocomplete
															{...field}
															style={{ width: '100%' }}
															options={countries}
															disablePortal
															getOptionLabel={option => option.currency ?? ''}
															renderOption={option =>
																<>
																	<InputAdornment style={{ marginRight: '15px' }}>
																		<img
																			loading="lazy"
																			width="20"
																			src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
																			srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
																			alt=""
																		/>
																	</InputAdornment>
																	{option.currency}
																</>}
															onChange={(event, value) => {
																field.onChange(value)
																if (value) handleCurrentCountry(value.code)
															}}
															PaperComponent={({ children }) => (
																<Paper className="autocomplete_holder">{children}</Paper>
															)}
															renderInput={params => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			{...params}
																			label='Currency'
																			variant='outlined'
																			fullWidth
																			// required
																			inputRef={ref}
																			FormHelperTextProps={{
																				style: { marginLeft: 0 }
																			}}
																			style={{ width: '100%', backgroundColor: '#fff' }}
																			InputProps={{
																				startAdornment:
																					<InputAdornment style={{ marginRight: '15px' }}>
																						<img
																							loading="lazy"
																							width="20"
																							src={`https://flagcdn.com/w20/${currentCountryCurrency?.code.toLowerCase()}.png`}
																							srcSet={`https://flagcdn.com/w40/${currentCountryCurrency?.code.toLowerCase()}.png 2x`}
																							alt=""
																						/>
																					</InputAdornment>
																			}}
																		/>
																	</div>
																)
															}}
														/>
													</>
												)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='w-full'>
								<div style={{ display: 'flex', justifyContent: 'center', }}>
									<div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div style={{ marginRight: '10px', marginBottom: '10px', }}>
												<span color="white" variant="contained"
													style={{
														backgroundColor: "#f5f5f5",
														fontSize: '16px',
														color: 'gray',
														borderRadius: '4px'
													}}>
													Every*:
												</span>
											</div>

											<div style={{ marginRight: '10px', marginBottom: '10px', width: '70px' }}>
												<Controller
													name="every"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<TextField
																{...field}
																variant='outlined'
																label=""
																// inputRef={billingEveryRef}
																type='number'
																fullWidth
																min="0"
																// onChange={(e) => {
																// 	let val = parseInt(e.target.value, 10);
																// 	if (isNaN(val)) {
																// 	  setEveryFielVal('');
																// 	} else {
																// 	  // is A Number
																// 	  val = val >= 0 ? val : 0;
																// 	  setEveryFielVal(val);
																// 	}
																//   }}
																// value = {everyFielVal}
																InputLabelProps={{ shrink: true }}
																required
																style={{ width: '100%', backgroundColor: '#fff' }}
																error={!!errors.every}
																helperText={errors?.every?.message}
																// formhelpertextprops={{
																// 	style: { marginLeft: 0 }
																// }}
															/>
														</>
													)}
												/>
											</div>
										</div>
										<div style={{ marginRight: '10px', marginBottom: '10px', width: '500px' }} className="relative">
											<Controller
												name="paymentTerm"
												className="mb-16"
												control={control}
												render={({ field: { ref, ...field }, fieldState: { error } }) => (
													<>
														<Autocomplete
															{...field}
															style={{ width: '100%' }}
															// options={[{id: 1, name: '1 Month'}, {id: 1, name: '3 Months'}, {id: 1, name: '6 Months'}, {id: 1, name: '12 Months'}]}
															options={paymentTermMenu}
															disablePortal
															getOptionLabel={option => option.display_name ?? ''}
															onChange={(event, value) => field.onChange(value)}
															PaperComponent={({ children }) => (
																<Paper className="autocomplete_holder">
																	{children}
																	{createCPaymentTermPermission && <Button
																		style={{ width: '100%' }}
																		variant="outlined"
																		color="primary"
																		onMouseDown={() => setOpenPaymentTermQuickAddDialog(true)}
																	>
																		Add Payment Term
																	</Button>}
																</Paper>
															)}
															renderInput={params => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			required
																			{...params}
																			// inputRef={paymentTermInputRef}
																			name="paymentTerm"
																			label='Payment Term'
																			variant='outlined'
																			fullWidth
																			error={!!error}
																			helperText={error?.message}
																			// inputRef={ref}
																			FormHelperTextProps={{
																				style: {
																					margin: 0,
																					backgroundColor: '#f4f4f4',
																					width: '100%',
																					paddingTop: '2px'
																				}
																			}}
																			style={{ width: '100%', backgroundColor: '#fff' }}
																		/>
																	</div>
																)
															}}
														/>
													</>
												)}
											/>
										</div>
										<div style={{ marginRight: '10px', marginBottom: '10px', width: '100%' }}>
											{/* <Controller
												name="renewal_date"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<TextField
															{...field}
															variant='outlined'
															label="Date"
															type='date'
															inputRef={billing_date_ref}
															InputLabelProps={{ shrink: true }}
															fullWidth
															required
															onChange={(event) => {
																field.onChange(event.target.value)
																setEndDate(event.target.value)
															}}

															FormHelperTextProps={{
																style: { marginLeft: 0 }
															}}
															inputProps={{ min: `${startDate}` }}
															style={{ width: '100%', backgroundColor: '#fff' }}
														/>
													</>
												)}
											/> */}
											{/* <CustomDatePicker
												state={billingDate}
												setState={setBillingDate}
												required={true}	
												label='Date*'										
											/> */}
											<CustomDatePickerV2
												state={billingDate}
												setState={setBillingDate}
												required={true}	
												label='Date*'										
											/>
										</div>
									</div>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>



          {/*---------------Payment Method------------------- */}
					<Accordion expanded={checkPaymentMethod} className={classes.subSection}>
						<AccordionSummary onClick={summeryClickSubPayment}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Payment Method</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', marginBottom: '10px' }}>
								<RadioGroup aria-label="quiz" name="quiz" value={purchaseOrder} onChange={handleRadioChange}>
									<div style={{ display: 'flex' }}>
										<div style={{ marginRight: '20px' }}>
											<FormControlLabel value="CreditCard" control={<Radio color="primary" />} label="Credit Card" />
										</div>
										<div style={{ marginLeft: '20px' }}>
											<FormControlLabel value="purchaseOrder" control={<Radio color="primary" />} label="Purchase Order" />
										</div>
									</div>
								</RadioGroup>
							</div>
							<div>
							{purchaseOrder == 'purchaseOrder' && <div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="poNumber"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='PO Number'
													fullWidth
													required
													// inputRef={poNumberRef}
													inputProps={{ readOnly: false }}
													error={!!errors.poNumber}
													helperText={errors?.poNumber?.message}
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									{/* <Controller
										name="po_date"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label="PO Date"
													type='date'
													inputRef={po_date_ref}
													required
													InputLabelProps={{ shrink: true }}
													onChange={(event) => {
														field.onChange(event.target.value)
														setStartDate(event.target.value)
													}}
													fullWidth
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													inputProps={{ max: `${endDate}` }}
													style={{ width: '100%', backgroundColor: '#fff' }}
												/>
											</>
										)}
									/> */}
									{/* <CustomDatePicker
										state={paymentDate}
										setState={setPaymentDate}
										required={true}	
										label='PO Date*'										
									/> */}
									<CustomDatePickerV2
										state={paymentDate}
										setState={setPaymentDate}
										required={ purchaseOrder === 'purchaseOrder' ? true : false}	
										label='PO Date*'										
									/>
								</div>
							</div>}
							{purchaseOrder == 'CreditCard' && <div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="cardHolderName"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label="Card Holder's Name"
													fullWidth
													required={ purchaseOrder === 'CreditCard' ? true : false}	
													// inputRef={cardHolderNameRef}
													inputProps={{ readOnly: false }}
													style={{ backgroundColor: '#fff' }}
													error={!!errors.cardHolderName}
													helperText={errors?.cardHolderName?.message}
												/>
											</>
										)}
									/>
								</div>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="cardNumber"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													required={ purchaseOrder === 'CreditCard' ? true : false}	
													variant='outlined'
													// inputRef={cardNumberRef}
													value={cardNumber}
													onKeyPress={(event) => {
														if (!/[0-9/-]+/.test(event.key)) {
															event.preventDefault();
														}
													}}
													label="Last 4 Digits of Credit Card"
													onChange={(e)=>{
														if(e.target.value.length > 4){
															return
														}
														setCardNumber(e.target.value)
													}}
													fullWidth
													inputProps={{ readOnly: false }}
													style={{ backgroundColor: '#fff' }}
													error={!!errors.cardHolderName}
													helperText={errors?.cardHolderName?.message}
												/>
											</>
										)}
									/>
								</div>
							</div>}
							</div>
						</AccordionDetails>
					</Accordion>

          {/*---------------Provider---------------------- */}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary
              style={{ pointerEvents: "none" }} /*expandIcon={<ExpandMore />}*/
            >
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Service Provider</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
                className="relative"
              >
                <Controller
                  name="provider"
                  className="mb-16"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        {...field}
                        style={{ width: "100%" }}
                        options={serviceProviderMenu}
                        disablePortal
                        getOptionLabel={(option) =>
                          option.service_provider_name ?? ""
                        }
                        onChange={(event, value) => field.onChange(value)}
                        PaperComponent={({ children }) => (
                          <Paper className="autocomplete_holder">
                            {children}
                            <Button
                              style={{ width: "100%" }}
                              variant="outlined"
                              color="primary"
                              onMouseDown={() =>
                                setOpenServiceProviderQuickAddDialog(true)
                              }
                            >
                              Add Service Provider
                            </Button>
                          </Paper>
                        )}
                        renderInput={(params) => {
                          return (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                {...params}
                                name="provider"
                                label="Service Provider"
                                variant="outlined"
                                fullWidth
                                required
                                inputRef={ref}
                                style={{
                                  width: "100%",
                                  backgroundColor: "#fff",
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/*---------------Reminder---------------------- */}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary
              style={{ pointerEvents: "none" }} /*expandIcon={<ExpandMore />}*/
            >
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Reminder</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "10px",
                }}
              >
                <Typography style={{ fontSize: "17px" }}>
                  Dalos will notify you automatically before contract
                  expiration.
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={noReminderCheckBox}
                  onChange={handleChangeNoReminderCheckbox}
                  disabled={
                    reminderCheckboxes.supportContact === true ||
                    reminderCheckboxes.term30 === true ||
                    reminderCheckboxes.term45 === true
                  }
                />
                <Typography style={{ fontSize: "17px" }}>
                  Do not remind me
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  disabled={noReminderCheckBox === true}
                  name={"term45"}
                  checked={reminderCheckboxes.term45}
                  onChange={handleChangeReminderCheckBoxes}
                />
                <Typography style={{ fontSize: "17px" }}>Remind me before 
                <Controller
										name="no_of_days"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='standard'
													label=''
													fullWidth
													value={noOfDays}
													onChange={(e)=>{
														setNoOfDays(e.target.value)
													}}
													onKeyPress={(event) => {
														if (!/[0-9/-]+/.test(event.key)) {
															event.preventDefault();
														}
													}}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px',
															fontSize: '17px'
														}
													}}
													disabled={!reminderCheckboxes.term45}
													style={{ width: '10%', backgroundColor: '#fff', padding: '0 5px' }}
												/>
											</>
										)}
									/>
								 no of days of contract expiry</Typography>
              </div>


              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  disabled={
                    noReminderCheckBox === true || (reminderCheckboxes.term45 === false && reminderCheckboxes.term30 === false )
                  }
                  name={"supportContact"}
                  checked={reminderCheckboxes.supportContact}
                  onChange={handleChangeTextMeOption}
                />
                <div className="flex items-center" style={{ width: "60%" }}>
                  <Typography
                    style={{
                      fontSize: "17px",
                      // width: "30%",
                    }}
                  >
                    Text Me
                  </Typography>

                  <Checkbox
									disabled={noReminderCheckBox === true || (reminderCheckboxes.term45 === false && reminderCheckboxes.term30 === false )}
									name={'supportContact'}
									checked={reminderCheckboxes.email}
									onChange={handleChangeEmailOption}
								/>
								<Typography style={{ fontSize: '17px' }}>Email Me</Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <div style={{ display: "flex", gap: "20px", paddingTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ borderRadius: "4px" }}
              type="submit"
            >
              Add
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ borderRadius: "4px" }}
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default QuickAddContract;
