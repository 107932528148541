import _ from '@lodash';
import TablePagination from '@fuse/components/general/TablePagination';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import ListView from './listView';
import useRouter from '@fuse/hooks/useRouter';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function EndUsersAccountsTable(props) {
  const classes = useStyles();
	const router = useRouter();

	let serviceRequests;
	
	if(router.query.report_name){
		serviceRequests = props.serviceRequests.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage);
	}else{
		serviceRequests = props.serviceRequests;
	}

	function handleChangePage(event, value) {
		props.setPage(value);
		if(!router.query.report_name){
			props.fetchServiceRequest(props.rowsPerPage, value, props.filters[props.currentFilter], props.currentSearch);
		}
	}

	function handleChangeRowsPerPage(rowsPerPage) {
		props.setPage(0)
		props.setRowsPerPage(rowsPerPage);
		if(!router.query.report_name){
			props.fetchServiceRequest(rowsPerPage, 0, props.filters[props.currentFilter], props.currentSearch);
		}
	}


	if(props.loading){
		return (<FuseLoading/>)
	}

	if (!props.viewServiceRequestPermission) {
		return (
			<div>
				<Card>
					<CardContent>You don't have permission to view any Service Requests at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		)
	}

	// if(serviceRequests.length <= 0){
	// 	return (
	// 		<motion.div
	// 			initial={{ opacity: 0 }}
	// 			animate={{ opacity: 1, transition: { delay: 0.1 } }}
	// 			className="flex flex-1 items-center justify-center h-full"
	// 		>
	// 			<div style={{height: "200px"}}>
	// 				<Typography variant="body2" component="p" color="textSecondary">
	// 					There are currently no service request available to view. Please check back later.
	// 				</Typography>
	// 			</div>
	// 		</motion.div>
	// 	)
	// }

	return (
		<div className="w-full flex flex-col">
			<SwitchView 
				serviceRequests={serviceRequests} 
				viewServiceRequestPermission = {props.viewServiceRequestPermission}
				createServiceRequestPermission = {props.createServiceRequestPermission}
				updateServiceRequestPermission = {props.updateServiceRequestPermission}
				deleteServiceRequestPermission = {props.deleteServiceRequestPermission}
				fetchServiceReq={props.fetchServiceReq}
			/>
			{/* <div className = "p-10">
				<TablePagination
					className="flex-shrink-0 border-t-1"
					component="div"
					count={props.totalRecords}
					rowsPerPage={'all'}
					page={props.tableName === 'manuallyAddedServiceRequest' ? props.setPage : props.page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div> */}
		</div>
	);

}

function SwitchView({
	serviceRequests, viewServiceRequestPermission, createServiceRequestPermission,
	updateServiceRequestPermission, deleteServiceRequestPermission, fetchServiceReq
}) {
	return(
		<ListView 
			serviceRequests={serviceRequests} 
			viewServiceRequestPermission = {viewServiceRequestPermission}
			createServiceRequestPermission = {createServiceRequestPermission}
			updateServiceRequestPermission = {updateServiceRequestPermission}
			deleteServiceRequestPermission = {deleteServiceRequestPermission}
			fetchServiceReq = { fetchServiceReq }
		/>
	)
}

export default withRouter(EndUsersAccountsTable)
