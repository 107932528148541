import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import message from './messageSlice';
import navbar from './navbarSlice';
import navigation from './navigationSlice';
import settings from './settingsSlice';
import pageTitle from './pageTitleSlice';
import platformModules from './platformModuleSlice';
import pusher from './pusherSlice';
import notifications from './notificationSlice';

const fuseReducers = combineReducers({
	navigation,
	settings,
	navbar,
	message,
	dialog,
	platformModules,
	pageTitle,
	pusher,
	notifications,
});

export default fuseReducers;
