import React,{useEffect,useRef} from 'react'

function RenderHtml(props) {
  const root = useRef(null)
  const rootIframe = useRef(null)
  useEffect(()=>{
    if(props.iframe && rootIframe.current){
      rootIframe.current.srcdoc = props.html
      const iframe = rootIframe.current;
      iframe.onload = () => {
        const iframeDocument = iframe.contentDocument;
  
        if (iframeDocument) {
          const head = iframeDocument.head;
          const style = document.createElement('style');
          style.innerHTML = `
            body {
              font-family: Arial, sans-serif; /* Set your desired font family here */
            }
          `;
          head.appendChild(style);
        }
      }
    }
    else if(root.current){
      root.current.innerHTML = props.html
    }
  },[props.html])
    
  return props.iframe?<iframe style={{width: '100%', height: '100%', fontFamily:'serif'}} ref={rootIframe}></iframe>: <div ref={root}></div>
       
}

export default RenderHtml