import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
	name: 'notifications',
	initialState: {
        total: 0,
        unread: 0,
        notifications: [],
		assetNotificationCount: 0,
		contractNotificationCount: 0,
	},
	reducers: {
		setNotifications: (state, action) => {
            state.notifications = action.payload.notifications;
            state.total = action.payload.total;
            state.unread = action.payload.unread;
		},
		setAssetNotification: (state, action) => {
			state.assetNotificationCount = action.payload.assetNotificationCount
		},
		setContractNotification: (state, action) => {
			state.contractNotificationCount = action.payload.contractNotificationCount
		}
	}
});

export const { setNotifications, setAssetNotification, setContractNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
