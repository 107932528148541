import { React, useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';

export default function Iot() {
  const [iframeSrc, setIframeSrc] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const authenticateAndLoadIframe = () => {
      const tb_token = window.localStorage.getItem('tb_token')
      if(tb_token && tb_token !== 'undefined'){
        const thingsBoardUrl = `${process.env.REACT_APP_THINGSBOARD_URL}/home?accessToken=${tb_token}`;
        
        setIframeSrc(thingsBoardUrl);
      }
      setIsLoading(false)
  };

  useEffect(() => {
    authenticateAndLoadIframe()
  },[])

    return (
      <>
        <div className='custom_tab'>
          {isLoading && <FuseLoading />}
          {iframeSrc && !isLoading &&
          <iframe
            src={iframeSrc}
            width="100%"
            height="800"
            style={{ border: 'none' }}
            title="ThingsBoard Dashboard"
          ></iframe>
          }
      </div>
      </>
    );
}
