import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { Button, Icon } from '@material-ui/core';
import { openCsvDialog } from "../store/csvDialogReducer"
import { useDispatch } from 'react-redux';
import {Menu,MenuItem} from '@material-ui/core';
import Media from 'react-media';
import IconButton from '@material-ui/core/IconButton';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { ReactComponent as GridView } from '../icons/grid_view.svg';
import { ReactComponent as ListView } from '../icons/list_view.svg';
import { ReactComponent as UploadIcon } from '../icons/upload.svg';
import SvgIcon from "@material-ui/core/SvgIcon";
import useToast from '@fuse/hooks/useToast';
import { usePusher } from '@fuse/hooks';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import SaveReportDialog from './saveReportDialog';
import { openReportViewerDialog } from '../store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}


function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [invoices, setIncidents] = useState([]);
	const {broadcastChannel} = usePusher()
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const new_incident_event = "new_incident_event"
	
	// const { hasPermission } = usePermission()

	// const viewManufacturersPermission = hasPermission('view-manufacturers')
	// const createManufacturersPermission = hasPermission('create-manufacturers')
	// const updateManufacturersPermission = hasPermission('update-manufacturers')

	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			// getImportList()
		}
		setSelectedTab(value);
	};
	
	function gotoDetailsPage(id){
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);
	

	function refreshList(){
	}

	function callToast(success,error,value){
        if(success){
            toast.success(value)
        }
        if(error){
            toast.error(value);
        }
    }

	if(loading){
		return(<FuseLoading />)
	}else{
		return (<>
				<SaveReportDialog callToast = { callToast } />

				<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				// header={
				// 	<DynamicFieldsHeader />
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
						{
							loading && <FuseLoading />
						}
						{
							!loading && <SalesOrderTable 
							//endUsersAccounts={invoices}
							selectedView={selectedView}
							//gotoDetailsPage={gotoDetailsPage}
							//refresh={refreshList}
						/>
						}
						</div>
					</>
				}
				innerScroll
			/>
			
		</>)
	}
}

export default withReducer('incidentApp', reducer)(CardedFullWidth2TabbedSample);
