import { React, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import axios from '@fuse/utils/axios';
import useToast from '@fuse/hooks/useToast';
import { useDispatch } from 'react-redux';
import { getAbbreviatedCapitalized } from '@fuse/utils/general';

export default function AssetActionComponent({ data, locationMenu, categoryMenu, fetchNotificationSettings, isPhoneNumberVerified, setOpenPhoneVerificationModal }) {
  const dispatch = useDispatch()
  const toast = useToast(dispatch);
  const [openEditNotification, setOpenEditNotification] = useState(false);
  const [openDeleteNotification, setOpenDeleteNotification] = useState(false);
  const [location, setLocation] = useState(null);
  const [category, setCategory] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [status, setStatus] = useState('');
  const [notification, setNotification] = useState('');
  const [stats, setStats] = useState('Enabled');

  const handleClickOpenEditNotification = () => {
    setOpenEditNotification(true);
  };

  const handleCloseOpenEditNotification = () => {
    setOpenEditNotification(false);
    setLocation(null);
    setCategory(null);
    setItemId(null);
    setStatus('');
    setNotification('');
    setStats('Enabled');
  };

  const handleEditNotification = () => {
    if( location == null || category == null || !status || !notification) return
    axios.put(`notifications/asset-notifications-settings/edit/${itemId}`,{
      location_id: location === 0 ? null : location,
      category_id: category === 0 ? null : category,
      network_status: status,
      get_notification_via: notification,
      keep_notification: stats.toLowerCase()
    }).then(response => {
      fetchNotificationSettings()
      handleCloseOpenEditNotification()
      toast.success(response.data.message)
		}).catch(err => {
			console.log(err);

		})
  }

  const handleDeleteNotification =(_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    if(!itemId) return
    axios.delete(`notifications/asset-notifications-settings/delete/${itemId}`).then((res)=>{
      fetchNotificationSettings()
      handleCloseOpenDeleteNotification()
      toast.success(res.data.message)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleClickOpenDeleteNotification = () => {
    setOpenDeleteNotification(true);
  };

  const handleCloseOpenDeleteNotification =(_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenDeleteNotification(false);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleNotificationChange = (event) => {
    if((event.target.value === 'both' || event.target.value === 'sms') && !isPhoneNumberVerified){
      setOpenPhoneVerificationModal(true)
      return
    }
    setNotification(event.target.value);
  };

  const handleStatsChange = (event) => {
    setStats(event.target.value);
  };

  const handleEditRow = () => {
    if(!Boolean(data)) return
    setLocation(data.location_id)
    setCategory(data.category_id)
    setStatus(data.status)
    setNotification(data.get_notified_via.toLowerCase())
    setStats(getAbbreviatedCapitalized(data.keep_notification))
    setItemId(data.id)
  }

  const setItemIdForDelete = () => {
    if(!Boolean(data)) return
    setItemId(data.id)
  }
  return (
    <>
      <Button className="transparent_icon_btn" onClick={handleClickOpenEditNotification}>
        <i 
          style={{fontSize: '20px', fontWeight: '500', color: '#1D2939'}}
          onClick={handleEditRow}
          className="ti ti-pencil mr-12"
        />
      </Button>
      <Button className="transparent_icon_btn" onClick={handleClickOpenDeleteNotification}>
        <i
          style={{fontSize: '20px', fontWeight: '500', color: '#F04438'}}
          onClick={setItemIdForDelete}
          className="ti ti-trash"
        />
      </Button>

      {/* edit notification modal */}
      <Dialog
        className="cstm_dialog_modal medium"
        open={openEditNotification}
        // onClose={handleCloseOpenEditNotification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flexbox_holder items-center w-full'>
            <h2 className="main_heading">Edit Notification</h2>
            <Button className='transparent_icon_btn' onClick={handleCloseOpenEditNotification}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='flexbox_holder items-center w-full'>
            <div style={{background: '#F9FAFB', borderRadius: '12px', marginBottom: '16px', padding: '16px 20px'}} className='flexbox_holder justify-start items-center w-full'>            
              <Box className='each_filter_holder pr-10'>
                <InputLabel className='label_holder'>Notify me when an asset at</InputLabel>
                <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                  <Select
                    style={{minWidth: '100px', maxWidth: '160px'}}
                    labelId="location-label"
                    id="location"
                    value={location}
                    label=""
                    onChange={handleLocationChange}
                    MenuProps={{
                      sx: {
                        maxHeight: 230,
                        '& .MuiMenuItem-root': {
                          whiteSpace: 'normal',
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    {locationMenu && locationMenu.map( loc => <MenuItem value={loc.id}>{loc.label}</MenuItem>)}
                  </Select>
                </FormControl>
              </Box>

              <Box className='each_filter_holder'>
                <InputLabel className='label_holder'>in</InputLabel>
                <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                  <Select
                    style={{minWidth: '100px', maxWidth: '160px'}}
                    labelId="category-label"
                    id="category"
                    value={category}
                    label=""
                    onChange={handleCategoryChange}
                    MenuProps={{
                      sx: {
                        maxHeight: 230,
                        '& .MuiMenuItem-root': {
                          whiteSpace: 'normal',
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    {categoryMenu && categoryMenu.map( category => <MenuItem value={category.id}>{category.label}</MenuItem>)}
                  </Select>
                </FormControl>
              </Box>

              <Box className='each_filter_holder pr-10'>
                <InputLabel className='label_holder'>has the following network status</InputLabel>
                <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                  <Select
                    style={{minWidth: '160px', maxWidth: '160px'}}
                    labelId="status-label"
                    id="status"
                    value={status}
                    label=""
                    onChange={handleStatusChange}
                    MenuProps={{
                      sx: {
                        maxHeight: 230,
                        '& .MuiMenuItem-root': {
                          whiteSpace: 'normal',
                        },
                      },
                    }}
                  >
                    <MenuItem value="both">Both</MenuItem>
                    <MenuItem value="online">Online</MenuItem>
                    <MenuItem value="offline">Offline</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box className='each_filter_holder justify-end'>
                <InputLabel className='label_holder'>Send my notification via</InputLabel>
                <FormControl fullWidth style={{maxWidth: '144px'}} className='cstm_select_formcontrol min_width_auto'>
                  <Select
                    style={{minWidth: '144px', maxWidth: '145px'}}
                    labelId="notification-label"
                    id="notification"
                    value={notification}
                    label=""
                    onChange={handleNotificationChange}
                    MenuProps={{
                      sx: {
                        maxHeight: 230,
                        '& .MuiMenuItem-root': {
                          whiteSpace: 'normal',
                        },
                      },
                    }}
                  >
                    <MenuItem value="both">Both</MenuItem>
                    <MenuItem value="sms">SMS</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            <Box className='each_filter_holder w-full'>
              <InputLabel className='label_holder'>Keep the notification</InputLabel>
              <FormControl fullWidth style={{maxWidth: '160px'}} className='cstm_select_formcontrol'>
                <Select
                  style={{maxWidth: '105px'}}
                  className="rounded_select_holder"
                  labelId="stats-label"
                  id="stats"
                  value={stats}
                  label=""
                  onChange={handleStatsChange}
                  renderValue={(selected) => (
                    <div className="flex items-center">
                      <span className={`dot_status ${selected.toLowerCase()}`} />
                      <span className="pl-4">{selected}</span>
                    </div>
                  )}
                  MenuProps={{
                    sx: {
                      maxHeight: 230,
                      '& .MuiMenuItem-root': {
                        whiteSpace: 'normal',
                      },
                    },
                  }}
                >
                  <MenuItem value="Enabled">
                    <div className="flex items-center">
                      <span className="dot_status enabled" />
                      <span className="pl-4">Enabled</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="Disabled">
                    <div className="flex items-center">
                      <span className="dot_status disabled" />
                      <span className="pl-4">Disabled</span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={handleCloseOpenEditNotification} className="button secondary_btn">Cancel</Button>
          <Button onClick={handleEditNotification} className="button primary_btn">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* delete notification modal */}
      <Dialog
        className="cstm_dialog_modal small text-center"
        open={openDeleteNotification}
        onClose={handleCloseOpenDeleteNotification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flex justify-end items-center w-full'>
            <Button className='transparent_icon_btn' onClick={handleCloseOpenDeleteNotification}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="warning_content_holder">
            <div className="exclamation_icon_holder red">
              <i className="ti ti-exclamation-mark" />
            </div>
            <h2 className="heading">Are you sure to delete the selected notification</h2>
            <p className="sub_content">By deleting notification you will lose all of your notification data and won't be able to restore them.</p>
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={handleCloseOpenDeleteNotification} className="secondary_btn">No, go back!</Button>
          <Button onClick={handleDeleteNotification} className="primary_btn">
            Yes, I'm sure!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}