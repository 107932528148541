import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from '@fuse/hooks';

const useStyles = makeStyles({
    layoutRoot: {},
    head: {
        position: "sticky",
        top: 0
    },
    root: {
        // minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    row: {
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom: "25px",
        display: 'flex'
    },
    column: {
        // border: '2px solid black',
        width: '40%',
        textAlign: 'left'
    }
});

const AssetTab = ({assetDetails}) => {
    const classes = useStyles();
    const router = useRouter();

    function redirectToAssetProfile(){
        router.push('/assets-list');
    }

    return (
        <>
            <div className='flex flex-col text-center overflow-auto w-full' style={{ height: 290 }}>
                <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{ fontWeight: 600, color: "rgb(132, 141, 158)" }} variant="subtitle1">Serial Number</Typography>
                    <a style={{ fontWeight: 400,color: 'black', fontSize: '1.4857142857142858rem',cursor: 'pointer'}} onClick={redirectToAssetProfile} variant="subtitle1">{assetDetails?.asset_serial_number}</a>
                </div>
                <div className={classes.column}>
                    <Typography style={{ fontWeight: 600, color: "rgb(132, 141, 158)" }} variant="subtitle1">Asset Name</Typography>
                    <Typography style={{ fontWeight: 400 }} variant="subtitle1">{assetDetails?.asset_name}</Typography>
                </div>
                </div>
                <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{ fontWeight: 600, color: "rgb(132, 141, 158)" }} variant="subtitle1">Asset Tag</Typography>
                    <Typography style={{ fontWeight: 400 }} variant="subtitle1">{assetDetails?.asset_tag}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{ fontWeight: 600, color: "rgb(132, 141, 158)" }} variant="subtitle1">Model Number</Typography>
                    <Typography style={{ fontWeight: 400 }} variant="subtitle1">{assetDetails?.model_number}</Typography>
                </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Manufacturer Name</Typography>
                        <Typography style={{fontWeight:400}} variant="subtitle1">{assetDetails?.manufacturer_name}</Typography>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssetTab
