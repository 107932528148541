import React, { useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
// import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import ColorPicker from '@fuse/components/ColorPicker'
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import { countries } from '@fuse/utils/countryName'
import Autocomplete from '@mui/material/Autocomplete';
import { generateRandomNumberString } from '@fuse/utils/stringOperations'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


const AddServiceProviderFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [formattedNumber, setFormattedNumber] = useState('')

    const defaultValues = {
        url: '',
        serviceProvider: '',
		phone_number: '',
		address1: '',
		city: '',
		state: '',
		zip: '',
		country: null,
    }

    const schema = yup.object({
        url: yup.string().trim().required('URL is required!'),
        serviceProvider: yup.string().trim().required('Service Provider is required!'),
        phoneNumber: yup.string().nullable(),
        address1: yup.string().nullable(),
        city: yup.string().nullable(),
        state: yup.string().nullable(),
        zip: yup.string().nullable(),
        country: yup.object().shape({
            name: yup.string(),
            code: yup.string(),
            currency: yup.string()
        }).nullable()
    })

    function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

    const { 
        control, 
        formState:{errors: serviceProviderFormErrors}, 
        handleSubmit: serviceProviderFormSubmit, 
        trigger,
        setValue,
        getValues,
        clearErrors,
        reset: serviceProviderFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    const errorData = (error) => {
		console.log('363=>',error)
	}


    const onSubmit = async (data) => {
        // await trigger();
        // if(Object.keys(statusFormErrors).length !== 0) { 
        //     return;
        // }
        if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
        if(data.url.indexOf('.') == -1){
            toast.error('Please enter a valid URL')
            return
        }
        console.log('dfghjk')
        const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			address1: data.address1,
			serviceProvider: data.serviceProvider,
			phone_number: formattedNumber || '',
			city: data.city,
			state: data.state,
			country: data.country?.name,
			zip: data.zip,
			url: `www.${data.url}`
		}
		console.log(payload)
		axios.post('/service-provider/add', payload).then(res => {
			if(res.status === 201){
				toast.error(res.data.message)
			}else{
				
				toast.success('Service Provider added successfully')
                newDataAddedEvent(res.data.data)
			}
            setFormattedNumber('')
			
		}).catch(err => {
			console.log('504=>',err)
			toast.error('Something went wrong!')
		})

    }


    return (
        <>
            <Dialog
                className="cstm_dialog_modal small text-center"
                open={openAddStatusModalStateData}
                // onClose={handleOpenCloseAddModalEvent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='heading_holder flexbox_holder items-center w-full'>
                        <h2 className="main_heading">Add Service Provider</h2>
                        <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                            <i className="icon ti ti-x" />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-left">
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Service Provider
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='serviceProvider' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='Service Provider Name' 
                                        fullWidth
                                        required
                                        error={!!serviceProviderFormErrors.serviceProvider}
                                        helperText={serviceProviderFormErrors?.serviceProvider?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{background: 'white'}}
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                URL
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='url' 
                                control={control}
                                render={( { field} ) => (
                                <>
                                    <TextField 
                                        {...field}
                                        InputProps={{
                                            startAdornment: 
                                            <InputAdornment style={{color: "#1D2939",fontSize: "14px",marginRight:"0"}} disableTypography position="start">
                                                www.</InputAdornment>,
                                        }}
                                        variant='outlined'
                                        // label='URL' 
                                        fullWidth
                                        required
                                        error={!!serviceProviderFormErrors.url}
                                        helperText={serviceProviderFormErrors?.url?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{background: 'white'}}                               
                                    />
                                </>
                                ) }
                            />
                        </div>



                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Phone Number
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Controller
                                name='phoneNumber' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        value={formattedNumber}
                                        // style={{background: 'white'}}
                                        onKeyPress={(event) => {
                                            if (!/[0-9/-]+/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e)=>{
											changeNumberFormat(e)
										}}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px'
                                            }
                                        }}
                                        onPaste={(e)=>{e.preventDefault()}}
                                        variant='outlined'
                                        // label='Phone Number' 
                                        fullWidth
                                        // error={!!serviceProviderFormErrors.phoneNumber}
                                        // helperText={serviceProviderFormErrors?.phoneNumber?.message}
                                        style={{ backgroundColor: '#fff' }}
                                    />
                                )}
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Address 1
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Controller
                                name='address1' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='Address Line 1' 
                                        fullWidth 
                                        style={{background: 'white'}}
                                    />
                                ) }
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                City
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Controller
                                name='city' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='City' 
                                        fullWidth
                                        style={{background: 'white'}}
                                    />
                                )}
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                State/Province
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Controller
                                name='state' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='State/Province' 
                                        style={{background: 'white'}}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Zip
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Controller
                                name='zip' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        // label='Zip' 
                                        style={{background: 'white'}}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Country
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Controller
                                name="country"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={countries}
                                            // disablePortal
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
                                            )}
                                            getOptionLabel={option => option.name ?? ''}
                                            renderInput={(params) => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            style={{background: 'white'}}
                                                            {...params}
                                                            // label="Country"
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="action_footer_holder">
                    <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                    <Button onClick={serviceProviderFormSubmit(onSubmit, errorData)} className="primary_btn">
                        Save and Select
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}


export default AddServiceProviderFromQuickAction;