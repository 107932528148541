import Page from './index';

export default {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/module-setting',
			exact: true,
			component: Page.Listing
		},
		{
			path: '/module-setting/reorder/:id',
			exact: true,
			component: Page.ReOrder
		}
	]
};
