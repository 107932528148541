import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import axios from "@fuse/utils/axios";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import {setModules} from "../../store/moduleReducer"

// import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';

function ModulesTable(){

	const dispatch = useDispatch();
	const toast = useToast(dispatch);

	const moduleState = useSelector(({modulesApp}) => modulesApp.moduleReducer);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	function handleChangePage(event, newPage){
		setPage(newPage);
	}

	function getModuleList() {
		return axios.get("/dynamic-attributes/fetch-all")
        .then((res) => {
            const { data } = res.data;
			dispatch(setModules(data))
		})

	}

	function handleChangeRowsPerPage(event){
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	}

	useEffect(()=> {
		getModuleList()
	}, [])

	return (
		<>
			<div className="flex flex-col min-h-full sm:border-1 sm:rounded-16 overflow-hidden">
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Icon</TableCell>
									<TableCell>Module Name</TableCell>
									<TableCell align="right">Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{moduleState.modules.map((moduleInfo) => (
								<TableRow key={moduleInfo.id}>
									<TableCell component="th" scope="row">
										{moduleInfo.id}
									</TableCell>
									<TableCell component="th" scope="row">
										{moduleInfo.label}
									</TableCell>
									<TableCell component="th" scope="row" align="right">
										<Button variant="contained" color="primary" component={Link} to={`/module-setting/reorder/${moduleInfo.id}`}>Re-Order Table Header</Button>
									</TableCell>
								</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						classes={{
							root: 'flex-shrink-0 border-t-1'
						}}
						rowsPerPageOptions={[3, 5, 10, 25, { label: 'All', value: 4 }]}
						colSpan={3}
						count={1}
						rowsPerPage={rowsPerPage}
						page={page}
						SelectProps={{
							inputProps: { 'aria-label': 'rows per page' },
							native: false
						}}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
			</div>
		</>
	);
};

export default ModulesTable;
