import { React, useState, useEffect, useRef } from 'react';
import axios from '@fuse/utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material'
import { IconBellRinging } from '@tabler/icons-react';
import DashboardCard from './dashboardCard';
import CardContent from '@material-ui/core/CardContent';
import { setAssetNotification, setContractNotification } from 'app/store/fuse/notificationSlice';
import AssetsDashboardHeader from '../header'
import Card from '@material-ui/core/Card';
import MapView from '../mapView';
import DashboardChart from './dashboardChart'
import DashboardChart2 from './dashboardChart2'
import AddDashboardAlert from '../addAlert';

export default function Assets() {
const [moduleName, setModuleName] = useState('asset');
const limit = 100, page = useRef(0);
const dispatch = useDispatch();
const [open, setOpen] = useState(false)
const [notifications, setNotifications] = useState([])
const [loading, setLoading] = useState(false);

const handleOpenDialog = () => {
  setOpen(true)
  getNotifications(moduleName)
}

const close = () => {
  setNotifications([])
  setOpen(false)
  page.current = 0
}

const {
  assetNotificationCount
} = useSelector(({ fuse }) => fuse.notifications);

function flattenNotifications( notifications ){
  if(moduleName !== 'asset') return notifications;
  return notifications.map((notification) => {
      return {
          ...notification,
          title: (()=>{
              const splitTitle = notification.title.split("since")
              const date = new Date(notification.notification_at)
              const options = { 
                  month: 'short', 
                  day: 'numeric', 
                  year: 'numeric', 
                  hour: 'numeric', 
                  minute: 'numeric', 
                  hour12: true 
              };
              return `${splitTitle[0]} since ${date.toLocaleString('en-US', options)}`
              // const splitTitle = notification.title.split(" ")
              // const dateTime = new Date(`${splitTitle[splitTitle.length - 2]} ${splitTitle[splitTitle.length - 1].slice(0, -1)}`);
              // const localDateTime = formatDateTo_MM_DD_YYYY_HH_MM_SS(dateTime.toUTCString())
              // return `${splitTitle.slice(0,-2).join(' ')} ${localDateTime}.`
          })()
      }
  })
}

function getNotifications(moduleName, refreshBtnClicked=false) {
  if (!moduleName) return
  axios.get(`/notifications?type=${moduleName}&limit=${limit}&page=${page.current}`).then((res) => {
    const { notifications: notification } = res.data.data;
    if(!refreshBtnClicked) setNotifications([...notifications, ...flattenNotifications(notification)])
    else setNotifications([...flattenNotifications(notification)])
    page.current++;
  }).catch((err) => {
    console.log(err)
  }).finally(() => setLoading(false))
}


function onClickRefreshBtn(){
  page.current = 0;
  getNotifications(moduleName, true)
}

function markAllAsRead() {
  axios.put(`/notifications/update-read-status-of-all-notifications?type=${moduleName}`).then((res) => {
    if (moduleName === 'contract') {
      dispatch(setContractNotification({
        contractNotificationCount: 0
      }))
    }
    close()
  }).catch((err) => {
    console.log(err)
  }).finally(() => setLoading(false))
}

function updateReadStatusOnClickNotification(notificationId) {
  if (!Boolean(notificationId)) return
  axios.put(`/notifications/update-read-status/${notificationId}`).then((res) => {
    refreshNotifications()
    getNotifications(moduleName)
  }).catch((err) => {
    console.log(err)
  })
}

const refreshNotifications = () => {
  axios.get("/notifications/count")
    .then((res) => {
      let assetCount = 0;
      let contractCount = 0;
      const { totalNotificationsCount } = res.data.data;
      const unreadNotificationsCount = totalNotificationsCount.reduce((res, curr) => {
        if (curr.type === "contract") {
          contractCount = curr.count;
        }
        if (curr.type === "asset") {
          assetCount = curr.count;
        }
        res[curr.type] = curr.count
        return res;
      }, {});
      dispatch(setAssetNotification({
        assetNotificationCount: assetCount
      }))
      dispatch(setContractNotification({
        contractNotificationCount: contractCount
      }))
    }).catch((err) => console.log(err));
};

useEffect(()=>{
  refreshNotifications()
},[])

  return (
    <>
      <div className='custom_tab'>
					<AssetsDashboardHeader />
					<div>
						<div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px', gap: '12px' }}>
							<DashboardCard IconBackgroundColor="#e62e00" />
							<div className='dashboard_alertcard dashboard_card' onClick={() => handleOpenDialog()}>
								<Card className='alert_card'>
									<CardContent style={{ height: '100%', padding: '27px' }}>
										<div>
											<span className='dashboardPattern_img'>
												<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
													<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
													<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
													<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
													<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
												</svg>
											</span>
											<Box sx={{ alignContent: 'center', display: 'flex' }}>
												<span className='alert_icon icon_holder'>
													<IconBellRinging color='#000' size={18} />
												</span>
											</Box>
											<div className='alert_detailstext'>
												<p>Alerts</p>
												<h5 style={{ display: 'flex' }}>{assetNotificationCount}
													<span style={{ marginTop: '5px' }}>
														<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
															<g clipPath="url(#clip0_2359_14699)">
																<path d="M6.7998 12.4546L10.7998 8.45459L6.7998 4.45459" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
															</g>
															<defs>
																<clipPath id="clip0_2359_14699">
																	<rect width="16" height="16" fill="white" transform="matrix(0 -1 1 0 0.799805 16.4546)" />
																</clipPath>
															</defs>
														</svg>
													</span>
												</h5>
											</div>
										</div>
									</CardContent>
								</Card>
							</div>
						</div>
						<Box sx={{ marginTop: '15px' }} className='map_outer_holder relative'>
							{/* <Card>
								<CardContent> */}
							<MapView />
							{/* </CardContent>
							</Card> */}
						</Box>
						<Box sx={{ marginTop: '8px' }}>
							<DashboardChart />
						</Box>
						<Box sx={{ marginTop: '8px', marginBottom: '10px' }}>
            <DashboardChart2 />
						</Box>
					</div>
				</div>

        <AddDashboardAlert
            open={open}
            close={close}
            moduleName={moduleName}
            notifications={notifications}
            loading={loading}
            markAllAsRead={markAllAsRead}
            getNotifications={() => getNotifications(moduleName)}
            updateReadStatusOnClickNotification={updateReadStatusOnClickNotification}
            onClickRefreshBtn={onClickRefreshBtn}
          />
    </>
  );
}
