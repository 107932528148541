import React, {useEffect,useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    row:{
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom:"25px",
        display: 'flex'
    },
    column:{
        // border: '2px solid black',
        width: '100%',
        textAlign: 'left'
    }
});

import { withRouter } from 'react-router-dom';
import { formatDate } from '@fullcalendar/core';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

  

function PaymentTab({billingCycleData, paymentMethodData}) {
    const classes = useStyles();
    const [statusData,setStatusData] = useState([])

    useEffect(()=>{
        
    },[])


    return (
        <div style={{height: 369, width: '100%',textAlign:'center',display: 'flex',flexDirection: 'column'}}>

            
            <div className={classes.row}>

            <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Amount</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">{`$ ${billingCycleData?.amount ?? 0}`}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">PO Date</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">{paymentMethodData?.po_date ? dateReformatTo_mmm_dd_yyyy(paymentMethodData?.po_date) : 'N/A'}</Typography>
                </div>
             

            </div>
             <div className={classes.row}>

            <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Payment Term</Typography>
                    <Typography style={{fontWeight:400,fontSize:"17px"}} variant="subtitle1">{billingCycleData?.payment_term?.display_name}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">PO Number</Typography>
                    <Typography style={{fontWeight:400,fontSize:"17px"}} variant="subtitle1">{paymentMethodData?.po_number ?? 'N/A'}</Typography>
                </div>
            </div>

        </div>
    )
}

export default withRouter(PaymentTab)
