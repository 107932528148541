import { React, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";

export default function ActionComponent() {
  const [openEditNotification, setOpenEditNotification] = useState(false);
  const [openDeleteNotification, setOpenDeleteNotification] = useState(false);
  const [location, setLocation] = useState('default');
  const [category, setCategory] = useState('default');
  const [status, setStatus] = useState('default');
  const [notification, setNotification] = useState('default');
  const [stats, setStats] = useState('Enabled');

  const handleClickOpenEditNotification = () => {
    setOpenEditNotification(true);
  };

  const handleCloseOpenEditNotification = () => {
    setOpenEditNotification(false);
  };

  const handleClickOpenDeleteNotification = () => {
    setOpenDeleteNotification(true);
  };

  const handleCloseOpenDeleteNotification = () => {
    setOpenDeleteNotification(false);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleNotificationChange = (event) => {
    setNotification(event.target.value);
  };

  const handleStatsChange = (event) => {
    setStats(event.target.value);
  };

  return (
    <>
      <Button className="transparent_icon_btn" onClick={handleClickOpenEditNotification}>
        <i style={{fontSize: '20px', fontWeight: '500', color: '#1D2939'}} className="ti ti-pencil mr-12" />
      </Button>
      <Button className="transparent_icon_btn" onClick={handleClickOpenDeleteNotification}>
        <i style={{fontSize: '20px', fontWeight: '500', color: '#F04438'}} className="ti ti-trash" />
      </Button>

      {/* edit notification modal */}
      <Dialog
        className="cstm_dialog_modal medium"
        open={openEditNotification}
        onClose={handleCloseOpenEditNotification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flexbox_holder items-center w-full'>
            <h2 className="main_heading">Edit Notification</h2>
            <Button className='transparent_icon_btn' onClick={handleCloseOpenEditNotification}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='flexbox_holder items-center w-full'>
            <Box className='each_filter_holder pr-10'>
              <InputLabel className='label_holder'>Notify me when an asset at</InputLabel>
              <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                <Select
                  style={{maxWidth: '160px'}}
                  labelId="location-label"
                  id="location"
                  value={location}
                  label=""
                  onChange={handleLocationChange}
                >
                  <MenuItem disabled value="default">
                    <span>Location</span>
                  </MenuItem>
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>New York</MenuItem>
                  <MenuItem value={3}>New Jersey</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box className='each_filter_holder'>
              <InputLabel className='label_holder'>in</InputLabel>
              <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                <Select
                  style={{maxWidth: '160px'}}
                  labelId="category-label"
                  id="category"
                  value={category}
                  label=""
                  onChange={handleCategoryChange}
                >
                  <MenuItem disabled value="default">
                    <span>Category</span>
                  </MenuItem>
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="desktop">Desktop</MenuItem>
                  <MenuItem value="swtiches">Swtiches</MenuItem>
                  <MenuItem value="sensors">Water Sensors</MenuItem>
                  <MenuItem value="pump">Infusion Pump</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box className='each_filter_holder pr-10'>
              <InputLabel className='label_holder'>has the following network status</InputLabel>
              <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                <Select
                  style={{maxWidth: '160px'}}
                  labelId="status-label"
                  id="status"
                  value={status}
                  label=""
                  onChange={handleStatusChange}
                >
                  <MenuItem disabled value="default">
                    <span>Status</span>
                  </MenuItem>
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="offline">Offline</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box className='each_filter_holder justify-end'>
              <InputLabel className='label_holder'>Send my notification via</InputLabel>
              <FormControl fullWidth style={{maxWidth: '160px'}} className='cstm_select_formcontrol min_width_auto'>
                <Select
                  style={{maxWidth: '160px'}}
                  labelId="notification-label"
                  id="notification"
                  value={notification}
                  label=""
                  onChange={handleNotificationChange}
                >
                  <MenuItem disabled value="default">
                    <span>Notification</span>
                  </MenuItem>
                  <MenuItem value="sms">SMS</MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box className='each_filter_holder w-full'>
              <InputLabel className='label_holder'>Keep the notification</InputLabel>
              <FormControl fullWidth style={{maxWidth: '160px'}} className='cstm_select_formcontrol'>
                <Select
                  style={{maxWidth: '105px'}}
                  className="rounded_select_holder"
                  labelId="stats-label"
                  id="stats"
                  value={stats}
                  label=""
                  onChange={handleStatsChange}
                  renderValue={(selected) => (
                    <div className="flex items-center">
                      <span className={`dot_status ${selected.toLowerCase()}`} />
                      <span className="pl-4">{selected}</span>
                    </div>
                  )}
                >
                  <MenuItem value="Enabled">
                    <div className="flex items-center">
                      <span className="dot_status enabled" />
                      <span className="pl-4">Enabled</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="Disabled">
                    <div className="flex items-center">
                      <span className="dot_status disabled" />
                      <span className="pl-4">Disabled</span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={handleCloseOpenEditNotification} className="button secondary_btn">Cancel</Button>
          <Button onClick={handleCloseOpenEditNotification} className="button primary_btn">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* delete notification modal */}
      <Dialog
        className="cstm_dialog_modal small text-center"
        open={openDeleteNotification}
        onClose={handleCloseOpenDeleteNotification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flex justify-end items-center w-full'>
            <Button className='transparent_icon_btn' onClick={handleCloseOpenDeleteNotification}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="warning_content_holder">
            <div className="exclamation_icon_holder red">
              <i className="ti ti-exclamation-mark" />
            </div>
            <h2 className="heading">Are you sure to delete the selected notification</h2>
            <p className="sub_content">By deleting notification you will lose all of your notification data and won't be able to restore them.</p>
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={handleCloseOpenDeleteNotification} className="secondary_btn">No, go back!</Button>
          <Button onClick={handleCloseOpenDeleteNotification} className="primary_btn">
            Yes, I'm sure!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}