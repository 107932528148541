import EndUsersAccountsTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import AddAssetFormDialog from './addAssetDialog';
import CsvUpload from './CsvUpload';
import { usePermission } from '@fuse/hooks';
import AssetImageUpload from './AssetImageUpload';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import FuseLoading from '@fuse/core/FuseLoading';
import { Tab, Tabs } from '@material-ui/core';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import { useDispatch, useSelector } from 'react-redux';
import QuickAddAsset from 'app/fuse-layouts/shared-components/QuickAddAsset';
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	}
});

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [page, setPage] = useState(0);
	const [currentSearch, setCurrentSearch] = useState('_');
	const { hasPermission } = usePermission()
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const { open: openAddAssetForm } = useSelector((state) => state.assetsApp.formViewDialog)
	const dialogState = useSelector(({assetsApp}) => assetsApp.reportViewDialogReducer);

	let viewAssetPermission = hasPermission('view-assets',true)
	let createAssetPermission = hasPermission('add-assets',true)
	let updateAssetPermission = hasPermission('update-assets',true)
	let deleteAssetPermission = hasPermission('delete-assets',true)

	const [logs,setLogs] = useState([]);
	const [ logsLoading,setLogsLoading ] = useState(false)
	const refreshAssetList = () => {window.dispatchEvent(new Event('refreshAssetList'))};

	async function fetchAssets(rowsPerPage='all', page=0, filter='_', search='_'){
		refreshAssetList()
		try {
			// const res = await axios.get(
			// 	`/assets?page=${page}&limit=${rowsPerPage}&filter=${filter}&search=${search == '' ? '_' : search}
			// 	&queryFilter=${router.query.queryFilter != undefined ? router.query.queryFilter : '_'}
			// 	&report_view=${router.query.report_name ? true : false}
			// 	&manufacturer_id=${manufacturer_id ? manufacturer_id : ''}&model_id=${model_id ? model_id : ''}
			// 	&category_id=${category_id ? category_id : ''}&contract_header_id=${contract_header_id ? contract_header_id : ''}`)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	function onSearch(){
		if(!currentSearch){
			setCurrentSearch('');
			return
		}
		fetchAssets(10, 0, currentSearch);
	}

	function refreshList(){
		return fetchAssets();
	}

	function formatLogs (data) {
		return data.map((item) => {
			return {
				id:item.id,
				action_id: `act${item.id}`,
				serial_number: item.asset.serial_number,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				user_role: item.user_role_name,
				field_name: item.action_field_name
			}
		})
	}

	function handleAddAssetFormClose(){
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}

	function getAllAssetActivities () {
		setLogsLoading(true)
		axios.get(`/assets/get-all-activities`).then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(()=>{
			console.log('cannot fetch logs');
		}).finally(()=>{
			setLogsLoading(false)
		})
	}

	const handleTabChange = (event, value) => {
		if(value == 1){
			getAllAssetActivities()
		}
		setSelectedTab(value);
	};

	function clearSearch() {
		setPage(0);
		setCurrentSearch('')
		fetchAssets();
	}
	
	useEffect(()=>{
		if(broadcastChannel){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
		return (
			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg: classes.topBg,
					contentWrapper : classes.contentWrapper
				}}
				header={
					<DynamicFieldsHeader 
						search={onSearch} 
						clearSearch={clearSearch}
						currentSearch={currentSearch}
					/>
				}
				contentToolbar={
					<>
						
					</>
				}
				content={
					<>
					<div className={selectedTab !== 0 ? 'hidden' : ''}>
						<EndUsersAccountsTable
							tableName='asset'
							viewAssetPermission = { viewAssetPermission }
							createAssetPermission = { createAssetPermission }
							updateAssetPermission = { updateAssetPermission }
							deleteAssetPermission = { deleteAssetPermission }
						/>
						{dialogState.open && <SaveReportDialog tableName={"asset"}/>}
						{/* <AddAssetFormDialog fetchAllHardwareAssets={ fetchAssets } /> */}
						{openAddAssetForm && <QuickAddAsset open={ openAddAssetForm } close={handleAddAssetFormClose}/>}
						<AssetImageUpload />
						<CsvUpload
							refresh={refreshList}
						/>
					</div>
					<div className={selectedTab !== 1 ? 'hidden' : ''}>					
						{logsLoading ? <FuseLoading/> : <ActivityAgGridListing module='asset' logs = {logs} /> }
					</div>
					</>
				}
				innerScroll
			/>
		)
	}
}

export default withReducer('assetsApp', reducer)(CardedFullWidth2TabbedSample);
