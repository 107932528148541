import React from 'react';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { useState, useEffect } from 'react';
import axios from "@fuse/utils/axios";
import { TextField, InputLabel, InputAdornment, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import useToast from '@fuse/hooks/useToast';
import { useDispatch } from 'react-redux';

function SecurityTab(){
	const user = useCurrentUser();
	const [platformCustomer, setPlatformCustomer] = useState({});
	const dispatch = useDispatch()
	const toast = useToast(dispatch);

	useEffect(()=>{
		axios.get(`/client/list/${user.data.id}`)
		.then(res=>{
			const {data} = res.data
			
			setPlatformCustomer(data)
		}).catch((err) => {
			console.log(err)
		})
	},[]);


	return(<>
		<InputLabel style={{marginTop: 10}}>API Key</InputLabel>
		<TextField
			className="mt-8 mb-16"
			variant="outlined"
			value={platformCustomer.api_key}
			readOnly
			fullWidth
			inputProps={{
				readOnly: true,
				disabled: true,
			}}
			InputProps={{
				endAdornment:(
					<InputAdornment position="end">
						<IconButton
						onClick={() => {
							navigator.clipboard.writeText(platformCustomer.api_key);
							toast.success("Copied to clipboard")
						}}>
						<FileCopyIcon />
						</IconButton>
					</InputAdornment>
				),
				className: 'Mui-disabled text-black'
			}}
		/>
	</>);
}

export default SecurityTab;