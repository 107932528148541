import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button, AccordionActions } from '@material-ui/core';
import axiosgeneral from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	  },
	  root: {
		width: "100%",
	  },
	  title: {
		fontSize: 14,
	  },
	  pos: {
		marginBottom: 12,
	  },
	  borderRightLight: {
		borderRight:"1px solid rgba(0,0,0,0.12)"
	  },
	  pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "center"
	},
});

function GridView(props) {
    const classes = useStyles();

	return (
        
					<Grid container spacing={3}>
						{
							
					props.endUsersAccounts
					.map(acc => {
										return (
										<Grid item sm={4} xs={12}>
										<Card className={classes.root} variant="outlined"
										>
										<CardHeader title={acc.service_name} subheader={acc.service_details}/>
										<Divider/>
										<CardContent>
										<Grid container spacing={1}>
											<Grid item xs={6} className={`${classes.borderRightLight} flex justify-between`}>
												<Typography variant="body2" component="p" color="textSecondary">
													Cost
												</Typography>
												<Typography variant="body2" component="p" color="textPrimary">
													{acc.cost}
												</Typography>
											</Grid>
											<Grid item xs={6} className="flex justify-between">
												<Typography variant="body2" component="p" color="textSecondary">
													MSRP
												</Typography>
												<Typography variant="body2" component="p" color="textPrimary">
													{acc.msrp}
												</Typography>
											</Grid>
											<Grid item xs={6} className={`${classes.borderRightLight} flex justify-between`}>
												<Typography variant="body2" component="p" color="textSecondary">
													Response Time
												</Typography>
												<Typography variant="body2" component="p" color="textPrimary">
													{acc.response_time}
												</Typography>
											</Grid>
											<Grid item xs={6} className="flex justify-between">
												<Typography variant="body2" component="span" color="textSecondary">
													Profit
												</Typography>
												
												<Typography variant="body2" component="span" color="textPrimary" className={`float-right`} style={{marginLeft:"auto"}}>
													{+acc.msrp - +acc.cost}
												</Typography>
											</Grid>
											{/* {Object.keys(acc).map(key=>{
												if(key != 'id'){
													return(
														<Grid item xs={6}>
														<Typography variant="body2" component="p" color="textSecondary">
														{key.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
														</Typography>
														<Typography variant="body2" component="p" color="textPrimary">
														{acc[key]}
														</Typography>
														</Grid>
													)
												}
												
											})} */}
										</Grid>
										</CardContent>
										{/* <CardActions>
											<Button size="small">Learn More</Button>
										</CardActions> */}
										</Card>
										</Grid>)
									})
						}
					</Grid>
	);

}

export default GridView
