import EndUsersAccountsTable from './tables';
import useToast from '@fuse/hooks/useToast'
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import AddLocationTypeFormDialog from './addLocationType';
import { usePermission } from '@fuse/hooks';
import { openFormViewerDialog,closeFormViewerDialog } from '../store/formViewDialogReducer';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { Tab, Tabs } from '@material-ui/core';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
// import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import { usePusher } from '@fuse/hooks';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	}
});

function CardedFullWidth2TabbedSample(props) {
	const router = useRouter();
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [page, setPage] = useState(0);
	const { hasPermission } = usePermission();
	const [loading, setLoading] = useState(false);
	const user = useCurrentUser();
	const [selectedTab, setSelectedTab] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [currentSearch, setCurrentSearch] = useState('_');
	const [locationTypes, setLocationTypes] = useState([]);
	const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)
	const openSt = useSelector((state) => state.reportApp)
	const serial_number = useSelector(({ServiceRequestsApp})=>ServiceRequestsApp.filterParamsReducer.serial_number)
	const [ logs,setLogs ] = useState([]);
	const [ logsLoading,setLogsLoading ] = useState(false)
	const addLocationTypePermission =hasPermission('add-location_type');
	const viewLocationTypePermission = hasPermission('view-location_type');
	const updateLocationTypePermission = hasPermission('update-location_type');
	const deleteLocationTypePermission = hasPermission('delete-location_type');
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"

	function getLocationTypes(){
		setLoading(true)
		axios.get("/location-type").then((res) => {
			const { data } = res.data;
			setLocationTypes(formatData(data))
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setLoading(false)
		});
	}

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	function formatData(data){
		return data.map((item) => {
			const tempElement = document.createElement('div');
			tempElement.innerHTML = item.description;
			return {
				id: item.id,
				location_type: item?.name,
				description: item.description,
				description_as_plain_text: tempElement.textContent
			}
		})
	}
	

	const handleTabChange = (event, value) => {
		if(value ==1){
			// getLogs()
		}
		setSelectedTab(value);
	};

	useEffect(()=>{
		// setLoading(true)
		getLocationTypes()

		if(serial_number){
			dispatch(openFormViewerDialog())
		}

		//call on component unmount
		return(()=>{
			dispatch(closeFormViewerDialog())
		})
	},[]);

	return (
		<>
			<SaveReportDialog tableName={"location type"}/>
			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg: classes.topBg,
					contentWrapper : classes.contentWrapper
				}}
				// header={
				// 	<DynamicFieldsHeader 
				// 		currentSearch={currentSearch}
				// 	/>
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Location types" />
				// 			<Tab key="end_user_tab_2" className="h-64" label="Activities" />
				// 		</Tabs>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							<EndUsersAccountsTable 
								locationTypes={locationTypes}
								page={page}
								setPage={setPage}
								rowsPerPage={rowsPerPage}
								setRowsPerPage={setRowsPerPage}
								getLocationTypes = {getLocationTypes}
								loading={loading}
								addLocationTypePermission = {addLocationTypePermission}
								viewLocationTypePermission = {viewLocationTypePermission}
								updateLocationTypePermission = {updateLocationTypePermission}
								deleteLocationTypePermission = {deleteLocationTypePermission}
							/>
							{openState.open && <AddLocationTypeFormDialog getLocationTypes = {getLocationTypes}/>}
						</div>
						{/* <div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
								{logsLoading ? <FuseLoading/> : <ActivityAgGridListing module='location_type' logs = {logs} /> }
						</div> */}
					</>
				}
				innerScroll
			/>
		</>
	)
}

export default withReducer('LocationTypeApp', reducer)(CardedFullWidth2TabbedSample);
