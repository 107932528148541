import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";
import { formatPrice } from "@fuse/utils/currencyFormat";

const useStyles = makeStyles({
  row: {
    // border: '2px solid black',
    // height: '100%',
    width: "100%",
    marginBottom: "15px",
    display: "flex",
  },
  column: {
    // border: '2px solid black',
    width: "100%",
    textAlign: "left",
  },
});

function PaymentTab({ billingCycleData }) {
  const classes = useStyles();
  
  return (
    <>
      <div className='cstm_aggridtable_holder'>
        <table style={{maxWidth: '650px'}}>
          <thead>
            <tr>
              <th>Attributes</th>
              <th style={{width: '350px'}}>Value</th>
            </tr>
          </thead>
					<tbody>
						<tr>
              <td>Currency</td>
              <td>{billingCycleData !== null ? billingCycleData?.currency : "N/A"}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>{billingCycleData !== null ? formatPrice(Number(billingCycleData?.amount)) : "N/A"}</td>
            </tr>
            <tr>
              <td>Payment Term</td>
              <td>{billingCycleData !== null ? billingCycleData.payment_term.display_name : "N/A"}</td>
            </tr>
            <tr>
              <td>Every</td>
              <td>{billingCycleData !== null ? billingCycleData.billing_cycle : "N/A"}</td>
            </tr>
            <tr>
              <td>Billing Cycle Date</td>
              <td>{billingCycleData !== null ? dateReformatTo_mmm_dd_yyyy(billingCycleData.billing_cycle_date) : "N/A"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default withRouter(PaymentTab);
