import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles, Modal, TextField } from '@material-ui/core';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function ServiceRequestSourceDialog(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const schema = yup.object({
        source_name: yup.string().required('Source Name is required!'),
    })

    const defaultValues = {
        source_name: ''
    }

    const { 
        control, 
        formState:{errors}, 
        handleSubmit: sourceFormSubmit, 
        reset: sourceFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    function manageSourceData(formData){
        const payload = {
            source_name: formData.source_name,
        }
        axios.post('/service-request-source/add', payload)
        .then(response => {
            if(response.status == 201){
                toast.error(response.data.message)
                return
            }else {
                sourceFormReset(defaultValues)
                //Send newly added source value
                props.handleCloseDialog(response.data.data.new_source)
                toast.success('Source added Successfully.')
                props.refreshList()  
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    function sourceFromErrors(error){
        console.log('statusFormErrorData: ',error);
    }

    return (
        <Modal
            open={props.open}
            className={classes.modal}
        >
            <div 
                style={{
                    width:700, 
                    backgroundColor: 'white',
                    borderRadius:9,display:'flex',
                    flexDirection:'column',
                    padding:'20px'
                }}
            >
                <div 
                    style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems:'center' 
                    }}
                >
                    <h1>Add Source</h1>
                    <IconButton onClick={()=>{
                        sourceFormReset(defaultValues)
                        props.handleCloseDialog()
                    }}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <form onSubmit={sourceFormSubmit(manageSourceData, sourceFromErrors)}>
                    <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>
                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='source_name' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='Source Name' 
                                        autoFocus
                                        fullWidth
                                        required
                                        style={{background: 'white'}}
                                        FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px'
                                        }
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            style={{ borderRadius: '4px' }}
                            type='submit' 
                        >
                            Add
                        </Button>
                        <Button 
                            variant='outlined'
                            color='primary'
                            size='small'
                            style={{ borderRadius: '4px' }}
                            onClick={()=>{
                                sourceFormReset(defaultValues)
                                props.handleCloseDialog()
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ServiceRequestSourceDialog
