const initialState = {
  isProgressBarVisible: false,
  syncStatus: 'IN_PROGRESS',
}

const actionTypes = {
  UPDATE_SYNC_STATUS: "UPDATE_SYNC_STATUS",
  TOGGLE_PROGRESS_BAR_VISIBILITY: "TOGGLE_PROGRESS_BAR_VISIBILITY",
}

export function updateSyncStatus(payload) {
  return {
    type: actionTypes.UPDATE_SYNC_STATUS,
    payload: {
      ...payload
    }
  }
}

export function toggleProgressBarVisibility(isVisible) {
  return {
    type: actionTypes.TOGGLE_PROGRESS_BAR_VISIBILITY,
    isVisible
  }
}

export default function syncReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_PROGRESS_BAR_VISIBILITY: {
      return {
        ...state,
        isProgressBarVisible: action.isVisible,
      }
    }
    case actionTypes.UPDATE_SYNC_STATUS: {
      return {
        ...state,
        syncStatus: action.payload.status,
      }
    }
    default:
      return state
  }
}
