import { React, useState, useEffect, useRef } from 'react';
import axios from '@fuse/utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material'
import { IconBellRinging } from '@tabler/icons-react';
import CardContent from '@material-ui/core/CardContent';
import { setAssetNotification, setContractNotification } from 'app/store/fuse/notificationSlice';
import Card from '@material-ui/core/Card';
import AddDashboardAlert from '../addAlert';
import ContractDashboardCard from './dashboardCard'
import ContractDashboardChart from './dashboardChart'

export default function Contracts() {
const [moduleName, setModuleName] = useState('contract');
const limit = 100, page = useRef(0);
const dispatch = useDispatch();
const [open, setOpen] = useState(false)
const [notifications, setNotifications] = useState([])
const [loading, setLoading] = useState(false);

const handleOpenDialog = () => {
  setOpen(true)
  getNotifications(moduleName)
}

const close = () => {
  setNotifications([])
  setOpen(false)
  page.current = 0
}

const {
  contractNotificationCount
} = useSelector(({ fuse }) => fuse.notifications);


function getNotifications(moduleName, refreshBtnClicked=false) {
  if (!moduleName) return
  axios.get(`/notifications?type=${moduleName}&limit=${limit}&page=${page.current}`).then((res) => {
    const { notifications: notification } = res.data.data;
    if(!refreshBtnClicked) setNotifications([...notifications, ...notification])
    else setNotifications([...notification])
    page.current++;
  }).catch((err) => {
    console.log(err)
  }).finally(() => setLoading(false))
}

function onClickRefreshBtn(){
  page.current = 0;
  getNotifications(moduleName, true)
}

function markAllAsRead() {
  axios.put(`/notifications/update-read-status-of-all-notifications?type=${moduleName}`).then((res) => {
    if (moduleName === 'asset') {
      dispatch(setAssetNotification({
        assetNotificationCount: 0
      }))
    }
    if (moduleName === 'contract') {
      dispatch(setContractNotification({
        contractNotificationCount: 0
      }))
    }
    close()
  }).catch((err) => {
    console.log(err)
  }).finally(() => setLoading(false))
}

function updateReadStatusOnClickNotification(notificationId) {
  if (!Boolean(notificationId)) return
  axios.put(`/notifications/update-read-status/${notificationId}`).then((res) => {
    refreshNotifications()
    getNotifications(moduleName)
  }).catch((err) => {
    console.log(err)
  })
}

const refreshNotifications = () => {
  axios.get("/notifications/count")
    .then((res) => {
      let assetCount = 0;
      let contractCount = 0;
      const { totalNotificationsCount } = res.data.data;
      const unreadNotificationsCount = totalNotificationsCount.reduce((res, curr) => {
        if (curr.type === "asset") {
          assetCount = curr.count;
        }
        if (curr.type === "contract") {
          contractCount = curr.count;
        }
        res[curr.type] = curr.count
        return res;
      }, {});
      dispatch(setAssetNotification({
        assetNotificationCount: assetCount
      }))
      dispatch(setContractNotification({
        contractNotificationCount: contractCount
      }))
    }).catch((err) => console.log(err));
};

useEffect(()=>{
    refreshNotifications()
},[])

  return (
    <>
    {!loading  &&
      <div className='custom_tab'>
					<div className="flex flex-1 w-full items-center justify-between">
						<div className="flex items-center">
							<Typography
								// component={motion.span}
								initial={{ x: -20 }}
								animate={{ x: 0, transition: { delay: 0.2 } }}
								delay={300}
								className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold assets_catalog_header"
								style={{ marginBottom: '10px' }}
							>
								Contract Dashboard
							</Typography>
						</div>
					</div>
					<div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px', gap: '12px' }}>
						<ContractDashboardCard />
						<div className='dashboard_alertcard dashboard_card' onClick={() => handleOpenDialog()}>
							<Card className='alert_card'>
								<CardContent style={{ height: '100%', padding: '27px' }}>
									<div>
										<span className='dashboardPattern_img'>
											<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
											</svg>
										</span>
										<Box sx={{ alignContent: 'center', display: 'flex' }}>
											<span className='alert_icon icon_holder'>
												<IconBellRinging color='#000' size={18} />
											</span>
										</Box>
										<div className='alert_detailstext'>
											<p>Alerts</p>
											<h5 style={{ display: 'flex' }}>{contractNotificationCount}
												<span style={{ marginTop: '5px' }}>
													<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
														<g clipPath="url(#clip0_2359_14699)">
															<path d="M6.7998 12.4546L10.7998 8.45459L6.7998 4.45459" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
														</g>
														<defs>
															<clipPath id="clip0_2359_14699">
																<rect width="16" height="16" fill="white" transform="matrix(0 -1 1 0 0.799805 16.4546)" />
															</clipPath>
														</defs>
													</svg>
												</span>
											</h5>
										</div>
									</div>
								</CardContent>
							</Card>
						</div>

						<ContractDashboardChart />
					</div>
				</div>
    }

        <AddDashboardAlert
            open={open}
            close={close}
            moduleName={moduleName}
            notifications={notifications}
            loading={loading}
            markAllAsRead={markAllAsRead}
            getNotifications={() => getNotifications(moduleName)}
            updateReadStatusOnClickNotification={updateReadStatusOnClickNotification}
            onClickRefreshBtn={onClickRefreshBtn}
          />
    </>
  );
}
