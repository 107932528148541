import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core';
import React, { useState,useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Autocomplete } from '@material-ui/lab';
import Editor from '@fuse/components/Editor';
// import LocationTypeDialog from './QuickAddLocationType';
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AddLocationTypeFromQuickAction from './AddLocationTypeFromQuickAction'



const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


const AddLocationFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const [ locationNote,setLocationNote ] = useState('')
    const [ locationTypes, setLocationTypes ] = useState([])
    const [ openAddLocationTypeModal, setOpenAddLocationTypeModal ] = useState(false)

    function getLocationTypes(){
		axios.get("/location-type").then((res) => {
			const { data } = res.data;
			setLocationTypes((()=>{
                return data.map((item)=>{
                    return {
                        id: item.id,
                        name: item.name
                    }
                })
            })())
		}).catch((err) => {
			console.log(err)
		})
	}


    const handleOpenCloseAddLocationType = () => {
        setOpenAddLocationTypeModal(!openAddLocationTypeModal);
    }


    useEffect(()=>{
        getLocationTypes()
    },[])


    const defaultValues = {
        location_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: null,
        note: '',
        locationType: null
    }

    const schema = yup.object({
        location_name: yup.string().trim().required('Location Name is required!'),
        address1: yup.string().trim().required('Address 1 is required!'),
        address2: yup.string().trim().nullable(),
        city: yup.string().trim().required('City is required!'),
        state: yup.string().trim().required('State/Province is required!'),
        zip: yup.string().trim().required('Zip is required!'),
        country: yup.object().shape({
            name: yup.string().required('Country is required!'),
            code: yup.string().required('Country is required!'),
            currency: yup.string().required('Country is required!')
        }),
        note:  yup.string().trim().nullable(),
        locationType: yup.object().shape({
            id: yup.string().required('Location Type is required!'),
            name: yup.string().required('Location Type is required!')
        }),
    })

    const { 
        control: shippingLocationFormControl, 
        formState:{errors: shippingLocationFormErrors}, 
        handleSubmit: shippingLocationFormSubmit, 
        trigger,
        clearErrors,
        setValue,
        reset: shippingLocationFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });



    function statusFormErrorData(error){
        console.log('statusFormErrorData: ',error);
    }


    const newLocationTypeAdded = (createdData) => {
        setOpenAddLocationTypeModal(false)
        setValue('locationType',{id:createdData.id,name:createdData.name})
        clearErrors('locationType')
        getLocationTypes()
    }


    const onSubmit = async (data) => {
        await trigger();
        if(Object.keys(shippingLocationFormErrors).length !== 0) { 
            return;
        }
        const payload = {
            entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
            address: data.address1,
            address2: data.address2,
            location: data.location_name,
            city: data.city,
            state: data.state,
            zip: data.zip,
            country: data.country.name || '',
            note: '',
            location_type_id: data.locationType.id
        }
      
        axios.post('/shipping/add', payload).then(response => {
            if(response.status == 201){
              toast.error(response.data.message)
              return
            }
      
            shippingLocationFormReset(defaultValues)
      
            //Send newly added location value
            // props.handleCloseLocationDialog(response.data.data.new_shipping_location)
            toast.success('Location added successfully.')
            newDataAddedEvent(response.data.data.new_shipping_location)
            // props.fetchLocationSuggestions()
              
        }).catch(err => {
            toast.error(err.response.data.data)
        })
    }
    
    return (

        <>
            <Dialog
            className="cstm_dialog_modal medium text-center"
            open={openAddStatusModalStateData}
            // onClose={handleOpenCloseAddModalEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div className='heading_holder flexbox_holder items-center w-full'>
                    <h2 className="main_heading">Add Location</h2>
                    <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                        <i className="icon ti ti-x" />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-left">
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Location Name
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="location_name"
                            className="mb-16"
                            defaultValue={defaultValues.location_name}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                    {...field}
                                    variant='outlined'
                                    // label='Location Name'
                                    required
                                    error={!!shippingLocationFormErrors.location_name}
                                    helperText={shippingLocationFormErrors?.location_name?.message}
                                    FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px',
                                            fontSize: '10px'
                                        }
                                    }}
                                    style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Address 1
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="address1"
                            className="mb-16"
                            defaultValue={defaultValues.address1}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                    {...field}
                                    variant='outlined'
                                    // label='Address Line 1'
                                    required
                                    // fullWidth
                                    error={!!shippingLocationFormErrors.address1}
                                    helperText={shippingLocationFormErrors?.address1?.message}
                                    FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px',
                                            fontSize: '10px'
                                        }
                                    }}
                                    style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Address 2
                            {/* <span className='required_span'>*</span> */}
                        </Typography>
                        <Controller
                            name="address2"
                            className="mb-16"
                            defaultValue={defaultValues.address2}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                    {...field}
                                    variant='outlined'
                                    // label='Address Line 1'
                                    required
                                    // fullWidth
                                    error={!!shippingLocationFormErrors.address2}
                                    helperText={shippingLocationFormErrors?.address2?.message}
                                    FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px',
                                            fontSize: '10px'
                                        }
                                    }}
                                    style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            City
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="city"
                            className="mb-16"
                            defaultValue={defaultValues.city}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='City'
                                        // fullWidth
                                        required
                                        error={!!shippingLocationFormErrors.city}
                                        helperText={shippingLocationFormErrors?.city?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{ marginBottom: '10px', width: '100%', marginRight: '10px',background: 'white'  }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            State/Province
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="state"
                            className="mb-16"
                            defaultValue={''}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='State/Province'
                                        required
                                        // fullWidth
                                        error={!!shippingLocationFormErrors.state}
                                        helperText={shippingLocationFormErrors?.state?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{ marginBottom: '10px', width: '100%', marginRight: '10px',background: 'white'  }} 
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Zip
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="zip"
                            className="mb-16"
                            defaultValue={''}
                            control={shippingLocationFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='State/Province'
                                        required
                                        // fullWidth
                                        error={!!shippingLocationFormErrors.zip}
                                        helperText={shippingLocationFormErrors?.zip?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{ marginBottom: '10px', width: '100%', marginRight: '10px',background: 'white'  }} 
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='autocomplete_holder mb-10 relative'>
                        <Typography className='status_text'>
                            Country
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="country"
                            className="mb-16"
                            control={shippingLocationFormControl}
                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                <>
                                    <Autocomplete
                                        {...field}
                                        style={{ width: '100%' }}
                                        options={countries}
                                        // disablePortal
                                        onChange={(event, value) => field.onChange(value)}
                                        PaperComponent={({ children }) => (
                                        <Paper className="autocomplete_holder">{children}</Paper>
                                        )}
                                        getOptionLabel={option => option.name ?? ''}
                                        renderInput={(params) => {
                                        return (
                                            <div ref={params.InputProps.ref}>
                                                <TextField
                                                    style={{background: 'white'}}
                                                    {...params}
                                                    // label="Country"
                                                    required
                                                    error={!!shippingLocationFormErrors.country}
                                                    helperText={shippingLocationFormErrors?.country?.message && 'Country is required!'}
                                                    FormHelperTextProps={{
                                                        style: { 
                                                            margin : 0, 
                                                            backgroundColor: '#f4f4f4',
                                                            width: '100%',
                                                            paddingTop: '2px',
                                                            fontSize: '10px',
                                                        }
                                                    }}
                                                    inputRef={ref}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                        )
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='autocomplete_holder mb-10 relative'>
                        <Typography className='status_text'>
                            Location Type
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="locationType"
                            // className="mb-16"
                            control={shippingLocationFormControl}
                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                <>
                                    <Autocomplete
                                        {...field} 
                                        style={{ width: '100%'}}
                                        options={locationTypes}
                                        // disablePortal
                                        onChange={(event, value) => field.onChange(value)}
                                        PaperComponent={({ children }) => (
                                            <Paper className="autocomplete_holder">{children}
                                                <div className='p-16'>
                                                    <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddLocationType()}>
                                                        <i className='ti ti-plus' />
                                                        <span>Add Location Type</span>
                                                    </Button>
                                                </div>
                                            </Paper>
                                        )}
                                        getOptionLabel={option => option.name ?? ''}
                                        renderInput={(params) => {
                                            return (
                                                <div ref={params.InputProps.ref}>
                                                    <TextField
                                                        name="locationType"
                                                        style={{background: 'white'}}
                                                        {...params}
                                                        // label="Location Type"
                                                        // required
                                                        error={!!shippingLocationFormErrors.locationType}
                                                        helperText={shippingLocationFormErrors?.locationType?.message && 'Location Type is required!'}
                                                        FormHelperTextProps={{
                                                            style: { 
                                                                margin : 0, 
                                                                backgroundColor: '#f4f4f4',
                                                                width: '100%',
                                                                paddingTop: '2px',
                                                                fontSize: '10px'
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>
                                            )
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="action_footer_holder">
                <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                <Button onClick={shippingLocationFormSubmit(onSubmit)} className="primary_btn">
                    Save and Select
                </Button>
            </DialogActions>
            </Dialog>
            {
                openAddLocationTypeModal && <AddLocationTypeFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddLocationType}
                    openAddStatusModalStateData={openAddLocationTypeModal}
                    newDataAddedEvent={newLocationTypeAdded}
                />
            }
        </>
        
    );
}

export default AddLocationFromQuickAction