import { Button, makeStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { useDispatch,useSelector } from 'react-redux';
import { openFormViewerDialog, closeFormViewerDialog } from '../store/formViewDialogReducer';
import AddRolesDialog from './AddRolesDialog';
import { usePermission } from '@fuse/hooks';
// import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
	buttonSquare:{
		borderRadius: '5px'
	},
}))

function Header() {

	const classes = useStyles()
	const dispatch = useDispatch()
	const { hasPermission } = usePermission()

	const viewRolePermission = hasPermission('view-role')
	const createRolePermission = hasPermission('add-role')
	const updateRolePermission = hasPermission('update-role')
	const deleteRolePermission = hasPermission('delete-role')
	const openState = useSelector((state) => state.rolesApp.formViewDialog)

	const handleOpenDialog = () => {
		dispatch(openFormViewerDialog())
	}

	return (
		<>
			<div className="flex flex-1 items-center justify-between p-4 sm:p-24">
				<div className="flex flex-shrink items-center justify-between w-full">
					<div className="flex items-center">
						<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M3 4.99509C3 3.89323 3.89262 3 4.99509 3H19.0049C20.1068 3 21 3.89262 21 4.99509V19.0049C21 20.1068 20.1074 21 19.0049 21H4.99509C3.89323 21 3 20.1074 3 19.0049V4.99509ZM5 5V19H19V5H5ZM7.97216 18.1808C7.35347 17.9129 6.76719 17.5843 6.22083 17.2024C7.46773 15.2753 9.63602 14 12.1022 14C14.5015 14 16.6189 15.2071 17.8801 17.0472C17.3438 17.4436 16.7664 17.7877 16.1555 18.0718C15.2472 16.8166 13.77 16 12.1022 16C10.3865 16 8.87271 16.8641 7.97216 18.1808ZM12 13C10.067 13 8.5 11.433 8.5 9.5C8.5 7.567 10.067 6 12 6C13.933 6 15.5 7.567 15.5 9.5C15.5 11.433 13.933 13 12 13ZM12 11C12.8284 11 13.5 10.3284 13.5 9.5C13.5 8.67157 12.8284 8 12 8C11.1716 8 10.5 8.67157 10.5 9.5C10.5 10.3284 11.1716 11 12 11Z"></path></svg>
						<Typography
							initial={{ x: -20 }}
							animate={{ x: 0, transition: { delay: 0.2 } }}
							delay={300}
							className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
							style = {{ color:'black'}}
						>
							Roles & Permissions
						</Typography>
					</div> 
					{createRolePermission && <div>
						<Button
							variant="contained"
							// color="secondary"
							style={{backgroundColor:'#562658', color:'white'}}
							className={`w-full ${classes.buttonSquare}`}
							onClick = {() => handleOpenDialog()}
							startIcon={
							<Icon fontSize="large" title="Crate Role">
								add
							</Icon>}>
								Create Role
						</Button>
					</div>}
				</div>
				<AddRolesDialog />
			</div>

			

		</>
	);
}

export default Header;
