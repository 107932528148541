import React, {useState,useEffect} from 'react'
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { comapreDates,getFormattedDate } from '@fuse/utils/dateOperations'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { 
    makeStyles,
    FormHelperText
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({  
    // helperText: {
    //     color: 'red',
    //     paddingLeft: '1px'
    // },
    datePickerInput: {
        '& .MuiInputBase-input': {
            fontSize: '16px'
        }
    }
}))



const CustomDatePickerV2 = (
    {   state,
        setState,
        label,
        required,
        minDate,
        maxDate,
        onChangeFromParent = () => {},
        fromCustomFilter = true
        // style={}
        // anchorOriginHorizontal="left",
        // anchorOriginVertical="bottom",
        // transformOriginHorizontal="left",
        // transformOriginVertical="top",
        // leftAlign="0px"
    }
) => {

    const classes = useStyles()

    const [inputValue,setInputValue] = useState('');
    const [ errorMessage,setErrorMessage ] = useState('');
    //For custom filter
    const [ makeStateEmpty,setMakeStateEmpty ] = useState(false);


    function handleStateChange ({key,val}) {
        setState((prev)=>{
            return {
                ...prev,
                [key]: val
            }
        })
    }

    function onKeyPressValidaion (e) {
        try {
            // const regex = /(0[1-9]|1[0-2])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d{2}/
            const regex1 = /(0[13578]|1[02])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d{2}/
            const regex2 = /(0[469]|1[1])[/](0[1-9]|[12][0-9]|3[0])[/](19|20)\d{2}/
            const regex3 = /(0[2])[/](0[1-9]|[12][0-9])[/](19|20)\d{2}/

            const value = inputValue;

            if(!value.length) return
            if(value.includes('_')){
                handleStateChange({
                    key: 'error',
                    val: true                    
                })
                setErrorMessage('Provide a valid date')
                return
            }
                handleStateChange({
                    key: 'error',
                    val: false                    
                })
                setErrorMessage('')                
            

            // Check if date is valid or not
            // regex.test(value) return false if string dosen't match with regex
            if(!regex1.test(value) && !regex2.test(value) && !regex3.test(value)){
                handleStateChange({
                    key: 'error',
                    val: true                    
                })
                setErrorMessage('Provide a valid date')
                return
            }
                handleStateChange({
                    key: 'error',
                    val: false                    
                })
                setErrorMessage('')
            
    
            // Check if date lesser than minDate
            if(minDate || null){
                const { hasError: lessThanVaildationFailed,
                    message: lessThanVaildationFailedMessage
                } = comapreDates(minDate,value,'is_less_than','Date should not be before minimal date');
                if(lessThanVaildationFailed){
                    handleStateChange({
                        key: 'error',
                        val: true                    
                    })
                    setErrorMessage(lessThanVaildationFailedMessage)
                    return
                }
                    handleStateChange({
                        key: 'error',
                        val: false                    
                    })
                    setErrorMessage(lessThanVaildationFailedMessage)
                
            }
    
            // Check if date greater than maxDate
            if(maxDate || null){
                const { 
                    hasError: greaterThanVaildationFailed,
                    message: greaterThanVaildationFailedMessage
                } = comapreDates(maxDate,value,'is_greater_than','Date should not be after maximal date');
                if(greaterThanVaildationFailed){
                    handleStateChange({
                        key: 'error',
                        val: true                    
                    })
                    setErrorMessage(greaterThanVaildationFailedMessage)
                    return
                }
                    handleStateChange({
                        key: 'error',
                        val: false                    
                    })
                    setErrorMessage(greaterThanVaildationFailedMessage)
                
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(()=>{
        if(!inputValue.length) { 
            handleStateChange({
                key: 'error',
                val: false                    
            })
            setErrorMessage('')    
            return
        }
        onKeyPressValidaion(inputValue);
    },[inputValue])

    return (
        <div className='date_pickr'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    // format="DD-MM-YYYY"
                    className="w-full"
                    views={['month', 'day', 'year']}
                    inputFormat="MM/dd/yyyy"
                    name="date"
                    {
                        ...(minDate == null ? {} : {minDate:new Date(minDate)})
                    }
                    {
                        ...(maxDate == null ? {} : {maxDate:new Date(maxDate)})
                    }
                    error={state?.error}
                    helperText=''
                    defaultValue={dayjs(new Date())}
                    renderInput={(params) => (
                        <TextField {...params} fullWidth size="small" />
                    )}
                    InputProps={{
                        disableUnderline: true,
                        required: required || false        
                    }}
                    onKeyUp={(e)=>{
                        setInputValue(e.target.value)
                    }}
                    sx={{
                        '& label': {
                            fontSize: '1.3rem',
                            '&.MuiInputLabel-shrink': {
                                color: 'blue',
                                fontSize: '11px',
                            },
                        },
                    }}
                    style={{
                        backgroundColor: 'white',
                        marginTop: '2px',
                        marginBottom: '0px'
                    }}
                    label={label || ''}
                    InputLabelProps={{ shrink: true }}
                    onOpen={(e)=>{if(fromCustomFilter) setMakeStateEmpty(true)}}
                    value={makeStateEmpty?null: state.date !== null ? new Date(state.date) : null}
                    onChange={(event)=>{
                        
                        if(isNaN(event)) return
                        if(!event){
                            handleStateChange({
                                key: 'date',
                                val: null
                            })
                            return
                        }
                        event.setHours(new Date().getHours())
                        event.setMinutes(new Date().getMinutes())
                        event.setSeconds(new Date().getSeconds())
                        handleStateChange({
                            key: 'date',
                            // val: ((new Date(event).toISOString()).split('T'))[0]
                            val: event
                        })
                        state = null
                        setInputValue(getFormattedDate((new Date(event).toISOString()).split('T')[0]))
                        onChangeFromParent(event)
                    }}                              
                    PopoverProps={{
                        disableRestoreFocus: true,                    
                    }}
                />
            </LocalizationProvider>
            <FormHelperText className='helpertext'>{errorMessage}</FormHelperText>
        </div>
        
    );
}

export default CustomDatePickerV2