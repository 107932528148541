import { Button, Icon, InputAdornment, TextField } from '@material-ui/core'
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from '@fuse/utils/axios';

const schemaPreValidation = yup.object().shape({
	email: yup.string().matches(/^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,6}$/,"You must enter a valid email").required('You must enter a email'),
});

const defaultValuesPreVerification = {
	email: '',
};

function EmailVerificationForm({ setCurrentEmail, dispatch, toast, setPageComponent }) {
    
	const { control: preVerificationControl, formState: preVerificationFormState, handleSubmit: handleSubmitPreVerification, reset: resetPreVerification } = useForm({
		mode: 'onChange',
		defaultValuesPreVerification,
		resolver: yupResolver(schemaPreValidation)
	});

	const { errors: errorsPreVerification } = preVerificationFormState;

    const onAPICall = (formData) => {
        axios.post('/end-user/send-verification-code', formData).then(res => {
            if(res.data.statusCode === 201){
                toast.error(res.data.message)
            }else{
                if(res.data.data.verified){
                    toast.success(res.data.message)
                    alreadyVerified(formData)
                }else {
                    onSuccess(formData)
                    toast.success('Verification Code send to your email address.')
                }
            }
        }).catch(err => {
            console.log('35=>',err)
            toast.error('Something went wrong!')
        })
    }

    const alreadyVerified = (formData) => {
        resetPreVerification(defaultValuesPreVerification)
        setCurrentEmail(formData.email)
        setPageComponent(3)
    }

    const onSuccess = (formData) => {
        resetPreVerification(defaultValuesPreVerification)
        setCurrentEmail(formData.email)
        setPageComponent(2)
    }
    
    const onSubmit = (formData) => {
        // console.log('82=>',formData)
        onAPICall(formData)
    }

    const onError = (err) => {
        console.log('86=>',err)
    }

  return (
    <>
      <form
            name="emailForm"
            noValidate
            className="flex flex-col justify-start w-full"
            onSubmit={handleSubmitPreVerification(onSubmit, onError)}
        >
            <Controller
                name="email"
                control={preVerificationControl}
                render={({ field }) => (
                    <TextField
                        {...field}
                        className="mb-16"
                        label="Email"
                        autoFocus
                        type="email"
                        error={!!errorsPreVerification.email}
                        helperText={errorsPreVerification?.email?.message}
                        variant="outlined"
                        required
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start' style={{ opacity: '0.5' }}><Icon>mail</Icon></InputAdornment>
                            )
                        }}
                        
                    />
                )}
            />
            
            <Button
                variant="contained"
                style={{ backgroundColor: '#F9B84B', borderRadius: '4px' }}
                className="w-50 mr-auto mt-16"
                aria-label="Continue"
                type="submit"
            >
                Continue
            </Button>
        </form>
    </>
  )
}

export default EmailVerificationForm
