import { Dialog, DialogTitle, DialogContent, DialogContentText, MenuItem, DialogActions, Grid, Button, Icon, IconButton, Box, TextField,CircularProgress,TextareaAutosize, Chip } from '@material-ui/core';
import { useState,useEffect,useDispatch } from 'react';
import { openDialog, closeDialog } from '../../../store/fuse/dialogSlice';
import useToast from '@fuse/hooks/useToast';
import axios from "@fuse/utils/axios";
import {actions} from '../shared/constants';

function InnerView(props){
    const toast = useToast(props.dispatch);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(props.title ?? `Request a change for quote ${props.quote.quote_id}`)
    const [selectedAction, setSelectedAction] = useState(props.action ? props.action : '');
    const [comment, setComment] = useState('');
    const [selectedItems, setSelectedItems] = useState(props.selectedItems);
    const [error, setError] = useState(''); 
    function handleMakeRequest(){
        if(selectedAction == ''){
            toast.error('Please select an action');
            return
        }

        if(comment == ''){
            toast.error('Please describe the request');
            return
        }

        setLoading(true)
        props.saveRequest('request', selectedAction, comment, ()=>{
            resetState();
            setLoading(false);
            props.closeDialogView()})
    }

    function resetState(){
        setSelectedAction('');
        setComment('');
    }

    useEffect(() => {
        if(selectedAction === 'add_line_item'){
            setLoading(true);
            axios.post(`/quotes/is-serial-number-mapped`,{
                serial_numbers: selectedItems.map(item => item.value)
            })
            .then(res => {
                if(res.data.statusCode !== 200){
                    setError(res.data.message);
                }
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
        }
    }, [selectedAction])

    if(loading){
        return(
            <Box display="flex" alignItems="center" justifyContent="center" justifyItems="center" style={{padding: 20}}>
                <CircularProgress color="primary"/>
            </Box>
        )
    }

    if(error){
        return (
            <>
            <DialogTitle onClose={props.closeMergeView} className={`mb-0 pb-0`}>
                {
                    
                    <Spreaded left={title} right={
                    <IconButton onClick={props.closeDialogView}>
                        <Icon>close</Icon>
                    </IconButton>} />
                }
            </DialogTitle>
            <DialogContent>
            <Box display="flex" alignItems="center" justifyContent="center" justifyItems="center" style={{padding: 20}}>
                {error}
            </Box>
            </DialogContent>
            </>
        )
    }

    return(
        <>
            <DialogTitle onClose={props.closeMergeView} className={`mb-0 pb-0`}>
                {
                    
                    <Spreaded left={title} right={
                    <IconButton onClick={props.closeDialogView}>
                        <Icon>close</Icon>
                    </IconButton>} />
                }
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {!props.hideAction && 
                    <Grid item xs={12}>
                        <span style={{fontWeight:'bold'}}>Select action</span><br/>
                        <TextField
                        select
                        margin="dense"
                        variant="outlined"
                        value={selectedAction}
                        fullWidth
                        onChange={(e)=>setSelectedAction(e.target.value)}
                        >
                            {
                                Object.entries(actions.requests).map(([key, value])=>(
                                    <MenuItem value={key}>{value.label}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    }
                        <Grid item xs={12}>
                            {selectedItems.map((item,index) => (
                                <Chip label={item.value}
                                key={item.id}
                                // onDelete={()=>{
                                //     setSelectedItems(selectedItems.filter((iid, ind) => ind != index));
                                //     props.removeItem(item.id);
                                // }}
                                className={`rounded-am mr-5`}
                                />
                            ))}
                        </Grid>
                    <Grid item xs={12}>
                    <span style={{fontWeight:'bold'}}>Add Note</span><br/>
                    <TextField
                        value={comment}
                        fullWidth
                        variant="outlined"
                        onChange={(e)=>setComment(e.target.value)}
                        multiline
                        rowsMax={Infinity}
                        style={{width:'100%'}}
                    />
                    </Grid>
                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button
                variant="contained"
                color="primary"
                size="small"
                style={{borderRadius:5}}
                onClick={handleMakeRequest}
                >
                    Submit Request
                </Button>
            </DialogActions>
        </>
    )
}

function Spreaded({left, right}) {
    return(
        <Box display="flex" alignItems="center">
            <Box flexGrow={1} >{left}</Box>
            <Box>{right}</Box>
        </Box>
    )
}

function CreateRequestView(props, dispatch) {
    props.closeDialogView = ()=>{dispatch(closeDialog())};
    props.dispatch = dispatch;
    dispatch(openDialog({children: (
		<InnerView {...props} />
		),
		maxWidth: 'sm',
		fullWidth:true}))
}

export default CreateRequestView;