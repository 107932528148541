import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { withRouter } from 'react-router-dom';
import axios from "@fuse/utils/axios";
import { setServiceRequestId } from 'app/main/service-request/store/filterParamsReducer';
import { setServiceTicketsCount } from 'app/main/assets/store/formViewDialogReducer'
import { useDispatch } from 'react-redux';
import { useRouter } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';

const useStyles = makeStyles(theme => ({
	loader: {
		display: 'flex',
		marginTop: '27px',
		marginLeft: '10px',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	drawerWidth: {
		width: 600
	},
	uploadImageButton: {
		padding: '10px 36px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer',
		marginTop: "25px"
	},
	subSection: {
		marginLeft: '20px !important',
		marginRight: '20px !important',
		marginBottom: '20px !important',
		padding: '10px',
		backgroundColor: '#f4f4f4',
		borderRadius: '4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
}))


function ServiceRequestsTab(props) {

	const [windowHeight, setWindowHeight] = useState(window.innerHeight - 430);

	const gridRef = useRef()

	const onGridReady = params => {
		// Following line to make the currently visible columns fit the screen  
		params.api.sizeColumnsToFit();

		// Following line dymanic set height to row on content
		params.api.resetRowHeights();
	};

	const columns = [
		{
			field: 'ticket_number', headerName: 'Ticket Number', width: 200, headerComponentFramework: CustomFilter,
			renderCell: (params) => {
				return (<a style={{ color: "black", cursor: 'pointer' }} onClick={() => redirectToServiceRequestsPage(params)}>{params.value}</a>)
			}
		},
		{ field: 'serial_number', headerName: 'Serial Number', headerComponentFramework: CustomFilter, width: 200 },
		{ field: 'title', headerName: 'Title', headerComponentFramework: CustomFilter, width: 200 },
		{ field: 'priority', headerName: 'Priority', headerComponentFramework: CustomFilter, width: 200 },
		{ field: 'agent_name', headerName: 'Agent Name', headerComponentFramework: CustomFilter, width: 200 },
		{
			field: 'service_request_status', headerName: 'Status', headerComponentFramework: CustomFilter, width: 200, renderCell: (params) => {
				return (
					<span style={{
						display: 'flex',
						gap: '6px',
						alignItems: 'center'
					}}>
						<span style={{
							height: '10px',
							width: '10px',
							borderRadius: '50%',
							display: 'inline-block',
							marginLeft: '5px',
							backgroundColor: params.value.status_color.code
						}}></span>
						<span style={{ display: "inline-block", fontSize: "15px" }}>{params.value.status_name}</span>
					</span>
				)
			}
		}
	];

	const classes = useStyles();
	const router = useRouter()
	const [serviceRequests, setServiceRequests] = useState([])
	const [loading, setLoading] = useState(false)
	const { assetDetails } = props

	const dispatch = useDispatch()
	const toast = useToast(dispatch)

	function redirectToServiceRequestsPage(params) {
		dispatch(setServiceRequestId(params.row.id))
		router.push('/service-tickets')
	}

	function formatServiceRequests(serviceRequests) {
		return serviceRequests.map((item) => {
			return {
				id: item.id,
				ticket_number: item.ticket_id,
				serial_number: item.asset.serial_number,
				title: item.title,
				priority: item.priority.charAt(0).toUpperCase() + item.priority.slice(1),
				agent_name: `${item.agent.first_name} ${item.agent.last_name}`,
				service_request_status: item.service_request_status
			}
		})
	}
	function getServiceRequests() {
		if (!assetDetails) return
		// setLoading(true)
		axios.get(`/assets/fetch-asset-service-request/${assetDetails.id}`, {
		}).then((res) => {
			const { response } = res.data.data;
			setServiceRequests(formatServiceRequests(response))
			dispatch(setServiceTicketsCount(response.length))
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			// setLoading(false)
		})
	}

	useEffect(() => {
		getServiceRequests()
	}, [])

	if (loading) {
		return (
			<FuseLoading />
		)
	}

	return (
		<div className={classes.root}>
			<div
				className="ag-theme-alpine"
				style={{
					width: "100%",
					height: windowHeight + 105,
					fontSize: '12px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					rowData={serviceRequests}
					animateRows
					onGridReady={onGridReady}
					paginationPageSize={10}
					pagination
					defaultColDef={{
						resizable: true
					}}
				>
					{columns.map(header => {
						return (
							<AgGridColumn
								key={header.field}
								field={header.field}
								headerName={header.headerName}
								width={header.width}
								minWidth={header.minWidth}
								maxWidth={header.maxWidth}
								sortable={false}
								flex={1}
								filter="text"
								wrapText
								headerComponentFramework={header.headerComponentFramework}
							/>
						)

					})}
				</AgGridReact>
			</div>
		</div>
	)
}

export default withRouter(ServiceRequestsTab)
