const actions = {
    requests: {
        add_line_item:{
            action: 'add_line_item',
            label: 'Add line item'
        },
        remove_line_item:{
            action: 'remove_line_item',
            label: 'Remove line item'
        },
        pro_rate:{
            action: 'pro_rate',
            label: 'Pro-rate'
        },
        service_plan_upgrade:{
            action: 'service_plan_upgrade',
            label: 'Upgrade service plan'
        },
        others:{
            action: 'others',
            label: 'Others'
        },
    },
    actions: {
        approve_quote:{
            action:'approve_quote',
            label:'Approve'
        },
        deny_quote:{
            action:'deny_quote',
            label:'Decline'
        },
    },
    request_statuses: {
        pending: 'pending',
        request_change: 'request_change',
        approved: 'approved',
        denied: 'denied'
    }
}

export {actions};