import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import axios from "@fuse/utils/axios";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import autoSuggestionEditor from './cellEditors/autoSuggestionEditor';
import textEditor from './cellEditors/textEditor';
import rowEditToggleRenderer from './cellRenderer/rowEditToggleRenderer';
import {getAbbreviatedCapitalized} from '@fuse/utils/general'

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	  },
	  root: {
		// minWidth: 275,
	  },
	  title: {
		fontSize: 14,
	  },
	  pos: {
		marginBottom: 12,
	  },
});

function ListView(props) {
	const [suggestions, setSuggestions] = useState({
		serial_number: [],
		service_name: []
	})
	const [selectedSerialSuggestion, setSelectedSerialSuggestion] = useState(null);
	const [selectedServiceNameSuggestion, setSelectedServiceNameSuggestion] = useState(null);
	const [selectedSkuSuggestion, setSelectedSkuSuggestion] = useState(null);
	const [gridApi, setGridApi] = useState(null)
	function applyStyleToRows(params) {
		const request = params.node.data.request ? params.node.data.request : '';
		const requested = params.node.data.requested ? params.node.data.requested : '';

		switch(request){
			case 'remove_line_item':
				return {border: '1px solid red'};
				break;
			case 'add_line_item':
				return {border: '1px solid green'}
				break;
			default:
				break;
		}
		
		switch(requested){
			case 'add':
				return {border: '1px solid green'}
				break;
			case 'remove':
				return {border: '1px solid red'}
				break;
			default:
				break;
		}
	}

	function preSelectRows(gridApi){
		const selectedIds = props.selectedLineItems.map(item => item.id)
		gridApi.api.getRenderedNodes().forEach(node => {
			node.setSelected(selectedIds.includes(node.data.id));
		});
	}

	function onGridReady(params){
		setGridApi(params)
		preSelectRows(params)
		loadInitialState (params)
	}

	useEffect(() => {
		if(!gridApi) return
		preSelectRows(gridApi)
	}, [props.selectedLineItems])

	useEffect(()=>{
		if(props.saving && gridApi){
			gridApi.api.stopEditing()
			if(props.setSaving) props.setSaving(false)
		}
	},[props.saving])

	function setEditing(params){
		if(params.data.requested && props.editableCols.map(col => col.name).includes(params.colDef.field)){
			return true;
		}
		return false;
	}

	const getSerialNumberSuggestions = _.debounce((value, callback)=>{
		console.log('getSerialNumberSuggestions');
		if(value == ''){
			return
		}
		axios.post(`/quotes/${props.quote_id}/suggestion/serial`,{
			search_query: value,
			model: props.selectedModel,
			asset_type: props.assetType
		})
		.then(res => {
			const {suggestions} = res.data.data;
			setSuggestions(currSuggestions => {
				return {...currSuggestions, serial_number: suggestions}
			});
			callback(suggestions);
		})
		.catch(err => console.log(err))
	}, 250, { 'maxWait': 1000 })

	const getSkuSuggestions = _.debounce((field, value, callback)=>{
		console.log('getSkuSuggessions');
		if(value == ''){
			return
		}
		axios.post(`/quotes/${props.quote_id}/suggestion/sku`,{
			search_query: value,
			sku_name: field,
			asset_type: props.assetType
		})
		.then(res => {
			const {suggestions} = res.data.data;
			setSuggestions(currSuggestions => {
				return {...currSuggestions, [field]: suggestions}
			});
			callback(suggestions);
		})
		.catch(err => console.log(err))
	}, 250, { 'maxWait': 1000 })

	const getServiceSuggestions = _.debounce((value, callback)=>{
		if(value == ''){
			return
		}
		axios.post(`/quotes/${props.quote_id}/suggestion/service`,{
			search_query: value
		})
		.then(res => {
			const {suggestions} = res.data.data;
			setSuggestions(currSuggestions => {
				return {...currSuggestions, service_name: suggestions}
			});
			callback(suggestions);
		})
		.catch(err => console.log(err))
	}, 250, { 'maxWait': 1000 })

	const getSuggestions = (field, value, callback) => {

		switch(field){
			case 'serial_number':
				getSerialNumberSuggestions(value, callback);
				break;
			case 'service_name':
				getServiceSuggestions(value, callback)
			// case 'software_subscription_sku':
			case 'asset_sku':
			case 'software_license_sku':
			case 'hardware_sku':
				getSkuSuggestions(field, value, callback)
		}
	}

	function onSuggestionSelect(field, params, selected, callback){
		console.log({field, params, selected, callback})
		switch(field){
			case 'serial_number':{
				onSerialSuggestionSelect(field, params, selected, callback);
				break;
			}
			case 'service_name':{
				onServiceNameSuggestionSelect(field, params, selected, callback);
				break;
			}
			// case 'software_subscription_sku':
			case 'asset_sku':
			case 'software_license_sku':
			case 'hardware_sku':
				onSkuSuggestionSelect(field, params, selected, callback);
				break;
		}
	}

	function onSerialSuggestionSelect(field, params, selected, callback){
		// console.log(index, serialNumberSuggestions);
		setSelectedSerialSuggestion(selected);
		callback(selected.serial_number);
		props.setEditedLineItem(field, params.rowIndex, selected);
	} 

	function onServiceNameSuggestionSelect(field, params, selected, callback){
		// console.log(index, serialNumberSuggestions);
		setSelectedServiceNameSuggestion(selected);
		callback(selected.serial_number);
		props.setEditedLineItem(field, params.rowIndex, selected);
	} 

	function onSkuSuggestionSelect(field, params, selected, callback){
		// console.log(index, serialNumberSuggestions);
		setSelectedSkuSuggestion(selected);
		callback(selected.serial_number);
		props.setEditedLineItem(field, params.rowIndex, selected);
	} 

	const handleRowDataChanged = ({api}) => {
		console.log(props.listItems.length)
		//to scroll table to bottom when new line item is being added
		api.ensureIndexVisible(props.listItems.length - 1, 'bottom')
	}

	const exceptCols = props.exceptCols ? props.exceptCols : [];
	
	const windowHeight = window.innerHeight- 500;

	function loadInitialState (params){

		const table_name =  'QUOTE_DETAIL_ADD_LINE_ITEM';

        axios.get(`/user-view/show-view-order?table_name=${table_name}`)
        .then(results=>{
            const { data } = results.data;
            const order = JSON.parse(data.order);
            params.columnApi.applyColumnState(order);
        })
        .catch(err=>{
            console.log(err);
        })
    }

    function saveView (rows){
        const order = JSON.stringify(rows);
        axios.post('/user-view/save-view-order',{
            table_name : 'QUOTE_DETAIL_ADD_LINE_ITEM',
            order : order
        })
        .then((results)=>{
			console.log('view updated')
        })
        .catch(err=>{
            console.log(err);
        })
    }

    function onColumnMoved(params){
		const rows = params.columnApi.getColumnState();
        saveView(rows);
	}

    function onColumnPinned(params){
		const rows = params.columnApi.getColumnState();
        saveView(rows);
	}

    function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	return (
			
			// <FuseScrollbars className="flex-grow overflow-x-auto">
				<div className="ag-theme-alpine" style={{width: "100%",height:windowHeight}}>
                    <AgGridReact
						ref={props.grid}
						// domLayout={'autoHeight'}
						rowData={props.listItems}
						editType="fullRow"
						onRowClicked={e => {if(props.onRowClick){props.onRowClick(e.data.id)}}}
						suppressRowClickSelection={true}
						rowSelection={'multiple'}
						onRowSelected={props.onRowSelected}
						onSelectionChanged={props.onSelectionChanged}
						getRowStyle={applyStyleToRows}
						onGridReady={onGridReady}
						frameworkComponents={{
							autoSuggestionEditor,
							textEditor,
							rowEditToggleRenderer
						}}
						defaultColDef={{
							resizable: true
						}}
						
						//called when number of rows changed
						onRowDataChanged={handleRowDataChanged}
						onColumnMoved = { onColumnMoved }
						onColumnVisible = { onColumnVisible }
						onColumnPinned = { onColumnPinned }
						suppressDragLeaveHidesColumns={true}

						>
                        {Object.keys(props.listItems[0]).filter(header => !exceptCols.includes(header)).map(header => {
							
							let editor = 'textEditor';

							const col = props.editableCols.find(col => col.name === header);

							if(col){
								switch(col.type){
									case 'select':
										editor = 'autoSuggestionEditor'
										break;
									case 'text':
										editor = 'textEditor'
										break;
									default:
										break;
								}
							}

                            if(header != 'id' && header !== 'requested'){
								if(['hardware_sku','software_subscription_sku','sku','software_sku','asset_sku'].includes(header)){
									return (<AgGridColumn 
										lockPosition={true}
										field={header} 
										editable={setEditing}
										headerName={getAbbreviatedCapitalized(header)}  
										sortable={ true } filter={ true }
										headerCheckboxSelection={true}
										headerCheckboxSelectionFilteredOnly={true}
										cellRenderer="rowEditToggleRenderer"
										cellEditor={editor}
										cellEditorParams={{
											updateColValue: props.updateColValue,
											suggestions: suggestions,
											getSuggestions: getSuggestions,
											onSuggestionSelect: onSuggestionSelect,
											suggestionField: header
										}}
										cellRendererParams={{
											startEditing: (rowIndex)=>{
												props.editableCols.forEach(col => {
													props.grid.current.api.startEditingCell({
														rowIndex,
														colKey: col.name
													})
												})
											}
										}}
										checkboxSelection={(params)=>{
											if(params.node.data.requested || params.node.data.request){
												return false;
											}
											return true;
										}}
										
										>
                                        </AgGridColumn>)
								}
								return (<AgGridColumn 
									field={header} 
									headerName={getAbbreviatedCapitalized(header)}  
									sortable={ true } filter={ true }
									editable={setEditing}
									cellEditor={editor}
									cellEditorParams={{
										suggestions: suggestions,
										getSuggestions: getSuggestions,
										onSuggestionSelect: onSuggestionSelect,
										suggestionField: header,
										updateColValue: props.updateColValue
									}}
									>
									</AgGridColumn>)
                            }
                        })}
                    </AgGridReact>
                </div>
			// </FuseScrollbars>
			
	);

}

export default ListView
