import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { Autocomplete } from "@material-ui/lab";
import { moduleOptions,reportOptions } from './constants';
import useToast from '@fuse/hooks/useToast';
import { useDispatch } from 'react-redux';
import { usePermission } from '@fuse/hooks';
import { setSelectedModule } from '../../store/filterParamsReducer'


import {
  Button,
  TextField,
  Typography
} from "@material-ui/core";


const Header = ({ setStandardReportProps }) => {
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const [ selectedModule, setSelectedModules ] = useState(null);
  const [ selectedReport, setSelectedReport ] = useState(null);
  const { hasPermission } = usePermission()
  let viewAssetPermission = hasPermission('view-assets',true)

  function handleGenerateReport(){
    if(!selectedModule){
      toast.error('Please select a module')
      return
    }
    if(!selectedReport){
      toast.error('Please select a report')
      return
    }

    setStandardReportProps({
      moduleName: selectedModule.name,
      reportName: selectedReport.name
    })

  }

  return (
    <div className='flex justify-between items-center'>
      <div>
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold whitespace-nowrap"
          style={{ color: 'black' }}
        >
          Select Module and Report
        </Typography>
      </div>

      <div className='flex justify-end w-full'>
        <div style={{ marginBottom: "10px", width: "30%",marginRight: 15 }}>
          <Autocomplete
            style={{ width: "100%" }}
            options={(viewAssetPermission != 'all' && viewAssetPermission?.length == 0) ? moduleOptions.slice(1): moduleOptions}
            disablePortal
            getOptionLabel={option => option.displayName}
              onChange={(_, value) => {
                setSelectedModules(value)
                setSelectedReport(null);
                setStandardReportProps(null)
                dispatch(setSelectedModule(value))
              }}
            renderInput={(params) => {
              return (
                <div ref={params.InputProps.ref}>
                  <TextField
                    {...params}
                    label="Select module"
                    variant="outlined"
                    fullWidth
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              );
            }}
          />
        </div>

        <div style={{ marginBottom: "10px", width: "30%",marginRight: 15 }}>
          <Autocomplete
            style={{ width: "100%" }}
            options={reportOptions[selectedModule?.name] ?? []}
            disablePortal
            value={selectedReport}
            onChange={(_, value) => {
              setSelectedReport(value);
            }}
            getOptionLabel={option => option.displayName}
            renderInput={(params) => {
              return (
                <div ref={params.InputProps.ref}>
                  <TextField
                    {...params}
                    label="Select report"
                    variant="outlined"
                    fullWidth
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              );
            }}
          />
        </div>

        <div>
          <Button 
            style={{
              background: '#6B2C57',
              height: 50,
              color:'#fff',
              borderRadius:5,
              padding: '0 20px'
            }}
            onClick={handleGenerateReport}
          >
            Generate Report
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Header
