import FusePageSimple from '@fuse/core/FusePageSimple'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import axios from "@fuse/utils/axios";
import { useDispatch, useSelector } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import useRouter from '@fuse/hooks/useRouter';
import { useCurrentUser, usePageTitle } from '@fuse/hooks';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'

import Header from './header'

const useStyles = makeStyles({
    layoutRoot:{
        background:'#fcfcfc'
    },
    noDisplayText: {
		textAlign: 'center',
		marginTop: '5vh'
	},
    topBg:{
        background:'#fcfcfc'
    },
    title:{
		fontSize: 14,
		fontWeight: 600,
		marginBottom:15
	},
    content:{
		padding:'2%',
        margin:'1rem auto'
	},
    root:{
        width:'97%',
        borderRadius:'4px',
        margin:'auto',
    },
    row:{
        display:'flex',
        marginBottom:'1rem'
    },
    box:{
        width: '80%',
        height: '40px',
        borderRadius: '4px'
    },
    txt:{
        padding: '10px',
        height:'40px'
    },
    marginDiv:{
        width: '95%',
        margin: '2rem auto',
        borderTop: '1px solid black'
    },
    textArea: {
        width: '70%',
        border:'1px solid gray',
        borderRadius: '4px',
        padding:'1rem'
    }
})

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#888888',
      color: 'rgba(D, D, D, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(15),
      border: '1px solid #dadde9',
      borderRadius: '5px'
    },
}))(Tooltip);

const requestQuote = () => {
	const dispatch = useDispatch();
    const toast = useToast(dispatch)
    const classes = useStyles()
    const [containerState, setContainerState] = useState([{id : 1, assetName:'', sku: '', query:'', disabled:false}])
    const [selected, setSelected] = useState('New')
    const [options, setOptions] = useState([])
    const [contractOptions, setContractOptions] = useState([])
    const [state, setState] = useState({})
    const [contractState, setContractState] = useState({})
    const [contractContainer, setContractContainer] = useState([{end_date:'', status: '', query:''}])
    const [comment, setComment] = useState('')
    const updatePageTitle = usePageTitle(dispatch);
    const router = useRouter()

    const {data:userData} = useCurrentUser()

    if(userData.email === 'sonder.phi@gmail.com'){
        return (
            <Typography variant='subtitle1' className={classes.noDisplayText}>
                You have no quotes to show!
            </Typography>
        )
    }

    const handleClick = () => {
        const id = containerState[0].id + 1

        setContainerState((items) => {
            return [{
                id,
                assetName : '',
                sku : '',
                query : ''
            }, ...items]
        })
    }

    const handleRadioChange = (e) => {
        setSelected(e.target.value)
    }

    const handleQueryInput = (value, idx, input) => {

        setContainerState((items) => {
            items[idx][input] = value
            
            if(value == '')
            {
                items[idx].sku = value
                items[idx].assetName = value
                items[idx].disabled = false
            }
            return[...items]
        })

        if(value)
        {
            axios.get(`/quotes/assets/get-suggestions?query=${value}`).then( res => {
                const {serial_numbers, suggestions} = res.data.data
                setOptions(serial_numbers)
                setState(suggestions)
            }).catch(err => {
                console.log(err)
            })
        }
        else
        {
            setOptions([])
        }
        
    }

    const handleContractQueryInput = (value) => {

        setContractContainer((item) => {
            item[0].query = value
            if(value == '')
            {
                item[0].end_date = value
                item[0].status = value
            }
            return [...item]
        })

        if(value)
        {
            axios.get(`/quotes/contract/get-suggestions?query=${value}`).then( res => {
                const {contractNumbers, suggestions} = res.data.data
                setContractOptions(contractNumbers)
                setContractState(suggestions)
            }).catch(err => {
                console.log(err)
            })
        }
        else
        {
            setContractOptions([])
        }
        
    }

    const handleSearchClick = (idx) => {
        setContainerState((items) => {
            let value = items[idx].query

            if(value)
            {
                if(!state[value])
                {
                    toast.error("Serial number doesn't exists")
                    items[idx].assetName = ''
                    items[idx].sku = ''
                }
                else
                {
                    items[idx].assetName = state[value].asset_name
                    items[idx].sku = state[value].asset_sku
                    items[idx].disabled = true
                }
            }
            else
            {
                items[idx].assetName = ''
                items[idx].sku = ''
            }
            
            return[...items]
        })
    }

    const handleContractSearchClick = (idx=0) => {
        setContractContainer((items) => {
            const value = items[idx].query
            if(value)
            {
                if(!contractState[value])
                {
                    toast.error("Contract number doesn't exists")
                    items[idx].end_date = ''
                    items[idx].status = ''
                }
                else
                {
                    items[idx].end_date = dateReformatTo_mmm_dd_yyyy(contractState[value].end_date)
                    items[idx].status = contractState[value].status
                }
            }
            else
            {
                items[idx].end_date = ''    
                items[idx].status = ''
            }
            return [...items]
        })
    }

    const handleRequestSubmit = () => {

        let serial_numbers = []
        let asset_sku = []
        let asset_name = []
        for(let cont of containerState)
        {
            if(cont.query == '')
            {
                toast.error('Please select an asset.')
                return false
            }
            else
            {
                if(cont.sku == '')
                {
                    toast.error(`Please add SKU for serial number ${cont.query}`)
                    return false
                }
                else if(cont.assetName == '')
                {
                    toast.error(`Please add asset name for serial number ${cont.query}`)
                    return false
                }
            }
            serial_numbers.push(cont.query)
            asset_sku.push(cont.sku)
            asset_name.push(cont.assetName)
        }

        if(selected == "Existing" && (contractContainer[0].query == "" || contractContainer[0].end_date == "" || contractContainer[0].status == ""))
        {
            toast.error(`Please select a contract to proceed`)
            return false
        }

        const data = {
            serial_numbers,
            asset_name,
            asset_sku,
            contract_id : contractContainer[0].query,
            contract_type : selected,
            comment
        }

        axios.post('/quotes/request/save-request', data).then( res => {
            const {data} = res

            if(data.statusCode == 200)
            {
                toast.success('Request submitted')
                // router.push('/view-request-quote')
                setContainerState([{id : 1, assetName:'', sku: '', query:'', disabled:false}])
                setSelected('New')
                setComment('')
            }
        }).catch( err => {
            console.log(err)
        })

        console.log(data)
    }

    const handleRemoveClicked = (idx) => {

        if(containerState.length == 1)
            return false
        
        setContainerState(items => {
            
            items.splice(idx, 1)

            return [...items]
        })
    }

    const Title = (props)=>{
		return(
		<>
			<span style={{fontWeight: 'bold'}}>Request Quote</span>
			<span> </span>
		</>
		)
	}

    useEffect(()=>{
        updatePageTitle(<Title/>)
    },[])

    return (
        <FusePageSimple
            classes ={{
                root:classes.layoutRoot,
                topBg:classes.topBg
            }} 
            // header={
            //     <Header />
            // }
            content={
                <Paper square style={{borderRadius:'4px'}} className={classes.root}>
                    <div className={classes.content}>
                        <Typography className={classes.title}>
                            Device Information
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                                {
                                    containerState.map( (obj, index) => {
                                        return(
                                            <Grid container spacing={1} key={obj.id} className={classes.row}>
                                                <Grid item xs={6} sm={4}>
                                                    <div style={{border:'0.5px solid gray', borderRadius:'4px', width:'100%', display:'flex', margin:'auto'}}>
                                                        <Autocomplete
                                                            id="service-search-box"
                                                            options={options}
                                                            style={{width:'100%'}}
                                                            className={classes.txt}
                                                            inputValue={obj.query}
                                                            freeSolo={true}
                                      
                                                            noOptionsText="No suggestion found"
                                                            onInputChange={(e, value) => {
                                                                handleQueryInput(value, index, 'query')
                                                            }}
                                                            renderInput={(params) => {
                                                                return(
                                                                <div 
                                                                ref={params.InputProps.ref}>
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Search Serial No."
                                                                    InputProps={{
                                                                        disableUnderline: true
                                                                    }}
                                                                    
                                                                />
                                                                </div>
                                                            )}}
                                                        />
                                                        <Button
                                                            startIcon={<SearchIcon />}
                                                            onClick={() => handleSearchClick(index)}
                                                        >
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} sm={4}>
                                                    <HtmlTooltip title={obj.sku} arrow placement="top">
                                                        <div style={{border:'0.5px solid gray', borderRadius:'4px', width:'100%'}}>
                                                            
                                                                <Input label="" disabled={obj.disabled} disableUnderline={true} placeholder="Asset SKU" value={obj.sku} className={classes.txt} variant="outlined" onChange={(e) => {
                                                                        handleQueryInput(e.target.value, index, 'sku')
                                                                }}/>
                                                            
                                                        </div>
                                                    </HtmlTooltip>
                                                </Grid>
                                                <Grid item xs={12} sm={4} style={{display:'flex'}}>
                                                    <HtmlTooltip title={obj.assetName} arrow placement="top">
                                                        <div style={{border:'0.5px solid gray', borderRadius:'4px', width:'80%'}}>
                                                            <Input label="" disabled={obj.disabled} disableUnderline={true} placeholder="Asset Name" value={obj.assetName} className={classes.txt} variant="outlined" onChange={(e) => {
                                                                    handleQueryInput(e.target.value, index, 'assetName')
                                                            }}/>
                                                        </div>
                                                    </HtmlTooltip>
                                                    {
                                                        <IconButton color="primary" aria-label="remove" component="span" onClick={ () => handleRemoveClicked(index)}>
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                    }
                                                    
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    className={classes.box}
                                    onClick={handleClick}
                                >
                                    Add More
                                </Button>
                            </Grid>
                        </Grid>

                        <div className={classes.marginDiv}>
                            {/* <Divider />
                            <Divider /> */}
                        </div>

                        <Typography className={classes.title}>
                            Contract Information
                        </Typography>
                        <FormControl component="fieldset">
                            <RadioGroup row aria-label="position" name="position" onChange={handleRadioChange} value={selected}>
                                <FormControlLabel value="New" control={<Radio color="primary" />} label="New Contract" />
                                <FormControlLabel value="Existing" control={<Radio color="primary" />} label="Existing Contract" />
                            </RadioGroup>
                        </FormControl>
                        {
                            selected == "Existing" && <Grid container spacing={3} style={{marginTop:'5px'}}>
                                <Grid item xs={6} sm={4}>
                                    <div style={{border:'0.5px solid gray', borderRadius:'4px', width:'100%', display:'flex', margin:'auto'}}>
                                        <Autocomplete
                                            style={{width:'90%'}}
                                            id="service-search-box"
                                            options={contractOptions}
                                            className={classes.txt}
                                            inputValue={contractContainer[0].query}
                                            freeSolo={true}
                                            noOptionsText="No suggestion found"
                                            onInputChange={(e, value) => {
                                                handleContractQueryInput(value)
                                            }}
                                            renderInput={(params) => {
                                                return(
                                                <div 
                                                ref={params.InputProps.ref}>
                                                <TextField
                                                    {...params}
                                                    placeholder="Search Contract No."
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                    
                                                />
                                                </div>
                                            )}}
                                        />
                                        <Button
                                            startIcon={<SearchIcon />}
                                            onClick={() => handleContractSearchClick()}
                                        >
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <div style={{border:'0.5px solid gray', borderRadius:'4px', width:'100%'}}>
                                        <Input label="" disabled disableUnderline={true} value={contractContainer[0].end_date} placeholder="Expire On" className={classes.txt} variant="outlined"/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div style={{border:'0.5px solid gray', borderRadius:'4px', width:'100%'}}>
                                        <Input label="" disabled disableUnderline={true} value={contractContainer[0].status} placeholder="Status" className={classes.txt} variant="outlined"/>
                                    </div>
                                </Grid>
                            </Grid>
                        }
                        <div className={classes.marginDiv}>
                        </div>

                        <Typography className={classes.title}>
                            Your Comment
                        </Typography>
                        <div className={classes.textArea}>
                            <TextareaAutosize
                                rowsMin={6}
                                rowsMax={6}
                                aria-label="your comment"
                                placeholder="Your Comment"
                                value={comment}
                                onChange={(e) => {setComment(e.target.value)}}
                                style={{width:'100%'}}
                            />
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        style={{float:'right', borderRadius:'4px', width:'150px'}}
                        onClick = {handleRequestSubmit}
                    >
                        Submit
                    </Button>
                </Paper>
            }
        />
    )
}

export default requestQuote