import React, { useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
// import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import ColorPicker from '@fuse/components/ColorPicker'
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))



const AddPaymentTermFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)


    const defaultValues = {
        paymentTerm: '',
        description: '',
        termDuration: ''
    }

    const schema = yup.object({
        paymentTerm: yup.string().trim().required('Payment Term is required!'),
        description: yup.string().nullable(),
        termDuration: yup.string().trim().required('Term Duration is required!')
    })


    const { 
        control, 
        formState:{errors: paymentTermFormErrors}, 
        handleSubmit: paymentTermSubmit, 
        trigger,
        reset: paymentTermFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });





    const onSubmit = async(data) => {
        const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			paymentTerm: data.paymentTerm,
            description: data.description.trim(),
			term_duration: data.termDuration,
		}

		axios.post('/payment-term/add', payload).then(res => {
			if(res.status === 201){
				toast.error(res.data.message)
                return
			}
            toast.success('Payment Term added successfully')
            newDataAddedEvent(res.data.data)
		}).catch(err => {
            console.log('error: ',err);
			toast.error(err.response.data.message)
		})
    }

    return (
        <Dialog
            className="cstm_dialog_modal small text-center"
            open={openAddStatusModalStateData}
            // onClose={handleOpenCloseAddModalEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div className='heading_holder flexbox_holder items-center w-full'>
                    <h2 className="main_heading">Add Payment Term</h2>
                    <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                        <i className="icon ti ti-x" />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-left">
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Payment Term Name
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="paymentTerm"
                            // className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='Status Name'
                                        required
                                        fullWidth
                                        error={!!paymentTermFormErrors.paymentTerm}
                                        helperText={paymentTermFormErrors?.paymentTerm?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Description
                        </Typography>
                        <Controller
                            name='description' 
                            control={control}
                            render={( { field} ) => (
                                <>
                                    <TextField 
                                        {...field}
                                        style={{background: 'white',marginBottom: '10px'}}
                                        variant='outlined'
                                        // label='Status Description' 
                                        autoFocus
                                        fullWidth
                                        InputProps={{
                                            inputComponent: TextareaAutosize,
                                        }}
                                        // required
                                        error={!!paymentTermFormErrors.description}
                                        helperText={paymentTermFormErrors?.description?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                            )}
						/>
                    </div>



                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Term Duration(months)
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller 
                            name='termDuration'
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    variant='outlined'
                                    required
                                    // label="Term Duration(months)"
                                    fullWidth
                                    onKeyPress={(event) => {
                                        if (!/[0-9]+/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                    error={!!paymentTermFormErrors.termDuration}
                                    helperText={paymentTermFormErrors?.termDuration?.message}
                                    FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px',
                                            fontSize: '10px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="action_footer_holder">
                <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                <Button onClick={paymentTermSubmit(onSubmit)} className="primary_btn">
                    Save and Select
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default AddPaymentTermFromQuickAction