import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useRouter from '@fuse/hooks/useRouter';

function Header() {

	const router = useRouter();
	const moduleState = useSelector(({modulesApp}) => modulesApp.moduleReducer);
	const id = router.params.id;

	const moduleName = moduleState.modules[id-1]['label']

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex flex-col items-start max-w-full min-w-0" style={{margin: 10}}>
				<div>
					<Typography
						className="flex items-center sm:mb-12"
						component={Link}
						role="button"
						to={`/module-setting`}
						color="inherit"
					>
						<Icon className="text-20">arrow_back</Icon>
						<span className="hidden sm:flex mx-4 font-medium">Back to modules</span>
					</Typography>
				</div>

				<div className="flex items-center max-w-full">
					<Icon
						initial={{ scale: 0 }}
						animate={{ scale: 1, transition: { delay: 0.2 } }}
						className="text-24 md:text-32"
					>
						apps
					</Icon>
					<Typography
						initial={{ x: -20 }}
						animate={{ x: 0, transition: { delay: 0.2 } }}
						delay={300}
						className="hidden sm:flex text-16 md:text-24 mx-12 font-semibold"
					>
						Re-order table - {moduleName}
					</Typography>
				</div>
			</div>
		</div>
	);
}

export default Header;
