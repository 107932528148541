export function formatPrice(price,currency='USD'){
    if(!Boolean(price) || typeof price !== 'number') return
    
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
    });
    return formatter.format(price);
}

export function formatPriceFieldOnBlur(price){
    if(!price) return
    const value = price.replace(/[,]/g, '');
    const formattedValue = parseFloat(value).toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
    return formattedValue;
}