const initialState = {
    filter : {},
    rowGroupModel : [],
    query: null,
    assets : [],
    custom_filter_data: null
};

const actionTypes = {
    SET_FILTER_LIST : 'SET_ASSET_FILTER_LIST',
    SET_ROW_GROUP_MODEL : 'SET_ROW_GROUP_MODEL',
    FILTER_ASSET_DATA: 'FILTER_ASSET_DATA',
    ASSET_PROFILE_REDIRECTION_FROM_MANUFACTURER: 'ASSET_PROFILE_REDIRECTION_FROM_MANUFACTURER',
    SET_CUSTOM_FILTER_DATA: 'SET_CUSTOM_FILTER_DATA'
}

export function setfilterlist(data){
    return {
        type:actionTypes.SET_FILTER_LIST,
        payload: {
            data
        }
    }
}

export function setCustomFilterData (data){
    return {
        type:actionTypes.SET_CUSTOM_FILTER_DATA,
        payload: {
            data
        }
    }
}

export function setfilterQuery(query = null){
    return {
        type:actionTypes.FILTER_ASSET_DATA,
        payload: {
            query
        }
    }
}

export function setrowGroupModel (data){
    return {
        type:actionTypes.SET_ROW_GROUP_MODEL,
        payload: {
            data
        }
    }
}

export function setAssetsForRedirection (data){
    return {
        type:actionTypes.ASSET_PROFILE_REDIRECTION_FROM_MANUFACTURER,
        payload: {
            data
        }
    }
}

export default function (state = initialState , action){
    switch(action.type){
        case actionTypes.SET_FILTER_LIST : {
            return {
                ...state,
                filter : {...action.payload.data}
            }
        }
        case actionTypes.FILTER_ASSET_DATA : {
            return {
                ...state,
                query: action.payload.query
                // query : {...action.payload.query}
            }
        }
        case actionTypes.SET_ROW_GROUP_MODEL :{
            return {
                ...state,
                rowGroupModel : [...action.payload.data]
            }
        }
        case actionTypes.ASSET_PROFILE_REDIRECTION_FROM_MANUFACTURER :{
            return {
                ...state,
                assets : [...action.payload.data]
            }
        }
        case actionTypes.SET_CUSTOM_FILTER_DATA :{
            return {
                ...state,
                custom_filter_data : action.payload.data
            }
        }
        default : return state
    }
}