import useToast from '@fuse/hooks/useToast'
import {
	makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer'
import { useCurrentUser, useRouter } from '@fuse/hooks'
import axios from "@fuse/utils/axios"
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Editor from '@fuse/components/Editor';
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AlertModal from 'helper/alertModal'


const useStyles = makeStyles(theme => (
	{
			uploadImageButton: {
					padding: '6px 16px',
					fontSize: '1.3rem',
					minWidth: '64px',
					boxSizing: 'border-box',
					transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					fontFamily: '"Inter", sans-serif',
					fontWeight: 500,
					lineHeight: 1.75,
					borderRadius: '4px',
					textTransform: 'none',
					backgroundColor: '#6B2C57',
					color: '#ffffff',
					cursor: 'pointer'
			},
			loader: {
					display: 'flex',
					'& > * + *': {
							marginLeft: theme.spacing(2),
					},
			},
			subSection: {
					marginLeft: '20px',
					marginRight: '20px',
					marginBottom: '20px',
					padding: '10px',
					backgroundColor: '#F9FAFB',
					borderRadius: '4px'
			},
			endButtons: {
					borderRadius: '4px'
			},
			drawerWidth: {
					width: 520,
					borderTopLeftRadius: '8px',
					borderBottomLeftRadius: '8px',
					overflowX: 'hidden',
					overflowY: 'hidden'
			},
	}
))

function AddLocationTypeFormDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const router = useRouter()
	const currentUser = useCurrentUser();
	const openState = useSelector((state) => state.LocationTypeApp.formViewDialog)
	const [loading, setLoading] = useState(false)
	const [locationType, setLocationType] = useState('')
	const [description, setDescription] = useState('')
	const { data: editFromState } = useSelector((state) => state.LocationTypeApp.formViewDialog);
  const [ locationTypeError, setLocationTypeError ] = useState('')
  const [openAlerModal, setOpenAlertModal] = useState(false);


	function setDefault(){
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setLocationType('')
		setLocationTypeError('')
	}

	const onCancel = () => {
		setDefault()
	}

	//form validation
	// const schema = yup.object({ })

	// setting default values of accessory
	// const defaultValues = {
	// 	location_type: '',
	// }

	// const { control, formState, handleSubmit, reset, setValue, getValues } = useForm({
	// 	mode: 'onChange',
	// 	defaultValues,
	// 	resolver: yupResolver(schema)
	// });

	function onEditorContentChange (content) {
		setDescription(content)
	}

	const handleSubmit = ()=>{
		// e.preventDefault()
		if(locationType.trim() === "") {
			setLocationTypeError('Location Type is required!')
			return
		}
		setLocationTypeError('')
		let payload = {
			name: locationType.trim(),
			description: description	
		}
		
		if(editFromState){
			payload.id = editFromState.id
			axios.post(`/location-type/edit`, payload, {
			}).then(results=>{
				toast.success(results.data.message)
				setDefault()
				props.getLocationTypes()
			}).catch(err=>{
				console.log(err)
				toast.error(err.response.data.message);
			})
		} else {
			axios.post(`/location-type/add`, payload, {
			}).then(results=>{
				toast.success(results.data.message)
				setDefault()
				props.getLocationTypes()
			}).catch(err=>{
				console.log(err)
				toast.error(err.response.data.message);
			})
		}
    }

	useEffect(() => {
		if(editFromState){
			let descriptionForEdit;
			if(editFromState.description === 'No Description') {
				descriptionForEdit = "";
			} else {
				descriptionForEdit = editFromState.description;
			}
			setLocationType(editFromState.location_type)
			setDescription(descriptionForEdit)
		}
	}, [])

	const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        onCancel()
    }

	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>
					<h4 className='heading'>{editFromState?'Update':'Add'} Location Type</h4>
					<IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
							<Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
					</IconButton>
        </div>
				<div className='progressbar_holder'>
					<div className='progress'>
							&nbsp;
					</div>
				</div>
					<div style={{paddingTop:'30px'}} className='cstm_wizard_holder'>
						<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder'>
								<div className='content_holder'>
									<div className="text-left">
										<div className='mb-10'>
											<Typography className='status_text'>
													Location Type Name
													<span className='required_span'>*</span>
											</Typography>
											<TextField
													variant='outlined'
													value={locationType}
													error={locationTypeError === '' ? false : true}
													helperText={locationTypeError}
													FormHelperTextProps={{
															style: { 
																	margin : 0, 
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px',
																	fontSize: '10px'
															}
													}}
													style={{ marginBottom: '10px', width: '100%',background: 'white' }}
													onChange={(e)=>setLocationType(e.target.value)}
											/>  
										</div>

										<div className='mb-10'>
											<Typography className='status_text'>
													Description
													{/* <span className='required_span'>*</span> */}
											</Typography>
											<Editor
													editorContent={description}
													onEditorContentChange={onEditorContentChange}
											/> 
										</div>
									</div>
								</div>
								{/* <DialogActions className="action_footer_holder"> */}
								<div className='btn_holder'>
									<Button
										color="inherit"
										onClick={handleAlertModal}
										className='back_btn secondary_btn'
									>
									Cancel
									</Button>
									<Button onClick={handleSubmit} className='next_btn'>                                                
										Proceed
									</Button>
								</div>
							</div>
						</Box>
					</div>
			</Drawer>

			{openAlerModal &&
                <AlertModal  openAlerModal module="location type" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
		</div>
	)
}

export default AddLocationTypeFormDialog
