import { combineReducers } from '@reduxjs/toolkit';
import reportViewDialogReducer from './reportViewDialogReducer';
import filterParamsReducer from './filterParamsReducer';
import formViewDialog from './formViewDialogReducer';
import csvDialogReducer from './csvDialogReducer';
import imageDialogReducer from './imageDialogReducer';

const reducer = combineReducers({
	filterParamsReducer,
	reportViewDialogReducer,
	formViewDialog,
	csvDialogReducer,
	imageDialogReducer,	
});

export default reducer;
