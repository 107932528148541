import React from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { Button, DialogTitle, TextField, Typography, Dialog,DialogContent, DialogActions } from '@mui/material'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))



const AddServiceLevelAgreementFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {


    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)


    const schema = yup.object({
        serviceLevelAggrement: yup.string().trim().required('Service Level Agreement is required!'),
        description: yup.string()
    })

    const defaultValues = {
        serviceLevelAggrement: '',
        description: ''
    }

    const { 
        control, 
        formState:{errors}, 
        handleSubmit, 
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const onSubmit = async(data) => {
        const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			sla: data.serviceLevelAggrement.trim(),
            description: data.description.trim()
		}

		axios.post('/service-level-agreement/add', payload).then(res => {
			if(res.status === 201){
				toast.error(res.data.message)
                return
			}
            
            toast.success('Service Level Agreement added successfully')
            newDataAddedEvent(res.data.data)
		}).catch(err => {
            console.log('error: ',err);
			toast.error('Something went wrong!')
		})
    }

    return (
        <>
            <Dialog
                className="cstm_dialog_modal small text-center"
                open={openAddStatusModalStateData}
                // onClose={handleOpenCloseAddModalEvent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='heading_holder flexbox_holder items-center w-full'>
                        <h2 className="main_heading">Add Service Level Agreement</h2>
                        <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                            <i className="icon ti ti-x" />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-left">
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Service Level Agreement
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="serviceLevelAggrement"
                                // className="mb-16"
                                defaultValue={''}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            variant='outlined'
                                            // label='Status Name'
                                            required
                                            fullWidth
                                            error={!!errors.serviceLevelAggrement}
                                            helperText={errors?.serviceLevelAggrement?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px',
                                                    fontSize: '10px'
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Description
                            </Typography>
                            <Controller 
                                name='description'
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        fullWidth
                                        multiline
                                        rows={8}
                                        inputProps={{ className: classes.textarea }}
                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                        error={!!errors.description}
                                        helperText={errors?.description?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="action_footer_holder">
                    <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                    <Button onClick={handleSubmit(onSubmit)} className="primary_btn">
                        Save and Select
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )


}

export default AddServiceLevelAgreementFromQuickAction;