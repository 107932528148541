import { Box, Breadcrumbs } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
	Route,
	Switch,
	Link as RouterLink
  } from "react-router-dom";
import Assets from './Assets/index';
import Contracts from './Contracts/index';
import Iot from './IoT/index';

function ListView(props) {
	const routes = ["/assets-dashboard", "/contracts-dashboard", "/iot-dashboard"];
	const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1).replace('-', ' ');
  

	return (
		<div>
			<Route
				path="/"
				render={(history) => (
					<>
						<div role="presentation" className="cstm_breadcrumbs_holder">
							<Breadcrumbs aria-label="breadcrumb">
							<span className='dashboard_breadcrumb' style={{ marginRight: '3px' }}>Dashboard </span>
							<span className='dashboard_breadcrumbTwo'>{getLastItem(history.location.pathname)}</span>
							</Breadcrumbs>
						</div>
						<Box className='cstm_tabs_holder'>
							<Tabs
								className='tabs_main_holder'
								value={
								history.location.pathname !== "/"
									? history.location.pathname
									: false
								}
							> 
								<Tab
									className='each_tab_holder'
									label="Assets"
									value={routes[0]}
									component={RouterLink}
									to={routes[0]}
								/>
								<Tab
									className='each_tab_holder'
									label="Contracts"
									value={routes[1]}
									component={RouterLink}
									to={routes[1]}
								/>
								<Tab
									className='each_tab_holder'
									label="IoT"
									value={routes[2]}
									component={RouterLink}
									to={routes[2]}
								/>
							</Tabs>
						</Box>
					</>
				)}
			/>
			<Switch>
				<Route path="/assets-dashboard" component={Assets} />
				<Route path="/contracts-dashboard" component={Contracts} />
				<Route path="/iot-dashboard" component={Iot} />
			</Switch>
		</div>
	);
}
export default ListView