const initialState = {
	open: false,
	mode: "create",
	asset_id:null
}

const actionTypes = {
    OPEN_CREATE_DIALOG: "OPEN_CREATE_DIALOG",
    CLOSE_CREATE_DIALOG: "CLOSE_CREATE_DIALOG",
	ADD_ASSET_ID: "ADD_ASSET_ID"
}

export function openCreateDialog(mode = "create"){
    return {
        type: actionTypes.OPEN_CREATE_DIALOG,
		payload: {
			mode
		}
    }
}

export function addAssetId(asset_id){
    return {
        type: actionTypes.ADD_ASSET_ID,
		payload: {
			asset_id
		}
    }
}

export function closeCreateDialog(mode = "create"){
    return {
        type: actionTypes.CLOSE_CREATE_DIALOG,
		payload: {
			mode
		}
    }
}

export default function (state = initialState, action) {
	switch (action.type) {
	  case actionTypes.OPEN_CREATE_DIALOG:{
		  return { 
			  ...state,
			  mode: action.payload.mode,
			  open: true,
		  }
	  }
	  case actionTypes.ADD_ASSET_ID:{
		return { 
			...state,
			asset_id: action.payload.asset_id,
		}
	}
	  case actionTypes.CLOSE_CREATE_DIALOG:{
			return { 
				...state,
				mode: action.payload.mode,
				open: false,
			}
	  }
	  default:
		return state
	}
}