import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core';
import React, { useState,useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Autocomplete } from '@material-ui/lab';
import Editor from '@fuse/components/Editor';
// import LocationTypeDialog from './QuickAddLocationType';
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const AddCategoryFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
})  => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const schema = yup.object({
        category_name: yup.string().trim().required('Category name is required!'),
        description: yup.string().nullable(),
    })

    const defaultValues = {
        category_name: '',
        description: ''
    }


    const { 
        control: categoryFormControl, 
        formState:{errors: categoryFormErrors}, 
        handleSubmit: categoryFormSubmit, 
        trigger,
        setValue,
        getValues,
        setErrors,
        reset: categoryFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    const onSubmit = async(data) => {
        await trigger();
        if(Object.keys(categoryFormErrors).length !== 0) { 
            return;
        }
        const payload = {
            entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
            category_name: data.category_name,
            category_type: 'hardware',
            description: data.description.trim()
        }

        axios.post('/category/add', payload).then(response => {
            // response.data.data.new_category
            if(response.status == 201){
                toast.error(response.data.message)
                return
            } 
            toast.success(response.data.message)
            categoryFormReset(defaultValues)
            newDataAddedEvent(response.data.data.new_category)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }




    return (
        <Dialog
            className="cstm_dialog_modal small text-center"
            open={openAddStatusModalStateData}
            // onClose={handleOpenCloseAddModalEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div className='heading_holder flexbox_holder items-center w-full'>
                    <h2 className="main_heading">Add Asset Category</h2>
                    <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                        <i className="icon ti ti-x" />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-left">
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Category Name
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="category_name"
                            // className="mb-16"
                            defaultValue={defaultValues.category_name}
                            control={categoryFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='Status Name'
                                        required
                                        fullWidth
                                        error={!!categoryFormErrors.category_name}
                                        helperText={categoryFormErrors?.category_name?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Description
                        </Typography>
                        <Controller
                            name='description'
                            defaultValue={defaultValues.description} 
                            control={categoryFormControl}
                            render={( { field} ) => (
                                <>
                                    <TextField 
                                        {...field}
                                        style={{background: 'white',marginBottom: '10px'}}
                                        variant='outlined'
                                        // label='Status Description' 
                                        autoFocus
                                        fullWidth
                                        InputProps={{
                                            inputComponent: TextareaAutosize,
                                        }}
                                        // required
                                        error={!!categoryFormErrors.description}
                                        helperText={categoryFormErrors?.description?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                            )}
						/>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="action_footer_holder">
                <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                <Button onClick={categoryFormSubmit(onSubmit)} className="primary_btn">
                    Save and Select
                </Button>
            </DialogActions>
        </Dialog>

    );

}

export default AddCategoryFromQuickAction;