import React,{useState,useEffect} from 'react'
import { 
    makeStyles,
    FormHelperText
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { comapreDates,getFormattedDate } from '@fuse/utils/dateOperations'
import { useDebounce } from '@fuse/hooks'

const useStyles = makeStyles(theme => ({  
    helperText: {
        color: 'red',
        paddingLeft: '1px'
    },
}))

/**
 * @param state type object has two property
 * 1. date: default null
 * 2. error: default false
 * @param setState type function
 * @param label type string
 * @param required type boolean default false
 * @param minDate type string optional expected format yyyy-mm-dd
 * @param maxDate type string optional expected format yyyy-mm-dd
 * @param condition type string optional expected to create the max or min date error message
 */

/**
 * Default behavior of KeyboardDatePicker
 * if maxDate is less than today's date then maxDate will be selected automatically
 * example if today is 20th august(08/20/2023) and maxDate is 19th august(08/19/2023)
 * then 08/19/2023 will be selected automatically
 * 
 * * if minDate is greater than today's date then minDate will be selected automatically
 * example if today is 20th august(08/20/2023) and minDate is 23rd august(08/23/2023)
 * then 08/23/2023 will be selected automatically
 */
function CustomDatePicker({
    state,
    setState,
    label,
    required,
    minDate,
    maxDate,
    condition
}) {

    const classes = useStyles()

    const [ inputValue,setInputValue ] = useState('');
    const [ errorMessage,setErrorMessage ] = useState('');

    const handleDebouncedInputValue = useDebounce(event => {
		setInputValue(event.target.value)
	}, 150);

    function handleStateChange ({key,val}) {
        setState((prev)=>{
            return {
                ...prev,
                [key]: val
            }
        })
    }

    function onKeyPressValidaion (e) {
        try {
            // const regex = /(0[1-9]|1[0-2])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d{2}/
            const regex1 = /(0[13578]|1[02])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d{2}/
            const regex2 = /(0[469]|1[1])[/](0[1-9]|[12][0-9]|3[0])[/](19|20)\d{2}/
            const regex3 = /(0[2])[/](0[1-9]|[12][0-9])[/](19|20)\d{2}/

            const value = inputValue;

            if(!value.length) return
            if(value.includes('_')){
                handleStateChange({
                    key: 'error',
                    val: true                    
                })
                setErrorMessage('Provide a valid date')
                return
            }else{
                handleStateChange({
                    key: 'error',
                    val: false                    
                })
                setErrorMessage('')                
            }

            // Check if date is valid or not
            // regex.test(value) return false if string dosen't match with regex
            if(!regex1.test(value) && !regex2.test(value) && !regex3.test(value)){
                handleStateChange({
                    key: 'error',
                    val: true                    
                })
                setErrorMessage('Provide a valid date')
                return
            }else{
                handleStateChange({
                    key: 'error',
                    val: false                    
                })
                setErrorMessage('')
            }
    
            //Check if date lesser than minDate
            if(minDate || null){
                const { hasError: lessThanVaildationFailed,
                    message: lessThanVaildationFailedMessage
                } = comapreDates(minDate,value,'is_less_than', label +' should not be before '+ condition);
                if(lessThanVaildationFailed){
                    handleStateChange({
                        key: 'error',
                        val: true                    
                    })
                    setErrorMessage(lessThanVaildationFailedMessage)
                    return
                }else{
                    handleStateChange({
                        key: 'error',
                        val: false                    
                    })
                    setErrorMessage(lessThanVaildationFailedMessage)
                }
            }
    
            //Check if date greater than maxDate
            if(maxDate || null){
                const { 
                    hasError: greaterThanVaildationFailed,
                    message: greaterThanVaildationFailedMessage
                } = comapreDates(maxDate, value, 'is_greater_than', label + ' should not be after ' + condition);
                if(greaterThanVaildationFailed){
                    handleStateChange({
                        key: 'error',
                        val: true                    
                    })
                    setErrorMessage(greaterThanVaildationFailedMessage)
                    return
                }else{
                    handleStateChange({
                        key: 'error',
                        val: false                    
                    })
                    setErrorMessage(greaterThanVaildationFailedMessage)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(()=>{
        if(!inputValue.length) { 
            handleStateChange({
                key: 'error',
                val: false                    
            })
            setErrorMessage('')    
            return
        }
        onKeyPressValidaion(inputValue);
    },[inputValue])

    return (
        <>
            <KeyboardDatePicker
                disableToolbar
                autoOk               
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                placeholder='mm/dd/yyyy'
                margin="normal"
                error={state.error}
                helperText=''
                minDate={minDate ?? undefined}
                maxDate={maxDate ?? undefined}
                InputProps={{
                    disableUnderline: true,
                    required: required || false ,  
                    onInput: handleDebouncedInputValue
                }}                
                style={{
                    backgroundColor: 'white',
                    marginTop: '2px',
                    marginBottom: '0px'
                }}
                id='date-picker-inline'
                label={label || ''}
                InputLabelProps={{ shrink: true }}
                value={state.date}
                onChange={(event)=>{
                    if(isNaN(event)) return
                    else if(!event){
                        handleStateChange({
                            key: 'date',
                            val: null
                        })
                        return
                    }
                    event.setHours(new Date().getHours())
                    event.setMinutes(new Date().getMinutes())
                    event.setSeconds(new Date().getSeconds())
                    handleStateChange({
                        key: 'date',
                        // val: ((new Date(event).toISOString()).split('T'))[0]
                        val: event
                    })
                    setInputValue(getFormattedDate((new Date(event).toISOString()).split('T')[0]))
                }}                              
                PopoverProps={{
                    disableRestoreFocus: true,                    
                }}
            />
            <FormHelperText className={classes.helperText}>{errorMessage}</FormHelperText>
        </>
    )
}

export default CustomDatePicker