import {useState} from 'react'
import {Button, Icon} from '@material-ui/core'

export default function ExpandButton(props){
    const {
        onClick=()=>{} ,icon, title , style={}
    } = props

    const [expanded, setExpanded] = useState(false);

    return (
        <Button 
        {...props}
        style={{...style, 
            transition: 'all .1s ease-in-out', 
            minHeight: 30, 
            minWidth: 30,
            ...(expanded ? {opacity: 1} : {opacity: 0.2})
        }}
        onMouseEnter={()=>setExpanded(true)}
        onMouseLeave={()=>setExpanded(false)}
        >
            {expanded && title &&
                <span style={{marginRight: 10}}>{title}</span>
            }
            {icon &&
                <Icon style={{fontSize: 20}}>{icon}</Icon>
            }
        </Button>
    )
}