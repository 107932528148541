import { Button } from '@material-ui/core'
import React from 'react'
import { useRouter } from '@fuse/hooks'

function NotFound404() {
  const router = useRouter()
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column'
    }}>
      <img  src="assets/images/404.png" alt="logo" />
      {/* <h1 style={{
        fontSize: '100px'
      }} className='text-center'>404</h1>
      <h1 style={{
        fontSize: '50px'
      }} className='text-center'>Page Not Found</h1> */}
      <Button
        variant="contained"
        color="primary"
        style={{ position: 'relative', bottom: '30px' }}
        className="w-224 mx-auto"
        aria-label="Reset"
        onClick={()=>router.push('/assets-dashboard')}
      >
        Return To Dashboard
      </Button>
    </div>
  )
}

export default NotFound404