export const getContractStatus = (endDate) =>{
    if (!endDate) return {};
    /*
     * Active- Contract End Date greater than 90 days
     * Expiring- Contract End Date in between 90 days
     * Expired - Contract End Date less than 90 days
    */
    if(Math.ceil((new Date(endDate).getTime() - new Date().getTime())/(1000*3600*24)) > 90){
      return { status: "Active", background: "green", icon: "done" };
    }else if(Math.ceil((new Date(endDate).getTime() - new Date().getTime())/(1000*3600*24)) <= 90 
      && Math.ceil((new Date(endDate).getTime() - new Date().getTime())/(1000*3600*24)) > 0){
      return { status: "Expiring", background: "grey", icon: "close" };
    }else if(Math.ceil((new Date(endDate).getTime() - new Date().getTime())/(1000*3600*24)) <= 0 ){
      return { status: "Expired", background: "grey", icon: "close" };
    }else{
        return {}
    }
  };