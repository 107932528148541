import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { Card, Box, Stepper, Step, StepButton, Button, TextareaAutosize, DialogTitle, Divider, Icon, IconButton, TextField, Typography, InputAdornment, Drawer, CardContent, Paper, Grid, Item, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, FormLabel, RadioGroup } from '@mui/material'
import axios from '@fuse/utils/axios'
import { countries } from '@fuse/utils/countryName'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'
import { Autocomplete } from '@material-ui/lab'
import { quickAddServiceProvider } from '../../main/service-provider/store/reportViewDialogReducer'
import AlertModal from 'helper/alertModal'


const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
        helperText: {
            color: 'red',
            paddingLeft: '1px'
        },
        datePickerInput: {
            '& .MuiInputBase-input': {
                fontSize: '16px'
            }
        }
    }
))



function QuickAddServiceProvider(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
	const toast = useToast(dispatch)
    const { data: editFromState } = useSelector((state) => state.serviceProviderApp.formViewDialog);


    const [ formName, setFormName ] = useState('Add Service Provider')
    const [openAlerModal, setOpenAlertModal] = useState(false);

    const [formattedNumber, setFormattedNumber] = useState('')


	const avoidHyperTextTransferProtocolString = ['http', 'https']
	const avoidWorldWideWebString = ['www']
	const dotValidation = [-1, 0]


    function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}


    const schema = yup.object({
		serviceProvider: yup.string().trim().required('Provider Name is required!'),
		// phone_number: yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Phone number should be in 000-000-0000 format').max(12, 'Phone Number can\'t exceed 10 digits'),
		// }),
		address1: yup.string().nullable(),
		city: yup.string().nullable(),
		state: yup.string().nullable(),
		country: yup.object().nullable(),
		zip: yup.string().nullable(),
		url: yup.string().trim().required('URL is required!'),
	})


    const defaultValues = {
		serviceProvider: '',
		phone_number: '',
		address1: '',
		city: '',
		state: '',
		country: {},
		zip: '',
		url: ''
	}


    const { 
        control, 
        formState:{errors: statusFormErrors}, 
        handleSubmit: statusFormSubmit, 
        trigger,
        getValues,
        setValue,
        reset: statusFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    const handleCloseForm = () => {
        
        props.close('serviceprovider')
    }


    const contractStatusError = (error) => {
        console.log(error)
    }


    const onSubmit = async(data) => {
        if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return;
		}

		if( avoidWorldWideWebString.includes(data.url?.split('.')[0]) || avoidHyperTextTransferProtocolString.includes(data.url?.split(':')[0]) || dotValidation.includes(data.url.indexOf('.')) ){
			toast.error(`URL www.${data.url} is not valid`)
			return
		}
		
		let payload = {
			address1: data.address1,
			serviceProvider: data.serviceProvider,
			phone_number: formattedNumber || '',
			city: data.city,
			state: data.state,
			country: data.country?.name ?? null,
			zip: data.zip,
			url: `www.${data.url}`
		}

        if(!editFromState){
			payload = {
				...payload,
				entity_external_platform_id: String(Math.trunc(Math.random()*20000000)),
			}
			
			axios.post('/service-provider/add', payload).then(res => {
				if(res.status === 201){
					toast.error(res.data.message)
				}else{
					// onSuccess()
					// props.getServiceProviders()
					toast.success('Service Provider added successfully')
                    dispatch(quickAddServiceProvider())
                    props.close('serviceprovider')
				}
				
			}).catch(err => {
				console.log('504=>',err)
				toast.error('Something went wrong!')
			})

		}else{
			
			payload = {
				...payload,
				id: editFromState.id,
			}

			axios.put('/service-provider/update', payload).then(res => {
				if(res.status === 201){
					toast.error(res.data.message)
				}else{
					// onSuccess()
					// props.getServiceProviders()
					toast.success('Service Provider updated successfully')
                    dispatch(quickAddServiceProvider())
                    props.close('serviceprovider')
				}
				
			}).catch(err => {
				console.log('504=>',err)
				toast.error('Something went wrong!')
			})

		}

    }


    useEffect( () => {
        if(!editFromState) return
        const url = editFromState.url.split('www.')[1]
        setValue('address1', editFromState.original_info.address_line_1)
        setValue('url', url)
        setValue('city', editFromState.original_info.city)
        setValue('country', {"name": editFromState.original_info.country, "code": "", "currency":""})
        setFormattedNumber(editFromState.original_info.phone_number ?? '')
        setValue('serviceProvider', editFromState.service_provider_name )
        setValue('state', editFromState.original_info.state)
        setValue('zip', editFromState.original_info.zip)

    }, [editFromState])

    const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        handleCloseForm()
    }

    return (


        <>
            <div>
                <Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
                    <div className='heading_holder'>
                        <h4 className='heading'>{formName}</h4>
                        <IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
                            <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                        </IconButton>
                    </div>
                    <div className='progressbar_holder'>
                        <div className='progress'>
                            &nbsp;
                        </div>
                    </div>
                        <div style={{paddingTop:'30px'}} className='cstm_wizard_holder'>
                            <Box sx={{ width: '100%' }} className='box_holder'>
                            <div className='main_content_holder'>
                                    <div className='content_holder'>
                                        <div className="text-left">
                                            <div className='mb-10'>
                                                <Typography className='status_text'>
                                                    Provider Name
                                                    <span className='required_span'>*</span>
                                                </Typography>
                                                <Controller 
                                                    name="serviceProvider"
                                                    className='serial_numberField'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            style={{ background: 'white' }}
                                                            variant='outlined'
                                                            autoFocus
                                                            fullWidth
                                                            required
                                                            defaultValue={defaultValues.serviceProvider}
                                                            error={!!statusFormErrors.serviceProvider}
                                                            helperText={statusFormErrors?.serviceProvider?.message}
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    border: '1px solid #F2F4F7',
                                                                    fontSize: '10px',
                                                                }
                                                            }} 
                                                        />
                                                    )}
                                                />
                                            </div> 
                                        </div>

                                        <div className="text-left">
                                            <Typography className='status_text'>
                                                URL
                                                <span className='required_span'>*</span>
                                            </Typography>
                                            <Controller 
                                                name="url"
                                                className="mb-16"
                                                defaultValue={''}
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            variant='outlined'
                                                            // label='URL*'
                                                            // fullWidth
                                                            error={!!statusFormErrors.url}
                                                            helperText={statusFormErrors?.url?.message}
                                                            FormHelperTextProps={{
                                                                style: { 
                                                                    margin : 0, 
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment>
                                                                        <Typography style={{ width: '100%', backgroundColor: '#fff', color: "#1D2939", fontSize: "14px" }}>www.</Typography>
                                                                    </InputAdornment>
                                                                ),
                                                                classes: {
                                                                    adornedEnd: classes.adornedEnd
                                                                }
                                                            }}
                                                            style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>


                                        <div className="text-left">
                                            <Typography className='status_text'>
                                                Phone Number
                                                {/* <span className='required_span'>*</span> */}
                                            </Typography>
                                            <Controller
                                                name="phone_number"
                                                className="mb-16"
                                                defaultValue={''}
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            value={formattedNumber}
                                                            variant='outlined'
                                                            // label='Phone Number'
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9/-]+/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e)=>{
                                                                changeNumberFormat(e)
                                                            }}
                                                            onPaste={(e)=>{
                                                                e.preventDefault();
                                                            }}
                                                            FormHelperTextProps={{
                                                                style: { 
                                                                    margin : 0, 
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    fontSize: '10px'
                                                                }
                                                            }}
                                                            style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                                        />
                                                    </>
                                                )}
                                            />

                                        </div>



                                        <div className="text-left">
                                            <Typography className='status_text'>
                                                Address 1
                                                {/* <span className='required_span'>*</span> */}
                                            </Typography>
                                            <Controller
                                                name="address1"
                                                className="mb-16"
                                                defaultValue={''}
                                                control={control}
                                                render={({ field }) => (
                                                <>
                                                        <TextField
                                                            {...field}
                                                            variant='outlined'
                                                            // label='Address'
                                                            // fullWidth
                                                            error={!!statusFormErrors.address1}
                                                            helperText={statusFormErrors?.address1?.message}
                                                            FormHelperTextProps={{
                                                                style: { 
                                                                    margin : 0, 
                                                                        margin : 0, 
                                                                    margin : 0, 
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px'
                                                                }
                                                            }}
                                                            style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <div className="text-left">
                                            <Typography className='status_text'>
                                                City
                                                {/* <span className='required_span'>*</span> */}
                                            </Typography>
                                            <Controller
                                                name="city"
                                                className="mb-16"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            variant='outlined'
                                                            // label='City'
                                                            // fullWidth
                                                            error={!!statusFormErrors.city}
                                                            helperText={statusFormErrors?.city?.message}
                                                            FormHelperTextProps={{
                                                                style: { 
                                                                    margin : 0, 
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px'
                                                                }
                                                            }}
                                                            style={{ display:'flex', width: '100%', marginBottom:'10px',marginRight: '10px', backgroundColor:'#fff' }}
                                                        />
                                                    </>
                                                )}
                                            />

                                        </div>


                                        <div className="text-left">
                                            <Typography className='status_text'>
                                                state   
                                                {/* <span className='required_span'>*</span> */}
                                            </Typography>
                                            <Controller
                                                name="state"
                                                className="mb-16"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            variant='outlined'
                                                            // label='State'
                                                            // fullWidth
                                                            error={!!statusFormErrors.state}
                                                            helperText={statusFormErrors?.state?.message}
                                                            FormHelperTextProps={{
                                                                style: { 
                                                                    margin : 0, 
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px'
                                                                }
                                                            }}
                                                            style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>


                                        <div className="text-left">
                                            <Typography className='status_text'>
                                                Zip   
                                                {/* <span className='required_span'>*</span> */}
                                            </Typography>
                                            <Controller
                                                name="zip"
                                                className="mb-16"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            variant='outlined'
                                                            // label='Zip'
                                                            // fullWidth
                                                            error={!!statusFormErrors.zip}
                                                            helperText={statusFormErrors?.zip?.message}
                                                            FormHelperTextProps={{
                                                                style: { 
                                                                    margin : 0, 
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px'
                                                                }
                                                            }}
                                                            style={{ display:'flex', width: '100%', marginBottom:'10px',marginRight: '10px', backgroundColor:'#fff' }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>


                                        <div className="text-left">
                                            <Typography className='status_text'>
                                                Country   
                                                {/* <span className='required_span'>*</span> */}
                                            </Typography>
                                            <Controller
                                                name="country"
                                                className="mb-16"
                                                control={control}
                                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                    <>
                                                        <Autocomplete
                                                            {...field}
                                                            style={{ width: '100%' }}
                                                            options={countries}
                                                            // disablePortal
                                                            onChange={(event, value) => field.onChange(value)}
                                                            PaperComponent={({ children }) => (
                                                                <Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
                                                            )}
                                                            getOptionLabel={option => option.name ?? ''}
                                                            renderInput={(params) => {
                                                                return (
                                                                    <div ref={params.InputProps.ref}>
                                                                        <TextField
                                                                            style={{background: 'white'}}
                                                                            {...params}
                                                                            // label="Country"
                                                                            inputRef={ref}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                        />
                                                                    </div>
                                                                )
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {/* <DialogActions className="action_footer_holder"> */}
                                    <div className='btn_holder'>
                                        <Button
                                            color="inherit"
                                            onClick={handleAlertModal}
                                            className='back_btn secondary_btn'
                                        >
                                        Cancel
                                        </Button>
                                        <Button onClick={statusFormSubmit(onSubmit, contractStatusError)} className='next_btn'>                                                
                                            Proceed
                                        </Button>
                                    </div>
                                </div>
                            </Box>
                        </div>
                </Drawer>
		    </div>

            {openAlerModal &&
                <AlertModal  openAlerModal module="service provider" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
        </>
    )

}



export default QuickAddServiceProvider;