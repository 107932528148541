import _ from '@lodash';
import TablePagination from '@fuse/components/general/TablePagination';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Icon, Divider } from '@material-ui/core';
import ListView from './listViewLineItems';
import GridView from './gridView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	  },
	  root: {
		// minWidth: 275,
	  },
	  title: {
		fontSize: 14,
	  },
	  pos: {
		marginBottom: 12,
	  },
	  buttonSquare: {
		borderRadius: 5,
	  },
});

function EndUsersAccountsTable(props) {
	const [saving, setSaving] = useState(false)
    const classes = useStyles();
	let listItems = props.listItems;

	function handleChangePage(event, value) {
		props.setPage(value);
		props.fetchItems(props.rowsPerPage, value, props.filters[props.currentFilter], props.currentSearch);
	}

	function handleChangeRowsPerPage(rowsPerPage) {
		props.setRowsPerPage(rowsPerPage);
		props.fetchItems(rowsPerPage, props.page, props.filters[props.currentFilter], props.currentSearch);
	}

	useEffect(()=>{
		if(!props.addedLineItem){
			setSaving(false)
		}
	},[props.addedLineItem])

	if(props.loading){
		return (<FuseLoading/>)
	}

	if(listItems.length <= 0){
		return (
			<div className="w-full flex flex-col">
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, transition: { delay: 0.1 } }}
					className="flex flex-1 items-center justify-center h-full"
				>
					<div style={{height: "200px"}}>
						<Typography variant="body2" component="p" color="textSecondary">
							No data found
						</Typography>
					</div>
				</motion.div>
				<TablePagination
					className="flex-shrink-0 border-t-1 mt-10"
					component="div"
					count={props.totalRecords}
					rowsPerPage={props.rowsPerPage}
					page={props.page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div>
		)
	}

	return (
		<div className="w-full flex flex-col">

			<SwitchView selectedView={props.selectedView} listItems={listItems}
						onSelectionChanged={props.onSelectionChanged}
						onRowSelected={props.onRowSelected}
						onRowClick={props.onRowClick}
						quote_id={props.quote_id}
						setEditedLineItem={props.setEditedLineItem}
						grid={props.grid}
						exceptCols={props.exceptCols}
						selectedModel={props.selectedModel}
						editableCols={props.editableCols}
						updateColValue={props.updateColValue}
						selectedLineItems={props.selectedLineItems}
						assetType={props.assetType}
						saving={saving}
						setSaving={(arg)=>setSaving(Boolean(arg))}
						/>
			<Grid container className={`p-5`}>
				<Grid item xs>
					<TablePagination
						className="flex-shrink-0 mt-10"
						component="div"
						count={props.totalRecords}
						rowsPerPage={props.rowsPerPage}
						page={props.page}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Grid>
			</Grid>
			<Divider />
			<Grid container className={`p-5`}>
				<Grid item xs>
					{props.addedLineItem == null ?
					<>
						<Button
						variant="contained"
						color="primary"
						className={`${classes.buttonSquare}`}
						startIcon={<Icon>add</Icon>}
						disabled={((props.listItems.length == 1 && props.listItems[0].request == "add_line_item") || (props.booked) || (props.changeRequested)) ? true : false}
						onClick={()=>{props.addLineItems(()=>{})}}
						>
							Add line item
						</Button>
					</>
					:
					<>
					<Button
					variant="contained"
					color="primary"
					className={`${classes.buttonSquare}`}
					startIcon={<Icon>save</Icon>}
					onClick={()=>{setSaving(true);props.onSaveLineItem()}}
					>
						Save
					</Button>
					<Button
					variant="contained"
					color="primary"
					className={`${classes.buttonSquare} ml-10`}
					startIcon={<Icon>cancel</Icon>}
					onClick={()=>{
						props.removeAddedLineItems(()=>{});
					}}
					>
						Cancel
					</Button>
					</>
					}
				</Grid>
			</Grid>

		</div>
	);

}

function SwitchView({listItems,selectedView, onRowSelected, onSelectionChanged, onRowClick, quote_id, setEditedLineItem, grid,
	exceptCols, selectedModel, editableCols, updateColValue, selectedLineItems, saving, setSaving, assetType
}) {
	if(selectedView == 0){
		return(<ListView listItems={listItems} 
			onRowSelected={onRowSelected}
			onSelectionChanged={onSelectionChanged}
			onRowClick={onRowClick}
			setEditedLineItem={setEditedLineItem}
			quote_id={quote_id}
			grid={grid}
			exceptCols={exceptCols}
			selectedModel={selectedModel}
			editableCols={editableCols}
			updateColValue={updateColValue}
			selectedLineItems={selectedLineItems}
			saving={saving}
			setSaving={setSaving}
			assetType={assetType}
			/>)
	}else{
		return(<GridView listItems={listItems} onRowClick={onRowClick}
		/>)		
	}
}

export default withRouter(EndUsersAccountsTable)
