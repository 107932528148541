import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField } from '@material-ui/core';
import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';


const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {

	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	const dispatch = useDispatch();
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<Icon style={{color:'black'}}>archive</Icon>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex md:text-18 mx-12 font-semibold"
					style = {{ color : 'black' }}
				>
					Archived Service Tickets List
				</Typography>
			</div>

			<div style={{display:'flex'}}>
				
				<div className={classes.buttonContainer}>
				<ThemeProvider theme={mainTheme}>
					<Paper
						component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
						style={{minWidth:"40px"}}
					>
						<TextField
							placeholder="Search Service Ticket"
							className="flex flex-1 mx-8"
							fullWidth
							InputProps={{
								disableUnderline: true,
								
							}}
							value={query_data ?? '' }
							onChange={(e)=>{
								dispatch(setQuickFilter(e.target.value))
							}}
						/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>
		</div>
	);
}

export default DynamicFieldsHeader;
