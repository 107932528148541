import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withReducer from 'app/store/withReducer';
import clsx from 'clsx';
import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import endUsersAccounts from '../shared/endUsersAccounts';
import EndUsersAccountsTable from './tables';
import useRouter from '@fuse/hooks/useRouter';
import axiosgeneral from "@fuse/utils/axios";
import FuseLoading from '@fuse/core/FuseLoading';
import {Menu,MenuItem} from '@material-ui/core';
import { ReactComponent as Eye } from '../icons/eye.svg';
import { ReactComponent as GridView } from '../icons/grid_view.svg';
import { ReactComponent as ListView } from '../icons/list_view.svg';
import { ReactComponent as SearchIcon } from '../icons/search.svg';
import { ReactComponent as UploadIcon } from '../icons/upload.svg';
import { ReactComponent as AssetIcon } from '../icons/block.svg';
import ArrowButton from '@fuse/components/general/ArrowButton';
import { ReactComponent as ArrowIcon } from '../icons/arrow.svg';
import SvgIcon from "@material-ui/core/SvgIcon";

const drawerWidth = 400;
const headerHeight = 200;

const views = ['List View','Grid View'];

const defaultCounts = {end_users:0, locations:0};

const defaultSearches = {location:'',quote_indicator:'',quote_status:'',revenue:'',action:''}

const useStyles = makeStyles(theme => ({
	'@global': {
		'#fuse-main': {
			height: '100vh'
		}
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: '100%',
		position: 'relative',
		// flex: '1 1 auto',
		height: 'auto',
		backgroundColor: theme.palette.background.default
	},
	cardGen: {
		width: '100%',
	},
	topBg: {
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none',
		padding:"10px"
	},
	topBgFixed: {
		height: headerHeight,
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none'
	},
	contentCardWrapper: {
		maxWidth: 1400,
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 0 auto',
		width: '100%',
		minWidth: '0',
		margin: '0 auto',
		// [theme.breakpoints.down('sm')]: {
		// 	padding: '16px 16px 0 16px'
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	padding: '12px 12px 0 12px'
		// }
	},
	contentCard: {
		display: 'flex',
		position: 'relative',
		// flex: '1 1 100%',
		flexDirection: 'row',
		backgroundColor: theme.palette.background.paper,
		minHeight: 0,
		overflow: 'hidden',
		borderRadius: '2px 2px 0 0',
	},
	drawerPaper: {
		width: drawerWidth,
		maxWidth: '100%',
		overflow: 'hidden',
		height: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'relative'
		}
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 1 100%',
		zIndex: 10,
		background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
			theme.palette.background.paper,
			0.6
		)} 20%,${fade(theme.palette.background.paper, 0.8)})`
	},
	content: {
		display: 'flex',
		// flex: '1 1 100%',
		minHeight: 0
	},
	avatar: {
		backgroundColor: red[500],
	},
	formControl: {
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	formControlMinWidth: {
		minWidth: 200,
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	card: {
		borderRadius:"5px"
	},
	cardContainer:{
	},
	borderRightLight: {
		borderRight:"1px solid rgba(0,0,0,0.12)"
	},
	buttonSquare:{
		borderRadius: '5px'
	}
}));

function CompanyDetail(props) {
	const dispatch = useDispatch();
	const [selectedView, setSelectedView] = useState(0);
	const [counts, setCounts] = useState(defaultCounts);
	const [quotes,setQuotes] = useState(endUsersAccounts);
	const [companyInitial,setCompanyInitial] = useState('');
	const [searches,setSearches] = useState(defaultSearches);
	const classes = useStyles(props);
	const router = useRouter();
	const [loading, setLoading] = useState(false);
	const [endUsersAccountsMod,setEndUsersAccounts] = useState([]);
	const [flattenedEndUserAccounts,setFlattenedEndUserAccounts] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);

	useEffect(()=>{
		const id = router.params.id;
		setLoading(true);
		axiosgeneral.get(`/user-company/fetch-company/${id}`)
		.then(res => {
			let accDetail = res.data.data.length > 0 ? res.data.data[0] : {};
			if(accDetail.hasOwnProperty('user_companies_user')){
				setCounts({...defaultCounts,end_users:accDetail.user_companies_user.length});
				delete accDetail.user_companies_user;
			}
			if(accDetail.hasOwnProperty('company_name')){
				setCompanyInitial(accDetail.company_name.split('')[0]);
			}
			setEndUsersAccounts(res.data.data);
			const flattened = flattenEndUserAccounts(accDetail);
			setFlattenedEndUserAccounts(flattened);
			props.showMessage({ message: res.data.message,autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
		})
		.catch(err => {
			return err;
		})
		setLoading(false);
	},[]);

	function flattenEndUserAccounts(accDetail){
				let accRef = accDetail;
				let modObj = {};
				let dynAttr = {};
				let keys = Object.keys(accRef);
				for(let i = 0; i<keys.length; i++){
					if(keys[i] != 'created_at' && keys[i] != 'updated_at' && keys[i] != 'platform_customer_id'){
						if(keys[i] != 'dynamic_attributes'){
							modObj[keys[i]] = accRef[keys[i]];
						}else{
							dynAttr = accRef[keys[i]];
						}
					}
				}

				if(Object.keys(dynAttr).length > 0){
					keys = Object.keys(dynAttr);
					for(let i = 0; i<keys.length; i++){
						if(keys[i] != 'id' && keys[i] != 'created_at' && keys[i] != 'updated_at'){
							modObj[keys[i]] = dynAttr[keys[i]]['attribute_value'];
						}
					}
				}
				return modObj;
	}

	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function getLocations(){
		const locationSet = new Set();
		for(let i = 0; i < quotes.length ; i++){
			locationSet.add(quotes[i].location_name);
		}
		return Array.from(locationSet);
	}

	function getIndicators(){
		const indicatorSet = new Set();
		for(let i = 0; i < quotes.length ; i++){
			indicatorSet.add(quotes[i].indicator);
		}
		return Array.from(indicatorSet);
	}

	function getStatuses(){
		const statusSet = new Set();
		for(let i = 0; i < quotes.length ; i++){
			statusSet.add(quotes[i].status);
		}
		return Array.from(statusSet);
	}

	function getRevenues(){
		const revenueSet = new Set();
		for(let i = 0; i < quotes.length ; i++){
			revenueSet.add(quotes[i].quote_amount);
		}
		return Array.from(revenueSet);
	}

	function handleSearch(search) {
		console.log(search)
		setSearches(search);
		console.log(searches);
		const quotesMod = quotes.filter(quote => {
			if(searches.location != '' && quote.location_name != searches.location){
				return false;
			}

			if(searches.quote_indicator != '' && quote.indicator != searches.quote_indicator){
				return false;
			}

			if(searches.quote_status != '' && quote.status != searches.quote_status){
				return false;
			}

			if(searches.revenue != '' && quote.quote_amount != searches.revenue){
				return false;
			}

			if(searches.action != '' && quote.action != searches.action){
				return false;
			}

			return true;
		});
		console.log(quotesMod);
		setQuotes(quotesMod);
	}

	function clearSearch() {
		setSearches(defaultSearches);
		setQuotes(endUsersAccounts);
	}

	function gotoContacts(e) {
		//console.log(e.target.value, parseInt(router.params.id));
		var id = parseInt(router.params.id);
		router.push(`/company/${id}/end-users`)
	}

	if(loading){
		return(
			<FuseLoading/>
		)
	}

	return (
		<div className={clsx(classes.root)}>
			<FuseScrollbars className="flex-grow overflow-x-auto">
			<div className={classes.topBg} >
			<Grid container spacing={1}>
				{
					endUsersAccountsMod.length > 0 && 
					Object.keys(flattenedEndUserAccounts)
					.map(key => {
						if(key != 'id' && flattenedEndUserAccounts[key] != null){
						
						return(
						<Grid item xs={6} sm={3}>
						<Card className={classes.card}>
						<CardContent className={classes.cardContainer}>
							{key.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
						<br/>
						
						<span style={{fontWeight:"bold"}}>
						{flattenedEndUserAccounts[key]}
						</span>
						</CardContent>
						</Card>
						</Grid>
						)
						}
					})
					
				}
				<Grid item xs={6} sm={3}>
				<Card className={classes.card}>
				<CardContent className={classes.cardContainer}>
				Number of end-users
				<br/>
				
				<span style={{fontWeight:"bold"}}>
				{counts.end_users}
				</span>
				</CardContent>
				</Card>
				</Grid>

				<Grid item xs={6} sm={3}>
				<Card className={classes.card}>
				<CardContent className={classes.cardContainer}>
				Number of locations
				<br/>
				
				<span style={{fontWeight:"bold"}}>
				14
				</span>
				</CardContent>
				</Card>
				</Grid>
			</Grid>
			</div>

			<div className={clsx(classes.contentCardWrapper, 'container')}>
				<div className={clsx(classes.contentCard, 'shadow')}>
				
				<main>
					<Grid container spacing={3} className="p-10">
					<Grid item xl className={classes.cardGen}>
				<Card className={classes.card}>
				
					<CardContent className={classes.cardContainer}>
						<Typography variant="body2" color="textPrimary" component="p">
							<div style={{width:'100%'}}>
						<Grid container spacing={2} className="p-24">
							<Grid item xs className={classes.borderRightLight}>
							<span style={{fontWeight:"bold"}}>
								20
							</span>
							<br/>Number of Active Quotes</Grid>
							<Grid item xs className={classes.borderRightLight}>
							<span style={{fontWeight:"bold"}}>
								20
							</span>
							<br/>Number of Upcoming Quotes</Grid>
							<Grid item xs className={classes.borderRightLight}>
							<span style={{fontWeight:"bold"}}>
								14
							</span>	
							<br/>Number of Pending Quotes</Grid>
							
							<Grid item xs>
							<span style={{fontWeight:"bold"}}>
								5
							</span>	
							<br/>Number of Lost Quotes</Grid>
						</Grid>
						<Divider/>
						<Grid container spacing={2} className="p-24">
							<Grid item sm className={`flex justify-between ${classes.borderRightLight}`}>
							
								YTD Actual Revenue
								<span style={{fontWeight:"bold"}}>$122,221.00</span>
							</Grid>
							<Grid item sm className={`flex justify-between ${classes.borderRightLight}`}>
								Forcasted Revenue
								<span style={{fontWeight:"bold"}}>
								$325,840.00</span></Grid>
							<Grid item sm>
								<Box className={classes.pullRight}>
								<Button
									variant="contained"
									color="primary"
									className={`w-full ${classes.buttonSquare}`}
									style={{width:'150px'}}
									startIcon={
									<Icon fontSize="large" title="Save View">
										assessment
									</Icon>}>
										View Chart
								</Button>
								</Box>
							</Grid>
						</Grid>
						</div>
						</Typography>
					</CardContent>
				<Divider/>
					<CardActions disableSpacing>
						{/* <ButtonGroup> */}
						<Button
							variant="text"
							color="primary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'120px'}}
							startIcon={
							<Icon fontSize="large" title="Save View">
								assessment
							</Icon>}>
								Chart
						</Button>
						<Button
							variant="text"
							color="primary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'120px'}}
							startIcon={
							<Icon fontSize="large" title="Save View">
								mail_outline
							</Icon>}>
								Emails
						</Button>
						<Button
							variant="text"
							color="primary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'120px'}}
							onClick={e=>gotoContacts(e)}
							startIcon={
							<Icon fontSize="large" title="Save View">
								local_phone_outline
							</Icon>}>
								Contacts
						</Button>
						<Button
							variant="text"
							color="primary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'120px'}}
							startIcon={
								<SvgIcon fontSize="small" color="primary"><AssetIcon /></SvgIcon>	
							}>
								Assets
						</Button>
						<Button
							variant="text"
							color="primary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'120px'}}
							startIcon={
							<Icon fontSize="large" title="Save View">
								location_on_outlined
							</Icon>}>
								Locations
						</Button>
						{/* </ButtonGroup> */}
					</CardActions>
				</Card>
				</Grid>
				<Grid item xl className={classes.cardGen}>
				<Card className={classes.card}>
					<CardHeader title={
						<Typography variant="subtitle1" color="textPrimary" component="p">Filters</Typography>
					}
					/>
					<Divider/>
					<CardContent className={classes.cardContainer}>
						<Grid container spacing={1} className="p-2" alignContent="center" alignItems="center">
							<Grid item sm xs={6}>
							<FormControl variant="outlined" fullWidth className={classes.formControl} margin='dense'>
								<InputLabel htmlFor="city_locations_filter">City Locations</InputLabel>
								<Select
								native
								value={searches.location}
								onChange={e => {handleSearch({...searches,location:e.target.value})}}
								label="City Locations"
								inputProps={{
									name: 'city_locations_filter',
									id: 'city_locations_filter',
								}}
								>
								<option aria-label="None" value="" />
								{
									getLocations()
									.map(loc => {
										return(
											<option value={loc}>{loc}</option>
										)
									})
								}
								</Select>
							</FormControl>
							</Grid>
							<Grid item sm xs={6}>
							<FormControl variant="outlined" fullWidth className={classes.formControl} margin='dense'>
								<InputLabel htmlFor="quote_indicator_filter">Quote Indicator</InputLabel>
								<Select
								native
								label="Quote Indicator"
								value={searches.quote_indicator}
								onChange={e => {handleSearch({...searches,quote_indicator:e.target.value})}}
								inputProps={{
									name: 'quote_indicator_filter',
									id: 'quote_indicator_filter',
								}}
								>
								<option aria-label="None" value="" />
								{
									getIndicators()
									.map(ind => {
										return(
											<option value={ind}>{ind}</option>
										)
									})
								}
								</Select>
							</FormControl>
							</Grid>
							<Grid item sm xs={6}>
							<FormControl variant="outlined" fullWidth className={classes.formControl} margin='dense'>
								<InputLabel htmlFor="quote_status_filter">Quote Status</InputLabel>
								<Select
								native
								label="Quote Status"								
								value={searches.quote_status}
								onChange={e => handleSearch({...searches,quote_status:e.target.value})}
								inputProps={{
									name: 'quote_status_filter',
									id: 'quote_status_filter',
								}}
								>
								<option aria-label="None" value="" />
								{
									getStatuses()
									.map(status => {
										return(
											<option value={status}>{status}</option>
										)
									})
								}
								</Select>
							</FormControl>
							</Grid>
							<Grid item sm xs={6}>
							<FormControl variant="outlined" fullWidth className={classes.formControl} margin='dense'>
								<InputLabel htmlFor="revenue_filter">Revenue</InputLabel>
								<Select
								native
								label="Revenue"
								value={searches.revenue}
								onChange={e => {handleSearch({...searches,revenue:e.target.value})}}
								inputProps={{
									name: 'revenue_filter',
									id: 'revenue_filter',
								}}
								>
								<option aria-label="None" value="" />
								{
									getRevenues()
									.map(rev => {
										return(
											<option value={rev}>{rev}</option>
										)
									})
								}
								</Select>
							</FormControl>
							</Grid>
							<Grid item sm xs={12} justify="center"  alignContent="center" alignItems="center" className={classes.pullRight}>
							<Button
								variant="contained"
								color="secondary"
								className={`w-full ${classes.buttonSquare}`}
								style={{width:'120px'}}
								onClick={clearSearch}
								>
									Clear Search
							</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				</Grid>
				<Grid item xl className={classes.cardGen}>
				<Card className={classes.card}>
					<CardContent className={classes.cardContainer}>
						<ArrowButton onClick={()=>console.log('works')} label='No Action (15)'/>
						<ArrowButton onClick={()=>console.log('works')} label='Changed By Agent (15)'/>
					</CardContent>
					<Divider/>
					<CardContent className={classes.cardContainer}>
						<Box className={classes.pullRight}>
						<div>
							<IconButton aria-controls="simple-menu" className={classes.buttonSquare} aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
								{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
								{viewMenuOpened?<Icon>arrow_drop_up</Icon>:<Icon>arrow_drop_down</Icon>}
								
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={viewMenuOpened}
								onClose={handleViewMenuClose}
							>
								{	
									views.map((view,index) => {
										return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
									})
								}
							</Menu>
							</div>
						</Box>
					</CardContent>
					<Divider/>
					<CardContent className={classes.cardContainer}>
						<EndUsersAccountsTable selectedView={selectedView} endUsersAccounts={quotes}/>
					</CardContent>
				</Card>
				</Grid>
				</Grid>
				</main>
				</div>
			</div>
			</FuseScrollbars>
		</div>
	);
}

export default CompanyDetail;
