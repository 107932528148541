const initialState = {
	modules : []
}

const actionTypes = {
    SET_MODULES: "SET_MODULES",
	REFRESH_USERS: "REFRESH_MODULES"
}

export function setModules( modules=[] ){

    return {
        type: actionTypes.SET_MODULES,
        payload: {
            modules
        },
    }
}

export default function (state = initialState, action) {
	switch (action.type) {
        case actionTypes.SET_MODULES:{
            return { 
                ...state,
                modules : [...action.payload.modules]
            }
        }
        default:
            return state
	}
}