import React from 'react'
import _ from '@lodash';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
    layoutRoot: {},
    head: {
        position: "sticky",
        top: 0
    },
    root: {
        // minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    row:{
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom:"25px",
        display: 'flex'
    },
    column:{
        // border: '2px solid black',
        width: '100%',
        textAlign: 'left'
    }
});

const ContractTab = ({contractDetails}) => {
    const classes = useStyles();

    return (
        <>
            <div className='flex flex-col text-center overflow-auto w-full' style={{height: 290}}>
                {Boolean(contractDetails) ? 
                <>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Contract Name</Typography>
                            <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contract_name}</Typography>
                        </div>
                        <div className={classes.column}>
                            <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Contract Number</Typography>
                            <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contract_number}</Typography>
                        </div>
                        <div className={classes.column}>
                            <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Start Date</Typography>
                            <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contract_start_date ? dateReformatTo_mmm_dd_yyyy(contractDetails?.contract_start_date) : ''}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">End Date</Typography>
                            <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contract_end_date ? dateReformatTo_mmm_dd_yyyy(contractDetails?.contract_end_date) : ''}</Typography>
                        </div>
                        <div className={classes.column}>
                            <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Status</Typography>
                            <Typography style={{fontWeight:400, color: "#000", display: "flex", alignItems:'center'}} variant="subtitle1">
                                <span style={{
                                    height:'10px',
                                    width:'10px',
                                    marginLeft: '5px',
                                    borderRadius: '50%',
                                    backgroundColor: contractDetails?.contract_status_color?.code
                                }}></span>                                
                                <span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{contractDetails?.contract_status} </span>
                            </Typography>
                        </div>
                        <div className={classes.column}>
                            <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Contract Type</Typography>
                            <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contract_type}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Service Provider Name</Typography>
                            <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails.contract_service_provider_name}</Typography>
                        </div>
                        <div className={classes.column}>
                            <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Service Level Agreement</Typography>
                            <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails.contract_service_level_agreement}</Typography>
                        </div>
                        <div className={classes.column}></div>
                    </div>
                </>:
                <Typography variant="h6" style={{
                    height: 290,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>{'No contract currently available'}
                </Typography>
                }
            </div>
        </>
    )
}

export default ContractTab
