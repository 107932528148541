import FusePageCardedOne from '@fuse/core/FusePageCardedOne';
import { makeStyles } from '@material-ui/core/styles';
import { React, useState, useEffect, useRef, Fragment, useCallback } from 'react';
import { Typography, Button, Box, FormControl, Select, MenuItem, InputLabel, Icon, Dialog, DialogTitle, DialogContent, Breadcrumbs } from "@mui/material";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { Link } from 'react-router-dom';
import { exportToCsv } from "@fuse/utils";
import {assetTemplate, categoryTemplate, manufacturerTemplate, modelTemplate, 
  accessoryTemplate, assetStatusTemplate, accessoryCategory, contractTemplate, serviceProviderTemplate, 
  shippingTemplate, userTemplate
} from './csvTemplate'
import { usePermission } from '@fuse/hooks';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});
export default function ImportCSV() {
	const gridRef = useRef();
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);


  const onGridReady = params => {
    // Following line to make the currently visible columns fit the screen  
    params.api.sizeColumnsToFit();
 
    // Following line dymanic set height to row on content
    params.api.resetRowHeights();
  };

  const classes = useStyles();
  const { hasPermission } = usePermission()
  const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
  const user = useCurrentUser();
  const addAssetStatusPermission = hasPermission('add-asset_status')
  const createCategoryPermission = hasPermission("add-asset_category")
  const createManufacturersPermission = hasPermission('add-manufacture')
  const createModelPermission = hasPermission("add-model");
  const createAccessoryPermission = hasPermission('add-accessory')
  const createAccessoryCategoryPermission = hasPermission('add-accessory_category')
  const createContractPermission = hasPermission('add-contract')
  const createServiceProviderPermission = hasPermission("add-service_provider");
  const addLocationPermission =hasPermission('add-location');
  const addUserPermission = hasPermission('add-end-user')
  let createAssetPermission = hasPermission('add-assets')
  const [rowData, setRowData] = useState([])

  const tableHeaders = [
    {
      field: "module",
      headerName: "Module Name",
      minWidth: 250,
		  headerComponentFramework: CustomFilter
    },
    {
      field: "actions",
      headerName: "Action",
      // cellRendererFramework: AssetActionComponent,
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: "download_template",
      headerName: "Download Template",
      // cellRendererFramework: AssetActionComponent,
      minWidth: 200,
      maxWidth: 200
    }
  ];

  useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

  useEffect(()=>{
    setRowData([
      {
        module: "Assets",
        value: "asset",
        createPermission: createAssetPermission
      },
      {
        module: "Asset Categories",
        value: "asset_category",
        createPermission: createCategoryPermission
      },
      {
        module: "Manufacturers",
        value: "manufacturer",
        createPermission: createManufacturersPermission
      },
      {
        module: "Models",
        value: 'model',
        createPermission: createModelPermission
      },
      {
        module: "Accessories",
        value: 'accessory',
        createPermission: createAccessoryPermission
      },
      {
        module: "Asset Status",
        value: 'asset_status',
        createPermission: addAssetStatusPermission
      },
      {
        module: "Accessory Category",
        value: 'accessory_category',
        createPermission: createAccessoryCategoryPermission
      },
      {
        module: "Contracts",
        value: 'contract',
        createPermission: createContractPermission
      },
      {
        module: "Service Providers",
        value: 'service_provider',
        createPermission: createServiceProviderPermission
      },
      {
        module: "Locations",
        value: 'location',
        createPermission: addLocationPermission
      },
  
      {
        module: "Users",
        value: 'user',
        createPermission: addUserPermission
      },
      
    ])
  }, [])

  

  function handleDownloadTemplateFile(filename = "import.csv", blob = null) {
		let link = document.createElement("a");
		if (link.download !== undefined) {
			let url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filename);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}


	function handleInitDownload(moduleName) {
    let template = []

    if(moduleName == 'asset') template = assetTemplate
    if(moduleName == 'asset_category') template = categoryTemplate
    if(moduleName == 'manufacturer') template = manufacturerTemplate
    if(moduleName == 'model') template = modelTemplate
    if(moduleName == 'accessory') template = accessoryTemplate
    if(moduleName == 'asset_status') template = assetStatusTemplate
    if(moduleName == 'accessory_category') template = accessoryCategory
    if(moduleName == 'contract') template = contractTemplate
    if(moduleName == 'service_provider') template = serviceProviderTemplate
    if(moduleName == 'location') template = shippingTemplate
    if(moduleName == 'user') template = userTemplate
		const templatFileBlob = exportToCsv(
			template
		);
		handleDownloadTemplateFile(`${moduleName}-import.csv`, templatFileBlob);
	}

  return (
    <>
      <FusePageCardedOne
        classes={{
          root: classes.layoutRoot,
          toolbar: 'p-0',
          topBg:classes.topBg,
          contentCard:classes.contentCard
        }}
        // header={
        // 	<AssetsDashboardHeader />
        // }
        content={
          <>
            <div role="presentation" className="cstm_breadcrumbs_holder">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/roles">
                  System Settings
                </Link>
                <span>Import CSV</span>
              </Breadcrumbs>
            </div>

            <h4 className="main_page_heading mb-20">Import CSV</h4>

            <div 
              className="ag-theme-alpine" 
              style={{
                width: "100%",
                height: "510px",
                fontSize:'12px'
              }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                animateRows
                onGridReady = { onGridReady }
                paginationPageSize={15}
                pagination
              >
                {tableHeaders.map(header => {
                    if( header.field === "module"){
                      return(
                        <AgGridColumn 
                          key = {header.field} 
                          field = {header.field}
                          headerName = {header.headerName}  
                          width = { header.width }
                          minWidth = { header.minWidth }
                          sortable = {true}
                          flex = {1}
                          filter = "text"
                          // cellRendererFramework={(event) => (<ParametersComponent data={event.data}/>)}
                          wrapText
                          autoHeight
                          // floatingFilter = {true}
                          headerComponentFramework = {header.headerComponentFramework}								
                        />
                      )
                    }
                    if(header.field === "actions"){
                      return(
                        <AgGridColumn 
                          key = {header.field} 
                          field = {header.field}
                          headerName = {header.headerName}  
                          width = { header.width }
                          minWidth = { header.minWidth }
                          maxWidth = { header.maxWidth }
                          sortable = {true}
                          flex = {1}
                          filter = "text"
                          wrapText
                          autoHeight
                          cellRendererFramework={(event) => {
                            return (
                            <>
                            {event?.data?.createPermission ? <Link disabled={!event?.data?.createPermission} to={`/csv?value=${event?.data?.value}`} className="primary_link_holder">
                              <i className='ti ti-upload' />
                              <span>Upload CSV</span>
                            </Link> : <p style={{color: 'gray'}}>
                              <i style={{fontSize: '16px'}} className='ti ti-upload' />
                              <span style={{paddingLeft: '4px'}}>Upload CSV</span>
                            </p>}
                            </>
                          )}}
                          headerComponentFramework = {header.headerComponentFramework}								
                        />
                      )
                    }
                    if(header.field === "download_template"){
                      return(
                        <AgGridColumn 
                          key = {header.field} 
                          field = {header.field}
                          headerName = {header.headerName}  
                          width = { header.width }
                          minWidth = { header.minWidth }
                          maxWidth = { header.maxWidth }
                          sortable = {false}
                          flex = {1}
                          filter = "text"
                          wrapText
                          autoHeight
                          cellRendererFramework={(event) => {
                            return (
                            <>
                            {event?.data?.createPermission ? <Link onClick={()=>{handleInitDownload(event?.data?.value)}} className="primary_link_holder">
                              <i className='ti ti-download' />
                              <span>Download template</span>
                            </Link> : <p style={{color: 'gray'}}>
                              <i style={{fontSize: '16px'}} className='ti ti-download' />
                              <span style={{paddingLeft: '4px'}}>Download template</span>
                            </p>}
                            </>
                          )}}
                          headerComponentFramework = {header.headerComponentFramework}								
                        />
                      )
                    }
                })}
              </AgGridReact>
            </div>
          </>
        }
        innerScroll
      />
    </>
  );
}