import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import ExampleConfig from 'app/main/example/ExampleConfig';
import DashboardConfig from 'app/main/dashboard/DashboardConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import RolesConfig from 'app/main/roles_and_permission/RolesConfig';
import UsersConfig from 'app/main/users/UsersConfig';
import CompaniesConfig from 'app/main/companies/CompaniesConfig';
import AssetsConfig from 'app/main/assets/AssetsConfig'
import AssetsCatalog from 'app/main/assets-catalog/CatalogConfig';
import AssetsDashboard from 'app/main/assets-dashboard/dashboardConfig';
import AssetsListing from 'app/main/assets-listing/ListingConfig';
import ArchivedAssetsConfig from 'app/main/archived-assets/ArchivedAssetsConfig';
import ContractsConfig from 'app/main/contracts/ContractsConfig';
import SettingConfig from 'app/main/setting/SettingConfig';
import ResetConfig from 'app/main/reset/ResetConfig';
import dynamicFieldsConfig from 'app/main/dynamicFields/dynamicFieldsConfig';
import SetPasswordConfig from 'app/main/setpassword/SetPasswordConfig';
import ModuleSettingConfig  from 'app/main/module/ModuleSettingConfig';
import ProfileConfig from 'app/main/profile/ProfileConfig';
import ShippingConfig from 'app/main/shipping_location/ShippingConfig';
import BillingConfig from 'app/main/billing/BillingConfig';
import PriceCatalogueConfig from 'app/main/price-catalogue/PriceCatalogueConfig';
import store from '../store'
import EnduserConfig from 'app/main/endUser/EnduserConfig';
import QuotesConfig from 'app/main/quotes/QuotesConfig';
import RequestQuotesConfig from 'app/main/request-quote/RequestQuotesConfig';
import InvoicesConfig from 'app/main/invoices/InvoicesConfig';
import SalesOrdersConfig from 'app/main/sales_orders/SalesOrdersConfig';
import CatalogConfig from 'app/main/catalog/CatalogConfig';
import ChatAppConfig from 'app/main/chat/ChatAppConfig';
import ReportsConfig from 'app/main/reports/ReportsConfig';
import NoAuthRoutesConfig from 'app/main/nonAuth/NoAuthRoutesConfig';
import CategoryConfig from 'app/main/category/CategoryConfig';
import ModelConfig from 'app/main/model/ModelConfig';
import ManufacturerConfig from 'app/main/manufacturers/ManufacturerConfig';
import ShippingConfig_ from 'app/main/shipping/ShippingConfig';
import AssetStatusConfig from 'app/main/asset-status/AssetStatusConfig';
import AccessoryConfig from 'app/main/accessory/AccessoryConfig'
import AccessoryCategoryConfig from 'app/main/accessory-category/AccessoryCategoryConfig'
import Registration from 'app/main/registration/RegistrationConfig';
import DepartmentConfig from 'app/main/department/DepartmentConfig';
import IncidentConfig from 'app/main/incident/IncidentConfig';
// import SubscriptionConfig from 'app/main/subscription-status/subscriptionStatusConfig'
import ModelCustomAttributesConfig from 'app/main/model-custom-attributes/ModelCustomAttributesConfig';
// import SubscriptionConfig_ from 'app/main/subscription/SubscriptionConfig_';
// import SubscriptionCategoryConfig from 'app/main/subscription-category/SubscriptionCategoryConfig';
import PaymentTermConfig from 'app/main/paymentTerm/PaymentTermConfig'
import ContractStatusConfig from 'app/main/contract-status/ContractStatusConfig'
import ServiceLevelAgreementConfig from 'app/main/service-level-agreement/ServiceLevelAgreementConfig';
import ServiceProviderConfig from 'app/main/service-provider/ServiceProviderConfig';
import ConnectorConfig from 'app/main/connectors/ConnectorConfig';
// import CloudProviderConfig from 'app/main/cloud-provider/CloudProviderConfig';
import ArchivedContractsConfig from 'app/main/archived_contracts/ArchivedContractsConfig'
import ContractTypeConfig from 'app/main/contract-types/ContractTypeConfig';
import ServiceRequestConfig from 'app/main/service-request/ServiceRequestConfig';
import LocationTypeConfig from 'app/main/locationType/LocationTypeConfig';
import ArchivedServiceRequestConfig from 'app/main/archived-service-request/ArchivedServiceRequestConfig';
import UserAuditLogConfig from 'app/main/user-audit-logs/UserAuditLogConfig';
import NotificationConfig from 'app/main/notifications/dashboardConfig';
import CSVConfig from 'app/main/csv/dashboardConfig';


const routeConfigs = [ 
	LoginConfig, Registration, ExampleConfig, RolesConfig, EnduserConfig, UsersConfig, CompaniesConfig, 
	AssetsConfig, AssetsCatalog,AssetsListing, AssetsDashboard, AccessoryConfig, AccessoryCategoryConfig, ContractsConfig, SettingConfig, ResetConfig, dynamicFieldsConfig, 
	SetPasswordConfig, ModuleSettingConfig, ProfileConfig, ShippingConfig, BillingConfig, PriceCatalogueConfig, 
	QuotesConfig, RequestQuotesConfig, InvoicesConfig, SalesOrdersConfig, ServiceRequestConfig, CatalogConfig, ChatAppConfig, 
	ReportsConfig, NoAuthRoutesConfig, PaymentTermConfig, CategoryConfig, ModelConfig, ManufacturerConfig, ShippingConfig, 
	ShippingConfig_, AssetStatusConfig, DepartmentConfig, IncidentConfig, AssetStatusConfig, 
	DepartmentConfig, IncidentConfig,ModelCustomAttributesConfig,
	ContractStatusConfig, ServiceLevelAgreementConfig, ServiceProviderConfig, ConnectorConfig,
	ArchivedContractsConfig,ArchivedAssetsConfig, ContractTypeConfig,LocationTypeConfig,ArchivedServiceRequestConfig,
	UserAuditLogConfig, NotificationConfig, CSVConfig
];

// console.log(store.getState().auth.user.role);

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	{
		path: '/',
		exact: true,
		component: () => <Redirect to={store.getState().auth.user.role.length !== 0? "/assets-dashboard" : "/login"} />
	},
	...FuseUtils.generateRoutesFromConfigs(routeConfigs), // [Role User]
	{
		path: '*',
		component: () => <Redirect to="/not-found" />
	},
];

//console.log(routes);

export default routes;
