import { lazy, Suspense, Component } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import ErrorBoundary from '@fuse/components/ErrorBoundary';

const ChatApp = lazy(() => import('../../chat/ChatApp'));

export default (props) => {

    const quoteId = props.quote_id;

        return (
            <div className="w-full flex flex-col" style={{height:400}}>
                <ErrorBoundary>
                    <Suspense fallback={<FuseLoading />}>
                        {quoteId && (
                            <ChatApp 
                                chatFilter={"quote_id"}
                                chatFilterValue={quoteId}
                                getContactsApiUrl={`/chat/get-quotes-global-users/${quoteId}`} 
                            />
                        )} 
                    </Suspense>
                </ErrorBoundary>
            </div>
        );

}