import { Paper, Grid, Chip, Accordion, AccordionSummary, AccordionDetails, Typography, useTheme, Divider, TableContainer, TableHead, TableRow, TableCell, TableBody, Table } from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const NoteView = (props) => {
    const {quote_id} = props;
    const [shownotes, setshownotes] = useState([]);
    const theme= useTheme()
    const toast = useToast()

    function viewnotes(){
        axios.get(`/quotes/${quote_id}/view-note`).then(res=>{
            const {data}= res.data;
            
            if(data.length == 0){

                toast.error(`${res.data.message}`)
            }else{
                setshownotes(data)
            }
            
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        viewnotes()
    },[])

    if(shownotes.length <= 0){
        return(
            <div style={{width: '100%'}}>
            <Paper 
                square 
                style={{paddingLeft:20,paddingTop:10,paddingBottom:10,paddingRight:50}}
                className="flex flex-1 items-center justify-center"
                elevation={0}
                >
                    No note found
            </Paper>
            </div>
        )
    }

    return(
        <div style={{width: '100%'}}>
        <TableContainer>
        <Table>
        <TableHead 
        square 
        style={{background:theme.palette.action.selected}}
        >
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>User Type</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Permission</TableCell>
                    </TableRow>
        </TableHead>
        <TableBody>
                {shownotes.map(log => (
                <TableRow>
                    <TableCell>
                        {log.client_user ?
                            <>
                            {log?.client_user?.first_name || ''}&nbsp;{log?.client_user?.last_name || ''}
                            </>
                            :
                            <>{log?.user_companies_user?.first_name || ''}&nbsp;{log?.user_companies_user?.last_name || ''}</>
                        }
                    </TableCell>
                            <TableCell>
                            {log.client_user ?
                                <>
                                {
                                log.client_user && log.client_user.role && 
                                <Chip 
                                label={log.client_user?.role?.display_name} 
                                size="small" 
                                style={{borderRadius:2,marginTop:5}}
                                />
                                }
                                </>
                                :
                                <>
                                {
                                log.user_companies_user && log.user_companies_user.roles_end_user && 
                                <Chip 
                                label={log.user_companies_user?.roles_end_user?.display_name} 
                                size="small" 
                                style={{borderRadius:2,marginTop:5}}
                                />
                                }
                                </>
                            }
                            </TableCell>
                            <TableCell>{dateReformatTo_mmm_dd_yyyy(log.created_at.split('T')[0])}</TableCell>
                            <TableCell>{log.notes}</TableCell>
                            <TableCell>{
                                log.permission.slice(1).split('_')[1] ? (
                                    log.permission[0].toUpperCase()+log.permission.slice(1).split('_')[0] +' '+ log.permission.slice(1).split('_')[1]
                                ):(
                                    log.permission[0].toUpperCase()+log.permission.slice(1).split('_')[0]
                                )
                            }
                            </TableCell>
                    </TableRow>)
                )
                }
                </TableBody>
                </Table>
                </TableContainer>
            </div>
    )
}

export default NoteView;