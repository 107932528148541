import Pusher from 'pusher-js';
import { createSlice } from '@reduxjs/toolkit';

const isPusherEnabled = Boolean(process.env.REACT_APP_PUSHER_ENABLED == "true");

if(!isPusherEnabled){
    console.log("%cPusher is disabled!","font-size: 16px;color: red;");
}

const pusherSlice = createSlice({
    name: 'pusher',
    initialState: {
        pusher: null,
        channel: null,
        channelName: "",
        userCount: 0,
        isReady: false,
    },
    reducers: {
        initPusher: (state, action) => {
            try {
                const { token, platformCustomerId } = action.payload;

                if (isPusherEnabled && token && platformCustomerId) {
                    const authURL = `${process.env.REACT_APP_BACKEND_API_URL}${process.env.REACT_APP_PUSHER_AUTH_ENDPOINT}`;

                    const pusher = new Pusher(process.env.REACT_APP_PUSER_KEY, {
                        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
                        authEndpoint: authURL,
                        auth: {
                            headers: { "authorization": `Bearer ${token}` },
                        },
                    });

                    const channelName = `presence-${platformCustomerId}-dalos-app`;
                    const broadcastChannelName = 'dalos-broadcast'

                    const channel = pusher.subscribe(channelName);
                    const broadcastChannel = pusher.subscribe(broadcastChannelName)

                    state.pusher = pusher;
                    state.channel = channel;
                    state.broadcastChannel = broadcastChannel
                    state.channelName = channelName;
                    state.isReady = true;
                }

            } catch (error) {
                console.log(error);
            }
        },
        updateUserCount: (state) => {
            try {
                if(isPusherEnabled){
                    state.userCount = state.channel.members.count;
                }
            } catch (error) {
                console.log(error);
            }
        },
        resetPusher: (state) => {
            if(isPusherEnabled && state.isReady){
                state.channel.unbind();
                state.pusher.unsubscribe(state.channelName);

                state.pusher = null;
                state.channel = null;
                state.broadcastChannel = null
                state.channelName = "";
                state.userCount = 0;
                state.isReady = false;
            }
        }
    }
});

export const { initPusher, updateUserCount, resetPusher } = pusherSlice.actions;

export default pusherSlice.reducer;
