import React, {useEffect,useRef,useState} from 'react'
import axios from "@fuse/utils/axios";
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import useToast from '@fuse/hooks/useToast';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { downloadFile } from '@fuse/utils/fileUtils';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';

const useStyles = makeStyles({

    root:{
      // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
      //   border: '2px solid black',
      //   display: 'none !important'
      // }
      '& .MuiDataGrid-columnsContainer':{
        background: '#8f8f8f26'
      }
    }
});

import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@material-ui/core';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { useDispatch } from 'react-redux';


function FilesTab({assetDetails,assetFiles,fetchFiles}) {

    const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

	const gridRef = useRef()
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
     
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };

    const columns = [
        { field: 'upload_date', headerName: 'Upload Date', width: 200, headerComponentFramework: CustomFilter,
            cellRendererFramework: (params) => {
                return (<span>{dateReformatTo_mmm_dd_yyyy(params.value)}</span>)
            }
        },
        { field: 'file_name', headerName: 'File Name',width: 400, headerComponentFramework: CustomFilter,
            cellRendererFramework: (params) =>{
                return (<span style={{color: "blue"}}>{params.value}</span>)
            }
        },
        { field: 'action', headerName: 'Action',width: 200,
            cellRendererFramework: (params) =>{
                return (
                    <>
                        <i style={{fontSize: '20px', cursor: 'pointer'}} className='ti ti-download' onClick={()=>downloadFile(params.data.file_link,params.data.file_name)} />
                        <i style={{fontSize: '20px', color: '#F04438', cursor: 'pointer'}} className='ti ti-trash' onClick={()=>setDeleteFileDetails(params.data)} />
                    </>
                )
            }
        }
    ];

    const classes = useStyles();
    const [ dialogStateOpen,setDialogStateOpen ] = useState(false)
    const assetId = assetDetails.id
    const [ data,setData ] = useState([])
    const [ deleteFileDetails, setDeleteFileDetails ] = useState(null)
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    function handleClose(){
        setDialogStateOpen(false)
    }

    function deleteFile(delete_file_id){
        axios.delete(`/assets/delete-asset-files/${delete_file_id}`).then((res)=>{
            fetchFiles()
            setDeleteFileDetails(null)
            console.log('delete clicked', deleteFileDetails)
            toast.success(res.data.message || 'Success')
        }).catch((err)=>{   
            console.log(err);
        })
    }

    function handleModalClose(){
        setDeleteFileDetails(null)
    }

    // function fetchFiles(){
    //     axios.get(`/assets/get-asset-files/${assetId}`).then((res)=>{
    //         const { files } = res.data.data;
    //         setData(files)
    //         getAssetFileCount(files.length)
    //     }).catch((err)=>{   
    //         console.log(err);
    //     })
    // }

    // useEffect(()=>{
    //     fetchFiles()
    // },[])


    return (
        <>
            <div className={classes.root}>
                {/* <Button 
                    style={{
                        marginLeft: '88%',
                        marginBottom: '8px'
                    }} 
                    variant='contained' 
                    color="primary"
                    onClick={()=>setDialogStateOpen(true)}
                >Upload files</Button> */}
                {/* <div className='flex flex-wrap justify-end'>
                    <Button className='button primary_btn mb-10'
                        onClick={()=>setDialogStateOpen(true)}
                    >
                        <i className="icon ti ti-upload" />
                        Upload File
                    </Button>
                </div> */}
                {/* <DataGrid
                    rows={assetFiles}
                    columns={columns}
                    hideFooter={true}
                /> */}
                
                {assetFiles.length === 0 && 
                    <div className='no_data_holder'>
                        <img src='assets/images/nodata.svg' alt='icon' />
                        <p className='bold_txt'>It's all empty here!</p>
                        <p className='normal_txt'>No data to show yet</p>
                    </div>
                }

                {assetFiles.length > 0 && 
                    <div 
                        className="ag-theme-alpine" 
                        style={{
                        width: "100%",
                        height: windowHeight+105,
                        fontSize:'12px'
                        }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            rowData={assetFiles}
                            animateRows
                            onGridReady = { onGridReady }
                            paginationPageSize={10}
                            pagination
                            defaultColDef={{
                                resizable: true
                            }}
                        >
                            {columns.map(header => {
                                return(
                                    <AgGridColumn 
                                        key = {header.field} 
                                        field = {header.field}
                                        headerName = {header.headerName}  
                                        width = { header.width }
                                        minWidth = { header.minWidth }
                                        maxWidth = { header.maxWidth }
                                        sortable = {false}
                                        flex = {1}
                                        filter = "text"
                                        wrapText
                                        cellRendererFramework = { header.cellRendererFramework}
                                        headerComponentFramework = {header.headerComponentFramework}								
                                    />
                                )
                                
                            })}
                        </AgGridReact>
                    </div>
                }

                {dialogStateOpen && <DropZone 
                        open={dialogStateOpen} 
                        handleClose={handleClose} 
                        assetId={assetId}
                        fetchFiles={fetchFiles}
                    />
                }
            </div>
            <GeneralModal
                open={Boolean(deleteFileDetails)}
                title={'Delete File'}
                text={<h4>Are you sure to delete this file named as <b>{deleteFileDetails?.file_name}</b>?</h4>}
                handleClose={handleModalClose}
                handleConfirm={()=>deleteFile(deleteFileDetails.id)}
            />
        </>
    )
}

export default withRouter(FilesTab)

function DropZone({open,handleClose,assetId,fetchFiles}){
    const uploadInput = useRef();
	const scrollRef = useRef();
    const [loading, setLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(false);
	const [uploadedFileInfo, setUploadedFileInfo] = useState({});
	const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    function setDefault (){
        setSelectedFile(null)
        setUploadedFileInfo({})
        setIsFileSelected(false)
        fetchFiles()
        handleClose()
    }

    function handleFileDragOver(event) {
		event.preventDefault();
		if (!isDragging) {
			setIsDragging(true)
		}
	}

	function handleFileDragLeave(event) {
		event.preventDefault();
		if (isDragging) {
			setIsDragging(false)
		}
	}

	function handleFileOnDrop(event) {
		event.preventDefault();
		setIsDragging(false)
		if (event.dataTransfer.files.length) {
			const file = event.dataTransfer.files[0];
            console.log('file-drop: ',file);
			setSelectedFile(file)
			handleFileInput({}, file);
		}
	}
    
    function handleFileInput(event, file = null) {
        if (!file) {
            file = uploadInput.current.files[0];
		}
		if (!file) {
            return;
		}
        // console.log('file: ',file);
		setSelectedFile(file);
		setUploadedFileInfo({ name: file.name, type: file.type, size: file.size });
		setIsFileSelected(true);
	}

    function handleFileUpload(){
        if (!selectedFile) return;
        const fileExtension = uploadedFileInfo.name.split('.').pop();
        let allowedExtensions = ["doc","docx","pdf","csv","txt","config","conf"]
        const shouldAcceptFileType = allowedExtensions.includes(fileExtension.toLowerCase());

        if(!shouldAcceptFileType){
            toast.error(`.${fileExtension} File not supported`);
            return
        }
        if(uploadedFileInfo.size > 10000000){
            toast.error("File size cannot exceed 10MB!");
            return
        }
        const formData = new FormData();
		formData.append("file", selectedFile);
        setLoading(true)
        axios.post(`/assets/upload-asset-file/${assetId}`,formData, {
			headers: {'Content-Type': 'multipart/form-data'}
        }).then((res)=>{
            setDefault()
            toast.success(res.data.message || 'Successfully uploaded')
        }).catch((err)=>{
            console.log(err)
        }).finally(()=>{
            setLoading(false)
        })
    }

    return(
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">
                Upload Files
            </DialogTitle>
            <DialogContent ref={scrollRef}>
                <div>
                    <div className="text-center" role="button">
                        {!loading && <>
                            <div style={{
                                padding: 80,
                                border: `4px dashed ${isDragging ? '#6B2C57' : '#999'}`
                            }}
                                onClick={() => uploadInput.current.click()}
                                onDragOver={handleFileDragOver}
                                onDragLeave={handleFileDragLeave}
                                onDrop={handleFileOnDrop}
                            >
                                <Icon fontSize="large" color="action" title="Upload CSV">
                                    cloud_upload
                                </Icon>
                                {isFileSelected && <>
                                    <p style={{ marginTop: 5 }}>{uploadedFileInfo.name}</p>
                                    <Button style={{ marginTop: 10 }} onClick={(event) => {
                                        event.stopPropagation();
                                        handleFileUpload();
                                    }} color="primary" variant="contained">
                                        Upload
                                    </Button>
                                </>}
                            </div>
                        </>}
                        {loading && <>
                            <CircularProgress />
                        </>}
                    </div>
                </div>
				<input
					ref={uploadInput}
					className="hidden"
					id="button-file"
					type="file"
					onChange={handleFileInput}
				/>
			</DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}