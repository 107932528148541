import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button } from '@material-ui/core';
import axiosgeneral from "@fuse/utils/axios";

const useStyles = makeStyles({
	layoutRoot: {}
});

function DynamicFieldsTable(props) {
    const classes = useStyles();
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [loading, setLoading] = useState(false);

	function getData(){
		setLoading(true);
		axiosgeneral.get(`/dynamic-attributes/fetch/${props.platformModuleId}`)
		.then(res => {
			setLoading(false);
			props.setNewPlatformModule({...props.platformModule,platform_customer_module_attributes: res.data.data});
			// console.log(platformModules);
		})
		.catch(err => {
			setLoading(false);
		})
	}

	useEffect(() => {
		getData()
	}, []);


	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event.target.value);
	}

	// if (loading) {
	// 	return <FuseLoading />;
	// }

	if(loading){
		return (<FuseLoading/>)
	}

	if (!props.platformModule.platform_customer_module_attributes || props.platformModule.platform_customer_module_attributes.length == 0) {
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-center h-full"
			>
				<Button
				color="primary"
					onClick={()=>props.openAddAttributeDialog(props.platformModuleId)}
				>
					Add field
				</Button>
			</motion.div>
		);
	}

	return (
		<div className="w-full flex flex-col">
			
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-end h-full"
			>
				<Button
				color="primary"
					onClick={()=>props.openAddAttributeDialog(props.platformModuleId)}
				>
					Add field
				</Button>
			</motion.div>
			<FuseScrollbars className="flex-grow overflow-x-auto">
				<Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
					

					<TableBody>
						{
							props.platformModule.platform_customer_module_attributes
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map(attr => {
								return(
									<TableRow
										className="h-72 cursor-pointer"
										hover
										tabIndex={-1}
										key={attr.id}
									>
                                        <TableCell className="p-4 md:p-16" component="th" scope="row">
											{attr.label}
										</TableCell>
                                        <TableCell className="p-4 md:p-16" component="th" scope="row">
                                            <Chip label={attr.attribute_types.attribute_type} className="m-2"/>
                                            <Chip label={attr.is_visible ? 'visible' : 'hidden'} className="m-2"/>
										</TableCell>
                                        <TableCell className="w-52 px-4 md:px-0" component="th" scope="row">
                                            <ButtonGroup color="primary" aria-label="button group" fullWidth variant="outlined">
                                                <IconButton
													onClick={()=>props.openUpdateAttributeDialog(props.platformModule.id,attr)}
												>												
                                                    <Icon className="text-green text-20">edit</Icon>
                                                </IconButton>
                                                <IconButton
													onClick={() => props.consfirmDelete(attr)}
												>												
                                                    <Icon className="text-red text-20">delete</Icon>
                                                </IconButton>
                                            </ButtonGroup>
										</TableCell>
                                </TableRow>
								)
							})
						}
                                
					</TableBody>
				</Table>
			</FuseScrollbars>

			<TablePagination
				className="flex-shrink-0 border-t-1"
				component="div"
				count={props.platformModule.platform_customer_module_attributes.length}
				rowsPerPage={rowsPerPage}
				page={page}
				backIconButtonProps={{
					'aria-label': 'Previous Page'
				}}
				nextIconButtonProps={{
					'aria-label': 'Next Page'
				}}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</div>
	);

}

export default withRouter(DynamicFieldsTable)
