import React, {useEffect,useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles({

    root:{
      // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
      //   border: '2px solid black',
      //   display: 'none !important'
      // }
      '& .MuiDataGrid-columnsContainer':{
        background: '#8f8f8f26'
      }
    }
});

import { withRouter } from 'react-router-dom';

  

function ApplicationsTab(props) {
    const classes = useStyles();
    const [statusData,setStatusData] = useState([])

    useEffect(()=>{
        
    },[])


    return (
        <div className={classes.root} style={{height: 290, width: '100%',textAlign:'center',overflow:"auto"}}>
            No Data found
        </div>
    )
}

export default withRouter(ApplicationsTab)
