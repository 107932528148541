import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button, AccordionActions } from '@material-ui/core';
import axiosgeneral from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ListView from './listView';
import GridView from './gridView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	  },
	  root: {
		// minWidth: 275,
	  },
	  title: {
		fontSize: 14,
	  },
	  pos: {
		marginBottom: 12,
	  },
});

function EndUsersAccountsTable(props) {
    const classes = useStyles();
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [loading, setLoading] = useState(false);
	let endUsersAccountsMod = props.endUsersAccounts
		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event.target.value);
	}


	if(loading){
		return (<FuseLoading/>)
	}

	if(endUsersAccountsMod.length <= 0){
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-center h-full"
			>
				<div style={{height: "200px"}}>
				<Typography variant="body2" component="p" color="textSecondary">
				No data found
				</Typography>
				</div>
			</motion.div>
		)
	}

	return (
		<div className="w-full flex flex-col">
			
			{/* <motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-end h-full"
			>
				<Button
				color="primary"
					onClick={()=>props.openAddAttributeDialog(props.platformModuleId)}
				>
					Add field
				</Button>
			</motion.div> */}
			<SwitchView selectedView={props.selectedView} endUsersAccounts={endUsersAccountsMod} 
				gotoDetailsPage={props.gotoDetailsPage}
			/>
			<TablePagination
				className="flex-shrink-0 border-t-1 mt-10"
				component="div"
				count={props.endUsersAccounts.length}
				rowsPerPage={rowsPerPage}
				page={page}
				backIconButtonProps={{
					'aria-label': 'Previous Page'
				}}
				nextIconButtonProps={{
					'aria-label': 'Next Page'
				}}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</div>
	);

}

function SwitchView({endUsersAccounts,selectedView,gotoDetailsPage}) {
	if(selectedView == 0){
		return(<ListView endUsersAccounts={endUsersAccounts} 
			gotoDetailsPage={gotoDetailsPage}/>)
	}else{
		return(<GridView endUsersAccounts={endUsersAccounts}
			gotoDetailsPage={gotoDetailsPage}
		/>)		
	}
}

export default withRouter(EndUsersAccountsTable)
