// import DemoContent from '@fuse/core/DemoContent';
import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect, Fragment } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { connect } from 'react-redux';
import { hideMessage, showMessage } from '../../../store/fuse/messageSlice';
import { openDialog, closeDialog } from '../../../store/fuse/dialogSlice';
import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button, Icon } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import {TextField,Menu,MenuItem} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Media from 'react-media';
import IconButton from '@material-ui/core/IconButton';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { ReactComponent as Eye } from '../icons/eye.svg';
import { ReactComponent as GridView } from '../icons/grid_view.svg';
import { ReactComponent as ListView } from '../icons/list_view.svg';
import { ReactComponent as SearchIcon } from '../icons/search.svg';
import { ReactComponent as UploadIcon } from '../icons/upload.svg';
import SvgIcon from "@material-ui/core/SvgIcon";
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

import SaveReportDialog from './saveReportDialog';

const useStyles = makeStyles({
	layoutRoot: {
		background:"#fcfcfc"
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:"#fcfcfc"
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [invoices, setInvoices] = useState([]);
	const router = useRouter();
	const agents = getAgents(invoices);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);

	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});

	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function getAgents(invoices){
		const agentSet = new Set();
		for(let i = 0; i < invoices.length ; i++){
			agentSet.add(invoices[i].Agent);
		}
		return Array.from(agentSet);
	}

	function applyFilters(filters){
		setFilters(filters);
		setInvoices(invoices.filter(acc=>{
			let flag = true;
			if(filters.agent != '' && filters.agent != acc.Agent){
				flag = false;
			}
			return flag;
		}))
	}

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};
	

	function toggleView(val){
		setSelectedView(val);
	}

	function gotoDetailsPage(id){
		router.push(`invoices/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	function toNumberFormat(numberString){
		const number = Number(numberString);
		let fixed = number.toFixed(2);
		let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
		let [formatedNumber] = number.toLocaleString().split(".");
		const result = `$${formatedNumber}.${secondNumber}`;
		return result
	}

	function getInvoices(){
		return axios.get(`/invoices?filter=${router.query.filter != undefined ? router.query.filter : "_"}`).then((res) => {
				const { data } = res.data;

				data.forEach((item)=>{
					item.invoice_date = dateReformatTo_mmm_dd_yyyy(item.invoice_date.split('T')[0])
					item.due_date = dateReformatTo_mmm_dd_yyyy(item.due_date.split('T')[0])
					item.handling_charges = toNumberFormat(item.handling_charges)
					item.tax_amount = toNumberFormat(item.tax_amount)
					item.shipping_charges = toNumberFormat(item.shipping_charges)
				})
				setInvoices(formatInvoices(data))
		}).catch((err) => {
			console.log(err)
		})
	}

	function aftersearchList(data){
		data.forEach((item)=>{
			item.invoice_date = dateReformatTo_mmm_dd_yyyy(item.invoice_date.split('T')[0])
			item.due_date = dateReformatTo_mmm_dd_yyyy(item.due_date.split('T')[0])
		})
		setInvoices(formatInvoices(data))
	}
	
	function handleSearchReset(){
		getInvoices()
	}

	function getSearchFilters(){
		setSearchFilters([
			{field: "hardware_sku", label: "Hardware SKU"},
			{field: "service_plan", label: "Service plan"},
		])
	}

	function getCustomFilters(){
		axios.get('/invoices/get-filters')
		.then(res => {
			const { data } = res.data;
			setCustomFilters(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	function getSearchSuggetions(query, field){
		axios.get('/invoices/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	// function handleSearch(query, field, filters) {
	// 	axios.get('/invoices/search',{
	// 		params: {
	// 			query,
	// 			field,
	// 			filters
	// 		}
	// 	})
	// 	.then(res => {
	// 		const { data } = res.data;
	// 		setInvoices(data)
	// 	})
	// 	.catch(err => {
	// 		console.log(err);
	// 	})
	// }
	
	function formatInvoices(data){
		return data.map((item) => {

			const dynamicAttributes = Object.entries(item.dynamic_attributes).reduce((acc, [key, item]) => {
				acc[key] = item.attribute_value

				return acc;
			},{});

			return {
				...lodash.omit(item, [
					"platform_customer_id",
					"client_user_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
				]),
				...dynamicAttributes,
			}
		})
	}

	function getDynamicAttributes(){
		return axios.get("/dynamic-attributes/fetch-all")
		.then((res) => {
			const { data } = res.data;

			const platformModuleItem = data.find(({name}) => name == "invoice_header");

			setPlatformModule(platformModuleItem);

			return axios.get(`/dynamic-attributes/fetch/${platformModuleItem.id}`)
				.then((res) => {
					const { data } = res.data;

					setPlatformModuleAttributes(data)

					return data;
				})
				.catch(err => {
					toast.error("Unable to fetch dynamic attributes")
					console.log(err)
				})
		})
		.catch(err => {
			toast.error("Unable to fetch dynamic attributes")
			setPlatformModule({});
			console.log(err)
		})
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			return getInvoices();
		})
	}

	useEffect(()=>{
		setLoading(true)
		// getSearchFilters();
		// getCustomFilters();
		getDynamicAttributes().then(() => {
			return getInvoices();
		}).finally(() => {
			setLoading(false)
		});
	},[]);

	if(loading){
		return(<FuseLoading />)
	}else{
	return (<>
			{/* <CsvUpload refresh={refreshList}/> */}

			<FusePageCarded
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0',
				topBg:classes.topBg,
				contentWrapper: classes.contentWrapper
			}}
			header={
				<DynamicFieldsHeader 
					searchList={aftersearchList}
					//handleSearch={handleSearch} 
					//searchSuggestions={searchSuggestions} 
					//clearSearch={clearFilters}
					//searchFilters={searchFilters}
					//handleSearchSuggestions={handelSearchSuggestions}
					handleSearchReset={handleSearchReset}
					// onSearchChange={(searchValue, fieldValue) => {
					// 	setCurrentSearchParameters({query: searchValue, field: fieldValue})
					// }}
				/>
			}
			contentToolbar={
				<>
						<Tabs
							value={selectedTab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="off"
							className="w-full h-64"
						>
							<Tab key="end_user_tab_1" className="h-64" label="All invoices" />
							{/* <Tab key="end_user_tab_2" className="h-64" label="My accounts" /> */}
							
						</Tabs>
				    {/* <div>
							<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
								{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={viewMenuOpened}
								onClose={handleViewMenuClose}
							>
								{	
									views.map((view,index) => {
										return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
									})
								}
							</Menu>
					</div> */}

				{/* <div className="p-10">
				<Media queries={{
				medium: "(max-width: 900px)",
				large: "(min-width: 1200px)"
				}}>
					{
						matches => (
							<>
								{matches.large && 
									<Button
										onClick={() => dispatch(openCsvDialog())}
										variant="contained"
										color="secondary"
										className={`w-full ${classes.buttonSquare}`}
										style={{width:'150px'}}
										startIcon={
											<SvgIcon fontSize="small"><UploadIcon  color="action" /></SvgIcon>
										}>
											Upload CSV
									</Button>
								}
								{
									matches.medium && 
									<IconButton
									onClick={() => dispatch(openCsvDialog())}
									variant="contained"
									color="secondary"
									className={classes.buttonSquare}
									>
										<SvgIcon fontSize="small"><UploadIcon  color="action" /></SvgIcon>
									</IconButton>
								}
							</>
						)
					}
				</Media>
				</div>
				<div className="p-10">
				<Media queries={{
				medium: "(max-width: 900px)",
				large: "(min-width: 1200px)"
				}}>
					{
						matches => (
							<>
								{matches.large && 
									<Button
										variant="contained"
										color="secondary"
										className={`w-full ${classes.buttonSquare}`}
										style={{width:'150px'}}
										startIcon={
										<Icon fontSize="large" title="Save View">
											save
										</Icon>}>
											Save View
									</Button>
								}
								{
									matches.medium && 
									<IconButton
									onClick={() => dispatch(openCsvDialog())}
									variant="contained"
									color="secondary"
									className={classes.buttonSquare}
									>
										<Icon>
											cloud_upload
										</Icon>
									</IconButton>
								}
							</>
						)
					}
				</Media>
				</div> */}
		</>
			}
			content={
				<>
				<div className="p-0">
					<SalesOrderTable 
						endUsersAccounts={invoices}
						selectedView={selectedView}
						gotoDetailsPage={gotoDetailsPage}
					/>
				</div>
				<SaveReportDialog />
				</>
			}
			innerScroll
		/>
		
	</>)
	}
}


export default CardedFullWidth2TabbedSample;

