const initialState = {
    filter : {},
    rowGroupModel : [],
    query:null,
    module_name: null
};

const actionTypes = {
    SET_FILTER_LIST : 'SET_ASSET_STATUS_FILTER_LIST',
    SET_ROW_GROUP_MODEL : 'SET_ROW_GROUP_MODEL',
    FILTER_ASSET_STATUS_DATA: 'FILTER_ASSET_STATUS_DATA',
    SELECTED_MODULE_NAME: 'SELECTED_MODULE_NAME'
}

export function setfilterlist(data){
    return {
        type:actionTypes.SET_FILTER_LIST,
        payload: {
            data
        }
    }
}

export function setfilterQuery(query = null){
    return {
        type:actionTypes.FILTER_ASSET_STATUS_DATA,
        payload: {
            query
        }
    }
}

export function setrowGroupModel (data){
    return {
        type:actionTypes.SET_ROW_GROUP_MODEL,
        payload: {
            data
        }
    }
}

export function setSelectedModule(module_name){
    return {
        type:actionTypes.SELECTED_MODULE_NAME,
        payload: {
            module_name
        }
    }
}

export default function (state = initialState , action){
    switch(action.type){
        case actionTypes.SET_FILTER_LIST : {
            return {
                ...state,
                filter : {...action.payload.data}
            }
        }
        case actionTypes.FILTER_ASSET_STATUS_DATA : {
            return {
                ...state,
                query: action.payload.query
            }
        }
        case actionTypes.SET_ROW_GROUP_MODEL :{
            return {
                ...state,
                rowGroupModel : [...action.payload.data]
            }
        }
        case actionTypes.SELECTED_MODULE_NAME :{
            return {
                ...state,
                module_name : action.payload.module_name
            }
        }
        default : return state
    }
}