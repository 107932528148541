import React, { useState } from 'react'
import {
  Button,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
} from '@mui/material';
import Editor from '@fuse/components/Editor';
import NotesIcon from './notePad.png';
import axiosInstance from '@fuse/utils/axios';
import { useRouter } from '@fuse/hooks';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast'

function AddNotes({ open, close, content, module }) {
  const [ editorContent, setEditorContent ] = useState(content);
  const [ assetNoteText, setAssetNoteText ] = useState(content);
  const router = useRouter();
  const entityId = router.params.id;
  const dispatch = useDispatch()
	const toast = useToast(dispatch)

  function onEditorContentChange(content){
    setEditorContent(content)
  }

  function saveNote(){
    let apiUrl = '', payload = {}
    if(module === "asset"){
      apiUrl = `/assets/${entityId}/update-description`;
      payload = {
        notes: assetNoteText,
        note_style: editorContent
      }
    }
    else if(module === "contract"){
      apiUrl = `/contracts/${entityId}/update-description`;
      payload = {
        contract_description: editorContent
      }
    }
    axiosInstance.put( apiUrl, {
      ...payload
    }).then((res)=>{
      toast.success('Notes Updated Successfully')
      if(module === "contract"){
      close(res.data.data.updatedDescription)
      }else{
      close(res.data.data.updatedAsset)
      }
    }).catch((err)=>{
      console.log(err)
    })
  }

  return (
    <Dialog
      className="cstm_dialog_modal small text-center"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div className='heading_holder flex justify-end items-center w-full'>
          <Button className='transparent_icon_btn' onClick={close}>
            <i className="icon ti ti-x" />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          <img className='mx-auto' src={NotesIcon} alt='icon' />
          <h4 className='modal_heading'>Edit Note</h4>
          <Editor editorContent={editorContent} onEditorContentChange={onEditorContentChange} setAssetNoteText={setAssetNoteText}/>
        </div>
      </DialogContent>
      <DialogActions className="action_footer_holder p-24">
        <Button className="primary_btn" onClick={saveNote}>
          <i className='ti ti-check' />
          <span className='pl-5'>Save</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddNotes