import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store from './store';
import { persistor } from './store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import axiosgeneral from "@fuse/utils/axios";
import { GoogleOAuthProvider } from '@react-oauth/google';

import Pusher from './containers/pusher';
import AuthCheckContainer from './containers/authCheck';

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName({ disableGlobal: true });

const App = () => {
	const clientId =process.env.REACT_APP_GOOGLE_CLIENT_ID2;
	// window.addEventListener('storage', (event) => {
	// 	console.log('event');
	// 	const credentials = axiosgeneral.defaults.headers.common.Authorization
	// 	if(event.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials) {
	// 	  window.localStorage.setItem('CREDENTIALS_SHARING', credentials)
	// 	  window.localStorage.removeItem('CREDENTIALS_SHARING')
	// 	}
	// 	if(event.key === 'CREDENTIALS_SHARING' && !credentials){
	// 	  console.log(event.newValue);
	// 	  axiosgeneral.defaults.headers.common.Authorization = event.newValue;
	// 	//   window.sessionStorage.setItem('persist:user', event.newValue);

	// 	}
	// 	// if(event.key === 'CREDENTIALS_FLUSH' && credentials){
	// 	// 	axiosgeneral.defaults.headers.common.Authorization = '';
	// 	// 	this.props.logout();
	// 	// 	this.props.setUserData({});
	// 	// }
	// 	// return Promise.all([
	// 	// 	// Comment the lines which you do not use
	// 	// 	// this.firebaseCheck(),
	// 	// 	// this.auth0Check(),
	// 	// 	this.jwtCheck()
	// 	// ]).then(() => {
	// 	// 	this.setState({ waitAuthCheck: false });
	// 	// });
	//   })
	//   window.localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString())
	//   window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<Provider store={store}>
					<PersistGate loading={<FuseSplashScreen />} persistor={persistor}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Auth>
								<Pusher>
									<Router history={history}>
										<FuseAuthorization>
											<FuseTheme>
												<SnackbarProvider
													maxSnack={5}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'right'
													}}
													classes={{
														containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
													}}
												>
													<GoogleOAuthProvider clientId={clientId}>
														<AuthCheckContainer>
															<FuseLayout />
														</AuthCheckContainer>
													</GoogleOAuthProvider>
												</SnackbarProvider>
											</FuseTheme>
										</FuseAuthorization>
									</Router>
								</Pusher>
							</Auth>
						</MuiPickersUtilsProvider>
					</PersistGate>
				</Provider>
			</StylesProvider>
		</AppContext.Provider>
	);
};

export default App;
