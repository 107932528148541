import React,{useEffect,useState,useRef} from 'react';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import useRouter from '@fuse/hooks/useRouter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import RenderHtml from '@fuse/components/RenderHtml';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';

const useStyles = makeStyles({
    mainContainer:{
        paddingRight:3,
        paddingBottom:3
    }
});

const Accordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		borderBottom:'1px solid rgba(0, 0, 0, .125)'
	},
}))(MuiAccordionDetails);

const AssetsTab = ({userDetails}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(null);
    // const [userDetails, setUserDetails] = useState(null);
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
	const router = useRouter();
    const openState = useSelector((state) => state.usersApp.formViewDialog)
    // useEffect(()=>{
    //   setUserDetails(openState.user_data?.client)
    // },[openState])

	const [rowData, setRowData] = useState([])

	function formatRowData() {
		if(!userDetails) {
			return
		}
		const data = userDetails.assigned_user.map((item) => {
			return {
				'asset_id': item?.id || 'N/A',
				'serial_number': item?.serial_number || 'N/A',
				'asset_tag': item?.asset_tag || 'No asset tag',
				'asset_name': item?.asset_name || 'N/A',
				'status': item?.asset_status?.status_name || 'N/A',
				'stat_color': item?.asset_status?.status_color?.code,
				'model_name': item?.asset_model?.model_name || 'N/A',
				'model_num': item?.model_number || 'N/A',
				'category': item?.category || 'N/A',
				'location': item?.location_id || 'N/A',
				'notes': item?.notes || 'N/A', 
				'contract_num': item?.contract_header?.entity_external_platform_id || 'N/A',
				'contract_id': item?.contract_header?.id || 'N/A'
			}
		})
		setRowData(data)
	}
	useEffect(() => {
		formatRowData()
	}, [userDetails])

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	function handelPageRedirect(asset_id){
		router.push(`/assets-list/${asset_id}`)
	}

	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

	const gridRef = useRef()
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
        
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };
    
    const columns = [
        { field: 'serial_number', headerName: 'Serial Number', minWidth: 200, headerComponentFramework: CustomFilter },
        { field: 'asset_tag', headerName: 'Asset Tag', headerComponentFramework: CustomFilter, minWidth: 200 },
        { field: 'asset_name', headerName: 'Asset name', headerComponentFramework: CustomFilter, minWidth: 200 },
        { field: 'status', headerName: 'Status', minWidth: 200,
			headerComponentFramework: CustomFilter,
		},
        { field: 'model_name', headerName: 'Model Name', headerComponentFramework: CustomFilter, minWidth: 200 },
        { field: 'model_num', headerName: 'Model Number', headerComponentFramework: CustomFilter, minWidth: 200 },
        { field: 'category', headerName: 'Category', headerComponentFramework: CustomFilter, minWidth: 200 },
        { field: 'location', headerName: 'Location', headerComponentFramework: CustomFilter, minWidth: 200 },
        { field: 'notes', headerName: 'Notes', headerComponentFramework: CustomFilter, minWidth: 200 },
        { field: 'contract_num', headerName: 'Contract Number', headerComponentFramework: CustomFilter, minWidth: 200 },
    ];

	function redirectToAssetProfile(event) {
		router.push(`/assets-list/${event.data.asset_id}`);
	}

	function redirectToContractDetails(event) {
		if(event.data.contract_id === 'N/A') return
		router.push(`/contract-details/${event.data.contract_id}`);
	}

	return (
		<>
			<div style={{display:"flex",justifyContent:'space-between',alignItems:'center',marginBottom:"20px"}}>
				<div >
					<Typography variant="h6" style={{color:'#3A4252'}}>Assets</Typography>			
				</div>
				{/* <div  style={{textAlign:"right"}}>
					<Button variant="outlined">
						<Icon>
							<span class="material-symbols-outlined" style={{fontSize:"17px"}}>
								edit
							</span>
						</Icon>
						Edit
					</Button>
						
				</div> */}
			</div>

			<div 
				className="ag-theme-alpine" 
				style={{
				width: "100%",
				height: windowHeight+105,
				fontSize:'12px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					rowData={rowData}
					animateRows
					onGridReady = { onGridReady }
					paginationPageSize={10}
					pagination
				>
				{columns.map(header => {
					if (header.field == 'serial_number') {
						return (
							<AgGridColumn
							key = {header.field} 
							field = {header.field}
							headerName = {header.headerName}  
							width = { header.width }
							minWidth = { header.minWidth }
							maxWidth = { header.maxWidth }
							sortable = {false}
							flex = {1}
							filter = "text"
							wrapText
							autoHeight
							headerComponentFramework = {header.headerComponentFramework}	
							cellRendererFramework={(event) => {
								return (<a
									style={{
										// height: '10px',
										// width: '10px',
										color: 'black',
										cursor: 'pointer',
										marginTop: '2px',
										borderRadius: '50%',
										display: 'inline-block',
									}}
									onClick={() => redirectToAssetProfile(event)}
									variant="subtitle1"
								>
									{<><span>{event.value}</span></>}
								</a>)
							}}
							>
							</AgGridColumn>
						)
					}
					if( header.field === "location" ){
						return(
							<AgGridColumn 
								key = {header.field} 
								field = {header.field}
								headerName = {header.headerName}  
								width = { header.width }
								minWidth = { header.minWidth }
								maxWidth = { header.maxWidth }
								sortable = {false}
								flex = {1}
								filter = "text"
								wrapText
								autoHeight
								cellRendererFramework = { header.cellRendererFramework}
								headerComponentFramework = {header.headerComponentFramework}								
							/>
						)
					}
					if( header.field === "notes" ){
						return(
							<AgGridColumn 
								key = {header.field} 
								field = {header.field}
								headerName = {header.headerName}  
								width = { header.width }
								minWidth = { header.minWidth }
								maxWidth = { header.maxWidth }
								sortable = {false}
								flex = {1}
								filter = "text"
								wrapText
								autoHeight
								cellRendererFramework = { header.cellRendererFramework}
								headerComponentFramework = {header.headerComponentFramework}								
							/>
						)
					}
					if( header.field === "status" ) {
						return(
							<AgGridColumn 
								key = {header.field} 
								field = {header.field}
								headerName = {header.headerName}  
								width = { header.width }
								minWidth = { header.minWidth }
								maxWidth = { header.maxWidth }
								sortable = {false}
								flex = {1}
								filter = "text"
								autoHeight
								cellRendererFramework={({data}) =>{
									return (
										<>
											<span style={{display: 'inline-block', border: '1px solid rgb(0, 0, 0)', borderRadius: '16px', padding: '0 12px', margin: '4px', backgroundColor: 'transparent', lineHeight: '24px', position: 'relative'}}>
												<span style={{height: '10px', width: '10px', marginTop: '2px', borderRadius: '50%', display: 'inline-block', backgroundColor: data.stat_color}}></span>
												<span style={{marginLeft: '5px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: 'Inter, sans-serif'}}>{data.status}</span>
											</span>
										</>
									)
								}}

								headerComponentFramework = {header.headerComponentFramework}								
							/>
						)
					}

					if (header.field == 'contract_num') {
						return (
							<AgGridColumn
							key = {header.field} 
							field = {header.field}
							headerName = {header.headerName}  
							width = { header.width }
							minWidth = { header.minWidth }
							maxWidth = { header.maxWidth }
							sortable = {false}
							flex = {1}
							filter = "text"
							wrapText
							autoHeight
							headerComponentFramework = {header.headerComponentFramework}	
							cellRendererFramework={(event) => {
								return (<a
									style={{
										// height: '10px',
										// width: '10px',
										color: 'black',
										cursor: 'pointer',
										marginTop: '2px',
										borderRadius: '50%',
										display: 'inline-block',
									}}
									onClick={() => redirectToContractDetails(event)}
									variant="subtitle1"
								>
									{<><span>{event.value}</span></>}
								</a>)
							}}
							>
							</AgGridColumn>
						)
					}
					
					return(
						<AgGridColumn 
							key = {header.field} 
							field = {header.field}
							headerName = {header.headerName}  
							width = { header.width }
							minWidth = { header.minWidth }
							maxWidth = { header.maxWidth }
							sortable = {false}
							flex = {1}
							filter = "text"
							cellRendererFramework = { header.cellRendererFramework}
							headerComponentFramework = {header.headerComponentFramework}								
						/>
					)								
				})}
				</AgGridReact>
			</div>
		</>
	)
}

export default AssetsTab
