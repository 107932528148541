import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';

const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#02b305',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#02b305',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#02b305',
    },
    circle: {
      width: 18,
      height: 18,
      borderRadius: '50%',
      backgroundColor: '#752445',
    },
    circleHollow: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      border: '2px solid #eaeaf0',
    },
    completed: {
      color: '#fff',
      background: '#02b305',
      borderRadius: "50%",
      zIndex: 1,
      fontSize: 18,
    },
  });

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <Check className={classes.completed} /> : (active ?<div className={classes.circle} />:<div className={classes.circleHollow} />)}
      </div>
    );
  }

//   QontoStepIcon.propTypes = {
//     /**
//      * Whether this step is active.
//      */
//     active: PropTypes.bool,
//     /**
//      * Mark the step as completed. Is passed to child components.
//      */
//     completed: PropTypes.bool,
//   };

  function StepperCustom(props) {
    return(
    <Stepper {...props} connector={<QontoConnector />}>
        {props.steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }

  export default StepperCustom;