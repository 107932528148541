import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core';
import React, { useState,useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Autocomplete } from '@material-ui/lab';
import Editor from '@fuse/components/Editor';
// import LocationTypeDialog from './QuickAddLocationType';
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


const AddLocationTypeFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {


    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)


    const [ locationType, setLocationType ] = useState('')
    const [ locationTypeError, setLocationTypeError ] = useState('')
    const [ description, setDescription ] = useState('')


    function onEditorContentChange (content) {
		setDescription(content)
	}

    function setDefault(){
        setLocationType('')
        setDescription('')
    }


    const shippingLocationTypeFormSubmit = (e) => {
        e.preventDefault()
        if(locationType.trim() === "") {
            setLocationTypeError('Location Type is required!')
        } else {
            setLocationTypeError('')
            e.preventDefault()

            let payload = {
                name: locationType.trim(),
                description: description	
            }

            axios.post(`/location-type/add`, payload, {
            }).then(results=>{
                toast.success(results.data.message)
                setDefault()
                // console.log(results.data.data.new_location_type)
                newDataAddedEvent(results.data.data.new_location_type)
            }).catch(err=>{
                console.log(err)
                toast.error(err.response.data.message);
            })
        }
    }


    

    return (
        <>
            <Dialog
                className="cstm_dialog_modal small text-center"
                open={openAddStatusModalStateData}
                // onClose={handleOpenCloseAddModalEvent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='heading_holder flexbox_holder items-center w-full'>
                        <h2 className="main_heading">Add Location Type</h2>
                        <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                            <i className="icon ti ti-x" />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-left">
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Location Type Name
                                <span className='required_span'>*</span>
                            </Typography>
                            <TextField
                                variant='outlined'
                                error={locationTypeError === '' ? false : true}
                                    helperText={locationTypeError}
                                    FormHelperTextProps={{
                                        style: { 
                                            margin : 0, 
                                            backgroundColor: '#f4f4f4',
                                            width: '100%',
                                            paddingTop: '2px',
                                            fontSize: '10px'
                                        }
                                    }}
                                style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                onChange={(e)=>setLocationType(e.target.value)}
                            />  
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Description
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Editor
                                editorContent={description}
                                onEditorContentChange={onEditorContentChange}
                            /> 
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="action_footer_holder">
                    <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                    <Button onClick={shippingLocationTypeFormSubmit} className="primary_btn">
                        Save and Select
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );

}


export default AddLocationTypeFromQuickAction;