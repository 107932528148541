import _ from '@lodash';
import 'ag-grid-enterprise';
import Icon from '@material-ui/core/Icon';
import { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterList, setRowGroupModel } from '../store/filterParamsReducer';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import DeleteConfirmModal from 'helper/DeleteConfirm';


const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	cardGen: {
		width: '20%',
		height:"100%"
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin:'5px 5px 5px 0',
		height:'28px'
	},
	activeTab: {
		background:"rgba(107, 44, 87, 0.04);",
		borderRadius:"0",
	},
	buttonSquare : {
		borderRadius:'5px'
	},
	btn:{
		background:"#30C90A",
		width:"120px",
		borderRadius:"17px",
		color:"#fff"
	},
	icon:{
		fontSize:"18px",
		color:"#61656F",
		display:"inline-block"
	}
});

function ListView(props) {
	const table_name = "LOCATION_TYPES";
    const classes = useStyles();
	const router = useRouter()
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const [gridApi, setGridApi] = useState(null);
	const gridRef = useRef();
	const [ windowHeight, setWindowHeight ] = useState(window.innerHeight-330);
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const skipHeader = ["id","description"]
	const [locationTypeDetails, setLocationTypeDetails] = useState(null)
	const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);


	const tableHeaders = [
		"location_type", "description_as_plain_text"
	];
	const handelEdit = (data) =>{
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}

	function handelDelete(){
		if(!locationTypeDetails) return
		axios.delete(`/location-type/delete/${locationTypeDetails.id}`).then((res)=>{
			toast.success(res.data.message)
			props.getLocationTypes()
		}).catch((err)=>{
			console.log(err)
			toast.error(err.response.data.message);
		})
	}

	function formatString(str){
        if(!str) return ''
		if(str === 'description_as_plain_text') return 'Description'
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }
	
	const onGridReady = (params) =>{
		setGridApi(params);
		props.locationTypes.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){			
				if(value === '' || !value || value === '')
				  list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if(router.query.report_name == undefined) {
			// loadInitialState (params)
			// dispatch(setFilterList({}));
			// dispatch(setRowGroupModel([]));
		}else{
			getReport(params);
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "location type";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function onFilterChanged(event){ 
		const rows = event.api.getFilterModel();
		// stop rerender to show no overlay text
		dispatch(setFilterList(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function loadInitialState(params) {
		axios
		.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then((results) => {
			const { data } = results.data;
			if (data !== null) {
			const order = JSON.parse(data.order);
			const filter = JSON.parse(data.filter);
			params?.columnApi.applyColumnState({ state: order });
			params?.api.setFilterModel(filter);
			}
		})
		.catch((err) => {
			console.log(err);
		});
  	}
	function onSortChanged(params){
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setRowGroupModel(rows));
			saveView(rows);
		}
  	}

	function saveView(rows, filter) {
		let payload = {}
		if(rows == 'N/A'){
		payload = {
			filter:  JSON.stringify(filter),
			table_name : table_name
		}
		}else{
		payload = {
			order:  JSON.stringify(rows),
			table_name : table_name
		}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
		console.log('view updated')
		})
		.catch(err=>{
		console.log(err);
		})
	}

	const onColumnMoved = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
			if(router.query.report_name == undefined) {
				saveView(rows);
			}
		}
	}

	function onColumnRowGroupChanged(event){
		if(router.query.report_name == undefined){
			const rows = event.columnApi.getColumnState();
			dispatch(setRowGroupModel(rows));
			saveView(rows);
			// const first_row = rows[0];
			// if(first_row.colId !== 'ag-Grid-AutoColumn'){
			// 	dispatch(setRowGroupModel([]));
			// 	saveView(rows);
			// }else{
			// 	dispatch(setRowGroupModel(rows));
			// }
		}
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

	const generateHeaderLabel = (header) => {
		if(header === 'description_as_plain_text') return 'Description'
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	const handleConfirm = (data) => {
		setOpenDeleteDialog(true)
		setLocationTypeDetails(data)
	}

	const handleClose = () => {
		setOpenDeleteDialog(false)
		setLocationTypeDetails(null)
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])

	useEffect(()=>{
		return () => {
			dispatch(setQuickFilter(null))
		}
	},[])

	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	//Logs
	function formatLogs(data){
		let length = data.length
		return data.map((item)=>{
				const obj =  {
						id:item.id,
						action_id: `act${length}`,
						
						action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
						location_type_name: item?.location_type?.name || item.type_name,
						name: item.user_name,
						action: item.action_name,
						description: item.comment,
						previous_value: item.previous_data,
						current_value: item.current_data,
						field_name: item.action_field_name,
						user_role: item.user_role_name
				}
				length --;
				return obj
		})
	}

	function getLogs(){
		// setLogsLoading(true)
		axios.get("/location-type/activities").then((res) => {
			setLogs(formatLogs(res.data.data.activities));
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			// setLogsLoading(false)
		});
	}

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	function handleOpenForm(){
		dispatch(openFormViewerDialog())
	}

	return (
		<>		
		<AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'location_type'} logs={logs} displayTableName = {'Location Types'}
				 onClick={handleChildClick}
				 onClickAddButton={handleOpenForm}
				 addPermission = {props.addLocationTypePermission}
			/>	
			{(tabValue == 0) && <div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'11px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					suppressRowClickSelection={true}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData={props.locationTypes}
					rowHeight = {30}
					onRowClicked={() => {
					}}
					
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }
					onSortChanged={onSortChanged}
					groupHeaderHeight ={1}
					frameworkComponents={frameworkComponents}
					floatingFiltersHeight = {40}
					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					pagination={true}
					defaultColDef={{
						resizable: true
					}}
				>
					<AgGridColumn
						lockPosition={true}
						rowSelection={'single'}
						width={100}
						minWidth={100}
						maxWidth={100}
						suppressSizeToFit={true}
						headerName="Actions"
						pinned= 'right' 
						className="action_field"
						lockPinned={true}
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>									
										{props.updateLocationTypePermission && <Tooltip id="edit" title="Edit" placement="top">
											{/* <Icon onClick={() => handelEdit(data)} style={{color: '#1d2939',paddingBottom: '3px',paddingRight:'30px',fontSize:'15px',cursor:'pointer'}}>edit</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-pencil" onClick={() => handelEdit(data)} />
										</Tooltip>}

										{props.deleteLocationTypePermission && <Tooltip id="delete" title="Delete" placement="top">
											{/* <Icon onClick={() => {handleConfirm(data)}} style={{color: '#1d2939',paddingBottom: '3px',paddingRight:'30px',fontSize:'15px',cursor:'pointer'}}>delete</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-trash" onClick={() => handleConfirm(data)} />
										</Tooltip>}
									</>
								)
							}else{
								return (<></>)
							}
						}}
					>
					</AgGridColumn>
					{tableHeaders.map(header => {
						if(skipHeader.includes(header)) return
						if(header == 'description_as_plain_text'){
							return (
								<AgGridColumn
									field={header}
									key = { header }
									// suppressSizeToFit={true}
									headerName={generateHeaderLabel(header)}  
									sortable={ true }
									flex = {1}
									minWidth={200}
									headerComponentFramework={CustomFilter}
									filter="text" 
									cellRendererFramework={(event) =>{
										return (
											<abbr style={{textDecoration: 'none'}} title={event.value}>{event.value}</abbr>
										)
									}}
									
								>
								</AgGridColumn>
							)
						}
						return (
							<AgGridColumn
								field={header}
								key = { header }
								// suppressSizeToFit={true}
								headerName={generateHeaderLabel(header)}  
								sortable={ true }
								flex = {1}
								minWidth={200}
								headerComponentFramework={CustomFilter}
								filter="text" 
								
							>
							</AgGridColumn>
						)
						
					})}
				</AgGridReact>
			</div>}

			{/* <GeneralModal
				open={locationTypeDetails}
				title={'Delete Location Type'}
				text={<h4>Are you sure to delete this location type named as <b>{locationTypeDetails?.location_type}</b>?</h4>}
				handleClose={handleClose}
				handleConfirm={handelDelete}
			/> */}
			{
				openDeleteDialog && 
				<DeleteConfirmModal
					openAlerModal={openDeleteDialog}
					module={'Location Type'}
					handleCloseAlertModal={handleClose} 
					handleAlertModal={handelDelete}
				/>
			}
		</>
	);
}

export default ListView