import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Box, Card, CardContent } from "@material-ui/core";
import Header from "./Header";
import Table from "./Table";
import image from "./searching.png"
import { AgChartsReact } from 'ag-charts-react';
import axios from '@fuse/utils/axios';
import FuseLoading from "@fuse/core/FuseLoading";
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { usePermission } from '@fuse/hooks';


function StandardReportLayout() {
  const [ standardReportProps, setStandardReportProps ] = useState(null)
  const [ chartOptions, setChartOptions ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const {broadcastChannel} = usePusher()
  const new_incident_event = "permission_change"
  const user = useCurrentUser();
  const { hasPermission } = usePermission()
  let viewAssetPermission = hasPermission('view-assets',true)

  useEffect(()=>{
    if(!standardReportProps) return
    setLoading(true)
    axios.get(`/reports/standard-reports-graph/${standardReportProps.moduleName}/${standardReportProps.reportName}`)
    .then((res)=>{
      const { graphData } = res.data.data;

      setChartOptions({
        data: graphData,
        series: [{
          type: 'column',
          xKey: Object.keys(graphData[0])[0],
          yKey: 'count'
        }]
      })
    }).catch((err)=>{
      console.log('err: ',err);
    }).finally(()=>{
      setLoading(false)
    })
  },[standardReportProps])

  useEffect(()=>{
		if(broadcastChannel){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

  return (
    <div style={{ padding: 20 }}>
      <Header setStandardReportProps={setStandardReportProps}/>
      {!standardReportProps && (
        <>
          <div className="searching-img">
            <img src={image}/>
          </div>
          <p style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '30px'
          }}>Select module and report type to show detailed report No Data?</p>
        </>
      )}
      {standardReportProps && 
        <>
          <Card
            style={{
              borderRadius: 10,
              boxShadow: "8px 8px 16px 0px rgba(0, 0, 0, 0.04)",
              border: "1px solid #EEE",
            }}
          >
            <Box
              display="flex"
              px={2}
              py={3}
              alignItems="center"
              justifyContent="space-between"
              style={{ borderBottom: "1px solid #dddddd" }}
            >
              <h4 style={{ color: 'black' }} className="font-500">Chart Report</h4>
            </Box>
            <CardContent className="p-0">
            {loading ? <FuseLoading/> : <AgChartsReact options={chartOptions} />}
              {/* <Chart
                height="280"
                width="100%"
                options={options}
                series={series}
                type="bar"
              /> */}
            </CardContent>
          </Card>
          <Card style={{
            borderRadius: 10,
            marginTop: 30,
            boxShadow: "8px 8px 16px 0px rgba(0, 0, 0, 0.04)",
            border: "1px solid #EEE",
          }}>
            {<Table standardReportProps={standardReportProps}/>}
          </Card>
        </>
      }
    </div>
  )
}

export default StandardReportLayout