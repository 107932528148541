/**
 * op_format: 'title_case' (default), 'sentence_case'
 */
 export function toReadable(raw_string, op_format="title_case"){
    return raw_string.replace(/[-_]/g, ' ')
                    .replace(/[A-Z]/g, (match, index) => ` ${match}`)
                    .toLowerCase()
                    .split(' ')
                    .map((word, index) => {
                        if(!index && op_format !== 'title_case')
                            return word
                        return word[0].toUpperCase() + word.substr(1)
                    })
                    .join(' ')
}

export function generateRecipientsString(recipients){
    switch(typeof recipients){
        case 'string':{
            return recipients
        }
        case 'object':{
            if(typeof recipients[0] === 'object'){
                return recipients[0].join(', ')
            }else{
                return recipients.join(', ')
            }
        }
        default: 
            return recipients
    }
}

export function formatString(str){
    if(!str) return str
    const allowAllCapsWords = ['id']
    return str.split('_').map(word => allowAllCapsWords.includes(word) ? word.toUpperCase() : word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
}

export function formatStringWithoutID (dataString) {
    if(!dataString) return dataString

    if(dataString.includes('_id')){
        const remove_id_string = dataString.replace('_id', '')
        return formatString(remove_id_string)
    }

    return formatString(dataString)
}

export function generateRandomNumberString () {
   return String(Math.trunc(Math.random()*20000))
}