const initialState = {
    filter : {},
    rowGroupModel : [],
    query: null
};

const actionTypes = {
    SET_FILTER_LIST : 'SET_MANUFACTURER_FILTER_LIST',
    SET_ROW_GROUP_MODEL : 'SET_ROW_GROUP_MODEL',
    FILTER_MANUFACTURER_DATA: 'FILTER_MANUFACTURER_DATA'
} 

export function setfilterlist(data){
    return {
        type:actionTypes.SET_FILTER_LIST,
        payload: {
            data
        }
    }
}

export function setfilterQuery(query = null){
    return {
        type:actionTypes.FILTER_MANUFACTURER_DATA,
        payload: {
            query
        }
    }
}

export function setrowGroupModel (data){
    return {
        type:actionTypes.SET_ROW_GROUP_MODEL,
        payload: {
            data
        }
    }
}

export default function (state = initialState , action){
    switch(action.type){
        case actionTypes.SET_FILTER_LIST : {
            return {
                ...state,
                filter : {...action.payload.data}
            }
        }
        case actionTypes.FILTER_MANUFACTURER_DATA : {
            return {
                ...state,
                query: action.payload.query
            }
        }
        case actionTypes.SET_ROW_GROUP_MODEL :{
            return {
                ...state,
                rowGroupModel : [...action.payload.data]
            }
        }
        default : return state
    }
}