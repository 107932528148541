import { useEffect, useState } from "react";
import { updateTitle } from 'app/store/fuse/pageTitleSlice';

export default function usePageTitle(dispatch) {
  const [title, setTitle] = useState("");

  useEffect(() => {
    dispatch(updateTitle(title));

    return () => {
        dispatch(updateTitle(""));
    }
  },[title])

  return (title) => {
     setTitle(title);
  }
}