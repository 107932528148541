
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({

	header:{
		width:'98%',
		margin:'auto 20px'
	},
	root:{
		width:'100%',
		backgroundColor:'white',
		color:'black',
		borderRadius:'5px'
	},

	title:{
		fontSize: 14,
		fontWeight: 600,
		marginBottom:15
	},
	pos:{
		fontSize: 12,
		color:'grey'
	}
})


function Header(props) {
	
	const classes = useStyles();

    return (
   		<div className= {classes.header} >
	        <Card className = {classes.root} >
				<CardContent>					
					<Typography className={classes.title}>
	        			Request Quote - <span className={classes.pos}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
	        		</Typography>
				</CardContent>
			</Card>
		</div>

    );

}

export default Header;