import React, { useState } from 'react'
import axios from '@fuse/utils/axios';
import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import Editor from '@fuse/components/Editor';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function LocationTypeDialog(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [locationType, setLocationType] = useState('')
    const [description, setDescription] = useState('')

    function onEditorContentChange (content) {
		setDescription(content)
	}

    function setDefault(){
        setLocationType('')
        setDescription('')
    }

    function manageLocationTypeData(e){
        e.preventDefault()

        let payload = {
			name: locationType.trim(),
			description: description	
		}

        axios.post(`/location-type/add`, payload, {
        }).then(results=>{
            toast.success(results.data.message)
            setDefault()
            props.handleCloseDialog(results.data.data.new_location_type)
            props.refresh()
        }).catch(err=>{
            console.log(err)
            toast.error(err.response.data.message);
        })

    }


    return (
        <Modal
            open={props.open}
            className={classes.modal}
        >
            <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Add Location Type</h1>
                    <IconButton onClick={()=>{
                        setDefault()
                        props.handleCloseDialog()
                    }}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <form onSubmit={(e)=>manageLocationTypeData(e)}>
                    <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>
                        <TextField
                            variant='outlined'
                            label='Location Type Name'
                            onChange={(e)=>setLocationType(e.target.value)}
                            required                                        
                            style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                        />  
                        <Editor
                            editorContent={description}
                            onEditorContentChange={onEditorContentChange}
                        />                          
                    </div>


                    <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
                        <Button
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                            type='submit'
                        >Add</Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={()=>{
                                setDefault()
                                props.handleCloseDialog()
                            }}
                        >Cancel</Button>
                    </div>
                </form>
            </div>

        </Modal>
    )
}

export default LocationTypeDialog
