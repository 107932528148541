import FusePageSimple from "@fuse/core/FusePageSimple"
import Paper from '@material-ui/core/Paper'
import Header from './header'
import { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import axios from "@fuse/utils/axios";
import { DataGrid } from '@material-ui/data-grid';
import useRouter from '@fuse/hooks/useRouter';
import TablePagination from '@fuse/components/general/TablePagination';

import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { useSelector,useDispatch } from 'react-redux';
import { useCurrentUser, usePageTitle } from '@fuse/hooks';
import { Typography } from "@material-ui/core"

const useStyles = makeStyles({
    title:{
		fontSize: 14,
		fontWeight: 600,
		marginBottom:15
	},
    noDisplayText: {
		textAlign: 'center',
		marginTop: '5vh'
	},
    content:{
		padding:'2%',
        margin:'1rem auto'
	},
    root:{
        width:'97%',
        borderRadius:'4px',
        margin:'auto',
    },
    row:{
        display:'flex',
        marginBottom:'1rem'
    },
    box:{
        width: '80%',
        height: '40px',
        borderRadius: '4px'
    },
    txt:{
        padding: '10px',
        height:'40px'
    },
    marginDiv:{
        width: '95%',
        margin: '2rem auto',
        borderTop: '1px solid black'
    },
    textArea: {
        width: '70%',
        border:'1px solid gray',
        borderRadius: '4px',
        padding:'1rem'
    },
    topBg:{
        background:'white'
    }
})
const RequestedQuotesView = () => {
    const classes = useStyles()
	const router = useRouter()
    const [requests, setRequests] = useState([])
	const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const dispatch = useDispatch();
	const updatePageTitle = usePageTitle(dispatch);

    const {data: userData} = useCurrentUser()

    if(userData.email === 'sonder.phi@gmail.com'){
        return (
            <Typography variant='subtitle1' className={classes.noDisplayText}>
                You have no quotes to show!
            </Typography>
        )
    }

    const columns = [
        { field:'request_id', headerName:'Request ID', flex:1},
        { field:'agent_name', headerName:'Agent Name', flex:1},
        { field:'user_name', headerName:'User Name', flex:1},
        { field:'created_at', headerName:'Request Date', flex:1, renderCell:(params)=>
            (
                <span>{dateReformatTo_mmm_dd_yyyy(params.row.created_at.split("T")[0])}</span>
            )
        },
        { field:'contract_type', headerName:'Contract Type', flex:1}
    ]
    
    const fetchRequestedQuotes = () => {
        axios.get('/quotes/request/get-requests').then( res => {
            setRequests(res.data.data.requests)
        }).catch( err => {
            console.log(err)
        })
    }

    const handlePageSizeChange = params => {
		setPageSize(params.pageSize)
	}

    useEffect( () => {
        fetchRequestedQuotes()
		updatePageTitle(<Title />);                
    }, [])

    const Title = (props)=>{
		return(
		<>
			<span style={{fontWeight: 'bold'}}>Request Quote</span>
			<span></span>
		</>
		)
	}

    function handleChangePage(event, value) {
        // console.log(value)
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
        // console.log(event)
		setRowsPerPage(event);
        setPage(0);
	}
  
    const startIndex = page*rowsPerPage
    const endIndex = startIndex + rowsPerPage
    // console.log('rowsPerPage',rowsPerPage)
    // console.log('page',page)
    // console.log('startIndex:',startIndex)
    // console.log('endIndex:',endIndex)
    
    const rowsData = requests.slice(startIndex,endIndex)

    function CustomPagination(){
        return(
            <div className="p-10">
				<TablePagination
					className="flex-shrink-0 border-t-1 mt-10"
					component="div"
					count={requests.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div>
        )
    }

    return (
        <FusePageSimple 
            // header = {
            //     <Header />
            // }
            classes ={{
                topBg:classes.topBg
            }}
            content = {
                <Paper  square style={{borderRadius:'4px',marginTop:'20px'}} className={classes.root}>
                    <div style={{width:'100%', height:'500px', padding:'15px'}}>
                        <DataGrid
                            rows = { rowsData }
                            columns = { columns }
                            // pageSize = { pageSize }
                            // onPageSizeChange = { handlePageSizeChange }
                            // rowsPerPageOptions={[10, 25, 50]}
                            disableSelectionOnClick
                            onRowClick = {(row) => {
                                router.push(`/view-request-quote/${row.row.request_id}`)
                            }}
                            components={{
                                Pagination: CustomPagination,
                            }}
                        />
                    </div>
                </Paper>
            }
        />
    )
}

export default RequestedQuotesView