import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
    root:{
        // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
        //   border: '2px solid black',
        //   display: 'none !important'
        // }
        '& .MuiDataGrid-columnsContainer':{
          background: '#8f8f8f26'
        }
    }
});

let on_hover = false
const action_to_color = {
    "CREATED": "green",
    "UPDATED": "orange",
    "DELETED": "red",
}

const columns = [
    { field: 'action_id', headerName: 'ID', width: 100 },
    { field: 'action_date', headerName: 'Date', width: 200},
    { field: 'action', headerName: 'Action',width: 200,
        renderCell: (params) =>{
           return (<span style={{color: action_to_color[params.value],fontWeight: 'bold'}}>{params.value}</span>)
        }
    },
    { field: 'field_name', headerName: 'Field Name', width: 200,
        renderCell: (params) => {
            // return Boolean(params.value) ? params.value : 'N/A'
            if(params.value?.length > 20){
                return(
                    <Tooltip title={params.value} placement="top-start">
                        <span style={{cursor: 'pointer'}}>{`${params.value?.slice(0,21)}...`}</span>
                    </Tooltip>
                )
            }else{
                return(<span>{Boolean(params.value) ? params.value : 'N/A'}</span>)
            }
        }
    },
    { field: 'previous_value', headerName: 'Previous value', width: 300,
        renderCell: (params) =>{
            // return Boolean(params.value) ? params.value : 'N/A'
            if(params.value?.length > 30){
                return(
                    <Tooltip title={params.value} placement="top-start">
                        <span className='activitiesTab-descText' style={{cursor: 'pointer'}}>{params.value}</span>
                    </Tooltip>
                )
            }else{
                return(<span>{Boolean(params.value) ? params.value : 'N/A'}</span>)
            }
        },
    },
    { field: 'current_value', headerName: 'Current value', width: 300,
        renderCell: (params) =>{
            if(params.value?.length > 30){
                return(
                    <Tooltip title={params.value} placement="top-start">
                        <span className='activitiesTab-descText' style={{cursor: 'pointer'}}>{params.value}</span>
                    </Tooltip>
                )
            }else{
                return(<span>{Boolean(params.value) ? params.value : 'N/A'}</span>)
            }
        },
    },
    { field: 'name', headerName: 'Name', width: 200},
    { field: 'user_role', headerName: 'Role', width: 200,
        renderCell: (params) => {
            return Boolean(params.value) ? params.value : 'N/A'
        } 
    },
    { field: 'description', headerName: 'Description',width: 400,
        renderCell: (params) => {
            if (params.value?.length > 20) {
                return (
                    <Tooltip title={params.value} placement="top-start">
                        <span onMouseOver={(()=>{
                            on_hover = true
                        })()} className='activitiesTab-descText'>{
                            (()=>{
                                if(!on_hover && params.value.length > 20){
                                return `${params.value.slice(0,20)}...`
                                }else if(on_hover){
                                    return params.value
                                }else{
                                    return params.value
                                }
                            })()
                        }</span>
                    </Tooltip>
                )
            } else {
                return (<span>{params.value}</span>)
            }
        }
    }
];
  

function ShowLogs(props){
    const classes = useStyles()
    const theme = useTheme();
    const height = props?.height ? props.height : '600px'
    return(
        <div className={classes.root} style={{width: '100%',height: height,overflow:"auto"}}>
            <DataGrid
                rows={props.logs}
                columns={columns}
                hideFooter={true}
            />
        </div>
    )

}

export default ShowLogs;