import {Button, Icon, IconButton, Card, CardContent, Grid, Divider, Select, 
	TextField, Typography, CircularProgress, Tabs, Tab, Box, MenuItem, 
	Dialog, DialogActions, DialogContent, DialogTitle, InputLabel} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { useState,useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { red } from '@material-ui/core/colors';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FormControl from '@material-ui/core/FormControl';
import EndUsersAccountsTable from './tables';
import TablePlain from './tablesPlain';
import useRouter from '@fuse/hooks/useRouter';
import axiosgeneral from "@fuse/utils/axios";
import { motion } from 'framer-motion';
import StepperCustom from '@fuse/components/general/Stepper'
import useToast from '@fuse/hooks/useToast';
import { exportToCsv } from "@fuse/utils";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import CreateRequestView from './CreateRequestView';
import ChnageReqsActionsLogsView from './ChnageReqsActionsLogsView';
import AllLineItems from './AllLineItems';
import EnduserGenericQuoteActionView from './EnduserGenericQuoteActionView';
import {actions} from '../shared/constants';
import NoteDialog from './noteDialog';
import InfoCard from '@fuse/components/general/InfoCard';
import InfoCardSmall from '@fuse/components/general/InfoCardSmall';
import NoteView from './NoteView';
import EmailView from './EmailView';
import {toReadable} from '@fuse/utils/stringOperations'
import download from 'downloadjs';
import axios from "@fuse/utils/axios";
import ExpandButton from '@fuse/components/general/ExpandButton';
import PriceSummary from '../PriceSummary';
import ChatView from './ChatView'

const drawerWidth = 400;
const headerHeight = 200;

const useStyles = makeStyles(theme => ({
	'@global': {
		'#fuse-main': {
			height: '100vh'
		}
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: '100%',
		position: 'relative',
		// flex: '1 1 auto',
		height: 'auto',
		width:'100%',
		backgroundColor: theme.palette.background.default
	},
	cardGen: {
		width: '100%',
	},
	topBg: {
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none',
		padding:"10px"
	},
	topBgFixed: {
		height: headerHeight,
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none'
	},
	contentCardWrapper: {
		maxWidth: 1400,
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 0 auto',
		width: '100%',
		minWidth: '0',
		margin: '0 auto',
		// [theme.breakpoints.down('sm')]: {
		// 	padding: '16px 16px 0 16px'
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	padding: '12px 12px 0 12px'
		// }
	},
	contentCard: {
		display: 'flex',
		position: 'relative',
		// flex: '1 1 100%',
		// flexDirection: 'column',
		width:"100%",
		backgroundColor: theme.palette.background.paper,
		minHeight: 0,
		overflow: 'hidden',
		borderRadius: '2px 2px 0 0',
	},
	drawerPaper: {
		width: drawerWidth,
		maxWidth: '100%',
		overflow: 'hidden',
		height: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'relative'
		}
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 1 100%',
		zIndex: 10,
		background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
			theme.palette.background.paper,
			0.6
		)} 20%,${fade(theme.palette.background.paper, 0.8)})`
	},
	content: {
		display: 'flex',
		// flex: '1 1 100%',
		minHeight: 0
	},
	avatar: {
		backgroundColor: red[500],
	},
	formControl: {
		padding: "0px"
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	formControlMinWidth: {
		minWidth: 200,
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	card: {
		borderRadius:"5px"
	},
	cardContainer:{
	},
	borderRightLight: {
		borderRight:"1px solid rgba(0,0,0,0.12)"
	},
	buttonSquare:{
		borderRadius: '5px'
	}
}));

function QuoteDetail(props) {
	const dispatch = useDispatch();
	const query = new URLSearchParams(location.search)
	const OpenInNewTab = !query.get('OpenInNewTab')
	const OpenTab = query.get('OpenTab')
	const [quoteChangable, setQuoteChangable] = useState(true);
	const filters = ['Select filter','Account Name'];
	const classes = useStyles();
	const pages = {summary:0, detail:1};
	const [selectedPage, setSelectedPage] = useState(pages.summary)
	const [quoteLoading, setQuoteLoading] = useState(false);
	const [buttonVisible, setButtonVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [quoteDenying, setQuoteDenying] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [activeStep, setActiveStep] = useState(1);
	let [quote,setQuote] = useState({});
	let [listItems,setListItems] = useState([]);
	let [listItemsModeled,setListItemsModeled] = useState([]);
	let [totalRecords,setTotalRecords] = useState(0);
	let [selectedLineItems, setSelectedLineItems] = useState([]);
	const commonTabs = ['Line Items', 'Activities', 'Email', 'Chat', 'Notes', 'System Information']
	const summaryTabs = ['Hardware', 'Software Subscription', 'Software License']
	const detailTabs = ['Detail']
	const [tabs, setTabs] = useState([...summaryTabs, ...commonTabs]);
	const [selectedTab, setSelectedTab] = useState(OpenTab ? tabs.indexOf(OpenTab) : 0);
	const [lastSelectedSummaryTab, setLastSelectedSummaryTab] = useState(0)
	const [selectedAssetType,setSelectedAssetType] = useState(changeSelectedAssetType())
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [userLocation, setUserLocation] = useState('');
	const [actionType, setActionType] = useState('');
	const [noteDialogOpen, setNoteDialogOpen] = useState(false);
	const grid = useRef();
	const gridSummary = useRef();
	const theme = useTheme();

	const toast = useToast(dispatch);
    
    const successAlert = (message) => {
        toast.success(message,{
        anchorOrigin: {
            vertical  : 'bottom',//top bottom
            horizontal: 'right'//left center right
        }
        })
    };

    const errorAlert = (message) => {
        toast.error(message,{
        anchorOrigin: {
            vertical  : 'bottom',//top bottom
            horizontal: 'right'//left center right
        }
        })
    };

	
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const router = useRouter();

	const id = router.params.id;

	const [currentFilter, setCurrentFilter] = useState(0);
	
	const [totalPrices, setTotalAmounts] = useState({
		total_sell_price: '-',
		total_msrp: '-'
	});
	
	const [servicePlans, setServicePlans] = useState([]);

	const [usersList, setUsersList] = useState([]);
	
	const [currentSuggestions, setCurrentSuggestions] = useState([]);

	const [currentSearch, setCurrentSearch] = useState('_');

	const [addedLineItem, setAddedLineItem] = useState(null);
	
	const [selectedSerialFromSuggestionAtAddLineItem, setSelectedSerialFromSuggestionAtAddLineItem] = useState(true);

	const [selectedModel, setSelectedModel] = useState(null);
	
	const [validationDialog, setValidationDialog] = useState(false);
	const [transferDialog, setTransferDialog] = useState(false);
	const [openUserDialog, setOpenUserDialog] = useState(false);

	const [recieverId, setRecieverId] = useState('');
	const [locations, setLocation] = useState('');
	const [tRecieverId, setTRecieverId] = useState('');
	const [tLocation, setTLocation] = useState('');
	const [locationList, setLocationList] = useState([]);
	const [locationTracker, setLocationTracker] = useState([]);

	const [summaryTabsCounts, setSummaryTabsCounts] = useState({})
	const [notOpenedEmailCount, setNotOpenedEmailCount] = useState(0)

	const steps = ['Select quote', 'Review quote', 'Preview quote', 'Payment'];

	const [showPriceSummary, setShowPriceSummary] = useState(false)

	const [addLineItemEditableCols, setAddLineItemEditableCols] = useState([
		{name: 'serial_number',type:'select', label: 'Serial Number', edited: false, required: true, def: ''},
		// {name: 'hardware_sku',type:'text', label: 'SKU', edited: false, required: false, def: 'asset.hardware_sku'},
		// {name: 'item_name',type:'text', label: 'Item Name', edited: false, required: false, def: 'asset.asset_name'},
		// {name: 'manufacturer',type:'text', label: 'Manufacturer', edited: false, def: 'asset.manufacturer.manufacturer_name'},
		// {name: 'service_name',type:'select', label: 'Service Name', edited: false, required: true, def: 'service.service_name'},
	])

	const [runuseEffect,setRunuseEffect] = useState(true)

	function changeSelectedAssetType(){
		if(summaryTabs.includes(tabs[selectedTab])){
			return tabs[selectedTab].split(' ').join('_').toLowerCase()
		}
		return null
	}

	function onClickChangeSelectedAssetsType(selectedAssetType){
		if(selectedAssetType == 'software_subscription') return;
		setRunuseEffect(false)	
	}

	useEffect(() => {
		if(runuseEffect){
			const newAssetType = changeSelectedAssetType()
			if(newAssetType){
				setSelectedAssetType(newAssetType)
			}
		}
	}, [selectedTab])

	useEffect(() => {
		if(selectedPage === pages.summary){
			fetchItemsModeled()
		}
		setAddLineItemEditableCols((prev) => {
			switch(selectedAssetType){
				case 'hardware':
					{
					return [
						{name: 'serial_number',type:'select', label: 'Serial Number', edited: false, required: true, def: ''},
						// {name: 'hardware_sku',type:'text', label: 'SKU', edited: false, required: false, def: 'asset.hardware_sku'},
						// {name: 'item_name',type:'text', label: 'Item Name', edited: false, required: false, def: 'asset.asset_name'},
						// {name: 'manufacturer',type:'text', label: 'Manufacturer', edited: false, def: 'asset.manufacturer.manufacturer_name'},
						// {name: 'service_name',type:'select', label: 'Service Name', edited: false, required: true, def: 'service.service_name'},
					]
					break;
				}
				case 'software_subscription':{
					return [
						// {name: 'software_subscription_sku',type:'select', label: 'SKU', edited: false, required: true, def: ''},
						{name: 'asset_sku',type:'select', label: 'Asset SKU', edited: false, required: true, def: 'asset.hardware_sku'},
						// {name: 'item_name',type:'text', label: 'Item Name', edited: false, required: false, def: 'asset.asset_name'},
						// {name: 'manufacturer',type:'text', label: 'Manufacturer', edited: false, def: 'asset.manufacturer.manufacturer_name'},
						// {name: 'service_name',type:'select', label: 'Service Name', edited: false, required: true, def: 'service.service_name'},
					]
					break;
				}
				case 'software_license':
					{
					return [
						{name: 'serial_number',type:'select', label: 'Serial Number', edited: false, required: true, def: ''},
						// {name: 'software_license_sku',type:'text', label: 'SKU', edited: false, required: false, def: 'asset.hardware_sku'},
						// {name: 'item_name',type:'text', label: 'Item Name', edited: false, required: false, def: 'asset.asset_name'},
						// {name: 'manufacturer',type:'text', label: 'Manufacturer', edited: false, def: 'asset.manufacturer.manufacturer_name'},
						// {name: 'service_name',type:'select', label: 'Service Name', edited: false, required: true, def: 'service.service_name'},
					]
					break;
				}
			}
		})
	}, [selectedAssetType])

	useEffect(async ()=>{
		fetchQuoteDetail();
		fetchItemsModeled();
		fetchUsers()
	},[router.params.id]);

	useEffect(async () => {
		fetchItems();
		if(selectedModel)
		{
			fetchUserLocations(null, 'company')
		}
		setButtonVisible(selectedModel ? true : false)
	}, [selectedModel])

	useEffect(()=>{
		const filter = filters[currentFilter];
		let suggestions = [];
		axiosgeneral.get(`/quotes/fetch-suggestions?filter=${filter}`)
		.then(res => {
			suggestions = Array.from(new Set(res.data.data));
			if(suggestions.length == 1 && suggestions[0] == ''){
				suggestions = [];
			}
			setCurrentSearch('');
			setCurrentSuggestions(suggestions);
		})
		.catch(err => {
			return err;
		})
	},[currentFilter]);

	function checkQuoteChangable(quote) {
		let changable = true;
		const enduserRequestStatus = quote.enduser_request_status;
		switch(enduserRequestStatus){
			case 'approved':
				toast.error("The quote was already approved")
				changable = false;
				break;
			case 'denied':
				toast.error("The quote was already declined")
				changable = false;
				break;
			default:
				break
		}

		if(quote.lost){
			toast.error("The quote was marked as lost")
			changable = false;
		}
		
		if(quote.booked){
			toast.error("The quote was already booked")
			changable = false;
		}

		setQuoteChangable(changable)
	}

	function fetchUsers(){
		axiosgeneral.get('/quotes/fetch-validation-users').then( res => {
			setUsersList(res.data.data)
		}).catch(err => {
			console.log(err)
		})
	}

	function fetchQuoteDetail(){
		getLineItemCountsAsAssetType();
		setQuoteLoading(true);
		axiosgeneral.get(`/quotes/${id}`)
		.then(res => {
			const {data} = res.data;
			checkQuoteChangable(data)
			setQuote(data);
		})
		.catch(err => console.log(err))
		.finally(()=>setQuoteLoading(false));
	}

	//counts in summary tabs title
	function getLineItemCountsAsAssetType(){
		axios.get(`/quotes/${id}/line-items-counts`).then(res=>{
			const { data }= res.data;
			setSummaryTabsCounts(data)
			
			const sum = Object.values(data).reduce((total, curr) => total + curr,0)

			if(sum != Number(quote.quote_line_items)){
				setQuote(detail => ({
					...detail,
					quote_line_items: sum
				}))
			}
		})
		.catch(err => console.log(err))
	}

	function summarySelected(item = {}){
		setSelectedModel(item?.model || '_');
		setSelectedPage(pages.detail);
		setLastSelectedSummaryTab(selectedTab)
		setSelectedTab(0)
		setTabs([...detailTabs, ...commonTabs])
	}

	useEffect(()=>{
		if(tabs[0]!='Detail'){
			setListItems(items=>items.filter((item)=>item.requested!='add' ))
			setSelectedLineItems([])
			setAddedLineItem(null)
		}
	},[tabs])
	
	function fetchItemsModeled(rowsPerPage=10, page=0, filter='_', search='_'){
		
		setLoading(true);

		axiosgeneral.get(`/quotes/line-items/grouped-by-model?id=${id}&page=${page}&rowsPerPage=${rowsPerPage}&assetType=${selectedAssetType}`)
		.then(res => {
			const {quote_line_items, total, service_plans, total_records} = res.data.data;
			setListItemsModeled(quote_line_items);
			setTotalRecords(total_records);
			setTotalAmounts(total);
			setServicePlans(service_plans);
			showMessage({ message: res.data.message,autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
		})
		.catch(err => {
			return err;
		})
		.finally(()=>setLoading(false));
	}

	function fetchItems(rowsPerPage=10, page=0, filter='_', search='_'){
		if(selectedModel === null) return;

		setLoading(true);

		axiosgeneral.get(`/quotes/line-items?id=${id}&page=${page}&rowsPerPage=${rowsPerPage}&model=${selectedModel ? selectedModel : 'NULL'}&assetType=${selectedAssetType}`)
		.then(res => {
			const {quote_line_items, total, service_plans, total_records} = res.data.data;
			console.log(quote_line_items);
			setListItems(quote_line_items);
			setTotalRecords(total_records);
			setTotalAmounts(total);
			setServicePlans(service_plans);
			setButtonVisible(true);
			showMessage({ message: res.data.message,autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
		})
		.catch(err => {
			return err;
		})
		.finally(()=>setLoading(false));
	}

	function onSearch(){
		if(!currentSearch){
			setCurrentSearch('');
			return
		}
		
		fetchItems(10, 0, filters[currentFilter], currentSearch);
	}

	function onSelectSuggestion(val){
		setCurrentSearch(currentSuggestions[val]);
	}

	function onSelectSuggestionRaw(val){
		setCurrentSearch(val);
	}

	function setAllStates(data){
		setListItems(data.quotes);
		setTotalRecords(data.number_of_records);
	}

	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function backToSummary(){
		fetchItemsModeled();
		setRunuseEffect(true)
		setListItems([])
		setSelectedModel(null)
		// setSelectedModel(null);
		setSelectedPage(pages.summary);
		setSelectedTab(lastSelectedSummaryTab)
		setTabs([...summaryTabs, ...commonTabs])
	}

	function denyQuote({comment='',action_reason=''}) {
		setQuoteDenying(true);
		axiosgeneral.post('/quotes/deny',{
			quote_id: quote.id,
			comment,
			action_reason
		})
		.then(res => {
			if(res.data.statusCode == 200){
				toast.success('Quote declined successfully');
				setQuote({...quote, enduser_request_status: res.data.enduser_request_status})
				router.push('/quotes')
			}else{
				toast.error(res.data.message);
			}
		})
		.catch(err => console.log(err))
		.finally(()=>setQuoteDenying(false))
	}

	function toNumberFormat(numberString){
		if(!numberString || numberString == '-') return '0.00'
		const number = Number(numberString);
		let fixed = number.toFixed(2);
		let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
		let [formatedNumber] = number.toLocaleString().split(".");
		const result = `${formatedNumber}.${secondNumber}`;
		return result
	}

	const getListItemsModeled = ()=>{
		if(!listItemsModeled) return
		return listItemsModeled.map(item => {
		switch(selectedAssetType){
			case 'hardware':
			case 'software_license':{
				return {
					model: item.model, 
					no_of_assets: item.no_of_assets,
					start_date: item.start_date ? dateReformatTo_mmm_dd_yyyy(item.start_date.split('T')[0]) : '-',
					end_date: item.end_date ? dateReformatTo_mmm_dd_yyyy(item.end_date.split('T')[0]) : '-',
					locations: item.shipping ? `${item.shipping.address1}, ${item.shipping.city}, ${item.shipping.zip}` : '-',
					service_plan: item.service_plan ? item.service_plan : '-',
					price: item.sell_price ? `$${toNumberFormat(item.sell_price)}` : '',
					requested_add_assets: item?.no_of_requested_add_assets || 0,
					requested_remove_assets: item?.no_of_requested_remove_assets || 0,
				}
				break;
			}
			case 'software_subscription':{
				return {
					// software_subscription_sku: item.hardware_sku,
					asset_sku: item.hardware_sku,
					start_date: item.start_date ? dateReformatTo_mmm_dd_yyyy(item.start_date.split('T')[0]) : '-',
					end_date: item.end_date ? dateReformatTo_mmm_dd_yyyy(item.end_date.split('T')[0]) : '-',
					// locations: item.shipping ? `${item.shipping.address1}, ${item.shipping.city}, ${item.shipping.zip}` : '-',
					quantity: item.no_of_assets,
					service_plan: item.service_plan ? item.service_plan : '-',
					price: item.sell_price ? `$${toNumberFormat(item.sell_price)}` : '',
					msrp: item.msrp ? `$${toNumberFormat(item.msrp)}` : '',
					requested_add_assets: item?.no_of_requested_add_assets || 0,
					requested_remove_assets: item?.no_of_requested_remove_assets || 0,
				}
				break;
			}
			default:
				return item
		}
	})
	}

	function formatString(str){
		if(!str){
			return str
		}
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

	function flattenListItems(){
		if(listItems.length > 0){
			return listItems.map(acc => {
				switch(selectedAssetType){
					case 'hardware':{
						return 	{
							id: acc.id,
							asset_sku: acc?.asset?.hardware_sku || '',
							asset_type: formatString(acc?.asset?.asset_type) || '',
							serial_number: acc?.asset?.serial_number || '',
							item_name: acc?.asset?.asset_name || '',
							locations: acc && acc.asset && acc.asset.shipping ? `${acc.asset.shipping.location_name}, ${acc.asset.shipping.address1}, ${acc.asset.shipping.city}, ${acc.asset.shipping.zip}` : '',
							service_sku: acc?.service?.service_sku || '',
							service_name: acc?.service?.service_name || '',
							service_plan: acc?.service_plan || '',
							start_date: acc && acc.start_date ? dateReformatTo_mmm_dd_yyyy(acc.start_date.split('T')[0]) : '',
							end_date: acc && acc.end_date ? dateReformatTo_mmm_dd_yyyy(acc.end_date.split('T')[0]) : '',
							price: `$${acc && acc.modified_sell_price ? toNumberFormat(acc.modified_sell_price) : '0.00'}`,
							msrp: `$${acc && acc.msrp ? toNumberFormat(acc.msrp) : '0.00'}`,
							discount: `${acc && acc.discount ? parseFloat(acc.discount).toFixed(2) : '0.00'}%`,
							requested: acc?.requested || undefined,
							request: acc?.request || '',
							manufacturer: acc?.asset?.manufacturer?.manufacturer_name || '',
						}
						break
					}
					case 'software_license':
					{
						return 	{
							id: acc.id,
							asset_sku: acc?.asset?.hardware_sku || '',
							asset_type: formatString(acc?.asset?.asset_type) || '',
							serial_number: acc?.asset?.serial_number || '',
							item_name: acc?.asset?.asset_name || '',
							locations: acc && acc.asset && acc.asset.shipping ? `${acc.asset.shipping.location_name}, ${acc.asset.shipping.address1}, ${acc.asset.shipping.city}, ${acc.asset.shipping.zip}` : '',
							service_sku: acc?.service?.service_sku || '',
							service_name: acc?.service?.service_name || '',
							service_plan: acc?.service_plan || '',
							start_date: acc && acc.start_date ? dateReformatTo_mmm_dd_yyyy(acc.start_date.split('T')[0]) : '',
							end_date: acc && acc.end_date ? dateReformatTo_mmm_dd_yyyy(acc.end_date.split('T')[0]) : '',
							price: `$${acc && acc.modified_sell_price ? toNumberFormat(acc.modified_sell_price) : '0.00'}`,
							msrp: `$${acc && acc.msrp ? toNumberFormat(acc.msrp) : '0.00'}`,
							discount: `${acc && acc.discount ? parseFloat(acc.discount).toFixed(2) : '0.00'}%`,
							requested: acc?.requested || undefined,
							request: acc?.request || '',
							manufacturer: acc?.asset?.manufacturer?.manufacturer_name || '',
						}
						break
					}
					case 'software_subscription': {
						return 	{
							id: acc.id,
							asset_sku: acc?.asset?.hardware_sku || '',
							asset_type: formatString(acc?.asset?.asset_type) || '',
							item_name: acc?.asset?.asset_name || '',
							quantity: acc.qty,
							locations: acc && acc.asset && acc.asset.shipping ? `${acc.asset.shipping.location_name}, ${acc.asset.shipping.address1}, ${acc.asset.shipping.city}, ${acc.asset.shipping.zip}` : '',
							service_sku: acc?.service?.service_sku || '',
							service_name: acc?.service?.service_name || '',
							service_plan: acc?.service_plan || '',
							start_date: acc && acc.start_date ? dateReformatTo_mmm_dd_yyyy(acc.start_date.split('T')[0]) : '',
							end_date: acc && acc.end_date ? dateReformatTo_mmm_dd_yyyy(acc.end_date.split('T')[0]) : '',
							price: `$${acc && acc.modified_sell_price ? toNumberFormat(acc.modified_sell_price) : '0.00'}`,
							msrp: `$${acc && acc.msrp ? toNumberFormat(acc.msrp) : '0.00'}`,
							discount: `${acc && acc.discount ? parseFloat(acc.discount).toFixed(2) : '0.00'}%`,
							requested: acc?.requested || undefined,
							request: acc?.request || '',
							manufacturer: acc?.asset?.manufacturer?.manufacturer_name || '',
						}
						break
					}
					default:
						return acc
				}
			
		});
		}
		return [];
	}

	function clearSearch() {
		setPage(0);
		setCurrentFilter(0);
		setCurrentSuggestions([]);
		setCurrentSearch('')
		fetchItems();
	}

	function onFilterChange(val){
		setCurrentFilter(val);
	}
	function gotoQuoteDetail(id){
		//console.log(id)
		router.push(`quotes/detail/${id}`)
	}
	const onRowSelected = (event) => {
		// console.log(event.node);
	};
	
	const onSelectionChanged = (event) => {
		const ids = event.api.getSelectedNodes().filter(item => !item.request).map((row,index) => row.data.id);
		setSelectedLineItems([...listItems.filter(item => ids.includes(item.id))]);
	};

	const handleAutoCompleteInputChange = (e)=>{
		try{
		if(e != null) {
			let currSearch = e.target.value;
			if(typeof(currSearch) != 'string'){return}
			currentSuggestions.forEach(suggestion => {
				if(suggestion.toLowerCase() == currSearch.toLowerCase()){
					currSearch = suggestion;
				}
			})
			onSelectSuggestionRaw(currSearch);
		}
	}catch(err){console.log(err)}
	}


	function handleDownloadFile(filename = "import.csv", blob = null){
		let link = document.createElement("a");
        if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
	}

	function fetchExportItems(filter='_', search='_'){
		axiosgeneral.get(`/quotes/export-all?filter=${filter}&search=${search == '' ? '_' : search}`)
		.then(res => {
			let rows = flattenExportItems(res.data.data.quotes)
			setListExportItems(rows)
		})
		.catch(err => {
			return err;
		})
	}

	function flattenExportItems(listItems){
		if(listItems.length > 0){
			
			return listItems.map(acc => ({
				asset_sku: acc?.asset?.hardware_sku || '',
				serial_number: acc?.asset?.serial_number || '',
				item_name: acc?.asset?.asset_name || '',
				locations: acc && acc.asset && acc.asset.shipping ? `${acc.asset.shipping.location_name}, ${acc.asset.shipping.address1}, ${acc.asset.shipping.city}, ${acc.asset.shipping.zip}` : '',
				service_sku: acc?.service?.service_sku || '',
				service_name: acc?.service?.service_name || '',
				service_plan: acc?.service_plan || '',
				start_date: acc && acc.start_date ? dateReformatTo_mmm_dd_yyyy(acc.start_date.split('T')[0]) : '',
				end_date: acc && acc.end_date ? dateReformatTo_mmm_dd_yyyy(acc.end_date.split('T')[0]) : '',
				price: `$${acc && acc.modified_sell_price ? `${toNumberFormat(acc.modified_sell_price)}.`+`${toNumberFormat(acc.modified_sell_price)}`.substr(-2) : '0.00'}`,
				msrp: `$${acc && acc.msrp ? `${toNumberFormat(acc.msrp)}.`+`${toNumberFormat(acc.msrp)}`.substr(-2) : '0.00'}`,
				discount: `${acc && acc.discount ? parseFloat(acc.discount).toFixed(2) : '0.00'}%`,
				manufacturer: acc?.asset?.manufacturer?.manufacturer_name || '',
			}));
		}
		return [];
	}


	function handleExportToCSV(){
		axiosgeneral.get(`/quotes/line-items/export-all?filter=${filters[currentFilter]}&search=${currentSearch == '' ? '_' : currentSearch}&quotes_id=${quote?.id}`)
			.then(res => {
				let rows = flattenExportItems(res.data.data.quote_line_items)
				
				const listingData = [];
				let headersList = Object.keys(rows[0]).map(header=>{
					return header;
				});
				listingData.push(headersList);
				rows.forEach(row=>{
					let data = [];
					for (let item in row){
						data.push(row[item])
					}
					listingData.push(data);		
				})
				const templatFileBlob = exportToCsv(listingData);
				const today = new Date();
				const timeString =  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
				handleDownloadFile(`quote_line_items_${timeString}.csv`, templatFileBlob);
			})
			.catch(err => {
				return err;
			})

	}

	function getTimeIn12hrsForm(time){
		let period = 'am';

		const splitted_time = time.split(':');

		if(parseFloat(splitted_time.join('.')) > 12.00){
			period = 'pm';
		}

		return `${time} ${period}`
	}

	function setEditedLineItem(field, index, item){
		const modList = [...listItems];
		let modObj = {}
		switch(field){
			case 'serial_number':{
				 modObj = {...modList[index], asset: item,
					cost: item?.price_catalogue?.service?.cost,
					msrp: item?.price_catalogue?.service?.msrp,
					asset_table_id: item.id,
					serial_number: item.serial_number,
					modified_sell_price: item?.price_catalogue?.price,
					service: item?.price_catalogue?.service,
					service_table_id: item?.price_catalogue?.service?.id,
					service_plan: item?.price_catalogue?.service?.service_plan,
					service_id: item?.price_catalogue?.service?.entity_external_platform_id,
					discount: 0
					}
				setSelectedSerialFromSuggestionAtAddLineItem(true);
				break;
			}
			case 'service_name':{
				modObj = {...modList[index],
					cost: item?.cost,
					msrp: item?.msrp,
					modified_sell_price: item?.price_catalogue?.price,
					service: item,
					service_table_id: item?.id,
					service_plan: item?.service_plan,
					service_id: item?.entity_external_platform_id,
					discount: 0
					}
			}
			// case 'software_subscription_sku':
			case 'asset_sku':
			case 'software_license_sku':
			case 'hardware_sku':
			{
				modObj = {...modList[index], asset: item,
				   cost: item?.price_catalogue?.service?.cost,
				   msrp: item?.price_catalogue?.service?.msrp,
				   asset_table_id: item.id,
				   serial_number: null,
				   modified_sell_price: item?.price_catalogue?.price,
				   service: item?.price_catalogue?.service,
				   service_table_id: item?.price_catalogue?.service?.id,
				   service_plan: item?.price_catalogue?.service?.service_plan,
				   service_id: item?.price_catalogue?.service?.entity_external_platform_id,
				   discount: 0
				   }
			   setSelectedSerialFromSuggestionAtAddLineItem(true);
			   break;
		   }
		}
		
		modList.splice(index, 1, modObj)
		setListItems(modList);
		setAddedLineItem([modObj])
		
	}

	function addLineItems(callback) {
		if(addedLineItem != null){
			toast.error("Only one line item can be added at once");
			return
		};

		if(selectedLineItems.length > 1){
			toast.error("Only one line item can be cloned");
			return
		};

		let clone = selectedLineItems;
		if(listItems.length == 0 && selectedLineItems.length == 0 ){
			clone = [{
				id:null,
				asset_sku: '',
				asset_type: selectedAssetType,
				serial_number: '',
				item_name: '',
				locations:  '',
				service_sku: '',
				service_name: '',
				service_plan:  '',
				start_date:  '',
				end_date:  '',
				price: '',
				msrp: '',
				discount: '',
				requested: 'add',
				request: null,
				manufacturer:  '',
			}]
			setSelectedPage(1)
			setTabs([...detailTabs,...commonTabs])
			setSelectedTab(0)
			setLastSelectedSummaryTab(selectedTab)
		}
		if(clone.length <= 0){
			listItems.forEach(item => {
				if(!item.request){
					clone = [{...item, request: null}]

					return
				}
			})
		}
		
		if(clone.length <= 0){
			toast.error("Please select a line item");
			return
		};

		clone = clone.map(item => ({...item, requested:'add'}))
		setAddedLineItem(clone);
		setListItems([...listItems, ...clone]);
		setSelectedSerialFromSuggestionAtAddLineItem(false);
		callback();
	}

	function removeAddedLineItems(callback){
		setListItems(listItems => {
			return listItems.filter(item => !item.requested)
		});
		setSelectedLineItems([]);
		setAddedLineItem(null);
		setSelectedSerialFromSuggestionAtAddLineItem(false);
		callback();
	}

	function fillBlankFields(item){
		let modItem = {...item}
		addLineItemEditableCols.forEach(col => {
			if(!modItem[col.name]){
				const path = col.def.split('.');
				let val = item;
				path.forEach((node) =>{
					if(!val) return
					 val = val[node] ? val[node] : null
				})
				if(val){
					modItem = {...modItem, [col.name]: val}
				}
			}
		})

		return modItem
	}

	function saveRequest(action_type, action, comment, callback) {
		const selected_line_item_ids = selectedLineItems.map(item => item.id)
		const manual_select = !selectedSerialFromSuggestionAtAddLineItem
		let added_line_item = !addedLineItem ? {} : {...addedLineItem[0],manual_select}
		if(manual_select){
			added_line_item = fillBlankFields(added_line_item)
		}
		axiosgeneral.post('/quotes/change-request',{
            quote_id: id,
            action_type,
            action,
			comment,
			selected_line_item_ids,
			added_line_item,
			asset_type: selectedAssetType
        })
        .then(res => {
            if(res.data.statusCode == 200){
				toast.success("Request successfully submitted");
				switch(action){
					case actions.requests.remove_line_item.action:
						setListItems(listItems.map(item => {
							if(selected_line_item_ids.includes(item.id)){
								return {...item, request: action}
							}
							return item;
						}))
						getLineItemCountsAsAssetType()
						break;
					case actions.requests.add_line_item.action:
						setSelectedLineItems(selectedLineItems.filter(item => {
							return !(item.requested && item.requested === 'add')
						}
						))
						
						if(selectedSerialFromSuggestionAtAddLineItem){
							setListItems(listItems.map(item => {
								if(item.requested && item.requested === 'add'){
									return {...item, requested: undefined, request: action}
								}
								return item;
							}))
						}else{
							setListItems(listItems.filter(item => {
								if(item.requested && item.requested === 'add'){
									return false;
								}
								return true;
							}))
						}
						
						setAddedLineItem(null);
						getLineItemCountsAsAssetType()
						break;
				}
				
				setSelectedLineItems([])
				setQuote(quote => {
					return {...quote, enduser_request_status: actions.request_statuses.request_change}
				})
				setSelectedSerialFromSuggestionAtAddLineItem(false);
                callback()
            }else{
                toast.err(res.data.message)
            }
        })
        .catch(err => console.log(err))
	}

	useEffect(()=>{
		// checkRowsSelectedInGrid();
	},[selectedLineItems])

	function handleAddLineItemChangeRequest(){
		if(!addedLineItem[0]) return;
		if(!selectedSerialFromSuggestionAtAddLineItem && !validAddLineItemEditedData(addedLineItem[0], addLineItemEditableCols)) return

		setListItems(items => {
			return items.map(item => {
				if(item.requested){
					return addedLineItem[0];
				}
				return item
			})
		})

		CreateRequestView({
			title: `Add a comment for quote #${quote.quote_id}`,
			hideAction: true,
			quote,
			selectedItems: addedLineItem.map(item => ({id:item.id,value: selectedAssetType === 'software_subscription' ? item.asset.hardware_sku : (item.serial_number?item.serial_number : item.asset.serial_number)})),
			removeItem: (id)=>{
				removeAddedLineItems(()=>{})
			},
			saveRequest: saveRequest,
			action: actions.requests.add_line_item.action
		}, dispatch)
	}

	function validAddLineItemEditedData(item, cols){
		let valid = true;
		cols.forEach(col => {
			if(!col.required) return
			if(!item[col.name] || !item[col.name] === '' || !col.edited){
				toast.error(`Please change value for ${col.label}`)
				valid = false
			}
		})
		return valid
	}

	function updateAddedLineItemColValue(colName, value){
		console.log('updating col:',{colName, value})
		setAddedLineItem(([item]) => {
			return [{...item, [colName]: value}]
		});

		setAddLineItemEditableCols(cols => {
			return cols.map(col => {
				if(col.name === colName){
					return {...col, edited: value? true : false}
				}
				return col;
			})
		})

		// setListItems(items => {
		// 	const modItems = items.map(item => {
		// 		if(item.requested){
		// 			return modItem;
		// 		}
		// 		return item;
		// 	})
		// 	return modItems;
		// })
	}
	
	function handleSendForValidation(){
		const line_item_ids = []
		selectedLineItems.map(item => {
			line_item_ids.push(item.id)
		})

		if(line_item_ids.length == 0)
		{
			toast.error('Line item not selected')
			return false
		}
		
		if(recieverId == '')
		{
			toast.error('Please select user to send validation request')
			return false
		}

		if(!locations)
		{
			toast.error('Please select a location')
			return false
		}

		const data = {
			line_item_ids,
			quote_id : id,
			user_id : recieverId,
			locations
		}

		axiosgeneral.post(`/quotes/send-for-validation`, data).then( res => {
			fetchItems()
			handleCloseValidationDialog()
			toast.success(res.data.message)
		}).catch(err => {
			toast.error(err.response.data.message)
			console.log(err)
		})
	}

	function handleTransferQuote(){
		const line_item_ids = []
		selectedLineItems.map(item => {
			line_item_ids.push(item.id)
		})

		if(line_item_ids.length == 0)
		{
			toast.error('Line item not selected')
			return false
		}
		
		if(tRecieverId == '')
		{
			toast.error('Please select user to send transfer request')
			return false
		}

		if(!tLocation)
		{
			toast.error('Please select a location')
			return false
		}

		const data = {
			line_item_ids,
			quote_id : id,
			user_id : tRecieverId,
			locations : tLocation
		}

		axiosgeneral.post(`/quotes/transfer-quote`, data).then( res => {
			setSelectedLineItems([])
			handleCloseTransferDialog()
			fetchItems()
			toast.success(res.data.message)
		}).catch(err => {
			toast.error(err.response.data.message)
		})
	}

	function fetchUserLocations(id, type) {
		axiosgeneral.get(`/quotes/fetch-all-locations/${id}/${type}`).then(res => {
			const {data} = res.data
			setLocationList(data.locations)
			setLocationTracker(data.locationTracker)
		}).catch( err => {
			console.log(err)
		})
	}

	function getNotOpenedEmailCount(){
		console.log('quote id: ', id);
		axios.get(`/quotes/get-notOpened-emails-count/${id}`).then(res => {
			const count = res.data.data.emailCount
			setNotOpenedEmailCount(count)
		}).catch(err => {
			console.log(err)
		})
	}


	useEffect(() => {
		getNotOpenedEmailCount()
	}, [])

	function handleCloseValidationDialog()
	{
		setRecieverId('');
		setLocation('');
		setLocationList([])
		setActionType('')
		setValidationDialog(false)
	}

	function handleCloseTransferDialog()
	{
		setTRecieverId('');
		setTLocation('');
		setLocationList([])
		setActionType('')
		setTransferDialog(false)
	}

	function isEmail(email) 
	{          
		// Regular Expression (Not accepts second @ symbol
		// before the @gmail.com and accepts everything else)
		var regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		  
		// Converting the email to lowercase
		return regexp.test(String(email).toLowerCase());
	}

	function handleUserCreationTransfer()
	{
		var regex = /^[A-Za-z ]+$/

		if(firstName.trim() != '' && regex.test(firstName) && lastName.trim() != '' && regex.test(lastName) && userEmail.trim() != '' && isEmail(userEmail)	&& userLocation.trim() != '')
		{
			const data = {
				name : firstName.trim() +" "+ lastName.trim(),
				email : userEmail.trim(),
				locations : userLocation,
				action: actionType,
				quotes_id :quote.id,
				selectedLineItems
			}
			
			axiosgeneral.post('/quotes/submit-user-creation=request', data).then( res => {
				toast.success("Your request has been sent to the System Admin to create a user account. You will be notified once completed.")
			}).catch(err => {
				toast.error(err.response.data.message)
			})

			console.log(locationList)

			//send validation request to non portal user as well 
			{const data = {
				line_item_ids: selectedLineItems.map(item => item.id),
				quote_id : quote.id,
				email: userEmail.trim(),
				name: firstName.trim() +" "+ lastName.trim(),
				locations: locationTracker[userLocation]
			}
	
			axiosgeneral.post(`/quotes/send-for-validation`, data).then( res => {
				fetchItems()
				toast.success(res.data.message)
				setOpenUserDialog(false)
				setSelectedLineItems([])
				fetchItems()
				setFirstName('')
				setLastName('')
				setUserEmail('')
				setUserLocation('')
			}).catch(err => {
				toast.error(err.response.data.message)
				console.log(err)
			})
			}
		}
		else
			toast.error('Please input proper data')
	}

	function downloadPDF(){
		const id = router.params.id;
		axios.get(`quotes/generate-pdf?quote_id=${id}` ,{
			responseType:'blob'
		}).then(response=>{
		   const content = response.headers['content-type'];
		   download(response.data, `quote_details_${id}_${(new Date()).toISOString().split('T')[0]}.pdf`, content)
		})
	}

	const tabsToOpenInNewWindow = ['Hardware','Software Subscription', 'Software License', 'Email', 'Line Items']
 
	const openTabInNewWindow = (tab) => {
		window.open(`${window.location.href}?OpenInNewTab=true&OpenTab=${tab}`)
	}
	
	return (
		<div className={clsx(classes.root)}>
			<NoteDialog  quoteId = {id} open={noteDialogOpen} closeDialog={()=>{setNoteDialogOpen(false)}}/>
			<Dialog open={openUserDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true} onBackdropClick={() => setOpenUserDialog(false)}>
				<DialogTitle id="form-dialog-title">Add User</DialogTitle>
				<DialogContent style={{width:'100%'}}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								style={{width:'100%'}}
								label="First Name"
								variant="outlined"
								type="text"
								value={firstName}
								required
								onChange={(e)=>setFirstName(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								style={{width:'100%'}}
								label="Last Name"
								variant="outlined"
								type="text"
								required
								value={lastName}
								onChange={(e)=>setLastName(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} >
							<TextField
								style={{width:'100%'}}
								label="Email Address"
								variant="outlined"
								type="email"
								required
								value={userEmail}
								onChange={(e)=>setUserEmail(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								label="Location"
								style={{width:'100%'}}
								select
								variant="outlined"
								required
								value={userLocation}
								onChange={(e)=>setUserLocation(e.target.value)}
							>
								{
									locationList.map( locationItem => {
										return (
											<MenuItem value={locationItem}>{locationItem}</MenuItem>
										)
									})
								}
							</TextField>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="primary" className={classes.buttonSquarePadded} onClick={handleUserCreationTransfer}>Request</Button>
					<Button variant="contained" color="secondary" className={classes.buttonSquarePadded} onClick={() => {
						setOpenUserDialog(false)
						setFirstName('')
						setLastName('')
						setUserEmail('')
						setUserLocation('')
						setLocationList([])
						setLocationTracker([])
						setRecieverId('')
						setTRecieverId('')
						}}>Close</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={validationDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
				<DialogTitle id="form-dialog-title">Validation Request
					<Typography variant="body2" component="p" color="textSecondary">
						* Please select the user and then location from the dropdown below
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
						<FormControl variant="filled"
						style={{width:'100%'}}
						>
							<Select
								className="pl-10 py-3"
								defaultValue="__"
								style={{width:'100%'}}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "center"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "center"
									},
									getContentAnchorEl: null
								}}
								onChange={(e) => {
									if(e.target.value != "__")
									{
										if(e.target.value == 'other')
										{
											setValidationDialog(false)
											setActionType('validation_request')
											setOpenUserDialog(true)
											fetchUserLocations(null, 'company')
											return false
										}
										setRecieverId(e.target.value)
										fetchUserLocations(e.target.value, 'user')
									}
								}}							
							>
								<MenuItem value="__">Select User</MenuItem>
								{
									usersList.map( user => {
										return (
											<MenuItem value={user.id}>{user.first_name +' '+ user.last_name} ( {user.role} )</MenuItem>
										)
									})
								}
								<MenuItem value='other' style={{color:'purple', fontWeight:'bold'}}>Other</MenuItem>
							</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={6}>
							
						<FormControl variant="filled"
								style={{width:'100%'}}
						>
							<Select
								className="pl-10 py-3"
								defaultValue="__"
								style={{width:'100%'}}
								disabled={locationList.length && (tRecieverId || recieverId) ? false : true}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "center"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "center"
									},
									getContentAnchorEl: null
								}}
								onChange={(e) => {
									if(e.target.value != "__")
									{
										setLocation(e.target.value)
									}
								}}
							>
								<MenuItem value="__">Select Location</MenuItem>
								{
									locationList.map( locationItem => {
										return (
											<MenuItem value={locationTracker[locationItem]}>{locationItem}</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>
						</Grid>
						<Grid item xs={3} md={3}>
						</Grid>
						<Grid item xs={6} md={6}>
							<Button
								style={{width:'100%'}}
								color="secondary"
								variant="contained"
								className={`${classes.buttonSquare} ml-10`}
								onClick={handleSendForValidation}
								>
									Send for Validation
							</Button>
						</Grid>
						<Grid item xs={3} md={3}>
						</Grid>
					</Grid>					
					
				</DialogContent>
				<DialogActions>
				<Button onClick={handleCloseValidationDialog} color="primary">
                    close
                </Button>
				</DialogActions>
			</Dialog>
			<Dialog open={transferDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
				<DialogTitle id="form-dialog-title">Transfer Quote Line Item
					<Typography variant="body2" component="p" color="textSecondary">
						* Please select the user and then location from the dropdown below
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
						<FormControl variant="outlined" fullWidth
						// style={{width:'100%'}}
						>
							<InputLabel id='userLabel'>Select User</InputLabel>
							<Select
								labelId='userLabel'
								label="Select User"
								className="pl-10 py-3"
								// defaultValue="__"
								style={{width:'100%'}}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "center"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "center"
									},
									getContentAnchorEl: null
								}}
								onChange={(e) => {
									if(e.target.value != "__")
									{
										if(e.target.value == 'other')
										{
											setTransferDialog(false)
											setOpenUserDialog(true)
											setActionType('transfer_line_item')
											fetchUserLocations(null, 'company')
											return false
										}
										setTRecieverId(e.target.value)
										fetchUserLocations(e.target.value, 'user')
									}
								}}							
							>
								{/* <MenuItem value="__">Select User</MenuItem> */}
								{
									usersList.map( user => {
										return (
											<MenuItem value={user.id}>{user.first_name +' '+ user.last_name} ( {user.role} )</MenuItem>
										)
									})
								}
								<MenuItem value='other' style={{color:'purple', fontWeight:'bold'}}>Other</MenuItem>
							</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={6}>
							
						<FormControl variant="outlined" fullWidth
								// style={{width:'100%'}}
						>
							<InputLabel id='locationLabel'>Select Location</InputLabel>
							<Select
								labelId='locationLabel'
								label="Select Location"
								className="pl-10 py-3"
								// defaultValue="__"
								style={{width:'100%'}}
								disabled={locationList.length && (tRecieverId || recieverId) ? false : true}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "center"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "center"
									},
									getContentAnchorEl: null
								}}
								onChange={(e) => {
									if(e.target.value != "__")
									{
										setTLocation(e.target.value)
									}
								}}
							>
								{/* <MenuItem value="__">Select locations</MenuItem> */}
								{
									locationList.map( locationItem => {
										return (
											<MenuItem value={locationTracker[locationItem]}>{locationItem}</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>
						</Grid>
						<Grid item xs={3} md={3}>
						</Grid>
						<Grid item xs={6} md={6}>
							<Button
								style={{width:'100%'}}
								color="secondary"
								variant="contained"
								className={`${classes.buttonSquare} ml-10`}
								onClick={handleTransferQuote}
								>
									Transfer
							</Button>
						</Grid>
						<Grid item xs={3} md={3}>
						</Grid>
					</Grid>					
					
				</DialogContent>
				<DialogActions>
				<Button onClick={handleCloseTransferDialog} color="primary">
                    close
                </Button>
				</DialogActions>
			</Dialog>
			<FuseScrollbars className="flex-grow overflow-x-auto">

			{/* <div className={clsx(classes.contentCardWrapper)}> */}
				<div className={clsx(classes.contentCard, 'shadow')}>
				
				<main style={{width:"100%"}}>
					<Grid container className="p-10">
					{(!quote?.merged_to_quote && OpenInNewTab ) &&
						<Grid item xl={12} className={`${classes.cardGen} pb-10`}>
						<Card className={classes.card}>
							<CardContent className={classes.cardContainer}>
								<StepperCustom activeStep={activeStep} className={`p-0`} steps={steps}/>
							</CardContent>
						</Card>
						</Grid>
					}
					{quoteLoading ? 
					(
						<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 0.1 } }}
						className="flex flex-1 items-center justify-center h-full p-20"
						>
							<CircularProgress/>
						</motion.div>
					)
					:
					(
						<>
						{(quote && OpenInNewTab) &&
						<>
						<Grid container spacing={2} className={`pb-10`}>
							<InfoCard
								label={{
									label: "Quote Info",
									icon: "request_quote",
									icon_color: '#c0dff3'
								}}
								subTitleCols={['Quote_Id']}
								showColKeys
								items={{
									Quote_Id: quote?.quote_id || '',
									Status: quote.enduser_brief_status ? toReadable(quote.enduser_brief_status) : '-',
									['Service Type']: quote?.service_type || '',
									['Service Level']: quote?.service_level || '',
									['Service Plan']: quote?.service_plan || '',
								}}
							/>
							<InfoCard
								label={{
									label: "Contact Info",
									icon: "contacts",
									icon_color: '#c6ec9d'
								}}
							
								subTitleCols={['Name']}

								colIcons={{
									Email: {
										icon: 'email',
										color: theme.palette.primary.main
									},
									Phone: {
										icon: 'phone',
										color: theme.palette.primary.main
									},
								}}
								items={{
									Name: quote.quote_user_first_name != null ? `${quote.quote_user_first_name} ${quote.quote_user_last_name != null ? quote.quote_user_last_name : ''}` : (quote.user_companies_user != null ? `${quote.user_companies_user.first_name} ${quote.user_companies_user.last_name}` : ''),
									Email: quote.quote_user_email != null ? quote.quote_user_email : (quote.user_companies_user != null ? quote.user_companies_user.email : ''),
									Phone: quote.quote_user_phone != null ? quote.quote_user_phone : (quote.user_companies_user != null ? quote.user_companies_user.phone : '')
								}}
							/>
							<InfoCard
								label={{
									label: "locations",
									icon: "room",
									icon_color: '#ecd39d'
								}}
							
								subTitleCols={[]}

								colIcons={{
									Location_name: {
										icon: 'room',
										color: theme.palette.primary.main
									}
								}}
								items={{
									Location_name: quote.quote_shipping_location_name != null ? quote.quote_shipping_location_name : (quote.shipping_location != null ? quote.shipping_location.location_name : ''),
									['Address Line 1']: quote.quote_shipping_address != null ? quote.quote_shipping_address : (quote.shipping_location != null ? quote.shipping_location.address1 : ''),
									City: quote.quote_shipping_address != null ? quote.quote_shipping_city : (quote.shipping_location != null ? quote.shipping_location.city : ''),
									State: quote.quote_shipping_address != null ? quote.quote_shipping_state : (quote.shipping_location != null ? quote.shipping_location.state : ''),
									Zip: quote.quote_shipping_address != null ? quote.quote_shipping_zip : (quote.shipping_location != null ? quote.shipping_location?.zip : '')
								}}
							/>
							<InfoCard
								label={{
									label: "Billing Address",
									icon: "business",
									icon_color: '#f6ccf8'
								}}
							
								subTitleCols={[]}

								colIcons={{
									Location_name: {
										icon: 'room',
										color: theme.palette.primary.main
									}
								}}
								items={{
									Location_name: quote.quote_billing_location_name != null ? quote.quote_billing_location_name : (quote.billing_location != null ? quote.billing_location.location_name : ''),
									['Address Line 1']: quote.quote_billing_address != null ? quote.quote_billing_address : (quote.billing_location != null ? quote.billing_location.address1 : ''),
									City: quote.quote_billing_address != null ? quote.quote_billing_city : (quote.billing_location != null ? quote.billing_location.city : ''),
									State: quote.quote_billing_address != null ? quote.quote_billing_state : (quote.billing_location != null ? quote.billing_location.state : ''),
									Zip: quote.quote_billing_address != null ? quote.quote_billing_zip : (quote.billing_location != null ? quote.billing_location?.zip : '')
								}}
							/>
						</Grid>
						<Grid container spacing={2} className={`pb-10`}>
						
							<InfoCardSmall
							label={{
								label: 'Agent Info',
								icon: 'person_outline',
								icon_color: '#2f95e4'
							}}
							subTitleCols={['agent_name']}
							items={{
								agent_name: quote.agent != null ? `${quote.agent.first_name} ${quote.agent.last_name}` : '',
								agent_email: quote.agent != null ? `${quote.agent.email}` : ''
							}}
							/>
							<InfoCardSmall
							label={{
								label: 'No. of Revisions',
								icon: 'settings_backup_restore',
								icon_color: '#f06e6e'
							}}
							subTitleCols={['no']}
							items={{
								no: quote?.action_logs || 0
							}}
							/>
							<InfoCardSmall
							label={{
								label: 'Assets',
								icon: 'border_all',
								icon_color: '#f4c033'
							}}
							subTitleCols={['no']}
							items={{
								no: quote?.quote_line_items || 0
							}}
							/>
							<InfoCardSmall 
							label={{
								label: 'Contract',
								icon: 'description',
								icon_color: '#c6ec9d'
							}}
							subTitleCols={['no']}
							items={{
								no: quote?.contract_header?.entity_external_platform_id || ''
							}}
							/>
							<InfoCardSmall 
							label={{
								label: 'Company',
								icon: 'business',
								icon_color: '#50b8df'
							}}
							subTitleCols={['no']}
							items={{
								no: quote.user_company != null ? quote.user_company.company_name : '',
							}}
							/>
							<InfoCardSmall 
							label={{
								label: 'Start Date',
								icon: 'calendar_today',
								icon_color: '#f78f20'
							}}
							subTitleCols={['no']}
							items={{
								no: quote.upcoming_contract_start_date ? dateReformatTo_mmm_dd_yyyy(quote.upcoming_contract_start_date.split('T')[0]) : ''
							}}
							/>
							<InfoCardSmall 
							label={{
								label: 'End Date',
								icon: 'calendar_today',
								icon_color: '#f17979'
							}}
							subTitleCols={['no']}
							items={{
								no: quote.upcoming_contract_end_date ? dateReformatTo_mmm_dd_yyyy(quote.upcoming_contract_end_date.split('T')[0]) : ''
							}}
							/>
						</Grid>
						</>
						}
					</>
					)}
					
				<Grid item xl={12} className={`${classes.cardGen} mt-0 pt-0`}>
				<Card className={`${classes.card} mt-0 pt-0`}>
					<CardContent className={`${classes.cardContainer} p-0`}>
					<div className={`w-full`}>
					<Tabs 
					value={selectedTab} 
					onChange={(event, newValue) => setSelectedTab(newValue)}
					indicatorColor="primary"
					textColor="primary"
					size="small"
					>
						{
							tabs.map((tab, index) => {
								if(!OpenInNewTab && tab != OpenTab) return
								if(tab != 'Email' ){
									return (
										<Tab label={
											<div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
												{tab} {summaryTabsCounts[tab.split(' ').join('_').toLowerCase()] ?
													<>({summaryTabsCounts[tab.split(' ').join('_').toLowerCase()] || ''})</>
													:
													<></>
												}
												{
													(OpenInNewTab && tabsToOpenInNewWindow.includes(tab)) &&
													<Icon frontSize = 'small' onClick={() => openTabInNewWindow(tab)}>
														open_in_new
													</Icon>
												}
											</div>} index={index} disabled={!OpenInNewTab}
										/>
								)}else{
									return (
										<Tab label={
											<div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
												{tab} {notOpenedEmailCount ?
													<>({notOpenedEmailCount || ''})</>
													:
													<></>
												}
												{
													(OpenInNewTab && tabsToOpenInNewWindow.includes(tab)) &&
													<Icon frontSize = 'small' onClick={() => openTabInNewWindow(tab)}>
														open_in_new
													</Icon>
												}
											</div>} index={index} disabled={!OpenInNewTab}
										/>
									)}
							})
						}
					</Tabs>
					</div>
					<Divider />
					
					</CardContent>
					<CardContent className={`${classes.cardContainer} m-0 p-0`}>
					{[...summaryTabs, ...detailTabs].includes(tabs[selectedTab]) &&
					<>
					<Grid container
					className="flex flex-1 items-center"
					>
					
					<Grid item xs={1}>
					{selectedPage === pages.detail &&
						<IconButton
						size="small"
						className={`rounded-sm`}
						onClick={backToSummary}
						>
							<Icon>arrow_back</Icon>
						</IconButton>
					}
					</Grid>
					<Grid item xs className={`p-5`}>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 0.1 } }}
						className="flex flex-1 items-center justify-end h-full"
					>
							{selectedPage === pages.detail && quote.id && !quote.lost && !quote.booked && quote.enduser_request_status !== 'accepted' && quote.enduser_request_status !== 'denied' && addedLineItem === null && selectedLineItems.length > 0 &&
							<Button
								variant="contained"
								color="secondary" 
								size="small"
								onClick = { ()=>{
									
									CreateRequestView({quote,
									selectedItems: selectedLineItems.map(item => ({id:item.id,value: selectedAssetType === 'software_subscription' ? item.asset.hardware_sku : item.asset.serial_number})),
									removeItem: (id)=>{
										setSelectedLineItems(selectedLineItems.filter(item => item.id !== id));
									},
									saveRequest: saveRequest
								}, dispatch)
								}}
								className={`ml-10 ${classes.buttonSquare}`}>
									Request change
							</Button>
							}
							</motion.div>
							</Grid>
							</Grid>
						{tabs[selectedTab] === 'Detail' && 
						<EndUsersAccountsTable 
						listItems={flattenListItems()}
						booked={quote.booked}
						changeRequested = {quote.enduser_desc_status === 'change_requested'}
						selectedView={selectedView}
						fetchItems={fetchItems}
						totalRecords={totalRecords}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						loading={loading}
						filters={filters}
						quote_id={id}
						currentFilter={currentFilter}
						currentSearch={currentSearch}
						onRowSelected={onRowSelected}
						onSelectionChanged={onSelectionChanged}
						successAlert={successAlert}
						errorAlert={errorAlert}
						addLineItems={addLineItems}
						setEditedLineItem={setEditedLineItem}
						removeAddedLineItems={removeAddedLineItems}
						selectedLineItems={selectedLineItems}
						grid={grid}
						onSaveLineItem={handleAddLineItemChangeRequest}
						addedLineItem={addedLineItem}
						exceptCols={['request']}
						selectedModel={selectedModel}
						editableCols={addLineItemEditableCols}
						updateColValue={updateAddedLineItemColValue}
						assetType={selectedAssetType}
						/>
						}
						{summaryTabs.includes(tabs[selectedTab]) &&
						<div calssName="w-full h-full" style={{position: 'relative'}}>
						{tabs[selectedTab] === 'Software Subscription' &&
						<ExpandButton
							onClick={summarySelected}
							color="primary"
							variant="contained"
							icon="edit"
							title="Edit"
							size="small"
							style={{position: 'absolute', right: 20, bottom: listItemsModeled.length == 0?0:80, zIndex: 100}}
						/>
						}
						<TablePlain 
						addLineItemBtnText="Add line item"
						listItems={getListItemsModeled()}
						listItemsModeled={listItemsModeled}
						selectedView={selectedView}
						fetchItems={fetchItems}
						totalRecords={totalRecords}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						loading={loading}
						filters={filters}
						quote_id={id}
						currentFilter={currentFilter}
						currentSearch={currentSearch}
						addLineItems={addLineItems}
						onRowClick={(item) => {
							onClickChangeSelectedAssetsType(selectedAssetType)
							if(selectedAssetType !== 'software_subscription') summarySelected(item)
						}
						}
						onSelectionChanged={onSelectionChanged}
						successAlert={successAlert}
						errorAlert={errorAlert}
						grid={gridSummary}
						table_name = { `QUOTE_SUMMARY_${selectedAssetType.toUpperCase()}` }
						/>
						</div>
						}
						</>
						}
						{tabs[selectedTab] === 'Activities' &&
							<ChnageReqsActionsLogsView
								quote={quote} />
						}
						{tabs[selectedTab] === 'Line Items' &&
							<AllLineItems
								quote={quote} />
						}
						{tabs[selectedTab] === 'Notes' &&
							<NoteView quote_id={quote.id} />
						}
						{tabs[selectedTab] === 'Email' &&
							<EmailView getNotOpenedEmailCount={getNotOpenedEmailCount} quote_id={quote.id} />
						}
						{tabs[selectedTab] === 'Chat' &&
							<ChatView quote_id={quote.id}/>
						}

					</CardContent>
				</Card>
				
				{(quote != null && quote.agent && OpenInNewTab) &&
				<Grid container className="pt-10">
				<Grid item xs={12} lg={3}>
					<Card style={{borderRadius: 5, padding: 5}}>
						<CardContent style={{padding: 5}}>
							{loading ?
							<Box display="flex" alignItems="center" justifyContent="center" justifyItems="center">
								<CircularProgress color="primary"/>
							</Box>
							:
							<Grid container>
								<Grid item lg className="border-r pl-5">
									<p className="mb-3">Total MSRP</p>
									<strong>${toNumberFormat(totalPrices?.total_msrp) || '0.00'}</strong>
								</Grid>
								<Grid item lg className="pl-5">
									<p className="mb-3">Total Price</p>
									<strong>${toNumberFormat(totalPrices?.total_sell_price)}</strong>
								</Grid>
							</Grid>
							}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} lg>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, transition: { delay: 0.1 } }}
					className="flex flex-1 items-center justify-end"
				>
					<Button
					color="primary"
					variant="outlined"
					className={`${classes.buttonSquare}`}
					onClick = {()=>downloadPDF()}
					>
						Export to PDF
					</Button>
					
					<Button
						variant="outlined"
						color="primary"
						onClick = { ()=>handleExportToCSV()}
						className={`ml-10 ${classes.buttonSquare}`}>
							Export to CSV
					</Button>
					{tabs[selectedTab] === 'Detail' && quoteChangable && quote.enduser_request_status === actions.request_statuses.pending &&
					<>
					<Button
					color="primary"
					variant="outlined"
					className={`${classes.buttonSquare} ml-10`}
					onClick={() => {selectedLineItems.length > 0 ? setTransferDialog(true) : toast.error('Please select line item to proceed')}}
					>
						Transfer Line Items
					</Button>
					{
						buttonVisible && 
						<Button
							color="primary"
							variant="outlined"
							className={`${classes.buttonSquare} ml-10`}
							onClick={() => {selectedLineItems.length > 0 ? setValidationDialog(true) : toast.error('Please select line item to proceed')}}
							>
								Send for Validation
						</Button>
					}
					</>
					}
					
					{quote.id &&
					<>
					<Button
						variant="outlined"
						color="primary"
						onClick={()=>{
							setNoteDialogOpen(true)
						}}
						className={`ml-10 ${classes.buttonSquare}`}>
							Add a note
					</Button>
					<Button
						variant="outlined"
						color="primary"
						onClick={()=>{
							setShowPriceSummary(true)
						}}
						className={`ml-10 ${classes.buttonSquare}`}>
							Show Total
					</Button>
					{selectedPage === pages.summary && quoteChangable &&
					<>
					<Button
					color="secondary"
					variant="contained"
					disabled={quote.enduser_request_status !== actions.request_statuses.pending}
					className={`${classes.buttonSquare} ml-10`}
					onClick={() => router.push(`/quotes/payment/${id}`)}
					>
						Approve
					</Button>

					<Button
					color="primary"
					variant="contained"
					className={`${classes.buttonSquare} ml-10`}
					onClick={()=>EnduserGenericQuoteActionView({
						onlyCallback: true,
						quote,
						action_name: actions.actions.deny_quote.label,
						action: actions.actions.deny_quote.action,
						action_type:'acttion',
						action_reasons: ['High Price','Not Needed',"Can't find assets","Going to refresh"],
						message: 'Please mention the reason of denial',
						callback: denyQuote
					},dispatch)}
					disabled={quote.enduser_request_status !== actions.request_statuses.pending}
					>
						{
							quoteDenying && 
							<span style={{paddingRight: 5}}>
							<CircularProgress size={10}/>
							</span>
						}
						Decline
					</Button>
					</>
					}
					</>
					}
				</motion.div>
				</Grid>
				</Grid>
				}
				</Grid>
				</Grid>
				</main>
				{/* </div> */}
			</div>
			</FuseScrollbars>
			<PriceSummary
			quoteId={id}
			// onConfirm={transferQuoteToEndUser}
			open={showPriceSummary}
			onClose={()=>setShowPriceSummary(false)}
			// confirming={transferringQuote}
			/>
		</div>
	);
}

export default QuoteDetail;
