import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Stack, Box, Stepper, Step, StepButton, Button, TextareaAutosize, DialogTitle, Divider, Icon, IconButton, TextField, Typography, InputAdornment, Drawer, CardContent, Paper, Grid, Item, CircularProgress } from '@mui/material'
import axios from '@fuse/utils/axios'
import Card from '@material-ui/core/Card';
import FormControl from '@mui/material/FormControl';
import { useCurrentUser } from '@fuse/hooks'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Editor from '@fuse/components/Editor';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import { formatPriceField, generateProperLocationString } from '@fuse/utils/general'
import { usePermission } from '@fuse/hooks';
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import { countries } from '@fuse/utils/countryName'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
// import { countries } from '@fuse/utils/countryName'
import AddDepartmentFromQuickAction from './modal/AddDepartmentFromQuickAction'
import ListItemText from '@material-ui/core/ListItemText';
import { quickAddedUser } from '../../main/users/store/reportViewDialogReducer'
import AlertModal from 'helper/alertModal'



const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
        helperText: {
            color: 'red',
            paddingLeft: '1px'
        },
        datePickerInput: {
            '& .MuiInputBase-input': {
                fontSize: '16px'
            }
        }
    }
))
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function QuickAddUser(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
	const toast = useToast(dispatch)
    const currentUser = useCurrentUser()

    const [showRole, setShowRole] = useState(false)
    const [department, setDepartment] = useState([])
	const [roles, setRoles] = useState([])
    const [isSuperUser, setIsSuperUser] = useState(false)
    const [startDate,setStartDate] = useState({
		date: null,
		error: false
	})
	const [endDate,setEndDate] = useState({
		date: null,
		error: false
	})
    const [completed, setCompleted] = React.useState({});
    const [activeStep, setActiveStep] = React.useState(0);
    const [ openAddDepartmentModal, setOpenAddDepartmentModal ] = useState(false)
    const [userRoles, setUserRoles] = useState([]);
    const { data: editFromState } = useSelector(({ usersApp }) => usersApp.formViewDialog);
    const [openAlerModal, setOpenAlertModal] = useState(false);
    const [email, setEmail] = useState()
    console.log('props', editFromState)


    async function getDepartment() {
		try {
			const res = await axios.get("/department")
			const { data } = res.data
			setDepartment(data.map((item) => {
				return {
					id: item.id,
					department_name: item.department_name
				}
			}))
		} catch (err) {
			console.log(err)
		}
	}

    const handleRoleChange = (event) => {
		setUserRoles(event.target.value);
	};

    function getRoleArr(userRoles, roles) {
		let item = [];
		for (let i = 0; i < userRoles.length; i++) {
			item.push(roles.find((item) => {
				if (item.display_name == userRoles[i]) {
					return item
				}
			})?.id)
		}
		if (item.length == 0) return null
		return item
	}

    useEffect(() => {
		if (editFromState) {
			let roles_ = editFromState.miltiple_role?.map(item => item.name)
			setUserRoles(roles_);
			setValue('firstName', editFromState.first_name)
			setValue('lastName', editFromState.last_name)
			setValue('title', editFromState.title)
			setValue('email', editFromState.email)
			// setFormattedNumber(editFromState.original_info.phone ?? '')
			setValue('phone', editFromState.original_info?.phone ?? '')
			// setValue('loginEnabled', editFromState.login_enabled)
			setValue('role', editFromState.roles_end_user)
			setValue('empNo', editFromState.original_info?.emp_no ?? '')
			setValue('address1', editFromState.original_info?.address ?? '')
			setValue('address2', editFromState.original_info?.address_2 ?? '')
			setValue('city', editFromState.original_info?.city ?? '')
			setValue('state', editFromState.original_info?.state ?? '')
			setValue('zipcode', editFromState.original_info?.zip ?? '')
			setValue('country', { name: editFromState.original_info?.country, code: 'N/A' });
			setValue('department', editFromState.department)
			// setValue('start_date', editFromState.original_info.start_date ? editFromState.original_info.start_date.split('T')[0] : '')
			// setValue('end_date', editFromState.original_info.end_date ? editFromState.original_info.end_date.split('T')[0] : '')
			setStartDate((prev) => {
				return {
					...prev,
					date: editFromState.original_info?.start_date
				}
			})
            setEmail(editFromState.email)
			// setEndDate((prev) => {
			// 	return {
			// 		...prev,
			// 		date: editFromState.original_info.end_date
			// 	}
			// })
			if (editFromState.login_enabled) setShowRole(true)
		}
	}, [editFromState])



    const newDepartmentAddedEvent = (createdData) => {
        setOpenAddDepartmentModal(false)
        setValue("department", {
            id: createdData.id,
            department_name: createdData.department_name
        })
        clearErrors("department")
        getDepartment()
    }

	async function getRoles() {
		try {
			const res = await axios.get("/roles")
			const { data } = res.data
			let all_roles = [...data.preDefinedRoles, ...data.customRoles]
			setRoles(all_roles.map((item) => {
				return {
					id: item.id,
					display_name: item.display_name
				}
			}))
		} catch (err) {
			console.log(err)
		}
	}


    const getUserDetails =  async () => {
		return axios.get("/user/get-details").then((res) => {
			const { data } = res.data;
			if ( data.includes('super_user') || data.includes('support')) {
				setIsSuperUser(true)
			}
		}).catch((err) => {
			console.log(err)
		})
	}

    const steps = ['User Details', 'Optional Information'];

    useEffect(() => {
		getDepartment()
		getRoles()
		getUserDetails()
	}, [])


    const handleCloseForm = () => {
        setActiveStep(0);
        reset(defaultValues);
        clearErrors()
        props.close('user')
    }


    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const totalSteps = () => {
        return steps.length;
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const defaultValues = {
        firstName: '',
		lastName: '',
		title: '',
		email: '',
        phone: '',
		loginEnabled: false,
		role: [],
		empNo: '',
		address1: '',
		address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: null,
        department: null
    };


    const validationSchema = [
        // 0 Required Details
        yup.object({
            firstName: yup.string().trim().required('First name is required.'),
            lastName: yup.string().trim().required('Last name is required!'),
            title: yup.string().trim().required('Title is required!'),
            email: yup.string().trim().email('Invalid email').required('Email address is required!'),
            phone: yup.string().trim().when({
                is: (val) => !!val,
                then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
            }),
            loginEnabled: yup.boolean().required(),
            // role: yup.array().of(yup.object().when('loginEnabled', {
            //     is: true,
            //     then: yup.object().shape({
            //         id: yup.string().required('Role is required'),
            //         display_name: yup.string().required('Role is required')
            //     }).required(),
            //     otherwise: yup.object().nullable(),
            // }))
        }),
        // 1 Contract Type
        yup.object({
            empNo: yup.string().notRequired(),
            address1: yup.string().notRequired(),
            address2: yup.string().notRequired(),
            city: yup.string().notRequired(),
            state: yup.string().notRequired(),
            zipcode: yup.string().notRequired(),
            country: yup.object().notRequired().nullable(),
            department: yup.object().notRequired().nullable(),
        })
    ];

    const currentValidationSchema = validationSchema[activeStep];


    const methods  = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(currentValidationSchema),
        mode: "onChange"
    });

    const { control, formState, handleSubmit, reset, setValue, clearErrors, getValues, trigger, register, setError } = methods

    const { errors } = formState


    const onSubmit = async (data) => {
        await trigger()
        if(Object.keys(errors).length !== 0) {
            return;
        }
        // const roleIds = data.role.map( (role, index) => {
        //     return role.id
        // })
        let role_arr = getRoleArr(userRoles, roles)

        // if(new Date(startDate.date) > endDate.date){
        //     toast.error("End date cannot be smaller than start date")
        //     return
        // }


        
        const payload = {
            first_name: data.firstName,
            last_name: data.lastName,
            title: data.title,
            email: data.email,
            login_enabled: showRole,
            role_id: role_arr ?? null,
            emp_no: data.empNo.trim(),
            address: data.address1.trim(),
            address_2: data.address2.trim(),
            city: data.city.trim(),
            state: data.state.trim(),
            zipcode: data.zipcode.trim(),
            country: data.country?.name ?? '',
            department_id: data.department?.id ?? null,
            start_date: startDate.date ?? '',
            // end_date: endDate.date ?? '',
            phone: data.phone || '',
        }

        if(editFromState){
            payload.user_id = editFromState.id,
            axios.post(`/user/update`, payload).then(response => {
           
                if (response.status == 201) {
                    toast.error(response.data.message)
                    return
                } 
                // else {
                    //onSuccess()
                    setActiveStep(0)
                    reset(defaultValues)
                    // props.getUsers()
                    // closeDialog()
                    toast.success(response.data.message)
                    dispatch(quickAddedUser())
                    props.close('user')
                // }
            }).catch(err => {
                console.log('212=>', err);
                toast.error("Something went wrong.")
            })
        }else{
            axios.post(`/user/add`, payload).then(response => {
           
                if (response.status == 201) {
                    toast.error(response.data.message)
                    return
                } 
                // else {
                    //onSuccess()
                    setActiveStep(0)
                    reset(defaultValues)
                    
                    // props.getUsers()
                    // closeDialog()
                    toast.success(response.data.message)
                    dispatch(quickAddedUser())
                    props.close('user')
                // }
            }).catch(err => {
                console.log('212=>', err);
                toast.error("Something went wrong.")
            })
        }
        
    }

    const checkEmailExist = async () => {
        try{
            const payload = {
                email: getValues().email.trim()
            }
            return (await axios.post(`/user/user-exist`, payload)).data.data.emailExist
        }catch(err){
            console.log(err)
        }
    }


    const handleNext = async () => {
        await trigger()
        if(showRole && !userRoles.length) return

        if(!editFromState){
            if(await checkEmailExist()){
                toast.error('Email already exists!')
                return
            } 
        }
        
        switch(activeStep) {
            // Required Details
            case 0:
                // console.log("Sujay Ghosh")
                // if(getValues().loginEnabled) {
                //     if(getValues().role.length === 0) {
                //         setError('role', {
                //             message:  "Role is required"
                //         })
                //     } else {
                //         clearErrors("role")
                //     }
                // } else {
                //     clearErrors("role")
                // }
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            default:
                break; 
        }

    };


    const handleOpenCloseAddDepartment = () => {
        setOpenAddDepartmentModal(!openAddDepartmentModal)
    }

    const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        handleCloseForm()
    }

    return (
        <>
            <div>
                <Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
                    <div className='heading_holder'>
                        <h4 className='heading'>{editFromState?'Update':'Add'} User</h4>
                        <IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
                            <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                        </IconButton>
                    </div>

                    <div className='progressbar_holder'>
                        <div style={{width: activeStep === 0 ? '50%' : activeStep === 1 ? '100%' : '0%'}} className='progress'>
                            &nbsp;
                        </div>
                    </div>

                    <div className='cstm_wizard_holder'>
                        <Box sx={{ width: '100%' }} className='box_holder'>
                            <Stepper activeStep={activeStep} className='stepper_holder'>
                                {steps.map((label, index) => (
                                <Step className='each_step_holder' key={label} completed={completed[index]}>
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                    {label}
                                    </StepButton>
                                </Step>
                                ))}
                            </Stepper>
                            <div className='main_content_holder'>
                                <h4 className='main_heading'>
                                    {activeStep === 0 && <span>User Details</span>}
                                    {activeStep === 1 && <span>Optional Information</span>}
                                </h4>
                                <FormProvider {...methods}>    
                                    <form>
                                        <div className='content_holder'>
                                            {activeStep === 0 && 
                                                <>
                                                    <Box className='light_blue_holder'>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        First Name
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller 
                                                                        name="firstName"
                                                                        className='serial_numberField'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                style={{ background: 'white' }}
                                                                                variant='outlined'
                                                                                fullWidth
                                                                                required
                                                                                defaultValue={defaultValues.firstName}
                                                                                error={!!errors.firstName}
                                                                                helperText={errors?.firstName?.message}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        margin: 0,
                                                                                        width: '100%',
                                                                                        paddingTop: '2px',
                                                                                        fontSize: '12px'
                                                                                    }
                                                                                }} 
                                                                            />
                                                                        )}
                                                                    />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Last Name
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller 
                                                                        name="lastName"
                                                                        className='serial_numberField'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                style={{ background: 'white' }}
                                                                                variant='outlined'
                                                                                fullWidth
                                                                                required
                                                                                defaultValue={defaultValues.lastName}
                                                                                error={!!errors.lastName}
                                                                                helperText={errors?.lastName?.message}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        margin: 0,
                                                                                        width: '100%',
                                                                                        paddingTop: '2px',
                                                                                        fontSize: '12px'
                                                                                    }
                                                                                }} 
                                                                            />
                                                                        )}
                                                                    />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Title
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller 
                                                                        name="title"
                                                                        className='serial_numberField'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                style={{ background: 'white' }}
                                                                                variant='outlined'
                                                                                fullWidth
                                                                                required
                                                                                defaultValue={defaultValues.title}
                                                                                error={!!errors.title}
                                                                                helperText={errors?.title?.message}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        margin: 0,
                                                                                        width: '100%',
                                                                                        paddingTop: '2px',
                                                                                        fontSize: '12px'
                                                                                    }
                                                                                }} 
                                                                            />
                                                                        )}
                                                                    />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Email address
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                    <Controller 
                                                                        name="email"
                                                                        className='serial_numberField'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                style={{ background: 'white' }}
                                                                                variant='outlined'
                                                                                disabled={editFromState}
                                                                                fullWidth
                                                                                required
                                                                                defaultValue={defaultValues.email}
                                                                                error={!!errors.email}
                                                                                helperText={errors?.email?.message}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        margin: 0,
                                                                                        width: '100%',
                                                                                        paddingTop: '2px',
                                                                                        fontSize: '12px'
                                                                                    }
                                                                                }} 
                                                                            />
                                                                        )}
                                                                    />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Phone Number
                                                                        {/* <span className='required_span'>*</span> */}
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller 
                                                                            name="phone"
                                                                            className='serial_numberField'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    style={{ background: 'white' }}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    required
                                                                                    defaultValue={defaultValues.phone}
                                                                                    error={!!errors.phone}
                                                                                    helperText={errors?.phone?.message}
                                                                                    FormHelperTextProps={{
                                                                                        style: {
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            fontSize: '12px'
                                                                                        }
                                                                                    }} 
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <div style={{display: 'flex', paddingLeft: '16px'}}>
                                                                {/* <Controller
                                                                    name="loginEnabled"
                                                                    className="mb-16"
                                                                    control={control}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <>
                                                                                <div className='flex items-center'>
                                                                                    <Checkbox
                                                                                        {...field}
                                                                                        color="primary"
                                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                                        checked={field.value}
                                                                                        onChange={(e, value) => {
                                                                                            field.onChange(value)
                                                                                            if (e.target.checked == true) setShowRole(true)
                                                                                            else setShowRole(false)
                                                                                        }}
                                                                                        // disabled={loginEnabledCondition()}
                                                                                    />
                                                                                    <Typography style={{fontSize: '1.3rem'}}>Login Enabled</Typography>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }}
                                                                /> */}
                                                                <input 
                                                                    type="checkbox" 
                                                                    id="vehicle1" 
                                                                    name="Login Enabled" 
                                                                    checked={showRole} 
                                                                    value={showRole}
                                                                    onChange={(e, value) => {
                                                                        if (e.target.checked == true) {
                                                                            setValue('loginEnabled', true)
                                                                            setShowRole(true)
                                                                        } else {
                                                                            setValue('loginEnabled', false)
                                                                            setShowRole(false)
                                                                            setUserRoles([])
                                                                        }
                                                                    }} 
                                                                />
                                                                
                                                                <Typography style={{marginTop: '10px', paddingLeft: '4px'}} className='status_text'>
                                                                    Login Enabled
                                                                        {/* <span className='required_span'>*</span> */}
                                                                </Typography>
                                                            </div>
                                                            { showRole &&
                                                            
                                                                // showRole &&
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Role
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller
                                                                            name="roleField"
                                                                            className={classes.dropdownCss}
                                                                            control={control}
                                                                            // style={{transform:"translateY(-50px)"}}
                                                                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                <>
                                                                                    {/* <InputLabel required id="demo-simple-select-outlined-label" className="mt-1">Select Roles</InputLabel> */}
                                                                                    {/* <span style={{display:"inline-block",position:"absolute",top:"10px",fontSize:"10px",left:"15px",background:"#f8f8f8",color:"#562459",width:"65px",zIndex:"1223646466"}}>Select Roles*</span> */}
                                                                                    <Select
                                                                                    
                                                                                        required
                                                                                        labelId="demo-simple-select-outlined-label"
                                                                                        id="company_list_label"
                                                                                        // label="Select Roles"
                                                                                        multiple
                                                                                        value={userRoles}
                                                                                        onChange={handleRoleChange}
                                                                                        renderValue={(selected) => selected.join(', ')}
                                                                                        // disabled={loginEnabledCondition()}
                                                                                        // MenuProps={menuProps}
                                                                                        variant="outlined"
                                                                                        fullWidth
                                                                                        className="test"
                                                                                        style={{background:"#fff"}}
                                                                                        MenuProps={{
                                                                                            sx: {
                                                                                              maxHeight: 230,
                                                                                              '& .MuiMenuItem-root': {
                                                                                                whiteSpace: 'normal',
                                                                                              },
                                                                                            },
                                                                                        }}
                                                                                    // style={{transform:"translateY(-50px)"}}
                                                                                    >
                                                                                        {roles.map((company) => (
                                                                                            <MenuItem key={company.id} value={company.display_name} className='test'>
                                                                                                {/* <Checkbox checked={companyName.indexOf(company.company_name) > -1} /> */}
                                                                                                <ListItemText
                                                                                                    primary={company.display_name}
                                                                                                    primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                                                                                                />
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                    {/* <Autocomplete
                                                                                    {...field}
                                                                                    style={{ width: '100%' }}
                                                                                    options={roles}
                                                                                    disablePortal
                                                                                    getOptionLabel={option => option.display_name }
                                                                                    onChange={(event, value) => {
                                                                                        field.onChange(value)
                                                                                    }}
                                                                                    PaperComponent={({ children }) => (
                                                                                        <Paper style={{ marginTop: '0px' }}>{children}</Paper>
                                                                                    )}
                                                                                    renderInput={params => {
                                                                                        return (
                                                                                            <div ref={params.InputProps.ref}>
                                                                                            <TextField
                                                                                                {...params}
                                                                                                name="role"
                                                                                                label='Select role*'
                                                                                                variant='outlined'
                                                                                                fullWidth
                                                                                                error={!!errors.role}
                                                                                                helperText={errors?.role?.message}
                                                                                                inputRef={ref}
                                                                                                FormHelperTextProps={{
                                                                                                    style: {marginLeft : 0}
                                                                                                }}
                                                                                            />
                                                                                            </div>
                                                                                        )
                                                                                    }}
                                                                                /> */}
                                                                                </>
                                                                            )}
                                                                        />
                                                                        {!userRoles.length && <p className='MuiFormHelperText-root'
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            color:'red'
                                                                                        }}>Role is required</p>}
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                            }
                                                            
                                                        </Grid>
                                                    </Box>
                                                </>
                                            }
                                            {activeStep === 1 && 
                                                <>
                                                    <Box className='light_blue_holder'>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Employee Number                                                                    
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller 
                                                                            name="empNo"
                                                                            className='serial_numberField'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    style={{ background: 'white' }}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    required
                                                                                    defaultValue={defaultValues.empNo}
                                                                                    error={!!errors.empNo}
                                                                                    helperText={errors?.empNo?.message}
                                                                                    FormHelperTextProps={{
                                                                                        style: {
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            fontSize: '12px'
                                                                                        }
                                                                                    }} 
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Address Line 1                                                                   
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller 
                                                                            name="address1"
                                                                            className='serial_numberField'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    style={{ background: 'white' }}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    required
                                                                                    defaultValue={defaultValues.address1}
                                                                                    error={!!errors.address1}
                                                                                    helperText={errors?.address1?.message}
                                                                                    FormHelperTextProps={{
                                                                                        style: {
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            fontSize: '12px'
                                                                                        }
                                                                                    }} 
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Address Line 2                                                                   
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller 
                                                                            name="address2"
                                                                            className='serial_numberField'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    style={{ background: 'white' }}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    required
                                                                                    defaultValue={defaultValues.address2}
                                                                                    error={!!errors.address2}
                                                                                    helperText={errors?.address2?.message}
                                                                                    FormHelperTextProps={{
                                                                                        style: {
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            fontSize: '12px'
                                                                                        }
                                                                                    }} 
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        City                                                                   
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller 
                                                                            name="city"
                                                                            className='serial_numberField'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    style={{ background: 'white' }}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    required
                                                                                    defaultValue={defaultValues.city}
                                                                                    error={!!errors.city}
                                                                                    helperText={errors?.city?.message}
                                                                                    FormHelperTextProps={{
                                                                                        style: {
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            fontSize: '12px'
                                                                                        }
                                                                                    }} 
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>


                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        State/Province                                                                   
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller 
                                                                            name="state"
                                                                            className='serial_numberField'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    style={{ background: 'white' }}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    required
                                                                                    defaultValue={defaultValues.state}
                                                                                    error={!!errors.state}
                                                                                    helperText={errors?.state?.message}
                                                                                    FormHelperTextProps={{
                                                                                        style: {
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            fontSize: '12px'
                                                                                        }
                                                                                    }} 
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Zipcode                                                                  
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller 
                                                                            name="zipcode"
                                                                            className='serial_numberField'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    style={{ background: 'white' }}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    required
                                                                                    defaultValue={defaultValues.zipcode}
                                                                                    error={!!errors.zipcode}
                                                                                    helperText={errors?.zipcode?.message}
                                                                                    FormHelperTextProps={{
                                                                                        style: {
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            fontSize: '12px'
                                                                                        }
                                                                                    }} 
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Country                                                                  
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller
                                                                            name="country"
                                                                            className="mb-16"
                                                                            control={control}
                                                                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                <>
                                                                                    <Autocomplete
                                                                                        {...field}
                                                                                        style={{ width: '100%' }}
                                                                                        options={countries}
                                                                                        disablePortal
                                                                                        onChange={(event, value) => field.onChange(value)}
                                                                                        PaperComponent={({ children }) => (
                                                                                            <Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
                                                                                        )}
                                                                                        getOptionLabel={option => option.name ?? ''}
                                                                                        renderInput={(params) => {
                                                                                            return (
                                                                                                <div ref={params.InputProps.ref}>
                                                                                                    <TextField
                                                                                                        {...params}
                                                                                                        style={{ background: 'white' }}
                                                                                                        //label="Country"
                                                                                                        error={!!errors.country}
                                                                                                        helperText={errors?.country?.message}
                                                                                                        FormHelperTextProps={{
                                                                                                            style: {
                                                                                                                margin: 0,
                                                                                                                width: '100%',
                                                                                                                paddingTop: '2px',
                                                                                                                fontSize: '12px'
                                                                                                            }
                                                                                                        }}
                                                                                                        inputRef={ref}
                                                                                                        variant="outlined"
                                                                                                        fullWidth
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Department                                                                  
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller
                                                                            name="department"
                                                                            className="mb-16"
                                                                            control={control}
                                                                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                <>
                                                                                    <Autocomplete
                                                                                        {...field}
                                                                                        style={{ width: '100%' }}
                                                                                        options={department}
                                                                                        disablePortal
                                                                                        getOptionLabel={option => option.department_name ?? ''}
                                                                                        onChange={(event, value) => {
                                                                                            field.onChange(value)
                                                                                        }}
                                                                                        PaperComponent={({ children }) => (            
                                                                                            <Paper className="autocomplete_holder autocomplete_paper_holder">{children}
                                                                                                <div className='p-16'>
                                                                                                    <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddDepartment()}>
                                                                                                        <i className='ti ti-plus' />
                                                                                                        <span>Add Department</span>
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </Paper>
                                                                                        )}
                                                                                        renderInput={params => {
                                                                                            return (
                                                                                                <div ref={params.InputProps.ref}>
                                                                                                    <TextField
                                                                                                        {...params}
                                                                                                        style={{ background: 'white' }}
                                                                                                        name="department"
                                                                                                        //label='Department'
                                                                                                        variant='outlined'
                                                                                                        fullWidth
                                                                                                        error={!!errors.department}
                                                                                                        helperText={errors?.department?.message}
                                                                                                        inputRef={ref}
                                                                                                        FormHelperTextProps={{
                                                                                                            style: {
                                                                                                                margin: 0,
                                                                                                                width: '100%',
                                                                                                                paddingTop: '2px',
                                                                                                                fontSize: '12px'
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>


                                                            <Grid item xs={6}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        Start Date                                                                  
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <CustomDatePickerV2
                                                                            state={startDate}
                                                                            setState={setStartDate}
                                                                            required={false}
                                                                            //label='Start Date'
                                                                            // maxDate={endDate.date}
                                                                            // condition='End Date'									
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>

{/* 
                                                            <Grid item xs={6}>
                                                                <Box>
                                                                    <Typography className='status_text'>
                                                                        End Date                                                                  
                                                                    </Typography>
                                                                    <FormControl fullWidth className='assets_listSelect'>
                                                                        <CustomDatePickerV2
                                                                            state={endDate}
                                                                            setState={setEndDate}
                                                                            required={false}	
                                                                            //label='End Date'
                                                                            // minDate={startDate.date}
                                                                            condition='Start Date'											
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Box>
                                                </>
                                            }
                                        </div>
                                        <div className='btn_holder'>
                                            {activeStep !== 0 && 
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className='back_btn'
                                                >
                                                    Back
                                                </Button>
                                            }

                                            {activeStep !== steps.length - 1 ?
                                                (
                                                    <Button onClick={handleNext} className='next_btn'>
                                                        Next
                                                    </Button>
                                                ) : (
                                                    <Button onClick={handleSubmit(onSubmit)} className='next_btn'>
                                                        Proceed
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    </form>
                                </FormProvider>
                            </div>
                        </Box>
                    </div>
                </Drawer>
            </div>

            {openAlerModal &&
                <AlertModal  openAlerModal module="user" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }

            {
                openAddDepartmentModal && <AddDepartmentFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddDepartment}
                    openAddStatusModalStateData={openAddDepartmentModal}
                    newDataAddedEvent={newDepartmentAddedEvent}
                />
            }
        </>
        
    )

}

export default QuickAddUser