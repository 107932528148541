import React, {useRef, useState, useEffect } from 'react'
import reducer from "../../store";
import withReducer from 'app/store/withReducer';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import axios from "@fuse/utils/axios";

import lodash from "lodash"
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@material-ui/core';
import useToast from '@fuse/hooks/useToast';
import { useDispatch } from 'react-redux';
import { usePermission } from '@fuse/hooks';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { setrowGroupModel } from '../../store/filterParamsReducer';
import DeleteConfirmModal from 'helper/DeleteConfirm';


function CustomReportLayout() {

  	const [reports, setReports] = useState([])
  	const [ windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
  	const [ openDeleteDialog, setOpenDeleteDialog] = useState(false);
  	const [ reportData, setReportData] = useState(null);
  	// const parentRef = useRef();
  	const gridRef = useRef();
	  const router = useRouter()
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const { hasPermission } = usePermission()
	const viewReportPermission = hasPermission('view-report')
	const deleteReportPermission = hasPermission('delete-report')
	const [groupByArr, setGroupByArr] = useState([])
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [groupByHappen, setGroupByHappen] = useState(false)
  	const fetchReport = ()=>{
		axios.get('/reports/view-reports')
		.then(results=>{
		setReports(formatData(results.data.data))
		})
		.catch(err=>{
			console.log(err);
		})
	}

  useEffect(()=>{
    fetchReport()
  },[])

  	const frameworkComponents = {
		customFilter: CustomFilter,
	};

  function formatData(data){
		return data.map((item) => {
			let moduleName ;
			if(item.table_name === 'service request') {
				moduleName = 'service ticket';
			} else if(item.table_name === 'service request category') {
				moduleName = 'service ticket category';
			} else if(item.table_name === 'service request status') { 
				moduleName = 'service ticket status';
			} else if(item.table_name === 'service request source'){
				moduleName = 'service ticket source';
			}else{
				moduleName = item.table_name
			}
			return {
				report_name: item.report_name,
				module: moduleName,
				date_created:item.created_at?dateReformatTo_mmm_dd_yyyy(item.created_at):'',
				...lodash.omit(item, [
					"platform_customer_id",
					"filter",
					"created_at",
					"updated_at",
          'portal',
					'user_id',
					'table_name',
					'row_model',

				]),
			}
		})
	}

	

	const openInNewTab = (url) => {
		window.open(url, "_blank", "noreferrer");
	};

	function onColumnRowGroupChanged(event){
		if(event.columns.length == 0) {
			setGroupByArr([])
      		setGroupByHappen(false)
		}else{
			setGroupByArr(event.columns)
      		setGroupByHappen(true)
		}
	}

  const onFirstDataRendered = (params) => {
		params.columnApi.sizeColumnsToFit(4500);
	};

  const handleDeleteDialogOpen = (data) => {
		// handleClickOpen()
		console.log('clicled')
		setOpenDeleteDialog(true);
		setReportData(data)
	}

  const handleClickOpen = () => {
		
	};

	const handleClose = () => {
		setOpenDeleteDialog(false);
	};

  function handelDelete(table_name, report_name){
		axios.delete(`/reports/delete-report/${table_name}/${report_name}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message);
			}
			else{
				handleClose()
				toast.success("Report deleted successfully")
        fetchReport()
			}
		}).catch((err)=>{
			console.log(err)
			toast.error(err.response.data.message)
		})
	}


	function handleDeleteV2 () {
		if (reportData.module !== undefined && reportData.report_name !== undefined) {
			axios.delete(`/reports/delete-report/${reportData.module}/${reportData.report_name}`).then((res)=>{
				if(res.status === 201){
					toast.error(res.data.message);
				}
				else{
					handleClose()
					toast.success("Report deleted successfully")
			fetchReport()
				}
			}).catch((err)=>{
				console.log(err)
				toast.error(err.response.data.message)
			})
		}
	}

	function getRoutPath(name){
		if(name == 'asset') return 'assets-list'
		if(name == 'asset category') return 'categories'
		if(name == 'accessory') return 'accessories'
		if(name == 'model attribute') return 'model-dynamic-attributes'
		if(name == 'asset status') return 'asset-statuses'
		if(name == 'accessory category') return 'accessory-categories'	
		if(name == 'payment term') return 'payment-terms'	
		if(name == 'contract status') return 'contract-tags'	
		if(name == 'contract type') return 'contract-types'	
		if(name == 'service provider') return 'service-providers'	
		if(name == 'service level agreement') return 'service-level-agreements'	
		if(name == 'subscription category') return 'subscription-categories'	
		if(name == 'subscription status') return 'subscription-statuses'	
		if(name == 'subscription provider') return 'subscription-providers'	
		if(name == 'service ticket') return 'service-requests'	
		if(name == 'service ticket category') return 'service-request-categories'	
		if(name == 'service ticket source') return 'service-request-sources'	
		if(name == 'service ticket status') return 'service-request-statuses'	
		if(name == 'location type') return 'location-types'	
			
		return `${name}s`
	}

	const generateHeaderLabel = (header) => {
		return header
			.split("_")
			.map((word) => {
				return word.charAt(0).toUpperCase() + word.substring(1);
			})
			.join(" ");
};

	if (!viewReportPermission) {
		return (
			<div>
				<Card>
					<CardContent>You don't have permission to view any Reports at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		)
	}


  return (
		<div style={{background: '#fff', marginBottom:'15px', padding: '28px'}}>
			<AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow} 
				groupByArrFromList ={ groupByArr }
				tableName= "Custom Report"
				displayTableName="Custom Report"
			/>
			<div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'12px',
				}}
			>
				<AgGridReact
					ref = {gridRef}
					suppressRowClickSelection
					// onGridReady = { onGridReady }
					onFirstDataRendered={onFirstDataRendered}
					animateRows
					rowData={reports}
					rowHeight = {30}
					onColumnRowGroupChanged = { onColumnRowGroupChanged }
					frameworkComponents={frameworkComponents}
					// no rows overlay 
					overlayNoRowsTemplate="<span>No Result Found</span>"

					// Row Grouping conditions
					showOpenedGroup
					suppressDragLeaveHidesColumns
					suppressMakeColumnVisibleAfterUnGroup
					rowGroupPanelShow={ rowGroupPanelShow }

					// onColumnMoved = { onColumnMoved }
					// onColumnPinned = { onColumnPinned }
					// onColumnVisible = { onColumnVisible }
					// onSortChanged = { onSortChanged }
					groupHeaderHeight ={1}
					// headerHeight = {15}
					floatingFiltersHeight = {40}

					// status bar enabled on range selection
					enableRangeSelection
					statusBar={{
						statusPanels: [
						{ 
							statusPanel: 'agAggregationComponent' },
						],
					}}

					// to check the the no. of rows selected during range selection
					// onRangeSelectionChanged = { onRangeSelectionChanged }						

					// onFilterChanged = { onFilterChanged }	
					
					// // on column group changed
					// onColumnRowGroupChanged = { onColumnRowGroupChanged }

					cacheQuickFilter
					
					pagination
					defaultColDef={{
						// flex: 1,
						// minWidth: 180,
						// sortable: true,
						resizable: true
					}}
				>
					{/* {reports.length && Object.keys(reports[0]).map(header => {
						console.log(header)
						// if(header == 'report_name'){
						
							// return(
							// 	<AgGridColumn 
							// 		key = {"jhv"} 
							// 		field = {"kbu"}
							// 		suppressSizeToFit = {true}
							// 		headerName = {"jhv"}  
							// 		sortable = {true} 
							// 		filter = "text"
							// 		// floatingFilter = {true} 
							// 		
							// 		headerComponentFramework={CustomFilter}
							// 		flex={1}
							// 	></AgGridColumn>
							// )
						// }
						if(header == 'module'){
						
							return(
								<AgGridColumn 
									key = {header} 
									field = {header}
									suppressSizeToFit = {true}
									headerName = {generateHeaderLabel(header)}  
									sortable = {true} 
									filter = "text"
									// floatingFilter = {true} 
									
									headerComponentFramework={CustomFilter}
									flex={1}
								></AgGridColumn>
							)
						}
						// if(header == 'date_created'){
						
						// 	return(
						// 		<AgGridColumn 
						// 			key = {header} 
						// 			field = {header}
						// 			suppressSizeToFit = {true}
						// 			headerName = {generateHeaderLabel(header)}  
						// 			sortable = {true} 
						// 			filter = "text"
						// 			// floatingFilter = {true} 
						// 			
						// 			headerComponentFramework={CustomFilter}
						// 			flex={1}
						// 		></AgGridColumn>
						// 	)
						// }
												
					})} */}
					<AgGridColumn 
						key = "report_name" 
						field = "report_name"
						suppressSizeToFit
						headerName = "Report Name"  
						sortable 
						filter = "text"
						// floatingFilter = {true} 																
						
						headerComponentFramework={CustomFilter}
						flex={1}
						minWidth={200}
					 />
					<AgGridColumn 
						key = "module" 
						field = "module"
						suppressSizeToFit
						headerName = "Module"  
						sortable 
						filter = "text"
						// floatingFilter = {true} 
						
						headerComponentFramework={CustomFilter}
						flex={1}
						minWidth={200}
					 />
					<AgGridColumn 
						key = "date_created" 
						field = "date_created"
						suppressSizeToFit
						headerName = "Date Created"  
						sortable 
						filter = "text"
						// floatingFilter = {true} 																
						
						headerComponentFramework={CustomFilter}
						flex={1}
						minWidth={200}
					 />
																																																														

						<AgGridColumn
							lockPosition
							rowSelection="single"
							width={100}
              minWidth={100}
              maxWidth={100}
							suppressSizeToFit
							headerName="Actions"
							pinned="right"
							lockPinned
							className="action_field"
							cellRendererFramework={({ data }) => {
								if (data) {
									return (
										<>
											<Tooltip id="view" title="View" placement="top">
													{/* <Icon
														onClick={()=>{openInNewTab(`/${getRoutPath(data.module)}?report_name=${data.report_name}`)}}
														fontSize="small"
														style={{
															color: '#1d2939',
															paddingBottom: "3px",
															paddingRight: "30px",
															fontSize: "15px",
															cursor: "pointer",
														}}
													>
														visibility
													</Icon> */}
													<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-eye" onClick={()=>{openInNewTab(`/${getRoutPath(data.module)}?report_name=${data.report_name}`)}} />
												</Tooltip>
												{deleteReportPermission && <Tooltip id="delete" title="Delete" placement="top">
												{/* <Icon 
													onClick={() => handleDeleteDialogOpen(data)} 
													style={{
														color: '#1d2939',
														paddingBottom: '3px',
														// paddingRight:'30px',
														fontSize:'15px',
														cursor:'pointer'
													}}
												>
													delete
												</Icon> */}
												<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-trash" onClick={() => handleDeleteDialogOpen(data)} />
											</Tooltip>}
									
										</>
									);
								} 
									return <></>;
								
							}}
						 />
				</AgGridReact>
			</div>
			{/* <DeleteModelDialog
				openDeleteDialog={openDeleteDialog}
				reportData={reportData}
				handelDelete={handelDelete}
				handleClose={handleClose}
			/> */}
			{
				openDeleteDialog && 
				<DeleteConfirmModal
					openAlerModal={openDeleteDialog}
					module={'Custom Report'}
					handleCloseAlertModal={handleClose} 
					handleAlertModal={handleDeleteV2}
				/>


			}
		</div>		
  	)
}

function DeleteModelDialog(props) {
	return (
	  <div>
		<Dialog
		  open={props.openDeleteDialog}
		  onClose={props.handleClose}
		  aria-labelledby="alert-dialog-title"
		  aria-describedby="alert-dialog-description"
		>
		  <DialogTitle id="alert-dialog-title">
			Delete Custom Report
		  </DialogTitle>
		  <DialogContent>
			<DialogContentText id="alert-dialog-description">
			  <h4>Are you sure to delete this Custom Report named as <b>{props.reportData?.report_name}</b>?</h4>
			</DialogContentText>
		  </DialogContent>
		  <DialogActions>
			<Button onClick={props.handleClose}>Cancel</Button>
			<Button onClick={() => props.handelDelete(props.reportData.module, props.reportData.report_name)} autoFocus>
			  Delete
			</Button>
		  </DialogActions>
		</Dialog>
	  </div>
	)  
}

export default withReducer('reportApp', reducer)(CustomReportLayout);
