import React from "react";
import { Tabs, Tab, Breadcrumbs, Link, Box } from "@mui/material";
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  Link as RouterLink
} from "react-router-dom";
import Assets from './Assets';
import Contracts from './Contracts';
import Subscriptions from './Subscriptions';
import Iot from './Iot';

export default function Notification() {
  const routes = ["/notification/assets", "/notification/contracts", "/notification/subscriptions", "/notification/iot"];
  const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);

  return (
    <div className="page_content_holder">
		<BrowserRouter>			
			<Route
				path="/"
				render={(history) => (
					<>
						<div role="presentation" className="cstm_breadcrumbs_holder">
							<Breadcrumbs aria-label="breadcrumb">
								<Link underline="hover" color="inherit" href>
									System Settings
								</Link>
								
								<span>{getLastItem(history.location.pathname)} Notifications</span>
							</Breadcrumbs>
						</div>
						<Box className='cstm_tabs_holder'>
							<Tabs
								className='tabs_main_holder'
								value={
								history.location.pathname !== "/"
									? history.location.pathname
									: false
								}
							> 
								<Tab
									className='each_tab_holder'
									label="Assets"
									value={routes[0]}
									component={RouterLink}
									to={routes[0]}
								/>
								<Tab
									className='each_tab_holder'
									label="Contracts"
									value={routes[1]}
									component={RouterLink}
									to={routes[1]}
								/>
								{/* <Tab
									className='each_tab_holder'
									label="Subscriptions"
									value={routes[2]}
									component={RouterLink}
									to={routes[2]}
								/> */}
								<Tab
									className='each_tab_holder'
									label="IoT"
									value={routes[3]}
									component={RouterLink}
									to={routes[3]}
								/>
							</Tabs>
						</Box>
					</>
				)}
			/>
			<Switch>
				<Route path="/notification/assets" component={Assets} />
				<Route path="/notification/contracts" component={Contracts} />
				<Route path="/notification/subscriptions" component={Subscriptions} />
				<Route path="/notification/iot" component={Iot} />
			</Switch>
		</BrowserRouter>
    </div>
  );
}
