import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import EndUsersAccountsTable from '../../tables';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import FuseLoading from '@fuse/core/FuseLoading';

const drawerWidth = 400;
const headerHeight = 200;
const views = ['List View','Grid View'];
const defaultCounts = {end_users:0, locations:0};
const defaultSearches = {location:'',quote_indicator:'',quote_status:'',revenue:'',action:''}

const useStyles = makeStyles(theme => ({
	'@global': {
		'#fuse-main': {
			height: '100vh'
		}
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: '100%',
		position: 'relative',
		// flex: '1 1 auto',
		height: 'auto',
		backgroundColor: theme.palette.background.default
	},
	cardGen: {
		width: '100%',
	},
	topBg: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		height: headerHeight,
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none'
	},
	contentCardWrapper: {
		position: 'relative',
		padding: '24px 24px 0 24px',
		maxWidth: 1400,
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 0 auto',
		width: '100%',
		minWidth: '0',
		maxHeight: '100%',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			padding: '16px 16px 0 16px'
		},
		[theme.breakpoints.down('xs')]: {
			padding: '12px 12px 0 12px'
		}
	},
	contentCard: {
		display: 'flex',
		position: 'relative',
		// flex: '1 1 100%',
		flexDirection: 'row',
		backgroundColor: theme.palette.background.paper,
		minHeight: 0,
		overflow: 'hidden',
		borderRadius: '20px 20px 0 0'
	},
	drawerPaper: {
		width: drawerWidth,
		maxWidth: '100%',
		overflow: 'hidden',
		height: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'relative'
		}
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 1 100%',
		zIndex: 10,
		background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
			theme.palette.background.paper,
			0.6
		)} 20%,${fade(theme.palette.background.paper, 0.8)})`
	},
	content: {
		display: 'flex',
		// flex: '1 1 100%',
		minHeight: 0
	},
	avatar: {
		backgroundColor: red[500],
	},
	formControl: {
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	formControlMinWidth: {
		minWidth: 200,
	}
  
}));

function Main(props) {
	const dispatch = useDispatch();
	const [selectedView, setSelectedView] = useState(0);
	const [counts, setCounts] = useState(defaultCounts);
	const [quotes,setQuotes] = useState([]);
	const [companyInitial,setCompanyInitial] = useState('');
	const [searches,setSearches] = useState(defaultSearches);
	const classes = useStyles(props);
	const router = useRouter();
	const [loading, setLoading] = useState(false);
	const [endUsersAccountsMod,setEndUsersAccounts] = useState([]);
	const [flattenedEndUserAccounts,setFlattenedEndUserAccounts] = useState({});

	useEffect(()=>{
		const id = router.params.id;

		setLoading(true);

		axios.get(`/contracts/${id}`)
		.then(res => {
			const { data } = res.data;
            console.log(data)
		})
		.catch(err => {
			console.log(err);
		}).finally(() => {
            setLoading(false);
        })
	},[]);

	function clearSearch() {
		setSearches(defaultSearches);
		setQuotes(endUsersAccounts);
	}

	if(loading){
		return(
			<FuseLoading/>
		)
	}

	return (
		<div className={clsx(classes.root)}>
			<div className={classes.topBg} />

			<div className={clsx(classes.contentCardWrapper, 'container')}>
				<div className={clsx(classes.contentCard, 'shadow')}>
				<FuseScrollbars className="flex-grow overflow-x-auto">
				<main>
					<Grid container spacing={3} className="p-10">
					<Grid item xl className={classes.cardGen}>
				<Card>
				<CardHeader
					avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            { companyInitial }
                        </Avatar>
					}
					title={
						<Grid container spacing={2}>
							{
								endUsersAccountsMod.length > 0 && 
								Object.keys(flattenedEndUserAccounts)
								.map(key => {
									if(key != 'id' && flattenedEndUserAccounts[key] != null){
									
									return(<Grid item xs={6} sm={3}>
									<span style={{fontWeight:"bold"}}>
										{key.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
									</span>
									<br/>
									{flattenedEndUserAccounts[key]}</Grid>)
									}
								})
								
							}
						</Grid>
					}
					subheader={
						<Grid container spacing={3}>
							<Grid item>Number of end-users: {counts.end_users}</Grid>
							<Grid item>Number of locations: 14</Grid>
						</Grid>
					}
				/>
				<Divider/>
					<CardContent>
						<Typography variant="body2" color="textSecondary" component="p">
							<div style={{width:'100%'}}>
						<Grid container spacing={2} className="p-24">
						<Grid item xs>
							<span style={{fontWeight:"bold"}}>
								Snapshot
							</span></Grid>
							<Grid item xs>
							<span style={{fontWeight:"bold"}}>
								Number of Active Quotes
							</span>
							<br/>20</Grid>
							<Grid item xs>
							<span style={{fontWeight:"bold"}}>
								Number of Upcoming Quotes
							</span>
							<br/>20</Grid>
							<Grid item xs>
							<span style={{fontWeight:"bold"}}>
								Number of Pending Quotes
							</span>	
							<br/>14</Grid>
							
							<Grid item xs>
							<span style={{fontWeight:"bold"}}>
								Number of Lost Quotes
							</span>	
							<br/>5</Grid>
							<Grid item xs></Grid>
							
							<Grid item xs></Grid>
						</Grid>
						<Divider/>
						<Grid container spacing={2} className="p-24">
							<Grid item sm={2}>
							<span style={{fontWeight:"bold"}}>								
							</span></Grid>
							<Grid item sm={2}>
							<span style={{fontWeight:"bold"}}>
								YTD Actual Revenue
							</span>
							<br/>$122,221.00</Grid>
							<Grid item sm={2}>
							<span style={{fontWeight:"bold"}}>
								Forcasted Revenue
							</span>
							<br/>$325,840.00</Grid>
							<Grid item sm>
								<Box className={classes.pullRight}>
								<Button
									variant="contained">
										View Chart
								</Button>
								</Box>
							</Grid>
						</Grid>
						</div>
						</Typography>
					</CardContent>
				<Divider/>
					<CardActions disableSpacing className={classes.pullRight}>
						<ButtonGroup>
						<Button
							variant="contained">
								Chats
						</Button>
						<Button
							variant="contained">
								Emails
						</Button>
						<Button
							variant="contained">
								Contacts
						</Button>
						<Button
							variant="contained">
								Assets
						</Button>
						<Button
							variant="contained">
								Locations
						</Button>
						</ButtonGroup>
					</CardActions>
				</Card>
				</Grid>
				<Grid item xl className={classes.cardGen}>
				<Card>
					<CardHeader title="Filters"/>
					<Divider/>
					<CardContent>
					</CardContent>
				</Card>
				</Grid>
				<Grid item xl className={classes.cardGen}>
				<Card>
					<CardContent>
						<ArrowButton onClick={()=>console.log('works')} label='No Action (15)'/>
						<ArrowButton onClick={()=>console.log('works')} label='Changed By Agent (15)'/>
					</CardContent>
					<Divider/>
					<CardContent>
						<Box className={classes.pullRight}>
							<FormControl variant="outlined" className={classes.formControlMinWidth}>
								<InputLabel htmlFor="view_selection">Select View</InputLabel>
								<Select
								native
								value={selectedView}
								onChange={e=>setSelectedView(e.target.value)}
								label="Select View"
								inputProps={{
									name: 'view_selection',
									id: 'view_selection',
								}}
								>
								{
									views.map((view,index) => <option value={index}>{view}</option>)
								}
								</Select>
							</FormControl>
						</Box>
					</CardContent>
					<Divider/>
					<CardContent>
						<EndUsersAccountsTable selectedView={selectedView} endUsersAccounts={quotes}/>
					</CardContent>
				</Card>
				</Grid>
				</Grid>
				</main>
				</FuseScrollbars>
				</div>
			</div>
		</div>
	);
}

function ArrowButton({onClick,label}) {
	return(
		<span
		onClick={()=>onClick}
		className="arrow-pointer">
				{label}
		</span>
	);
}

export default Main;
