import { combineReducers } from '@reduxjs/toolkit';
import mapViewDialogReducer from './mapViewDialogReducer';
import reportViewDialogReducer from './reportViewDialogReducer';
import filterParamsReducer from './filterParamsReducer';
import formViewDialog from './formViewDialogReducer';

const reducer = combineReducers({
	mapViewDialogReducer,
	filterParamsReducer,
	reportViewDialogReducer,
	formViewDialog
});

export default reducer;
