import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Box, Grid } from "@material-ui/core"
import WizardScreenDailog from "./components/WizardScreenDailog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  label: {
    color: "green",
  },
}));

const WizardScreen = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('lg');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleMaxWidthChange = (event) => {
  //   setMaxWidth(event.target.value);
  // };

  // const handleFullWidthChange = (event) => {
  //     setFullWidth(event.target.checked);
  // };
   
  return (
    <>
      <Box className="welcome-screen">
        <Box className="upper-Text-part">
          <Typography variant="h2" className="welcome-screen-main-title mb-3">Welcome To Dalos</Typography>
          <Typography className="welcome-screen-subTitle">your platform to manage and track your assets and subscriptions!</Typography>
          <Typography className="welcome-screen-miniTitle">We're thrilled to have you onboard.</Typography>
          <Box className="bottom-text-part text-center mt-5">
              <Typography className="ws-title">
                This guide makes it easy for you to get started by taking you through the step-by-step process to track assets and subscriptions.
              </Typography>
              <Typography className="ws-miniTitle">Click the box below to begin setting up assets or subscriptions</Typography>
              <Box className="ws-actionBtns">
                <Button variant="contained" onClick={handleClickOpen}>Add Asset</Button>
                <Button variant="contained">Add Subscription</Button>
                <Button variant="contained">Add Both</Button>
              </Box>
          </Box>
        </Box>
      </Box>

      <WizardScreenDailog 
        open={open}
        setOpen={setOpen}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
    </>
  );
};

export default WizardScreen;
