import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@fuse/components/general/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, AccordionActions } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import useRouter from '@fuse/hooks/useRouter';
import ListView from '../components/listView';
import lodash from "lodash"
import { dateReformatTo_mmm_dd_yyyy, getFormattedDate } from '@fuse/utils/dateOperations'
// import { toNumberFormat } from '@fuse/utils/parsePrice';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ContractsTable(props) {
	const table_name = "SHIPPING_DETAILS_CONTRACT_LISTING";
	const classes = useStyles();

	const dispatch = useDispatch();
	const router = useRouter();

	const [data, setData] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [loading, setLoading] = useState(false);

	const { shippingId } = props;

	const [contracts, setContracts] = useState([]);

	let paginatedContracts = contracts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event);
		setPage(0)
	}

	function getData() {
		setLoading(true);
		// if(!props.viewPermission){
		// 	setLoading(false);
		// 	return ;
		// }
		axios.get("/contracts",{
			params: {
				shipping_id: shippingId,
			}
		}).then((res) => {
			const { data } = res.data;
			setContracts(formatContracts(data))
		}).catch((err) => {
			console.log(err)
		}).finally(() =>{
			setLoading(false)
		})
	}

	function formatContracts(data){
		console.log(data)
		return data.map((contract) => {
			const dynamicAttributes = Object.entries(contract.dynamic_attributes).reduce((acc, [key, item]) => {
				acc[key] = item.attribute_value

				return acc;
			},{});

			const serviceTypeList = contract?.contract_line_items.reduce((acc, item) => {
				acc.push(item?.asset?.service_type);
				return acc;
			}, []);

			const serviceType = (new Set(serviceTypeList)).size == 1 ? "Various" : serviceTypeList[0];

			return {
				contract_id: contract.entity_external_platform_id,
				company_name: contract.user_company?.company_name,
				provider_name: contract.service_provider_name,
				...lodash.omit(contract, [
					"user_companies_id",
					"user_companies_users_id",
					"user_company",
					"user_companies_user",
					"dynamic_attributes",
					"user_id",
					"platform_customer_id",
					"entity_external_platform_id",
					"updated_at",
					"shipping",
					"billing",
				]),
				billing_id: contract?.billing?.entity_external_platform_id,
				shipping_id: contract?.shipping?.entity_external_platform_id,
				location: contract?.shipping 
						  ? `${contract.shipping.address1}, ${contract.shipping.city}, ${contract.shipping?.zip}` 
						  : "",
				contract_start_date: getFormattedDate(contract.contract_start_date),
				contract_end_date: getFormattedDate(contract.contract_end_date),
				asset_count: contract?.contract_line_items?.length,
				quote_number: contract?.quote?.quote_id,
				service_type: serviceType,
				total_price: (contract?.contract_line_items 
								   ? contract?.contract_line_items.reduce((acc, item) => acc + parseInt(item.sell_price), 0) 
								   : 0.00),
				created_date: getFormattedDate(contract?.created_at) || "",
				...dynamicAttributes,
			}
		})
	}

	const columnHeaders = [
		{
			field: 'contract_id',
			label: 'Contract ID',
			cellClicked: function(params, value){
				router.push(`/contracts/details/${params.node.data.id}`)
			}
		},
		{
			field: 'asset_count',
			label: 'Number of assets',
		},
		{
			field: 'contract_status',
			label: 'Status',
		},
		{
			field: 'location',
			label: 'Location',
		},
		{
			field: 'quote_number',
			label: 'Quote number',
			cellClicked: function (params, value){
				console.log(params.node.data);
				router.push(`/quotes/detail/${params.node.data?.quote?.id}`)
			},
		},
		{
			field: 'contract_start_date',
			label: 'Start date',
		},
		{
			field: 'contract_end_date',
			label: 'End date',
		},
		{
			field: 'service_type',
			label: 'Service type',
		},
		{
			field: 'total_price',
			label: 'Amount',
		},
		{
			field: 'created_date',
			label: 'Created date',
		},
	];

	useEffect(() => {
	    getData()
	}, [])

	if (loading) {
		return (<FuseLoading />)
	}
	// else if(!props.viewPermission){
	// 	return (
	// 		<div>
	// 			You don't have the view permission for contracts
	// 		</div>
	// 	)
	// }
	else if (contracts.length <= 0) {
		return (
			<div
				style={{ width: "100%" }}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="w-full flex flex-1 items-center justify-center h-full"
			>
				<div style={{ height: "200px" }}>
					<Typography variant="body2" component="p" color="textSecondary">
						No data found
					</Typography>
				</div>
			</div>
		)
	}else{
		return (
			<div className="w-full flex flex-col">
				<ListView rows={paginatedContracts} columns={columnHeaders} /*onRowClicked={(params) => {
					router.push(`/contracts/details/${params.node.data.id}`)
				}}*/
				table_name = { table_name }
				/>
				<div className="p-10">
					<TablePagination
						className="flex-shrink-0 border-t-1 mt-10"
						component="div"
						count={contracts.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</div>
			</div>
		);
	}

	
}

export default withRouter(ContractsTable)
