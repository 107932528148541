import { Grid, Divider, Paper, Typography, Box, CircularProgress,Accordion, AccordionSummary, AccordionDetails, Chip } from '@material-ui/core';
import { useState,useEffect,useDispatch } from 'react';
import { useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	table: {
		height:430,
        overflowX:'auto',
	}
});

function ShowLogs(props){
    const classes = useStyles()
    const theme = useTheme();
    if(props.logs.length<0){
        console.log(props.logs);
        return(
            <>
                <h6>No Logs available</h6>
            </>
        )
    }else{
        return(
            <>
                <Paper 
                    square 
                    style={{background:theme.palette.action.selected,paddingLeft:20,paddingTop:10,paddingBottom:10,paddingRight:50}}
                    className="flex flex-1 items-center justify-center"
                >
                    <Grid container style={{width:"100%"}}>
                        <Grid item xs={3}>User Name</Grid>
                        <Grid item xs={3}>User Type</Grid>
                        <Grid item xs={3}>Action</Grid>
                        <Grid item xs={3}>Date</Grid>
                    </Grid>
                </Paper>
                <div className={classes.table}>
                {props.logs.map(log => (
                <Accordion key={log.id}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`log_line_${log.id}`}
                    id={`log_line_${log.id}`}
                    >
                        <Grid container style={{width:"100%"}} spacing={2}>
                            <Grid item xs={3}>{`${log.client_user.first_name} ${log.client_user.last_name}`}</Grid>
                            <Grid xs={3}>
                            {
                                log.client_user.role.display_name && 
                                <Chip 
                                label={log.client_user.role.display_name.charAt(0).toUpperCase()+log.client_user.role.display_name.slice(1)} 
                                size="small" 
                                style={{borderRadius:2,marginTop:5}}
                                />
                            }
                            </Grid>
                            <Grid item xs>{log.action}</Grid>
                            <Grid item xs={3}>{dateReformatTo_mmm_dd_yyyy(log.created_at)}</Grid>
                        </Grid>
                    </AccordionSummary>
                    <Divider/>
                    {/* <AccordionDetails>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography>
                        {"details"}
                    </Typography>
                    </Grid> */}
                    {/* {
                        log.comment && log.comment != null && log.comment != '' ? 
                        <Grid item xs={12}>
                            <Paper className={`p-10 rounded-sm`} variant="outlined">
                                <Typography component="h6" style={{fontWeight:'bold'}}>
                                    Comment:
                                </Typography>
                                <Typography>
                                    {log.comment}
                                </Typography>
                            </Paper>
                        </Grid>
                        :
                        <>
                        </>
                    } */}
                    {/* </Grid>
                    </AccordionDetails> */}
                </Accordion>
                ))}
                </div>
            </>
        )
    
    }


}

export default ShowLogs;