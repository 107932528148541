import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { openFormViewerDialog, setFromData, setAccessoryData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AccessoriesDialog from './AccessoriesDialog';
import { Button } from '@material-ui/core';
import { openReportViewerDialog, setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import DeleteConfirmModal from 'helper/DeleteConfirm';
import {stripHtml} from '../../../../helper/common';


function ListView(props) {
	const table_name = "ACCESSORY";
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const gridRef = useRef();
	const [open, setOpen] = useState(false);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState('lg');	
	const [tabState, setTabState] = useState("seats");
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [accessory, setAccessory] = useState(null)
	const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false)
	const [accessoryId, setAccessorId] = useState(null)
	const skipHeader = [
		"id", "user_companies_id", "platform_customer_id",
		"entity_external_platform_id", "created_at",
		"updated_at", "original_info",
		"manufacturer", "accessory_category"
	]
	const [ loading, setLoading ] = useState(false);
	const [ accessoryCategoryMenu, setAccessoryCategoryMenu ] = useState([])
	const [ manufacturerMenu, setManufacturerMenu ] = useState([])
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);
	const [loadingActivityLog,setLoadingActivityLog] = useState(false);

	const tableHeaders = [
		"accessory_name", "model_number", "manufacturer_name", "accessory_category_name", "note", "source"
	];

	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );

	const tab_value = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.tab_value
  );

	function TabButton(props) {
		const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
		const isActive = tab == tabState;
		return (
				<Button 
					{...restProps} 
					onClick={() => tabStateChange(tab)} 
					className={restProps.className && isActive ? activeClass : ""}
				>
					{props.children}
				</Button>
		)
	}

	function handleClickOpen(data){
		setAccessorId(data.id)
		setOpen(true)
		dispatch(setAccessoryData(data))
	}
	
	function Close(){
		setOpen(false)
		setTabState('seats')
	}
	
	function TabContainer(props) {
		const { tabState, tab } = props;
		const shouldRender = tab == tabState;
		return shouldRender ? props.children : <></>
	}

	const handleConfirm = (data) => {
		setOpenDeleteDialog(true)
		setAccessory(data)
	}

	const handleClose = () => {
		setOpenDeleteDialog(false)
		setAccessory(null)
	}

	function handleEdit(data){
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}
	
	function handleAddAccessory(){
		dispatch(openFormViewerDialog())
	}

	function handleDelete(){
		axios.delete(`/accessory/delete/${accessory.id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message);
			} else {
				handleClose()
				toast.success(res.data.message)
				props.refresh()
			}
		}).catch((err)=>{
			console.log(err)
			toast.error(err.data.message);
		})
	}

	function formatString(str){
		if(!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}
	
	const onGridReady = (params) =>{
		props.endUsersAccounts.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(value === '') list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if(router.query.report_name == undefined) {
			dispatch(setfilterlist({}));
			// loadInitialState (params);
			dispatch(setrowGroupModel([]))
		}else{
			setRowGroupPanelShow('always')
			getReport(params);
		}
	}
	
	function onFilterChanged (event) {
		const rows = event.api.getFilterModel();
		// to show No result found in column filter
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "accessory";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		// autoGroupColumnDef={{ minWidth: 5000 }}
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState (params){
		axios.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then(results=>{
			const { data } = results.data;
			if(data !== null){
				const order = JSON.parse(data.order);
				const filter = JSON.parse(data.filter);
				params.columnApi.setColumnState(order);
				params.api.setFilterModel(filter);
			}
		})
		.catch(err=>{
			console.log(err);
		})
  }

	function saveView (rows, filter){
		let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 
		// const order = JSON.stringify(rows);
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

	function onColumnVisible (params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
			saveView(params.columnApi.getColumnState());
		}
	}
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }

	function onColumnRowGroupChanged(event) {
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}

		if (router.query.report_name == undefined) {
			const rows = event.columnApi.getColumnState();
			const first_row = rows[0];
			if (first_row.colId !== "ag-Grid-AutoColumn") {
			  dispatch(setrowGroupModel(rows));
			  saveView(rows);
			} else {
			  dispatch(setrowGroupModel(rows));
			}
		  }
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onCellValueChanged(e){
		let accessory_name = null
		let model_number = null
		let accessory_category = null
		let manufacturer = null
		let notes = null
		if(e.colDef.field == "accessory_name"){
			accessory_name = e.newValue
		}
		if(e.colDef.field == "model_number"){
			model_number = e.newValue
		}
		if(e.colDef.field == "accessory_category_name"){
			accessory_category = accessoryCategoryMenu.find(item=>item.label == e.newValue)
		}
		if(e.colDef.field == "manufacturer_name"){
			manufacturer = manufacturerMenu.find(item=>item.label == e.newValue)
		}
		if(e.colDef.field == "notes"){
			notes = e.newValue
		}
		const payload = {
			edit_id: Number(e.data.id),
			accessory_name:e.data.accessory_name,
			model_number: e.data.model_number,
			note: e.data.note,
			accessory_category_id: accessory_category ? accessory_category.id : e.data.accessory_category.id ,
			manufacturer_id: manufacturer ? manufacturer.id : e.data.manufacturer.id,
		}
		if(!payload.accessory_name){
			toast.error('Accessory Name is required')
			props.refresh()
			return
		} else if(!payload.model_number){
			toast.error('Model Number is required')
			props.refresh()
			return
		}
		axios.post(`/accessory/edit`, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
				toast.success('Accessory updated Successfully.')
				props.refresh()
			}		  
		}).catch(err => {
			console.log('212=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const generateHeaderLabel = (header) => {
		if(header == 'accessory_category_id'){
			return 'Accessory Category Name'
		} else if(header == 'manufacturer_id'){
			return 'Manufacturer Name'
		} else if(header === 'accessory_category_name') {
			return 'Accessory Category';
		} else if(header === "manufacturer_name") {
			return 'Manufacturer';
		} else if(header === "model_number") {
			return 'Model';
		} else if(header === "accessory_name") {
			return 'Accessory';
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data
		);
	},[query_data])

	useEffect(() => {
		return () => {
				dispatch(setQuickFilter(null))
		}
	}, [])

	const fetchManufacturerSuggestions = () => {
		setLoading(true)
		axios.get("/manufacturers").then(response => {
			setLoading(false)
			let manufacturer = AlphabeticalSorting(response.data.data,'manufacturer_name')
			setManufacturerMenu(manufacturer.map((item)=>{
				return{
					id: item.id,
					label: item.manufacturer_name
				}
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const fetchAccessoryCategorySuggestions = () => {
		axios.get('/accessory-category').then(response => {
			let accessory_category = AlphabeticalSorting(response.data.data,'category_name')
			setAccessoryCategoryMenu(accessory_category.map((item)=>{
				return{
					id: item.id,
					label: item.accessory_category_name
				}
			}))
		}).catch(err => {
			console.log('164=>',err);
			// toast.error('Something went wrong!')
		})
	}

	useEffect(()=>{
		fetchAccessoryCategorySuggestions()
		fetchManufacturerSuggestions()
		// if(Object.keys(filterModel).length !== 0){
		// 	setTimeout(()=>{
		// 		gridRef.current.api.setFilterModel(filterModel);
		// 	},0)
		// }
	},[])

	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	//logs
	function formatLogs(data){
		return data.map((item)=>{
				return {
						id:item.id,
						action_id: `act${item.id}`,
						action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
						accessory_name: item?.accessory?.accessory_name ||  item.accessory_name,
						name: item.user_name,
						action: item.action_name,
						description: item.comment,
						previous_value: item?.previous_data ? formatValues(item?.action_field_name, item?.previous_data) : '-',
						current_value: item?.current_data ? formatValues(item?.action_field_name, item?.current_data) : '-',
						field_name: item.action_field_name,
						user_role: item.user_role_name
					}
		})
	}

	const formatValues = (fieldName, value) => {
		if(!value) return '-'

		if(fieldName === 'Note') {
		  return stripHtml(value)
		} 
		  return value
	  }

	function getLogs(){
		setLoadingActivityLog(true)
			axios.get('/accessory/activities')
			.then(({data})=>{
				setLoadingActivityLog(false)
				setLogs(formatLogs(data.data.activities));
			})
			.catch(()=>{
				console.log('cannot fetch logs');
			})
	}


	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	useEffect(()=>{
    setTabValue(tab_value)
  }, [tab_value])


  	const handleAddReport = () => {
		dispatch(openReportViewerDialog())
	}

	return (
		<>
			{(tabValue == 0) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'Accessory'} logs={logs} displayTableName = {'Accessories'}
				 onClick={handleChildClick} onClickAddButton={handleAddAccessory}
				 addPermission = {props.createAccessoryPermission}
				 onClickAddReportButton={handleAddReport}
			/>}
			{(tabValue == 1) && (<ActivityAgGridListing module={'Accessory'} logs={logs} displayTableName = {'Accessories'}
			addPermission = {props.createAccessoryPermission}
			loadingActivityLog={loadingActivityLog}
			
			/>)}

			{(tabValue == 0) && <div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'12px'
				}}
			>
				<AgGridReact
					ref = { gridRef}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData = {props.endUsersAccounts}
					rowHeight = {30}
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }
					onSortChanged = { onSortChanged }

					groupHeaderHeight ={1}
					frameworkComponents={frameworkComponents}
					floatingFiltersHeight = {40}

					// get filter model
					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }
					
					pagination={true}
					defaultColDef={{
						resizable: true
					}}
				>
					<AgGridColumn 
						lockPosition={true}
						rowSelection={'single'}
						width={100}
						minWidth={100}
						maxWidth={100}
						headerName="Actions"
						pinned= 'right' 
						lockPinned={true}
						className="action_field"
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>
										{/* {props.updateCategoryPermission &&  */}
											{/* {props.viewAccessoryPermission  && <Tooltip id="setting" title="Setting" placement="top">
												<Icon 
													onClick={()=>{handleClickOpen(data)}}
													style={{
															color: '#1d2939',
															paddingBottom: '3px',
															paddingRight:'30px',
															fontSize:'15px',
															cursor:'pointer'
														}}
													>
														settings
													</Icon>
											</Tooltip>} */}
											{props.updateAccessoryPermission && <Tooltip id="edit" title="Edit" placement="top">
												{/* <Icon 
													onClick={() => handleEdit(data)} 
													style={{
															color: '#1d2939',
															paddingBottom: '3px',
															paddingRight:'30px',
															fontSize:'15px',
															cursor:'pointer'
														}}
													>
														edit
													</Icon> */}
												<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-pencil" onClick={() => handleEdit(data)} />
											</Tooltip>}										
										{/* } */}
										{/* {props.deleteategoryPermission && */}
											{props.deleteAccessoryPermission  &&  <Tooltip id="delete" title="Delete" placement="top">
												{/* <Icon 
													onClick={() => handleConfirm(data)} 
													style={{
														color: '#1d2939',
														paddingBottom: '3px',
														paddingRight:'30px',
														fontSize:'15px',
														cursor:'pointer'
													}}
												>
													delete
												</Icon> */}
												<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-trash" onClick={() => handleConfirm(data)} />
											</Tooltip>}
										{/* } */}
									</>
								)
							}else{
								return (<></>)
							}
						}}
					></AgGridColumn>
					{tableHeaders.map(header => {
						if(!skipHeader.includes(header)){
							if(header == 'manufacturer_name'){
								return (
									<AgGridColumn 
										key = {header}
										field = {header}
										editable = {props.updateAccessoryPermission}										
										onCellValueChanged = {onCellValueChanged}
										headerName = {generateHeaderLabel(header)}  
										sortable = {true}
										filter = "text"
										suppressSizeToFit = {true}
										flex = {1}
										minWidth={200}
										headerComponentFramework={CustomFilter}
										
										cellEditorParams= {{values:manufacturerMenu.map(manufacturer => manufacturer.label)}}
										cellEditor= 'agRichSelectCellEditor'
									>
									</AgGridColumn>
								)
							} else if(header == 'accessory_category_name'){
								return (
									<AgGridColumn 
										key = {header}
										field = {header}
										flex = {1}
										minWidth={200}
										editable = {props.updateAccessoryPermission}										
										onCellValueChanged = {onCellValueChanged}
										headerName = {generateHeaderLabel(header)}  
										sortable = {true}
										filter = "text"
										// flex = {1}
										suppressSizeToFit = {true}
										headerComponentFramework={CustomFilter}
										
										cellEditorParams= {{values:accessoryCategoryMenu.map(accessorycategory => accessorycategory.label)}}
										cellEditor= 'agRichSelectCellEditor'
										
									>
									</AgGridColumn>
								)
							}
							else if(header == 'source'){
								return (
									<AgGridColumn 
										key = {header}
										field = {header}
										// editable = {props.updateAccessoryPermission}										
										// onCellValueChanged = {onCellValueChanged}
										headerName = {generateHeaderLabel(header)}  
										sortable = {true}
										filter = "text"
										flex = {1}
										minWidth={200}
										// flex = {1}
										suppressSizeToFit = {true}
										headerComponentFramework={CustomFilter}
										
										// cellEditorParams= {{values:accessoryCategoryMenu.map(accessorycategory => accessorycategory.label)}}
										// cellEditor= 'agRichSelectCellEditor'
										
									>
									</AgGridColumn>
								)
							}
							return(
								<AgGridColumn 
									key = {header} 
									field = {header}
									editable = {props.updateAccessoryPermission}										
									onCellValueChanged = {onCellValueChanged}
									headerName = {generateHeaderLabel(header)}  
									sortable = {true} 
									filter = "text"
									flex = {1}
									minWidth={200}
									suppressSizeToFit = {true}
									headerComponentFramework={CustomFilter}
									
								></AgGridColumn>
							)
						}							
					})}
				</AgGridReact>

				{/* <GeneralModal
					open={accessory}
					title={'Delete Accessory'}
					text={<h4>Are you sure to delete this Accessory named as <b>{accessory?.accessory_name}</b>?</h4>}
					handleClose={handleClose}
					handleConfirm={handleDelete}
				/> */}

				{
					openDeleteDialog && 

					<DeleteConfirmModal 
						openAlerModal={openDeleteDialog}
						module={'Accessory'}
						handleCloseAlertModal={handleClose} 
						handleAlertModal={handleDelete}
					/>
				}

				<AccessoriesDialog fullWidth={fullWidth} 
					maxWidth={maxWidth} 
					open={open} 
					setOpen ={setOpen} 
					Close={Close} 
					accessoryId={accessoryId}
					// classes={classes} 
					TabButton ={TabButton} 
					tabState={tabState} 
					setTabState={setTabState} 
					TabContainer={TabContainer}
				/>
			</div>}
		</>			
	);
}

export default ListView