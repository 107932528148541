import { React, useState } from "react";
import { Button, Dialog, DialogTitle, TextField,  DialogContent, DialogActions, Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import axios from '@fuse/utils/axios';
import useToast from '@fuse/hooks/useToast';
import { useDispatch } from 'react-redux';
import { getAbbreviatedCapitalized } from '@fuse/utils/general';

export default function ContractActionComponent({ data, locationMenu, categoryMenu, fetchNotificationSettings, locationList, manufacturerList, isPhoneNumberVerified, setOpenPhoneVerificationModal}) {
    const dispatch = useDispatch()
    const toast = useToast(dispatch);
    const [openEditNotification, setOpenEditNotification] = useState(false);
    const [openDeleteNotification, setOpenDeleteNotification] = useState(false);



    const [ selectedLocation, setSelectedLocation ] = useState("default")
    const [ selectedManufacturer, setSelectedManufacturer ] = useState("default")
    const [ selectedModel, setSelectedModel ] = useState("default")
    const [ manufacturerModelList, setManufacturerModelList ] = useState([]);
    const [ selectedNotificationType, setSelectedNotificationType ] = useState("default")
    const [ selectedNumberOfDays, setSelectedNumberOfDays ] = useState(0);
    const [ selectedStatus, setSelectedStatus ] = useState("Enable")

    const [location, setLocation] = useState(null);
    const [category, setCategory] = useState(null);
    const [itemId, setItemId] = useState(null);
    const [status, setStatus] = useState('');
    const [notification, setNotification] = useState('');
    const [stats, setStats] = useState('Enabled');












  const handleClickOpenEditNotification = () => {
    setOpenEditNotification(true);
  };

  const handleCloseOpenEditNotification = () => {
    setOpenEditNotification(false);
    
  };

  const handleEditNotification = () => {

    if(isNaN(parseInt(selectedNumberOfDays))) {
      toast.error(`Please enter valid no of days.`);
      return;
    }
    // if( location == null || category == null || !status || !notification) return
    if((selectedLocation === "0" && selectedManufacturer === "0" &&  selectedModel === "0") || ( selectedNumberOfDays === "0")) {
      toast.error(`Please fill out all the required fields`);
      return;
    }
    // if(contractNotificationData[index].notificationType === "default") {
    //   toast.error(`Please Select the valid Notification Type`);
    //   return;
    // }
    const payload = {
      contract_notification_id: itemId,
      location_id: selectedLocation === "0" ? null : parseInt(selectedLocation),
      manufacturer_id: selectedManufacturer === "0" ? null : parseInt(selectedManufacturer),
      model_id: selectedModel === "0" ? null : parseInt(selectedModel),
      days: parseInt(selectedNumberOfDays),
      notification_type: selectedNotificationType,
      status: selectedStatus === "Enabled" ? "1" : "0"
    }

    axios.put(`/contracts/update-contract-notification`,payload).then(response => {
      fetchNotificationSettings()
      handleCloseOpenEditNotification()
      toast.success('Contract Notification has been updated.')
		}).catch(err => {
			console.log(err);
		})
  }

  const handleDeleteNotification = () => {
    if(!itemId) return
    axios.delete(`/contracts/delete-contract-notification/${itemId}`).then((res)=>{
        toast.success('Notification has been deleted.')
        fetchNotificationSettings()
        handleCloseOpenDeleteNotification()
    }).catch((err) => {
        toast.error('Unable to delete notification!')
    })
  }

  const handleClickOpenDeleteNotification = () => {
    setOpenDeleteNotification(true);
  };

  const handleCloseOpenDeleteNotification =(_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setSelectedLocation("default")
    setSelectedManufacturer("default")
    setSelectedModel("default")
    setManufacturerModelList([])
    setSelectedNotificationType("default")
    setSelectedNumberOfDays(0)
    setSelectedStatus("Enabled")
    setOpenDeleteNotification(false);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleNotificationChange = (event) => {
    setNotification(event.target.value);
  };

  const handleStatsChange = (event) => {
    setStats(event.target.value);
  };

  const handleNumberOfDaysInputChangeOnEditForm = (event) => {
    setSelectedNumberOfDays(event.target.value)
  }

  const handleModelChangeOnEditForm = (event) => {
    setSelectedModel(event.target.value.toString())
  }

  const handleLocationChangeOnEditForm = (event) => {
    setSelectedLocation(event.target.value.toString())
  }

  const handleManufacturerChangeOnEditForm = (event) => {
    if(event.target.value.toString() !== "0") {
      axios.get(`/models/get-model/${event.target.value}`).then(response => {
        const { modelData } = response.data.data
        setManufacturerModelList(modelData)
      }).catch(err => {
        console.log('29=>',err)
      })
    }
    setSelectedModel("0")
    setSelectedManufacturer(event.target.value.toString())
  }

  const handleNotificationTypeChangeOnEditForm = (event) => {
    // console.log(event.target.value)
    if((event.target.value === 'Both' || event.target.value === 'SMS') && !isPhoneNumberVerified){
      setOpenPhoneVerificationModal(true)
      return
    }
    setSelectedNotificationType(event.target.value)
  }

  const handleStatsChangeOnEditForm = (event) => {
    setSelectedStatus(event.target.value)
  }

  const handleEditRow = () => {
    if(!Boolean(data)) return
    axios.get(`/models/get-model/${data.manufacturer_id}`).then(response => {
      const { modelData } = response.data.data
      setManufacturerModelList(modelData)
      setSelectedNumberOfDays(data.days)
      setSelectedManufacturer(data.manufacturer_id)
      setSelectedModel(data.model_id)
      setSelectedLocation(data.location_id)
      setSelectedNotificationType(data.get_notification_via)
      
      setSelectedStatus(data.status_name)
      setItemId(data.id)
    }).catch(err => {
      console.log('29=>',err)
      toast.error("Unable to fetch model list")
    })
  }

  const setItemIdForDelete = () => {
    if(!Boolean(data)) return
    setItemId(data.id)
  }
  return (
    <>
      <Button className="transparent_icon_btn" onClick={handleClickOpenEditNotification}>
        <i 
          style={{fontSize: '20px', fontWeight: '500', color: '#1D2939'}}
          onClick={handleEditRow}
          className="ti ti-pencil mr-12"
        />
      </Button>
      <Button className="transparent_icon_btn" onClick={handleClickOpenDeleteNotification}>
        <i
          style={{fontSize: '20px', fontWeight: '500', color: '#F04438'}}
          onClick={setItemIdForDelete}
          className="ti ti-trash"
        />
      </Button>

      {/* edit notification modal */}
      <Dialog
        className="cstm_dialog_modal medium"
        open={openEditNotification}
        // onClose={handleCloseOpenEditNotification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flexbox_holder items-center w-full'>
            <h2 className="main_heading">Edit Notification</h2>
            <Button className='transparent_icon_btn' onClick={handleCloseOpenEditNotification}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='flexbox_holder items-center w-full'>
            <div style={{background: '#F9FAFB', borderRadius: '12px', marginBottom: '16px', padding: '16px 20px'}} className='flexbox_holder justify-start items-center w-full'>            
              <Box className='each_filter_holder pr-10'>
                <InputLabel className='label_holder'>Notify me</InputLabel>
                <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                  <TextField 
                    style={{maxWidth: '100px'}}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    label='' 
                    value={selectedNumberOfDays}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(event) => handleNumberOfDaysInputChangeOnEditForm(event)}
                    placeholder='Days'
                    fullWidth 
                  />
                </FormControl>
                {/* <FormControl fullWidth className='cstm_select_formcontrol'>
                  <Select
                    style={{maxWidth: '160px'}}
                    labelId="location-label"
                    id="location"
                    value={location}
                    label=""
                    onChange={handleLocationChange}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    {locationMenu && locationMenu.map( loc => <MenuItem value={loc.id}>{loc.label}</MenuItem>)}
                  </Select>
                </FormControl> */}
              </Box>

              <Box className='each_filter_holder pr-10'>
                <InputLabel className='label_holder'>days before a contract for</InputLabel>
                
                <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                  <Select
                    style={{minWidth: '240px', maxWidth: '240px'}}
                    labelId="category-label"
                    id="category"
                    value={selectedManufacturer}
                    label=""
                    onChange={handleManufacturerChangeOnEditForm}
                    MenuProps={{
                      sx: {
                        maxHeight: 230,
                        '& .MuiMenuItem-root': {
                          whiteSpace: 'normal',
                        },
                      },
                    }}
                  >
                    <MenuItem value="0">All</MenuItem>
                    {manufacturerList && manufacturerList.map( (manufacturer, index) => <MenuItem key={`${index}_manu`} value={manufacturer.id}>{manufacturer.manufacturer_name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Box>

              <Box className='each_filter_holder pr-10'>
                <InputLabel className='label_holder'>for model</InputLabel>
                <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                  <Select
                    style={{minWidth: '190px', maxWidth: '190px'}}
                    labelId="status-label"
                    id="status"
                    value={selectedModel}
                    label=""
                    onChange={handleModelChangeOnEditForm}
                    MenuProps={{
                      sx: {
                        maxHeight: 230,
                        '& .MuiMenuItem-root': {
                          whiteSpace: 'normal',
                        },
                      },
                    }}
                  >
                    <MenuItem value="0">All</MenuItem>
                    {
                      manufacturerModelList && manufacturerModelList.map( (manufacturerModel, manufacturerModelIndex) => {
                        return (
                          <MenuItem key={`${manufacturerModelIndex}_model`} value={manufacturerModel.id}>{manufacturerModel.model_no} </MenuItem>
                        )
                      })
                    }                 
                  </Select>
                </FormControl>
              </Box>

              <Box className='each_filter_holder pr-10'>
                <InputLabel className='label_holder'>at</InputLabel>
                <FormControl fullWidth className='cstm_select_formcontrol min_width_auto'>
                    <Select
                      style={{minWidth: '330px', maxWidth: '330px'}}
                      labelId="location-label"
                      id="location"
                      value={selectedLocation}
                      label=""
                      onChange={handleLocationChangeOnEditForm}
                      MenuProps={{
                        sx: {
                          maxHeight: 230,
                          '& .MuiMenuItem-root': {
                            whiteSpace: 'normal',
                          },
                        },
                      }}
                    >
                      <MenuItem value="0">All</MenuItem>
                      {locationList && locationList.map( (loc, locIndex) => <MenuItem index={`${locIndex}_location`} value={loc.id}>{loc.location_name}</MenuItem>)}
                    </Select>
                </FormControl>
                
              </Box>              

              <Box className='each_filter_holder justify-end'>
                <InputLabel className='label_holder'>Send my notification via</InputLabel>
                <FormControl fullWidth style={{maxWidth: '160px'}} className='cstm_select_formcontrol min_width_auto'>
                  <Select
                    style={{maxWidth: '160px'}}
                    labelId="notification-label"
                    id="notification"
                    value={selectedNotificationType}
                    label=""
                    onChange={handleNotificationTypeChangeOnEditForm}
                    // onChange={(event) => handleNotificationChange(event, index)}
                    MenuProps={{
                      sx: {
                        maxHeight: 230,
                        '& .MuiMenuItem-root': {
                          whiteSpace: 'normal',
                        },
                      },
                    }}
                  >
                    <MenuItem value="Both">Both</MenuItem>
                    <MenuItem value="SMS">SMS</MenuItem>
                    <MenuItem value="Email">Email</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            <Box className='each_filter_holder w-full'>
              <InputLabel className='label_holder'>Keep the notification</InputLabel>
              <FormControl fullWidth style={{maxWidth: '160px'}} className='cstm_select_formcontrol'>
                <Select
                  style={{maxWidth: '105px'}}
                  className="rounded_select_holder"
                  labelId="stats-label"
                  id="stats"
                  value={selectedStatus}
                  label=""
                  onChange={handleStatsChangeOnEditForm}
                  renderValue={(selected) => (
                    <div className="flex items-center">
                      <span className={`dot_status ${selected.toLowerCase()}`} />
                      <span className="pl-4">{selected}</span>
                    </div>
                  )}
                  MenuProps={{
                    sx: {
                      maxHeight: 230,
                      '& .MuiMenuItem-root': {
                        whiteSpace: 'normal',
                      },
                    },
                  }}
                >
                  <MenuItem value="Enabled">
                    <div className="flex items-center">
                      <span className="dot_status enabled" />
                      <span className="pl-4">Enabled</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="Disabled">
                    <div className="flex items-center">
                      <span className="dot_status disabled" />
                      <span className="pl-4">Disabled</span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={handleCloseOpenEditNotification} className="button secondary_btn">Cancel</Button>
          <Button onClick={handleEditNotification} className="button primary_btn">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* delete notification modal */}
      <Dialog
        className="cstm_dialog_modal small text-center"
        open={openDeleteNotification}
        onClose={handleCloseOpenDeleteNotification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flex justify-end items-center w-full'>
            <Button className='transparent_icon_btn' onClick={handleCloseOpenDeleteNotification}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="warning_content_holder">
            <div className="exclamation_icon_holder red">
              <i className="ti ti-exclamation-mark" />
            </div>
            <h2 className="heading">Are you sure to delete the selected notifications</h2>
            <p className="sub_content">By deleting notifications you will lose all of your notification data and won't be able to restore them.</p>
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={handleCloseOpenDeleteNotification} className="secondary_btn">No, go back!</Button>
          <Button onClick={handleDeleteNotification} className="primary_btn">
            Yes, I'm sure!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}