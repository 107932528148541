import Icon from '@material-ui/core/Icon';
import { TextField, InputAdornment } from '@material-ui/core';
import React, {forwardRef,useImperativeHandle,useRef,useState} from 'react';

export default forwardRef((props, ref) => {
    const [currentValue, setCurrentValue] = useState(null);
    const inputRef = useRef(null);
  
    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          // When the filter is empty we will receive a null value here
          if (!parentModel) {
            inputRef.current.value = '';
            setCurrentValue(null);
          } else {
            inputRef.current.value = parentModel.filter + '';
            setCurrentValue(parentModel.filter);
          }
        },
      };
    });
  
    const onInputBoxChanged = (input) => {
      if (input.target.value === '') {
        // Remove the filter
        props.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged(null, null);
        });
        return;
      }
  
      setCurrentValue(input.target.value);
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged('equals', input.target.value);
      });
    };
  
    return (
      <>
        <div style = {{ marginTop:'8px'}}>
            <TextField 
                id="floating-filter-custom-search" 
                label="search" 
                variant="outlined" 
                size="small"
                ref={inputRef} 
                onInput={onInputBoxChanged}
                InputProps={{
                    endAdornment:(
                        <InputAdornment position="end">
                            <Icon>search</Icon>
                        </InputAdornment>
                    )
                }}
            />

        </div>

      </>
    );
  });