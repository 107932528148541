function customSort(date1, date2) {
  const parsedDate1 = new Date(date1);
  const parsedDate2 = new Date(date2);

  if (parsedDate1 < parsedDate2) {
      return -1;
  } else if (parsedDate1 > parsedDate2) {
      return 1;
  } else {
      return 0;
  }
}
export default customSort