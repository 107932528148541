import React from "react";


export default function Subscription() {

  return (
    <>
      <h1>Subscription</h1>
    </>
  );
}
