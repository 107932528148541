import Page from './index';
import { authRoles } from 'app/auth';

export default {
settings: {
		layout: {
			config: {
				footer:{
					display:false
				}
			}
		}
	},
	// auth: authRoles.user,
	routes: [
		{
			path: '/locations',
			exact: true,
			component: Page.Layout
		},
		{
			path: '/locations/:id',
			exact: true,
			component: Page.Details
		},
	]
};