import Page from './index';

export default {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/users',
			exact: true,
			component: Page.Listing
		},
		{
			path: '/user/:id',
			exact: true,
			component: Page.UserDetails
		},
	]
};
