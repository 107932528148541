import React, {useEffect,useState} from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { withRouter } from 'react-router-dom';
import RenderHtml from '@fuse/components/RenderHtml';
import { formatPrice } from '@fuse/utils/currencyFormat';

const useStyles = makeStyles({
    row:{
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom:"25px",
        display: 'flex'
    },
    column:{
        // border: '2px solid black',
        width: '100%',
        textAlign: 'left'
    }
});

const Accordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
  	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
  	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);

function InformationTab({assetDetails}) {
	const [expanded, setExpanded] = React.useState('panel1');
	const classes = useStyles();
	const [informationData,setInformationData] = useState({})

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	function truncateString(notes){
		let str
		if(notes === undefined || notes === null) return
		if(notes != ''){
			if(notes.length > 20){
				str = `${notes.slice(0,21)}...`
			}else{
				str = notes
			}
		}else{
			str = 'N/A'
		}
		return str
	}

	useEffect(()=>{
		if(!assetDetails) return
		axios.get(`/assets/${assetDetails.id}/information?model_id=${assetDetails.model_id}`).then((res)=>{
			setInformationData(res.data.data.asset)
		}).catch((err)=>{
			console.log('error',err)
		})
	},[assetDetails])


	return (
		<>
			<div className='cstm_aggridtable_holder'>
				<table style={{maxWidth: '650px'}}>
					<thead>
						<tr>
							<th>Attributes</th>
							<th>Value</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Asset Category</td>
							<td>{informationData?.asset_model?.category?.category_name}</td>
						</tr>
						<tr>
							<td>Purchase Date</td>
							<td>{informationData.purchase_date ? dateReformatTo_mmm_dd_yyyy(informationData.purchase_date) : 'N/A'}</td>
						</tr>
						<tr>
							<td>Price</td>
							<td>{informationData.purchase_cost ? formatPrice(Number(informationData.purchase_cost)) : 'N/A'}</td>
						</tr>
						<tr>
							<td>End of Service Life</td>
							<td>{informationData?.asset_model?.eosl ? dateReformatTo_mmm_dd_yyyy(informationData.asset_model.eosl) : 'N/A'}</td>
						</tr>
						<tr>
							<td>End of Life</td>
							<td>{informationData?.asset_model?.eol ? dateReformatTo_mmm_dd_yyyy(informationData.asset_model.eol) : 'N/A'}</td>
						</tr>
						<tr>
							<td>Deployed Date</td>
							<td>{informationData.deployed_date ? dateReformatTo_mmm_dd_yyyy(informationData.deployed_date) : 'N/A'}</td>
						</tr>
						<tr>
							<td>Recovery Date</td>
							<td>{informationData.recovery_date ? dateReformatTo_mmm_dd_yyyy(informationData.recovery_date) : 'N/A'}</td>
						</tr>
						<tr>
							<td>Warranty Date</td>
							<td>{informationData.warranty_end_date ? dateReformatTo_mmm_dd_yyyy(informationData.warranty_end_date) : 'N/A'}</td>
						</tr>
						<tr>
							<td>Install Date</td>
							<td>{informationData.install_date ? dateReformatTo_mmm_dd_yyyy(informationData.install_date) : 'N/A'}</td>
						</tr>
						<tr>
							<td>Order Number</td>
							<td>{informationData.order_number ?? 'N/A'}</td>
						</tr>
						<tr>
							<td>Model Attributes</td>
							<td>{informationData?.asset_specs?.length === 0 ? 'N/A' : informationData?.asset_specs?.length}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	)
}

export default withRouter(InformationTab)
