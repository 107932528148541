import _ from '@lodash';
import TablePagination from '@fuse/components/general/TablePagination';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListView from './listView';
import GridView from './gridView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function EndUsersAccountsTable(props) {
  const classes = useStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(props.endUsersAccounts.length);
	const [loading, setLoading] = useState(false);

	let endUsersAccountsMod = props.endUsersAccounts
		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(rowsPerPage) {
		setPage(0);
		setRowsPerPage(rowsPerPage);
	}

	useEffect(()=>{
		setRowsPerPage(props.endUsersAccounts.length)
		setPage(0)
	},[props.endUsersAccounts.length])

	if(loading){
		return (<FuseLoading/>)
	}

	if (!props.viewContractPermission) {		
		return (
			<div>
				<Card>
					<CardContent>You don't have permission to view any Contracts at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		)
	}

	// if(endUsersAccountsMod.length <= 0){
	// 	return (
	// 		<motion.div
	// 			initial={{ opacity: 0 }}
	// 			animate={{ opacity: 1, transition: { delay: 0.1 } }}
	// 			className="flex flex-1 items-center justify-center h-full"
	// 		>
	// 			<div style={{height: "200px"}}>
	// 				<Typography variant="body2" component="p" color="textSecondary">
	// 					No data found
	// 				</Typography>
	// 			</div>
	// 		</motion.div>
	// 	)
	// }

	return (
		<div className="w-full flex flex-col">
			<SwitchView 
				selectedView={props.selectedView} 
				endUsersAccounts={endUsersAccountsMod} 
				gotoDetailsPage={props.gotoDetailsPage}
				refresh={props.refresh}
				customFilterData = { props?.customFilterData }
				viewContractPermission = {props.viewContractPermission}
				createContractPermission = {props.createContractPermission}
				updateContractPermission = {props.updateContractPermission}
				deleteContractPermission = {props.deleteContractPermission}
			/>
			{/* <div className="p-20">
				<TablePagination
					className="flex-shrink-0 border-t-1 mt-10"
					component="div"
					count={props.endUsersAccounts.length}
					rowsPerPage={rowsPerPage !== props.endUsersAccounts.length ? rowsPerPage : 'all'}
					page={page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div> */}
		</div>
	);
}

function SwitchView(props) {
	if(props.selectedView == 0){
		return(
			<ListView 
				endUsersAccounts={props.endUsersAccounts} 
				gotoDetailsPage={props.gotoDetailsPage}
				refresh={props.refresh}
				customFilterData = { props?.customFilterData }
				viewContractPermission = {props.viewContractPermission}
				createContractPermission = {props.createContractPermission}
				updateContractPermission = {props.updateContractPermission}
				deleteContractPermission = {props.deleteContractPermission}
			/>
		)
	}else{
		return(
			<GridView 
				endUsersAccounts={props.endUsersAccounts}
				gotoDetailsPage={props.gotoDetailsPage}
			/>
		)		
	}
}

export default withRouter(EndUsersAccountsTable)
