import Page from './index';

export default {
settings: {
		layout: {
			config: {
				footer: false
			}
		}
	},
	// auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/price-catalogue',
			exact: true,
			component: Page.Layout
		}
	]
};