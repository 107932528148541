import Page from './index';
import { authRoles } from 'app/auth';

export default {
settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: false,
	routes: [
		{
			path: '/quotes/validate-line-items/temp/:h/:p/:s',
			exact: true,
			component: Page.ValidateLineItem
		},
		{
			path: '/not-found',
			exact: true,
			component: Page.NotFound404
		}
	]
};