import React, { useEffect, useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete';
// import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'

import Checkbox from '@mui/material/Checkbox';




const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


const AddDepartmentFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const schema = yup.object({
        department_name: yup.string().trim().required('Department is required!'),
    })

    const defaultValues = {
        department_name: ''
    }

    const { 
        control, 
        formState:{errors: departmentFormErrors}, 
        handleSubmit: departmentFormSubmit, 
        reset: departmentFormReset,
        trigger,
        getValues
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const onSubmit = async() => {
        await trigger();
        if(Object.keys(departmentFormErrors).length !== 0) { 
            return;
        }
        // if(getValues().department_name.trim() === '') {
        //     setError('department_name', {
        //         message:  "Department is required"
        //     })
        //     return;
        // }   else {
        //     clearErrors("department_name")
        const payload = {
            entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
            department_name: getValues().department_name,
        }
        axios.post('/department/add', payload).then(response => {
            if(response.status == 201){
                toast.error(response.data.message)
            } else {
                departmentFormReset(defaultValues)
                toast.success('Department added successfully.')
                newDataAddedEvent(response.data.data.new_data)
            }
        }).catch(err => {
            console.log('234: ',err);
            toast.error(err.response.data.data)
        })
        // } 
        
    }

    return (
        <Dialog
            className="cstm_dialog_modal small text-center"
            open={openAddStatusModalStateData}
            // onClose={handleOpenCloseAddModalEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div className='heading_holder flexbox_holder items-center w-full'>
                    <h2 className="main_heading">Add Department</h2>
                    <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                        <i className="icon ti ti-x" />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-left">
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Department Name
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="department_name"
                            className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='Department Name'
                                        required
                                        // fullWidth
                                        error={!!departmentFormErrors.department_name}
                                        helperText={departmentFormErrors?.department_name?.message}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                    />
                                </>
                            )}
                        />
                    </div>
                </div>

            </DialogContent>
            <DialogActions className="action_footer_holder">
                <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                <Button onClick={departmentFormSubmit(onSubmit)} className="primary_btn">
                    Save and Select
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default AddDepartmentFromQuickAction;