import { colDefs as AssetColDefs } from "./asset";
import { fetchContracts, colDefs as ContractColDefs } from "./contract"
import { fetchSubscription, colDefs as SubscriptionColDefs } from "./subscription"
import { fetchServiceRequests, colDefs as ServiceRequestsColDefs } from "./serviceRequest"

export function getColDefs({ moduleName, reportName}){
  let colDefs = []

  if(moduleName === "asset"){
    colDefs = [...AssetColDefs];
    colDefs = colDefs.map((i)=>({...i,rowGroup: false}))
    switch (reportName) {
      case "asset_by_location":
        colDefs[12] = {
          ...colDefs[12],
          rowGroup: true,
          // sort: 'desc'
        }
      break;
      case "asset_by_category":
        colDefs[10] = {
          ...colDefs[10],
          rowGroup: true,
        }
      break;
      case "asset_by_manufacturer":
        colDefs[11] = {
          ...colDefs[11],
          rowGroup: true,
        }
      break;
      case "asset_by_department":
        colDefs[4] = {
          ...colDefs[4],
          rowGroup: true,
        }
      break;
      default:
        colDefs = [...AssetColDefs]
        break;
    }
  }
  else if(moduleName === "contract"){
    colDefs = [...ContractColDefs];
    colDefs = colDefs.map((i)=>({...i,rowGroup: false, sort: ''}))
    switch (reportName) {
      case "contract_by_vendor":
        colDefs[8] = {
          ...colDefs[8],
          rowGroup: true
        }        
      break;
      case "contract_by_expiry_date":
        colDefs[3] = {
          ...colDefs[3],
          rowGroup: true
        }
      break;
      case "contract_by_value":
        colDefs[7] = {
          ...colDefs[7],
          rowGroup: true
        }
      break;
      case "contract_by_duration":
        colDefs[4] = {
          ...colDefs[4],
          rowGroup: true
        }
      break;
      default:
        break;
    }
  }
  else if(moduleName === "subscription"){
    colDefs = [...SubscriptionColDefs]
    colDefs = colDefs.map((i)=>({...i,rowGroup: false, sort: ''}))
    switch (reportName) {
      case 'subscription_by_type':
        colDefs[4] = {
          ...colDefs[4],
          rowGroup: true,
          // sort: 'desc'
        }
        break;
      case 'subscription_by_cost':
        colDefs[9] = {
          ...colDefs[9],
          rowGroup: true,
          // sort: 'desc'
        }
        break
      case 'subscription_by_duration':
        colDefs[8] = {
          ...colDefs[8],
          rowGroup: true,
          // sort: 'desc'
        }
        break
      case 'subscription_by_vendor':
        colDefs[1] = {
          ...colDefs[1],
          rowGroup: true,
          // sort: 'desc'
        }
        break
      default:
        break;
    }
  }
  else if(moduleName === "service_requests" ){
    colDefs = [...ServiceRequestsColDefs]
    colDefs = colDefs.map((i)=>({...i,rowGroup: false, sort: ''}))
    switch (reportName) {
      case "service_requests_by_type":
        colDefs[4] = {
          ...colDefs[4],
          rowGroup: true
        }        
      break;
      case "service_requests_by_priority":
        colDefs[3] = {
          ...colDefs[3],
          rowGroup: true
        }
      break;
      case "service_requests_by_status":
        colDefs[7] = {
          ...colDefs[7],
          rowGroup: true
        }
      break;
      case "service_requests_by_department":
        colDefs[10] = {
          ...colDefs[10],
          rowGroup: true
        }
      break;
      default:
        break;
    }
  }
  return colDefs
}

export function getRowData(moduleName){
  if(moduleName === "asset") return []
  if(moduleName === "contract") return fetchContracts()
  if(moduleName === "subscription") return fetchSubscription()
  if(moduleName === "service_requests") return fetchServiceRequests()
}