import React, { useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
// import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import ColorPicker from '@fuse/components/ColorPicker'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))





const AddStatusFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [color, setColor] = useState({name:'black',code:"#000000"});

    const defaultValues = {
        status_name: '',
        status_description: ''
    }

    const schema = yup.object({
        status_name: yup.string().trim().required('Status is required!'),
        // status_description: yup.string().trim().required('Description is required!')
    })

    const { 
        control, 
        formState:{errors: statusFormErrors}, 
        handleSubmit: statusFormSubmit, 
        trigger,
        reset: statusFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });



    function statusFormErrorData(error){
        console.log('statusFormErrorData: ',error);
    }


    const onSubmit = async (data) => {
        await trigger();
        if(Object.keys(statusFormErrors).length !== 0) { 
            return;
        }
        const payload = {
            entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
            status_name: data.status_name,
            type:'hardware',
            status_description: data.status_description,
            status_color: color
        }
        axios.post('/asset-status/add', payload).then(response => {
            if(response.status == 201){
                toast.error(response.data.message)
            } else {
                
                statusFormReset(defaultValues)
                setColor({name:'black',code:"#000000"})               
                toast.success('Asset Status added successfully.')
                newDataAddedEvent(response.data.data.new_hardware_asset_status)
            }
        }).catch(err => {
            console.log('234: ',err);
            // toast.error(err.response.data.data)
        })
    }


    return (
        <Dialog
            className="cstm_dialog_modal small text-center"
            open={openAddStatusModalStateData}
            // onClose={handleOpenCloseAddModalEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div className='heading_holder flexbox_holder items-center w-full'>
                    <h2 className="main_heading">Add Status</h2>
                    <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                        <i className="icon ti ti-x" />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-left">
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Status Name
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="status_name"
                            // className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='Status Name'
                                        required
                                        fullWidth
                                        error={!!statusFormErrors.status_name}
                                        helperText={statusFormErrors?.status_name?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Status Description
                            {/* <span className='required_span'>*</span> */}
                        </Typography>
                        <Controller
                            name='status_description' 
                            control={control}
                            render={( { field} ) => (
                                <>
                                    <TextField 
                                        {...field}
                                        style={{background: 'white',marginBottom: '10px'}}
                                        variant='outlined'
                                        // label='Status Description' 
                                        autoFocus
                                        fullWidth
                                        InputProps={{
                                            inputComponent: TextareaAutosize,
                                        }}
                                        // required
                                        // error={!!statusFormErrors.status_description}
                                        // helperText={statusFormErrors?.status_description?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                            )}
						/>
                    </div>
                    <div>
                        <Typography className='status_text'>
                            Choose Color
                            {/* <span className='required_span'>*</span> */}
                        </Typography>
                        <ColorPicker color={color}
                            setColor={setColor}
                        />
                    </div> 
                </div>
            </DialogContent>
            <DialogActions className="action_footer_holder">
                <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                <Button onClick={statusFormSubmit(onSubmit)} className="primary_btn">
                    Save and Select
                </Button>
            </DialogActions>
        </Dialog>
    );


}

export default  AddStatusFromQuickAction;