import React from 'react'
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import { Box, Card, CardContent } from '@mui/material';

function IconComponent({ icon }) {
  switch (icon) {
    case 'computer':
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2223_377)">
            <path d="M14.5453 3H2.90891C2.50725 3 2.18164 3.32561 2.18164 3.72727V11C2.18164 11.4017 2.50725 11.7273 2.90891 11.7273H14.5453C14.9469 11.7273 15.2726 11.4017 15.2726 11V3.72727C15.2726 3.32561 14.9469 3 14.5453 3Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.09082 14.6362H12.3635" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.54541 11.7273V14.6364" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.9092 11.7273V14.6364" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_2223_377">
              <rect width="17.4545" height="17.4545" fill="white" transform="translate(0 0.0908203)" />
            </clipPath>
          </defs>
        </svg>
      )
    case 'handhelds':
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2223_1538)">
            <path d="M10.382 3.72729H7.47288C7.07122 3.72729 6.74561 4.05291 6.74561 4.45457V7.36366C6.74561 7.76532 7.07122 8.09093 7.47288 8.09093H10.382C10.7836 8.09093 11.1092 7.76532 11.1092 7.36366V4.45457C11.1092 4.05291 10.7836 3.72729 10.382 3.72729Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.10938 5.18164H3.83665" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.10938 8.09082H3.83665" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.0186 5.18164H14.7458" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.0186 8.09082H14.7458" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.10938 11H14.7457" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.10938 13.9089H14.7457" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_2223_1538">
              <rect width="17.4545" height="17.4545" fill="white" transform="translate(0.200195 0.0908203)" />
            </clipPath>
          </defs>
        </svg>
      )
    default:
      break;
  }
}

function InfoCard({ title, value, icon, backgroundColor, iconBgColor, handleClick }) {
  return (
    <Card style={{ borderRadius: '16px' }} className='screenShot_Tabcard mb-0'>
      <CardContent style={{ padding: '12px' }}>
        <div style={{ backgroundColor: backgroundColor }} className='catalog_card'>
          <span className='pattern_img'>
            <svg width="100" height="103" viewBox="0 0 100 103" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.04">
                <circle cx="76.7002" cy="26.5" r="76.0307" stroke="black" strokeWidth="0.93865" />
                <circle cx="76.7004" cy="26.4999" r="63.8282" stroke="black" strokeWidth="0.93865" />
                <circle cx="76.7002" cy="26.5" r="46.9325" stroke="black" strokeWidth="0.93865" />
                <circle cx="76.7005" cy="26.5" r="30.0368" stroke="black" strokeWidth="0.93865" />
              </g>
            </svg>
          </span>
          <Box sx={{ alignContent: 'center', display: 'flex' }}>
            <span style={{ backgroundColor: iconBgColor }} className='catalog_cardIcon'>
              <IconComponent icon={icon} />
            </span>
          </Box>
          <div className='catalog_cardDetails'>
            <p>{ value }</p>
            {
                title.length > 20 ?
                (
                    <abbr 
                    style={{textDecoration: 'none', fontSize: '16px', fontWeight: '600'}}
                    title={title}
                    >{title.substring(0, 15).trim()}...</abbr>
                ):
                (<h5>{title}</h5>)
            }
          </div>
        </div>
        <div style={{paddingTop: '12px'}} className="flex flex-1 w-full items-center justify-between px-4">
          <div>
            <p className='assets_viewText'>View Assets</p>
          </div>
          <div style={{ cursor: 'pointer'}} onClick={ handleClick }>
            <span>
              <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2173_70)">
                  <path d="M4.58203 9.27246H14.7638" stroke="#1D2939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M11.855 12.1816L14.7641 9.27246" stroke="black" strokeWidth="1.45455" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M11.855 6.36353L14.7641 9.27262" stroke="black" strokeWidth="1.45455" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_2173_70">
                    <rect width="17.4545" height="17.4545" fill="white" transform="translate(0.945801 0.54541)" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default InfoCard