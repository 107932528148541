import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles, Modal, TextField } from '@material-ui/core';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function AddModelAttributeDialog(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const schema = yup.object({
        attribute_name: yup.string().required('Attribute name is required!'),
    })

    const defaultValues = {
        attribute_name: ''
    }

    const { 
        control, 
        formState:{errors: attributeFromErrors}, 
        handleSubmit: attributeFormSubmit, 
        reset: attributeFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    function manageAttributeFormData(formData){
        // console.log('manageAttributeFormData: ',formData);
        let payload = {
			attribute_names: [{attribute_name: formData.attribute_name}],
		}

        axios.post('/model-custom-attributes/add', payload).then((res)=>{
            if(res.status === 201) {
                toast.error(res.data.message)
            }
            else{
                toast.success(res.data.message)
                props.refreshList()
                attributeFormReset(defaultValues)
                props.handleCloseDialog(res.data.data.new_attribute)
            }
		}).catch((err)=>{
			console.log('error',err.response);
			toast.error(err.response.data.data)
		})

    }

    function attributeFormErrorData(error){
        console.log('attributeFormErrorData: ',error);
    }


    return (
        <Modal
            open={props.open}
            className={classes.modal}
        >
            <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Add Model Attribute</h1>
                    <IconButton onClick={()=>{
                        attributeFormReset(defaultValues)
                        props.handleCloseDialog()
                    }}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <form onSubmit={attributeFormSubmit(manageAttributeFormData, attributeFormErrorData)}>
                    <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>
                        
                        <Controller
                            name="attribute_name"
                            className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Attribute'
                                        required
                                        fullWidth
                                        // error={!!attributeFromErrors.attribute_name}
                                        // helperText={attributeFromErrors?.attribute_name?.message}
                                        FormHelperTextProps={{
                                            style: {marginLeft : 0}
                                        }}
                                        style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
                        <Button
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                            type='submit'
                        >Add</Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={()=>{
                                attributeFormReset(defaultValues)
                                props.handleCloseDialog()
                            }}
                        >Cancel</Button>
                    </div>
                </form>
            </div>

        </Modal>
    )
}

export default AddModelAttributeDialog
