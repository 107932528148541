import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import {TextField,MenuItem, FormControlLabel} from '@material-ui/core';
import { useState, useEffect, Fragment } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	const [currentFilter, setCurrentFilter] = useState('');
	const [currentFilterLabel, setCurrentFilterLabel] = useState('');
	const dispatch = useDispatch();
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	function handleSearchReset(){
		props.handleSearchReset();
		props.handleSearchSuggestions("", currentFilter)
		setSearchQuery("");
	}

	useEffect(() => {
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilter(props.searchFilters[0].field)
		}
	},[props.searchFilters])

	useEffect(() => {
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilterLabel(props.searchFilters.find((item) => item.field == currentFilter)?.label || "");
		}
	},[currentFilter]);

	useEffect(() => {
		props.onSearchChange(search, currentFilter)
	},[search, currentFilter])

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				{/* <svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M3 18H21V6H3V18ZM1 5C1 4.44772 1.44772 4 2 4H22C22.5523 4 23 4.44772 23 5V19C23 19.5523 22.5523 20 22 20H2C1.44772 20 1 19.5523 1 19V5ZM9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11C8.55228 11 9 10.5523 9 10ZM11 10C11 11.6569 9.65685 13 8 13C6.34315 13 5 11.6569 5 10C5 8.34315 6.34315 7 8 7C9.65685 7 11 8.34315 11 10ZM8.0018 16C7.03503 16 6.1614 16.3907 5.52693 17.0251L4.11272 15.6109C5.10693 14.6167 6.4833 14 8.0018 14C9.52031 14 10.8967 14.6167 11.8909 15.6109L10.4767 17.0251C9.84221 16.3907 8.96858 16 8.0018 16ZM16.2071 14.7071L20.2071 10.7071L18.7929 9.29289L15.5 12.5858L13.7071 10.7929L12.2929 12.2071L14.7929 14.7071L15.5 15.4142L16.2071 14.7071Z"></path></svg> */}
				<Icon style={{color:'black'}}>archive</Icon>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{color:'black'}}
				>
					Archived Contracts List
				</Typography>
			</div>

			<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center shadow ${classes.buttonSquare}`}
							style={{width:"240px"}}
						>
							<TextField
								placeholder="Search Archived Contracts"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
		</div>
	)
}

export default DynamicFieldsHeader;
