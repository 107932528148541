import _ from '@lodash';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListView from './listView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});
function AssetsDashboard(props) {
  const classes = useStyles();
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const endUsersAccountsMod = props.endUsersAccounts
	
	if(loading){
		return (<FuseLoading/>)
	}
	if (false) {
		return (
			<div>
				<Card>
					<CardContent>You don't have permission to view any Subscription Providers at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		)
	}
	return (
		<div className="w-full flex flex-col">
			<SwitchView 
            selectedView={props.selectedView} endUsersAccounts={endUsersAccountsMod}
			/>
			
		</div>
	);
}
function SwitchView({
	// endUsersAccounts,
	selectedView,
}) {
	if(true){
		return(
			<ListView 
			/>)
	}		
}
export default withRouter(AssetsDashboard)
