import useToast from '@fuse/hooks/useToast'
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog,
	DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Icon,
	IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Tooltip, Typography,
	Drawer,TextareaAutosize 
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import AddCategoryDialog from '@fuse/components/Modal/QuickAddCategory'
import ManufacturerDialog from '@fuse/components/Modal/QuickAddManufacturer'
import AddModelAttributeDialog from '@fuse/components/Modal/QuickAddModelAttribute'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
	display: 'flex',
	'& > * + *': {
		marginLeft: theme.spacing(2),
	},
	},
	subSection: {
		marginLeft:'20px',
		marginRight:'20px',
		marginBottom:'20px',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
	},
	endButtons: {
			borderRadius: '4px'
	},drawerWidth: {
		width: 600
	},
	textarea: {
		resize: "both",
		width: "100%"
	},
}))

function AddModelDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const { data:editFormState, open } = useSelector(({paymentTermApp}) => paymentTermApp.formViewDialog);

	//form validation
	const schema = yup.object({
		payment_term: yup.string().required('Payment Term name is required!'),
		description: yup.string()
	})

	const defaultValues = {
		payment_term: '',
		description: '',
		term_duration: ''
	}

	
	const onCancel = () => {
		reset(defaultValues)
		closeDialog()
	}
	
	const closeDialog = () => {
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(()=>{
		if(editFormState){
			setValue('payment_term',editFormState.payment_term_name)
			setValue('description',editFormState.description)
			setValue('term_duration',editFormState.term_duration)
		}
	},[editFormState])

  const { errors } = formState

	const manageFormData = (formData) => {
		let payload = {
			entity_external_platform_id: generateRandomNumberString(),
			paymentTerm: formData.payment_term,
			description: formData.description,
			term_duration: formData.term_duration,
		}

		if(editFormState){

			payload = {
				id: editFormState.id,
				paymentTerm: formData.payment_term,
				description: formData.description,
				term_duration: formData.term_duration,
			}

			axios.put('/payment-term/update', payload).then(res => {
				if(res.status === 201){
					toast.error(res.data.message)
				}else{
					reset(defaultValues)
					closeDialog()
					props.getPaymentTerms()
					toast.success('Payment Term updated successfully')
					dispatch(setQuickFilter(null))
				}
			}).catch(err => {
				console.log('212=>',err);
				toast.error('Something went wrong!')
			})
		}else{
			axios.post('/payment-term/add', payload).then(res => {
				if(res.status === 201){
					toast.error(res.data.message)
				}else{
					reset(defaultValues)
					closeDialog()
					props.getPaymentTerms()
					toast.success('Payment Term added successfully')
					dispatch(setQuickFilter(null))
				}

			}).catch(err => {
				console.log('212=>',err);
				toast.error('Something went wrong!')
			})
		}
		
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	//   useEffect(() => {
	//     setValue("allSpan", allSpan)
	//   }, [allSpan])

	return (
		<div>

			<Drawer anchor='right' open={open} classes={{ paper: classes.drawerWidth }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{ Boolean(editFormState) ? 'Update Payment Term' : 'Add Payment Term'}</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

				{/*----------------------Item Information------------------------- */}
					
					<div className={classes.subSection}>
						<div style={{ width: '100%' }}>
						<Typography style={{fontSize:'16px'}}>Required Information *</Typography>					
							<Divider style={{marginBottom: '10px'}}/>
						</div>

						<div >
							<div style={{ borderRadius:9, padding: '0', width:'100%', marginBottom: '10px'}}>
								<Controller 
									name='payment_term'
									control={control}
									render={({field}) => (
										<TextField
											{...field}
											variant='outlined'
											required
											label="Payment Term"
											fullWidth
											style={{ width: '100%', backgroundColor: '#fff' }}
											// error={!!errors.payment_term}
											// helperText={errors?.payment_term?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
										/>
									)}
								/>
							
							</div>
							<div style={{ borderRadius:9, padding: '0', width:'100%', marginBottom: '10px'}}>
								<Controller 
									name='term_duration'
									control={control}
									render={({field}) => (
										<TextField
											{...field}
											variant='outlined'
											required
											label="Term Duration(months)"
											fullWidth
											onKeyPress={(event) => {
												if (!/[0-9]+/.test(event.key)) {
													event.preventDefault();
												}
											}}
											style={{ width: '100%', backgroundColor: '#fff' }}
											// error={!!errors.payment_term}
											// helperText={errors?.payment_term?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
										/>
									)}
								/>
							
							</div>

							<div style={{ borderRadius:9, padding: '0', width:'100%'}}>
								<Controller 
									name='description'
									control={control}
									render={({field}) => (
										<TextField
											{...field}
											variant='outlined'
											label="Description"
											fullWidth
											multiline
											rows={8}
											inputProps={{ className: classes.textarea }}
											style={{ width: '100%', backgroundColor: '#fff' }}
											// error={!!errors.description}
											// helperText={errors?.description?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
										/>
									)}
								/>
							
							</div>
						</div>
					</div>
					<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
						<Button className={classes.endButtons} style={{marginRight: '10px'}} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFormState ? 'Update' : 'Add'}</Button>
						<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
					</div>						
				</form>        
			</Drawer>
		</div>
	)
}

export default AddModelDialog
