import { combineReducers } from '@reduxjs/toolkit';

import emailDialogReducer from './emailDialogReducer';
import reportViewDialogReducer from './reportViewDialogReducer';
import filterParamsReducer from './filterParamsReducer';

const reducer = combineReducers({
	emailDialogReducer,
	reportViewDialogReducer,
	filterParamsReducer
});

export default reducer;
