module.exports = [
    {
        id:1,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
    {
        id:2,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
    {
        id:3,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
    {
        id:4,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
    {
        id:5,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
    {
        id:6,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
    {
        id:7,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
    {
        id:8,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
    {
        id:9,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
    {
        id:10,
        contract_end_date: 'May 1, 2021',
        contract_no: '1705C0021',
        quote_number: 'Q-1705C0021',
        location_name: 'Fedex-Atlanta',
        agent_name: 'Mary J',
        service: 'DEPOT 5X8 S DAY',
        number_of_items: '2',
        quote_amount: '$2,004.80',
        action: 'no Action',
        status: 'no action',
        indicator: 'On-Time',
    },
]