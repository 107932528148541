import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button, AccordionActions } from '@material-ui/core';
import axiosgeneral from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	  },
	  root: {
		// minWidth: 275,
	  },
	  title: {
		fontSize: 14,
	  },
	  pos: {
		marginBottom: 12,
	  },
});

function ListView(props) {
    const classes = useStyles();
	//console.log(props.endUsersAccounts);
	return (
			
			// <FuseScrollbars className="flex-grow overflow-x-auto">
				<div className="ag-theme-alpine" style={{height: 400, width: "100%"}}>
                    <AgGridReact
                        rowData={props.endUsersAccounts}
						onRowClicked={e => {props.gotoDetailsPage(e.data.id)}}
						>
                        {Object.keys(props.endUsersAccounts[0]).map((header, index) => {
                            if(header != 'id'){
								return (<AgGridColumn 
										field={header}
										key={index} 
										headerName={header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}  
										sortable={ true } filter={ true }
										>
                                        </AgGridColumn>)
                            }
                        })}
                    </AgGridReact>
                </div>
			// </FuseScrollbars>
			
	);

}

export default ListView
