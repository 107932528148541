import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useRouter } from "@fuse/hooks";
import { Button } from "@mui/material";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AddAssetsInContract from "./AddAssetsInContract";

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnsContainer": {
      background: "#8f8f8f26",
    },
  },
});

function AssetsTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { allAssets } = props;
  const [ open, setOpen ] = useState(false)

  const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

	const gridRef = useRef()
  const onGridReady = params => {
    // Following line to make the currently visible columns fit the screen  
    params.api.sizeColumnsToFit();

    // Following line dymanic set height to row on content
    params.api.resetRowHeights();
  };
  
  function redirectToAssetPage(params) {
    router.push(`/assets-list/${params.data.id}`);
  }

  const columns = [
    {
      field: "serial_number",
      headerName: "Serial Number",
      width: 200,
      headerComponentFramework: CustomFilter,
      cellRendererFramework: (params) => {
        return (
          <a
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => redirectToAssetPage(params)}
          >
            {params.value}
          </a>
        );
      },
    },
    { field: "user_name", headerName: "User Name", headerComponentFramework: CustomFilter, minWidth: 150, width: 200 },
    { field: "model", headerName: "Model", headerComponentFramework: CustomFilter, minWidth: 150, width: 200 },
    { field: "category", headerName: "Category", headerComponentFramework: CustomFilter, minWidth: 150, width: 200 },
    { field: "location", headerName: "Location", headerComponentFramework: CustomFilter, minWidth: 150, width: 200, 
      cellRendererFramework: (params) => {
        return (<span style={{wordBreak: 'break-word'}}>{params.value}</span>)        
      }
    },
    { field: "asset_status", headerName: "Asset Status", headerComponentFramework: CustomFilter, minWidth: 150, width: 200 },
  ];

  if (loading) {
    return <FuseLoading />;
  }

  //handle Add Assets Drawer open
  function handleDrawerOpen () {
    setOpen(true)
  }

  //handle Add Assets Drawer close
  function handleDrawerClose () {
    setOpen(false)
  }
  function handleHideColumnDropDownDesign(){
    return { border: '1px solid #000000', borderRadius: '16px', padding: '4px 12px', backgroundColor: 'transparent', lineHeight: '24px', position: 'relative' }
  }
  return (
    <>
      <div className='flex flex-wrap justify-end mb-10'>
        <Button className='button primary_maroon_outlined_btn'
          onClick = {handleDrawerOpen}
        >
          <i className="icon ti ti-plus" />
          Add Assets
        </Button>
      </div>

      {allAssets.length === 0 && 
        <div className='no_data_holder'>
          <img src='assets/images/nodata.svg' alt='icon' />
          <p className='bold_txt'>It's all empty here!</p>
          <p className='normal_txt'>No data to show yet</p>
        </div>
      }
      {
        allAssets.length > 0 &&      
        <div 
          className="ag-theme-alpine" 
          style={{
          width: "100%",
          height: windowHeight+105,
          fontSize:'12px'
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={allAssets}
            animateRows
            onGridReady = { onGridReady }
            paginationPageSize={10}
            pagination
            defaultColDef={{
              resizable: true
            }}
          >
            {columns.map(header => {
              if (header.field == "asset_status") {
                return (
                  <AgGridColumn
                    field={header.field}
                    key={header.field}
                    suppressSizeToFit
                    filter="text"
                    sortable
                    minWidth={200}
                    // floatingFilter
                    headerName={header.headerName}
                    headerComponentFramework = {header.headerComponentFramework}								
                    cellRendererFramework={(event) => {
                      if (event.colDef.field === "asset_status") {
                        return (
                          // <span style={{ border:'1px solid #000000',borderRadius:'16px', padding:'4px 10px', backgroundColor:'transparent', lineHeight:'24px'}}>
                          <span style={handleHideColumnDropDownDesign()}>
                            <span
                              style={{
                                height: "10px",
                                width: "10px",
                                marginTop: "2px",
                                borderRadius: "50%",
                                display: "inline-block",
                                backgroundColor:
                                  event.data?.asset_status_color?.code
                              }}
                             />
                            <span style={{ marginLeft: "5px", fontSize:'12px', fontWeight:'500', color:'black', fontFamily:'"Inter", sans-serif', }}>
                              {console.log(event)}
                              {event.value}
                            </span>
                          </span>
                        );
                      } if (
                        event.colDef.headerName == "Group" &&
                        event.node.field == "asset_status"
                      ) {
                        return (
                          <>
                            <span>{event.value}</span>
                          </>
                        );
                      } 
                        return (
                          <>
                            <span>{event.value}</span>
                          </>
                        );
  
                    }}                  
                   />
                );
              } 
              return(
                <AgGridColumn 
                  key = {header.field} 
                  field = {header.field}
                  headerName = {header.headerName}  
                  width = { header.width }
                  minWidth = { header.minWidth }
                  maxWidth = { header.maxWidth }
                  sortable = {false}
                  flex = {1}
                  filter = "text"
                  wrapText
                  autoHeight = {header.field === 'location'}
                  cellRendererFramework = { header.cellRendererFramework}
                  headerComponentFramework = {header.headerComponentFramework}								
                />
              )
            })}
          </AgGridReact>
        </div>
      }
      {
        open && <AddAssetsInContract 
          open={open} 
          close={handleDrawerClose} 
          getContractDetails={props.getContractDetails}
          contractDetails={props.contractDetails}
        />
      }
    </>
  );
}

export default withRouter(AssetsTab);
