import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import FuseLoading from '@fuse/core/FuseLoading';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const drawerWidth = 400;
const headerHeight = 200;
const views = ['List View','Grid View'];
const defaultCounts = {end_users:0, locations:0};
const defaultSearches = {location:'',quote_indicator:'',quote_status:'',revenue:'',action:''}

const useStyles = makeStyles(theme => ({
	'@global': {
		'#fuse-main': {
			height: '100vh'
		}
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: '100%',
		position: 'relative',
		// flex: '1 1 auto',
		height: 'auto',
		backgroundColor: theme.palette.background.default
	},
	cardGen: {
		width: '100%',
	},
	topBg: {
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none',
		padding:"10px"
	},
	topBgFixed: {
		height: headerHeight,
		backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
		backgroundColor: theme.palette.primary.dark,
		backgroundSize: 'cover',
		pointerEvents: 'none'
	},
	contentCardWrapper: {
		maxWidth: 1400,
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 0 auto',
		width: '100%',
		minWidth: '0',
		margin: '0 auto',
		// [theme.breakpoints.down('sm')]: {
		// 	padding: '16px 16px 0 16px'
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	padding: '12px 12px 0 12px'
		// }
	},
	contentCard: {
		display: 'flex',
		position: 'relative',
		// flex: '1 1 100%',
		flexDirection: 'row',
		backgroundColor: theme.palette.background.paper,
		minHeight: 0,
		overflow: 'hidden',
		borderRadius: '2px 2px 0 0',
	},
	drawerPaper: {
		width: drawerWidth,
		maxWidth: '100%',
		overflow: 'hidden',
		height: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'relative'
		}
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		// flex: '1 1 100%',
		zIndex: 10,
		background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
			theme.palette.background.paper,
			0.6
		)} 20%,${fade(theme.palette.background.paper, 0.8)})`
	},
	content: {
		display: 'flex',
		// flex: '1 1 100%',
		minHeight: 0
	},
	avatar: {
		backgroundColor: red[500],
	},
	formControl: {
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	formControlMinWidth: {
		minWidth: 200,
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	card: {
		borderRadius:"5px"
	},
	cardContainer:{
	},
	borderRightLight: {
		borderRight:"1px solid rgba(0,0,0,0.12)"
	},
	buttonSquare:{
		borderRadius: '5px'
	}
}));

function Main(props) {
	const [quotes,setQuotes] = useState([]);
	const [searches,setSearches] = useState(defaultSearches);
	const classes = useStyles(props);
	const router = useRouter();
	const [loading, setLoading] = useState(false);
	const [endUsersAccountsMod,setEndUsersAccounts] = useState([]);
	const [flattenedEndUserAccounts,setFlattenedEndUserAccounts] = useState({});
	const [contactDetails, setContactDetails] = useState(null);
	const [enduserRoles, setenduserRoles] = useState(null);
	const [endusermodules, setendusermodules] = useState([]);
	const [roleId, setRoleId] = useState();
	const [ispowerUser, setispowerUser] = useState();
	const [permissionidList, setpermissionidList] = useState([]);
	const [enableUser, setenableUser] = useState(false);
	const [moduleIdList, setmoduleIdList] = useState()
	const id = router.params.id;

	useEffect(()=>{
		setLoading(true);
		axios.get(`/end-user/${id}`)
		.then(res => {
			const data  = res.data.data.end_user;
			setContactDetails(data);
			setmoduleIdList(res.data.data.new_list)
			setRoleId(data.roles_end_users_id )
			let portal_user = (data.portal_user == null || data.portal_user == "false") ? "false": "true";
			setenableUser(portal_user);			
			console.log(enableUser)			
			axios.get(`/end-user/show/all/roles/${id}`)
			.then(res =>{
				const { data } = res.data;
				setenduserRoles(data.end_user_roles);				
				setendusermodules(data.all_modules);
			})
		})
		.catch(err => {
			console.log(err);
		}).finally(() => {
            setLoading(false);
        });		
	},[]);

	useEffect(()=>{
		let check_poweruser = false;
		if(roleId && enduserRoles){
			let role = enduserRoles.find(data=>data.id == roleId)
			if(role){
				check_poweruser = role.role_name == 'power_user'
				setispowerUser(check_poweruser)
			}
		}
		if(check_poweruser){
			setpermissionidList(endusermodules.map((module)=>{
				return Number(module.id)
			}))
		}else{
			setpermissionidList(moduleIdList)
		}
		
	},[roleId])
	
	function handlepermissionInput(event){
		if(event.target.checked){
			setpermissionidList((data)=>{
				return [...data, Number(event.target.value)]
			})
		}else{
			setpermissionidList((data)=>{
				return data.filter((item)=>{
					return item != Number(event.target.value)
				})
			})
		}
	}
	function clearSearch() {
		setSearches(defaultSearches);
		setQuotes(endUsersAccounts);
	}

	if(loading){
		return(
			<FuseLoading/>
		)
	}

	function updateDetails(){
		console.log(roleId, enableUser, permissionidList);
		axios.post('/end-user/update',{
			id: id,
			roles_end_users_id: roleId,
			portal_user: enableUser,
			module_list: permissionidList
		}).then((res)=>{
			console.log(res.data)
		})
		
	}
	return (<>		
		<div className={clsx(classes.root)}>
			<FuseScrollbars className="flex-grow overflow-x-auto">
				<div className={classes.topBg} >
					{contactDetails && <Grid container spacing={1}>
						<Grid item xs={6} sm={3} lg={4}>
							<Card className={classes.card}>
								<CardContent className={classes.cardContainer}>
									Email
									<br/>
									<span style={{fontWeight:"bold"}}>
										{contactDetails.email}
									</span>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={6} sm={3} lg={4}>
							<Card className={classes.card}>
								<CardContent className={classes.cardContainer}>
									Company name
									<br/>
									<span style={{fontWeight:"bold"}}>
										{contactDetails.user_company.company_name}
									</span>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={6} sm={3} lg={4}>
							<Card className={classes.card}>
								<CardContent className={classes.cardContainer}>
									Cust code
									<br/>
									<span style={{fontWeight:"bold"}}>
										{contactDetails.entity_external_platform_id}
									</span>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={6} sm={3} lg={4}>
							<Card className={classes.card}>
								<CardContent className={classes.cardContainer}>
									First name
									<br/>
									<span style={{fontWeight:"bold"}}>
										{contactDetails.first_name}
									</span>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={6} sm={3} lg={4}>
							<Card className={classes.card}>
								<CardContent className={classes.cardContainer}>
									Last name
									<br/>
									<span style={{fontWeight:"bold"}}>
										{contactDetails.last_name}
									</span>
								</CardContent>
							</Card>
						</Grid>
					</Grid>}
				</div>
				{enduserRoles &&
					<Card className={classes.card} style={{margin: 10}}>
						<CardContent className={classes.cardContainer}>
							<span style={{fontWeight:"bold", padding: "18px"}}>Role</span>
							<Grid container spacing={1} className="p-10" >
								<Grid item xs={6} sm={8} lg={8}>
										<RadioGroup row aria-label="position" name="position"
											onChange={event=>{
												setRoleId(event.target.value)
											}}
										>	
										<Grid container spacing={1} className="p-10">
												
											{enduserRoles.map((role)=>{
												return (
													<Grid item xs={6} sm={6} lg={6} key={role.id} >

														<FormControlLabel value={role.id} control={
														<Radio 
															name="role"
															checked={role.id == roleId}
														/>} label={role.display_name} />
													
													</Grid>
													
												)								
											})}
											
										
									</Grid>
									</RadioGroup>
								</Grid>
								
								<Grid item xs={6} sm={8} lg={4}>
									<Checkbox 
									value={contactDetails.id}
									style={{marginTop: "1rem"}}
									onChange={()=>{
										var portal_user = enableUser
										setenableUser(portal_user)
									}}
									/>
									<span style={{marginTop: "2m"}}>Enable user</span>
								</Grid>
							</Grid>
							
						</CardContent>
					</Card>
				}
				{/* && contactDetails.roles_end_user */}
				{endusermodules && 
					<Card className={classes.card} style={{margin: 10}}>
						<CardContent className={classes.cardContainer}>
							<span style={{fontWeight:"bold"}}>Modules</span><br/>
							<Grid container spacing={1} className="p-10" >
								{endusermodules.map((module)=>{
									return (
										<Grid item xs={6} sm={4} lg={3}>
											<Checkbox disabled={!roleId}
											onChange={handlepermissionInput}
											checked={permissionidList.includes(module.id)}
											value={module.id}
											/>{module.display_name}
										</Grid>
									)								
								})}
							</Grid>
						</CardContent>
					</Card>
				}
				{contactDetails && 
					<Card className={classes.card} style={{margin: 10}}>
						<CardContent className={classes.cardContainer}>
						<div>
								<Button
									variant="contained"
									color="secondary"
									className={classes.buttonSquare}
									style={{float:"right"}}
									//onClick={openUserCreationDialog}
								>
									Load locations
								</Button>
								</div><br /><br />
							<div className="ag-theme-alpine" style={{height: 400, width: "100%", margin: 5}}>
							{/* <Grid item xs={6} sm={4} lg={4}><br/> */}
							
							{/* </Grid> */}
								<AgGridReact
									rowData={[]}>
									{["Location","Action"].map(item => (
										<AgGridColumn 
										field={"asset_name"} 
										headerName={item}
										sortable={ true } filter={ true }
										></AgGridColumn>
									))}
								</AgGridReact>
							</div>
							<div>
								<Button disabled={!roleId}
									variant="contained"
									color="secondary"
									className={classes.buttonSquare}
									onClick={updateDetails}
									style={{float:"right", margin: "6px"}}
									//onClick={openUserCreationDialog}
								>
									Update
								</Button>
								<Button disabled={enableUser}
									variant="contained"
									color="secondary"
									className={classes.buttonSquare}
									style={{float:"right", margin: "6px"}}
									//onClick={openUserCreationDialog}
								>
									Send activation email
								</Button>
							</div>
						</CardContent>
					</Card>
				}
			</FuseScrollbars>
		</div>
	</>);
}

function ArrowButton({onClick,label}) {
	return(
		<span
		onClick={()=>onClick}
		className="arrow-pointer">
				{label}
		</span>
	);
}

export default Main;
