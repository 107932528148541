import { Icon } from "@material-ui/core";
import { useState } from "react";
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import { useEffect } from 'react';

export default function CustomFilter(props, header='', gridRef={}){
  const columnHeaderAndValueMap = (props.columnApi.columnModel.columnDefs).reduce(( acc, curr )=>{
    acc[curr.field] = curr.headerName;
    return acc
  },{});

  // const [columnHeaderAndValueMap, setColumnHeaderAndValueMap] = useState({
  //   "contract_type_name": "Contract Type Name",
  //   "asset_tag": "Asset Tag", "name": "Asset Name",
  //   "user_name": "User",
  //   "status": "Status",
  //   "contract_number": "Contract Number"
  // })

  const [visibility, setVisibility] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [filterDate, setFilterDate] = useState({
		date: null,
		error: false
	});

  function formatDate(inputDate, field) {
    let dateObj = new Date(inputDate);
    let month
    let monthNames = ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December"];
    month = monthNames[dateObj.getMonth()];
    let day = ('0' + dateObj.getDate()).slice(-2);
    let year = dateObj.getFullYear();
    let outputDate
    if(field === 'problem_time' || field === 'recovery_time'){
      month = '' + (dateObj.getMonth() + 1);
      if (month.length < 2) 
        month = '0' + month;
      outputDate = [year, month, day].join('.')

    } else {
      outputDate = month + ' ' + day + ', ' + year;
    }

    return  outputDate;
  }

  const handleChange = (e) => {
    if(props.column.colDef.field.includes('date') || props.column.colDef.field === 'problem_time' || props.column.colDef.field === 'recovery_time'){
      setFilterValue(e)
      props.api.setFilterModel({
        [props.column.colDef.field]: {
          filterType: "text",
          type: "contains",
          filter: formatDate(e,props.column.colDef.field)
        }
      });
      setFilterDate({
        date: null,
        error: false
      });
    }else if(props.column.colDef.field.includes('time')){
      props.api.setFilterModel({
        [props.column.colDef.field]: {
          filterType: "text",
          type: "contains",
          filter: e
        }
      });
      setFilterDate({
        date: null,
        error: false
      });
    } else{
      setFilterValue(e.target.value)
      props.api.setFilterModel({
        [props.column.colDef.field]: {
          filterType: "text",
          type: "contains",
          filter: e.target.value
        }
      });
    }
    
  }

  useEffect(()=>{
    let keyArr = Object.keys(gridRef?.current?.api?.getFilterModel() || {})
    if(keyArr.includes(header)){
      setVisibility(true)
      setFilterValue(gridRef?.current?.api?.getFilterModel()[header]?.filter)
    }
  },[header])


  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {!visibility && <p style={{ display: 'flex', fontSize: '12px', fontWeight: '600', fontFamily: '"Inter", sans-serif', color: '#1D2939' }}>
        <span className="sort_icon_holder">
          {/* <Icon onClick={() => { props.api.setSortModel([{ colId: props.column.colDef.field, sort: 'desc' }]) }} style={{ fontSize: '1.8rem' }}>
            arrow_downward
          </Icon> */}
          <span className="icon_holder" onClick={() => { props.api.setSortModel([{ colId: props.column.colDef.field, sort: 'desc' }]) }}>
            <i className="ti ti-arrow-narrow-down" />
          </span>
          <span className="icon_holder" onClick={() => { props.api.setSortModel([{ colId: props.column.colDef.field, sort: 'asc' }]) }}>
            <i className="ti ti-arrow-narrow-up" />
          </span>
          {/* <Icon onClick={() => { props.api.setSortModel([{ colId: props.column.colDef.field, sort: 'asc' }]) }} style={{ fontSize: '1.8rem' }}>
            arrow_upward
          </Icon> */}
        </span>
        <span style={{ paddingLeft: '6px' }}> {columnHeaderAndValueMap[props.column.colDef.field]} </span>
      </p>}
      {(visibility && (!props.column.colDef.field.includes('date') && !props.column.colDef.field.includes('time'))) && <input
        type="text"
        style={{ padding: '6px', backgroundColor: 'transparent' }}
        value={filterValue}
        onChange={handleChange}
        placeholder="Search..."
        className="search_input"
      />}
      {(visibility && (props.column.colDef.field.includes('date') || props.column.colDef.field.includes('time'))) && <div className="datepickr_holder"><CustomDatePickerV2
        state={filterDate}
        setState={setFilterDate}
        required={false}
        label=''	
        onChangeFromParent = {(e)=>{handleChange(e)}}								
      /></div>}
      
      {!visibility && <p style={{ color: '#98A2B3', fontWeight: '200' }} onClick={() => { setVisibility(true) }}>
        {/* open */}
        <Icon style={{ fontSize: '1.8rem' }}>
          search
        </Icon>
      </p>}
      {visibility && <p style={{ color: '#98A2B3', fontWeight: '200' }} onClick={() => { setVisibility(false) }}>
        <Icon onClick={() => { 
          props.api.setFilterModel([{}]) 
          setFilterValue()
           }} style={{ fontSize: '1.8rem' }}>
          close
        </Icon>
      </p>}
    </div>
  );
};