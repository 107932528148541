import {IconButton, Icon, Tooltip} from '@material-ui/core';
import {useState} from 'react';

const rowEditToggleRenderer = (props) => {
    const [startedEditing, setStartEditing] = useState(false);

    function startEditing(){
        setStartEditing(true);
        props.startEditing(props.rowIndex);
    }

    if(props.data.request && props.data.request != null){
        return(
            <>
            <Tooltip
            title={`Requested ${props.data.request.split('_').join(' ')}`}
            >
            <IconButton size="small">
            <Icon>info</Icon>
            </IconButton>
            </Tooltip>
            <span>{props.value}</span>
            </>
        )
    }

    return (
        <>
        {props.data.requested &&
        <IconButton size="small" className={`radius-sm mr-5`}
        disable={startedEditing}
        onClick={startEditing}
        >
            <Icon>edit</Icon>
        </IconButton>
        }
        <span>{props.value}</span>
        </>
    )
}

export default rowEditToggleRenderer;