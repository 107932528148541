const initialState = {
	staticHeaders : [],
    dynamicHeaders : [],
    visibleHeaders : []
}

const actionTypes = {
    SET_VISIBLE_HEADERS: "SET_VISIBLE_HEADERS",
    SET_STATIC_HEADERS: "SET_STATIC_HEADERS",
    SET_DYNAMIC_HEADERS: "SET_DYNAMIC_HEADERS",
    SET_ALL_HEADERS: "SET_ALL_HEADERS"
}

export function setAllHeaders(staticHeaders=[], dynamicHeaders = [], visibleHeaders=[]){
    return {
        type: actionTypes.SET_ALL_HEADERS,
        payload: {
            staticHeaders, dynamicHeaders, visibleHeaders
        },
    }
}

export function setVisibleHeaders( visibleHeaders = [] ) {
    return {
        type: actionTypes.SET_VISIBLE_HEADERS,
        payload: {
            visibleHeaders 
        },
    }
}

export function setStaticHeaders( staticHeaders = [] ) {
    return {
        type: actionTypes.SET_STATIC_HEADERS,
        payload: {
            staticHeaders 
        },
    }
}

export function setDynamicHeaders( dynamicHeaders = [] ) {
    return {
        type: actionTypes.SET_DYNAMIC_HEADERS,
        payload: {
            dynamicHeaders 
        },
    }
}

export default function (state = initialState, action) {
	switch (action.type) {
        case actionTypes.SET_ALL_HEADERS : {
            return {
                ...state,
                staticHeaders : action.payload.staticHeaders,
                dynamicHeaders : action.payload.dynamicHeaders,
                visibleHeaders : action.payload.visibleHeaders,
            }
        }
        case actionTypes.SET_VISIBLE_HEADERS : {
            return {
                ...state,
                visibleHeaders : action.payload.visibleHeaders
            }
        }
        case actionTypes.SET_STATIC_HEADERS : {
            return {
                ...state,
                staticHeaders : action.payload.staticHeaders
            }
        }
        case actionTypes.SET_DYNAMIC_HEADERS : {
            return {
                ...state,
                dynamicHeaders : action.payload.dynamicHeaders
            }
        }
        default:
            return state
	}
}