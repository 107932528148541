import useToast from '@fuse/hooks/useToast'
import {
  Button, DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Typography,
  Drawer
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { Box } from '@mui/material';
import Editor from '@fuse/components/Editor';
import AlertModal from 'helper/alertModal'
import ColorPicker from '@fuse/components/ColorPicker'

const useStyles = makeStyles(theme => ({
  uploadImageButton: {
    padding: '6px 16px',
    fontSize: '1.3rem',
    minWidth: '64px',
    boxSizing: 'border-box',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: '4px',
    textTransform: 'none',
    backgroundColor: '#6B2C57',
    color: '#ffffff',
    cursor: 'pointer'
  },
  loader: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  subSection: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '20px',
    padding: '10px',
    backgroundColor: '#f4f4f4',
    borderRadius: '4px'
  },
  endButtons: {
    borderRadius: '4px'
  },
  drawerWidth: {
    width: 520,
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
}))

function AddAssetStatusDialog(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
  const openState = useSelector((state) => state.assetStatusApp.formViewDialog)
  const { data: editFromState } = useSelector(({ assetStatusApp }) => assetStatusApp.formViewDialog);
  const [color, setColor] = useState({ name: 'black', code: "#000000" });
  const [description, setDescription] = useState('')
  const [openAlerModal, setOpenAlertModal] = useState(false);

  //form validation
  const schema = yup.object({
    status_name: yup.string().required('Status is required!').matches(/\S/, 'Status cannot be only spaces!')
  })

  const defaultColor = { name: 'black', code: "#000000" }

  const defaultValues = {
    status_name: ''
  }
  const {
    control,
    formState: { errors: statusFormErrors },
    handleSubmit,
    trigger,
    reset,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const onCancel = () => {
    reset(defaultValues)
    setDescription('')
    dispatch(setFromData(null))
    dispatch(closeFormViewerDialog())
    setColor(defaultColor)
  }

  const closeDialog = () => {
    dispatch(setFromData(null))
    dispatch(closeFormViewerDialog())
    setColor(defaultColor)
    setDescription('')
  }


  useEffect(() => {
    if (editFromState) {
      setValue('status_name', editFromState.status_name);
      setDescription(editFromState.original_info.status_description)
      setColor(editFromState.status_color)
    }
  }, [editFromState])

  function onEditorContentChange(content) {
    setDescription(content)
  }

  function editAssetStatus(payload) {
    axios.post(`/asset-status/edit?type=hardware`, payload).then(response => {
      if (response.status == 201) {
        toast.error(response.data.message)
      } else {
        reset(defaultValues)
        closeDialog()
        toast.success('Asset status updated Successfully.')
        dispatch(setQuickFilter(null))
        if (props.getAssetStatus) {
          props.getAssetStatus()
        }
      }
    }).catch(err => {
      console.log('212=>', err);
      toast.error('Something went wrong!')
    })
  }

  const manageFormData = async (formData) => {
    await trigger();
    let payload = {
      entity_external_platform_id: String(Math.trunc(Math.random() * 20000)),
      type: 'hardware',
      status_name: formData.status_name,
      status_description: description,
      status_color: color
    }
    if (editFromState) {
      payload.edit_id = Number(editFromState.id);
      delete payload.entity_external_platform_id;
      editAssetStatus(payload)
    } else {
      axios.post('/asset-status/add', payload).then(response => {
        if (response.status == 201) {
          toast.error(response.data.message)
        } else {
          reset(defaultValues)
          closeDialog()
          toast.success(response.data.message)
          dispatch(setQuickFilter(null))
          if (props.getAssetStatus) {
            props.getAssetStatus()
          }
        }
      }).catch(err => {
        console.log('212=>', err);
        toast.error('Something went wrong!')
      })
    }
  }
  const handleAlertModal=(_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenAlertModal(!openAlerModal)
}

const handleCloseAlertModal=() =>{
    setOpenAlertModal(false)
    onCancel()
}
  return (
    <>
    <div>
      <Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
        <div className='heading_holder'>
          <h4 className='heading'>{Boolean(editFromState) ? 'Update' : 'Add'} Asset Status</h4>
          <IconButton style={{ padding: '0px' }} onClick={handleAlertModal}>
            <Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
          </IconButton>
        </div>
        <div className='progressbar_holder'>
          <div className='progress'>
            &nbsp;
          </div>
        </div>
        {/*--------------------------Item Information----------------------------- */}
        <div style={{ paddingTop: '30px' }} className='cstm_wizard_holder'>
          <Box sx={{ width: '100%' }} className='box_holder'>
            <div className='main_content_holder'>
              <div className='content_holder'>
                <div className="text-left">
                  <div className='mb-10'>
                    <Typography className='status_text'>
                      Status Name
                      <span className='required_span'>*</span>
                    </Typography>
                    <Controller
                      name="status_name"
                      // className="mb-16"
                      defaultValue={''}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            {...field}
                            variant='outlined'
                            // label='Status Name'
                            required
                            fullWidth
                            error={!!statusFormErrors.status_name}
                            helperText={statusFormErrors?.status_name?.message}
                            FormHelperTextProps={{
                              style: {
                                margin: 0,
                                width: '100%',
                                paddingTop: '2px',
                                fontSize: '10px'
                              }
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div className='mb-10'>
                    <Typography className='status_text'>
                      Description
                    </Typography>
                    <Editor
                      editorContent={description}
                      onEditorContentChange={onEditorContentChange}
                      moduleType='asset_status'
                    />
                  </div>
                  <div>
                    <Typography className='status_text'>
                      Choose Color
                    </Typography>
                    <ColorPicker color={color}
                      setColor={setColor}
                    />
                  </div>
                </div>
              </div>
              <div className='btn_holder'>
                <Button
                  color="inherit"
                  onClick={handleAlertModal}
                  className='back_btn secondary_btn'
                >
                  Cancel
                </Button>
                <Button onClick={handleSubmit(manageFormData)} className='next_btn'>
                  Proceed
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </Drawer>
    </div>
    {openAlerModal &&
                <AlertModal  openAlerModal module="asset status" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
    </>
  )
}

export default AddAssetStatusDialog
