import { configureStore } from '@reduxjs/toolkit';
import createReducer from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { getStoredState, REHYDRATE } from 'redux-persist';
import axiosgeneral from "@fuse/utils/axios";
// import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

// function crossBrowserListener(token) {
// 	console.log(`state: ${JSON.stringify(token.getState())}`)
// 	console.log('event');
// 			const credentials = axiosgeneral.defaults.headers.common.Authorization
// 			if(event.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials) {
// 			  window.localStorage.setItem('CREDENTIALS_SHARING', credentials)
// 			  window.localStorage.removeItem('CREDENTIALS_SHARING')
// 			}
// 			if(event.key === 'CREDENTIALS_SHARING' && !credentials){
// 			  console.log(event.newValue);
// 			  axiosgeneral.defaults.headers.common.Authorization = event.newValue;
// 			  window.sessionStorage.setItem('persist:user', event.newValue);
			  
// 			}
			// if(event.key === 'CREDENTIALS_FLUSH' && credentials){
			// 	axiosgeneral.defaults.headers.common.Authorization = '';
			// 	this.props.logout();
			// 	this.props.setUserData({});
			// }
	// console.log(`token: ${JSON.stringify(token.defaults.headers.common)}`);
	// console.log('triggerde');
	// return async function() {
	//   let state = await getStoredState(persistConfig)
  
	//   store.dispatch({
	// 	type: REHYDRATE,
	// 	key: persistConfig.key,
	// 	payload: state,
	//   })
	// }
//   }

if (process.env.NODE_ENV === 'development' && module.hot) {
	module.hot.accept('./rootReducer', () => {
		const newRootReducer = require('./rootReducer').default;
		store.replaceReducer(newRootReducer.createReducer());
	});
}

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
	const { createLogger } = require(`redux-logger`);
	const logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });

	middlewares.push(logger);
	// middlewares.push(createStateSyncMiddleware({}))
}

const persistConfig = {
	key: 'root',
	storage: sessionStorage,
}

const rootReducer = createReducer();

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development'
});

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(createReducer(store.asyncReducers));
	return store;
};

// initMessageListener(store);
export const persistor = persistStore(store);
// window.addEventListener('storage',(event) => {
// 		console.log('event');
// 		const credentials = axiosgeneral.defaults.headers.common.Authorization
// 		if(event.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials) {
// 		  window.localStorage.setItem('CREDENTIALS_SHARING', credentials)
// 		  window.localStorage.removeItem('CREDENTIALS_SHARING')
// 		}
// 		if(event.key === 'CREDENTIALS_SHARING' && !credentials){
// 		  console.log(event.newValue);
// 		  axiosgeneral.defaults.headers.common.Authorization = event.newValue;
// 		  window.sessionStorage.setItem('persist:user', event.newValue);
		  
// 		}
// 		// if(event.key === 'CREDENTIALS_FLUSH' && credentials){
// 		// 	axiosgeneral.defaults.headers.common.Authorization = '';
// 		// 	this.props.logout();
// 		// 	this.props.setUserData({});
// 		// });
// 	}
// );

// 		  window.sessionStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString())
// 		  window.sessionStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
export default store;
