import React, {useEffect,useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import useToast from '@fuse/hooks/useToast';

const useStyles = makeStyles({
    row:{
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom:"15px",
        display: 'flex'
    },
    column:{
        // border: '2px solid black',
        // width: '100%',
        width:"50%",
        textAlign: 'left'
    }
});

const InfoTab = () => {
    const classes = useStyles();
    const [userDetails, setUserDetails] = useState(null);
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const openState = useSelector((state) => state.usersApp.formViewDialog)
    useEffect(()=>{
        setUserDetails(openState.user_data?.client)
    },[openState])

    return (
        <>
            <div style={{display:"flex",justifyContent:'space-between',alignItems:'center',marginBottom:"20px"}}>
                <div >
                    <Typography variant="h6" style={{color:'#3A4252'}}>Address</Typography>                
                </div>
                {/* <div  style={{textAlign:"right"}}>
                <Button variant="outlined"><Icon><span class="material-symbols-outlined" style={{fontSize:"17px"}}>
                    edit
                    </span></Icon> Edit</Button>
                </div> */}
            </div>
            <div style={{height: 290, width: '100%',textAlign:'center',display: 'flex',flexDirection: 'column',overflow:"auto"}}>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Address 1</Typography>
                        <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.address || 'N/A'}</Typography>
                    </div>
                    <div className={classes.column}>
                        <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Address 2</Typography>
                        <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.address_2 || 'N/A'}</Typography>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">City</Typography>
                        <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.city || 'N/A'}</Typography>
                    </div>
                    <div className={classes.column}>
                        <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">State / Province</Typography>
                        <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.state || 'N/A'}</Typography>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Zip Code</Typography>
                        <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.zip || 'N/A'}</Typography>
                    </div>
                    <div className={classes.column}>
                        <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Country</Typography>
                        <Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{userDetails?.country || 'N/A'}</Typography>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoTab
