import { exportToCSV } from "@fuse/utils/generateCsv";
import exportExcel  from "@fuse/utils/excel";
import generatePdf  from "@fuse/utils/generatePdf";
import generateGroupByFileData  from "@fuse/utils/groupByDataFormat";
import axios from "@fuse/utils/axios";

const multiFormatFileManager = async (fileType, tableName, gridRef, moduleName) =>{
  if(tableName  == 'asset') await getAssetDetails(fileType, gridRef)
  if(tableName  == 'subscription') await getSubscriptionDetails(fileType)
  if(tableName  == 'Contract') await getContractDetails(fileType)
  // if(tableName  == 'service_request') await getServiceTicketDetails(fileType)
  if(tableName  == 'shipping') await getLocationDetails(fileType)
  if(tableName  == 'user') await getUserDetails(fileType)
    //new way to export using gridRef applicable for without SSRM
  if(tableName  == 'Archived Asset' || tableName == 'Archived Contract' || tableName == 'service_provider' || 
    tableName  == 'Manufacture' || tableName == 'Model' || tableName == "Archived Service Tickets" || tableName == "service_request") await generateCSVForAllModules(fileType, gridRef, tableName)
  if(tableName  == 'user_audit_logs') await getUserAuditLogDetails(fileType, gridRef)
  if(tableName  == 'Data Report') await getStandardReportDetails(fileType, gridRef, moduleName)
    
}

function generateFile(defaultHeader, results, fileType, filePrefix){
  let csvArr = [defaultHeader]
  for(let obj of results){
    if(filePrefix  == 'asset') csvArr.push((Object.values(obj)).slice(0, -1))
    else csvArr.push((Object.values(obj)))
  }
  if (fileType == 'Excel') exportExcel(defaultHeader,csvArr, `${filePrefix}`)
  if (fileType == 'CSV') exportToCSV(csvArr, `${filePrefix}`) 
  if (fileType == 'PDF') generatePdf(csvArr, `${filePrefix}`) 

  return true
}

function generateGroupColumnNameAndColumnValue(resultColumnArr,groupByColumnName){
  
  if(!Array.isArray(resultColumnArr)){
    groupByColumnName += `---> ${Object.keys(resultColumnArr)[0]}`
    return generateGroupColumnNameAndColumnValue(resultColumnArr[Object.keys(resultColumnArr)[0]], groupByColumnName)
    
  }else{
    return {groupByColumnName,resultColumnArr}
  }
}

function generateFileWhichIncludeGroupByColumn(defaultHeader, results, fileType, filePrefix){
  let csvArr = [defaultHeader]
  for(let key of Object.keys(results)){
   const {groupByColumnName,resultColumnArr} = generateGroupColumnNameAndColumnValue(results[key],`--> ${key ?? ''}`)
    csvArr.push([groupByColumnName])
    
    for(let columnData of resultColumnArr){
        csvArr.push(['',...(Object.values(columnData))])
    }
    // console.log("groupByString", groupByString,obj)
    // if(!Array.isArray(results)){
    //   groupByString += `---> ${key}`
    // }else{
    //   csvArr.push([groupByString])
    //   for(let obj of results[key]){
    //     csvArr.push(['',...(Object.values(obj))])
    //   }
    // }
    
    

  }
  if (fileType == 'Excel') exportExcel(defaultHeader,csvArr, `${filePrefix}`)
  if (fileType == 'CSV') exportToCSV(csvArr, `${filePrefix}`) 
  if (fileType == 'PDF') generatePdf(csvArr, `${filePrefix}`) 

  return true
}

async function getAssetDetails(fileType, gridRef){
  const defaultHeader = ['Serial Number', 'Asset Tag', 'Asset Name', 'User', 'Status',
    'Contract Number', 'Contract Tag', 'Model Name', 'Model Number', 'Category', 'Manufacture',
    'Location', 'Address', 'Notes', 'Created Date', 'Updated Date', 'Source', 'Network Status'
  ]
  const { results } = (await axios.post(`assets/all-asset-for-csv`, {
      dataSourceParams:{
      "filterModel": gridRef?.current?.api?.getFilterModel(),
      }
    }
  )).data.data
  generateFile(defaultHeader, results, fileType, 'asset')

}

async function getUserAuditLogDetails(fileType, gridRef){
  const defaultHeader = ['Name', 'Role', 'Login Time',
  'Logout Time', 'Total Session','Ip Address']
  const { results } = (await axios.post(`user/all-user-audit-log-for-csv`, {
    dataSourceParams:{
      "filterModel": gridRef?.current?.api?.getFilterModel(),
    }
  }
  )).data.data
  generateFile(defaultHeader, results, fileType, 'user-audit-log')
}

async function getSubscriptionDetails(fileType){
  const defaultHeader = ['Subscription Name', 'Provider Name', 'Status', 'No Of Seats',
    'Category', 'Department', 'Start Date', 'End Date', 'Amount', 'Billing Cycle',
    'Billing Cycle Date', 'Website', 'PO Date', 'PO Number', 'Card Name', "Card Holder's Name",
  ]
  const { results } = (await axios.get(`subscription/all-subscription-for-csv`)).data.data
  generateFile(defaultHeader, results, fileType, 'subscription')

}

async function generateCSVForAllModules(fileType, gridRef, tableName){
  let fileName = (tableName  == 'Archived Asset'? 'archive_asset': tableName == 'Archived Contract'?'archive_contract': tableName == 'service_provider'?'service_provider': 
    tableName  == 'Manufacture'?'manufacturer': tableName == 'Model'?'model': tableName == 'service_request' ? 'service_ticket' :
    tableName == 'Archived Service Tickets' ? 'archived_service_ticket' : '')

  let allRowData = gridRef.current.props.rowData
  let columnKeyNameArr = (gridRef.current.columnApi.columnModel.columnDefs.map(item=> item?.field)).slice(1)
  const defaultHeader = (gridRef.current.columnApi.columnModel.columnDefs.map(item=> item.headerName)).slice(1)

  const filteredArray = allRowData.map(item => {
    return columnKeyNameArr.reduce((acc, key) => {
        acc[key] = item[key] || '';
        return acc;
    }, {});
  });
  generateFile(defaultHeader, filteredArray, fileType, fileName)
  

}

async function getContractDetails(fileType){
  const defaultHeader = ['Contract Number', 'Contract', 'Start Date', 'End Date',
    'Type', 'Contract Tag', 'No Of Assets', 'SLA', 'Service Provider', 'Master',
    'Parent Contract', 'New/renew', 'Location'
  ]
  const { results } = (await axios.get(`end-user-contracts/all-contract-for-csv`)).data.data
  generateFile(defaultHeader, results, fileType, 'contract')

}

async function getServiceTicketDetails(fileType){
  const defaultHeader = ['Ticket Number', 'Serial Number', 'Problem Summery', 'Priority',
    'Model Number', 'Contract Number', 'Status', 'Agent', 'User', 'Location',
    'Detailed Description Of The Issue', 'Created Date', 'Updated Date'
  ]
  const { results } = (await axios.get(`service/all-service-ticket-for-csv`)).data.data
  generateFile(defaultHeader, results, fileType, 'service_ticket')

}

async function getLocationDetails(fileType){
  const defaultHeader = ['Location Name', 'Address 1', 'Address 2', 'City',
    'State/Province', 'Zip', 'Country', 'Note', 'Location Type Name',
    'Number Of Assets'
  ]
  const { results } = (await axios.get(`shipping/all-location-for-csv`)).data.data
  generateFile(defaultHeader, results, fileType, 'location')

}
async function getUserDetails(fileType){
  const defaultHeader = ['First Name', 'Last Name', 'Phone', 'Email',
    'Title', 'Address', 'City', 'State/Province', 'Zip',
    'Country', 'Address 2', 'Employee Number', 'Source', 'Role Name', 'Department Name',
  ]
  const { results } = (await axios.get(`user/all-user-for-csv`)).data.data
  generateFile(defaultHeader, results, fileType, 'user')

}


async function getStandardReportDetails(fileType, gridRef, moduleName){
  const columnNameArr = gridRef?.current?.columnApi.getAllColumns()?.map(item => item.colDef.headerName)
  let defaultHeader = ['Group', ...columnNameArr]

  const groupColumn = gridRef?.current?.columnApi?.getRowGroupColumns()
    let name = ''
  if(moduleName == 'asset'){
    name = groupColumn[0].colDef.field.includes('location')?'asset_by_location':
    groupColumn[0].colDef.field.includes('category')?'asset_by_category':
    groupColumn[0].colDef.field.includes('department')?'asset_by_department':
    groupColumn[0].colDef.field.includes('manufacturer')?'asset_by_manufacturer':''
  }else{
    name = 'report_data'
  }
  const { fileData } = (await axios.get(`reports/standard-reports-csv/${moduleName}/${name}`)).data.data

  const results = generateGroupByFileData(fileData, gridRef, moduleName)
  generateFileWhichIncludeGroupByColumn(defaultHeader, results, fileType, `${moduleName}_report`)


}
export default multiFormatFileManager;

