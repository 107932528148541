import React, {useEffect,useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import RenderHtml from '@fuse/components/RenderHtml';

const useStyles = makeStyles({
    row:{
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom:'15px',
        display: 'flex'
    },
    column:{
        // border: '2px solid black',
        width: '100%',
        textAlign: 'left'
    }
});

function SubscriptionDetailsTab({contractDetails}) {
    console.log("contractDetails", contractDetails)
    const classes = useStyles();
    const [statusData,setStatusData] = useState([])


    return (
        <div className='flex flex-col text-center w-full overflow-auto custom-scrollbar' style={{height: 250}}>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Contract Name</Typography>
                    <Typography style={{fontWeight:400, maxWidth: '400px', wordBreak: 'break-all'}} variant="subtitle1">{contractDetails?.contractName}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Contract Number</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contractNumber}</Typography>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Contract Type</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contractType}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Contract Status</Typography>
                    {/* <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contractStatus}</Typography> */}
                    <span className='flex items-center'>
                        <span style={{
                            height:'10px',
                            width:'10px',
                            marginBottom: '2px',
                            borderRadius: '50%',
                            backgroundColor: contractDetails?.contractStatusColor?.code
                        }}></span>
                        <Typography style={{fontWeight:400, marginLeft: '5px'}} variant="subtitle1">{contractDetails?.contractStatus}</Typography>
                        {/* <span style={{marginLeft: '5px'}}>{contractDetails?.contractStatus}</span> */}
                    </span>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Start Date</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contractStartDate ? dateReformatTo_mmm_dd_yyyy(contractDetails?.contractStartDate) : 'N/A'}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">End Date</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.contractEndDate ? dateReformatTo_mmm_dd_yyyy(contractDetails?.contractEndDate) : 'N/A'}</Typography>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Contract Description</Typography>
                    {/* <Typography style={{fontWeight:400}} variant="subtitle1">
                        {contractDetails?.contractDescription}
                    </Typography> */}
                    <RenderHtml html={contractDetails?.contractDescription} iframe={false}/>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Service Level Agreement</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.sla}</Typography>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Master</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">
                        {contractDetails?.master}
                    </Typography>
                </div>
                { contractDetails.master === 'No' && 
                    <div className={classes.column}>
                        <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Parent Contract</Typography>
                        <Typography style={{fontWeight:400}} variant="subtitle1">{contractDetails?.parentContract}</Typography>
                    </div>
                }
            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E"}} variant="subtitle1">Total Contract Value</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">
                        {contractDetails?.total_contract_value !== null ? formatPriceFieldOnBlur(contractDetails?.total_contract_value) : "N/A"}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SubscriptionDetailsTab)
