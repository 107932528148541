import React, {useEffect, useState, useRef} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { withRouter } from 'react-router-dom';
import axios from "@fuse/utils/axios";
import { setServiceRequestId } from 'app/main/service-request/store/filterParamsReducer';
import { useDispatch } from 'react-redux';
import { useRouter } from '@fuse/hooks';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';

const useStyles = makeStyles({
    root:{
      '& .MuiDataGrid-columnsContainer':{
        background: '#8f8f8f26'
      }
    }
});


function ServiceRequestsTab(props) {
    const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

	const gridRef = useRef()
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
        
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };
    
    const columns = [
        { field: 'ticket_number', headerName: 'Ticket Number', width: 200, minWidth: 150,
            headerComponentFramework: CustomFilter,
            cellRendererFramework: (params) =>{
                return (<a style={{color: "black",cursor: 'pointer'}} onClick={()=>redirectToServiceRequestsPage(params)}>{params.value}</a>)
            }
        },
        { field: 'serial_number', headerName: 'Serial Number', headerComponentFramework: CustomFilter, width: 200, minWidth: 170},
        { field: 'title', headerName: 'Title', headerComponentFramework: CustomFilter, width: 200, minWidth: 150},
        { field: 'priority', headerName: 'Priority', headerComponentFramework: CustomFilter, width: 200, minWidth: 150},
        { field: 'agent_name', headerName: 'Agent Name', headerComponentFramework: CustomFilter, width: 200, minWidth: 160}
    ];
    
    const classes = useStyles();
    const dispatch = useDispatch()
    const router = useRouter()
    const [serviceRequests,setServiceRequests] = useState([])
    const [loading,setLoading] = useState(false)
    const {allAssets} = props
    const assetsIds = allAssets.map(item => item.serial_number )

    function redirectToServiceRequestsPage(params){
        router.push(`/service-tickets/${params.data.id}`)
    }

    function formatServiceRequests(serviceRequests){
        return serviceRequests.map((item)=>{
            return { 
                id: item.id,
                ticket_number: item.ticket_id,
                serial_number: item.asset.serial_number,
                title: item.title,
                priority: item?.service_request_priority?.name ?? "N/A",                
                agent_name: item.agent ? `${item.agent.first_name} ${item.agent.last_name}` : 'N/A'
            }
        })
    }
    
    function getServiceRequests(){
        if(!allAssets) return
        setLoading(true)
        axios.get(`/contracts/get-service-requests/${assetsIds.join('-')}`,{
        }).then((res) => {
            const { response } = res.data.data;
            setServiceRequests(formatServiceRequests(response))
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(()=>{
        getServiceRequests()
    },[])

    if(loading){
        return(
            <FuseLoading/>
        )
    }

    return (
        <>
            {serviceRequests.length === 0 && 
                <div className='no_data_holder'>
                    <img src='assets/images/nodata.svg' alt='icon' />
                    <p className='bold_txt'>It's all empty here!</p>
                    <p className='normal_txt'>No data to show yet</p>
                </div>
            }

            {
                serviceRequests.length > 0 &&      
                <div 
                    className="ag-theme-alpine" 
                    style={{
                    width: "100%",
                    height: windowHeight+105,
                    fontSize:'12px'
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={serviceRequests}
                        animateRows
                        onGridReady = { onGridReady }
                        paginationPageSize={10}
                        pagination
                        defaultColDef={{
                            resizable: true
                        }}
                    >
                    {columns.map(header => {
                        return(
                            <AgGridColumn 
                                key = {header.field} 
                                field = {header.field}
                                headerName = {header.headerName}  
                                width = { header.width }
                                minWidth = { header.minWidth }
                                maxWidth = { header.maxWidth }
                                sortable = {false}
                                flex = {1}
                                filter = "text"
                                wrapText
                                cellRendererFramework = { header.cellRendererFramework}
                                headerComponentFramework = {header.headerComponentFramework}								
                            />
                        )
                    })}
                    </AgGridReact>
                </div>
            }
            {/* <div className={classes.root} style={{height: 290, width: '100%',overflow:"auto"}}>
                <DataGrid
                    rows={serviceRequests}
                    columns={columns}
                    hideFooter={true}
                />
            </div> */}
        </>
    )
}

export default withRouter(ServiceRequestsTab)
