import { createSlice } from '@reduxjs/toolkit';

const pageTitleSlice = createSlice({
	name: 'pageTitle',
	initialState: {
		title: "",
	},
	reducers: {
		updateTitle: (state, action) => {
			state.title = action.payload;
		},
	}
});

export const { updateTitle } = pageTitleSlice.actions;

export default pageTitleSlice.reducer;
