import React, { useEffect, useState, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import Tooltip from '@material-ui/core/Tooltip';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';

const useStyles = makeStyles({

    root: {
        // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
        //   border: '2px solid black',
        //   display: 'none !important'
        // }
        '& .MuiDataGrid-columnsContainer': {
            background: '#8f8f8f26'
        }
    }
});

import { withRouter } from 'react-router-dom';

const action_to_color = {
    "CREATED": "green",
    "UPDATED": "orange",
    "ARCHIVED": "red",
    "RESTORED": "blue"
}

const columns = [
    { field: 'action_id', headerName: 'ID', minWidth: 100, headerComponentFramework: CustomFilter },
    { field: 'action_date', headerName: 'Date', minWidth: 200, headerComponentFramework: CustomFilter },
    {
        field: 'action', headerName: 'Action', minWidth: 150, headerComponentFramework: CustomFilter,
        cellRendererFramework: (params) => {
           return (<span style={{ color: action_to_color[params.value], fontWeight: 'bold' }}>{params.value}</span>)
        }
    },
    { field: 'field_name', headerName: 'Field Name', minWidth: 200, headerComponentFramework: CustomFilter },
    {
        field: 'previous_value', headerName: 'Previous value', minWidth: 200, headerComponentFramework: CustomFilter,
        cellRendererFramework: (params) => {
            // return Boolean(params.value) ? params.value : 'N/A'
            if (params.value?.length > 20) {
                return (
                    <Tooltip title={params.value} placement="top-start">
                        <span style={{ cursor: 'pointer' }}>{`${params.value?.slice(0, 21)}...`}</span>
                    </Tooltip>
                )
            } else {
                return (<span>{Boolean(params.value) ? params.value : 'N/A'}</span>)
            }
        },
    },
    {
        field: 'current_value', headerName: 'Current value', minWidth: 200, headerComponentFramework: CustomFilter,
        cellRendererFramework: (params) => {
            // return Boolean(params.value) ? params.value : 'N/A'
            if (params.value?.length > 20) {
                return (
                    <Tooltip title={params.value} placement="top-start">
                        <span style={{ cursor: 'pointer' }}>{`${params.value?.slice(0, 21)}...`}</span>
                    </Tooltip>
                )
            } else {
                return (<span>{Boolean(params.value) ? params.value : 'N/A'}</span>)
            }
        },
    },
    { field: 'name', headerName: 'Name', minWidth: 200, headerComponentFramework: CustomFilter },
    { field: 'user_role', headerName: 'Role', minWidth: 200, headerComponentFramework: CustomFilter },
    {
        field: 'description', headerName: 'Description', minWidth: 400, headerComponentFramework: CustomFilter,
        cellRendererFramework: (params) => {
            if (params.value?.length > 50) {
                return (
                    <Tooltip title={params.value} placement="top-start">
                        <span className='activitiesTab-descText'>{params.value}</span>
                    </Tooltip>
                )
            } else {
                return (<span>{params.value}</span>)
            }
        }
    }
    
];



function FilesTab({ assetDetails }) {
    const classes = useStyles();
    const [activities, setActivities] = useState([])

    const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

	const gridRef = useRef()
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
     
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };

    function formatActivities(data) {
        return data.map((item) => {
            return {
                id:item.id,
                action_id: `act${item.id}`,
                action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
                name: item.user_name,
                action: item.action_name,
                description: item.comment,
                previous_value: item.previous_data,
                current_value: item.current_data,
                user_role: item.user_role_name,
                field_name: item.action_field_name
            }
        })
    }

    useEffect(() => {
        if (!assetDetails) return

        axios.get(`/assets/fetch-asset-activities/${assetDetails.id}`).then((res) => {
            // console.log('res',res);
            setActivities(formatActivities(res.data.data.activities))
        }).catch((err) => {
            console.log('error', err);
        })
    }, [assetDetails])


    return (
        <>
            <div 
                className="ag-theme-alpine" 
                style={{
                width: "100%",
                height: windowHeight+105,
                fontSize:'12px'
                }}
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={activities}
                    animateRows
                    onGridReady = { onGridReady }
                    paginationPageSize={10}
                    pagination
                    defaultColDef={{
						resizable: true
					}}
                >
                    {columns.map(header => {
                        return(
                            <AgGridColumn 
                                key = {header.field} 
                                field = {header.field}
                                headerName = {header.headerName}  
                                width = { header.width }
                                minWidth = { header.minWidth }
                                maxWidth = { header.maxWidth }
                                sortable = {false}
                                flex = {1}
                                filter = "text"
                                wrapText
                                cellRendererFramework = { header.cellRendererFramework}
                                headerComponentFramework = {header.headerComponentFramework}								
                            />
                        )
                        
                    })}
                </AgGridReact>
            </div>
            {/* <div className={classes.root} style={{ height: 290, width: '100%',overflow:"auto" }}>
                <DataGrid
                    rows={activities}
                    columns={columns}
                    hideFooter={true}
                />
            </div> */}
        </>
    )
}

export default withRouter(FilesTab)
