import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch, useSelector } from 'react-redux';
import {Menu,MenuItem} from '@material-ui/core';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
// import SaveReportDialog from './saveReportDialog';
import AddCustomAttributes from './addCustomAttributes';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { usePermission } from '@fuse/hooks';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import SaveReportDialog from '../../reports/components/saveReportDialog';


const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [customAttribute, setCustomAttribute] = useState([]);
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [logs,setLogs] = useState([]);
	const [addButtonClick, setAddButtonClick] = useState(false);
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const { hasPermission } = usePermission()
	const viewModelAttributePermission = hasPermission('view-model_attribute')
	const createModelAttributePermission = hasPermission('add-model_attribute')
	const updateModelAttributePermission = hasPermission('update-model_attribute')
	const deleteModelAttributePermission = hasPermission('delete-model_attribute')
	const { open } = useSelector(({ modelCustomAttributeApp }) => modelCustomAttributeApp.formViewDialog);
	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			// getLogs()
		}
		setSelectedTab(value);
	};
	
	async function getModelDynamicAttributes(){
		// if(!viewManufacturersPermission){
		// 	return;
		// }
		try {
			const res = await axios.get('/model-custom-attributes');
			const { data } = res.data;
			setCustomAttribute(formatData(data));
		} catch (err) {
			console.log(err);
		}
	}

	function formatData(data){
		return data.map((item) => {
			return {
				attribute_name: item.attribute_name,
				model_attribute: item.attribute_name,
				source: item.source,
				id: item.id,
				original_info:{
					attribute_name: item.attribute_name,
				},
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
				]),
			}
		})
	}

	// function formatLogs(data){
	// 	return data.map((item)=>{
	// 		return {
	// 			id:item.id,
	// 			action_id: `act${item.id}`,
	// 			action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
	// 			attribute_name: item?.model_custom_attributes?.attribute_name || item.attribute_name,
	// 			name: item.user_name,
	// 			action: item.action_name,
	// 			description: item.comment,
	// 			previous_value: item.previous_data,
	// 			current_value: item.current_data,
	// 			field_name: item.action_field_name,
	// 			user_role: item.user_role_name
	// 		}
	// 	})
	// }

	// function getLogs(){
	// 	axios.get('/model-custom-attributes/activities')
	// 	.then(({data})=>{
	// 		setLogs(formatLogs(data.data.activities));
	// 	})
	// 	.catch(err=>{
	// 		console.log('cannot fetch logs');
	// 	})
	// }

	function callToast(success,error,value){
		if(success){
			toast.success(value)
		}
		if(error){
			toast.error(value);
		}
	}

	function refreshList(){
		getModelDynamicAttributes()
	}

	useEffect(()=>{
		setLoading(true)
		getModelDynamicAttributes().finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	} else {
		return (<>
			<SaveReportDialog tableName={"model attribute"}/>

			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				// header={
				// 	<DynamicFieldsHeader />
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label=" All Model Dynamic Attributes" />
				// 			{viewModelAttributePermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}
							
				// 		</Tabs>
				// 		<div>
				// 			<Menu
				// 				id="simple-menu"
				// 				anchorEl={anchorEl}
				// 				keepMounted
				// 				open={viewMenuOpened}
				// 				onClose={handleViewMenuClose}
				// 			>
				// 				{	
				// 					views.map((view,index) => {
				// 						return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
				// 					})
				// 				}
				// 			</Menu>
				// 		</div>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							{
								loading && <FuseLoading />
							}
							{
								!loading && <SalesOrderTable 
									endUsersAccounts={customAttribute}
									selectedView={selectedView}
									refresh={refreshList}
									viewModelAttributePermission = {viewModelAttributePermission}
									createModelAttributePermission = {createModelAttributePermission}
									updateModelAttributePermission = {updateModelAttributePermission}
									deleteModelAttributePermission = {deleteModelAttributePermission}
								/>
							}
						</div>
						{open && <AddCustomAttributes getModelDynamicAttributes = {getModelDynamicAttributes}/>}
						<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
							{/* <ActivitieLogs logs = {logs} /> */}
								{/* {Boolean(logs.length) ? <ActivityAgGridListing module='custom_attributes' logs={logs}/> : <FuseLoading/> } */}
						</div>
					</>
				}
				innerScroll
			/>
			
		</>)
	}
}

export default withReducer('modelCustomAttributeApp', reducer)(CardedFullWidth2TabbedSample);
