import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState,useEffect, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch,useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { isArray } from "lodash";
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
  const classes = useStyles();
	const table_name = "SUBSCRIPTION_STATUS";
	const router = useRouter();
	const dispatch = useDispatch();
	const gridRef = useRef();
	const toast = useToast(dispatch);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [editData, setEditData ]  = useState({});
	const [editButtonClick, setEditButtonClick ]  = useState(false);
	const [editDone, setEditDone ]  = useState(false);
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);
	
	const [subscriptionStatus, setSubscriptionStatus] = useState(null)
	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );

	const onGridReady = (params) =>{
		if(router.query.report_name == undefined) {
			dispatch(setfilterlist({}));
			// loadInitialState (params);
			dispatch(setrowGroupModel([]))
		}else{
			getReport(params);
		}
	}

	const onGridSizeChanged = ()=>{
		// setWindowHeight(window.innerHeight - 290);
	}
	
	function onFilterChanged (event) {
		const rows = event.api.getFilterModel();
		// to show No result found in column filter
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "subscription status";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
					const order = JSON.parse(data.order);
					const filter = JSON.parse(data?.filter);
          params?.columnApi.applyColumnState({ state: order });
					params.api.setFilterModel(filter);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

	function saveView(rows, filter) {
    if(isArray(rows) && !rows.length ) return
    let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 

    axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
  }

	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

  function onColumnVisible (params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row => {
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		const report_name = router.query.report_name;
		if(report_name == undefined){
			const rows = event.columnApi.getColumnState();
			const first_row = rows[0];
			saveView(rows);
			dispatch(setrowGroupModel(rows));
	
			// if(first_row.colId !== 'ag-Grid-AutoColumn'){
			// 	saveView(rows);
			// 	dispatch(setrowGroupModel([]));
			// }else{
			// 	dispatch(setrowGroupModel(rows));
			// }
		}
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

	const handleConfirm = (data) => {
		setSubscriptionStatus(data)
	}

	const handleClose = () => {
		setSubscriptionStatus(null)
	}

	function onCellValueChanged(e){
		let payload = {
			type:'software',
			status_name:e.data.status_name_,
			edit_id:Number(e.data.id),
			status_description: e.data.description,
		}
		if(!payload.status_name){
			toast.error('Status Name is required ')
			props.refresh()
			return
		}
		if(!payload.status_description){
			toast.error('Status Description is required ')
			props.refresh()
			return
		}
		// const payload = {
		// 	department_name:e.data.description,
		// 	edit_id:e.data.id,
		// }
		axios.post('/asset-status/edit?type=software', payload).then(response => {
			if(response.status == 201){
				toast.success(response.data.message)
				props.refresh()
			} else {
			//   onSuccess()
			  toast.success('Subscription Status updated Successfully.')
			  props.refresh()
			//   props.fetchAllHardwareAssets()
			}
		}).catch(err => {
		  console.log('212=>',err);
		  toast.error('Something went wrong!')
		})

	}
	
	function handleDelete(){
		axios.delete(`/asset-status/delete/${subscriptionStatus.id}?type=software`).then((res)=>{
			if(res.status == 201){
				toast.error(res.data.message)
				return
			} else {
				handleClose()
				toast.success(res.data.message)
				props.refresh()
			}
		}).catch((err)=>{
			console.log(err)
			toast.error(err.data.message);
		})
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function handelEdit(data){
		dispatch(setFromData({id:data.id,
			status_name:data.status_name,
			type:data.type,
			status_description: data.status_description,
			status_color: data.status_color,
		}))
		dispatch(openFormViewerDialog())
		
	}

	function editCloseForm(data){
		if(data){
			setEditDone(true)
			setEditButtonClick(false)
		}
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data
		);
	},[query_data])

	useEffect(() => {
		return () => {
				dispatch(setQuickFilter(null))
		}
	}, [])

	useEffect(()=>{
		onGridReady()
	},[props.endUsersAccounts])

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	//Logs
	function getLogs(){
		axios.get('/asset-status/sub-activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log(err)
			console.log('cannot fetch logs');
		})
	}

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				status_name: item?.subscription_status?.status_name || item.status_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
			}
		})
	}

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	return (
		<>	
			<AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'subscription_status'} logs={logs} displayTableName = {'Subscription Statuses'}
				 onClick={handleChildClick}
			/>	
			{(tabValue == 0) && <div className="ag-theme-alpine" style={{width: "100%",height:windowHeight+105,fontSize:'12px'}}>
				<AgGridReact
					ref={gridRef}
					rowData={props.endUsersAccounts}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowHeight = {30}
					onGridSizeChanged = { onGridSizeChanged }
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}
					
					// get filter model
					onFilterChanged = { onFilterChanged }		
					onColumnMoved = { onColumnMoved }	
					onColumnVisible = { onColumnVisible }
					onColumnPinned = { onColumnPinned }
					onSortChanged={onSortChanged}
					groupHeaderHeight ={1}
					frameworkComponents={frameworkComponents}
					floatingFiltersHeight = {40}

					//row grouping
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }
					// autoGroupColumnDef={{ minWidth: 5000 }}
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					pagination={true}
					defaultColDef={{
						resizable: true
					}}
				>
					<AgGridColumn 
						lockPosition={true}
						rowSelection={'single'}
						width={100}
						minWidth={100}
						maxWidth={100}
						suppressSizeToFit={true}
						headerName="Actions"
						pinned= 'right' 
						className="action_field"
						lockPinned={true}
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>
										{props.updateSStatusPermission && 
										<Tooltip id="edit" title="Edit" placement="top">
											<Icon 
												onClick={() => handelEdit(data) }
											style={{
												color: '#1d2939',
												paddingBottom: '5px',
												paddingRight:'30px',
												fontSize:'15px',
												cursor:'pointer'
											}}
											>
												edit
											</Icon>
										</Tooltip>}
										{props.deleteSStatusPermission && 
											<Tooltip id="delete" title="Delete" placement="top" >
												<Icon
													onClick={() => handleConfirm(data) }
													style={{
														color: '#1d2939',
														paddingBottom: '3px',
														paddingRight:'30px',
														fontSize:'15px',
														cursor:'pointer'
													}}
												>
													delete
												</Icon>
											</Tooltip>
										}
									</>
								)
							}else{
								return(
									<>
									</>
								)
							}
						}}
					>
					</AgGridColumn>
					<AgGridColumn 
						field="status_name_"
						headerName="Status Name"
						editable={props.updateSStatusPermission}
						onCellValueChanged = {onCellValueChanged}  
						sortable={ true }
						flex = {1} 
						minWidth={200}
						headerComponentFramework={CustomFilter}
						
						cellRendererFramework={(event) =>{
							if(event.colDef.field === "status_name_" ){
								return (
									<span>
										<span style={{
											height:'10px',
											width:'10px',
											marginTop: '2px',
											borderRadius: '50%',
											display: 'inline-block',
											backgroundColor: event.data?.status_color?.code
										}}></span>
										<span style={{marginLeft: '5px'}}>{event.data?.status_name_}</span>
									</span>
								)
							}
							else if(event.colDef.headerName == 'Group' && event.node.field == 'status_name_'){
								return <><span>{event.value}</span></>
							}
						}}
						filter="text"
					>
					</AgGridColumn>
					<AgGridColumn 
						field="description"
						headerName="Status Description"  
						editable={props.updateSStatusPermission}
						onCellValueChanged = {onCellValueChanged}
						sortable={ true }
						flex = {1} 
						minWidth={200}
						headerComponentFramework={CustomFilter}
						
						filter="text"
					>
					</AgGridColumn>
				</AgGridReact>
				<GeneralModal
					open={subscriptionStatus}
					title={'Delete Subscription Status'}
					text={<h4>Are you sure to delete this Subscription Status named as <b>{subscriptionStatus?.status_name}</b>?</h4>}
					handleClose={handleClose}
					handleConfirm={handleDelete}
				/>
			</div>}
		</>
	);
}

export default ListView
