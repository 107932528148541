import React from 'react'
import { Grid, Card, Box, Button } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { TextField, Paper } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {Icon} from '@material-ui/core';
import * as yup from 'yup';
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'


// import * as yup from 'yup';



const schema = yup.object().shape({
  // name: yup
  // 	.string()
  // 	.required('You must enter a product name')
  // 	.min(5, 'The product name must be at least 5 characters')
});


const Comp5 = ({ activeStep, setActiveStep, handleNext, handleBack }) => {
     const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(schema)
  });

  const { reset, watch, control, onChange, formState, handleSubmit, setValue } = methods;
  return (
    <>
      <div style={{position:"relative",height:"100%"}}>
         <Grid container className='p-5'>
        <Grid item lg={12} >
          <Box  p={1}>
            <div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
              <Controller
                name="address_1"
                className="mb-16"
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Location Name*'
                      fullWidth
                      inputProps={{ readOnly: false }}
                      // InputLabelProps={{ shrink: true }}
                      FormHelperTextProps={{
                        style: {
                          margin: 0,
                          backgroundColor: '#f4f4f4',
                          width: '100%',
                          paddingTop: '2px'
                        }
                      }}
                      style={{ backgroundColor: '#fff' }}

                    />
                  </>
                )}
              />
            </div>
            <div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
              <Controller
                name="address_1"
                className="mb-16"
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Street Address*'
                      fullWidth
                      inputProps={{ readOnly: false }}
                      // InputLabelProps={{ shrink: true }}
                      FormHelperTextProps={{
                        style: {
                          margin: 0,
                          backgroundColor: '#f4f4f4',
                          width: '100%',
                          paddingTop: '2px'
                        }
                      }}
                      style={{ backgroundColor: '#fff' }}

                    />
                  </>
                )}
              />
            </div>
             <Box className='flex justify-between'>
               <div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
              <Controller
                name="address_1"
                className="mb-16"
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant='outlined'
                      label='City*'
                      fullWidth
                      inputProps={{ readOnly: false }}
                      // InputLabelProps={{ shrink: true }}
                      FormHelperTextProps={{
                        style: {
                          margin: 0,
                          backgroundColor: '#f4f4f4',
                          width: '100%',
                          paddingTop: '2px'
                        }
                      }}
                      style={{ backgroundColor: '#fff' }}

                    />
                  </>
                )}
              />
            </div>
             <div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="country"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={countries}
													disablePortal
													onChange={(event, value) => field.onChange(value)}
													PaperComponent={({ children }) => (
														<Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
													)}
													getOptionLabel={option => option.name ?? ''}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	style={{ background: 'white' }}
																	{...params}
																	label="State*"

																	inputRef={ref}
																	variant="outlined"
																	fullWidth
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
          </Box>
           <Box className='flex justify-between'>
               <div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
              <Controller
                name="address_1"
                className="mb-16"
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Zip Code'
                      fullWidth
                      inputProps={{ readOnly: false }}
                      // InputLabelProps={{ shrink: true }}
                      FormHelperTextProps={{
                        style: {
                          margin: 0,
                          backgroundColor: '#f4f4f4',
                          width: '100%',
                          paddingTop: '2px'
                        }
                      }}
                      style={{ backgroundColor: '#fff' }}

                    />
                  </>
                )}
              />
            </div>
             <div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="country"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={countries}
													disablePortal
													onChange={(event, value) => field.onChange(value)}
													PaperComponent={({ children }) => (
														<Paper className="autocomplete_holder autocomplete_paper_holder">{children}</Paper>
													)}
													getOptionLabel={option => option.name ?? ''}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	style={{ background: 'white' }}
																	{...params}
																	label="Country*"

																	inputRef={ref}
																	variant="outlined"
																	fullWidth
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
          </Box>

            <Box className='flex justify-end'>
              <Button variant='outlined' className='wizard-screen-backBtn'>
              <Icon>
                <span class="material-symbols-outlined">
                  add
                </span>
              </Icon>
              Add More</Button>
            </Box>
          </Box>
          
        </Grid>
      </Grid>
      
     <Box className='w-full absolute bottom-1'>
       <Box className='w-full flex justify-between'>
         <Button variant='outlined' className='wizard-screen-backBtn' onClick={handleBack}>Go Back</Button>
      <Button variant='outlined' className='wizard-screen-nextBtn' onClick={handleNext}>Next</Button>
       </Box>
      </Box>
      </div>
    </>
  );
};

export default Comp5
