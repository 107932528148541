// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'typeface-poppins';
import './i18n';
import './react-chartjs-2-defaults';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import './styles/mapbox-style.css';
import App from 'app/App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import { LicenseManager } from 'ag-grid-enterprise';
import * as Sentry from "@sentry/react";
import { CaptureConsole, HttpClient, ExtraErrorData } from "@sentry/integrations";



Sentry.init({
    //The Data Source Name (DSN) key tells the Sentry SDK where to send events, ensuring they go to the right project.
    dsn: process.env.REACT_APP_SENTRY_DSN,
    //This variable controls the total amount of breadcrumbs that should be captured
    maxBreadcrumbs: 50,
    // If debug is enabled SDK will attempt to print out useful debugging information if something goes wrong with sending the event.
    // It's generally not recommended to turn it on in production, though turning debug mode on will not cause any safety concerns.
    debug: process.env.REACT_APP_SENTRY_DEBUG,
    //Environments help you better filter issues, releases values In ("local", "dev", "statging", "production")
    environment: process.env.REACT_APP_ENVIRONMENT,
    //This integration captures all Console API calls and redirects them to Sentry using the SDK's captureMessage or captureException call, depending on the log level
    integrations: [new ExtraErrorData(), new HttpClient()],
  });



// LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);
// LicenseManager.setLicenseKey('[TRIAL]_this_AG_Grid_Enterprise_key_( AG-043903 )_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_purchasing_a_production_key_please_contact_( info@ag-grid.com )___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_( 30 September 2023 )____[v2]_MTY5NjAyODQwMDAwMA==4a43d539f6521489ef3e796e6a5d1d09');
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-048883 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( DzAani (Dalos) )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Dalos )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Dalos )_need_to_be_licensed___( Dalos )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 5 November 2024 )____[v2]_MTczMDc2NDgwMDAwMA==05097ad2d66d970ea26c014e89a8a939');

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

