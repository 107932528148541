import { React } from "react";



function NoResultView({ moduleName }){
  return(
    <div className='no_data_holder'>
      <img src='assets/images/nodata.svg' alt='icon' />
      <p className='bold_txt'>It's all empty here!</p>
      <p className='normal_txt'>There is no {moduleName.toLowerCase()} to display</p>
    </div>
  )
}

export default NoResultView