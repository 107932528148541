import React from "react";

export default function ContractStatusComponent({ status }) {
  return (
        <>
            { status === "1" && 
                <span className="cstm_badge_holder enabled">
                    Enabled
                </span>
            }
            { status === "0" && 
                <span className="cstm_badge_holder disabled">
                    Disabled
                </span>
            }
        </>
    )
}