import _ from '@lodash';
import TablePagination from '@fuse/components/general/TablePagination';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Icon } from '@material-ui/core';
import ListView from './listView';
import GridView from './gridView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	  },
	  root: {
		// minWidth: 275,
	  },
	  title: {
		fontSize: 14,
	  },
	  pos: {
		marginBottom: 12,
	  },
	  buttonSquare: {
		borderRadius: 5,
	  },
});

function EndUsersAccountsTable(props) {
    const classes = useStyles();
	const table_name = props.table_name;
	let listItems = props.listItems;
								// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	function handleChangePage(event, value) {
		props.setPage(value);
		props.fetchItems(props.rowsPerPage, value, props.filters[props.currentFilter], props.currentSearch);
	}

	function handleChangeRowsPerPage(rowsPerPage) {
		props.setRowsPerPage(rowsPerPage);
		props.fetchItems(rowsPerPage, props.page, props.filters[props.currentFilter], props.currentSearch);
	}


	if(props.loading){
		return (<FuseLoading/>)
	}

	if(!listItems || listItems.length <= 0){
		return (
			<div className="w-full flex flex-col">
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-center h-full"
			>
				{ table_name == 'QUOTES' && <div style={{height: "200px"}}>
				<Typography variant="body2" component="p" color="textSecondary">
				No data found
				</Typography>
				</div>}
				{props.addLineItemBtnText && <>
				{props.addedLineItem == null  ?
					<>
						<Button
						variant="contained"
						color="primary"
						className={`${classes.buttonSquare}`}
						startIcon={<Icon>add</Icon>}
						onClick={()=>{props.addLineItems(()=>{})}}
						>
							{props.addLineItemBtnText}
						</Button>
					</>
					:
					<>
					<Button
					variant="contained"
					color="primary"
					className={`${classes.buttonSquare}`}
					startIcon={<Icon>save</Icon>}
					onClick={()=>{setSaving(true);props.onSaveLineItem()}}
					>
						Save
					</Button>
					<Button
					variant="contained"
					color="primary"
					className={`${classes.buttonSquare} ml-10`}
					startIcon={<Icon>cancel</Icon>}
					onClick={()=>{
						props.removeAddedLineItems(()=>{});
					}}
					>
						Cancel
					</Button>
					</>
					}
					</>
				} 
			</motion.div>
				{/* <div className={`w-full p-10`}>
					<TablePagination
						className="flex-shrink-0 border-t-1 m-5"
						component="div"
						count={props.totalRecords}
						rowsPerPage={props.rowsPerPage}
						page={props.page}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
					</div> */}
			</div>
		)
	}

	return (
		<div className="w-full flex flex-col">

			<SwitchView selectedView={props.selectedView} listItems={listItems}
						onSelectionChanged={props.onSelectionChanged}
						onRowSelected={props.onRowSelected}
						onRowClick={props.onRowClick}
						quote_id={props.quote_id}
						setEditedLineItem={props.setEditedLineItem}
						grid={props.grid}
						checkRowsSelectedInGrid={props.checkRowsSelectedInGrid}
						table_name = { table_name }
						setCurrentFilter={props.setCurrentFilter}
						setCurrentSearch={props.setCurrentSearch}
						/>
			
			<Grid container className={`p-10`}>
				<Grid item xs>
					<TablePagination
						className="flex-shrink-0 mt-10"
						component="div"
						count={props.totalRecords}
						returnExact={true}
						rowsPerPage={props.rowsPerPage}
						page={props.page}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Grid>
			</Grid>
		</div>
	);

}

function SwitchView({listItems,selectedView, onRowSelected, onSelectionChanged, onRowClick, quote_id, setEditedLineItem, grid, checkRowsSelectedInGrid,table_name, setCurrentFilter, setCurrentSearch
}) {
	if(selectedView == 0){
		return(<ListView listItems={listItems} 
			onRowSelected={onRowSelected}
			onSelectionChanged={onSelectionChanged}
			onRowClick={onRowClick}
			setEditedLineItem={setEditedLineItem}
			quote_id={quote_id}
			grid={grid}
			checkRowsSelectedInGrid={checkRowsSelectedInGrid}
			table_name = { table_name }
			setCurrentFilter={setCurrentFilter}
			setCurrentSearch={setCurrentSearch}
			/>)
	}else{
		return(<GridView listItems={listItems} onRowClick={onRowClick}
		/>)		
	}
}

export default withRouter(EndUsersAccountsTable)
