import React, {useEffect, useState} from 'react';
import Drawer from '@fuse/components/general/Drawer';
import axios from "@fuse/utils/axios";

import { makeStyles, Button, Icon, IconButton, Box, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles(theme => {
    console.log(theme)
    return {
        tableHeader: {
            background: theme.palette.action.hover
        },
        boldCell: {
            fontWeight: 450
        },
        cellBorderLeft: {
            borderLeft: `1px solid ${theme.palette.action.hover}`
        }
    }
})

export default function PriceSummary(props) {
    const classes = useStyles()

    const {
        quoteId, onConfirm = () => {}, open = false, confirming, onClose
    } = props

    const [loading, setLoading] = useState(false)
    const [summary, setSummary] = useState({})

    function fetchPriceSummary(){
        if(quoteId){
            setLoading(true);
            axios.get(`/quotes/${quoteId}/price-summary`)
            .then(res => {
                const {statusCode, data} = res.data;
                if(statusCode === 200){
                    setSummary(data)
                }
            })
            .catch(err => console.log(err))
            .finally(() => {setLoading(false)})
        }
    }

    function toNumberFormat(numberString){
		const number = Number(numberString);
		let fixed = number.toFixed(2);
		let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
		let [formatedNumber] = number.toLocaleString().split(".");
		const result = `${formatedNumber}.${secondNumber}`;
		return result
	}

    useEffect(() => {
        if(open){
            fetchPriceSummary()
        }
    }, [open])

  return (
    <Drawer
    {...props}
    anchor="right"
    >
        <div style={{maxWidth: 1000, padding: '20px 20px 0 20px', display: 'flex', flexDirection: 'column', height: '90%'}}>
        <div style={{fontSize: 20, fontWeight: 'bold', marginBottom: 20, display: 'flex', justifyContent: 'space-between'}}>
            <span>
                <IconButton
                color="primary"
                disabled={true}
                >
                    <Icon>attach_money</Icon>
                </IconButton> Price summary
            </span>
            <span>
            <IconButton
            color="primary"
            variant="text"
            onClick={onClose}
            size="small"
            >
                <Icon>close</Icon>
            </IconButton>
            </span>
        </div>
        {loading ?
            <Loading />
            :
            <>
            <div style={{flex: 1}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow className={`${classes.tableHeader}`}>
                            <TableCell>Type</TableCell>
                            <TableCell className={`${classes.cellBorderLeft}`}>Qty</TableCell>
                            <TableCell className={`${classes.cellBorderLeft}`}>MSRP</TableCell>
                            <TableCell className={`${classes.cellBorderLeft}`}>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {Object.entries(summary).map(([type, {
                        total_sell_price = 0,
                        total_msrp = 0,
                        total_qty = 0
                    }]) => {
                        const modType = type.split('_').join(' ')
                        return (
                        <TableRow>
                            <TableCell className={`${type === 'total' ? classes.boldCell : ''}`}>{`${modType.charAt(0).toUpperCase()}${modType.substring(1)}`}</TableCell>
                            <TableCell className={`${classes.cellBorderLeft} ${type === 'total' ? classes.boldCell : ''}`}>{total_qty}</TableCell>
                            <TableCell className={`${classes.cellBorderLeft} ${type === 'total' ? classes.boldCell : ''}`}>${toNumberFormat(total_msrp)}</TableCell>
                            <TableCell className={`${classes.cellBorderLeft} ${type === 'total' ? classes.boldCell : ''}`}>${toNumberFormat(total_sell_price)}</TableCell>
                        </TableRow>
                        )
                    })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
            {/* <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
                <Button
                    onClick={onConfirm}
                    variant='contained'
                    color="primary"
                    style={{width: 100}}
                    disabled={confirming}
                    size="small"
                >
                    <Icon style={{marginRight: 5}}>done</Icon>Finish
                </Button>
            </div> */}
            </>
        }
        </div>
    </Drawer>
  );
}

function Loading(){
    return(
        <Box style={{flex: 1,display: 'flex',width: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress size={20}/>
            <span style={{marginLeft: 10}}>Please wait</span>
        </Box>
    )
}
