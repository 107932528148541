const initialState = {
	open: false,
	quickAddServiceLevelAgreement: false
}

const actionTypes = {
    OPEN_REPORT_VIEWER_DIALOG: "OPEN_REPORT_VIEWER_DIALOG",
    CLOSE_REPORT_VIEWER_DIALOG: "CLOSE_REPORT_VIEWER_DIALOG",
	QUICK_ADD_SERVICE_PROVIDER: "QUICK_ADD_SERVICE_PROVIDER"
}

export function openReportViewerDialog(){
    return {
        type: actionTypes.OPEN_REPORT_VIEWER_DIALOG,
    }
}

export function closeReportViewerDialog(){
    return {
        type: actionTypes.CLOSE_REPORT_VIEWER_DIALOG,
    }
}


export function quickAddServiceLevelAgreement () {
	return {
		type: actionTypes.QUICK_ADD_SERVICE_PROVIDER
	}
}

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.OPEN_REPORT_VIEWER_DIALOG:{
			return { 
				...state,
				open: true,
			}
		}
		case actionTypes.CLOSE_REPORT_VIEWER_DIALOG:{
			return { 
				...state,
				open: false,
			}
		}
		case actionTypes.QUICK_ADD_SERVICE_PROVIDER: {
			return { 
				...state,
				quickAddServiceLevelAgreement: !state.quickAddServiceLevelAgreement,
			}
		}
	  default:
		return state
	}
}