import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField, SvgIcon } from '@material-ui/core';
import { useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { setAssetQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { UploadIcon } from '@fuse/icons';
import { openCsvDialog } from '../store/csvDialogReducer';
import { openImageDialog } from '../store/imageDialogReducer';
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { useDebounce } from '@fuse/hooks'
import axios from "@fuse/utils/axios";
import { exportToCSV } from "@fuse/utils/generateCsv";

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {

	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	let suggRef = useRef(null);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)

	const { hasPermission } = usePermission()
	const addReportPermission = hasPermission('add-report')
	const viewAssetPermission = hasPermission('view-assets',true)
	const createAssetPermission = hasPermission('add-assets',true)
	const updateAssetPermission = hasPermission('update-assets',true)
	const deleteAssetPermission = hasPermission('delete-assets',true)



	async function getAssetDetails(){
    const defaultHeader = ['Serial Number', 'Asset Tag', 'Asset Name', 'User', 'Status',
		'Contract Number', 'Contract Status', 'Model Name', 'Model Number', 'Category', 'Manufacture',
		'Location', 'Address', 'Notes', 'Created Date', 'Updated Date', 'Source', 'Network Status'
	]
	// const keys = [
	// 	'serial_number', 'asset_tag', 'asset_name', 'user_name', 'asset_status_name', 'contract_number', 
	// 'contract_status_name', 'model_name', 'model_no', 'category_name', 'manufacturer_name', 
	// 'location_name', 'address', 'notes', 'created_at', 'updated_at', 'source', 'connection_status'
	// ]
	let csvArr = [defaultHeader]
    const { results } = (await axios.get(`assets/all-asset-for-csv`)).data.data
		for(let obj of results){
			csvArr.push((Object.values(obj)).slice(0, -1))
		}
		// console.log(data.data.data.results[0])
		// console.log(Object.values(data.data.data.results[0]))
		exportToCSV(csvArr, 'asset_csv')

  }



	const handleDebouncedInputValue = useDebounce(e => {
		dispatch(setAssetQuickFilter(e.target.value))
	}, 250);

	return (
		<div className="flex flex-1 w-full items-center justify-between">
		</div>
	);
}

export default DynamicFieldsHeader;
