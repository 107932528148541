import useToast from '@fuse/hooks/useToast'
import { Button, 
  DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Typography,
  Drawer ,TextareaAutosize
} from '@material-ui/core'
import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ColorPicker from '@fuse/components/ColorPicker'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	subSection: {
		marginLeft:'20px',
		marginRight:'20px',
		marginBottom:'20px',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
	drawerWidth: {
		width: 600
	},
}))

function AddManufactureDialog(props) {
	const classes = useStyles()
	const [color, setColor] = useState({name:'black',code:"#000000"});
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.subStatusApp.formViewDialog)
	const { data:editFromState } = useSelector(({subStatusApp}) => subStatusApp.formViewDialog);

	//form validation
	const schema = yup.object({
		// status: yup.string().required('Status is required!'),
		// status_description: yup.string().required('Description is required!'),
	})

	const defaultColor = {name:'black',code:"#000000"}

	const defaultValues = {
		status:'',
		status_description: ''
	}

	const onCancel = () => {
		reset(defaultValues)
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		dispatch(closeFormViewerDialog())
		dispatch(closeFormViewerDialog())
		setColor(defaultColor)
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setColor(defaultColor)
	}
	
	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(()=>{
		if(editFromState){
			console.log("editFromState", editFromState)
			setValue('status',editFromState.status_name);
			setValue('status_description',editFromState.status_description)
			setColor(editFromState.status_color)
		}
	},[editFromState])

	const { errors } = formState

	function editAssetStatus(payload){
		axios.post(`/asset-status/edit?type=software`, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				closeDialog()
				toast.success('Subscription Status updated Successfully.')
				dispatch(setQuickFilter(null))
				if(props.getAssetStatus){
				props.getAssetStatus()
				}
			}
		}).catch(err => {
			console.log('212=>',err);
			toast.error('Something went wrong!')
		})
	}

	const manageFormData = (formData) => {
		let payload = {
			entity_external_platform_id:String(Math.trunc(Math.random()*20000)),
			type: 'software',
			status_name:formData.status,
			status_description: formData.status_description || '',
			status_color:color
		}
		if (editFromState) {
			let payload = {
				type:'software',
				status_name:formData.status,
				edit_id:Number(editFromState.id),
				status_description: formData.status_description || '',
				status_color: color
			}
			editAssetStatus(payload)
		} else {
			axios.post('/asset-status/add', payload)
			.then(response => {
				if(response.status == 201){
					toast.error(response.data.message)
				} else {
					reset(defaultValues)
					closeDialog()
					toast.success(response.data.message)
					dispatch(setQuickFilter(null))
					console.log(props)
					if (props.getAssetStatus) {
						props.getAssetStatus()
					}
				}
			}).catch(err => {
				console.log('212=>',err);
				toast.error('Something went wrong!')
			})
		}		
	}
	const errorData = (error) => {
		console.log('363 =>',error)
	}

	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{Boolean(editFromState) ? 'Update' : 'Add'} Subscription Status</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

				{/*--------------------------Item Information-------------------------- */}
					
				<div className={classes.subSection}>
					<div style={{ width: '100%' }}>
					<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
						<Divider style={{marginBottom: '10px'}}/>
					</div>

					<div >
						<div style={{ display: 'flex', justifyContent:'center' }}>
							<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
								<Controller
									name='status' 
									control={control}
									render={( { field} ) => (
									<>
										<TextField 
											{...field}
											style={{background: 'white'}}											
											variant='outlined'
											label='Subscription Status Name' 
											autoFocus
											fullWidth
											required
											// error={!!errors.status}
											// helperText={errors?.status?.message}
											// FormHelperTextProps={{
											// 	style: { 
											// 		margin : 0, 
											// 		backgroundColor: '#f4f4f4',
											// 		width: '100%',
											// 		paddingTop: '2px'
											// 	}
											// }}
										/>
									</>
									) }
								/>
							</div> 
						</div>
					</div>		

					<div style={{ display: 'flex', justifyContent:'center' }}>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name='status_description' 
								control={control}
								render={( { field} ) => (
									<>
										<TextareaAutosize 
											{...field}
											style={{background: 'white',width: '100%',minHeight: '20%',border: 'solid 2px #c8c5c5',padding: '9px'}}
											variant='outlined' 
											label='Status Description' 
											fullWidth
											placeholder="Status Description"
											// required
										/>
									</>
								)}
							/>
						</div> 
					</div>

					<div style={{ display: 'flex', justifyContent:'center' }}>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%',display:'flex',alignItems:'center' }}>
							<Typography style={{paddingRight: '10px',fontSize:'16px'}}>Choose Color</Typography>
							<ColorPicker setColor={setColor} color={color}/>
						</div> 
					</div>
				</div>

				<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
					<Button 
						className={classes.endButtons}
						style={{marginRight: '10px'}}
						type='submit' variant='contained'
						color='primary'
					>
						{editFromState ? 'Update' : 'Add'}
					</Button>
					<Button 
						className={classes.endButtons} 
						variant='outlined'
						color='primary'
						onClick={ onCancel }
					>
						Cancel
					</Button>
				</div>
				</form>        
			</Drawer>
		</div>
	)
}

export default AddManufactureDialog
