import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import Tooltip from '@material-ui/core/Tooltip';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import DeleteConfirmModal from 'helper/DeleteConfirm';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
    const classes = useStyles();
	const table_name = "contract_status";
	const router = useRouter();
	const dispatch = useDispatch();
	const gridRef = useRef();
	const toast = useToast(dispatch);
	// const rowGroupPanelShow = 'always';
	const skipHeader = [ 'id', 'status_color' ]
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [contractStatusDetails, setContractStatusDetails] = useState({})
	const [logs,setLogs] = useState([]);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const quick_add_contract_status = useSelector((state) => state.contractStatusApp.reportViewDialogReducer.quickContractStatusAdded);
	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const tableHeaders = [
		"contract_tag_name", "tag_description"
	];
	const handleClickOpen = () => {
		setOpenDeleteDialog(true);
	};

	function setHeaderName(header){
		if(header == 'model_no'){
			return 'Model Number'
		}
		return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const handleClose = () => {
		setOpenDeleteDialog(false);
	};

	const handleConfirm = (data) => {
		setOpenDeleteDialog(true)
		setContractStatusDetails(data)
	}

	const onGridReady = (params) =>{
		if(router.query.report_name == undefined) {
			dispatch(setfilterlist({}));
			// loadInitialState (params);
			dispatch(setrowGroupModel([]))
		}else{
			getReport(params);
		}
	}

	const onGridSizeChanged = ()=>{
		// setWindowHeight(window.innerHeight - 290);
	}

	function handleAddContractStatus () {
		dispatch(openFormViewerDialog())
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		// to show No result found in column filter
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "contract tag";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
          const order = JSON.parse(data.order);
		  const filter = JSON.parse(data.filter);
          params?.columnApi.applyColumnState({ state: order });
		  params?.api.setFilterModel(filter);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  	function saveView(rows, filter) {
		let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }


	function onColumnRowGroupChanged(event){
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}	
	}

	function onCellValueChanged(e){
		let payload = {
			contractStatus: e.data.contract_tag_name,
			id:e.data.id,
		}
		if(!payload.contractStatus){
			toast.error('Contract Tag Name is required')
			props.refresh()
			return
		}
		axios.put('/contract-status/update', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
			//   onSuccess()
			  toast.success('Contract Tag updated successfully!')
			  props.refresh()
			//   props.fetchAllHardwareAssets()
			}
		}).catch(err => {
		  console.log('212=>',err);
		  toast.error('Something went wrong!')
		})
	}	

	function handelEdit(data){
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}


	

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	const handleChildClick = (value) => {
		setTabValue(value)
  	};

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				status_name: item?.contract_status?.contract_status_name || item.contract_status_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment.replace('contract status', 'contract tag'),
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name === 'Contract Status Name' ? 'Contract Tag Name' : item.action_field_name === 'Status Description' ? 'Tag Description' : item.action_field_name,
				user_role: item.user_role_name
			}
		})
	}

	function getLogs(){
		axios.get('/contract-status/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])


	useEffect( () => {
		console.log("AGENTINA WORLD CUP")
		props.refresh()
	}, [quick_add_contract_status])

	useEffect(()=>{
		onGridReady()
		return () => {
			dispatch(setQuickFilter(null))
		}
	},[props.endUsersAccounts])

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	function handelDelete(){
		axios.delete(`/contract-status/delete/${contractStatusDetails?.id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message)
			}else{
				toast.success('Contract Tag deleted successfully')
				handleClose()
				props.refresh()
			}
		}).catch((err)=>{
			console.log('err',err);
			toast.error('Something went wrong!');
		})
	}
	return (			
		<>
			<AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList ={ groupByArr }
				tableName={'contract_tag'}
				logs={logs}
				displayTableName = {'Contract Tag'}
				onClick={handleChildClick}
				onClickAddButton={handleAddContractStatus}
				addPermission = {props.createContractStatusPermission}
			/>
			{(tabValue == 0) &&
				<div className="ag-theme-alpine" style={{width: "100%",height:windowHeight+105,fontSize:'11px'}}>
					<AgGridReact
						ref={gridRef}
						rowData={props.endUsersAccounts}
						onGridReady = { onGridReady }
						animateRows = { true }
						rowHeight = {30}
						onGridSizeChanged = { onGridSizeChanged }
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						defaultColDef={{
							resizable: true
						}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}
						
						// get filter model
						onFilterChanged = { onFilterChanged }		
						onColumnMoved = { onColumnMoved }	
						onColumnVisible = { onColumnVisible }
						onColumnPinned = { onColumnPinned }
						onSortChanged={onSortChanged}
						groupHeaderHeight ={1}
						// headerHeight = {15}
						floatingFiltersHeight = {40}

						//row grouping
						showOpenedGroup={true}
						suppressDragLeaveHidesColumns={true}
						suppressMakeColumnVisibleAfterUnGroup={true}
						rowGroupPanelShow={ rowGroupPanelShow }
						onColumnRowGroupChanged = { onColumnRowGroupChanged }
						frameworkComponents={frameworkComponents}
						pagination={true}
					>
						<AgGridColumn
							headerName="Actions"
							lockPosition={true}
							rowSelection={'single'}
							width={100}
              minWidth={100}
              maxWidth={100}
							suppressSizeToFit={true}
							pinned= 'right' 
							className="action_field"
							lockPinned={true}
							cellRendererFramework={({data}) =>{
								if(data){
									return (
										<>
										{/* props.updateModelPermission && */}
										{/* props.deleteModelPermission && */}
										{ props.updateContractStatusPermission && <Tooltip id="edit" title="Edit" placement="top"
										>
											{/* <Icon onClick={() => handelEdit(data) }
												style={{
													color: '#1d2939',
													paddingBottom: '5px',
													paddingRight:'30px',
													fontSize:'15px',
													cursor:'pointer'
												}}
											>edit</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-pencil" onClick={() => handelEdit(data)} />
											</Tooltip>
										}
											{props.deleteContractStatusPermission  && <Tooltip id="delete" title="Delete" placement="top" >
												{/* <Icon
												onClick={() => handleConfirm(data) }
												style={{
													color: '#1d2939',
													paddingBottom: '3px',
													paddingRight:'30px',
													fontSize:'15px',
													cursor:'pointer'
												}}>delete</Icon> */}
												<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-trash" onClick={() => handleConfirm(data)} />
											</Tooltip>}
										</>
									)
								}else{
									return (<></>)
								}
							}}
						>
						</AgGridColumn>
						{
							tableHeaders.map((header)=>{
								if(!skipHeader.includes(header)){
									return(
										<AgGridColumn 
											field={header}
											key= {header} 
											editable={ props.updateContractStatusPermission}
											onCellValueChanged = {onCellValueChanged}
											headerName={ setHeaderName(header) } 
											sortable={ true } filter = "text" flex = {1}
											minWidth={200}
											headerComponentFramework={CustomFilter}
											// floatingFilter = { true }
											
											cellRendererFramework={(event) =>{
												if(event.colDef.field === "contract_tag_name" ){
													return (
														<span>
															<span style={{
																height:'10px',
																width:'10px',
																marginTop: '2px',
																borderRadius: '50%',
																display: 'inline-block',
																backgroundColor: event.data?.status_color?.rgba || event.data?.status_color?.code
															}}></span>
															<span style={{marginLeft: '5px'}}>{event.data?.contract_tag_name}</span>
														</span>
													)
												}else if(event.colDef.field === "tag_description"){
													return <>{event.data?.tag_description}</>
												}else if(event.colDef.headerName == 'Group' && event.node.field == 'contract_tag_name'){
													return <><span>{event.value}</span></>
												}else if (event.colDef.headerName == 'Group' && event.node.field == 'tag_description'){
													return(<><span>{event.value}</span></>)
												}
											}}
										></AgGridColumn>
									)
								}
							})
						}
					</AgGridReact>
				</div>
			}
			{/* <GeneralModal
				open={openDeleteDialog}
				title={'Delete Contract Status'}
				text={<h4>Are you sure to delete this Contract Status named as <b>{contractStatusDetails?.contract_status_name}</b>?</h4>}
				handleConfirm={handelDelete}
				handleClose={handleClose}
			/> */}
			{
				openDeleteDialog && 

				<DeleteConfirmModal 
					openAlerModal={openDeleteDialog}
					module={'Contract Tag'}
					handleCloseAlertModal={handleClose} 
					handleAlertModal={handelDelete}
				/>
			}
		</>
	);
}

export default ListView
