import Page from './index';

export default {
	settings: {
		layout: {
			config: {
				footer:{
					display:false
				}
			}
		}
	},
	routes: [
		{
			path: '/profile-settings',
			exact: true,
			component: Page.Profile
		}
	]
};
