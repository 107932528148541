import { combineReducers } from '@reduxjs/toolkit';
import dialogReducer from './dialogReducer';
import csvDialogReducer from './csvDialogReducer';
import imageDialogReducer from './imageDialogReducer';
import formViewDialog from './formViewDialogReducer';
import reportViewDialogReducer from './reportViewDialogReducer';
import filterParamsReducer from './filterParamsReducer';

const reducer = combineReducers({
	dialogReducer,
	csvDialogReducer,
	imageDialogReducer,
	reportViewDialogReducer,
	filterParamsReducer,
	formViewDialog
});

export default reducer;
