exports.countries = [ 
    {"name": "United States", "code": "US", "currency": "USD" , "phone": "+1"},  
    {"name": "Canada", "code": "CA", "currency":"CAD", "phone": "+1"}, 
    {"name": "Afghanistan", "code": "AF", "currency": "AFN" , "phone": "+93"},
    {"name": "Albania", "code": "AL", "currency":"ALL", "phone": "+355"}, 
    {"name": "Algeria", "code": "DZ", "currency":"DZD", "phone": "+213"},
    {"name": "American Samoa", "code": "AS", "currency":"USD", "phone": "+1684"},
    {"name": "AndorrA", "code": "AD", "currency":"EUR", "phone": "+376"}, 
    {"name": "Angola", "code": "AO", "currency":"AOA", "phone": "+244"}, 
    {"name": "Anguilla", "code": "AI", "currency":"XCD", "phone": "+1264"},
    {"name": "Antigua and Barbuda", "code": "AG", "currency":"XCD", "phone": "+1268"}, 
    {"name": "Argentina", "code": "AR", "currency":"ARS", "phone": "+54"}, 
    {"name": "Armenia", "code": "AM", "currency":"AMD", "phone": "+374"},
    {"name": "Aruba", "code": "AW", "currency":"AWG", "phone": "+297"},
    {"name": "Australia", "code": "AU", "currency":"AUD", "phone": "+61"},
    {"name": "Austria", "code": "AT", "currency":"EUR", "phone": "+43"}, 
    {"name": "Azerbaijan", "code": "AZ", "currency":"AZN", "phone": "+994"}, 
    {"name": "Bahamas", "code": "BS", "currency":"BSD", "phone": "+1242"}, 
    {"name": "Bahrain", "code": "BH", "currency":"BHD", "phone": "+973"}, 
    {"name": "Bangladesh", "code": "BD", "currency":"BDT", "phone": "+880"}, 
    {"name": "Barbados", "code": "BB", "currency":"BBD", "phone": "+1246"}, 
    {"name": "Belarus", "code": "BY","currency":"BYN", "phone": "+375"}, 
    {"name": "Belgium", "code": "BE", "currency":"EUR", "phone": "+32"},
    {"name": "Belize", "code": "BZ", "currency":"BZD", "phone": "+501"}, 
    {"name": "Benin", "code": "BJ", "currency":"XOF", "phone": "+229"}, 
    {"name": "Bermuda", "code": "BM", "currency":"BMD", "phone": "+1441"}, 
    {"name": "Bhutan", "code": "BT", "currency":"BTN", "phone": "+975"}, 
    {"name": "Bolivia", "code": "BO", "currency":"BOV", "phone": "+591"}, 
    {"name": "Bosnia and Herzegovina", "code": "BA", "currency":"BAM", "phone": "+387"}, 
    {"name": "Botswana", "code": "BW", "currency":"BWP", "phone": "+267"},
    {"name": "Bouvet Island", "code": "BV", "currency":"NOK", "phone": ""}, 
    {"name": "Brazil", "code": "BR", "currency":"BRL", "phone": "+55"},
    {"name": "British Indian Ocean Territory", "code": "IO", "currency":"USD", "phone": ""}, 
    {"name": "Brunei Darussalam", "code": "BN", "currency":"BND", "phone": "+673"}, 
    {"name": "Bulgaria", "code": "BG", "currency":"BGN", "phone": "+359"}, 
    {"name": "Burkina Faso", "code": "BF", "currency":"XOF", "phone": "+226"}, 
    {"name": "Burundi", "code": "BI", "currency":"BIF", "phone": "+257"}, 
    {"name": "Cambodia", "code": "KH", "currency":"KHR", "phone": "+855"}, 
    {"name": "Cameroon", "code": "CM", "currency":"XAF", "phone": "+237"}, 
    {"name": "Cape Verde", "code": "CV", "currency":"CVE", "phone": "+238"}, 
    {"name": "Cayman Islands", "code": "KY", "currency":"KYD", "phone": "+1345"}, 
    {"name": "Central African Republic", "code": "CF", "currency":"XAF", "phone": "+236"}, 
    {"name": "Chad", "code": "TD", "currency":"XAF", "phone": "+235"}, 
    {"name": "Chile", "code": "CL", "currency":"CLP", "phone": "+56"}, 
    {"name": "China", "code": "CN","currency":"CNY", "phone": "+86"}, 
    {"name": "Christmas Island", "code": "CX", "currency":"AUD", "phone": "+61"}, 
    {"name": "Cocos (Keeling) Islands", "code": "CC", "currency":"AUD", "phone": "+61"}, 
    {"name": "Colombia", "code": "CO", "currency":"COP", "phone": "+57"}, 
    {"name": "Comoros", "code": "KM", "currency":"KMF", "phone": "+269"},
    {"name": "Congo", "code": "CG", "currency":"XAF", "phone": "+242"}, 
    {"name": "Congo, The Democratic Republic of the", "code": "CD", "currency":"CDF", "phone": "+243"}, 
    {"name": "Cook Islands", "code": "CK", "currency":"NZD", "phone": "+682"}, 
    {"name": "Costa Rica", "code": "CR", "currency":"CRC", "phone": "+506"},
    {"name": "Croatia", "code": "HR", "currency":"HRK", "phone": "+385"},
    {"name": "Cyprus", "code": "CY", "currency":"EUR", "phone": "+357"}, 
    {"name": "Czech Republic", "code": "CZ", "currency":"CZK", "phone": "+420"}, 
    {"name": "Denmark", "code": "DK", "currency":"DKK", "phone": "+45"}, 
    {"name": "Djibouti", "code": "DJ", "currency":"DJF", "phone": "+253"}, 
    {"name": "Dominica", "code": "DM", "currency":"XCD", "phone": "+1767"}, 
    {"name": "Dominican Republic", "code": "DO", "currency":"DOP", "phone": "+1829"}, 
    {"name": "Ecuador", "code": "EC", "currency":"USD", "phone": "+593"}, 
    {"name": "Egypt", "code": "EG", "currency":"EGP", "phone": "+20"}, 
    {"name": "El Salvador", "code": "SV", "currency":"SVC", "phone": "+503"}, 
    {"name": "Equatorial Guinea", "code": "GQ", "currency":"XAF", "phone": "+240"}, 
    {"name": "Eritrea", "code": "ER", "currency":"ERN", "phone": "+291"}, 
    {"name": "Estonia", "code": "EE", "currency":"EUR", "phone": "+372"}, 
    {"name": "Ethiopia", "code": "ET", "currency":"ETB", "phone": "+251"}, 
    {"name": "Falkland Islands (Malvinas)", "code": "FK", "currency":"FKP", "phone": "+500"}, 
    {"name": "Faroe Islands", "code": "FO", "currency":"DKK", "phone": "+298"}, 
    {"name": "Fiji", "code": "FJ", "currency":"FJD", "phone": "+679"}, 
    {"name": "Finland", "code": "FI", "currency":"EUR", "phone": "+358"}, 
    {"name": "France", "code": "FR", "currency":"EUR", "phone": "+33"}, 
    {"name": "French Guiana", "code": "GF", "currency":"EUR", "phone": "+594"}, 
    {"name": "French Polynesia", "code": "PF", "currency":"XPF", "phone": "+689"},
    {"name": "French Southern Territories", "code": "TF", "currency":"EUR","phone": ""},
    {"name": "Gabon", "code": "GA", "currency":"XAF", "phone": "+241"}, 
    {"name": "Gambia", "code": "GM", "currency":"GMD", "phone": "+220"}, 
    {"name": "Georgia", "code": "GE", "currency":"GEL", "phone": "+995"}, 
    {"name": "Germany", "code": "DE", "currency":"EURO", "phone": "+49"}, 
    {"name": "Ghana", "code": "GH", "currency":"GHS", "phone": "+233"}, 
    {"name": "Gibraltar", "code": "GI", "currency":"GIP", "phone": "+350"}, 
    {"name": "Greece", "code": "GR", "currency":"EURO", "phone": "+30"}, 
    {"name": "Greenland", "code": "GL", "currency":"DKK", "phone": "+229"}, 
    {"name": "Grenada", "code": "GD", "currency":"XCD", "phone": "+1473"}, 
    {"name": "Guadeloupe", "code": "GP", "currency":"EUR", "phone": "+590"}, 
    {"name": "Guam", "code": "GU", "currency":"USD", "phone": "+1671"}, 
    {"name": "Guatemala", "code": "GT", "currency":"GTQ", "phone": "+502"}, 
    {"name": "Guernsey", "code": "GG", "currency":"GBP", "phone": "+44"}, 
    {"name": "Guinea", "code": "GN", "currency":"GNF", "phone": "+224"}, 
    {"name": "Guinea-Bissau", "code": "GW", "currency":"XOF", "phone": "+245"}, 
    {"name": "Guyana", "code": "GY", "currency":"GYD", "phone": "+592"}, 
    {"name": "Haiti", "code": "HT", "currency":"USD", "phone": "+509"}, 
    {"name": "Heard Island and Mcdonald Islands", "code": "HM", "currency":"AUD", "phone": ""}, 
    {"name": "Holy See (Vatican City State)", "code": "VA", "currency":"EUR", "phone": ""}, 
    {"name": "Honduras", "code": "HN", "currency":"HNL", "phone": "+504"}, 
    {"name": "Hong Kong", "code": "HK", "currency":"HKD", "phone": "+852"}, 
    {"name": "Hungary", "code": "HU", "currency":"HUF", "phone": "+36"}, 
    {"name": "Iceland", "code": "IS", "currency":"ISK", "phone": "+354"}, 
    {"name": "India", "code": "IN", "currency":"INR", "phone": "+91"}, 
    {"name": "Indonesia", "code": "ID", "currency":"IDR", "phone": "+62"}, 
    // {"name": "Iran, Islamic Republic Of", "code": "IR", "phone": "+"},
    {"name": "Iraq", "code": "IQ", "currency":"IQD", "phone": "+964"}, 
    {"name": "Ireland", "code": "IE", "currency":"EUR", "phone": "+353"}, 
    {"name": "Isle of Man", "code": "IM", "currency":"GBP", "phone": "+44"}, 
    {"name": "Israel", "code": "IL", "currency":"ILS", "phone": "+972"}, 
    {"name": "Italy", "code": "IT", "currency":"EUR", "phone": "+39"}, 
    {"name": "Jamaica", "code": "JM", "currency":"JMD", "phone": "+1876"}, 
    {"name": "Japan", "code": "JP", "currency":"JPY", "phone": "+81"}, 
    {"name": "Jersey", "code": "JE", "currency":"GBP", "phone": "+44"}, 
    {"name": "Jordan", "code": "JO", "currency":"JOD", "phone": "+962"}, 
    {"name": "Kazakhstan", "code": "KZ", "currency":"KJT", "phone": "+7"}, 
    {"name": "Kenya", "code": "KE", "currency":"KES", "phone": "+254"}, 
    {"name": "Kiribati", "code": "KI", "currency":"AUD", "phone": "+686"}, 
    {"name": "South Korea", "code": "KP", "currency":"KPW", "phone": ""},
    // {"name": "Korea, Republic of", "code": "KR", "phone": "+"}, 
    {"name": "Kuwait", "code": "KW", "currency":"KWD", "phone": "+965"}, 
    {"name": "Kyrgyzstan", "code": "KG", "currency":"KGS", "phone": "+996"}, 
    {"name": "Lao People\'s' Democratic Republic", "code": "LA", "currency":"LAK", "phone": ""}, 
    {"name": "Latvia", "code": "LV", "currency":"EUR", "phone": "+371"}, 
    {"name": "Lebanon", "code": "LB", "currency":"LBP", "phone": "+961"}, 
    {"name": "Lesotho", "code": "LS", "currency":"ZAR", "phone": "+266"}, 
    {"name": "Liberia", "code": "LR", "currency":"LRD", "phone": "+231"}, 
    {"name": "Libyan Arab Jamahiriya", "code": "LY", "currency":"LYD", "phone": ""}, 
    {"name": "Liechtenstein", "code": "LI", "currency":"CHF", "phone": "+423"}, 
    {"name": "Lithuania", "code": "LT", "currency":"EUR", "phone": "+370"}, 
    {"name": "Luxembourg", "code": "LU", "currency":"EUR", "phone": "+352"}, 
    {"name": "Macao", "code": "MO", "currency":"MOP", "phone": ""}, 
    {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK", "currency":"MKD", "phone": "+389"}, 
    {"name": "Madagascar", "code": "MG","currency":"MGA" , "phone": "+261"}, 
    {"name": "Malawi", "code": "MW", "currency":"MWK", "phone": "+265"}, 
    {"name": "Malaysia", "code": "MY", "currency":"MYR", "phone": "+60"}, 
    {"name": "Maldives", "code": "MV", "currency":"MVR", "phone": "+960"}, 
    {"name": "Mali", "code": "ML", "currency":"XOF", "phone": "+223"}, 
    {"name": "Malta", "code": "MT", "currency":"EUR", "phone": "+356"}, 
    {"name": "Marshall Islands", "code": "MH", "currency":"USD", "phone": "+692"}, 
    {"name": "Martinique", "code": "MQ", "currency":"EUR", "phone": "+596"}, 
    {"name": "Mauritania", "code": "MR", "currency":"MRU", "phone": "+222"}, 
    {"name": "Mauritius", "code": "MU", "currency":"MUR", "phone": "+230"}, 
    {"name": "Mayotte", "code": "YT", "currency":"EUR", "phone": "+262"}, 
    {"name": "Mexico", "code": "MX", "currency":"MXN", "phone": "+52"}, 
    {"name": "Micronesia, Federated States of", "code": "FM", "currency":"USD", "phone": ""}, 
    {"name": "Moldova, Republic of", "code": "MD", "currency":"MDL", "phone": ""}, 
    {"name": "Monaco", "code": "MC", "currency":"EUR", "phone": "+377"}, 
    {"name": "Mongolia", "code": "MN", "currency":"MNT", "phone": "+976"}, 
    {"name": "Montenegro", "code": "ME", "currency":"EUR", "phone": "+382"},
    {"name": "Montserrat", "code": "MS", "currency":"XCD", "phone": "+1664"},
    {"name": "Morocco", "code": "MA", "currency":"MAD", "phone": "+212"}, 
    {"name": "Mozambique", "code": "MZ", "currency":"MZN", "phone": "+258"}, 
    {"name": "Myanmar", "code": "MM", "currency":"MMK", "phone": "+95"}, 
    {"name": "Namibia", "code": "NA", "currency":"ZAR", "phone": "+264"}, 
    {"name": "Nauru", "code": "NR", "currency":"AUD", "phone": ""}, 
    {"name": "Nepal", "code": "NP", "currency":"NPR", "phone": "+977"}, 
    {"name": "Netherlands", "code": "NL", "currency":"EUR", "phone": "+599"},
    {"name": "New Caledonia", "code": "NC", "currency":"XPF", "phone": "+687"}, 
    {"name": "New Zealand", "code": "NZ", "currency":"NZD", "phone": "+64"}, 
    {"name": "Nicaragua", "code": "NI", "currency":"NIO", "phone": "+505"}, 
    {"name": "Niger", "code": "NE", "currency":"XOF", "phone": "+227"}, 
    {"name": "Nigeria", "code": "NG", "currency":"NGN", "phone": "+234"}, 
    {"name": "Niue", "code": "NU", "currency":"NZD", "phone": "+683"}, 
    {"name": "Norfolk Island", "code": "NF", "currency":"AUD", "phone": "+672"}, 
    {"name": "Northern Mariana Islands", "code": "MP", "currency":"USD", "phone": "+1670"}, 
    {"name": "Norway", "code": "NO", "currency":"NOK", "phone": "+47"}, 
    {"name": "Oman", "code": "OM", "currency":"OMR", "phone": "+968"}, 
    {"name": "Pakistan", "code": "PK", "currency":"PKR", "phone": "+92"}, 
    {"name": "Palau", "code": "PW", "currency":"USD", "phone": "+680"}, 
    {"name": "Palestinian Territory, Occupied", "code": "PS", "currency":"EUR", "phone": "+970"}, 
    {"name": "Panama", "code": "PA", "currency":"USD", "phone": "+507"}, 
    {"name": "Papua New Guinea", "code": "PG", "currency":"PGK", "phone": "+675"}, 
    {"name": "Paraguay", "code": "PY", "currency":"PYG", "phone": "+595"}, 
    {"name": "Peru", "code": "PE", "currency":"PEN", "phone": "+51"}, 
    {"name": "Philippines", "code": "PH", "currency":"PHP", "phone": "+63"}, 
    {"name": "Pitcairn", "code": "PN", "currency":"NZD", "phone": ""}, 
    {"name": "Poland", "code": "PL", "currency":"PLN", "phone": "+48"}, 
    {"name": "Portugal", "code": "PT", "currency":"EUR", "phone": "+351"}, 
    {"name": "Puerto Rico", "code": "PR", "currency":"USD", "phone": "+1787"}, 
    {"name": "Qatar", "code": "QA", "currency":"QAR", "phone": "+974"}, 
    {"name": "Reunion", "code": "RE", "currency":"EUR", "phone": "+262"},
    {"name": "Romania", "code": "RO", "currency":"RON", "phone": "+40"}, 
    {"name": "Russian Federation", "code": "RU", "currency":"RUB", "phone": ""}, 
    {"name": "RWANDA", "code": "RW", "currency":"RWF", "phone": "+250"}, 
    {"name": "Saint Helena", "code": "SH", "currency":"SHP", "phone": ""}, 
    {"name": "Saint Kitts and Nevis", "code": "KN", "currency":"XCD", "phone": ""}, 
    {"name": "Saint Lucia", "code": "LC", "currency":"XCD", "phone": ""}, 
    {"name": "Saint Pierre and Miquelon", "code": "PM", "currency":"EUR", "phone": ""}, 
    {"name": "Saint Vincent and the Grenadines", "code": "VC", "currency":"XCD", "phone": ""}, 
    {"name": "Samoa", "code": "WS", "currency":"WST", "phone": "+685"}, 
    {"name": "San Marino", "code": "SM", "currency":"EUR", "phone": "+378"}, 
    {"name": "Sao Tome and Principe", "code": "ST", "currency":"STN", "phone": "+239"}, 
    {"name": "Saudi Arabia", "code": "SA", "currency":"SAR", "phone": "+966"}, 
    {"name": "Senegal", "code": "SN", "currency":"XOF", "phone": "+221"}, 
    {"name": "Serbia", "code": "RS", "currency":"RSD", "phone": "+381"}, 
    {"name": "Seychelles", "code": "SC", "currency":"SCR", "phone": "+248"}, 
    {"name": "Sierra Leone", "code": "SL", "currency":"SLE", "phone": "+232"}, 
    {"name": "Singapore", "code": "SG", "currency":"SGD", "phone": "+65"}, 
    {"name": "Slovakia", "code": "SK", "currency":"EUR", "phone": "+421"}, 
    {"name": "Slovenia", "code": "SI", "currency":"EUR", "phone": "+386"}, 
    {"name": "Solomon Islands", "code": "SB", "currency":"SBD", "phone": "+677"}, 
    {"name": "Somalia", "code": "SO", "currency":"SOS", "phone": "+252"}, 
    {"name": "South Africa", "code": "ZA", "currency":"ZAR", "phone": "+27"}, 
    {"name": "South Georgia and the South Sandwich Islands", "code": "GS", "currency":"USD", "phone": ""}, 
    {"name": "Spain", "code": "ES", "currency":"EUR", "phone": "+34"}, 
    {"name": "Sri Lanka", "code": "LK", "currency":"LKR", "phone": "+94"}, 
    {"name": "Sudan", "code": "SD", "currency":"SDG", "phone": "+249"}, 
    {"name": "Suriname", "code": "SR", "currency":"SRD", "phone": "+597"}, 
    {"name": "Svalbard and Jan Mayen", "code": "SJ", "currency":"NOK", "phone": ""}, 
    {"name": "Swaziland", "code": "SZ", "currency":"SZL", "phone": "+268"}, 
    {"name": "Sweden", "code": "SE", "currency":"SEK", "phone": "+46"}, 
    {"name": "Switzerland", "code": "CH", "currency":"CHE", "phone": "+41"},
    {"name": "Taiwan, Province of China", "code": "TW", "currency":"TWD", "phone": ""}, 
    {"name": "Tajikistan", "code": "TJ", "currency":"TJS", "phone": "+992"}, 
    {"name": "Tanzania, United Republic of", "code": "TZ", "currency":"TZS", "phone": ""}, 
    {"name": "Thailand", "code": "TH", "currency":"THB", "phone": "+66"}, 
    {"name": "Timor-Leste", "code": "TL", "currency":"USD", "phone": ""}, 
    {"name": "Togo", "code": "TG", "currency":"XOF", "phone": "+228"}, 
    {"name": "Tokelau", "code": "TK", "currency":"NZD", "phone": ""}, 
    {"name": "Tonga", "code": "TO", "currency":"TOP", "phone": "+676"}, 
    {"name": "Trinidad and Tobago", "code": "TT", "currency":"TTD", "phone": "+1868"}, 
    {"name": "Tunisia", "code": "TN", "currency":"TND", "phone": "+216"}, 
    {"name": "Turkey", "code": "TR", "currency":"TRY", "phone": ""}, 
    {"name": "Turkmenistan", "code": "TM", "currency":"TMT", "phone": "+993"}, 
    {"name": "Turks and Caicos Islands", "code": "TC", "currency":"USD", "phone": "+1649"}, 
    {"name": "Tuvalu", "code": "TV", "currency":"AUD", "phone": "+688"}, 
    {"name": "Uganda", "code": "UG", "currency":"UGX", "phone": "+256"}, 
    {"name": "Ukraine", "code": "UA", "currency":"UAH", "phone": "+380"}, 
    {"name": "United Arab Emirates", "code": "AE", "currency":"AED", "phone": "+971"}, 
    {"name": "United Kingdom", "code": "GB", "currency":"GBP", "phone": "+44"},
    {"name": "United States Minor Outlying Islands", "code": "UM", "currency":"USD", "phone": ""}, 
    {"name": "Uruguay", "code": "UY", "currency":"UYI", "phone": "+598"},
    {"name": "Uzbekistan", "code": "UZ", "currency":"UZS", "phone": "+998"}, 
    {"name": "Vanuatu", "code": "VU", "currency":"VUV", "phone": "+678"}, 
    {"name": "Venezuela", "code": "VE", "currency":"VED", "phone": "+58"}, 
    {"name": "Viet Nam", "code": "VN", "currency":"VND", "phone": ""}, 
    {"name": "Virgin Islands, British", "code": "VG", "currency":"USD", "phone": "+1284"}, 
    {"name": "Virgin Islands, U.S.", "code": "VI", "currency":"USD", "phone": "+1340"}, 
    {"name": "Wallis and Futuna", "code": "WF", "currency":"XPF", "phone": "+681"}, 
    {"name": "Western Sahara", "code": "EH", "currency":"MAD", "phone": "+212"}, 
    {"name": "Yemen", "code": "YE", "currency":"YER", "phone": "+967"}, 
    {"name": "Zambia", "code": "ZM", "currency":"ZMW", "phone": "+260"}, 
    {"name": "Zimbabwe", "code": "ZW", "currency":"ZWL", "phone": "+263"} 
]