import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import ChipInput from "material-ui-chip-input";
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';

import useToast from '@fuse/hooks/useToast';
import axios from "@fuse/utils/axios";

import TextField from '@material-ui/core/TextField';
import { openEmailDialog, closeEmailDialog } from '../../../store/emailDialogReducer';

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.2),
    },
}));

function sendPdfToEmail(props) {
    //console.log(props)
    const classes = useStyles();
    const dispatch = useDispatch();
    const toast = useToast(dispatch);
    const dialogState = useSelector(({ emailSalesOrder }) => emailSalesOrder.emailDialogReducer);
    // const [email, setEmail] = useState('')
    const [emailList, setEmailList] = useState([])

    // const handleOnKeyDown = (event) => {

    //     if (event.key === "Enter") {
    //         if (!email && emailList.length === 0) {
    //             toast.error('Email is required')
    //         }
    //         if (email) {
    //             const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //             const checkVaild = regex.test(String(email).toLowerCase());
    //             if (checkVaild) {
    //                 const list = [...emailList]
    //                 if (list.includes(email)) {
    //                     setEmail('')
    //                     toast.error('Email already exist in the list');
    //                     return
    //                 }
    //                 list.push(email)
    //                 setEmailList(list)
    //                 setEmail('')
    //             } else {
    //                 toast.error('Valid email is required')
    //             }
    //         }
    //     }
    // }

    // const handleDelete = (emailList, index) => {
    //     setEmailList(emailList.filter((item, i) => i !== index))
    // }

    const validateEmail = (email) => {

        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const checkVaild = regex.test(String(email).toLowerCase());
        if (!email && emailList.length === 0) {
            toast.error('Email should not be empty')
            return false
        }
        else if (!checkVaild) {
            toast.error('Valid email is required')
            return false
        }
        else if (emailList.includes(email)) {
            toast.error('Email already exists in the list')
            return false
        }
        else {
            emailList.push(email)
            return true
        }
    }

    const deleteChip = (email) => {
        setEmailList(emailList.filter((item) => item !== email))
    }

    function sendEmail() {

        // const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // if(email){

        //     const checkVaild = regex.test(String(email).toLowerCase());

        //     if(checkVaild){

        // axios.post(`/invoices/send-email`,{
        //     id: props.invoice_id,
        //     email
        // }).then(res=>{
        //     const { data } = res.data
        //     toast.success(res.data.message)

        //     dispatch(closeEmailDialog())
        //     setEmail('')

        // })
        //     }else{
        //         toast.error('Valid email is required')
        //     }
        // }else{
        //     toast.error('Email is required')
        // }

        if (emailList.length !== 0) {

            axios.post(`/invoices/send-email`, {
                id: props.invoice_id,
                email: emailList,
            }).then(res => {
                const { data } = res.data
                toast.success(res.data.message)

                dispatch(closeEmailDialog())
                // setEmail('')
                setEmailList([])
            })
        } else {
            toast.error('Email is required')
        }


    }


    return (
        <Dialog onClose={() => {
            dispatch(closeEmailDialog())
        }} aria-labelledby="simple-dialog-title" open={dialogState.open} maxWidth="sm" fullWidth={true}>

            <DialogTitle id="simple-dialog-title">Enter email</DialogTitle>
            <DialogContent>
                <div>
                    {/* <TextField
                        d="outlined-basic"
                        label="Email"
                        variant="outlined"
                        style={{ width: '100%' }}
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                        onKeyDown={handleOnKeyDown}
                        InputProps={{
                            startAdornment: emailList.map((item, index) => (
                                <Chip
                                    key={index}
                                    label={item}
                                    className={classes.chip}
                                    onDelete={() => handleDelete(emailList, index)}
                                />
                            )),                           
                        }}
                    /> */}
                    <ChipInput
                        label="Email"
                        variant='outlined'
                        fullWidth={true}
                        onBeforeAdd={validateEmail}
                        value={emailList}
                        onDelete={deleteChip}
                    />
                    <div style={{ marginTop: '10px' }}>
                        <Typography variant="body2">*Press enter for multiple emails</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={sendEmail}>
                    Send Email
                </Button>
                <Button onClick={() => {
                    dispatch(closeEmailDialog())
                    // setEmail('')
                    setEmailList([])
                }} color="primary" autoFocus>
                    Cancel
                </Button>
            </DialogActions>

        </Dialog>

    )


}

export default sendPdfToEmail