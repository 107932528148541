import { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Paper, MenuItem, Popper, Fade, List } from '@material-ui/core/';

const textEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const refInput = useRef(null);
    useEffect(() => {
        // focus on the input
        setTimeout(() => refInput.current.focus());
    }, []);
 
    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                // this simple editor doubles any value entered into the input
                return value;
            },
 
            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            },
 
            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                // our editor will reject any value greater than 1000
                return value > 1000;
            }
        };
    });
 
    return (
        <>
        <input 
            ref={refInput}
            value={value}
            style={{width: "100%"}}
            onChange={(e)=>{
                setValue(e.target.value)
                if(props.updateColValue){
                    props.updateColValue(props.colDef.field, event.target.value)
                }
            }}
            placeholder={ (props?.colDef?.field == 'sku') ? props?.colDef?.field.toUpperCase() : (props?.colDef?.field || '').split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
        />
        </>
    );
 });

 export default textEditor;