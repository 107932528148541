export const moduleOptions = [
  {
    name: 'asset',
    displayName: 'Asset'
  },
  {
    name: 'contract',
    displayName: 'Extended Contract'
  },
  // {
  //   name: 'subscription',
  //   displayName: 'Software Subscriptions'
  // },
  {
    name: 'service_requests',
    displayName: 'Service Tickets'
  }
]

export const reportOptions = {
  'asset': [
    {
      name: 'asset_by_location',
      displayName: 'Asset by Location'
    },
    {
      name: 'asset_by_category',
      displayName: 'Asset By Category'
    },
    {
      name: 'asset_by_department',
      displayName: 'Asset by Department'
    },
    {
      name: 'asset_by_manufacturer',
      displayName: 'Asset by Manufacturer'
    }
  ],
  'contract': [
    {
      name: 'contract_by_duration',
      displayName: 'Contract by Duration'
    },
    {
      name: 'contract_by_vendor',
      displayName: 'Contract by Vendor'
    },
    {
      name: 'contract_by_value',
      displayName: 'Contract by Value'
    },
    {
      name: 'contract_by_expiry_date',
      displayName: 'Contract by Expiry Date'
    }
  ],
  // 'subscription': [
  //   {
  //     name: 'subscription_by_type',
  //     displayName: 'Subscription by Type'
  //   },
  //   {
  //     name: 'subscription_by_cost',
  //     displayName: 'Subscription by Cost'
  //   },
  //   {
  //     name: 'subscription_by_duration',
  //     displayName: 'Subscription by Duration'
  //   },
  //   {
  //     name: 'subscription_by_vendor',
  //     displayName: 'Subscription by Vendor'
  //   }
  // ],
  'service_requests': [
    {
      name: 'service_requests_by_type',
      displayName: 'Tickets by Type'
    },
    {
      name: 'service_requests_by_priority',
      displayName: 'Tickets by Priority'
    },
    {
      name: 'service_requests_by_status',
      displayName: 'Tickets by Status'
    },
    {
      name: 'service_requests_by_department',
      displayName: 'Tickets by Department'
    }
  ]
}