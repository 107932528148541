import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Controller , useForm, FormProvider } from 'react-hook-form';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2';
import OTPInput from 'react-otp-input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import { Autocomplete } from '@material-ui/lab'
import axios from '@fuse/utils/axios'
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { setImageUrl } from 'app/auth/store/userSlice';
import { countries } from '@fuse/utils/countryName'
import {
	Tabs, Tab, Box, Typography, Checkbox, Grid, Card,
	MenuItem, FormControl, Avatar, Input, InputLabel, TextField, Paper, Dialog, DialogTitle, DialogContent, DialogActions
  } from '@mui/material'
import { makeStyles } from "@material-ui/core/styles";
import setCanvasPreview from "./setCanvasPreview";
import ReactCrop, {
	centerCrop,
	makeAspectCrop,
	Crop,
	PixelCrop,
	convertToPixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
// import CircularProgress from '@mui/material/CircularProgress';
import {CircularProgress} from '@material-ui/core';
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'
import userIcon from './tabs/user.svg'
import profileImg from "./user-profile-pic.png"
// import {useDropzone} from 'react-dropzone'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import PhoneNumberVerificationModal from '@fuse/components/Modal/PhoneNumberVerificationModal';

function centerAspectCrop(
	mediaWidth,
	mediaHeight,
	aspect,
) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	)
}

const useStyles = makeStyles((theme) => ({

	large: {
		width: "100%",
		height: "300px",
		borderRadius: 0
	},
}));


const EditUserProfile = ({ classes, refresh }) => {
// const classes = useStyles();
	const [imgSrc, setImgSrc] = useState('')
	const [loading, setLoading] = useState(false)
	const [ photoUploadLoader, setPhotoUploadLoader ] = useState(false)
	const [imageName, setImageName] = useState('')
	const [imagePath, setImagePath] = useState('')
	const [imageLocation, setImageLocation] = useState(null)
	const [userDetail, setUserDetail] = useState([]);
	const [role, setRole] = useState([]);
	const [uploadImage, setIsUploadImage] = useState(false);
	const [profileImage, setProfileImage] = useState(
		"https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
	);
	const imgRef = useRef(null)
	const previewCanvasRef = useRef(null);
	const [ formattedNumber, setFormattedNumber ] = useState('')
	const [ alreadyExisitngNumber, setAlreadyExisitingNumber ] = useState('')
	const [ openEditPhoto, setOpenEditPhoto ] = useState(false);
	const [openPreviewPhoto, setOpenPreviewPhoto] = useState(false);
	const [crop, setCrop] = useState({});
	const [aspect, setAspect] = useState(16 / 9)
	const [completedCrop, setCompletedCrop] = useState(null)
	const [ isPhoneVerified, setIsPhoneVerified ] = useState(false);
	const inputRefImg = useRef(null)
	const [ openConfirmRemovePhotoModal, setOpenConfirmRemovePhotoModal ] = useState(false)
	const [ openPhoneVerificationModal, setOpenPhoneVerificationModal ] = useState(false);

	const handleClickOpenEditPhoto = () => {
		setOpenEditPhoto(true);
	};
	const [ startDate, setStartDate ] = useState('')
	const [ endDate , setEndDate ] = useState('')

	const handleCloseEditPhoto = () => {
		// console.log('Hello ')
		setImgSrc("")
		// imgRef = null
		// previewCanvasRef = null
		setCrop({})
		setCompletedCrop(null)
		setPhotoUploadLoader(false)
		setOpenEditPhoto(false);
	};

	const handleClickOpenPreviewPhoto = () => {
		setOpenPreviewPhoto(true);
	};
	
	const handleClosePreviewPhoto = () => {
		setOpenPreviewPhoto(false);
	};

	const dispatch = useDispatch()
	const toast = useToast(dispatch)

	const defaultValues = {
		first_name: '',
		last_name: '',
		email: '',
		mobile: '',
		title: '',
		department: '',
		address_1: '',
		address_2: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		employee_number: '',
		start_date: '',
		// end_date: ''
	}

	const validationSchema = yup.object({
		first_name: yup.string().trim().required('First Name is required!').max(155, 'First Name should be under 155 characters.'),
		last_name: yup.string().trim().required('Last Name is required!').max(155, 'Last Name should be under 155 characters.'),
		mobile: yup.string().nullable(),
		title: yup.string().trim().required('Title is required.').min(2, 'Title must be greater than 2 chars!'),
		address_1: yup.string().trim().nullable(),
		address_2 : yup.string().trim().nullable(),
		city: yup.string().trim().nullable(),
		state: yup.string().trim().nullable(),
		zip: yup.string().trim().nullable(),
		country: yup.object().nullable(),
		// department: yup.string().trim().required('Department is required!'),
	})

	const methods = useForm({
		mode: 'onChange',
		defaultValues,

		resolver: yupResolver(validationSchema)
	});
	const user = useCurrentUser();

	const { formState: { errors }, reset, watch, control, onChange, formState, handleSubmit, setValue, clearErrors, getValues, trigger } = methods;

	function setData() {
		setValue('first_name', userDetail?.first_name || '')
		setValue('last_name', userDetail?.last_name || '')
		setValue('email', userDetail?.email || '')
		// setValue('mobile', userDetail?.phone || '')
		setAlreadyExisitingNumber(userDetail?.phone || '');
		setFormattedNumber(userDetail?.phone || '')
		setIsPhoneVerified(Boolean(userDetail?.phone || ''))
		setValue('title', userDetail?.title || '')
		setValue('department', userDetail?.department?.department_name || '')
		setValue('address_1', userDetail?.address || '')
		setValue('address_2', userDetail?.address_2 || '')
		setValue('city', userDetail?.city || '')
		setValue('state', userDetail?.state)
		setValue('zip', userDetail?.zip)
		setValue('country', { name: userDetail?.country, code: 'N/A' })
		setValue('employee_number', userDetail?.emp_no)
		// if(typeof userDetail.end_date !== undefined && userDetail.end_date !== null && userDetail.end_date !== '') {
		// 	setValue('end_date' , `${new Date(userDetail.end_date).getMonth() + 1}/${new Date(userDetail.end_date).getDate()}/${new Date(userDetail.end_date).getFullYear()}`)
		// } else {
		// 	setValue('end_date', '')
		// }

		if(typeof userDetail.start_date !== undefined && userDetail.start_date !== null && userDetail.start_date !== '') {
			setValue('start_date' , `${new Date(userDetail.start_date).getMonth() + 1}/${new Date(userDetail.start_date).getDate()}/${new Date(userDetail.start_date).getFullYear()}`)
		} else {
			setValue('start_date', '')
		}
	}

	useEffect(() => {
		// if(userDetail){
		setData()
		// }else{
		// 	reset(defaultValues)
		// }
	}, [userDetail])

	const onChangeImageUpload = (event) => {
		setLoading(true)
		const selectedFile = event.target.files[0]
		if (!selectedFile) return
		if (selectedFile.size > 5000000) {
			toast.error("File size cannot exceed 5Mb!");
			setUploadingFile(true)
			
		} else {
			
			const formData = new FormData()
			formData.append("file", selectedFile)
			axios.post('/end-user/upload-profile-image', formData).then(response => {
				if (response.status === 201) {
					toast.error(response.data.message)
				} else {
					axios.put('end-user/update-image', {
						image_path: response.data.data.location
					}).then(response => {
						toast.success('Profile photo has been updated.')
						// location.reload();
					}).catch(err => {
						console.log('212=>', err);
						toast.error('Something went wrong!')
					})
					setImageName(selectedFile.name)
					setImageLocation(response.data.data.imageLocation)
					setIsUploadImage(true)
					setImagePath(response.data.data.location)
					setProfileImage(response.data.data.imageLocation)
					
				}
			}).catch(err => {
				console.log('136=>', err);
				toast.error('Something went wrong!')
			}).finally(() => {
				setLoading(false)
			})
		}
	}

	const errorData = (error) => {
			if(error.first_name !== undefined ||
				error.last_name !== undefined ||
				error.title !== undefined ||
				error.address_1 !== undefined ||
				error.city !== undefined ||
				error.state !== undefined ||
				error.zip !== undefined ||
				error.country !== undefined
			) {
				if(profileTabValue !== 0) {
					setProfileTabValue(0);
				}
			}
	}


	useDebounceEffect(
		async () => {
		  if (
			completedCrop?.width &&
			completedCrop?.height &&
			imgRef.current &&
			previewCanvasRef.current
		  ) {
			// We use canvasPreview as it's much faster than imgPreview.
			canvasPreview(
			  imgRef.current,
			  previewCanvasRef.current,
			  completedCrop,
			  1,
			  0,
			)
		  }
		},
		100,
		[completedCrop],
	)


	const manageFormData = (formData) => {
		const payload = {
			first_name: formData.first_name || '',
			last_name: formData.last_name || '',
			title: formData.title || '',
			address: formData.address_1 || '',
			address_2: formData.address_2 || '',
			city: formData.city || '',
			state: formData.state || '',
			zipcode: formData.zip || '',
			country: formData.country?.name || '',
			emp_no: formData.employee_number || '',
			start_date: formData.start_date || '',
			// end_date: formData.end_date || '',
			photoUrl: imagePath || '',
			department_id: userDetail.department?.id ?? null
		}
		axios.post('end-user/update-user-profile-details', payload).then(response => {
			if (response.status === 201) {
				toast.error(response.data.message)
			} else {
				toast.success('User Profile updated successfully')
				// if(uploadImage || (userDetail?.first_name.trim()!=formData.first_name.trim()) || (userDetail?.last_name.trim() !=formData.last_name.trim())){
				// location.reload();
				// }
				// reset(defaultValues)
				// refresh()
				// setUserDetail(results.data.data.client)
				// setRole(results.data.data.role_location)
				// setImageLocation(results.data.data.imageUrl)
				// setProfileImage(results.data.data.imageUrl)
				// setData()
			}
		}).catch(err => {
			console.log('212=>', err);
			toast.error('Something went wrong!')
		})
	}

	function backButtonPress() {
		window.history.go(-1);
		return false;
	}

	async function getUserDetails() {
		axios.get(`/end-user/view-client/${user.data.id}`)
			.then(results => {
				reset(defaultValues)
				setImageLocation(results.data.data.imageUrl)
				setUserDetail(results.data.data.client)
				setRole(results.data.data.role_location)
			})
	}
	useEffect(() => {
		getUserDetails()
	}, [])


	let [profileTabValue, setProfileTabValue] = useState(0);

	const handleChangeProfileTabValue = (event, newValue) => {
		setProfileTabValue(newValue);
	};

	function a11yProps(index) {
		return {
		  id: `simple-tab-${index}`,
		  'aria-controls': `simple-tabpanel-${index}`,
		};
	}


	function removeImage() {
		axios.put('end-user/update-image', {
			image_path: null
		}).then(response => {
			toast.success('Profile photo has been removed.')
			setImageLocation(null)
			dispatch(setImageUrl(null))
			setOpenConfirmRemovePhotoModal(false)
			// location.reload();
		}).catch(err => {
			toast.error('Something went wrong!')
		})
	}


	function  onSelectFileEvent(e) {
		if (e.target.files && e.target.files.length > 0) {
			const extension = e.target.files[0].type
			console.log(extension)
			if(extension === "image/png" || extension === "image/jpg" || extension === "image/jpeg") {
				setCrop(undefined) // Makes crop preview update between images.
				const reader = new FileReader()
				reader.addEventListener('load', () =>
					setImgSrc(reader.result?.toString() || ''),
				)
				reader.readAsDataURL(e.target.files[0])
				setOpenEditPhoto(true);
			} else {
				toast.error("Invalid File Format!")
				e.target.value = null;
				return
			}
			
		}
	}

	function onImageLoad(e) {
		if (aspect) {
			const { width, height } = e.currentTarget
			setCrop(centerAspectCrop(width, height, aspect))
		}
	}

	function dataURLtoBlob (dataURL) {
		let byteString = atob(dataURL.split(",")[1]);
		let mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
		let ab = new ArrayBuffer(byteString.length);
		let ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
		  ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	}


	async function handleClickUploadImage() {
		try {
			setPhotoUploadLoader(true)
			let formData = new FormData();
			setCanvasPreview(
				imgRef.current, // HTMLImageElement
				previewCanvasRef.current, // HTMLCanvasElement
				convertToPixelCrop(
					crop,
					imgRef.current.width,
					imgRef.current.height
				)
			);
			const dataUrl = previewCanvasRef.current.toDataURL();
			formData.append("file", dataURLtoBlob(dataUrl), `${new Date().getTime()}.png`);
			axios.post('/end-user/upload-profile-image', formData).then(response => {
				if (response.status === 201) {
					toast.error(response.data.message)
				} else {
					// console.log(response)
					axios.put('end-user/update-image', {
						image_path: response.data.data.location
					}).then(responseData => {
						toast.success('Profile photo has been updated.')
						// location.reload();
						setOpenEditPhoto(false)
						setOpenPreviewPhoto(false)
						setImageLocation(response.data.data.imageLocation)
						dispatch(setImageUrl(response.data.data.imageLocation))
						setIsUploadImage(true)
						setImagePath(response.data.data.location)
						setProfileImage(response.data.data.imageLocation)
					}).catch(err => {
						console.log('212=>', err);
						toast.error('Something went wrong!')
					})
					// setImageName(selectedFile.name)
					
				}
			}).catch(err => {
				// toast.error('Unable to upload the photo');
				console.log(`Error message ${err.message}`)
			})
		} catch(error) {
			console.log('236=>', error);
			toast.error('Something went wrong!')
		} finally {
			setPhotoUploadLoader(false)
		}
	}

	const handleCloseOpenConfirmRemovePhotoModal = () => {
		setOpenConfirmRemovePhotoModal(!openConfirmRemovePhotoModal)
	}

	const handleCloseOpenPhoneVerificationModal = () => {
    setOpenPhoneVerificationModal(false)
		getUserDetails()
  }

	const handleOpenPhoneVerificationModal = () => {
		setOpenPhoneVerificationModal(true)
	}

	return (
		<div className={classes.root}>


			{/* <Card style={{ padding: "10px 20px" }}> */}

				<h2 className='font_24_heading mb-36'>Edit Profile</h2>

				<form onSubmit={handleSubmit(manageFormData, errorData)} style={{background: '#fcfcfc'}}>
					<div className='profile_section_holder'>
						<div className='left_holder'>
							<div className='white_card_holder'>
								<div className='user_profileImg'>
									{
										loading ? <>
										</> : 
										<div className='img_holder' style={{cursor:'pointer'}}>
											<div className='input_btn_holder' >
											{/* onClick={handleClickOpenEditPhoto} */}
												<input
													id="avatar-input"
													type="file"
													accept="image/*"
													onChange = {onSelectFileEvent}
													className="input_file"
													ref={inputRefImg}
												/>
												<span className='icon_holder'>
													<i className='ti ti-camera' />
												</span>
											</div>
											<img 
												src={imageLocation === null ? userIcon : imageLocation} 
												alt=''
												onClick={(e) => {
													inputRefImg.current.click()
												}} 
											/>
										</div>
									}
									{imageLocation && <Button className='remove_btn' onClick={handleCloseOpenConfirmRemovePhotoModal} disabled={imageLocation === null}>Remove Photo</Button>}
								</div>
								<div className='profile_details_holder'>
									<div className='each_holder'>
										<span className='label_holder'>Full Name</span>
										<p className='info_holder'>{userDetail.first_name} {userDetail.last_name}</p>
									</div>
									<div className='each_holder'>
										<span className='label_holder'>Email Address</span>
										<p className='info_holder'>{userDetail.email}</p>
									</div>
								</div>
							</div>
						</div>
						<div className='right_holder'>
							<div className='white_card_holder'>
								<Box className='cstm_tabs_holder'>
									<Tabs value={profileTabValue} onChange={handleChangeProfileTabValue} aria-label="basic tabs example" className='tabs_main_holder'>
										<Tab className='each_tab_holder' label="Personal Information" {...a11yProps(0)} />
										<Tab className='each_tab_holder' label="Department" {...a11yProps(1)} />
										<Tab className='each_tab_holder' label="Locations" {...a11yProps(2)} />
									</Tabs>
								</Box>

								<Button type='submit' style={{float: 'right'}} className='button primary_btn'>Save Changes</Button>

								{ profileTabValue === 0 &&
									<>
										<Grid container spacing={2}>
										{/* <Chip label="Personal Information" className='customChip' style={{ margin: "20px 0" }} /> */}
											<Grid item lg={6} xl={6} md={12}>
												<Controller
													name="first_name"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<InputLabel className='form_label_input' shrink htmlFor="first-name">
																First Name <span className='required_span'>*</span>
															</InputLabel>
															<TextField
																{...field}
																id='first-name'
																className='form_input_holder'
																variant='outlined'
																label=''
																fullWidth
																required
																inputProps={{ readOnly: false }}
																InputLabelProps={{ shrink: true }}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px',
																		fontSize:'10px'
																	}
																}}
																style={{ backgroundColor: '#fff' }}
																error={!!errors.first_name}
																helperText={errors?.first_name?.message}
															/>
														</>
													)}
												/>
											</Grid>

											<Grid item lg={6} xl={6} md={12}>
												<Controller
													name="last_name"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<InputLabel className='form_label_input' shrink htmlFor="last-name">
																Last Name <span className='required_span'>*</span>
															</InputLabel>
															<TextField
																{...field}
																id='last-name'
																className='form_input_holder'
																variant='outlined'
																label=''
																fullWidth
																required
																InputLabelProps={{ shrink: true }}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px',
																		fontSize:'10px'
																	}
																}}
																style={{ backgroundColor: '#fff' }}
																error={!!errors.last_name}
																helperText={errors?.last_name?.message}
															/>
														</>
													)}
												/>
											</Grid>

											<Grid item lg={6} xl={6} md={12}>
												<Controller
													name="email"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<InputLabel className='form_label_input' shrink htmlFor="email">
																Email Address <span className='required_span'>*</span>
															</InputLabel>
															<TextField
																{...field}
																id='email'
																className='form_input_holder'
																variant='outlined'
																label=''
																fullWidth
																InputLabelProps={{ shrink: true }}
																inputProps={{ readOnly: false }}
																disabled
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px',
																		fontSize:'10px'
																	}
																}}
																style={{ backgroundColor: '#fff' }}

															/>
														</>
													)}
												/>
											</Grid>

											<Grid className='flex flex-wrap justify-between' item lg={6} xl={6} md={12}>
												<Controller
													name="mobile"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<InputLabel className='form_label_input w-100' shrink htmlFor="phone">
																Phone Number
															</InputLabel>
															<TextField
																{...field}
																id='phone'
																className='form_input_holder'
																variant='outlined'
																placeholder="To save your phone number click on the verify button"
																value={formattedNumber}
																label=''
																disabled
																InputLabelProps={{ shrink: true }}
																style={{ width: 'calc(100% - 85px)', marginRight: '8px', backgroundColor: '#fff' }}
															/>
														</>
													)}
												/>
												<Button className='button primary_btn small_padding' onClick={handleOpenPhoneVerificationModal}>{!isPhoneVerified ? "Verify" : "Update"}</Button>
											</Grid>
										
											<Grid item lg={6} xl={6} md={12}>
												<Controller
													name="title"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<InputLabel className='form_label_input' shrink htmlFor="title">
																Title <span className='required_span'>*</span>
															</InputLabel>
															<TextField
																{...field}
																id='title'
																className='form_input_holder'
																variant='outlined'
																label=''
																fullWidth
																required
																inputProps={{ readOnly: false }}
																InputLabelProps={{ shrink: true }}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px',
																		fontSize:'10px'
																	}
																}}
																error={!!errors.title}
																helperText={errors?.title?.message}
																style={{ backgroundColor: '#fff' }}

															/>
														</>
													)}
												/>
											</Grid>

											<Grid item lg={6} xl={6} md={12}>
												<Controller
													name="employee_number"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<InputLabel className='form_label_input' shrink htmlFor="employee_no">
																Employee Number
															</InputLabel>
															<TextField
																{...field}
																id='employee_no'
																className='form_input_holder'
																variant='outlined'
																label=''
																disabled
																fullWidth
																InputLabelProps={{ shrink: true }}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
																style={{ backgroundColor: '#fff' }}
															/>
														</>
													)}
												/>
											</Grid>
										</Grid>
									</>
								}
								{/* <Button variant='contained' type='submit'>Update Details</Button> */}

								{ profileTabValue === 1 &&
									<>
										<Grid container spacing={2}>
											<Grid item lg={6} xl={6} md={12}>
												<Controller
													name="department"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<InputLabel className='form_label_input' shrink htmlFor="dept">
																Department
															</InputLabel>
															<TextField
																{...field}
																id='dept'
																className='form_input_holder'
																variant='outlined'
																label=''
																fullWidth
																disabled
																// aria-readonly
																inputProps={{ readOnly: true }}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
																style={{ backgroundColor: '#fff' }}
																InputLabelProps={{ shrink: true }}
																// error={!!errors.department}
																// helperText={errors?.department?.message}
															/>
														</>
													)}
												/>
											</Grid>

											<Grid item lg={6} xl={6} />

											<Grid item lg={6} xl={6} md={12}>												
												<Controller
													name="start_date"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<InputLabel className='form_label_input' shrink htmlFor="start_date">
																Start Date 
																{/* <span className='required_span'>*</span> */}
															</InputLabel>
															<TextField
																{...field}
																id='start_date'
																className='form_input_holder'
																variant='outlined'
																label=""
																type='text'
																InputLabelProps={{ shrink: true }}
																disabled
																fullWidth
																inputProps={{ readOnly: true }}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
															// style={{ backgroundColor: '#fff' }}
															// inputProps={{ max: `${endDate}` }}
															// style={{ width: '100%', backgroundColor: '#fff' }}
															/>
														</>
													)}
												/>
												{/* <CustomDatePickerV2
													className="date_picker"
													style={{ background: '#FCFCFD' }}
													state={startDate}
													setState={setStartDate}
													required={false}
												// label='Recovery Date'
												/> */}
											</Grid>

											
										</Grid>
									</>
								}

								{ profileTabValue === 2 &&
									<>
										{/* <Chip label="My Locations" style={{ margin: "20px 0" }} /> */}
										<Grid container spacing={2}>
											
											{role.map(roleData => {
												return (
													<>
														<Grid item lg={6} xl={6} md={12}>
															<InputLabel className='form_label_input' shrink htmlFor="role">
																Role
															</InputLabel>
															<TextField
																value={roleData.name}
																id='role'
																className='form_input_holder'
																variant='outlined'
																label=''
																fullWidth
																disabled
																inputProps={{ readOnly: true }}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
																style={{ backgroundColor: '#fff' }}
																InputLabelProps={{ shrink: true }}
															/>
														</Grid>
														<Grid item lg={6} xl={6} md={12}>
															<InputLabel className='form_label_input' shrink htmlFor="loc">
																Location
																{/* {item.location} */}
															</InputLabel>
															<ul className='list_holder location_list_holder'>
																{ 																
																(roleData.location !== "All locations" ? roleData.location.map( (loc, locIndex) => <li key={`loc_12_${locIndex}`}>{loc}</li>
																): <li key={`loc_12_1232431423`}>All locations</li> )
																}
															</ul>
														</Grid>
														
													</>
												)
											})}
										</Grid>
									</>
								}
							</div>

							{
								profileTabValue === 0 && 
								<div className='white_card_holder'>
									<Grid container spacing={2}>
										{/* <Chip label="Personal Information" className='customChip' style={{ margin: "20px 0" }} /> */}
										<Grid item lg={12} xl={12} md={12}>
											<Controller
												name="address_1"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<InputLabel className='form_label_input' shrink htmlFor="address1">
															Address Line 1 
															{/* <span className='required_span'>*</span> */}
														</InputLabel>
														<TextField
															{...field}
															id='address1'
															className='form_input_holder'
															variant='outlined'
															label=''
															fullWidth
															inputProps={{ readOnly: false }}
															InputLabelProps={{ shrink: true }}
															error={!!errors.address_1}
															helperText={errors?.address_1?.message}
															FormHelperTextProps={{
																style: {
																	margin: 0,
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
															style={{ backgroundColor: '#fff' }}

														/>
													</>
												)}
											/>
										</Grid>

										<Grid item lg={12} xl={12} md={12}>
											<Controller
												name="address_2"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<InputLabel className='form_label_input' shrink htmlFor="address2">
															Address Line 2
														</InputLabel>
														<TextField
															{...field}
															id='address2'
															className='form_input_holder'
															variant='outlined'
															label=''
															fullWidth
															InputLabelProps={{ shrink: true }}
															FormHelperTextProps={{
																style: {
																	margin: 0,
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
															style={{ backgroundColor: '#fff' }}
														/>
													</>
												)}
											/>
										</Grid>

										<Grid item lg={6} xl={6} md={12}>
											<Controller
												name="city"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<InputLabel className='form_label_input' shrink htmlFor="city">
															City
														</InputLabel>
														<TextField
															{...field}
															id='city'
															className='form_input_holder'
															variant='outlined'
															label=''
															fullWidth
															inputProps={{ readOnly: false }}
															InputLabelProps={{ shrink: true }}
															error={!!errors.city}
															helperText={errors?.city?.message}
															FormHelperTextProps={{
																style: {
																	margin: 0,
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
															style={{ backgroundColor: '#fff' }}

														/>
													</>
												)}
											/>
										</Grid>

										<Grid item lg={6} xl={6} md={12}>
											<Controller
												name="state"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<InputLabel className='form_label_input' shrink htmlFor="state">
															State/Province
														</InputLabel>
														<TextField
															{...field}
															id='state'
															className='form_input_holder'
															variant='outlined'
															label=''
															fullWidth
															InputLabelProps={{ shrink: true }}
															error={!!errors.state}
															helperText={errors?.state?.message}
															FormHelperTextProps={{
																style: {
																	margin: 0,
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
															style={{ backgroundColor: '#fff' }}
														/>
													</>
												)}
											/>
										</Grid>

										<Grid item lg={6} xl={6} md={12}>
											<Controller
												name="zip"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<InputLabel className='form_label_input' shrink htmlFor="zip">
															Zip Code
														</InputLabel>
														<TextField
															{...field}
															id='zip'
															className='form_input_holder'
															variant='outlined'
															label=''
															fullWidth
															inputProps={{ readOnly: false }}
															InputLabelProps={{ shrink: true }}
															error={!!errors.zip}
															helperText={errors?.zip?.message}
															FormHelperTextProps={{
																style: {
																	margin: 0,
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
															style={{ backgroundColor: '#fff' }}

														/>
													</>
												)}
											/>
										</Grid>

										<Grid item lg={6} xl={6} md={12}>
											<Controller
												name="country"
												className="mb-16"
												control={control}
												render={({ field: { ref, ...field }, fieldState: { error } }) => (
													<>
														<InputLabel className='form_label_input' shrink htmlFor="country">
															Country
														</InputLabel>
														<Autocomplete
															{...field}
															id='country'
															className='form_input_holder autocomplete_holder w-100'
															options={countries}
															disablePortal
															onChange={(event, value) => field.onChange(value)}
															PaperComponent={({ children }) => (
																<Paper className="autocomplete_holder">{children}</Paper>
															)}
															error={!!errors.country}
															helperText={errors?.country?.message}
															getOptionLabel={option => option.name ?? ''}
															renderInput={(params) => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			style={{ background: 'white' }}
																			className='w-100'
																			{...params}
																			label=""
																			inputRef={ref}
																			variant="outlined"
																			fullWidth
																		/>
																	</div>
																)
															}}
														/>
													</>
												)}
											/>
										</Grid>
									</Grid>
								</div>
							}
						</div>
					</div>
				</form>
			

			{/* edit photo modal */}
			<Dialog
				className="cstm_dialog_modal"
        		maxWidth='md'
				open={openEditPhoto}
				// onClose={handleCloseEditPhoto}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<div className='heading_holder flexbox_holder items-center w-full'>
						<h2 className="main_heading">Upload Photo</h2>
						<Button className='transparent_icon_btn' onClick={handleCloseEditPhoto}>
						<i className="icon ti ti-x" />
						</Button>
					</div>
				</DialogTitle>
				<DialogContent>

					{
						imgSrc === '' ?  (
							<div className='cstm_dropzone_holder' >
								<div>
									<div>
										<Icon fontSize="large" color="action" title="Upload Image">
											cloud_upload
										</Icon>
									</div>
								
									<div className='my-5'>
										<span>Drag & Drop the Files here</span>
									</div>
								</div>
								
								<div className='my-20'>
									<span>Or</span>
								</div>
								<div>
									<input type="file" 
										name="Browse File" 
										className='browse-button'
										onChange={onSelectFileEvent}
										accept="image/*"
									/>
								</div>
								<>
									{/* <p style={{marginTop: 5}}>Name of the Uploaded file</p> */}
								</>
							</div>
						) : (
							<ReactCrop
								crop={crop}
								onChange={(_, percentCrop) => setCrop(percentCrop)}
								onComplete={(c) => setCompletedCrop(c)}
								aspect={false}
								// minWidth={400}
								minHeight={100}
								// circularCrop
							>
								<img
									ref={imgRef}
									alt="Crop me"
									src={imgSrc}
									style={{ transform: `scale(1) rotate(0)`}}
									onLoad={onImageLoad}
								/>
							</ReactCrop>
						)
					}
					{!!completedCrop && (
						<>
							<div style={{display: 'none' }}>
								<canvas
									ref={previewCanvasRef}
									style={{
										border: '1px solid black',
										objectFit: 'contain',
										width: completedCrop.width,
										height: completedCrop.height,
									}}
								/>
							</div>
						</>
					)}
					{/* loader */}
					{
						photoUploadLoader && (
							<div style={{height: '100px'}} className='relative'>
								<div id="fuse-splash-screen" className='fuse_light_bg'>
									<div className="spinner-wrapper">
										<div className="spinner">
											<div className="inner">
												<div className="gap"/>
												<div className="left">
													<div className="half-circle"/>
												</div>
												<div className="right">
													<div className="half-circle"/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					}
					
				</DialogContent>
				<DialogActions className="action_footer_holder">
					<Button onClick={handleCloseEditPhoto} disabled={photoUploadLoader} className="button secondary_btn">Cancel</Button>
					<Button onClick={handleClickUploadImage} disabled={photoUploadLoader} className="button primary_btn">
						upload
					</Button>
				</DialogActions>
			</Dialog>

			{/* preview photo modal */}
			<Dialog
				className="cstm_dialog_modal"
        		maxWidth='md'
				open={openPreviewPhoto}
				onClose={handleClosePreviewPhoto}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<div className='heading_holder flexbox_holder items-center w-full'>
						<h2 className="main_heading">Preview Photo</h2>
						<Button className='transparent_icon_btn' onClick={handleClosePreviewPhoto}>
						<i className="icon ti ti-x" />
						</Button>
					</div>
				</DialogTitle>
				<DialogContent>
					<div style={{minWidth: '800px'}}>      
						Content
					</div>
				</DialogContent>
				<DialogActions className="action_footer_holder">
					<Button onClick={handleClosePreviewPhoto} className="button secondary_btn">Cancel</Button>
					<Button onClick={handleClosePreviewPhoto} className="button primary_btn">
						Save
					</Button>
				</DialogActions>
			</Dialog>

			{
				openConfirmRemovePhotoModal &&  
				<Dialog
					className="cstm_dialog_modal small text-center"
					open={openConfirmRemovePhotoModal}
					// onClose={handleCloseOpenDeleteNotification}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
					<div className='heading_holder flex justify-end items-center w-full'>
						<Button className='transparent_icon_btn' onClick={handleCloseOpenConfirmRemovePhotoModal}>
						<i className="icon ti ti-x" />
						</Button>
					</div>
					</DialogTitle>
					<DialogContent>
					<div className="warning_content_holder">
						<div className="exclamation_icon_holder yellow">
						<i className="ti ti-exclamation-mark" />
						</div>
						<h2 className="heading">Are you sure to remove the photo?</h2>
						{/* <p className="sub_content">Your progress will be lost if you proceed with this.</p> */}
					</div>
					</DialogContent>
					<DialogActions className="action_footer_holder">
					<Button onClick={handleCloseOpenConfirmRemovePhotoModal} className="secondary_btn">No, go back!</Button>
					<Button onClick={removeImage} className="primary_btn">
						Yes, I'm sure!
					</Button>
					</DialogActions>
			  	</Dialog>
			}

			{/* verify phone number */}
      {openPhoneVerificationModal && (
        <PhoneNumberVerificationModal
          open={openPhoneVerificationModal}
          close={handleCloseOpenPhoneVerificationModal}
        />
      )}
	
		</div>
	)
}

export default EditUserProfile
