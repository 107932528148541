import React, { useEffect, useState, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';

import axios from "@fuse/utils/axios";
import { duration } from 'moment';
import Status from '../Status';
import { getLocalTimeFromUTC } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({

    root: {
        // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
        //   border: '2px solid black',
        //   display: 'none !important'
        // }
        '& .MuiDataGrid-columnsContainer': {
            background: '#8f8f8f26'
        }
    }
});

const columns = [
    // { field: 'ticket_number', headerName: 'Ticket Number', width: 100 },
    { field: 'severity', headerName: 'Severity', width: 200 },
    { field: 'time', headerName: 'Time', width: 250 },
    { field: 'recovery_time', headerName: 'Recovery time', width: 200 },
    {
        field: 'status', headerName: 'Status', width: 150,
        renderCell: (params) => {
            if (params.value == "RESOLVED") return (<span style={{ color: "green" }}>{params.value}</span>)
            if (params.value == 'PROBLEM') return (<span style={{ color: "orange" }}>{params.value}</span>)
        }
    },
    {
        field: 'problem', headerName: 'Description', width: 400,
        renderCell: (params) => {
            if (params.value?.length > 50) {
                return (
                    <Tooltip title={params.value} placement="top-start">
                        <span className='activitiesTab-descText'>{params.value}</span>
                    </Tooltip>
                )
            } 
                return (<span>{params.value}</span>)
            
        }
    },
    { field: 'duration', headerName: 'Duration', width: 200 }
];


function IncidentsTab(props) {
    const classes = useStyles();
    const tableHeaders = [
        {
          field: "severity",
          headerName: "Severity",
          headerComponentFramework: CustomFilter,		
          minWidth: 350,
          maxWidth: 350
        },
        {
          field: "time",
          headerName: "Time",
          minWidth: 170,
          headerComponentFramework: CustomFilter		
        },
        {
          field: "problem",
          headerName: "Problem",
          minWidth: 200,
          headerComponentFramework: CustomFilter	
        },
        {
          field: "recovery_time",
          headerName: "Recovery Time",
          minWidth: 170,
          headerComponentFramework: CustomFilter	
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 120,
          headerComponentFramework: CustomFilter	
        },
        {
          field: "duration",
          headerName: "Duration",
          // cellRendererFramework: AssetActionComponent,
          minWidth: 140,
          maxWidth: 140
        }
    ];
    
    const gridRef = useRef()
    
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
     
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };
    const [rowData, setRowData] = useState([]);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

    const [incidents, setIncidents] = useState([])
    const [loading, setLoading] = useState(false)

    const { assetDetails } = props

    function formtIncidents(incidents) {
        return incidents.map((item) => {
            return {
                id: item.id,
                // ticket_number: '27927',
                severity: item.event_severity,
                time: getLocalTimeFromUTC(item.created_at),
                recovery_time: item.recovery_time,
                status: item.event_status,
                problem: item.alert_subject,
                duration: item.event_duration
            }
        })
    }
    function getIncidents() {
        if (!assetDetails) return
        setLoading(true)
        axios.get(`/assets/fetch-asset-incident/${assetDetails.id}`, {
        }).then((res) => {
            const { incident } = res.data.data;
            setIncidents(formtIncidents(incident))
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getIncidents()
    }, [])

    if (loading) {
        return (
            <FuseLoading />
        )
    }

    if (!incidents.length) {
        return (
            <>
                <div 
                    className="ag-theme-alpine" 
                    style={{
                    width: "100%",
                    height: windowHeight+105,
                    fontSize:'12px'
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        animateRows
                        onGridReady = { onGridReady }
                        paginationPageSize={10}
                        pagination
                        defaultColDef={{
                            resizable: true
                        }}
                    >
                    {tableHeaders.map(header => {
                        if( header.field === "problem"){
                            return(
                            <AgGridColumn 
                                key = {header.field} 
                                field = {header.field}
                                headerName = {header.headerName}  
                                width = { header.width }
                                minWidth = { header.minWidth }
                                maxWidth = { header.maxWidth }
                                sortable = {false}
                                flex = {1}
                                filter = "text"
                                // cellRendererFramework={(event) => (<ParametersComponent data={event.data}/>)}
                                wrapText
                                autoHeight
                                // floatingFilter = {true}
                                headerComponentFramework = {header.headerComponentFramework}								
                            />
                            )
                        }if( header.field === "status"){
                            return(
                            <AgGridColumn 
                                key = {header.field} 
                                field = {header.field}
                                headerName = {header.headerName}  
                                width = { header.width }
                                minWidth = { header.minWidth }
                                maxWidth = { header.maxWidth }
                                sortable = {false}
                                flex = {1}
                                filter = "text"
                                cellRendererFramework={(event) => {
                                    return (
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                backgroundColor: '#FFFAEB',
                                                color: '#F79009',
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                border: '1px solid #FEC84B',
                                                borderRadius: '16px',
                                                padding: '2px 16px',
                                                marginTop: '4px'
                                            }}
                                        >
                                            Problem
                                        </span>                                            
                                    );
                                }}
                                wrapText
                                headerComponentFramework = {header.headerComponentFramework}								
                            />
                            )
                        }
                            return(
                            <AgGridColumn 
                                key = {header.field} 
                                field = {header.field}
                                headerName = {header.headerName}  
                                width = { header.width }
                                minWidth = { header.minWidth }
                                maxWidth = { header.maxWidth }
                                sortable = {false}
                                flex = {1}
                                filter = "text"
                                wrapText
                                headerComponentFramework = {header.headerComponentFramework}								
                            />
                            )
                        
                    })}
                    </AgGridReact>
                </div>
                {/* <Status /> */}
            </>
        )
    }

    return (
        <>
            <div className={classes.root} style={{ height: 290, width: '100%', overflow: "auto" }}>
                <DataGrid
                    rows={incidents}
                    columns={columns}
                    hideFooter
                />
            </div>
        </>
    )
}

export default withRouter(IncidentsTab)
