import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import Editor from '@fuse/components/Editor';
import { Button, DialogTitle, TextField, Typography, Dialog,DialogContent, DialogActions } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const AddAccessoryCategoryFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
})  => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [ description, setDescription ] = useState('')

    const schema = yup.object({
        accessory_category_name: yup.string().trim().required('Accessory category name is required!')
    })

    const defaultValues = {
        accessory_category_name: ''
    }

    const { 
        control, 
        formState:{errors}, 
        handleSubmit,
        reset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    function onEditorContentChange(content) {
		setDescription(content)
	}

    const onSubmit = async(data) => {
        const payload = {
            entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
            accessory_category_name: data.accessory_category_name,
            description: description
        }

        axios.post('/accessory-category/add', payload).then(response => {
            // response.data.data.new_category
            if(response.status == 201){
                toast.error(response.data.message)
                return
            } 
            toast.success('Accessory Category added Successfully.')
            reset(defaultValues)
            newDataAddedEvent(response.data.data.new_accessory_category)
        }).catch(err => {
            console.log('69: ',err, err?.response);
            toast.error(err?.response?.data?.message)
        })
    }

    return (
        <Dialog
            className="cstm_dialog_modal small text-center"
            open={openAddStatusModalStateData}
            // onClose={handleOpenCloseAddModalEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div className='heading_holder flexbox_holder items-center w-full'>
                    <h2 className="main_heading">Add Accessory Category</h2>
                    <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                        <i className="icon ti ti-x" />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-left">
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Accessory Category
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="accessory_category_name"
                            // className="mb-16"
                            defaultValue={defaultValues.accessory_category_name}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='Status Name'
                                        required
                                        fullWidth
                                        error={!!errors.accessory_category_name}
                                        helperText={errors?.accessory_category_name?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Description
                        </Typography>
                        <Editor
                            editorContent={description}
                            onEditorContentChange={onEditorContentChange}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="action_footer_holder">
                <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                <Button onClick={handleSubmit(onSubmit)} className="primary_btn">
                    Save and Select
                </Button>
            </DialogActions>
        </Dialog>

    );

}

export default AddAccessoryCategoryFromQuickAction;