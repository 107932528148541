import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import {TextField,MenuItem,SvgIcon, FormControlLabel} from '@material-ui/core';
import { useState, useEffect, Fragment } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import useToast from '@fuse/hooks/useToast'
import { UploadIcon } from '@fuse/icons';
import { openCsvDialog } from "../store/csvDialogReducer"
import { usePermission } from '@fuse/hooks';
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';


const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	const [currentFilter, setCurrentFilter] = useState('');
	const [currentFilterLabel, setCurrentFilterLabel] = useState('');
	const { hasPermission } = usePermission()
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	const createServiceProviderPermission = hasPermission('add-service_provider')
	const addReportPermission = hasPermission('add-report')

	function handleSearchReset(){
		props.handleSearchReset();
		props.handleSearchSuggestions("", currentFilter)
		setSearchQuery("");
	}

	useEffect(() => {
		console.log(props.searchFilters);
		
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilter(props.searchFilters[0].field)
		}
	},[props.searchFilters])

	useEffect(() => {
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilterLabel(props.searchFilters.find((item) => item.field == currentFilter)?.label || "");
		}
	},[currentFilter]);

	useEffect(() => {
		props.onSearchChange(search, currentFilter)
	},[search, currentFilter])

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Service Providers List
				</Typography>
			</div>
			<div style={{display:'flex'}}>
				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}
				{createServiceProviderPermission  && <div className={classes.buttonContainer}>
					<Button
					onClick={() => dispatch(openCsvDialog())}
					variant="contained"
					color="secondary"
					className={`w-full ${classes.buttonSquare}`}
					style={{width:'150px',marginRight: '10px'}}
					startIcon={
						<SvgIcon fontSize="small"><UploadIcon color="default" /></SvgIcon>
					}>
						Upload CSV
					</Button>
				</div>}
				{/* {(createManufacturersPermission == 'all' || createManufacturersPermission?.length > 0) && <div className={classes.buttonContainer}> */}
				{createServiceProviderPermission  && <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'200px', marginRight: '10px'}}
						onClick = {()=>dispatch(openFormViewerDialog())}
						startIcon={
						<Icon fontSize="small" title="Add Service Provider">
							add
						</Icon>}>
						Add Service Provider
					</Button>
				</div>}
				{/* <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight:"20px"}}
						onClick = {()=>dispatch(openReportViewerDialog())}
						startIcon={
						<Icon fontSize="large" title="Save View">
							save
						</Icon>}>
							Save View
					</Button>
				</div> */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px"}}
						>
							<TextField
								placeholder="Search Service Providers"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>

	
		</div>
	)
}

export default DynamicFieldsHeader;
