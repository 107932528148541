// import FuseScrollbars from '@fuse/core/FuseScrollbars';
// import _ from '@lodash';
// import Checkbox from '@material-ui/core/Checkbox';
// import Icon from '@material-ui/core/Icon';
// import Typography from '@material-ui/core/Typography';
// import { motion } from 'framer-motion';
// import { useEffect, useState } from 'react';
// import IconButton from '@material-ui/core/IconButton';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import { useDispatch, useSelector } from 'react-redux';
// import FuseLoading from '@fuse/core/FuseLoading';
// import { makeStyles } from '@material-ui/core/styles';
// import Chip from '@material-ui/core/Chip';
// import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button, AccordionActions } from '@material-ui/core';
// import axiosgeneral from "@fuse/utils/axios";
// import Grid from '@material-ui/core/Grid';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';

// const useStyles = makeStyles({
// 	layoutRoot: {},
// 	head: {
// 		position: "sticky",
// 		top: 0
// 	  },
// 	  root: {
// 		// minWidth: 275,
// 	  },
// 	  title: {
// 		fontSize: 14,
// 		marginBottom: 10,
// 	  },
// 	  pos: {
// 		marginBottom: 12,
// 	  },
// });

// function GridView(props) {
//     const classes = useStyles();

// 	function getFormatedHeaderName(header){
// 		return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
// 	}

// 	console.log(props);

// 	return (
// 		<Grid container spacing={3}  className="mb-3">{	
// 			props.endUsersAccounts.map((acc, index) =>(
// 				<Grid item xs={"auto"} key={index + 1}>
// 					<Card className={classes.root} variant="outlined">
// 						<CardContent>
// 							<Typography className={classes.title} gutterBottom>
// 								{acc.company_name}
// 							</Typography>

// 							{Object.entries(acc).map(([column, value], index) => (
// 								!["id", "contract_number"].includes(column) && (
// 									<Typography key={index} gutterBottom>
// 										{getFormatedHeaderName(column)}: {value}
// 									</Typography>
// 								)
// 							))}
// 						</CardContent>
// 					</Card>
// 				</Grid>
// 			))
// 		}</Grid>
// 	);

// }

// export default GridView


import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button, AccordionActions } from '@material-ui/core';
import axiosgeneral from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	  },
	  root: {
		width: "100%",
	  },
	  title: {
		fontSize: 14,
	  },
	  pos: {
		marginBottom: 12,
	  },
	  borderRightLight: {
		borderRight:"1px solid rgba(0,0,0,0.12)"
	  },
	  pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "center"
	},
});

function GridView(props) {
    const classes = useStyles();

    // console.log(props);

	return (
        
					<Grid container spacing={3}>
						{
							
					props.endUsersAccounts
					.map((acc,index) => {
										return (
										<Grid key={index} item sm={4} xs={12}>
										<Card className={classes.root} variant="outlined"
										onClick={(e)=>{if(props.gotoCompanyDetail){props.gotoCompanyDetail(acc.id)}}}
										>
										<CardHeader title={acc.company_name} subheader={`company_id: ${acc.company_id}`}/>
										<Divider/>
										<CardContent>
										<Grid container spacing={1}>
											<Grid item xs={6} className={`${classes.borderRightLight} flex justify-between`}>
												<Typography variant="body2" component="p" color="textSecondary">
													City 
												</Typography>
												<Typography variant="body2" component="p" color="textPrimary">
													{acc.city}
												</Typography>
											</Grid>
											<Grid item xs={6} className="flex justify-between">
												<Typography variant="body2" component="p" color="textSecondary">
													Bill to code
												</Typography>
												<Typography variant="body2" component="p" color="textPrimary">
													{acc.bill_to_code}
												</Typography>
											</Grid>
											<Grid item xs={6} className={`${classes.borderRightLight} flex justify-between`}>
												<Typography variant="body2" component="p" color="textSecondary">
													State
												</Typography>
												<Typography variant="body2" component="p" color="textPrimary">
													{ acc.billing_location_state }
												</Typography>
											</Grid>
											<Grid item xs={6} className="flex justify-between">
												<Typography variant="body2" component="span" color="textSecondary">
													Zip
												</Typography>
												
												<Typography variant="body2" component="span" color="textPrimary" className={`float-right`} style={{marginLeft:"auto"}}>
													{ acc.zip }
												</Typography>
											</Grid>
											{/* {Object.keys(acc).map(key=>{
												if(key != 'id'){
													return(
														<Grid item xs={6}>
														<Typography variant="body2" component="p" color="textSecondary">
														{key.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
														</Typography>
														<Typography variant="body2" component="p" color="textPrimary">
														{acc[key]}
														</Typography>
														</Grid>
													)
												}
												
											})} */}
										</Grid>
										</CardContent>
										{/* <CardActions>
											<Button size="small">Learn More</Button>
										</CardActions> */}
										</Card>
										</Grid>)
									})
						}
					</Grid>
	);

}

export default GridView
