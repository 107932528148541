const initialState = {
	open: false,
}

const actionTypes = {
    OPEN_CSV_DIALOG: "OPEN_CSV_DIALOG",
    CLOSE_CSV_DIALOG: "CLOSE_CSV_DIALOG"
}

export function openCsvDialog(){
    return {
        type: actionTypes.OPEN_CSV_DIALOG,
    }
}

export function closeCsvDialog(){
    return {
        type: actionTypes.CLOSE_CSV_DIALOG,
    }
}

export default function (state = initialState, action) {
	switch (action.type) {
	  case actionTypes.OPEN_CSV_DIALOG:{
		  return { 
			  ...state,
			  open: true,
		  }
	  }
	  case actionTypes.CLOSE_CSV_DIALOG:{
			return { 
				...state,
				open: false,
			}
	  }
	  default:
		return state
	}
}