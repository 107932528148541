import { useEffect } from "react"
import { useDispatch } from 'react-redux';
import usePusher from '@fuse/hooks/usePusher';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { initPusher, updateUserCount, resetPusher } from 'app/store/fuse/pusherSlice';

export default function PusherContainer(props) {
	const dispatch = useDispatch();
	const user = useCurrentUser().data;
	const { isReady, channel } = usePusher();

	const platformCustomerId = user.platform_customer_id;
	const userID = user.id
	const token = user.token;

	useEffect(() => {
		if (platformCustomerId && token) {
			dispatch(initPusher({
				/*In SAM Notifications are user specific instead of company
				thats why while creating channel name userID is used.*/
				platformCustomerId: userID,
				token,
			}));
		} else {
			dispatch(resetPusher());
		}
	}, [platformCustomerId]);

	useEffect(() => {
		if (isReady) {
			channel.bind("pusher:subscription_succeeded", (member) => {
				dispatch(updateUserCount())
			});

			channel.bind("pusher:member_added", (member) => {
				dispatch(updateUserCount())
			});

			channel.bind("pusher:member_removed", (member) => {
				dispatch(updateUserCount())
			});
		}
	}, [isReady]);

	return props.children;
}