// import DemoContent from '@fuse/core/DemoContent';
import DynamicFieldsTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect, Fragment } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { connect } from 'react-redux';
import axiosgeneral from "@fuse/utils/axios";
import { hideMessage, showMessage } from '../../../store/fuse/messageSlice';
import { setPlatformModules,setAttributeTypes } from '../../../store/fuse/platformModuleSlice';
import { openDialog, closeDialog } from '../../../store/fuse/dialogSlice';
import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button } from '@material-ui/core';
import AttributeAddForm from './attributeAddForm';
import AttributeUpdateForm from './attributeUpdateForm';
 
const useStyles = makeStyles({
	layoutRoot: {}
});

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);;
	const [selectedTab, setSelectedTab] = useState(0);

	useEffect(()=>{
		setLoading(true);
		axiosgeneral.get('/dynamic-attributes/fetch-all')
		.then(res => {
			props.setPlatformModules(res.data.data);
			// console.log(platformModules);
			setLoading(false);
			props.showMessage({ message: 'Modules fetched!',autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
		})
		.catch(err => {
			setLoading(false);
			props.showMessage({ message: 'Could not fetch modules',autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'error' });
		})

		axiosgeneral.get('/dynamic-attributes/get-attribute-types')
		.then(res => {
			props.setAttributeTypes(res.data.data);
		})
		.catch(err => {
			console.log(err)
		})
	},[]);

	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};

	const openUpdateAttributeDialog = (platformModuleId,attr) => {
		props.openDialog({
			children: (
				<Fragment>
					<DialogTitle id="alert-dialog-title">Update field</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description" >
							<AttributeUpdateForm attributeTypes={props.attributeTypes} 
									updateAttribute={updateAttribute}
									platformModuleId={platformModuleId}
									prevAttr={attr}/>
						</DialogContentText>
					</DialogContent>
				</Fragment>
				 ),
			maxWidth: 'sm',
			fullWidth:true
			 })
	}

	const updateAttribute = (data) => {
		// setLoading(true);
		data = {...data, values: String(data.values)}
		axiosgeneral.post('/dynamic-attributes/update',data)
		.then(res => {
			// setLoading(false);
			const added = res.data.data;
			if(res.data.statusCode == 250)
			{
				props.showMessage({ message: res.data.message,autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'error' });
				return
			}
			props.showMessage({ message: 'Attribute updated successfully!',autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
			props.closeDialog();
			let modifiedModules = props.platformModules.map(module => {
				if(module.id == added[0].platform_module_id){

					return {...module,platform_customer_module_attributes:added};
					console.log('deleting')
				}
				return module
			});
			props.setPlatformModules(modifiedModules)
		})
		.catch(err => {
			// setLoading(false);
			props.showMessage({ message: 'Could not update field',autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'error' });
		})
	}

	const openAddAttributeDialog = (platformModuleId) => {
		props.openDialog({
			children: (
				<Fragment>
					<DialogTitle id="alert-dialog-title">Create field</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<AttributeAddForm attributeTypes={props.attributeTypes} 
									addAttribute={addAttribute}
									platformModuleId={platformModuleId}/>
						</DialogContentText>
					</DialogContent>
				</Fragment>
				 ),
				 
			maxWidth: 'sm',
			fullWidth:true
			 })
	}

	const addAttribute = (data) => {
		if(data.attribute_name.trim() == '')
		{
			props.showMessage({ message: 'Invalid attribute name',autoHideDuration: 6000,//ms
			anchorOrigin: {
				vertical  : 'bottom',//top bottom
				horizontal: 'right'//left center right
			},
			variant: 'error' });
			return
		}
		// setLoading(true);
		axiosgeneral.post('/dynamic-attributes/create',data)
		.then(res => {
			// setLoading(false);
			const added = res.data.data;
			if(res.data.statusCode == 250)
			{
				props.showMessage({ message: res.data.message,autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'error' });
				return
			}
			props.showMessage({ message: 'Attribute created successfully!',autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
			props.closeDialog();
			let modifiedModules = props.platformModules.map(module => {
				if(module.id == added[0].platform_module_id){

					return {...module,platform_customer_module_attributes:added};
					console.log('deleting')
				}
				return module
			});
			props.setPlatformModules(modifiedModules)
		})
		.catch(err => {
			// setLoading(false);
			console.log(err);
			props.showMessage({ message: 'Could not create field',autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'error' });
		})
	}

	const setNewPlatformModule = (newModule) => {
		props.setPlatformModules(
			props.platformModules.map(module => {
				if(newModule.id == module.id){
					return newModule;
				}
				return module;
			})
		)
	}

	const consfirmDelete = (attribute) => {
		props.openDialog({
			children: (
				<Fragment>
					<DialogTitle id="alert-dialog-title">Do you want to delete this field?</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{`${attribute.label} of type ${attribute.attribute_types.attribute_type}`}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=> deleteAttribute(attribute)} color="primary">
							Delete
						</Button>
						<Button onClick={()=> props.closeDialog()} color="primary" autoFocus>
							Close
						</Button>
					</DialogActions>
				</Fragment>
				 )
			 })
	}

	const deleteAttribute = (attribute) => {
		// setLoading(true);
		axiosgeneral.post('/dynamic-attributes/drop',{platform_customer_module_attribute_id:attribute.id})
		.then(res => {
			const deleted = res.data.data
			console.log(deleted)
			// setLoading(false);

			let modifiedModules = props.platformModules.map(module => {
				if(module.id == deleted.platform_module_id){

					let attributes = module.platform_customer_module_attributes.filter(attribute => {
						return attribute.id != deleted.id
					})
					return {...module,platform_customer_module_attributes:attributes};
					console.log('deleting')
				}
				return module
			});
			console.log(modifiedModules);
			props.setPlatformModules(modifiedModules);
			props.showMessage({ message: 'Attribute deleted successfully!',autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'success' });
			props.closeDialog();
		})
		.catch(err => {
			// setLoading(false);
			console.log(err);
			props.showMessage({ message: 'Could not delete the field',autoHideDuration: 6000,//ms
				anchorOrigin: {
					vertical  : 'bottom',//top bottom
					horizontal: 'right'//left center right
				},
				variant: 'error' });
			props.closeDialog();
		})
	}

	if(loading){
		return(<FuseLoading />)
	}else{
	return (
		<FusePageCarded
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0'
			}}
			header={
				<DynamicFieldsHeader platformModules={props.platformModules} selectedTab={selectedTab} setSelectedTab={(val)=>setSelectedTab(val)}/>
			}
			contentToolbar={
				<Tabs
					value={selectedTab}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="off"
					className="w-full h-64"
				>
					{
						// console.log(props.platformModules)
						props.platformModules.map(module => {
							return <Tab key={`module_tab_${module.id}`} className="h-64" label={module.label} />
						})
					}
				</Tabs>
			}
			content={
				<div className="p-24">

					{
						props.platformModules.map(module => {
							if(selectedTab === module.id -1){
								return (<DynamicFieldsTable 
									key={module.id} 
									consfirmDelete={consfirmDelete}
									openAddAttributeDialog={openAddAttributeDialog}
									platformModuleId={module.id}
									platformModule = {module}
									setNewPlatformModule={setNewPlatformModule}
									openUpdateAttributeDialog={openUpdateAttributeDialog}
									/>)
							}
						})
					}
				</div>
			}
			innerScroll
		/>
	)
		}
}

const mapStateToProps = state => {
	return {
		user: state.auth.user,
		platformModules: state.fuse.platformModules.platformModules,
		attributeTypes: state.fuse.platformModules.attributeTypes
	}
}

const mapDispatchToProps = (dispatch) => ({
	showMessage: data => dispatch(showMessage(data)),
	setPlatformModules: data => dispatch(setPlatformModules(data)),
	setAttributeTypes: data => dispatch(setAttributeTypes(data)),
	openDialog: data => dispatch(openDialog(data)),
	closeDialog: data => dispatch(closeDialog(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(CardedFullWidth2TabbedSample);

