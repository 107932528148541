import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import CsvUpload from './CsvUpload';
import {Card, CardContent, Menu,MenuItem} from '@material-ui/core';
import axios from "@fuse/utils/axios";
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
import { usePermission } from '@fuse/hooks';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
// import SaveReportDialog from './saveReportDialog';
import AddAssetStatus from './addAssetStatus';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import SaveReportDialog from '../../reports/components/saveReportDialog';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [invoices, setAssetStatus] = useState([]);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [logs,setLogs] = useState([]);
	const { hasPermission } = usePermission()
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const viewAssetStatusPermission = hasPermission('view-asset_status')
	const addAssetStatusPermission = hasPermission('add-asset_status')
	const updateAssetStatusPermission = hasPermission('update-asset_status')
	const deleteAssetStatusPermission = hasPermission('delete-asset_status')

	function getAssetStatus(){
		// if(!viewAssetStatusPermission){
		// 	return;
		// }
		return axios.get("/asset-status?type=hardware").then((res) => {
			const { data } = res.data;
			setAssetStatus(formatData(data))
		}).catch((err) => {
			console.log(err)
		})
	}
	
	function formatData(data){
		return data.map((item) => {
			const tempDescription = item.status_description;
			const tempElement = document.createElement('div');
			tempElement.innerHTML = tempDescription;
			item.status_description = item.status_description == '' ?  'No Description' : tempElement.textContent
			return {
				type_: item.type.charAt(0).toUpperCase()+ item.type.slice(1),
				status_name_: item.status_name,
				id_: item.entity_external_platform_id,
				status_description: item.status_description == '' ?  'No Description' : tempElement.textContent,
				original_info:{
					status_description: item.status_description === 'No Description' ? '' : tempDescription
				},
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
					'source'
				]),
				source: item.source
			}
		})
	}

	function refreshList(){
		getAssetStatus();
	}

	function formatLogs(data){
        return data.map((item)=>{
            return {
                id:item.id,
                action_id: `act${item.id}`,
                action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
								status_name: item?.asset_status?.status_name || item.status_name,
                name: item.user_name,
                action: item.action_name,
                description: item.comment,
                previous_value: item.previous_data,
                current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
            }
        })
    }

	function getLogs(){
		axios.get('/asset-status/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	useEffect(()=>{
		setLoading(true)
		getAssetStatus().finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	} else {
		return (
			<>
				{( addAssetStatusPermission || updateAssetStatusPermission) && 
					<CsvUpload 
						refresh={refreshList}
						viewAssetStatusPermission = { viewAssetStatusPermission }
						addAssetStatusPermission = { addAssetStatusPermission }
						updateAssetStatusPermission = { updateAssetStatusPermission }
					/>
				}

				<SaveReportDialog tableName={"asset status"}/>

				<FusePageCarded
					classes={{
						root: classes.layoutRoot,
						toolbar: 'p-0',
						topBg:classes.topBg,
						contentCard:classes.contentCard
					}}
					// header={
					// 	<DynamicFieldsHeader 
					// 		handleSearch={handleSearch} 
					// 		searchSuggestions={searchSuggestions} 
					// 		clearSearch={clearFilters}
					// 		searchFilters={searchFilters}
					// 		handleSearchSuggestions={handelSearchSuggestions}
					// 		handleSearchReset={handleSearchReset}
					// 		onSearchChange={(searchValue, fieldValue) => {
					// 			setCurrentSearchParameters({query: searchValue, field: fieldValue})
					// 		}}
					// 	/>
					// }
					// contentToolbar={
					// 	<>
					// 		<Tabs
					// 			value={selectedTab}
					// 			onChange={handleTabChange}
					// 			indicatorColor="primary"
					// 			textColor="primary"
					// 			variant="scrollable"
					// 			scrollButtons="off"
					// 			className="w-full h-64"
					// 		>
					// 			<Tab key="end_user_tab_1" className="h-64" label="All Asset Status" />
					// 			{viewAssetStatusPermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}
								
					// 		</Tabs>
					// 		<div>
					// 			{/* <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
					// 				{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
					// 			</IconButton> */}
					// 			<Menu
					// 				id="simple-menu"
					// 				anchorEl={anchorEl}
					// 				keepMounted
					// 				open={viewMenuOpened}
					// 				onClose={handleViewMenuClose}
					// 			>
					// 				{	
					// 					views.map((view,index) => {
					// 						return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
					// 					})
					// 				}
					// 			</Menu>
					// 		</div>
					// 	</>
					// }
					content={
						<>
							<div className={selectedTab !== 0 ? 'hidden' : ''}>
								{
									loading && <FuseLoading />
								}
								{
									!loading && <SalesOrderTable 
										endUsersAccounts={invoices}
										selectedView={selectedView}
										refresh={refreshList}
										viewAssetStatusPermission ={viewAssetStatusPermission}
										addAssetStatusPermission = {addAssetStatusPermission}
										updateAssetStatusPermission = {updateAssetStatusPermission}
										deleteAssetStatusPermission = {deleteAssetStatusPermission}
									/>
								}
							</div>
							<AddAssetStatus getAssetStatus = {getAssetStatus}/>
							
							{viewAssetStatusPermission && 
								<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
									{/* <ActivitieLogs logs = {logs} /> */}
								{Boolean(logs.length) ? <ActivityAgGridListing module='asset_status' logs={logs}/> : <FuseLoading/> }
								</div>
							}
							{!viewAssetStatusPermission && 
								<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
									<Card>
										<CardContent>You don't have the view permission for Asset Status.</CardContent>
									</Card>
								</div>
							}
						</>
					}
					innerScroll
				/>
			</>
		)
	}
}

export default withReducer('assetStatusApp', reducer)(CardedFullWidth2TabbedSample);
