import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useState,useEffect,useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link ,useParams } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import axios from "@fuse/utils/axios";
import useCurrentUser from '@fuse/hooks/useCurrentUser';

import useToast from '@fuse/hooks/useToast';
import { useSelector, useDispatch } from 'react-redux';
import { orange } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import FuseUtils from '@fuse/utils';

const useStyles = makeStyles(theme => ({
	productImageFeaturedStar: {
		position: 'absolute',
		top: 0,
		right: 0,
		color: orange[400],
		opacity: 0
	},
	productImageUpload: {
		transitionProperty: 'box-shadow',
		transitionDuration: theme.transitions.duration.short,
		transitionTimingFunction: theme.transitions.easing.easeInOut
	},
	productImageItem: {
		transitionProperty: 'box-shadow',
		transitionDuration: theme.transitions.duration.short,
		transitionTimingFunction: theme.transitions.easing.easeInOut,
		'&:hover': {
			'& $productImageFeaturedStar': {
				opacity: 0.8
			}
		},
		'&.featured': {
			pointerEvents: 'none',
			boxShadow: theme.shadows[3],
			'& $productImageFeaturedStar': {
				opacity: 1
			},
			'&:hover $productImageFeaturedStar': {
				opacity: 1
			}
		}
	}
}));


// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const phoneRegExp = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
//const phoneRegExp = /^(\+?\d{0,1})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	address1: yup.string().required(),
	address2:yup.string().required(),
	address_state:yup.string().required(),
	city:yup.string().required(),
	zip:yup.string().required(),
	phone:yup.string()
		.matches(phoneRegExp, 'You must enter a valid phone number')
        .min(10, 'You must enter a valid phone number')
		.required(),
	email:yup.string().email('You must enter a valid email').required(),
	website:yup.string().required()
});

const defaultValues = {
	address1: '',
	address2:'',
	address_state:'',
	city:'',
	zip:'',
	phone:'',
	email:'',
	website:'',
	logo1:''
};


function BasicInfoTab(props) {

	const classes = useStyles(props);

	const user = useCurrentUser();
	let userId = user.data.id;

	const { control, formState, handleSubmit, reset } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;
	const dispatch = useDispatch();
	const toast = useToast(dispatch);

	const [up,setUp] = useState(null);
	const [showImage, setShowImage] = useState({url:null}); 
	const [imageUpload , setImageUpload ] = useState(false);
	const uploadInput = useRef();

	useEffect(()=>{
		axios.get(`/client/list/${user.data.id}`)
		// axios.get(`/client/list/2`)
		.then(results=>{
			reset(results.data.data);
			// console.log(results.data.data);
			setShowImage({
				url:results.data.data.logo_url
			})
			if(results.data.data.logo_url){
				setImageUpload(true)
			}
		})
	},[]);


	function onSubmit(formData){
		
		let storeData = {
			address1:formData.address1,
			address2:formData.address2,
			address_state:formData.address_state,
			city:formData.city,
			zip : formData.zip,
			phone : formData.phone,
			website : formData.website,
			email :formData.email ,
			logo_url: showImage.url
		}
		// console.log(storeData);
		onUpdateUser(storeData);
	}

	function onUpdateUser(formData){
		axios.post(`/client/update/${user.data.id}`,formData)
		// axios.post(`/client/update/2`,formData)
		.then(result=>{
			toast.success(result.data.message);
		}).catch(err=>{
			if(err.response){
				toast.error(err.response.data.message)
			}
		})
	}

	function checkImage(){
		if(showImage.url){
			return (<> <img className={clsx(
				classes.productImageItem,
				'flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer outline-none shadow hover:shadow-lg',
					)} 
							src={showImage.url} 
							alt="logo" style={{margin:'0 auto'}} /> </>)
		}else{
			
			return (<></> )

		}
	}

	function saveImage(file){
		const formData = new FormData();
		formData.append("logo", file);
		axios.post(`/client/get-url`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(results=>{
			// console.log(results);
			setImageUpload(true);
			setShowImage({url:results.data.data.url});
		}).catch(err=>{
			if(err.response){
				toast.error(err.response.data.message);
			}
		})
	}

	function allowDrop(event) {
 		 event.preventDefault();
	}

	function drop(event) {
	  event.preventDefault();
	  let files = [...event.dataTransfer.files];
	  const file = files[0];
	  // console.log(file);
	  saveImage(file);

	}

	function saveFile(){
		let file = uploadInput.current.files[0];
		// console.log(file)
		saveImage(file)
	}

	return(
		<div>
			<form
				name="updateForm"
				noValidate 
				onSubmit={handleSubmit(onSubmit)}
			>

			<Controller
				name="address1"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
							className="mt-8 mb-16"
							label="Address 1"
							autoFocus
							error={!!errors.address1}
							helperText={errors?.address1?.message}
							variant="outlined"
							required
							fullWidth
					/>
				)}
			/>

			<Controller
				name="address2"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
							className="mt-8 mb-16"
							label="Address 2"
							error={!!errors.address2}
							helperText={errors?.address2?.message}
							variant="outlined"
							required
							fullWidth
					/>
				)}
			/>			

			<Controller
				name="address_state"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
							className="mt-8 mb-16"
							label="Address State"
							error={!!errors.address_state}
							helperText={errors?.address_state?.message}
							variant="outlined"
							required
							fullWidth
					/>
				)}
			/>

			<Controller
				name="city"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
							className="mt-8 mb-16"
							label="City"
							error={!!errors.city}
							helperText={errors?.city?.message}
							variant="outlined"
							required
							fullWidth
					/>
				)}
			/>

			<Controller
				name="zip"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
							className="mt-8 mb-16"
							label="Zip"
							error={!!errors.zip}
							helperText={errors?.zip?.message}
							variant="outlined"
							required
							fullWidth
					/>
				)}
			/>

			<Controller
				name="phone"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
							className="mt-8 mb-16"
							label="Phone"
							error={!!errors.phone}
							helperText={errors?.phone?.message}
							variant="outlined"
							required
							fullWidth
							placeholder="Phone* eg. 111-111-1111"
					/>
				)}
			/>

			<Controller
				name="email"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
							className="mt-8 mb-16"
							label="Email"
							error={!!errors.email}
							helperText={errors?.email?.message}
							variant="outlined"
							required
							fullWidth
					/>
				)}
			/>
 
			<Controller
				name="website"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
							className="mt-8 mb-16"
							label="Website"
							error={!!errors.website}
							helperText={errors?.website?.message}
							variant="outlined"
							required
							fullWidth
					/>
				)}
			/>

			{/*<div className="flex justify-center sm:justify-start flex-wrap -mx-16">
				<Controller
					name="logo"
					control={control}
					defaultValue={[]}
					render={({ field: { onChange, value } }) => (
						<label
							htmlFor="button-file"
							className={clsx(
								classes.productImageUpload,
								'flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg'
							)}
						>
							<input
								accept="image/*"
								className="hidden"
								id="button-file"
								type="file"
								onChange={async e => {
									function readFileAsync() {
										return new Promise((resolve, reject) => {
											const file = e.target.files[0];
											if (!file) {
												return;
											}
											const reader = new FileReader();
											
											reader.onload = () => {
												resolve({
													id: FuseUtils.generateGUID(),
													url: `data:${file.type};base64,${btoa(reader.result)}`,
													type: 'image'
												});
											};

											reader.onerror = reject;

											reader.readAsBinaryString(file);
											// saveImage(file);
										});
									}

									const newImage = await readFileAsync();
									// setShowImage(newImage);
									
									onChange(showImage);
								}}
							/>
							<Icon fontSize="large" color="action">
								cloud_upload
							</Icon>
						</label>
					)}
				/>

			</div>*/}

			<div
				onClick={() => uploadInput.current.click()}
				onDrop={drop} 
				onDragOver={allowDrop}	 
				role="button" 
				style={{border:'4px dashed black',textAlign:'center',padding:'20px',width:'50%'}}
				>
				{checkImage()}
				<p>Drag and Drop or Click to upload</p>
			 	<Icon fontSize="large" color="action">cloud_upload</Icon>

			 </div>
			 	

			<Button
				variant="contained"
				color="primary"
				className="w-224 mx-auto mt-16"
				aria-label="Reset"
				disabled={ !isValid  || !imageUpload }
				type="submit"
				>Update Details
			</Button>
				
			</form>
				<input
					ref={uploadInput}
					className="hidden"
					id="button-file"
					type="file"
					accept="image/*"
					onChange={saveFile}
				/>
		</div>
	);



}

export default BasicInfoTab;
