import { Box, Divider, Grid, Typography } from '@material-ui/core'
import React from 'react'
import Icon from '@material-ui/core'

const Test = () => {
  return (
    <>
    <div className='cstm-header'>
     
      <div style={{width:"80%",margin:'auto'}}>
        <img src="https://dalos.co/wp-content/uploads/2023/01/logo-1.png" style={{width:"150px"}} alt="" />
      </div>
     <div class="custom-shape-divider-bottom-1684755649">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
    </div>
    <div className='main-container'>
      <div>
        <p>We sincerely apologize! It appears that you've attempted to access a site that doesn't exist in our system. Please verify the company name and try again, or take advantage of our special offer: <a href={process.env.REACT_APP_SERVER === "PRODUCTION" ? 'https://welcome.dalos.co/registration' : 'https://welcome.dalos-staging.space/registration'}>Sign Up</a> here for a free 30-day trial!</p>
      </div>
    </div>
    <div className='cstm-footer' >
        <Grid container style={{padding:"20px"}}>
            <Grid item lg={3}>
               <img src="https://dalos.co/wp-content/uploads/2023/01/Group-444.png" alt="" />
               <Box mt={3}>
                  <Typography variant='subtitle2' className='ftr-subtitle'>
                      ADDRESS : 
                  </Typography>
                  <Typography variant='subtitle1' className='ftr-subtitle1' >
                    DALOS 28175 Haggerty Road, Novi, MI 48377, USA
                  </Typography>
               </Box>
               <Box mt={2}>
                  <Typography variant='subtitle2' className='ftr-subtitle'>
                      PHONE : 
                  </Typography>
                  <Typography variant='subtitle1' className='ftr-subtitle1'>
                    888-256-8880
                  </Typography>
               </Box>
              
            </Grid>
            <Grid item lg={2}>
               <Typography variant='h6' className='ftr-heading'>Company</Typography>
                <Box mt={3}>
                 <a className='block ftr-featCol-navBtn' href="#">Home</a>
                 <a className='block ftr-featCol-navBtn' href="#">Contact Us</a>
                 <a className='block ftr-featCol-navBtn' href="#">About Us</a>
                 <a className='block ftr-featCol-navBtn' href="#">Blog</a>
               </Box>
            </Grid>
            <Grid item lg={5}>
                <Typography variant='h6' className='ftr-heading'>Features</Typography>
                <Box mt={3}>
                  <Grid container>
                      <Grid item lg={6}>
                        <a className='block ftr-featCol-navBtn'>IT Asset Management</a>
                        <a className='block ftr-featCol-navBtn'>Contract Management</a>
                        <a className='block ftr-featCol-navBtn'>Contract & Subscription Renewal</a>
                        <a className='block ftr-featCol-navBtn'>Renewal Automation</a>
                         <a className='block ftr-featCol-navBtn'>Customer Web Portal</a>
                        
                      </Grid>
                       <Grid item lg={6}>
<a className='block ftr-featCol-navBtn'>IT Network Monitoring</a>
                        <a className='block ftr-featCol-navBtn'>Payment Processing</a>
                        <a className='block ftr-featCol-navBtn'>Email Collaboration</a>
                        <a className='block ftr-featCol-navBtn'>LiveChat</a>
                         <a className='block ftr-featCol-navBtn'>24/7 Customer Success Support</a>
                      </Grid>
                  </Grid>
                </Box>
            </Grid>
            <Grid item lg={2}>
                <Typography variant='h6' className='ftr-heading'>
                  Connect With Us
                  <Box mt={3}>

                  </Box>
                </Typography>
            </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item lg={12} className='flex items-center justify-center' >
                <Box className='copyright-section'>
                  <Typography className='copyright-text'>© 2023 Dalos. All rights reserved.</Typography>
                   <Box>
                    <a className='copyright-navBtn'  href="#">Privacy Policy</a>
                    
                 <a className='copyright-navBtn' href="#">Legal</a>
                </Box>
                </Box>
               
          </Grid>
        </Grid>
    </div>
    </>
  )
}

export default Test
