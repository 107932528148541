const initialState = {
	open: false,
}

const actionTypes = {
    OPEN_REPORT_VIEWER_DIALOG: "OPEN_REPORT_VIEWER_DIALOG",
    CLOSE_REPORT_VIEWER_DIALOG: "CLOSE_REPORT_VIEWER_DIALOG"
}

export function openReportViewerDialog(){
    return {
        type: actionTypes.OPEN_REPORT_VIEWER_DIALOG,
    }
}

export function closeReportViewerDialog(){
    return {
        type: actionTypes.CLOSE_REPORT_VIEWER_DIALOG,
    }
}

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.OPEN_REPORT_VIEWER_DIALOG:{
			return { 
				...state,
				open: true,
			}
		}
		case actionTypes.CLOSE_REPORT_VIEWER_DIALOG:{
				return { 
					...state,
					open: false,
				}
		}
		default:
			return state
	}
}