import Page from './index';

export default {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/organization-settings',
			exact: true,
			component: Page.Product
		},
		// {
		// 	path: '/roles/:id',
		// 	component: Page.Edit
		// },
	]
};
