import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core';
import React, { useState,useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Autocomplete } from '@material-ui/lab';
import Editor from '@fuse/components/Editor';
// import LocationTypeDialog from './QuickAddLocationType';
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';



const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


const AddAttributeFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const schema = yup.object({
        attribute_name: yup.string().trim().required('Attribute name is required!'),
    })

    const defaultValues = {
        attribute_name: ''
    }

    const { 
        control: attributeFormControl, 
        formState:{errors: attributeFormErrors}, 
        handleSubmit: attributeFormSubmit, 
        trigger,
        setValue,
        getValues,
        setErrors,
        reset: attributeFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    const onSubmit = async(data) => {
        await trigger();
        if(Object.keys(attributeFormErrors).length !== 0) { 
            return;
        }
        let payload = {
			attribute_names: [{attribute_name: data.attribute_name}],
		}
        axios.post('/model-custom-attributes/add', payload).then((res)=>{
            if(res.status === 201) {
                toast.error(res.data.message)
            } else {
                toast.success(res.data.message)
                attributeFormReset(defaultValues)
                // console.log(res.data.data)
                newDataAddedEvent(res.data.data.new_attribute)
                // props.handleCloseDialog(res.data.data.new_attribute)
            }
		}).catch((err)=>{
			console.log('error',err.response);
			toast.error(err.response.data.data)
		})
    }

    return (
        <Dialog
            className="cstm_dialog_modal small text-center"
            open={openAddStatusModalStateData}
            // onClose={handleOpenCloseAddModalEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div className='heading_holder flexbox_holder items-center w-full'>
                    <h2 className="main_heading">Add Model Attribute</h2>
                    <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                        <i className="icon ti ti-x" />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-left">
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Attribute Name
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="attribute_name"
                            className="mb-16"
                            defaultValue={defaultValues.attribute_name}
                            control={attributeFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Attribute'
                                        required
                                        fullWidth
                                        error={!!attributeFormErrors.attribute_name}
                                        helperText={attributeFormErrors?.attribute_name?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{ marginBottom: '10px',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="action_footer_holder">
                <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                <Button onClick={attributeFormSubmit(onSubmit)} className="primary_btn">
                    Save and Select
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default AddAttributeFromQuickAction