import FusePageSimple from '@fuse/core/FusePageSimple';
import { useRef } from 'react';
import Header from './Header';
import ModulesTable from './ModulesTable';

function Main() {
	const pageLayout = useRef(null);

	return (
		<FusePageSimple
			header={<Header/>}
			content={
				<div className="p-24">
					<ModulesTable/>
				</div>
			}
		/>
			);
}

export default Main;
