import useToast from '@fuse/hooks/useToast'
import { makeStyles } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import { usePermission } from '@fuse/hooks';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { Box, Button, Icon,
	IconButton, TextField, Typography,
	Drawer, Paper } from "@mui/material";
import Editor from '@fuse/components/Editor';
import AddManufacturerFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddManufacturerFromQuickAction'
import AddAccessoryCategoryFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddAccessoryCategoryFromQuickAction'
import AlertModal from 'helper/alertModal'
const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	subSection: {
		marginLeft: '20px !important',
		marginRight: '20px !important',
		marginBottom: '20px !important',
		padding: '10px',
		backgroundColor: '#f4f4f4',
		borderRadius: '4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
	drawerWidth: {
		width: 520,
		borderTopLeftRadius: '8px',
		borderBottomLeftRadius: '8px',
		overflowX: 'hidden',
		overflowY: 'hidden'
	}
}))

function AddAccessoryDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.accessoryApp.formViewDialog);
	const { data: editFromState } = useSelector(({ accessoryApp }) => accessoryApp.formViewDialog);
	const { hasPermission } = usePermission()
	const createManufacturePermission = hasPermission('add-manufacture')
	const createAccessoryCategoryPermission = hasPermission('add-accessory_category')
	const [loading, setLoading] = useState(false);
	const [manufacturers, setManufacturers] = useState([])
	const [accessoryCategoryMenu, setAccessoryCategoryMenu] = useState([])
  const [ description, setDescription ] = useState('');
  const [openAlerModal, setOpenAlertModal] = useState(false);

	//form validation
	const schema = yup.object({
    accessory_name: yup.string().required("Accessory is required").matches(/\S/, 'Accessory cannot be only spaces!'),
    model_number: yup.string().required("Model is required").matches(/\S/, 'Model cannot be only spaces!'),
    accessory_category: yup.object().required("Accessory category is required"),
    manufacturer: yup.object().required("Manufacturer is required")
  })

	// setting default values of accessory
	const defaultValues = {
		accessory_name: '',
		model_number: '',
    accessory_category: null,
    manufacturer: null
	}

  const { control, formState, handleSubmit, reset, clearErrors, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { errors } = formState

	// when cancelling to add or edit all values are reset to default values
	const onCancel = () => {
		reset(defaultValues)
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
    setDescription('')
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
    setDescription('')
	}

  function onEditorContentChange(content) {
		setDescription(content)
	}

	// fetching the value for manufacturer dropdown list
	const fetchManufacturerSuggestions = (value = '') => {
		setLoading(true)
		axios.get("/manufacturers").then(response => {
			setLoading(false)
			setManufacturers((AlphabeticalSorting(response.data.data, 'manufacturer_name')).filter((item) => { return Boolean(item.manufacturer_name) }))
		}).catch(err => {
			console.log('136=>', err);
			// toast.error('Something went wrong!')
		})
	}

	/**-----------------Manufacture-------------------- */
	const [openManufactureDialog, setOpenManufactureDialog] = useState(false)

  const handleOpenCloseAddManufacturer = () => {
    setOpenManufactureDialog(!openManufactureDialog)
  }

  const newManufacturerAddedEvent = (createdData) => {
		handleOpenCloseAddManufacturer()
      setValue('manufacturer', {
			id: createdData.id, 
			entity_external_platform_id: createdData.entity_external_platform_id, 
			manufacturer_name: createdData.manufacturer_name
		})
    clearErrors('manufacturer')
    fetchManufacturerSuggestions()
  } 

	// fetching the value for accessory category dropdown list
	const fetchAccessoryCategorySuggestions = (value = '') => {
		setLoading(true)
		axios.get('/accessory-category').then(response => {
			setLoading(false)
			setAccessoryCategoryMenu((AlphabeticalSorting(response.data.data, 'accessory_category_name')).filter((item) => { return Boolean(item.accessory_category_name) }))
		}).catch(err => {
			console.log('164=>', err);
			// toast.error('Something went wrong!')
		})
	}

	/**-----------------Accessory Category-------------------- */
	const [openAccessoryCategoryDialog, setOpenAccessoryCategoryDialog] = useState(false)

	function handleOpenCloseAddAccessoryCategory() {
		setOpenAccessoryCategoryDialog(!openAccessoryCategoryDialog)
	}

  const newAccessoryCategoryAddedEvent = (createdData) => {
		handleOpenCloseAddAccessoryCategory()
    setValue('accessory_category', {
			id: createdData.id,
			accessory_category_name: createdData.accessory_category_name
		})
    clearErrors('accessory_category')
    fetchAccessoryCategorySuggestions()
  }

	// changing the value of accessory if its edited
	useEffect(() => {
		if (editFromState) {
			setValue('accessory_name', editFromState.accessory_name);
			setValue('model_number', editFromState.model_number);
      setValue('manufacturer', editFromState.manufacturer)
      setValue('accessory_category', editFromState.accessory_category)
			setDescription(editFromState.note == "No Note" ? '' : editFromState.note)
		}
	}, [editFromState])


	// edit accessory api call
	function editAccessory(payload) {
		axios.post(`/accessory/edit`, payload).then(response => {
			if (response.status == 201) {
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				closeDialog()
				toast.success('Accessory updated Successfully.')
				dispatch(setQuickFilter(null))
				if (props.getAccessory) {
					props.getAccessory()
				}
			}
		}).catch(err => {
			console.log('212=>', err);
			// toast.error('Something went wrong!')
		})
	}

	// setting the payload data and calling the edit or add accessory api
	const manageFormData = (formData) => {
		let payload = {
			entity_external_platform_id: String(Math.trunc(Math.random() * 20000)),
			accessory_name: formData.accessory_name,
			model_number: formData.model_number,
			note: description,
			manufacturer_id: formData.manufacturer.id,
			accessory_category_id: formData.accessory_category.id,
		}

		if (editFromState) {
			let payload = {
				edit_id: Number(editFromState.id),
				accessory_name: formData.accessory_name,
				model_number: formData.model_number,
				note: description,
				manufacturer_id: formData.manufacturer.id,
			  accessory_category_id: formData.accessory_category.id,
			}
			editAccessory(payload)
		} else {
			console.log('payload', payload)
			axios.post('/accessory/add', payload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					reset(defaultValues)
					closeDialog()
					toast.success('Accessory added Successfully.')
					dispatch(setQuickFilter(null))
					props.getAccessory()
				}
			}).catch(err => {
				console.log('212=>', err);
				// toast.error('Something went wrong!')
			})
		}
	}

	// to call the manufacturer and accessory category suggestions
	useEffect(() => {
		fetchManufacturerSuggestions()
		fetchAccessoryCategorySuggestions()
	}, [])
	
	const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        onCancel()
    }
	return (
		<div>
      {
        openAccessoryCategoryDialog && <AddAccessoryCategoryFromQuickAction
          handleOpenCloseAddModalEvent={handleOpenCloseAddAccessoryCategory}
          openAddStatusModalStateData={openAccessoryCategoryDialog}
          newDataAddedEvent={newAccessoryCategoryAddedEvent}
        />
      }
      {
        openManufactureDialog && <AddManufacturerFromQuickAction
          handleOpenCloseAddModalEvent={handleOpenCloseAddManufacturer}
          openAddStatusModalStateData={openManufactureDialog}
          newDataAddedEvent={newManufacturerAddedEvent}
        />
      }
		<>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>
					<h4 className='heading'>{Boolean(editFromState) ? "Update Accessory" : "Add Accessory"}</h4>
					<IconButton style={{ padding: '0px' }} onClick={handleAlertModal}>
						<Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
					</IconButton>
				</div>
				<div className='progressbar_holder'>
					<div className='progress'>
						&nbsp;
					</div>
				</div>

				<div style={{ paddingTop: '30px' }} className='cstm_wizard_holder'>
					<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder'>
							<div className='content_holder'>
								<div className="text-left">
									<div className='mb-10'>
										<Typography className='status_text'>
											Accessory
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name='accessory_name'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													style={{ background: 'white' }}
													variant='outlined'
													autoFocus
													fullWidth
                          error={!!errors.accessory_name}
                          helperText={errors?.accessory_name?.message}
												/>
											)}
										/>
									</div>

									<div className='mb-10'>
										<Typography className='status_text'>
											Model
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name='model_number'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													style={{ background: 'white' }}
													variant='outlined'
													autoFocus
													fullWidth
                          error={!!errors.model_number}
                          helperText={errors?.model_number?.message}
												/>
											)}
										/>
									</div>

									<div className='autocomplete_holder mb-10'>
										<Typography className='status_text'>
											Accessory Category
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name="accessory_category"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field }, fieldState: { error } }) => (
												<>
													<Autocomplete
                            {...field}
														style={{ width: '100%' }}
														options={accessoryCategoryMenu}
														onChange={(_, value) => field.onChange(value)}
														PaperComponent={({ children }) => (
															<Paper className="autocomplete_holder autocomplete_paper_holder">
																{children}
																<div className='p-16'>
																	{createAccessoryCategoryPermission && <Button
																		className='secondary_btn w-full'
																		onMouseDown={() => handleOpenCloseAddAccessoryCategory()}
																	>
																		<i className='ti ti-plus' />
																		<span>Add Accessory Category</span>
																	</Button>}
																</div>
															</Paper>
														)}
														getOptionLabel={option => option.accessory_category_name ?? ''}
														renderInput={(params) => {
															return (
																<div ref={params.InputProps.ref}>
																	<TextField
																		{...params}
																		style={{ background: 'white' }}
																		variant="outlined"
																		fullWidth
                                    error={!!error}
                                    helperText={error?.message && "Accessory category is required"}
																	/>
																</div>
															)
														}}
													/>
												</>
											)}
										/>
									</div>

									<div className='autocomplete_holder mb-10'>
										<Typography className='status_text'>
											Manufacturer
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name="manufacturer"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field }, fieldState: { error } }) => (
												<>
													<Autocomplete
                            {...field}
														style={{ width: '100%' }}
														options={manufacturers}
														onChange={(_, value) => field.onChange(value)}
														PaperComponent={({ children }) => (
															<Paper className="autocomplete_holder autocomplete_paper_holder">
																{children}
																<div className='p-16'>
																	{createManufacturePermission && <Button
																		className='secondary_btn w-full'
																		onMouseDown={() => handleOpenCloseAddManufacturer()}
																	>
																		<i className='ti ti-plus' />
																		<span>Add Manufacturer</span>
																	</Button>}
																</div>
															</Paper>
														)}
														getOptionLabel={option => option.manufacturer_name ?? ''}
														renderInput={(params) => {
															return (
																<div ref={params.InputProps.ref}>
																	<TextField
																		{...params}
																		style={{ background: 'white' }}
																		variant="outlined"
																		fullWidth
																		required
                                    error={!!errors.manufacturer}
                                    helperText={errors?.manufacturer && "Manufacturer is required"}
																	/>
																</div>
															)
														}}
													/>
												</>
											)}
										/>
									</div>
                  <div className='mb-10'>
										<Typography className='status_text'>
											Description
										</Typography>
										<Editor
											editorContent={description}
											onEditorContentChange={onEditorContentChange}
											moduleType='accessory'
										/>
									</div>
									<div className='btn_holder'>
										<Button
											color="inherit"
											onClick={handleAlertModal}
											className='back_btn secondary_btn'
										>
											Cancel
										</Button>
										<Button onClick={handleSubmit(manageFormData)} className='next_btn'>
											Proceed
										</Button>
									</div>
								</div>
							</div>

						</div>
					</Box>
				</div>
			</Drawer>
			{openAlerModal &&
                <AlertModal  openAlerModal module="accessory" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
			</>
		</div>
	)
}

export default AddAccessoryDialog
