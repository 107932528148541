import React, { useEffect, useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete';
// import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AddDepartmentFromQuickAction from './AddDepartmentFromQuickAction';
import Checkbox from '@mui/material/Checkbox';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'
import ColorPicker from '@fuse/components/ColorPicker'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


const AddContractStatusFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const [color, setColor] = useState({name:'black',code:"#000000"});

    const schema = yup.object({
       contractStatus: yup.string().trim().required('Tag is required!'),
       statusDescription: yup.string()
    })

    const defaultValues = {
        contractStatus: '',
        statusDescription: ''
    }

    const { 
        control, 
        formState:{errors: statusFormErrors}, 
        handleSubmit: statusFormSubmit, 
        trigger,
        reset: statusFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    const onsubmit = async (data) => {
        await trigger();
        if(Object.keys(statusFormErrors).length !== 0) { 
            return;
        }
        const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			contractStatus: data.contractStatus,
            status_description: data.statusDescription,
            status_color: color,
		}

		axios.post('/contract-status/add', payload).then(res => {
			if(res.status === 201){
				toast.error(res.data.message)
			}else{
				newDataAddedEvent(res.data.data)
				toast.success('Contract Tag added successfully')
			}
		}).catch(err => {
            console.log('59: ',err);
			// toast.error('Something went wrong!')
		})
    }


    return (
        <>
            <Dialog
                className="cstm_dialog_modal small text-center"
                open={openAddStatusModalStateData}
                // onClose={handleOpenCloseAddModalEvent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='heading_holder flexbox_holder items-center w-full'>
                        <h2 className="main_heading">Add Contract Tag</h2>
                        <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                            <i className="icon ti ti-x" />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-left">
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Tag
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller 
                                name="contractStatus"
                                className='serial_numberField'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        style={{ background: 'white' }}
                                        variant='outlined'
                                        autoFocus
                                        fullWidth
                                        required
                                        defaultValue={defaultValues.contractStatus}
                                        error={!!statusFormErrors.contractStatus}
                                        helperText={statusFormErrors?.contractStatus?.message}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                border: '1px solid #F2F4F7',
                                                fontSize: '10px'
                                            }
                                        }} 
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Description
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Controller 
                                name='statusDescription'
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label="Description"
                                        fullWidth
                                        multiline
                                        rows={8}
                                        inputProps={{ className: classes.textarea }}
                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                        error={!!statusFormErrors.statusDescription}
                                        helperText={statusFormErrors?.statusDescription?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Choose Color
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <ColorPicker setColor={setColor} color={color}/>
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions className="action_footer_holder">
                    <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                    <Button onClick={statusFormSubmit(onsubmit)} className="primary_btn">
                        Save and Select
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )

}


export default AddContractStatusFromQuickAction;


