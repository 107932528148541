import OTPInput from 'react-otp-input';
import { React, useState, useEffect, useRef, Fragment, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, InputAdornment, Autocomplete, Box } from "@mui/material";
import { countries } from '@fuse/utils/countryName';
import { formatPhoneNumber } from '@fuse/utils/formatPhoneNumber';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import axios from '@fuse/utils/axios';
function PhoneNumberVerificationModal({
  open,
  close
}) {
  const dispatch = useDispatch()
  const toast = useToast(dispatch);
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ openEnterOTPScreen, setOpenEnterOTPScreen ] = useState(false);
  const [ otp, setOtp ] = useState('');
  const [ country, setCountry ] = useState({
    "name": "United States",
    "code": "US",
    "currency": "USD",
    "phone": "+1"
  });

  function changeNumberFormat(e){
		setPhoneNumber(formatPhoneNumber(e.target.value))
	}

  const handleCloseOpenEnterOTPScreen = () => {
    setOtp('')
    setPhoneNumber('')
    setOpenEnterOTPScreen(false)
    close()
  }

  function sendOTPtoVerifyPhoneNumber(){
    if (!Boolean(phoneNumber) || (phoneNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(phoneNumber))) {
			toast.error("Enter a valid Phone Number!")
			return
		}
    axios.post('/user/send-otp-to-user',{
      phone_number: `${country.phone} ${phoneNumber}`
    }).then((res)=>{
      setOpenEnterOTPScreen(true)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{})
  }

  function verifyOTP(){
    if(!Boolean(otp)){
      toast.error("Please enter OTP to proceed");
      return
    }
    axios.post('/user/verify-user-otp',{
      phone_number: `${country.phone} ${phoneNumber}`,
      otp: otp,
    }).then((res)=>{
      toast.success(res.data.message)
      handleCloseOpenEnterOTPScreen()
    }).catch((err)=>{
      if(err.response.data.message === "jwt expired")err.response.data.message = "Invalid OTP"
      toast.error(err.response.data.message)
    }).finally(()=>{
    })
  }

  return (
    <>
      {
        !openEnterOTPScreen && (
          <Dialog
            className="cstm_dialog_modal text-center"
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <div className='heading_holder flex justify-end items-center w-full'>
                <Button className='transparent_icon_btn' onClick={close}>
                  <i className="icon ti ti-x" />
                </Button>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="warning_content_holder">
                <div className="exclamation_icon_holder yellow">
                  <i className="ti ti-exclamation-mark" />
                </div>
                <h2 className="heading">Your mobile number isn't updated in your profile</h2>
                <p className="sub_content">Please enter a mobile number below and this will automatically be updated in your profile</p>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="country-select-demo"
                      options={countries}
                      autoHighlight
                      disableClearable
                      value={country}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value)=>{
                        setCountry(value)
                      }}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <Box
                            key={key}
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {`${option.name} (${option.phone})`}
                          </Box>
                        );
                      }}
                      renderInput={(params) => {
                        return (
                        <TextField
                          {...params}
                          inputRef={params.ref}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment:
                              <InputAdornment position="start" style={{ marginRight: '15px' }}>
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                              </InputAdornment>
                          }}
                        />
                      )}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                    fullWidth
                    label='Phone number'
                    value={phoneNumber}
                    onChange={changeNumberFormat}
                    onKeyPress={(event) => {
                      if (!/[0-9/-]+/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start" style={{ marginRight: '15px' }}>
                          {country.phone}
                        </InputAdornment>
                    }}
                  />
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions className="action_footer_holder">
              <Button onClick={sendOTPtoVerifyPhoneNumber} className="primary_btn">
                Update and Proceed
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
      {
        openEnterOTPScreen && (
          (<Dialog
            className="cstm_dialog_modal small text-center"
            open={openEnterOTPScreen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <div className='heading_holder flex justify-end items-center w-full'>
                <Button className='transparent_icon_btn' onClick={handleCloseOpenEnterOTPScreen}>
                  <i className="icon ti ti-x" />
                </Button>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="warning_content_holder">
                <div className="exclamation_icon_holder yellow">
                  <i className="ti ti-exclamation-mark" />
                </div>
                <h2 className="heading">Please enter the verification code sent to your phone number</h2>
              </div>
              <OTPInput
                inputStyle={{ width: '500%', border: '1px solid #999', height:'40px', borderRadius: '4px', fontSize: '20px' }}
                inputType='text'
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span style={{ marginRight: '10px' }}></span>}
                renderInput={(props) =>(
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]+/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className='myOtp' 
                    {...props} 
                  />
                )}
                containerStyle={`my-otp-input`}
              />
            </DialogContent>
            <DialogActions className="action_footer_holder">
              <Button onClick={verifyOTP} className="primary_btn">
                Verify
              </Button>
            </DialogActions>
          </Dialog>)
        )
      }
    </>
  )
}

export default PhoneNumberVerificationModal