
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect, Fragment } from 'react';

const useStyles = makeStyles(theme => ({
    
}));

function ArrowButton(props) {
    const color = props.color?props.color:'nutal';
    const variant = props.variant?props.variant:'btn-arrow-right';
    const classes = useStyles(props);
    const [colorVar, setColor] = useState('btn-nutral');
    const [variantVar, setVariant] = useState('btn-arrow-right');

    useEffect(() => {
        switch(color){
            case 'secondary':
                setColor('btn-secondary');
                break;
            case 'danger':
                setColor('btn-danger');
                break;
            case 'success':
                setColor('btn-success');
                break;
            case 'warning':
                setColor('btn-warning');
                break;
            case 'info':
                setColor('btn-info');
                break;
            default:
                setColor('btn-nutral');
                break;
        }

        switch(variant){
            case 'left':
                setVariant('btn-arrow-left');
                break;
            case 'right':
                setVariant('btn-arrow-right');
                break;
            default:
                setVariant('btn-arrow-right');
                break;
        }
    },[color, variant])

    let className = 'default';
    if(props.color){
        className = props.color;
    }
	return(
        <button className={`btn ${colorVar} ${variantVar}`} onClick={()=>props.onClick ? props.onClick() : (()=>{})}>
            {props.label ? props.label : ''}
        </button>
	);
}

export default ArrowButton;