import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import {TextField,MenuItem} from '@material-ui/core';
import { useState, useEffect, Fragment } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<Icon
					component={motion.span}
					initial={{ scale: 0 }}
					animate={{ scale: 1, transition: { delay: 0.2 } }}
					className="text-24 md:text-32"
				>
					business
				</Icon>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-24 mx-12 font-semibold"
				>
					Shipping Locations
				</Typography>
			</div>

			<div className={`flex flex-1 items-center justify-end ${classes.noPaddingRight}`}>
				<ThemeProvider theme={mainTheme}>
					<Paper
						component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center w-full max-w-512 px-8 py-4 shadow ${classes.buttonSquare}`}
					>
						<Icon color="action">search</Icon>

						<TextField
							placeholder="Search"
							className="flex flex-1 mx-8"
							// disableUnderline
							fullWidth
							value={search}
							onChange={ev => setSearchQuery(ev.target.value)}
							InputProps={{
								disableUnderline: true
							}}
							
						/>
						{search != '' && <IconButton
											aria-label="toggle password visibility"
											onClick={()=>{setSearchQuery('');props.clearSearch()}}
											size="small"
											>
												<Icon>cancel</Icon>
											</IconButton>
						}
					</Paper>
				</ThemeProvider>
				
				<div className={`pl-10 `} style={{width:"200px"}}>
				<Button
					variant="contained"
					color="secondary"
					onClick={()=>props.search(search)}
					className={`w-full ${classes.buttonSquare}`}>
						Search
				</Button>
				</div>
			</div>
		</div>
	);
}

export default DynamicFieldsHeader;
