import React, { } from 'react';
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import loaderImg from "../icons/Loader.png";
import { toggleProgressBarVisibility, updateSyncStatus } from '../store/dataSyncReducer';

function DataSyncStatusModal({ open, close }) {
	const { syncStatus } = useSelector(({ ConnectorsApp }) => ConnectorsApp.dataSyncReducer);
  const dispatch = useDispatch();

  function handleClose(){
    close()
    if(syncStatus !== 'IN_PROGRESS'){
      dispatch(toggleProgressBarVisibility(false))
      dispatch(updateSyncStatus({
        status: 'IN_PROGRESS'
      }))
    }
  }

  console.log('syncStatus',syncStatus)

  return (
    <Dialog
      className="cstm_dialog_modal small text-center"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div className='heading_holder flex justify-end items-center w-full'>
          <Button className='transparent_icon_btn' onClick={handleClose}>
            <i className="icon ti ti-x" />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="warning_content_holder">          
          {
            syncStatus === 'IN_PROGRESS' && (
              <>
               <div className="warning_content_holder">
								<div className='loader_animation_holder'>
									<img src={loaderImg} alt='loader' />
								</div>
                <h2 className="heading">Synchronization In Progress</h2>
                <p className="sub_content">
                  Synchronization is underway. This may take a few minutes. You can close this and carry on with your work.
                  we'll notify you when it is done.
                </p>
                </div>
              </>
            )
          }
          {
            syncStatus === 'COMPLETED' && (
              <>
              <div className="exclamation_icon_holder green">
                <i className="ti ti-check white" />
              </div>
              <h2 className="heading">Synchronization Complete</h2>
              <p className="sub_content">The synchronization process finished successfully. Enjoy your updated data!</p>
            </>
            )
          }
          {
            syncStatus === 'ERROR' && (
              <>
                <div className="warning_content_holder">
                  <div className="exclamation_icon_holder yellow">
                    <i className="ti ti-exclamation-mark white" />
                  </div>
                  <h2 className="heading">Synchronization Failed</h2>
                  <p className="sub_content">
                    Please try after some time.
                  </p>
                </div>
              </>
            )
          }
        </div>
      </DialogContent>
      <div className="action_footer_holder justify-center">
        <Button style={{ maxWidth: '65px' }} className="primary_btn" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Dialog>
  )
}

export default DataSyncStatusModal