import { combineReducers } from '@reduxjs/toolkit';

import emailDialogReducer from './emailDialogReducer';
import filterParamsReducer from './filterParamsReducer';
import reportViewDialogReducer from './reportViewDialogReducer';

const reducer = combineReducers({
	emailDialogReducer,
	filterParamsReducer,
	reportViewDialogReducer
});

export default reducer;
