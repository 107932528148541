import React ,{ useState,useEffect } from 'react';
import { useParams,useLocation } from 'react-router-dom';
import axios from "@fuse/utils/axios";

import SetPassword from './SetPassword';
import LinkExpired from './LinkExpired';


function SetPasswordMain(){
	const query = new URLSearchParams(useLocation().search)
	const id = query.get('det')

	let [expired,setExpired] = useState(false);
	let [userMail,setUserMail] = useState(null);

	useEffect(()=>{
		let base64ToString = Buffer.from(id, "base64").toString();
		base64ToString = JSON.parse(base64ToString);
		console.log(base64ToString);
		const  { email } = base64ToString;
		setUserMail(email);
		console.log(email);
		axios.get(`end-user/verify?email=${email}`)
		.then(result=>{
			console.log(result.data);
			if(result.data.data.is_set_password === true)
				setExpired(true)
		})

	},[]);


	if(expired){
		return <LinkExpired />
	}else{
		return < SetPassword id={id} />
	}
}

export default SetPasswordMain;