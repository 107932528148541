import React from "react";

export default function ContractParametersComponent({ data }) {
    return (
      <div className="flex flex-wrap py-8">
        <span className="cstm_badge_holder">
          Days : { data.days }
        </span>
        <span className="cstm_badge_holder">
          Location : { data.location_name }
        </span>
        <span className="cstm_badge_holder">
          Manufactures : { data.manufacturer_name }
        </span>
        <span className="cstm_badge_holder">
          Model : { data.model_name }
        </span>
        <span className="cstm_badge_holder">
          Notification : { data.get_notification_via }
        </span>
      </div>
    )
  }